import DeepLink from './deeplink';

const DeeplinkConfig = {
  settings: {
    layout: {
      config: {
        navbar: {
          display: false,
        },
        toolbar: {
          display: false,
        },
        footer: {
          display: false,
        },
        leftSidePanel: {
          display: false,
        },
        rightSidePanel: {
          display: false,
        },
      },
    },
  },
  routes: [
    {
      path: 'apps/:name?/:id?',
      element: <DeepLink />,
    },
  ],
};

export default DeeplinkConfig;
