import PropTypes from 'prop-types';
import withReducer from 'app/store/withReducer';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { motion } from 'framer-motion';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TablePagination from '@mui/material/TablePagination';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import DeleteIcon from '@mui/icons-material/Delete';
import CircularProgress from '@mui/material/CircularProgress';
import Icon from '@mui/material/Icon';
import Input from '@mui/material/Input';
import Paper from '@mui/material/Paper';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import moment from 'moment';
import _ from '@lodash';
import FuseLoading from '@fuse/core/FuseLoading';
import { useTranslation } from 'react-i18next';
import history from '@history';
import { useSnackbar } from 'notistack';
import negativeReviewsData from '../../query/negativeReviews';
import {
  getAllNegativeReview,
  negativeReviewStatus,
  deleteNegativeReview,
  reSetNegativeReviews,
} from './store/negativeReviewsSlice';
import reducer from './store';

function EnhancedTableToolbar(props) {
  const { numSelected } = props;
  if (numSelected > 0) {
    return (
      <Toolbar className="absolute left-0 right-0 -top-80 bg-black text-white z-10 rounded-md">
        {numSelected > 0 ? (
          <Typography
            sx={{ flex: '1 1 100%' }}
            color="inherit"
            variant="subtitle1"
            component="div"
            className="text-white"
          >
            {numSelected} Selected
          </Typography>
        ) : (
          ''
        )}

        {numSelected > 0 ? (
          <Tooltip title="Delete">
            <IconButton className="text-white">
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        ) : (
          ''
        )}
      </Toolbar>
    );
  }
}

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

function NegativeReviews() {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const negativeReviewListData = useSelector(
    ({ negativeReviewsReducer }) =>
      negativeReviewsReducer?.negativeReviews?.getAllNegativeReviewData?.data?.getAllNegativeReview
        ?.data || ''
  );

  const totalUsers = useSelector(
    ({ negativeReviewsReducer }) =>
      negativeReviewsReducer?.negativeReviews?.getAllNegativeReviewData?.data?.getAllNegativeReview
        ?.totalCount || 0
  );

  const reviewStatus = useSelector(
    ({ negativeReviewsReducer }) =>
      negativeReviewsReducer?.negativeReviews?.negativeReviewStatusData?.data?.negativeReviewStatus
        ?.data || []
  );

  const deleteReviewStatus = useSelector(
    ({ negativeReviewsReducer }) =>
      negativeReviewsReducer?.negativeReviews?.deleteNegativeReviewData?.data
        ?.deleteNegativeReview || {}
  );

  const [loading, setLoading] = useState(true);
  const [selected, setSelected] = useState([]);
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchValue, setSearchValue] = useState('');
  const [statusValue, setStatusValue] = useState('all');
  const [selectedReviewId, setSelectedReviewId] = useState();
  const [refreshIcon, setRefreshIcon] = useState(false);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [selectedRowAction, setSelectedRowAction] = useState('action');
  const [selectedRow] = useState(null);
  const [order, setOrder] = useState('desc');
  const [orderBy, setOrderBy] = useState('cts');
  const [orderNow, setOrderNow] = useState('desc');

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = negativeReviewListData?.map((n) => n.id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;

  useEffect(() => {
    let unmounted = false;
    const payload = {
      query: negativeReviewsData.getAllNegativeReview,
      variables: {
        pageNumber: 1,
        pageSize: 10,
        searchText: '',
        sortBy: 'cts',
        sortOrder: 'desc',
      },
    };
    dispatch(getAllNegativeReview(payload));

    const payloadData = {
      query: negativeReviewsData.negativeReviewStatus,
    };
    dispatch(negativeReviewStatus(payloadData));

    return () => {
      unmounted = true;
      dispatch(reSetNegativeReviews());
    };
  }, [dispatch]);

  useEffect(() => {
    if (negativeReviewListData) {
      setData(negativeReviewListData);
    } else {
      setData();
    }
  }, [negativeReviewListData]);

  useEffect(() => {
    if (negativeReviewListData || negativeReviewListData === null) {
      setLoading(false);
    } else {
      setLoading(true);
    }
  }, [negativeReviewListData]);

  useEffect(() => {
    if (deleteReviewStatus && deleteReviewStatus.status === 400) {
      enqueueSnackbar(deleteReviewStatus.message, {
        variant: 'error',
        autoHideDuration: 3000,
      });
    } else if (deleteReviewStatus && deleteReviewStatus.status === 200) {
      enqueueSnackbar(deleteReviewStatus.message, {
        variant: 'success',
        autoHideDuration: 3000,
      });
    }
  }, [enqueueSnackbar, deleteReviewStatus]);

  function EnhancedTableHead(props) {
    return (
      <TableHead className="bg-grey-50 rounded-md">
        <TableRow>
          <TableCell className="text-16 font-medium" align="left">
            <TableSortLabel hideSortIcon>{t('negativeReviews.column.no')}</TableSortLabel>
          </TableCell>
          <TableCell className="text-16 font-medium" align="left">
            <TableSortLabel
              direction={order}
              onClick={() => {
                handleSorting('name');
              }}
            >
              {t('common.name')}
            </TableSortLabel>
          </TableCell>
          <TableCell className="text-16 font-medium" align="left">
            <TableSortLabel
              direction={order}
              onClick={() => {
                handleSorting('businessName');
              }}
            >
              {t('common.businessName')}
            </TableSortLabel>
          </TableCell>
          <TableCell className="text-16 font-medium" align="left">
            <TableSortLabel hideSortIcon>{t('negativeReviews.column.mobileNo')}</TableSortLabel>
          </TableCell>
          <TableCell className="text-16 font-medium" align="left">
            <TableSortLabel
              direction={order}
              onClick={() => {
                handleSorting('cts');
              }}
            >
              {t('negativeReviews.column.date')}
            </TableSortLabel>
          </TableCell>
          <TableCell className="text-16 font-medium" align="left">
            <TableSortLabel hideSortIcon>{t('common.status')}</TableSortLabel>
          </TableCell>
          <TableCell className="text-16 font-medium rounded-tr-md">
            <TableSortLabel hideSortIcon>{t('common.action')}</TableSortLabel>
          </TableCell>
        </TableRow>
      </TableHead>
    );
  }

  if (loading) {
    return <FuseLoading />;
  }

  function redirectNegativeReviewsDetails(id) {
    history.push({
      pathname: `/negative-review-detail/${id}`,
    });
  }

  function handleChangePage(event, value) {
    setPage(value + 1);
    const payload = {
      query: negativeReviewsData.getAllNegativeReview,
      variables: {
        pageSize: rowsPerPage,
        pageNumber: value + 1,
        searchText: searchValue,
        sortBy: orderBy,
        sortOrder: orderNow,
      },
    };
    if (statusValue !== 'all') {
      payload.variables.status = statusValue;
    }
    dispatch(getAllNegativeReview(payload));
  }

  function handleChangeRowsPerPage(event) {
    setRowsPerPage(event.target.value);
    setPage(1);
    const payload = {
      query: negativeReviewsData.getAllNegativeReview,
      variables: {
        pageSize: event.target.value,
        pageNumber: 1,
        searchText: searchValue,
        sortBy: orderBy,
        sortOrder: orderNow,
      },
    };
    if (statusValue !== 'all') {
      payload.variables.status = statusValue;
    }
    dispatch(getAllNegativeReview(payload));
  }

  const commonGetData = async (searchValues = '') => {
    setSearchValue(searchValues);
    const payload = {
      query: negativeReviewsData.getAllNegativeReview,
      variables: {
        searchText: searchValues,
        pageNumber: page,
        pageSize: rowsPerPage,
        sortBy: orderBy,
        sortOrder: orderNow,
      },
    };
    if (statusValue !== 'all') {
      payload.variables.status = statusValue;
    }
    await dispatch(getAllNegativeReview(payload));
  };

  function handleSearchEvent(event) {
    setPage(1);
    const searchText = event.target.value;
    commonGetData(searchText);
  }

  const handleClearSearch = async () => {
    await setPage(1);
    await setSearchValue('');
    await commonGetData();
  };

  function handleStatusDropdownChange(event) {
    const statusData = event.target.value;
    setPage(1);
    setStatusValue(statusData);
    setLoading(true);
    const payload = {
      query: negativeReviewsData.getAllNegativeReview,
      variables: {
        pageSize: rowsPerPage,
        pageNumber: 1,
        searchText: searchValue,
        sortBy: orderBy,
        sortOrder: orderNow,
      },
    };
    if (statusData !== 'all') {
      payload.variables.status = statusData;
    }
    dispatch(getAllNegativeReview(payload));
    setTimeout(() => {
      setLoading(false);
    }, 800);
  }

  const handleSorting = (event) => {
    const sortColumnName = event;
    setOrderBy(sortColumnName);
    if (order === 'asc') {
      const sorted = [...data].sort((sortTypeA, sortTypeB) =>
        sortTypeA.col > sortTypeB.col ? 1 : 1
      );
      setData(sorted);
      setOrderNow('asc');
      setOrder('desc');
    }
    if (order === 'desc') {
      const sorted = [...data].sort((sortTypeA, sortTypeB) =>
        sortTypeA.col < sortTypeB.col ? 1 : 1
      );
      setData(sorted);
      setOrderNow('desc');
      setOrder('asc');
    }

    const payload = {
      query: negativeReviewsData.getAllNegativeReview,
      variables: {
        pageSize: 10,
        pageNumber: 1,
        searchText: searchValue,
        sortBy: sortColumnName,
        sortOrder: order,
      },
    };
    dispatch(getAllNegativeReview(payload));
  };

  function handleActionDropdownChange(event) {
    setSelectedRowAction(event.target.value);
  }

  const handleDelete = async (redirectNegativeReviewId) => {
    try {
      setRefreshIcon(true);
      const payload = {
        query: negativeReviewsData.deleteNegativeReview,
        variables: {
          redirectNegativeReviewId,
        },
      };
      await dispatch(deleteNegativeReview(payload));

      const payloadData = {
        query: negativeReviewsData.getAllNegativeReview,
        variables: {
          searchText: searchValue,
          pageNumber: page,
          pageSize: rowsPerPage,
          sortBy: orderBy,
          sortOrder: orderNow,
        },
      };
      await dispatch(getAllNegativeReview(payloadData));
      setRefreshIcon(false);
    } catch (error) {
      console.error('An error occurred:', error);
    }
  };

  return (
    <div className="p-20 lg:p-24">
      <div className="flex flex-1 w-full items-center justify-between mb-36">
        <div className="flex items-center">
          <Typography
            component={motion.span}
            initial={{ x: -20 }}
            animate={{ x: 0, transition: { delay: 0.2 } }}
            delay={300}
            className="sm:flex text-16 md:text-28 font-bold"
          >
            {t('negativeReviews.negativeReviewsList.title')}
          </Typography>
        </div>
        <div className="flex flex-1 items-center justify-center px-12">
          <Paper
            component={motion.div}
            initial={{ y: -20, opacity: 0 }}
            animate={{ y: 0, opacity: 1, transition: { delay: 0.2 } }}
            className="flex items-center w-full max-w-512 px-8 rounded-8 shadow-none"
          >
            <Input
              placeholder={t('navigation.search')}
              className="flex flex-1 mx-8 text-black text-16"
              disableUnderline
              name="searchText"
              fullWidth
              inputProps={{
                'aria-label': 'Search',
              }}
              onChange={handleSearchEvent}
              value={searchValue}
            />
            {searchValue ? (
              <Icon color="action" className="cursor-pointer" onClick={() => handleClearSearch()}>
                close
              </Icon>
            ) : (
              <Icon color="action">search</Icon>
            )}
          </Paper>
        </div>
        <div className="items-center justify-end flex">
          <Select
            className="rounded-full border border-darkgreen h-40 bg-white text-16 w-full min-w-192 leading-5 shadow-none capitalize"
            variant="filled"
            sx={{ '& .MuiFilledInput-input': { pt: '8px' } }}
            value={statusValue}
            fullWidth
            onChange={handleStatusDropdownChange}
            IconComponent={() => (
              <Icon className="text-20 align-middle absolute right-14 text-black ml-2">
                filter_list
              </Icon>
            )}
          >
            <MenuItem value="all" className="capitalize">
              {t('menuItems.all')}
            </MenuItem>
            {reviewStatus &&
              reviewStatus?.length > 0 &&
              reviewStatus?.map((item, index) => (
                <MenuItem value={item?.key} className="capitalize">
                  {item?.type}
                </MenuItem>
              ))}
          </Select>
        </div>
      </div>
      <div className="bg-white rounded-md relative shadow">
        {data?.length > 0 ? (
          <>
            <EnhancedTableToolbar numSelected={selected.length} />
            <TableContainer>
              <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle" size="medium">
                <EnhancedTableHead
                  numSelected={selected.length}
                  onSelectAllClick={handleSelectAllClick}
                  rowCount={negativeReviewListData?.length}
                />
                <TableBody>
                  {data &&
                    data?.length > 0 &&
                    data?.map((row, index) => {
                      const isItemSelected = isSelected(row.id);
                      return (
                        <TableRow
                          hover
                          className="border border-t-2 border-t-grey-100"
                          role="checkbox"
                          aria-checked={isItemSelected}
                          tabIndex={-1}
                          key={row.id}
                          selected={isItemSelected}
                        >
                          <TableCell
                            className="text-16 color-black font-semibold w-[10%]"
                            align="left"
                          >
                            {(page - 1) * rowsPerPage + index + 1}
                          </TableCell>
                          <TableCell className="text-16 color-black font-semibold" align="left">
                            {row?.name}
                          </TableCell>
                          <TableCell
                            className="text-16 color-black max-w-sm  break-all font-semibold"
                            align="left"
                          >
                            {row.businessName}
                          </TableCell>
                          <TableCell className="text-16 color-black font-semibold" align="left">
                            {row?.phoneNumber && (
                              <>
                                {row?.countryCode.charAt(0) !== '+' && '+'}
                                {row?.countryCode} {row.phoneNumber}{' '}
                              </>
                            )}
                          </TableCell>
                          <TableCell className="text-16 color-black font-semibold" align="left">
                            {moment(row?.cts).format('MMM DD, YYYY')}
                          </TableCell>
                          <TableCell className="text-16 color-black font-semibold" align="left">
                            {row.status === 'request_for_Call' ? (
                              <span className="text-orange">Request for call</span>
                            ) : (
                              <span
                                className={`capitalize ${
                                  row.status === 'solved' ? 'text-green' : 'text-blue'
                                }`}
                              >
                                {row.status}
                              </span>
                            )}
                          </TableCell>
                          <TableCell className="text-16 color-black font-semibold" align="left">
                            <Select
                              className="rounded-md border border-grey-100 font-semibold text-grey-600 h-28 bg-grey-100 text-16 w-96 min-h-0 p-0 leading-4 shadow-none pt-0"
                              sx={{ '& .MuiFilledInput-input': { pt: '14px' } }}
                              variant="filled"
                              value={selectedRow === row ? selectedRowAction : 'action'}
                              fullWidth
                              onChange={handleActionDropdownChange}
                            >
                              <MenuItem
                                value="action"
                                className="capitalize mx-5 rounded-md"
                                disabled
                              >
                                {t('common.action')}
                                {refreshIcon && row.id === selectedReviewId && (
                                  <CircularProgress
                                    size={20}
                                    className="text-darkgreen absolute mx-auto right-5"
                                  />
                                )}
                              </MenuItem>
                              <MenuItem
                                value="view"
                                className="capitalize hover:bg-darkgreen-100 hover:text-darkgreen mx-5 rounded-md p-6"
                                onClick={() => redirectNegativeReviewsDetails(row.id)}
                              >
                                {t('common.viewDetails')}
                              </MenuItem>
                              <MenuItem
                                value="delete"
                                className="capitalize hover:bg-darkgreen-100 hover:text-darkgreen mx-5 rounded-md p-6"
                                onClick={() => {
                                  handleDelete(row.id);
                                  setSelectedReviewId(row.id);
                                }}
                              >
                                {t('common.delete')}
                              </MenuItem>
                            </Select>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>
          </>
        ) : (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1, transition: { delay: 0.1 } }}
            className="flex flex-1 items-center justify-center h-full"
          >
            <Typography color="textSecondary" variant="h5" className="text-16 py-12">
              {t('negativeReviews.negativeReviewsList.messages.noNegativeReviews')}
            </Typography>
          </motion.div>
        )}
      </div>
      {negativeReviewListData?.length > 0 && (
        <TablePagination
          sx={{
            '.MuiInputBase-root': {
              lineHeight: 'inherit',
              padding: 0,
            },
          }}
          component="div"
          count={totalUsers}
          rowsPerPage={rowsPerPage}
          page={page - 1}
          backIconButtonProps={{
            'aria-label': 'Previous Page',
          }}
          nextIconButtonProps={{
            'aria-label': 'Next Page',
          }}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      )}
    </div>
  );
}

export default withReducer('negativeReviewsReducer', reducer)(NegativeReviews);
