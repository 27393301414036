import PropTypes from 'prop-types';
import withReducer from 'app/store/withReducer';
import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Input from '@mui/material/Input';
import Paper from '@mui/material/Paper';
import Icon from '@mui/material/Icon';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import DeleteIcon from '@mui/icons-material/Delete';
import FuseLoading from '@fuse/core/FuseLoading';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import { Controller, useForm } from 'react-hook-form';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import CircularProgress from '@mui/material/CircularProgress';
import history from '@history';
import { useSnackbar } from 'notistack';
import * as yup from 'yup';
import _ from '@lodash';
import ReactSelect from 'react-select';
import { FixedSizeList as List } from 'react-window';
import { Autocomplete } from '@mui/material';
import { makeStyles } from '@mui/styles';
import TabletVideosDetails from './TabletVideosDetails';
import tabletVideosData from '../../query/tabletVideos';
import queryData from '../../query/common';
import {
  getBusinessTypeVideoDetailsForAdmin,
  openAddVideoDialog,
  closeAddVideoDialog,
  reSetTabletVideos,
  getBusinessType,
  addVideoByBusinessType,
} from './store/tabletVideosSlice';
import reducer from './store';
import { getPhysicalBusinessTypeData } from '../../../store/userSlice';

const defaultValues = {
  businessType: '',
  businessAttribute: '',
};

const schema = yup.object().shape({
  // businessType: yup.string().required('Business Attribute Name is required'),
  // businessAttribute: yup.string().required('Business Attribute Icon is required'),
});

function EnhancedTableToolbar(props) {
  const { numSelected } = props;
  if (numSelected > 0) {
    return (
      <Toolbar className="absolute left-0 right-0 -top-80 bg-black text-white z-10 rounded-md">
        {numSelected > 0 ? (
          <Typography
            sx={{ flex: '1 1 100%' }}
            color="inherit"
            variant="subtitle1"
            component="div"
            className="text-white"
          >
            {numSelected} Selected
          </Typography>
        ) : (
          ''
        )}

        {numSelected > 0 ? (
          <Tooltip title="Delete">
            <IconButton className="text-white">
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        ) : (
          ''
        )}
      </Toolbar>
    );
  }
}

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};
const useStyles = makeStyles(() => ({
  root: {
    '& .MuiAutocomplete-tag': {
      backgroundColor: '#0BA861',
      color: 'white',
      borderRadius: '8px',
    },
    '& .MuiChip-deleteIcon': {
      color: 'white',
    },
  },
}));

function TabletVideos() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const classes = useStyles();

  const tabletVideosValueData = useSelector(
    ({ tabletVideosReducer }) =>
      tabletVideosReducer?.tabletVideos?.getBusinessTypeVideoDetailsForAdminData?.data
        ?.getBusinessTypeVideoDetailsForAdmin?.data || ''
  );

  const totalTabletVideo = useSelector(
    ({ tabletVideosReducer }) =>
      tabletVideosReducer?.tabletVideos?.getBusinessTypeVideoDetailsForAdminData?.data
        ?.getBusinessTypeVideoDetailsForAdmin?.totalCount || 0
  );

  const addVideoByBusinessTypeStatus = useSelector(
    ({ tabletVideosReducer }) =>
      tabletVideosReducer?.tabletVideos?.addVideoByBusinessTypeData?.data?.addVideoByBusinessType
  );

  const appDialog = useSelector(
    ({ tabletVideosReducer }) => tabletVideosReducer.tabletVideos.videoDialog
  );
  const physicalBusinessTypeList = useSelector(getPhysicalBusinessTypeData);

  const [loading, setLoading] = useState(true);
  const [loadingPage, setLoadingPage] = useState(false);
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchValue, setSearchValue] = useState('');
  const [data, setData] = useState(tabletVideosValueData);
  const [refreshIcon, setRefreshIcon] = useState(false);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [imageUpload, setImageUpload] = useState();
  const [imagePreviewName, setImagePreviewName] = useState();
  const [noImgMsg, setNoImgMsg] = useState('');
  const [businessList, setBusinessList] = useState();
  const [businessName, setBusinessName] = useState();
  const [businessTypeId, setBusinessTypeId] = useState(0);
  const [inputValue] = useState();
  const { control, watch, reset, handleSubmit, formState, getValues, setError } = useForm({
    mode: 'onChange',
    defaultValues,
    resolver: yupResolver(schema),
  });
  const { isValid, dirtyFields, errors } = formState;

  const CustomMenuList = (props) => {
    const itemHeight = 35;
    const { options, children, maxHeight, getValue } = props;
    const [value] = getValue();
    const initialOffset = options.indexOf(value) * itemHeight;
    if (options.length > 0) {
      return (
        <div>
          <List
            height={maxHeight}
            itemCount={children.length}
            itemSize={itemHeight}
            initialScrollOffset={initialOffset}
          >
            {({ index, style }) => (
              <div style={style} className="bg-white relative">
                {children[index]}
              </div>
            )}
          </List>
        </div>
      );
    }
    return <div className="p-16"> {t('tabletVideos.tabletVideoDetail.loading')}</div>;
  };
  /**
   * Initialize Dialog with Data
   */
  const initDialog = useCallback(() => {
    /**
     * Dialog type: 'new'
     */
    if (appDialog.type === 'new') {
      reset({
        ...defaultValues,
        ...appDialog.data,
      });
    }
  }, [appDialog.data, appDialog.type, reset]);

  /**
    /**
     * On Dialog Open
     */
  useEffect(() => {
    if (appDialog.props.open) {
      initDialog();
    }
  }, [appDialog.props.open, dispatch, initDialog]);

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = tabletVideosValueData?.map((n) => n.id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;

  useEffect(() => {
    const payload = {
      query: tabletVideosData.getBusinessTypeVideoDetailsForAdmin,
      variables: { pageNumber: 1, pageSize: 10, searchText: '' },
    };
    dispatch(getBusinessTypeVideoDetailsForAdmin(payload));
    return () => {
      dispatch(reSetTabletVideos());
    };
  }, [dispatch]);

  useEffect(() => {
    if (tabletVideosValueData && tabletVideosValueData?.length) {
      setData(tabletVideosValueData);
    } else {
      setData();
    }
  }, [tabletVideosValueData]);

  useEffect(() => {
    if (tabletVideosValueData || tabletVideosValueData === null) {
      setLoading(false);
    } else {
      setLoading(true);
    }
  }, [tabletVideosValueData]);

  useEffect(() => {
    if (physicalBusinessTypeList?.length > 0) {
      setBusinessList(
        physicalBusinessTypeList?.map((idx) => ({
          value: idx.id,
          label: idx.bType,
        }))
      );
    } else {
      setBusinessList([]);
    }
  }, [physicalBusinessTypeList]);

  useEffect(() => {
    if (addVideoByBusinessTypeStatus && addVideoByBusinessTypeStatus.status === 400) {
      enqueueSnackbar(addVideoByBusinessTypeStatus.message, {
        variant: 'error',
        autoHideDuration: 3000,
      });
      closeSnackbar(3000);
      setRefreshIcon(false);
      setImageUpload();
      setImagePreviewName();
    } else if (addVideoByBusinessTypeStatus && addVideoByBusinessTypeStatus.status === 200) {
      enqueueSnackbar(addVideoByBusinessTypeStatus.message, {
        variant: 'success',
        autoHideDuration: 3000,
      });
      closeSnackbar(3000);
      setBusinessTypeId();
      setRefreshIcon(false);
      setImageUpload();
      setImagePreviewName();
      dispatch(closeAddVideoDialog());
    }
  }, [addVideoByBusinessTypeStatus, enqueueSnackbar, closeSnackbar, dispatch]);

  function handleChangePage(event, value) {
    setPage(value + 1);
    setLoadingPage(true);
    const payload = {
      query: tabletVideosData.getBusinessTypeVideoDetailsForAdmin,
      variables: {
        pageSize: rowsPerPage,
        pageNumber: value + 1,
        searchText: searchValue,
      },
    };
    dispatch(async (dispatchValue) => {
      await dispatchValue(getBusinessTypeVideoDetailsForAdmin(payload));
      setLoadingPage(false);
    });
  }

  function handleSelectBusinessType(event) {
    setBusinessTypeId(event.value);
  }

  function handleChangeRowsPerPage(event) {
    setLoadingPage(true);
    setRowsPerPage(event.target.value);
    setPage(1);
    const payload = {
      query: tabletVideosData.getBusinessTypeVideoDetailsForAdmin,
      variables: {
        pageSize: event.target.value,
        pageNumber: 1,
        searchText: searchValue,
      },
    };
    dispatch(async (dispatchValue) => {
      await dispatchValue(getBusinessTypeVideoDetailsForAdmin(payload));
      setLoadingPage(false);
    });
  }

  function EnhancedTableHead(props) {
    const { onSelectAllClick, numSelected, rowCount } = props;

    return (
      <TableHead className="bg-grey-50 rounded-md">
        <TableRow>
          {/* <TableCell padding="checkbox" className="rounded-tl-md">
             <Checkbox
               color="primary"
               indeterminate={numSelected > 0 && numSelected < rowCount}
               checked={rowCount > 0 && numSelected === rowCount}
               onChange={onSelectAllClick}
               inputProps={{
                 'aria-label': 'select all User',
               }}
             />
           </TableCell> */}
          <TableCell className="text-16 font-medium" align="left">
            <TableSortLabel hideSortIcon> {t('tabletVideos.column.srNo')}</TableSortLabel>
          </TableCell>
          {/* <TableCell
               className="text-16 font-medium"
               align="left"
             >
               <TableSortLabel
                 hideSortIcon
                 direction={order}
                 onClick={() => {
                   handleSorting('id');
                 }}
               >
                 Index
               </TableSortLabel>
             </TableCell> */}
          <TableCell className="text-16 font-medium" align="left">
            <TableSortLabel hideSortIcon>{t('tabletVideos.column.BusinessType')}</TableSortLabel>
          </TableCell>
          <TableCell className="text-16 font-medium" align="left">
            <TableSortLabel hideSortIcon>{t('tabletVideos.column.totalVideo')}</TableSortLabel>
          </TableCell>
          <TableCell className="text-16 font-medium" align="left">
            <TableSortLabel>{t('tabletVideos.column.totalUsers')}</TableSortLabel>
          </TableCell>
          <TableCell className="text-16 font-medium rounded-tr-md">
            <TableSortLabel hideSortIcon>{t('common.action')}</TableSortLabel>
          </TableCell>
        </TableRow>
      </TableHead>
    );
  }

  function redirectTabletVideoDetailsRoutes(id, name) {
    return history.push({
      pathname: `/tablet-video-details/${id}/${name}`,
    });
  }

  if (loading) {
    return <FuseLoading />;
  }

  function closeComposeDialog() {
    return dispatch(closeAddVideoDialog());
  }

  /**
   * Remove Event
   */
  function handleRemove() {
    const fileInput = document.getElementById('button-file');
    if (fileInput) {
      fileInput.value = '';
    }
    closeComposeDialog();
    reset(defaultValues);
    setImageUpload();
    setImagePreviewName();
  }
  const onSubmit = async (value) => {
    setRefreshIcon(true);
    try {
      const payload = {
        query: tabletVideosData.addVideoByBusinessType,
        variables: {
          businessTypeId: parseInt(businessTypeId, 10),
          file: null,
          videoTitle: value.businessSubTitle,
        },
      };
      const dataObj = {
        payload,
        fileData: imageUpload,
      };
      await dispatch(addVideoByBusinessType(dataObj));
      const payloadAbout = {
        query: tabletVideosData.getBusinessTypeVideoDetailsForAdmin,
        variables: {
          pageNumber: page,
          pageSize: rowsPerPage,
          searchText: searchValue,
        },
      };
      dispatch(getBusinessTypeVideoDetailsForAdmin(payloadAbout));
    } catch (error) {
      console.error('Error occurred:', error);
    }
  };

  function handleDrop(e) {
    e.preventDefault();
    const file = e.dataTransfer.files[0];
    if (!file.type.startsWith('video/')) {
      enqueueSnackbar(`${t('tabletVideos.tabletVideoDetail.fileTypeError')}`, {
        variant: 'error',
        autoHideDuration: 2000,
      });
      return;
    }
    const reader = new FileReader();

    reader.readAsBinaryString(file);

    reader.onload = async () => {
      setImageUpload(file);
      setImagePreviewName(file?.name);
    };
    setNoImgMsg('');
  }

  function handleFileChange(e) {
    const file = e.target.files[0];
    if (!file.type.startsWith('video/')) {
      enqueueSnackbar(`${t('tabletVideos.tabletVideoDetail.fileTypeError')}`, {
        variant: 'error',
        autoHideDuration: 2000,
      });
      return;
    }
    const reader = new FileReader();
    reader?.readAsBinaryString(file);

    reader.onload = async () => {
      setImageUpload(file);
      setImagePreviewName(file?.name);
    };
    setNoImgMsg('');
  }

  function commonGetData(searchValues = '') {
    setSearchValue(searchValues);
    const payload = {
      query: tabletVideosData.getBusinessTypeVideoDetailsForAdmin,
      variables: {
        searchText: searchValues,
        pageNumber: page,
        pageSize: rowsPerPage,
      },
    };
    dispatch(getBusinessTypeVideoDetailsForAdmin(payload));
  }

  function handleSearchEvent(event) {
    const searchText = event.target.value;
    commonGetData(searchText);
  }

  const handleClearSearch = async () => {
    await setPage(1);
    await setSearchValue('');
    await commonGetData();
  };

  return (
    <div className="p-20 lg:p-24">
      {loadingPage && (
        <div className="loader-container fixed top-0 left-0 w-full h-full flex justify-center items-center">
          <div className="loader h-40 w-40 rounded-full border-4 border-solid border-white border-t-darkgreen" />
        </div>
      )}
      <div className="flex flex-1 w-full items-center justify-between mb-36">
        <div className="flex items-center">
          <Typography
            component={motion.span}
            initial={{ x: -20 }}
            animate={{ x: 0, transition: { delay: 0.2 } }}
            delay={300}
            className="sm:flex text-16 md:text-28 font-bold"
          >
            {t('tabletVideos.title')}
          </Typography>
        </div>
        <div className="flex flex-1 items-center justify-center px-12">
          <Paper
            component={motion.div}
            initial={{ y: -20, opacity: 0 }}
            animate={{ y: 0, opacity: 1, transition: { delay: 0.2 } }}
            className="flex items-center w-full max-w-512 px-8 rounded-8 shadow-none"
          >
            <Input
              placeholder={t('tabletVideos.tabletVideoDetail.placeHolder')}
              className="flex flex-1 mx-8 text-black text-16"
              disableUnderline
              name="searchText"
              fullWidth
              inputProps={{
                'aria-label': 'Search',
              }}
              onChange={handleSearchEvent}
              value={searchValue}
            />
            {searchValue ? (
              <Icon color="action" className="cursor-pointer" onClick={() => handleClearSearch()}>
                close
              </Icon>
            ) : (
              <Icon color="action">search</Icon>
            )}
          </Paper>
        </div>
        <div className="items-center justify-end flex">
          <Button
            variant="contained"
            color="secondary"
            className="w-full md:text-18 font-medium disabled:text-black"
            aria-label="Add Business Type"
            onClick={() => {
              dispatch(openAddVideoDialog());
            }}
          >
            <Icon className="text-16">add</Icon>
            <span className="hidden sm:flex text-white">&nbsp; {t('tabletVideos.addVideo')}</span>
            <span className="flex sm:hidden">&nbsp; {t('tabletVideos.new')}</span>
          </Button>
        </div>
      </div>
      <div className="bg-white rounded-md relative shadow">
        {tabletVideosValueData?.length > 0 ? (
          <>
            <EnhancedTableToolbar numSelected={selected.length} />
            <TableContainer>
              <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle" size="medium">
                <EnhancedTableHead
                  numSelected={selected.length}
                  onSelectAllClick={handleSelectAllClick}
                  rowCount={tabletVideosValueData?.length}
                />
                <TableBody>
                  {data &&
                    data.length > 0 &&
                    data.map((row, index) => {
                      const isItemSelected = isSelected(row.id);
                      const labelId = `enhanced-table-checkbox-${index}`;

                      return (
                        <TableRow
                          hover
                          className="border border-t-2 border-t-grey-100"
                          role="checkbox"
                          aria-checked={isItemSelected}
                          tabIndex={-1}
                          key={row.id}
                          selected={isItemSelected}
                        >
                          {/* <TableCell
                            padding="checkbox"
                            onClick={(event) => handleClick(event, row.id)}
                            sx={{ cursor: 'pointer' }}
                          >
                            <Checkbox
                              color="primary"
                              checked={isItemSelected}
                              inputProps={{
                                'aria-labelledby': labelId,
                              }}
                            />
                          </TableCell> */}
                          {/* <TableCell
                            component="th"
                            className="text-16 color-black font-semibold"
                            id={labelId}
                            scope="row"
                            padding="none"
                          >
                            {index + 1}+{rowsPerPage * page}
                          </TableCell> */}
                          <TableCell className="text-16 color-black font-semibold" align="left">
                            {(page - 1) * rowsPerPage + index + 1}
                          </TableCell>
                          <TableCell className="text-16 color-black font-semibold" align="left">
                            {row?.bType}
                          </TableCell>
                          <TableCell className="text-16 color-black font-semibold" align="left">
                            {row?.videoCount}
                          </TableCell>
                          <TableCell className="text-16 color-black font-semibold" align="left">
                            {row?.businessCount}
                          </TableCell>
                          <TableCell className="text-16 color-black font-semibold" align="left">
                            <span
                              className="inline-block bg-darkgreen-100 rounded text-darkgreen py-4 px-12 text-16 font-semibold cursor-pointer hover:bg-darkgreen hover:text-white"
                              aria-hidden="true"
                              onClick={() => {
                                setTimeout(() => {
                                  redirectTabletVideoDetailsRoutes(row.businessTypeId, row?.bType);
                                }, 500);
                              }}
                            >
                              {t('common.viewDetails')}
                            </span>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>
          </>
        ) : (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1, transition: { delay: 0.1 } }}
            className="flex flex-1 items-center justify-center h-full"
          >
            <Typography color="textSecondary" variant="h5" className="text-16 py-12">
              {t('tabletVideos.noData')}
            </Typography>
          </motion.div>
        )}
      </div>
      {tabletVideosValueData?.length > 0 && (
        <TablePagination
          sx={{
            '.MuiInputBase-root': {
              lineHeight: 'inherit',
              padding: 0,
            },
          }}
          component="div"
          count={totalTabletVideo}
          rowsPerPage={rowsPerPage}
          page={page - 1}
          backIconButtonProps={{
            'aria-label': 'Previous Page',
          }}
          nextIconButtonProps={{
            'aria-label': 'Next Page',
          }}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      )}
      <Dialog
        classes={{
          root: classes.root,
          paper: 'm-24 p-28 business-type-dropdown',
        }}
        {...appDialog.props}
        onClose={closeComposeDialog}
        fullWidth
        maxWidth="xs"
      >
        <DialogTitle className="p-0 mb-24">
          <Icon
            onClick={handleRemove}
            className="text-24 absolute top-12 right-12 text-grey-400 font-normal cursor-pointer hover:text-gray-700 focus:text-gray-700"
          >
            cancel
          </Icon>
          <Typography className="text-20 font-semibold text-black border-b-2 border-solid border-darkgreen-100 w-full pb-10">
            Add Videos
          </Typography>
        </DialogTitle>

        <form
          noValidate
          onSubmit={handleSubmit(onSubmit)}
          className="flex flex-col md:overflow-hidden"
        >
          <DialogContent
            className={`${classes.attDialog} p-0 mb-24 business-type-dropdown-content`}
          >
            <div className="block mb-24">
              <InputLabel id="businessType" className="text-16 font-medium mb-12 text-grey-900">
                {t('business.businessList.column.businessType')}*
              </InputLabel>
              <ReactSelect
                labelId="businessType"
                id="businessType"
                placeholder={t('tabletVideos.formFields.businessSubTitle.selectPlaceholder')}
                name="businessType"
                inputValue={inputValue}
                onChange={(e) => {
                  handleSelectBusinessType(e);
                }}
                value={businessList.find((op) => {
                  return op.value === businessTypeId;
                })}
                options={businessList}
                components={{
                  MenuList: CustomMenuList,
                }}
              />
              {/* {errors.businessType && (
                <p className="text-red -mt-24 absolute">{errors.businessType.message}</p>
              )} */}
            </div>
            <div className="block mb-24">
              <InputLabel
                htmlFor="businessSubTitle"
                className="text-16 font-medium mb-12 text-grey-900"
              >
                {t('tabletVideos.formFields.businessSubTitle.name')}
              </InputLabel>
              <Controller
                control={control}
                name="businessSubTitle"
                render={({ field }) => (
                  <TextField
                    {...field}
                    placeholder={t('tabletVideos.formFields.businessSubTitle.name')}
                    size="small"
                    id="businessSubTitle"
                    error={!!errors.businessSubTitle}
                    helperText={errors?.businessSubTitle?.message}
                    variant="outlined"
                    required
                    fullWidth
                  />
                )}
              />
            </div>
            <InputLabel
              htmlFor="businessSubTitle"
              className="text-16 font-medium mb-12 text-grey-900"
            >
              {t('tabletVideos.tabletVideoDetail.add')}
            </InputLabel>
            <motion.div className="block mb-24 bg-darkgreen-100 p-14 rounded-8">
              <Paper
                className="relative w-full h-full rounded-8 shadow-none border-1 border-dashed border-darkgreen p-20 bg-darkgreen-100 text-center"
                role="button"
                onDrop={handleDrop}
                onDragOver={(e) => e.preventDefault()}
              >
                <span className="block text-center">
                  <img
                    src="/assets/images/admin-icon/video-icon.png"
                    className="mx-auto mb-12"
                    width="61"
                    height="64"
                    alt="..."
                  />
                </span>
                <div className="block font-medium">
                  {imagePreviewName || `${t('tabletVideos.tabletVideoDetail.fileTypeError')}`}
                </div>
                <span className="block my-6">or</span>
                <div className="inline-block">
                  <label htmlFor="button-file">
                    <input
                      accept="video/*"
                      className="hidden"
                      id="button-file"
                      type="file"
                      onChange={handleFileChange}
                    />
                    <Button
                      size="small"
                      className="rounded-xxl block text-14 font-medium px-24 py-5 w-auto"
                      variant="contained"
                      color="secondary"
                      component="span"
                    >
                      {t('tabletVideos.tabletVideoDetail.browseFile')}
                    </Button>
                  </label>
                </div>

                {noImgMsg && <span className="text-red block mt-16">{noImgMsg}</span>}
              </Paper>
            </motion.div>
          </DialogContent>
          <DialogActions className="p-0 justify-start">
            <Button
              className="md:text-18 font-semibold disabled:text-black rounded-md"
              variant="contained"
              color="secondary"
              type="submit"
              disabled={businessTypeId === 0 || refreshIcon || !imageUpload}
            >
              {t('tabletVideos.tabletVideoDetail.add')}
              {refreshIcon && (
                <CircularProgress size={24} className="text-darkgreen absolute mx-auto" />
              )}
            </Button>

            <Button
              className="md:text-18 font-semibold bg-white text-darkgreen border border-solid border-darkgreen rounded-md hover:text-white hover:bg-darkgreen"
              variant="contained"
              color="primary"
              type="button"
              onClick={handleRemove}
            >
              {t('common.cancel')}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
}

export default withReducer('tabletVideosReducer', reducer)(TabletVideos);
