const getQuickChatDefaultAvatar = ` 
query ($businessId: Int){
	getQuickChatDefaultAvatar (businessId: $businessId){
          message
          status
          data{
            id
            cts
            avatar
            isActive
            isBusinessProfileUrl
          }      
	}
  }  
`;

const checkQuickChatDomain = ` 
query($domain: String!){
	checkQuickChatDomain(domain: $domain) {
          message
          status
          data{
            domainAvailable
            suggestionDomain
          }      
	}
  }  
`;

const createQuickChatAndUpdate = ` 
mutation($avatar: String, $avatarImageFile: Upload, $domain: String!, $headerColor: String!, $name: String!, $userBusinessId: Int!, $isBusinessProfileUrl: Boolean){
	createQuickChatAndUpdate(avatar: $avatar, avatarImageFile: $avatarImageFile, domain: $domain, headerColor: $headerColor, name: $name, userBusinessId: $userBusinessId, isBusinessProfileUrl: $isBusinessProfileUrl) {
          message
          status  
	}
  }  
`;

const getQuickChat = ` 
query($businessId: Int, $domain: String){
	getQuickChat(businessId: $businessId, domain: $domain) {
          message
          status 
          data{
            avatar
            domain
            headerColor
            id
            isActive
            name
            userBusinessesId
            quickChatSuggestion
          }
	}
  }  
`;

const getAllQuickChatWithLastMessage = ` 
query($pageNumber: Int, $pageSize: Int, $quickChatId: Int!, $status: [String!], $sortBy: String, $sortOrder: OrderDirection) {
  getAllQuickChatWithLastMessage(
    quickChatId: $quickChatId
    pageSize:$pageSize
    pageNumber:$pageNumber
    status:$status
    sortBy:$sortBy
    sortOrder: $sortOrder
  ) {
    status
    message
    totalCount
    data {
      id
      ipAddress
      quickChatId
      favourite
      unReadCount
      status
      chatResolveStatus
      quickChatRoomMessages {
        quickChatRoomId
        message
        messageType
        status
        uts
        cts
      }
    }
  }
} 
`;

const getAllQuickChatRoomMessage = ` 
query($ipAddress: String!, $quickChatId: Int!, $pageNumber: Int, $pageSize: Int) {
  getAllQuickChatRoomMessage(
    ipAddress:$ipAddress
    quickChatId: $quickChatId
    pageNumber:$pageNumber
    pageSize: $pageSize
  ) {
    status
    message
    status
    uts
    data{
      cts
      message
      messageType
      quickChatRoomId
      status
      uts
      id
    }
  }
} 
`;

const getAllQuickChatRoomMessageUsingQuickChatId = ` 
query($pageNumber: Int, $pageSize: Int, $quickChatRoomId: Int!, $sortBy: String, $sortOrder: OrderDirection) {
  getAllQuickChatRoomMessageUsingQuickChatId(
    pageNumber:$pageNumber
    pageSize: $pageSize
    quickChatRoomId: $quickChatRoomId
    sortBy: $sortBy
    sortOrder: $sortOrder
  ) {
    status
    message
    data {
      message
      messageType
      quickChatRoomId
      id
      status
      cts
      uts
    }
  }
} 
`;

const sendQuickChatMessageByOwner = ` 
mutation($message: String!, $quickChatRoomId: Int!) {
  sendQuickChatMessageByOwner(
    message:$message
    quickChatRoomId: $quickChatRoomId
  ) {
    status
    message
  }
} 
`;

const saveQuickChatClientRating = `mutation ($quickChatId: Int!, $ipAddress: String!, $rating: Int!) {
  saveQuickChatClientRating(
    quickChatId: $quickChatId
    ipAddress: $ipAddress
    rating: $rating
  ) {
    status
    message
  }
}
`;

const addQuickChatFavorite = `mutation ($isFavorite: Boolean!, $quickChatRoomId: Int!) {
  addQuickChatFavorite(
    isFavorite: $isFavorite
    quickChatRoomId: $quickChatRoomId
  ) {
    status
    message
  }
}
`;
const startAndStopQuickChatServices = `mutation($isActive: Boolean, $userBusinessId: Int!, $isWhatsAppSupport: Boolean) {
  startAndStopQuickChatServices(isActive: $isActive, userBusinessId: $userBusinessId, isWhatsAppSupport: $isWhatsAppSupport) {
    status
    message
  }
}
`;

const getQuickChatDetails = `query ($businessId: Int){
  getQuickChatDetails(businessId: $businessId) {
    status
    message
    data {
      id
      name
      userBusinessesId
      isActive
      isWhatsAppSupport
      avatar
      domain
      headerColor
    }
  }
}`;

const getQuickChatCountAndInsightsMatrix = `query($businessId: Int!, $botTypeId: Int!){
  getQuickChatCountAndInsightsMatrix(businessId: $businessId, botTypeId: $botTypeId){
    status
    message
    data {
      name
      count
    }
  }
}
`;

const updateAppSetUpCard = `mutation ( $userBusinessId: Int!, $cardType: String!, $productType: String!){
  updateAppSetUpCard( userBusinessId: $userBusinessId, cardType: $cardType, productType: $productType) {
    status
    message
  }
}`;

const getChatRoomResolveStatus = `query  {
  getChatRoomResolveStatus {
    data{
      key
      value
    }
    message
    status
  }
}`;

const updateQuickChatResolveStatus = `mutation ($quickChatRoomId:Int!
 , $status:String!){
  updateQuickChatResolveStatus(quickChatRoomId:$quickChatRoomId, status:$status)
  {
    message
    status
  }
}`;

const getQuickChatHumanInteraction = `query ($quickChatId:Int!){
 getQuickChatHumanInteraction(quickChatId:$quickChatId)
 {
   message
   status
   data {
      isHumanInteraction
    }
 }
}`;

const setQuickChatHumanInteraction = `mutation ($quickChatId:Int!, $status: Boolean!){
  setQuickChatHumanInteraction(quickChatId:$quickChatId, status: $status)
  {
    message
    status
  }
 }`;

const quickChatQuery = {
  getChatRoomResolveStatus,
  getQuickChatDefaultAvatar,
  checkQuickChatDomain,
  createQuickChatAndUpdate,
  getQuickChat,
  getAllQuickChatWithLastMessage,
  getAllQuickChatRoomMessage,
  getAllQuickChatRoomMessageUsingQuickChatId,
  sendQuickChatMessageByOwner,
  saveQuickChatClientRating,
  addQuickChatFavorite,
  startAndStopQuickChatServices,
  getQuickChatDetails,
  getQuickChatCountAndInsightsMatrix,
  updateAppSetUpCard,
  updateQuickChatResolveStatus,
  getQuickChatHumanInteraction,
  setQuickChatHumanInteraction,
};

export default quickChatQuery;
