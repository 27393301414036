import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import ReactApexChart from 'react-apexcharts';
import { useTranslation } from 'react-i18next';
import { selectDashboardData } from 'src/app/store/userSlice';
import metricsData from '../../query/metrics';
import { formatDateWithTimeZone, getDataToServer, handleApiRequest } from '../../common/common';
import NoData from './noDataScreen';

function ReviewsOnGoogleVsYelp(props) {
  const { reviewPlatformId } = props;
  const [selectedReviewSentimentId, setSelectedReviewSentimentId] = useState(7);
  const [overAll, setOverAll] = useState([]);
  const userCurrentBusinessDetails = useSelector(selectDashboardData);

  const [newGraphData, setNewGraphData] = useState([]);

  useEffect(() => {
    try {
      const fetchData = async () => {
        if (reviewPlatformId) {
          const payload = {
            query: metricsData.getLineChartData,
            variables: {
              businessId: parseInt(userCurrentBusinessDetails?.id, 10),
            },
          };
          const result = await handleApiRequest(payload);
          if (result?.getLineChartData?.status === 200) {
            const data = result?.getLineChartData?.data || [];
            if (data) {
              setNewGraphData([
                {
                  name: 'Google',
                  data: data?.lineChartJson?.google.map((item) => item.reviewrating),
                },
                {
                  name: 'Yelp',
                  data: data?.lineChartJson?.yelp.map((item) => item.reviewrating),
                },
              ]);
              setOverAll(data?.lineChartJson?.google.map((item) => item.timestamp));
            }
          }
        }
      };
      fetchData();
    } catch (error) {
      console.error('An error occurred:', error);
    }
  }, [userCurrentBusinessDetails?.id, selectedReviewSentimentId, reviewPlatformId]);

  const getChartSimple = (data, categories, index, types) => {
    const chart = {
      series: data,
      options: {
        grid: {
          strokeDashArray: 3,
          show: true,
        },
        chart: {
          events: {
            mounted: (charts) => {
              charts.windowResizeHandler();
            },
          },
          type: types,
          stacked: false,
          toolbar: {
            show: false,
          },
        },
        plotOptions: {
          bar: {
            borderRadius: 3,
            columnWidth: '60px',
          },
        },
        stroke: {
          curve: 'smooth',
          width: 2,
        },
        markers: {
          size: 4,
          shape: 'circle',
        },
        legend: {
          show: true,
          position: 'bottom',
          markers: {
            radius: 12,
            width: 12,
            height: 12,
            fillColors: ['#0BA861', '#FFCE56'], // Ensure colors match your series
          },
          labels: {
            colors: ['#000000'],
          },
        },
        colors: ['#0BA861', '#FFCE56'],
        fill: {
          opacity: 1,
        },
        dataLabels: {
          enabled: false,
        },
        xaxis: {
          axisTicks: {
            show: false,
          },
          categories,
          labels: {
            offsetY: 0,
          },
          min: 0,
          max: categories.length > 7 ? 7 : categories.length - 1,
        },
        yaxis: {
          min: 0,
          max: 100,
          tickAmount: 5,
          labels: {
            align: 'left',
            maxWidth: 350,
            position: 'left',
            offsetX: -15,
            formatter(val) {
              return `${val.toFixed(1)}%`;
            },
            style: {
              fontSize: '14px',
            },
          },
        },
      },
    };

    return (
      <div
        id={`chart${index}`}
        className="flex flex-col flex-auto w-full"
        style={{ overflowX: 'auto', overflowY: 'hidden' }}
      >
        <ReactApexChart
          options={chart.options}
          series={chart.series}
          type={chart.options.chart.type}
          height="300px"
          width={categories.length > 7 ? `${categories.length * 60}px` : '100%'}
        />
      </div>
    );
  };

  return (
    <div className="relative">
      {overAll ? (
        <>
          <div>
            {getChartSimple(
              newGraphData,
              overAll?.map((period) => formatDateWithTimeZone(period, 'MMM YYYY')),
              5,
              'line'
            )}
          </div>
        </>
      ) : (
        <NoData />
      )}
    </div>
  );
}

export default ReviewsOnGoogleVsYelp;
