import withReducer from 'app/store/withReducer';
import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { motion } from 'framer-motion';
import TablePagination from '@mui/material/TablePagination';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import { useTranslation } from 'react-i18next';
import Input from '@mui/material/Input';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import CircularProgress from '@mui/material/CircularProgress';
import InputLabel from '@mui/material/InputLabel';
import { useSnackbar } from 'notistack';
import { useForm } from 'react-hook-form';
import { makeStyles } from '@mui/styles';
import { Autocomplete } from '@mui/material';
import * as yup from 'yup';
import _ from '@lodash';
import Icon from '@mui/material/Icon';
import FuseLoading from '@fuse/core/FuseLoading';
import ReactSelect from 'react-select';
import { FixedSizeList as List } from 'react-window';
import { getOnlineBusinessTypeData } from 'app/store/userSlice';
import businessData from '../../query/business';
import queryData from '../../query/common';
import {
  getAllBusinessTypeAttributes,
  addBusinessTypeAttribute,
  resetBusiness,
  updateBusinessAttributeType,
  openNewBusinessDialog,
  closeNewBusinessDialog,
  openEditBusinessDialog,
  closeEditBusinessDialog,
  getBusinessType,
  getAllBusinessAttributes,
} from './store/businessSlice';
import reducer from './store';

const defaultValues = {
  businessType: '',
  businessAttribute: '',
};
const schema = yup.object().shape({});

const useStyles = makeStyles(() => ({
  root: {
    '& .MuiAutocomplete-tag': {
      backgroundColor: '#0BA861',
      color: 'white',
      borderRadius: '8px',
    },
    '& .MuiChip-deleteIcon': {
      color: 'white',
    },
  },
}));

function BusinessAttributeType() {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { t } = useTranslation();

  const businessAttributeData = useSelector(
    ({ businessReducer }) =>
      businessReducer?.business?.getAllBusinessTypeAttributesIdData?.data
        ?.getAllBusinessTypeAttributes?.data || ''
  );

  const addBusinessTypeAttributeStatus = useSelector(
    ({ businessReducer }) =>
      businessReducer?.business?.addBusinessTypeAttributeData?.data?.addBusinessTypeAttribute
  );

  const updateBusinessAttributeTypeStatus = useSelector(
    ({ businessReducer }) =>
      businessReducer?.business?.updateBusinessAttributeTypeData?.data?.updateBusinessAttributeType
  );

  const totalBusinessAttribute = useSelector(
    ({ businessReducer }) =>
      businessReducer?.business?.getAllBusinessTypeAttributesIdData?.data
        ?.getAllBusinessTypeAttributes?.totalCount
  );

  const appDialog = useSelector(({ businessReducer }) => businessReducer.business.businessDialog);

  const businessAllAttributeList = useSelector(
    ({ businessReducer }) =>
      businessReducer?.business?.getAllBusinessAttributesData?.data?.getAllBusinessAttributes
        ?.data || ''
  );

  const businessTypeList = useSelector(getOnlineBusinessTypeData);

  const [loading, setLoading] = useState(true);
  const [loadingPage, setLoadingPage] = useState(false);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(12);
  const [searchValue, setSearchValue] = useState('');
  const [orderNow, setOrderNow] = useState('desc');
  const [orderBy, setOrderBy] = useState('cts');
  const [businessAttributeList, setBusinessAttributeList] = useState(businessAttributeData);
  const [refreshIcon, setrefreshIcon] = useState(false);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [businessList, setBusinessList] = useState();
  const [businessAttList, setBusinessAttList] = useState();
  const [businessTypeId, setBusinessTypeId] = useState(0);
  const [status, setStatus] = useState(true);
  const [businessAttributeId, setBusinessAttributeId] = useState([]);
  const [businessAttributeValue, setBusinessAttributeValue] = useState([]);
  const [inputValue] = useState();

  const { control, watch, reset, handleSubmit, formState, getValues, setError } = useForm({
    mode: 'onChange',
    defaultValues,
    resolver: yupResolver(schema),
  });
  const { isValid, dirtyFields, errors } = formState;
  const CustomMenuList = (props) => {
    const itemHeight = 35;
    const { options, children, maxHeight, getValue } = props;
    const [value] = getValue();
    const initialOffset = options.indexOf(value) * itemHeight;
    if (options.length > 0) {
      return (
        <div>
          <List
            height={maxHeight}
            itemCount={children.length}
            itemSize={itemHeight}
            initialScrollOffset={initialOffset}
          >
            {({ index, style }) => (
              <div style={style} className="bg-white relative">
                {children[index]}
              </div>
            )}
          </List>
        </div>
      );
    }
    return <div className="p-16"> {t('tabletVideos.tabletVideoDetail.loading')}</div>;
  };
  /**
   * Initialize Dialog with Data
   */
  const initDialog = useCallback(() => {
    /**
     * Dialog type: 'edit'
     */
    if (appDialog.type === 'edit' && appDialog.data) {
      setBusinessTypeId(appDialog.data?.business_types?.id);

      setBusinessAttributeId(
        appDialog.data?.business_attributes.map((value) => {
          return parseInt(value.id, 10);
        })
      );

      setBusinessAttributeValue(
        appDialog.data?.business_attributes.map((value) => {
          return value;
        })
      );

      reset({
        ...appDialog.data,
      });
    }

    /**
     * Dialog type: 'new'
     */
    if (appDialog.type === 'new') {
      // reset({
      //   ...defaultValues,
      //   ...appDialog.data,
      // });
      setBusinessTypeId(0);
      setBusinessTypeId([]);
      setBusinessAttributeValue([]);
    }
  }, [appDialog.data, appDialog.type, reset]);

  /**
	/**
	 * On Dialog Open
	 */

  useEffect(() => {
    if (appDialog.props.open) {
      initDialog();
    }
  }, [appDialog.props.open, dispatch, initDialog]);

  useEffect(() => {
    let unmounted = false;
    const payload = {
      query: businessData.getAllBusinessTypeAttributes,
      variables: {
        pageSize: 12,
        pageNumber: 1,
        searchText: '',
        sortBy: 'cts',
        sortOrder: 'desc',
        isActiveFilter: true,
      },
    };
    dispatch(getAllBusinessTypeAttributes(payload));

    return () => {
      unmounted = true;
      dispatch(resetBusiness());
    };
  }, [dispatch]);

  useEffect(() => {
    if (appDialog.props.open) {
      const payload = {
        query: businessData.getAllBusinessAttributes,
        variables: {
          pageSize: 1000,
          pageNumber: 1,
          sortBy: 'bAttribute',
          sortOrder: 'asc',
        },
      };
      dispatch(getAllBusinessAttributes(payload));
    }
  }, [dispatch, appDialog.props.open]);

  const fetchData = (value) => {
    if (appDialog.props.open) {
      const payload = {
        query: businessData.getAllBusinessAttributes,
        variables: {
          pageSize: 1000,
          pageNumber: 1,
          sortBy: 'bAttribute',
          sortOrder: 'asc',
          searchText: value,
        },
      };
      dispatch(getAllBusinessAttributes(payload));
    }
  };

  useEffect(() => {
    if (businessAttributeData && businessAttributeData.length) {
      setBusinessAttributeList(businessAttributeData);
    } else {
      setBusinessAttributeList();
    }
  }, [businessAttributeData]);

  useEffect(() => {
    if (businessAttributeData || businessAttributeData === null) {
      setLoading(false);
    } else {
      setLoading(true);
    }
  }, [businessAttributeData]);

  useEffect(() => {
    if (businessTypeList?.length > 0) {
      setBusinessList(
        businessTypeList?.map((idx) => ({
          value: idx.id,
          label: idx.bType,
        }))
      );
    } else {
      setBusinessList([]);
    }
  }, [businessTypeList]);

  useEffect(() => {
    if (businessAllAttributeList && businessAllAttributeList.length) {
      setBusinessAttList(businessAllAttributeList);
    } else {
      setBusinessAttList([]);
    }
  }, [businessAllAttributeList]);

  useEffect(() => {
    if (
      (addBusinessTypeAttributeStatus && addBusinessTypeAttributeStatus.status === 400) ||
      (addBusinessTypeAttributeStatus && addBusinessTypeAttributeStatus.status === 500)
    ) {
      enqueueSnackbar(addBusinessTypeAttributeStatus.message, {
        variant: 'error',
        autoHideDuration: 3000,
      });
      setrefreshIcon(false);
      closeSnackbar(3000);
      dispatch(closeNewBusinessDialog());
      reset(defaultValues);
    } else if (addBusinessTypeAttributeStatus && addBusinessTypeAttributeStatus.status === 200) {
      enqueueSnackbar(addBusinessTypeAttributeStatus.message, {
        variant: 'success',
        autoHideDuration: 3000,
      });
      reset(defaultValues);
      setrefreshIcon(false);
      closeSnackbar(3000);
      dispatch(closeNewBusinessDialog());
      reset(defaultValues);
    }
  }, [reset, dispatch, addBusinessTypeAttributeStatus, enqueueSnackbar, closeSnackbar]);

  useEffect(() => {
    if (updateBusinessAttributeTypeStatus && updateBusinessAttributeTypeStatus.status === 400) {
      enqueueSnackbar(updateBusinessAttributeTypeStatus.message, {
        variant: 'error',
        autoHideDuration: 3000,
      });
      setrefreshIcon(false);
      closeSnackbar(3000);
      dispatch(closeEditBusinessDialog());
      reset(defaultValues);
    } else if (
      updateBusinessAttributeTypeStatus &&
      updateBusinessAttributeTypeStatus.status === 200
    ) {
      enqueueSnackbar(updateBusinessAttributeTypeStatus.message, {
        variant: 'success',
        autoHideDuration: 3000,
      });
      reset(defaultValues);
      setrefreshIcon(false);
      closeSnackbar(3000);
      dispatch(closeEditBusinessDialog());
      reset(defaultValues);
    }
  }, [reset, dispatch, updateBusinessAttributeTypeStatus, enqueueSnackbar, closeSnackbar]);

  const commonGetData = async (searchValues = '') => {
    setSearchValue(searchValues);
    const payload = {
      query: businessData.getAllBusinessTypeAttributes,
      variables: {
        searchText: searchValues,
        pageNumber: page,
        pageSize: rowsPerPage,
        sortBy: searchValues ? 'bType' : orderBy,
        sortOrder: searchValues ? 'asc' : orderNow,
        isActiveFilter: Boolean(JSON.parse(status)),
      },
    };
    await dispatch(getAllBusinessTypeAttributes(payload));
  };

  function handleSearchEvent(event) {
    setPage(1);
    const searchText = event.target.value;
    commonGetData(searchText);
  }

  function handleChangePage(event, value) {
    setPage(value + 1);
    setLoadingPage(true);
    const payload = {
      query: businessData.getAllBusinessTypeAttributes,
      variables: {
        pageSize: rowsPerPage,
        pageNumber: value + 1,
        searchText: searchValue,
        sortBy: orderBy,
        sortOrder: orderNow,
        isActiveFilter: Boolean(JSON.parse(status)),
      },
    };
    dispatch(async (dispatchValue) => {
      await dispatchValue(getAllBusinessTypeAttributes(payload));
      setLoadingPage(false);
    });
  }

  function handleChangeRowsPerPage(event) {
    setRowsPerPage(event.target.value);
    setPage(1);
    const payload = {
      query: businessData.getAllBusinessTypeAttributes,
      variables: {
        pageSize: event.target.value,
        pageNumber: 1,
        searchText: searchValue,
        sortBy: orderBy,
        sortOrder: orderNow,
        isActiveFilter: Boolean(JSON.parse(status)),
      },
    };
    dispatch(async (dispatchValue) => {
      await dispatchValue(getAllBusinessTypeAttributes(payload));
      setLoadingPage(false);
    });
  }

  if (loading) {
    return <FuseLoading />;
  }

  function closeComposeDialog() {
    appDialog.type === 'edit'
      ? dispatch(closeEditBusinessDialog())
      : dispatch(closeNewBusinessDialog());
    setBusinessTypeId(0);
  }

  /**
   * Remove Event
   */
  function handleRemove() {
    closeComposeDialog();
    reset(defaultValues);
  }

  function onSubmit(submitData) {
    setrefreshIcon(true);
    if (businessTypeId === 0 || !businessTypeId || businessTypeId?.length === 0) {
      setError('businessType', {
        type: 'manual',
        message: 'Business Type is require',
      });
      setrefreshIcon(false);
    }
    if (businessAttributeId.length === 0) {
      setError('businessAttribute', {
        type: 'manual',
        message: 'Business Attribute is require',
      });
      setrefreshIcon(false);
    }
    if (appDialog.type === 'new') {
      if (businessTypeId !== 0 && businessAttributeId.length > 0) {
        const payload = {
          query: businessData.addBusinessTypeAttribute,
          variables: { bAttributeId: businessAttributeId, bTypeId: businessTypeId },
        };
        dispatch(addBusinessTypeAttribute(payload));
        const payloadData = {
          query: businessData.getAllBusinessTypeAttributes,
          variables: {
            pageSize: rowsPerPage,
            pageNumber: page,
            searchText: searchValue,
            sortBy: orderBy,
            sortOrder: orderNow,
            isActiveFilter: Boolean(JSON.parse(status)),
          },
        };
        setTimeout(() => {
          dispatch(getAllBusinessTypeAttributes(payloadData));
        }, 800);
      }
    } else {
      const payload = {
        query: businessData.updateBusinessAttributeType,
        variables: {
          bAttributeId: businessAttributeId,
          bTypeId: businessTypeId,
          bAttributeTypeId: submitData?.id,
        },
      };
      dispatch(updateBusinessAttributeType(payload));
      const payloadData = {
        query: businessData.getAllBusinessTypeAttributes,
        variables: {
          pageSize: rowsPerPage,
          pageNumber: page,
          searchText: searchValue,
          sortBy: orderBy,
          sortOrder: orderNow,
          isActiveFilter: Boolean(JSON.parse(status)),
        },
      };
      setTimeout(() => {
        dispatch(getAllBusinessTypeAttributes(payloadData));
      }, 1000);
    }
  }

  function handleSelectBusinessType(event) {
    setBusinessTypeId(event.value);
  }

  function handleSelectBusinessAttribute(event, newValue) {
    setBusinessAttributeValue(newValue);
    setBusinessAttributeId(
      newValue.map((value) => {
        return parseInt(value.id, 10);
      })
    );
  }
  function handleStatusDropdownChange(event) {
    const statusValue = event.target.value;
    setStatus(statusValue);
    setLoading(true);
    setPage(1);
    const payloadData = {
      query: businessData.getAllBusinessTypeAttributes,
      variables: {
        pageSize: rowsPerPage,
        pageNumber: 1,
        searchText: searchValue,
        sortBy: orderBy,
        isActiveFilter: Boolean(JSON.parse(statusValue)),
        sortOrder: orderNow,
      },
    };
    dispatch(getAllBusinessTypeAttributes(payloadData));

    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }

  const handleClearSearch = async () => {
    await setPage(1);
    await setSearchValue('');
    await commonGetData();
  };

  return (
    <div className="p-20 lg:p-24">
      {loadingPage && (
        <div className="loader-container fixed top-0 left-0 w-full h-full flex justify-center items-center">
          <div className="loader h-40 w-40 rounded-full border-4 border-solid border-white border-t-darkgreen" />
        </div>
      )}
      <div className="flex flex-1 w-full items-center justify-between mb-36">
        <div className="flex items-center">
          <Typography
            component={motion.span}
            initial={{ x: -20 }}
            animate={{ x: 0, transition: { delay: 0.2 } }}
            delay={300}
            className="sm:flex text-20 md:text-28 font-bold"
          >
            {t('business.businessAttributeType.title')}
          </Typography>
        </div>
        <div className="flex flex-1 items-center justify-center px-12">
          <Paper
            component={motion.div}
            initial={{ y: -20, opacity: 0 }}
            animate={{ y: 0, opacity: 1, transition: { delay: 0.2 } }}
            className="flex items-center w-full max-w-512 px-8 rounded-8 shadow-none"
          >
            <Input
              placeholder={t(
                'business.businessAttributeType.placeholder.searchBusinessAttributeType'
              )}
              className="flex flex-1 mx-8 text-black text-16"
              disableUnderline
              name="searchText"
              fullWidth
              inputProps={{
                'aria-label': 'Search',
              }}
              onChange={handleSearchEvent}
              value={searchValue}
            />
            {searchValue ? (
              <Icon
                color="action"
                className="cursor-pointer"
                onClick={() => {
                  handleClearSearch();
                }}
              >
                close
              </Icon>
            ) : (
              <Icon color="action">search</Icon>
            )}
          </Paper>
        </div>
        <div className="items-center justify-end flex">
          <Select
            className="rounded-full border border-darkgreen h-40 bg-white text-16 w-full min-w-136 leading-5 shadow-none mr-16"
            variant="filled"
            sx={{ '& .MuiFilledInput-input': { pt: '8px' } }}
            value={status}
            fullWidth
            onChange={handleStatusDropdownChange}
            IconComponent={() => (
              <Icon className="text-20 align-middle absolute right-14 text-black ml-2">
                filter_list
              </Icon>
            )}
          >
            <MenuItem value="true" className="capitalize">
              {t('menuItems.active')}
            </MenuItem>
            <MenuItem value="false" className="capitalize">
              {t('menuItems.inActive')}
            </MenuItem>
          </Select>
        </div>
        <div className="items-center justify-end flex">
          <Button
            variant="contained"
            color="secondary"
            className="w-full md:text-18 font-medium disabled:text-black"
            aria-label="Add Business Type"
            onClick={() => {
              dispatch(openNewBusinessDialog());
            }}
          >
            <Icon className="text-16">add</Icon>
            <span className="hidden sm:flex text-white">
              &nbsp; {t('business.businessAttributeType.buttons.businessAttributeTypeBtn')}
            </span>
            <span className="flex sm:hidden">
              &nbsp; {t('business.businessAttribute.buttons.new')}
            </span>
          </Button>
        </div>
      </div>
      <div
        className={`${
          businessAttributeList && businessAttributeData?.length > 0
            ? 'grid xl:grid-cols-4 lg:grid-cols-3 md:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-32'
            : 'bg-white rounded-md relative shadow p-20'
        }`}
      >
        {businessAttributeList && businessAttributeData?.length > 0 ? (
          <>
            {businessAttributeList?.map((row, index) => (
              <div className="bg-white rounded-md relative shadow p-20" key={index}>
                <div className="flex items-center justify-between mb-5">
                  <span className="inline-block text-14 text-grey-700 font-medium">
                    {t('business.businessType.label.businessType')}
                  </span>
                  <span
                    className="inline-block text-14 text-white bg-darkgreen rounded-full w-24 h-24 text-center flex items-center cursor-pointer"
                    aria-hidden="true"
                    onClick={() => {
                      dispatch(openEditBusinessDialog(row));
                    }}
                  >
                    {/* <Icon>more_horiz</Icon> */}
                    <Icon className="text-14 mx-auto">edit</Icon>
                  </span>
                </div>
                <h2 className="font-bold text-18 text-black">{row?.business_types?.bType}</h2>
                <div className="flex items-center justify-between mt-20 mb-8">
                  <span className="inline-block text-14 text-grey-700 font-medium">
                    {t('business.businessAttributeType.labels.businessAttributes')}
                  </span>
                  <span className="inline-block text-14 text-grey-700 font-medium">
                    {t('dashboard.dashboardAdmin.total')} {row?.business_attributes?.length}{' '}
                  </span>
                </div>
                {row?.business_attributes?.length > 0 &&
                  row?.business_attributes?.map((attributeName, i) => (
                    <span
                      className="text-14 text-black bg-grey-100 py-5 px-12 rounded-xl inline-block mr-12 my-6 font-medium color-black"
                      key={i}
                    >
                      {attributeName?.bAttribute}
                    </span>
                  ))}
              </div>
            ))}
          </>
        ) : (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1, transition: { delay: 0.1 } }}
            className="flex flex-1 items-center justify-center h-full"
          >
            <Typography color="textSecondary" variant="h5" className="text-16">
              {t('business.businessAttribute.validationMessage.noBusinessAttribute')}
            </Typography>
          </motion.div>
        )}
      </div>

      {businessAttributeData?.length > 0 && (
        <TablePagination
          sx={{
            '.MuiInputBase-root': {
              lineHeight: 'inherit',
              padding: 0,
            },
          }}
          component="div"
          count={totalBusinessAttribute}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={[12, 24, 36, 48, 96]}
          page={page - 1}
          backIconButtonProps={{
            'aria-label': 'Previous Page',
          }}
          nextIconButtonProps={{
            'aria-label': 'Next Page',
          }}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      )}

      <Dialog
        classes={{
          root: classes.root,
          paper: 'm-24 p-28 business-type-dropdown',
        }}
        {...appDialog.props}
        onClose={closeComposeDialog}
        fullWidth
        maxWidth="xs"
      >
        <DialogTitle className="p-0 mb-24">
          <Icon
            onClick={handleRemove}
            className="text-24 absolute top-12 right-12 text-grey-400 font-normal cursor-pointer hover:text-gray-700 focus:text-gray-700"
          >
            cancel
          </Icon>
          <Typography className="text-20 font-semibold text-black border-b-2 border-solid border-darkgreen-100 w-full pb-10">
            {appDialog.type === 'new'
              ? 'Add Business Attribute Type'
              : 'Edit Business Attribute Type'}
          </Typography>
        </DialogTitle>

        <form
          noValidate
          onSubmit={handleSubmit(onSubmit)}
          className="flex flex-col md:overflow-hidden"
        >
          <DialogContent
            className={`${classes.attDialog} p-0 mb-24 business-type-dropdown-content`}
          >
            <div className="block mb-24">
              <InputLabel id="businessType" className="text-16 font-medium mb-12 text-grey-900">
                {t('business.businessList.column.businessType')}
              </InputLabel>
              <ReactSelect
                labelId="businessType"
                placeholder={t('business.selectBusinessType')}
                id="businessType"
                name="businessType"
                inputValue={inputValue}
                onChange={(e) => {
                  handleSelectBusinessType(e);
                }}
                value={businessList.find((op) => {
                  return op.value === businessTypeId;
                })}
                options={businessList}
                components={{
                  MenuList: CustomMenuList,
                }}
              />
              {errors.businessType && (
                <p className="text-red -mt-24 absolute">{errors.businessType.message}</p>
              )}
            </div>
            <div className="block">
              <InputLabel
                id="businessAttribute"
                className="text-16 font-medium mb-12 text-grey-900"
              >
                {t('business.businessAttribute.title')}
              </InputLabel>
              <Autocomplete
                multiple
                id="tags-standard"
                options={businessAttList}
                name="businessAttribute"
                size="small"
                getOptionLabel={(option) => option?.bAttribute}
                isOptionEqualToValue={(option, value) => {
                  return option.id === parseInt(value.id, 10);
                }}
                onChange={handleSelectBusinessAttribute}
                value={businessAttributeValue}
                disableCloseOnSelect
                renderOption={(props, option, { selected }) => (
                  <MenuItem
                    key={option.id}
                    value={option?.id}
                    sx={{ justifyContent: 'space-between' }}
                    {...props}
                  >
                    {option?.bAttribute}
                    {selected ? <Icon color="info">done</Icon> : null}
                  </MenuItem>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    size="small"
                    onChange={(e) => fetchData(e.target.value)}
                    className="text-16 font-medium mb-12 text-grey-900"
                    placeholder={t(
                      'business.businessAttributeType.placeholder.selectBusinessAttribute'
                    )}
                  />
                )}
              />
            </div>
          </DialogContent>
          <DialogActions className="p-0 justify-start">
            <Button
              className="md:text-18 font-semibold disabled:text-black rounded-md"
              variant="contained"
              color="secondary"
              type="submit"
              disabled={businessAttributeId.length === 0 || businessTypeId === 0 || refreshIcon}
            >
              {appDialog.type === 'new' ? 'Create' : 'Update'}
              {refreshIcon && (
                <CircularProgress size={24} className="text-darkgreen absolute mx-auto" />
              )}
            </Button>

            <Button
              className="md:text-18 font-semibold bg-white text-darkgreen border border-solid border-darkgreen rounded-md hover:text-white hover:bg-darkgreen"
              variant="contained"
              color="primary"
              type="button"
              onClick={handleRemove}
            >
              {t('common.cancel')}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
}

export default withReducer('businessReducer', reducer)(BusinessAttributeType);
