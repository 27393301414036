import { motion } from 'framer-motion';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import clsx from 'clsx';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import * as yup from 'yup';
import _ from '@lodash';
import { useSnackbar } from 'notistack';
import history from '@history';
import withReducer from 'app/store/withReducer';
import InputAdornment from '@mui/material/InputAdornment';
import Icon from '@mui/material/Icon';
import IconButton from '@mui/material/IconButton';
import CircularProgress from '@mui/material/CircularProgress';
import JSEncrypt from 'jsencrypt';
import { useTranslation } from 'react-i18next';
import reducer from './store';
import passwordData from '../query/resetPassword';
import { forgotPassword, resetresetPassword } from './store/resetPasswordSlice';
import { getEncryptedData } from '../common/common';

/**
 * Form Validation Schema
 */

const defaultValues = {
  password: '',
  passwordConfirm: '',
};

function ResetPassword() {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const schema = yup.object().shape({
    password: yup
      .string()
      .required(t('userProfile.newPassword'))
      .min(8, t('users.resetPassword.formFields.validationMessages.passwordTooShort'))
      .matches(
        /^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
        t('users.resetPassword.formFields.validationMessages.passwordRequirements')
      ),
    passwordConfirm: yup
      .string()
      .oneOf(
        [yup.ref('password'), null],
        t('signUpPage.formField.passwordConfirm.validationMessages.passwordMatch')
      ),
  });

  const resetPasswordStatus = useSelector(({ resetPasswordReducer }) =>
    resetPasswordReducer?.resetPassword?.forgotPasswordData?.data?.forgotPassword
      ? resetPasswordReducer.resetPassword.forgotPasswordData.data.forgotPassword
      : {}
  );

  const { control, formState, handleSubmit, reset } = useForm({
    mode: 'onChange',
    defaultValues,
    resolver: yupResolver(schema),
  });
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const { isValid, dirtyFields, errors } = formState;
  const [isConfirm, setIsConfirm] = useState();
  const [showPassword, setShowPassword] = useState(false);
  const [showCnfPassword, setShowCnfPassword] = useState(false);
  const [refreshIcon, setrefreshIcon] = useState(false);

  useEffect(() => {
    setLoading(false);
    if (resetPasswordStatus && resetPasswordStatus.status === 401) {
      // enqueueSnackbar(resetPasswordStatus.message, {
      //   variant: 'error',
      //   autoHideDuration: 3000,
      // });
      setrefreshIcon(false);
      setIsConfirm(resetPasswordStatus.status);
    } else if (resetPasswordStatus && resetPasswordStatus.status === 400) {
      enqueueSnackbar(resetPasswordStatus.message, {
        variant: 'error',
        autoHideDuration: 3000,
      });
      setIsConfirm(resetPasswordStatus.status);
      setrefreshIcon(false);
    } else if (resetPasswordStatus && resetPasswordStatus.status === 200) {
      // enqueueSnackbar(resetPasswordStatus.message, {
      //   variant: 'success',
      //   autoHideDuration: 3000,
      // });
      setrefreshIcon(false);
      setIsConfirm();
      localStorage.removeItem('authToken');
      setIsConfirm(resetPasswordStatus.status);
    }
  }, [enqueueSnackbar, resetPasswordStatus]);

  useEffect(() => {
    return () => {
      dispatch(resetresetPassword());
    };
  }, [dispatch]);

  const query = new URLSearchParams(window.location.search);
  localStorage.setItem('authToken', query.get('token'));

  function onSubmit(model) {
    setrefreshIcon(true);
    setLoading(true);
    const data = getEncryptedData(`{"password": "${model.password}"}`);
    const payload = {
      query: passwordData.forgotPassword,
      variables: { data },
    };
    dispatch(forgotPassword(payload));
  }

  return (
    <div className="flex flex-col flex-auto items-center">
      <div
        className="top-cover bg-darkgreen-100 h-96 w-full"
        style={{ height: 'calc(100vh - 670px)' }}
      />
      <div className="md:flex md:flex-col block items-center justify-center w-full">
        <div>
          <Card className="relative -top-96 mx-16">
            <CardContent className="md:p-64 p-16 bg-white rounded-3xl md:w-3xl w-full md:mx-auto text-center">
              <div className="review-logo md:mb-60 mb-28">
                <img
                  className="max-w-xs mx-auto w-[170px]"
                  src="assets/images/logo/logo.png"
                  alt="logo"
                />
              </div>
              {!isConfirm ? (
                <div className="reset-password-form">
                  <Typography variant="h6" className="mb-24 font-bold text-18 sm:text-24">
                    {t('userProfile.resetYourPassword')}
                  </Typography>

                  <form
                    name="resetForm"
                    noValidate
                    className="flex flex-col justify-center w-full"
                    onSubmit={handleSubmit(onSubmit)}
                  >
                    <Controller
                      name="password"
                      control={control}
                      render={({ field }) => (
                        <>
                          <span className="block text-left text-16 font-medium mb-12">
                            {t('userProfile.newPassword')}
                          </span>
                          <TextField
                            {...field}
                            className="mb-40"
                            type="password"
                            name="password"
                            error={!!errors.password}
                            helperText={errors?.password?.message}
                            variant="outlined"
                            InputProps={{
                              className: 'pr-2',
                              type: showPassword ? 'text' : 'password',
                              endAdornment: (
                                <InputAdornment position="end">
                                  <IconButton onClick={() => setShowPassword(!showPassword)}>
                                    <Icon className="text-20" color="action">
                                      {showPassword ? 'visibility' : 'visibility_off'}
                                    </Icon>
                                  </IconButton>
                                </InputAdornment>
                              ),
                            }}
                            required
                            fullWidth
                          />
                        </>
                      )}
                    />

                    <Controller
                      name="passwordConfirm"
                      control={control}
                      render={({ field }) => (
                        <>
                          <span className="block text-left text-16 font-medium mb-12">
                            {t('userProfile.confirmPassword')}
                          </span>
                          <TextField
                            {...field}
                            className="mb-16"
                            type="password"
                            error={!!errors.passwordConfirm}
                            helperText={errors?.passwordConfirm?.message}
                            variant="outlined"
                            InputProps={{
                              className: 'pr-2',
                              type: showCnfPassword ? 'text' : 'password',
                              endAdornment: (
                                <InputAdornment position="end">
                                  <IconButton onClick={() => setShowCnfPassword(!showCnfPassword)}>
                                    <Icon className="text-20" color="action">
                                      {showCnfPassword ? 'visibility' : 'visibility_off'}
                                    </Icon>
                                  </IconButton>
                                </InputAdornment>
                              ),
                            }}
                            required
                            fullWidth
                          />
                        </>
                      )}
                    />
                    <div>
                      <Button
                        variant="contained"
                        color="primary"
                        className="whitespace-nowrap rounded-md px-32 py-8 text-center bg-darkgreen text-white font-semibold text-18 mt-24 md:mb-56 mb-16"
                        aria-label="Reset"
                        disabled={_.isEmpty(dirtyFields) || !isValid || loading || refreshIcon}
                        type="submit"
                      >
                        {t('users.resetPassword.button.reset')}{' '}
                        {refreshIcon && (
                          <Icon
                            className={`text-14 ml-2 mt-1 absolute right-6 cursor-pointer text-white font-bold animate-spin ${
                              refreshIcon ? `animate-spin` : ''
                            }`}
                            title="Refresh Token"
                          >
                            <img
                              src="/assets/images/icon/loading.png"
                              alt="rating-5"
                              className="mx-auto"
                            />
                          </Icon>
                        )}
                      </Button>
                      {loading && <CircularProgress size={24} />}
                    </div>
                  </form>
                </div>
              ) : isConfirm === 401 ? (
                <div className="reset-password-expired">
                  <Typography variant="h6" className="mb-24 font-bold text-18 sm:text-24">
                    {t('users.resetPassword.messages.expiredLink')}
                  </Typography>
                  <Typography
                    variant="h6"
                    className="mb-24 font-medium text-14 sm:text-18 max-w-sm mx-auto"
                  >
                    {t('users.resetPassword.messages.expiredLinkExplanation')}
                  </Typography>
                </div>
              ) : isConfirm === 200 ? (
                <div className="reset-password-success">
                  <Typography variant="h6" className="mb-24 font-bold text-18 sm:text-24">
                    {t('users.resetPassword.messages.successReset')}
                  </Typography>
                  <Typography
                    variant="h6"
                    className="mb-24 font-medium text-14 sm:text-18 max-w-sm mx-auto"
                  >
                    {t('users.resetPassword.messages.loginWithNewPassword')}
                  </Typography>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => history.push('/sign-in')}
                    className="whitespace-nowrap rounded-md px-32 py-8 text-center bg-darkgreen text-white font-semibold text-18 mt-24 md:mb-56 mb-16"
                    aria-label="Request"
                    type="button"
                  >
                    Login
                  </Button>
                </div>
              ) : (
                ''
              )}
            </CardContent>
          </Card>
        </div>
      </div>
    </div>
  );
}

export default withReducer('resetPasswordReducer', reducer)(ResetPassword);
