import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogTitle,
  Icon,
  Typography,
} from '@mui/material';
import { GoogleOAuthProvider, useGoogleLogin } from '@react-oauth/google';
import { selectDashboardData } from 'app/store/userSlice';
import { useSelector } from 'react-redux';
import { t } from 'i18next';
import { useSnackbar } from 'notistack';
import { useCallback, useEffect, useState } from 'react';
import manageBusinessData from '../../../query/manageBusiness';
import { handleApiRequest } from '../../../common/common';

const GoogleLoginButton = ({ onSuccess, onError, loader }) => {
  const handleGoogleLogin = useGoogleLogin({
    flow: 'auth-code',
    onSuccess,
    onError,
    scope: ['https://www.googleapis.com/auth/business.manage'],
  });

  return (
    <Button
      size="small"
      variant="contained"
      className="rounded-md px-16 text-16 gap-20 "
      color="secondary"
      onClick={() => handleGoogleLogin()}
      disabled={loader}
    >
      <img
        src="/assets/images/icon/google icon.png"
        alt=" G "
        className="bg-white  rounded-full p-4 max-h-28"
      />
      {t('primaryDetails.connectBusiness')}
      {loader && <CircularProgress size={24} className="text-quick-review absolute mx-auto" />}
    </Button>
  );
};

const ConnectYourBusiness = (props) => {
  const { setIsBusinessConnect } = props;
  const userCurrentBusinessDetails = useSelector(selectDashboardData);
  const [openDialog, setOpenDialog] = useState(false);
  const [refreshIcon, setRefreshIcon] = useState(false);
  const [loader, setLoader] = useState(false);
  const [locationId, setLocationId] = useState('');
  const [businessList, setBusinessList] = useState([]);
  const { enqueueSnackbar } = useSnackbar();

  const fetchUserDetails = useCallback(async () => {
    try {
      if (userCurrentBusinessDetails?.id) {
        const payload = {
          query: manageBusinessData.getUserBusinessDetail,
          variables: {
            businessId: parseInt(userCurrentBusinessDetails?.id, 10),
          },
        };
        const result = await handleApiRequest(payload);
        if (result?.getUserBusinessDetail?.status === 200) {
          setIsBusinessConnect(result?.getUserBusinessDetail?.data?.[0].isGoogleVerified);
        }
      }
    } catch (error) {
      console.error('An error occurred:', error);
    }
  }, [userCurrentBusinessDetails?.id, setIsBusinessConnect]);

  useEffect(() => {
    fetchUserDetails();
  }, [fetchUserDetails]);

  const responseGoogle = async (response) => {
    setLoader(true);
    try {
      const payload = {
        query: manageBusinessData.getUserRegisteredGoogleBusiness,
        variables: {
          authorizationCode: response?.code,
          businessId: parseInt(userCurrentBusinessDetails?.id, 10),
          isAddedByGoogleConnect: false,
        },
      };
      const result = await handleApiRequest(payload);
      setLoader(false);
      if (result?.getUserRegisteredGoogleBusiness?.status === 200) {
        const data = result.getUserRegisteredGoogleBusiness?.data;
        const filterPlaceIdData = data.filter((item) => item?.businessPlaceId);
        setBusinessList(filterPlaceIdData);
        setOpenDialog(true);
      } else {
        enqueueSnackbar(result?.getUserRegisteredGoogleBusiness?.message, {
          variant: 'error',
          autoHideDuration: 3000,
        });
      }
    } catch (error) {
      setLoader(false);
      console.error('Error fetching registered Google Business:', error);
    }
  };

  const handleError = (error) => {
    console.log('Login Failed:', error);
  };

  const handleConnectBusiness = async (data) => {
    setRefreshIcon(true);
    try {
      setLocationId(data?.locationId);
      const payload = {
        query: manageBusinessData.connectMyBusiness,
        variables: {
          placeId: data?.businessPlaceId,
          userBusinessId: parseInt(userCurrentBusinessDetails?.id, 10),
          locationId: data?.locationId,
          accountId: data?.accountId,
        },
      };
      const result = await handleApiRequest(payload);
      setRefreshIcon(false);
      if (result?.connectMyBusiness?.status === 200) {
        setOpenDialog(false);
        setLocationId('');
        fetchUserDetails();
      } else {
        enqueueSnackbar(result?.connectMyBusiness?.message, {
          variant: 'error',
          autoHideDuration: 3000,
        });
      }
    } catch (error) {
      setRefreshIcon(false);
      console.error('Error connecting business:', error);
    }
  };

  return (
    <div className="lg:p-24 p-16 ">
      <div className="sm:flex justify-between items-center lg:mb-20 mb-16">
        <h1 className="md:text-28 text-20 font-bold font-Quicksand sm:mb-0 mb-16">
          {t('primaryDetails.primaryDetails')}
        </h1>
        <div className="flex space-x-4 gap-12">
          {/* <Button
            size="small"
            variant="outlined"
            color="secondary"
            className=" text-quick-review rounded-md px-16 gap-20 text-16 "
          >
            <img
              src="/assets/images/icon/play.png"
              alt=" G "
              className="bg-white  rounded-full max-h-28 m"
            />

            {t('primaryDetails.howToConnect')}
          </Button> */}
          <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
            <GoogleLoginButton onSuccess={responseGoogle} onError={handleError} loader={loader} />
          </GoogleOAuthProvider>
        </div>
      </div>

      <div className="flex flex-col md:flex-row bg-white p-56 rounded-6 gap-56 items-center ">
        <div className="w-1/2 flex flex-col">
          <div className="mb-16">
            <h2 className="text-32 font-bold mb-20 font-Quicksand">
              {t('primaryDetails.manageBusinessProfile')}
            </h2>
            <p className="text-18 font-medium font-Quicksand text-grey-800 mb-24">
              {t('primaryDetails.primaryDetailsPara')}
            </p>
          </div>

          <div className="flex space-x-4 gap-20 mb-40">
            {/* <Button
              size="small"
              variant="outlined"
              color="secondary"
              className=" text-quick-review rounded-md px-16 gap-20 text-16 "
            >
              <img
                src="/assets/images/icon/play.png"
                alt=" G "
                className="bg-white  rounded-full max-h-28 m"
              />

              {t('primaryDetails.howToConnect')}
            </Button> */}

            <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
              <GoogleLoginButton onSuccess={responseGoogle} onError={handleError} loader={loader} />
            </GoogleOAuthProvider>
          </div>
          <div className="font-semibold text-24 mb-24">
            <h1> {t('primaryDetails.whatYouCanDo')} </h1>
          </div>
          <div className=" flex gap-4 pr-10">
            <div className=" w-1/2 ">
              <div className="bg-gray-A500  h-auto  mb-20 mr-10 pb-16 pl-10 p-16 rounded-xl pr-48">
                <img
                  src="/assets/images/icon/manage-multiple.png"
                  alt=" Manage Multiple"
                  className="mb-16  max-h-56"
                />
                <h4 className="text-16 font-semibold mb-8">
                  {t('primaryDetails.manageMultipleProfiles')}
                </h4>
                <p className="text-14 font-medium text-black ">
                  {t('primaryDetails.manageMultipleProfilesPara')}
                </p>
              </div>
              <div className="bg-gray-A500  h-auto mb-10 mr-10 pr-40 p-16 rounded-xl">
                <img
                  src="/assets/images/icon/manage-service.png"
                  alt="Manage Services"
                  className="mb-16   max-h-56"
                />
                <h4 className="text-16 font-semibold mb-8">{t('primaryDetails.manageServices')}</h4>
                <p className="text-14 font-medium text-black">
                  {t('primaryDetails.manageServicesPara')}
                </p>
              </div>
            </div>
            <div className="w-1/2">
              <div className="bg-gray-A500 w-full h-auto mb-20 ml-10 pl-16 py-16 rounded-xl">
                <img
                  src="/assets/images/icon/post-update.png"
                  alt="Post Update"
                  className="mb-16  max-h-56"
                />
                <h4 className="text-16 font-semibold mb-8">{t('primaryDetails.postUpdates')}</h4>
                <p className="text-14 font-medium text-black">
                  {t('primaryDetails.postUpdatesPara')}
                </p>
              </div>
              <div className="bg-gray-A500 w-full h-auto mb-10 ml-10 p-16 rounded-xl">
                <img
                  src="/assets/images/icon/respondReview.png"
                  alt="Monitor Respond"
                  className="mb-16   max-h-56"
                />
                <h4 className="text-16 font-semibold mb-8">
                  {t('primaryDetails.monitorRespondToReview')}
                </h4>
                <p className="text-14 font-medium text-black">
                  {t('primaryDetails.monitorRespondToReviewPara')}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className=" p-20 w-1/2">
          <img src="/assets/images/icon/Group.png" alt="Large Map" className="rounded-lg " />
        </div>
      </div>
      <Dialog
        classes={{
          paper: 'p-24',
        }}
        open={openDialog}
        onClose={(event, reason) => {
          if (reason !== 'backdropClick') {
            setOpenDialog(false);
          }
        }}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle className="p-0 mb-16">
          <Icon
            onClick={() => {
              setOpenDialog(false);
            }}
            className="text-24 absolute top-12 right-12 text-grey-400 font-normal cursor-pointer hover:text-gray-700 focus:text-gray-700"
          >
            close
          </Icon>
        </DialogTitle>
        <DialogActions className="p-0 border p-12 w-full h-320 overflow-scroll rounded block border-quick-review border-solid text-left">
          {businessList?.length > 0 ? (
            businessList?.map((item, index) => {
              return (
                <div
                  className="!ml-0 mb-12 border p-8 rounded border-solid border-quick-review"
                  key={index}
                >
                  <Typography className="font-semibold text-14">{item?.businessName}</Typography>
                  <Typography className="text-14 my-2">Address: {item?.businessAddress}</Typography>
                  <Button
                    className={`bg-quick-review rounded hover:bg-quick-review text-white disabled:bg-gray px-16 font-semibold text-14 ${
                      item?.isAvailable ? '' : 'opacity-80'
                    }`}
                    disabled={locationId === item?.locationId && refreshIcon}
                    onClick={() => handleConnectBusiness(item)}
                  >
                    {t('businessCompetitor.connectBusiness')}
                    {locationId === item?.locationId && refreshIcon && (
                      <CircularProgress size={24} className="text-quick-review absolute mx-auto" />
                    )}
                  </Button>
                </div>
              );
            })
          ) : (
            <Typography>{t('businessCompetitor.noBusinessAvailable')}</Typography>
          )}
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ConnectYourBusiness;
