import { selectDashboardData } from 'app/store/userSlice';
import { t } from 'i18next';
import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Button, CircularProgress } from '@mui/material';
import history from '@history';
import { useLocation, useParams } from 'react-router-dom';
import DoneIcon from '@mui/icons-material/Done';
import { useSnackbar } from 'notistack';
import FuseLoading from '@fuse/core/FuseLoading';
import quickSocialQuery from '../../query/quickSocial';
import SelectPlatformBox from './SelectPlatformBox';
import {
  handleApiRequest,
  handleFBLogin,
  fetchSocialConnections,
  handleSocialResponse,
} from '../../common/common';
import Linkedin from './LinkedinConnect';

const SocialConnects = () => {
  const params = useParams();
  const location = useLocation();
  const [socialData, setSocialData] = useState([]);
  const [profileData, setProfileData] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const [selectedAccount, setSelectedAccount] = useState([]);
  const [accessToken, setAccessToken] = useState();
  const [connectionData, setConnectionData] = useState([]);
  const [openDialog, setOpenDialog] = useState(true);
  const [facebookUserId, setFacebookUserId] = useState();
  const [selectedPage, setSelectedPage] = useState([]);
  const [socialPlatformId, setSocialPlatformId] = useState(null);
  const userCurrentBusinessDetails = useSelector(selectDashboardData);
  const [loading, setLoading] = useState(false);
  const [isLinkedin, setIsLinkedin] = useState(false);
  const [openLinkedinPopUp, setOpenLinkedinPopUp] = useState(false);
  const [linkedinRefreshIcon, setLinkedinRefreshIcon] = useState(false);
  const appKey = process.env.REACT_APP_WABA_APP_ID;
  const fbConfigKey = process.env.REACT_APP_FB_CONFIG_ID;
  const inConfigKey = process.env.REACT_APP_IN_CONFIG_ID;

  const fetchAgentSupportStatus = useCallback(async () => {
    try {
      setLoading(true);
      if (userCurrentBusinessDetails?.id) {
        const payloadData = {
          query: quickSocialQuery.getQuickSocialChannelWiseData,
          variables: {
            businessId: Number(userCurrentBusinessDetails?.id),
          },
        };
        const resultData = await handleApiRequest(payloadData);
        setLoading(false);
        if (resultData?.getQuickSocialChannelWiseData?.status === 200) {
          setSocialData(resultData?.getQuickSocialChannelWiseData?.data);
        }
      }
    } catch (error) {
      console.error('An error occurred:', error);
    }
  }, [userCurrentBusinessDetails?.id]);

  useEffect(() => {
    fetchAgentSupportStatus();
  }, [fetchAgentSupportStatus]);

  const fetchProfileData = useCallback(
    async (reviewPlatformId) => {
      try {
        setLoading(true);
        if (userCurrentBusinessDetails?.id && reviewPlatformId) {
          const payloadData = {
            query: quickSocialQuery.getAllQuickSocialProfileDetails,
            variables: {
              userBusinessId: Number(userCurrentBusinessDetails?.id),
              mediaChannelId: Number(reviewPlatformId),
            },
          };
          const resultData = await handleApiRequest(payloadData);
          setLoading(false);
          if (resultData?.getAllQuickSocialProfileDetails?.status === 200) {
            setProfileData(resultData?.getAllQuickSocialProfileDetails?.data);
          }
        }
      } catch (error) {
        console.error('An error occurred:', error);
      }
    },
    [userCurrentBusinessDetails?.id]
  );
  useEffect(() => {
    if (location.state?.mediaChannelId) {
      fetchProfileData(location.state?.mediaChannelId);
    }
  }, [fetchProfileData, location.state?.mediaChannelId]);

  const handleSocialConnection = async (social) => {
    setLoading(true);
    try {
      let authResponse;
      switch (social?.reviewPlatformId) {
        case 9:
        case 10:
          authResponse = await handleFBLogin(
            appKey,
            fbConfigKey,
            inConfigKey,
            social?.reviewPlatformId
          );
          if (authResponse) {
            const fbAccessToken = authResponse?.accessToken;
            setFacebookUserId(authResponse?.userID);
            if (fbAccessToken && userCurrentBusinessDetails?.id) {
              await fetchSocialConnections(
                social?.reviewPlatformId,
                userCurrentBusinessDetails,
                setConnectionData,
                setAccessToken,
                setOpenDialog,
                setSelectedPage,
                setLoading,
                fbAccessToken,
                enqueueSnackbar
              );
            } else {
              console.log('Missing Facebook access token or business details.');
            }
          } else {
            console.log('Facebook login failed or was cancelled.');
          }
          break;
        case 11:
          // handleXLogin();
          break;
        case 12:
          setIsLinkedin(true);
          setOpenLinkedinPopUp(true);
          break;
        default:
          console.log('Unsupported review platform ID.');
          break;
      }
    } catch (error) {
      console.error('Error during social connection:', error);
    } finally {
      setLoading(false);
    }
    setSocialPlatformId(social?.reviewPlatformId);
  };

  const handleClose = () => {
    setOpenDialog(false);
  };

  useEffect(() => {
    if (profileData && Object.keys(profileData)?.length > 0 && selectedAccount) {
      history.push(`quick-social/social-connect/profile/${params?.id ? params?.id : ''}`, {
        selectedAccount,
        profileData,
      });
    }
  }, [params.id, profileData, selectedAccount]);

  if (loading) {
    return <FuseLoading />;
  }

  return (
    <>
      <div className="lg:p-24 p-16">
        <p className="md:text-28 text-20 font-600 lg:mb-20 mb-16">
          {t('navigation.socialConnects')}
        </p>
        <div className="bg-white p-20 rounded-md">
          <p className="text-16 sm:text-24 font-600">{t('quickSocial.connectSocial')}</p>
          <div className="flex flex-col mt-14 gap-32 ">
            {socialData &&
              socialData?.length > 0 &&
              socialData?.map((social, i) => (
                <div
                  key={i}
                  className="border-1 rounded-md px-24 sm:px-40 py-20 sm:py-32 border-grey-400 flex gap-20 sm:gap-0 items-start sm:flex-row flex-col justify-between bg-grey-50"
                >
                  <div className="flex items-center gap-20">
                    <img
                      className="mx-0 sm:mx-auto rounded-full w-40 h-40 sm:w-48 sm:h-48 "
                      src={social?.logo}
                      alt={social?.name}
                    />
                    <h3 className="text-16 sm:text-20 font-500">
                      {social.name.charAt(0).toUpperCase() + social.name.slice(1)}
                    </h3>
                  </div>
                  {social?.isConnected ? (
                    <div className="flex gap-10 pt-10 flex-col xs:w-auto w-full xs:flex-row justify-center items-center">
                      <Button
                        className="rounded-md text-14 w-full xs:w-auto sm:text-16 font-500 px-16"
                        size="medium"
                        variant="contained"
                        color="secondary"
                      >
                        <img className="mx-8 w-12" src="assets/images/business/check.svg" alt="" />
                        {t('quickSocial.connected')}
                        {linkedinRefreshIcon && social?.name === 'linkedin' && (
                          <CircularProgress
                            className="text-quick-social absolute mx-auto"
                            size={24}
                          />
                        )}
                      </Button>
                      <Button
                        className="rounded-md px-16 w-full xs:w-auto text-14 sm:text-16 font-500"
                        size="medium"
                        variant="outlined"
                        color="quickSocial"
                        onClick={() => {
                          setSelectedAccount(social);
                          fetchProfileData(social?.reviewPlatformId);
                        }}
                      >
                        {t('quickSocial.profileDetails')}
                      </Button>
                    </div>
                  ) : (
                    <Button
                      className="rounded-md text-14 px-16 w-full xs:w-160 sm:px-28 sm:text-16"
                      size="medium"
                      variant="contained"
                      color="quickSocial"
                      onClick={() => handleSocialConnection(social)}
                    >
                      {t('quickSocial.connect')}
                    </Button>
                  )}
                </div>
              ))}
          </div>
        </div>
        {connectionData?.length > 0 && openDialog && (
          <SelectPlatformBox
            onClose={handleClose}
            openDialog={openDialog}
            socialPlatformId={socialPlatformId}
            setSocialPlatformId={setSocialPlatformId}
            connectionData={connectionData}
            setConnectionData={setConnectionData}
            setSelectedPage={setSelectedPage}
            selectedPage={selectedPage}
            setOpenDialog={setOpenDialog}
            setLoading={setLoading}
            handleSocialResponse={() =>
              handleSocialResponse(
                socialPlatformId,
                accessToken,
                facebookUserId,
                selectedPage,
                userCurrentBusinessDetails,
                setLoading,
                enqueueSnackbar,
                fetchAgentSupportStatus,
                setConnectionData,
                setOpenDialog
              )
            }
            handleClose={handleClose}
          />
        )}
      </div>
      {isLinkedin && (
        <Linkedin
          setIsLinkedin={setIsLinkedin}
          setLinkedinRefreshIcon={setLinkedinRefreshIcon}
          fetchAgentSupportStatus={fetchAgentSupportStatus}
          setOpenLinkedinPopUp={setOpenLinkedinPopUp}
          openLinkedinPopUp={openLinkedinPopUp}
        />
      )}
    </>
  );
};

export default SocialConnects;
