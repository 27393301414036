import {
  Button,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogActions,
  Icon,
  Typography,
  TextField,
} from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import history from '@history';
import * as yup from 'yup';
import { t } from 'i18next';
import InputLabel from '@mui/material/InputLabel';
import { useSelector } from 'react-redux';
import _ from '@lodash';
import { useSnackbar } from 'notistack';
import { handleApiRequest } from 'src/app/main/common/common';
import quickAdsQuery from 'src/app/main/query/quickAds';
import { selectDashboardData } from 'app/store/userSlice';

const SelectObjective = (props) => {
  const navigate = useNavigate();
  const { openValue, cancelCall } = props;
  const userCurrentBusinessDetails = useSelector(selectDashboardData);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [campaignObjectives, setCampaignObjectives] = useState([]);
  const [objective, setObjective] = useState({});
  const [campaignName, setCampaignName] = useState('');
  const [draftId, setDraftId] = useState('');

  if (draftId) {
    history.push('/create-ads', { data: objective, campaignName, draftId });
  }

  useEffect(() => {
    const fetchDashboard = async () => {
      const payload = {
        query: quickAdsQuery.getQuickAdsObjective,
      };
      const result = await handleApiRequest(payload);
      if (result?.getQuickAdsObjective?.status === 200) {
        setCampaignObjectives(result?.getQuickAdsObjective?.data);
      } else {
        enqueueSnackbar(result?.getQuickAdsObjective?.message, {
          variant: 'error',
          autoHideDuration: 2000,
        });
      }
    };
    if (openValue) {
      fetchDashboard();
    }
  }, [enqueueSnackbar, openValue]);

  const handleClose = () => {
    cancelCall();
    setObjective({});
    setCampaignName('');
  };

  const createAndUpdateAdsCampaign = useCallback(async () => {
    try {
      if (campaignName && objective) {
        const payload = {
          query: quickAdsQuery.createAndUpdateAdsCampaign,
          variables: {
            adDraftId: null,
            userBusinessId: Number(userCurrentBusinessDetails?.id),
            campaign: {
              campaignName,
              objectiveId: objective?.id,
            },
          },
        };
        const result = await handleApiRequest(payload);
        if (result?.createAndUpdateAdsCampaign?.status === 200) {
          setDraftId(result?.createAndUpdateAdsCampaign?.data?.adDraftId);
        } else {
          enqueueSnackbar(result?.createAndUpdateAdsCampaign?.message, {
            variant: 'error',
            autoHideDuration: 2000,
          });
        }
      }
    } catch {
      console.error('An error occurred');
    }
  }, [campaignName, enqueueSnackbar, objective, userCurrentBusinessDetails?.id]);

  return (
    <Dialog
      classes={{
        paper: 'm-24 rounded-md',
      }}
      open={openValue}
      onKeyDown={(event) => {
        if (event.keyCode === 27) {
          handleClose();
        }
      }}
      fullWidth
      maxWidth="xs"
    >
      <DialogTitle className="p-0 border-b-2 border-solid text-gray-A500 p-20">
        <Icon
          onClick={() => handleClose()}
          className="text-24 absolute top-20 right-20 font-normal cursor-pointer text-black hover:text-gray-700 focus:text-gray-700"
        >
          {t('appPromotionSlider.cancel')}
        </Icon>
        <Typography className="text-20 font-semibold text-black w-full">
          {t('quickAds.campaignObjective')}
        </Typography>
      </DialogTitle>
      <div className="p-20">
        <div className="mb-20">
          <h3 className="text-18 font-semibold mb-10">{t('quickAds.enterCampaignName')}</h3>
          <TextField
            className="w-full border-grey-300 bg-gray-A500"
            value={campaignName}
            size="medium"
            onChange={(e) => setCampaignName(e.target.value)}
            placeholder={t('quickAds.enterCampaignName')}
            sx={{
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: '#f5f6fa',
                },
                '&:hover fieldset': {
                  borderColor: '#219EBC',
                },
              },
              '& .MuiInputBase-input': {
                fontWeight: '600',
                lineHeight: '22px',
                fontSize: 16,
              },
            }}
          />
        </div>
        <h3 className="text-18 font-semibold mb-10">
          {t('business.businessAttribute.formField.select')} {t('quickAds.campaignObjective')}
        </h3>
        {campaignObjectives &&
          campaignObjectives?.length > 0 &&
          campaignObjectives?.map((item, index) => {
            return (
              <div
                key={index}
                className={`block mt-10 border-2 border-solid  px-20 py-16 shadow rounded-md cursor-pointer ${
                  objective?.id === item?.id ? 'border-quick-social' : 'border-gray-A500'
                }`}
                onClick={() => {
                  setObjective(item);
                }}
                aria-hidden
              >
                <div className="flex items-center">
                  <img className="w-56 h-56" src={item?.icon} alt={item?.name} />
                  <div className="ml-16">
                    <Typography className="text-18 font-medium">{item?.name}</Typography>
                    <Typography className="text-14 font-medium text-grey-600">
                      {item?.description}
                    </Typography>
                  </div>
                </div>
              </div>
            );
          })}
        <div className="flex justify-end">
          <div className="text-center flex gap-6 ">
            <Button
              onClick={() => {
                handleClose();
              }}
              className="rounded-md mt-16"
              color="quickSocial"
              variant="outlined"
            >
              {t('common.cancel')}
            </Button>
            <Button
              disabled={!campaignName || !objective?.id}
              onClick={() => {
                createAndUpdateAdsCampaign();
              }}
              className="rounded-md mt-16"
              color="quickSocial"
              variant="contained"
            >
              {t('quickCampaigns.createCampaigns')}
            </Button>
          </div>
        </div>
      </div>
    </Dialog>
  );
};

export default SelectObjective;
