import React from 'react';
import { Dialog, DialogContent } from '@mui/material';
import { t } from 'i18next';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import SocialReviews from './SocialReviews';

const CreateReviewPost = ({ open, onClose, textColor, selectedImage, selectedCategories }) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="createPost-confirmation-dialog"
      classes={{
        paper: `m-24 p-6 sm:p-28 business-type-dropdown border-t-8 border-solid text-center border-t-${textColor} border-${textColor} rounded-md`,
      }}
      PaperProps={{
        sx: {
          minWidth: { xs: '90%', sm: '90%', md: '80%', lg: '80%' },
          backgroundColor: '#f5f6fa',
        },
      }}
    >
      <IconButton className="absolute top-4 right-4" onClick={onClose} color="inherit">
        <CloseIcon className="w-32 h-32 text-grey-700" />
      </IconButton>

      <DialogContent className="p-0 mb-24">
        <SocialReviews
          reviewTitle={t('quickSocial.selectReview')}
          selectedImage={selectedImage}
          selectedCategories={selectedCategories}
        />
      </DialogContent>
    </Dialog>
  );
};

export default CreateReviewPost;
