import { useEffect, useState, useRef, useCallback, useMemo } from 'react';
import { debounce } from 'lodash';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import FuseLoading from '@fuse/core/FuseLoading';
import FilterListIcon from '@mui/icons-material/FilterList';
import { useSnackbar } from 'notistack';
import history from '@history';
import ShareIcon from '@mui/icons-material/Share';
import OpenInFullIcon from '@mui/icons-material/OpenInFull';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import PauseIcon from '@mui/icons-material/Pause';
import SearchIcon from '@mui/icons-material/Search';
import manageBusinessData from 'src/app/main/query/manageBusiness';
import quickAdsQuery from 'src/app/main/query/quickAds';
import { getAllUserBusinessSubscription, selectDashboardData } from 'app/store/userSlice';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  TextField,
  InputAdornment,
  Icon,
  Checkbox,
  IconButton,
  MenuItem,
  Menu,
  Radio,
} from '@mui/material';
import Pagination from 'src/app/component/table/Pagination';
import ShowAdsPost from '../showAdsPost';
import {
  formatDateWithTimeZone,
  handleApiRequest,
  numberFormateOnTimeZone,
} from '../../../common/common';
import SelectObjective from '../add-ads-campaign/select-objective';

const filterList = ['DRAFT', 'COMPLETED', 'ACTIVE', 'PAUSED', 'SCHEDULED'];

function AdsCampaign() {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalCount, setTotalCount] = useState();
  const [showFilterOption, setShowFilterOption] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedFilters, setSelectedFilters] = useState('');
  const [ADSdata, setADSData] = useState();
  const [filterIds, setFilterIds] = useState([]);
  const [openSelectObjective, setOpenSelectObjective] = useState(false);
  const userCurrentBusinessDetails = useSelector(selectDashboardData);
  const [hoveredRow, setHoveredRow] = useState(null);
  const [showList, setShowList] = useState(false);
  const [cardsData, setCardsData] = useState([]);
  const [menuState, setMenuState] = useState({
    anchorEl: null,
    activeIndex: null,
  });
  const [popupData, setPopupData] = useState({
    isOpen: false,
    image: '',
    position: {},
    id: null,
  });
  const connected = cardsData?.length > 0 && cardsData?.some((item) => item?.isConnect);
  const fetchCardDetails = useCallback(async () => {
    try {
      const payload = {
        query: quickAdsQuery.getAdsConnectApis,
        variables: {
          businessId: Number(userCurrentBusinessDetails?.id),
        },
      };
      const result = await handleApiRequest(payload);
      if (result?.getAdsConnectApis?.status === 200) {
        const cards = result?.getAdsConnectApis?.data || [];
        setCardsData(cards);
      }
    } catch (error) {
      console.error('error', error);
    }
  }, [userCurrentBusinessDetails?.id]);

  useEffect(() => {
    if (userCurrentBusinessDetails?.id) {
      fetchCardDetails();
    }
  }, [fetchCardDetails, userCurrentBusinessDetails?.id]);

  const fetchAdsCampaign = useCallback(async () => {
    try {
      const payload = {
        query: quickAdsQuery.getAdsCampaign,
        variables: {
          quickHubAdsAccountId: Number(userCurrentBusinessDetails?.quickHubAdsAccountId),
          pageNumber: page,
          pageSize,
          campaignStatusFilter: selectedFilters,
          searchText: searchTerm,
        },
      };
      const result = await handleApiRequest(payload);
      if (result?.getAdsCampaign?.status === 200) {
        setADSData(result?.getAdsCampaign?.data?.ads);
        setTotalCount(result?.getAdsCampaign?.totalCount);
      }
    } catch (err) {
      console.error('Error fetching data:', err);
    }
  }, [
    page,
    pageSize,
    searchTerm,
    selectedFilters,
    userCurrentBusinessDetails?.quickHubAdsAccountId,
  ]);

  useEffect(() => {
    if (userCurrentBusinessDetails?.quickHubAdsAccountId) {
      fetchAdsCampaign();
    }
  }, [fetchAdsCampaign, userCurrentBusinessDetails?.quickHubAdsAccountId]);

  const handleChangePage = (event, value) => {
    setPage(value + 1);
  };

  const handleChangeRowsPerPage = (event, value) => {
    setPageSize(event.target.value);
    setPage(1);
  };

  const handleSelectFilter = (filter) => {
    setSelectedFilters(filter);
    setShowFilterOption(false);
    setPage(1);
  };
  const handleCloseMenu = () => {
    setShowList(false);
    setMenuState({
      anchorEl: null,
      activeIndex: null,
    });
  };

  const handleEdit = (data) => {
    history.push('/create-ads', {
      adsData: data,
    });
  };

  const handleOpenMenu = (event, index) => {
    event.stopPropagation();
    setShowList(true);
    setMenuState({
      anchorEl: event.currentTarget,
      activeIndex: index,
    });
  };

  const publishAds = useCallback(
    async (id) => {
      try {
        if (id) {
          const payload = {
            query: quickAdsQuery.publishAds,
            variables: {
              adsCampaignId: id,
            },
          };
          const result = await handleApiRequest(payload);
          if (result?.publishAds?.status === 200) {
            fetchAdsCampaign();
            handleCloseMenu();
            enqueueSnackbar(result?.publishAds?.message, {
              variant: 'success',
              autoHideDuration: 2000,
            });
          } else {
            enqueueSnackbar(result?.publishAds?.message, {
              variant: 'error',
              autoHideDuration: 2000,
            });
          }
        }
      } catch {
        console.error('An error occurred');
      }
    },
    [enqueueSnackbar, fetchAdsCampaign]
  );

  const handleDelete = async (data, from) => {
    try {
      const payload = {
        query: quickAdsQuery.pauseOrDeleteAdsCampaign,
        variables: {
          adsCampaignId: Number(data?.id),
          operation: from,
        },
      };
      const result = await handleApiRequest(payload);
      if (result?.pauseOrDeleteAdsCampaign?.status === 200) {
        fetchAdsCampaign();
        handleCloseMenu();
        enqueueSnackbar(result?.pauseOrDeleteAdsCampaign?.message, {
          variant: 'success',
          autoHideDuration: 2000,
        });
      }
    } catch (err) {
      console.error('Error fetching data:', err);
    }
  };

  if (loading) {
    return <FuseLoading />;
  }

  const handleClosePopup = () => {
    setPopupData({ isOpen: false, image: '', position: {}, id: null });
  };

  const viewPost = (event, image, rowIndex, id) => {
    setPopupData({
      isOpen: true,
      image,
      position: rowIndex < 3 ? 'below' : 'above',
      id,
    });
  };

  const closePopup = () => {
    setPopupData({ isOpen: false, image: null, position: {}, id: null });
  };

  const handleClearSearch = () => {
    setSearchTerm('');
  };

  return (
    <div className="p-20 lg:p-24 h-full">
      <div>
        <div className="flex  flex-col md:flex-row w-full items-start md:items-center justify-between mb-20">
          <Typography
            initial={{ x: -20 }}
            animate={{ x: 0, transition: { delay: 0.2 } }}
            delay={300}
            className="text-20 md:text-28 font-bold mb-16 md:mb-0"
          >
            {t(`quickAds.adsCampaign`)}
          </Typography>
          <div className="flex items-start sm:items-center flex-col sm:flex-row gap-10 sm:gap-0 md:w-auto w-full md:justify-normal justify-between quick-social">
            {connected && (
              <div className="flex sm:w-auto w-full">
                <TextField
                  placeholder="Search"
                  variant="outlined"
                  margin="normal"
                  value={searchTerm}
                  disabled={!searchTerm && ADSdata?.length <= 0}
                  className="bg-gray-A500 border-none rounded-sm border-grey-A500 m-0 max-h-40 sm:w-320 w-full"
                  onChange={(e) => {
                    setPage(1);
                    setSearchTerm(e?.target?.value);
                  }}
                  InputProps={{
                    endAdornment: searchTerm ? (
                      <InputAdornment position="end">
                        <Icon
                          color="quickSocial"
                          className="cursor-pointer text-20"
                          onClick={handleClearSearch}
                        >
                          {t(`quickAds.close`)}
                        </Icon>
                      </InputAdornment>
                    ) : (
                      <InputAdornment position="end">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
            )}
            {(connected || ADSdata?.length <= 0) && (
              <div className="relative">
                <Button
                  className="rounded-md w-28 min-w-28 p-20 h-24 min-h-24 mx-16"
                  color="quickSocial"
                  variant="contained"
                  disabled={(!selectedFilters && ADSdata?.length <= 0) || !connected}
                  onClick={() => setShowFilterOption((prev) => !prev)}
                >
                  <FilterListIcon />
                </Button>
                <div className="quick-social">
                  {showFilterOption && (
                    <div className="absolute z-10 top-60 right-16 bg-gray-A500 min-w-320 w-full rounded-md shadow p-14">
                      <div className="flex items-center justify-between mb-12">
                        <h3 className="text-20 font-semibold">
                          {t(`users.UserList.Filter.mainBtn`)}
                        </h3>
                        <Icon
                          className="cursor-pointer text-18"
                          aria-hidden
                          onClick={() => setShowFilterOption(false)}
                        >
                          {t(`quickAds.close`)}
                        </Icon>
                      </div>
                      <div className="flex items-center justify-between mt-10">
                        <Typography className="text-16 text-grey-800 font-medium capitalize">
                          {t(`menuItems.all`)}
                        </Typography>
                        <Checkbox
                          className="p-0 text-grey-300 rounded-md"
                          checked={selectedFilters?.length === filterList?.length}
                          onChange={() => handleSelectFilter('all')}
                        />
                      </div>
                      {filterList &&
                        filterList?.map((list, index) => (
                          <div key={index} className="flex items-center justify-between mt-10">
                            <Typography className="text-16 text-grey-800 font-medium capitalize">
                              {list.charAt(0).toUpperCase() + list.slice(1).toLowerCase()}
                            </Typography>
                            <Radio
                              className="p-0 text-grey-300 rounded-md"
                              checked={selectedFilters === list}
                              onChange={() => handleSelectFilter(list)}
                            />
                          </div>
                        ))}
                      {/* <div className="flex items-center justify-between mt-10">

                      <Typography className="text-16 text-grey-800 font-medium">
                        {t(`common.active`)}
                      </Typography>
                      <Checkbox
                        className="p-0 text-grey-300 rounded-md"
                        // checked={filterIds.some(
                        //   (filterId) => filterId?.key === item?.key
                        // )}
                        // onChange={() => handleSelectFilter(item)}
                      />
                    </div> */}
                      <div className="flex mt-16 gap-12 justify-end">
                        <Button
                          className="rounded-md py-0 max-w-128 w-full text-16 font-medium min-h-32 bg-quick-social-100"
                          size="small"
                          variant="outlined"
                          color="quickSocial"
                          type="button"
                          onClick={() => {
                            setSelectedFilters('');
                            setShowFilterOption(false);
                          }}
                        >
                          {t(`quickCampaign.campaign.clearFilter`)}
                          <Icon className="text-16">{t(`quickAds.close`)}</Icon>
                        </Button>
                        {/* <Button

                        className="rounded-md py-0 max-w-128 w-full text-16 font-medium min-h-32"
                        size="small"
                        variant="contained"
                        color="quickSocial"
                        type="button"
                        onClick={() => {
                          fetchAdsCampaign();
                          setShowFilterOption(false);
                        }}
                      >
                        {t('quickChat.apply')}
                      </Button> */}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            )}
            <div>
              <Button
                className="rounded-md h-40 min-h-40 text-18 font-medium"
                color="quickSocial"
                variant="contained"
                disabled={!connected}
                onClick={() => setOpenSelectObjective(true)}
              >
                <Icon>{t(`quickAds.add`)}</Icon> {t(`quickCampaign.campaign.createCampaign`)}
              </Button>
            </div>
          </div>
        </div>

        {connected && ADSdata && ADSdata?.length > 0 ? (
          <TableContainer
            component={Paper}
            className="rounded-md border-1 border-solid border-grey-A500 shadow"
          >
            <Table>
              <TableHead className="border-b-2 border-grey-400">
                <TableRow>
                  <TableCell className="font-bold text-18">
                    {t('quickCampaign.campaign.srNo')}
                  </TableCell>
                  <TableCell className="font-bold text-18">{t('quickAds.dateCreated')}</TableCell>
                  <TableCell className="font-bold  text-18">{t('quickSocialPost.post')}</TableCell>
                  <TableCell className="font-bold text-18">
                    {t('quickCampaign.campaign.campaignName')}
                  </TableCell>
                  <TableCell className="font-bold  text-18">
                    {t('listManagement.startDate')}
                  </TableCell>
                  <TableCell className="font-bold  text-18">
                    {t('listManagement.endDate')}
                  </TableCell>
                  <TableCell className="font-bold  text-18">{t('common.status')}</TableCell>
                  <TableCell className="font-bold  text-18">{t('quickAds.views')}</TableCell>
                  <TableCell className="font-bold  text-18">{t('quickAds.leads')}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {ADSdata &&
                  ADSdata?.length > 0 &&
                  ADSdata?.map((data, index) => (
                    <TableRow
                      key={index}
                      className="cursor-pointer hover:bg-quick-social-100 hover:bg-opacity-30 border-b-2"
                      onMouseEnter={() => setHoveredRow(index)}
                      onMouseLeave={() => setHoveredRow(null)}
                      onClick={() =>
                        history.push(`/ads-campaign-details/${data?.id}`, {
                          ADSdata: data,
                        })
                      }
                    >
                      <TableCell className="font-medium text-16 p-12">{index + 1}</TableCell>
                      <TableCell className="font-medium text-16 p-12">
                        {formatDateWithTimeZone(data?.createdTime, 'MMM DD, YYYY')}
                      </TableCell>
                      <TableCell
                        className="font-medium text-12 md:text-16 p-12 relative"
                        onMouseEnter={(e) => viewPost(e, data?.mediaUrl, index, data?.id)}
                        onMouseLeave={closePopup}
                      >
                        <img
                          src="assets/images/quick-hub/quick-ads/post.png"
                          onMouseEnter={(e) => viewPost(e, data?.mediaUrl, index, data?.id)}
                          onMouseLeave={closePopup}
                          aria-hidden
                          alt="Post"
                          width={24}
                          height={24}
                        />
                        {popupData?.isOpen &&
                          popupData?.image === data?.mediaUrl &&
                          popupData?.id === data?.id && (
                            <div
                              className="absolute bg-white border shadow-lg p-2 rounded z-50 "
                              style={{
                                top: popupData?.position === 'below' ? '30px' : '-150px',
                                left: '30%',
                                transform: 'translateX(-50%)',
                              }}
                            >
                              {popupData?.image ? (
                                <img
                                  src={popupData?.image}
                                  alt="Preview"
                                  className="min-w-160 h-144 object-contain"
                                />
                              ) : (
                                <div className="border-1 border-gray-A500 shadow-2 shadow-A500 min-w-160 h-144 flex justify-center items-center bg-quick-social-100">
                                  <p className="font-bold text-40 text-quick-social">
                                    {data?.name?.charAt(0).toUpperCase()}
                                  </p>
                                </div>
                              )}
                            </div>
                          )}
                      </TableCell>
                      <TableCell className="font-medium text-12 md:text-16 p-12">
                        <span
                          onClick={() =>
                            history.push(`/ads-campaign-details/${data?.id}`, {
                              ADSdata: data,
                            })
                          }
                          aria-hidden
                        >
                          {data?.name}
                        </span>
                      </TableCell>
                      <TableCell className="font-medium text-16 p-12">
                        {formatDateWithTimeZone(data?.scheduleDate, 'MMM DD, YYYY')}
                      </TableCell>
                      <TableCell className="font-medium text-12 md:text-16 p-12">
                        {formatDateWithTimeZone(data?.endDate, 'MMM DD, YYYY')}
                      </TableCell>
                      <TableCell className="p-12 border-b-0">
                        <span
                          className={`${
                            data?.status === 'SCHEDULED'
                              ? 'bg-quick-social-100 text-quick-social border-1 border-quick-social'
                              : data?.status === 'ACTIVE'
                              ? 'bg-green-100 text-quick-review border-1 border-quick-review'
                              : data?.status === 'COMPLETED'
                              ? 'bg-[#f1cdea] text-[#C837AB] border-1 border-[#C837AB]'
                              : data?.status === 'PAUSED'
                              ? 'bg-quick-campaign-50 text-quick-campaign border-1 border-quick-campaign'
                              : 'bg-yellow-100 text-quick-chat border-1 border-quick-chat'
                          } rounded-full text-14 font-medium text-center px-10 py-5`}
                        >
                          {data?.status}
                        </span>
                      </TableCell>
                      <TableCell className="font-medium text-12 md:text-16 p-12">
                        {data?.stats?.views > 0
                          ? numberFormateOnTimeZone(data?.stats?.views)
                          : '--'}
                      </TableCell>
                      <TableCell className="font-medium text-12 md:text-16 p-12">
                        {data?.stats?.leads > 0
                          ? numberFormateOnTimeZone(data?.stats?.leads)
                          : '--'}
                      </TableCell>
                      <TableCell
                        className="font-medium text-12 md:text-16 p-12"
                        onClick={(event) => event.stopPropagation()}
                      >
                        {hoveredRow === index ? (
                          <div
                            role="button"
                            tabIndex={0}
                            aria-label="Open menu"
                            onClick={(event) => {
                              event.stopPropagation();
                              handleOpenMenu(event, index, data);
                            }}
                            onKeyDown={(e) => {
                              if (e.key === 'Enter' || e.key === ' ') {
                                (event) => handleOpenMenu(event, index, data);
                              }
                            }}
                            className="cursor-pointer"
                          >
                            <MoreVertIcon />
                          </div>
                        ) : (
                          <div className="bg-transparent rounded-full h-24 w-24" />
                        )}
                        {showList && menuState?.activeIndex === index && (
                          <div className="">
                            <Menu
                              anchorEl={menuState?.anchorEl}
                              open={Boolean(menuState?.anchorEl)}
                              onClose={handleCloseMenu}
                              classes={{ paper: 'min-w-112 shadow-none' }}
                              anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'right',
                              }}
                              transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                              }}
                              sx={{
                                '& .MuiPaper-root': {
                                  boxShadow: '1px 1px 3px gray',
                                },
                              }}
                            >
                              {data?.status !== 'COMPLETED' && (
                                <MenuItem
                                  onClick={(event) => {
                                    event.stopPropagation();
                                    handleEdit(data);
                                  }}
                                >
                                  <Icon className="text-20 mr-8 ">{t('quickSocial.edit1')}</Icon>
                                  <span className="text-16 font-500">{t('common.edit')}</span>
                                </MenuItem>
                              )}
                              {data?.status === 'ACTIVE' && (
                                <MenuItem
                                  onClick={(event) => {
                                    event.stopPropagation();
                                    handleDelete(data, 'PAUSE');
                                  }}
                                >
                                  <PauseIcon className="text-20 mr-8" />
                                  <p className="text-16 font-500">{t('quickAds.pause')}</p>
                                </MenuItem>
                              )}
                              {data?.status === 'PAUSED' && (
                                <MenuItem
                                  onClick={(event) => {
                                    event.stopPropagation();
                                    publishAds(data?.id);
                                  }}
                                >
                                  <PlayArrowIcon className="text-20 mr-8" />
                                  <p className="text-16 font-500">{t('quickAds.resume')}</p>
                                </MenuItem>
                              )}
                              {data && (
                                <MenuItem
                                  onClick={(event) => {
                                    event.stopPropagation();
                                    handleDelete(data, 'DELETE');
                                  }}
                                >
                                  <Icon className="text-20 mr-8 ">{t('quickSocial.delete1')}</Icon>
                                  <span className="text-16 font-500">{t('common.delete')}</span>
                                </MenuItem>
                              )}
                            </Menu>{' '}
                          </div>
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <div className="flex flex-col items-center justify-center max-w-384 mx-auto custom-height">
            <img
              src="assets/images/quick-hub/quick-ads/no-ads.png"
              alt="No ads"
              className="h-320 w-320 mb-16"
            />
            <div className="text-28 font-bold mb-16">
              {t('quickCampaign.campaign.noCampaignYet')}
            </div>
            <p className="text-17 font-500 mb-16 text-gray-700 text-center">
              {t('quickAds.noAdsContent')}
            </p>
            <Button
              variant="outlined"
              color="quickSocial"
              size="medium"
              disabled={!connected}
              className="mb-16 rounded-md text-16 font-500 bg-quick-social-100 text-black"
              onClick={() => setOpenSelectObjective(true)}
            >
              {t('quickCampaign.campaign.createYourFirstCampaign')}
            </Button>
          </div>
        )}
      </div>
      {/* <ShowAdsPost
        open={popupData?.isOpen}
        onClose={handleClosePopup}
        image={popupData?.image}
        position={popupData?.position}
      /> */}
      {connected && totalCount > 10 && (
        <div className="bg-white px-52 py-32 bottom-0 right-0 w-full flex justify-end">
          <Pagination
            totalCount={totalCount}
            rowsPerPage={pageSize}
            page={page}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </div>
      )}
      <SelectObjective
        openValue={openSelectObjective}
        cancelCall={() => setOpenSelectObjective(false)}
      />
    </div>
  );
}
export default AdsCampaign;
