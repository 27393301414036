import React, { useEffect, useState, useCallback } from 'react';
import {
  Typography,
  CircularProgress,
  MenuItem,
  Select,
  TextField,
  InputLabel,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Icon,
} from '@mui/material';
import { yupResolver } from '@hookform/resolvers/yup';
import FuseLoadingWhiteBg from '@fuse/core/FuseLoading/FuseLoadingWhiteBg';
import ConformationDialogBox from 'src/app/main/CommonComponent/ConformationDialogBox';
import PhoneInput from 'react-phone-input-2';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';
import _ from '@lodash';
import { t } from 'i18next';
import manageBusinessData from 'src/app/main/query/manageBusiness';
import {
  handleApiRequest,
  handlePhoneNumberValidationCheck,
  getDecryptData,
} from 'src/app/main/common/common';
import { useSnackbar } from 'notistack';
import UserService from '../../../../services/userService';

const defaultValues = {
  accountSid: '',
  authToken: '',
};

const Sms = (props) => {
  const schema = yup.object().shape({
    accountSid: yup.string().trim().required(t('configure.requiredAccountSid')),
    authToken: yup.string().trim().required(t('configure.requiredAuthToken')),
  });

  const loginUserData = UserService.getUserData();
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(true);
  const [loadingSave, setLoadingSave] = useState(false);
  const [loadingTestSms, setLoadingTestSms] = useState(false);
  const [serviceProviderId, setServiceProviderId] = useState('name');
  const [openTestSmSDialog, setOpenTestSmSDialog] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [massageValue, setMassageValue] = useState();
  const [phone, setPhone] = useState();
  const [phoneValue, setPhoneValue] = useState();
  const [errorMessage, setErrorMessage] = useState(true);
  const [twilioPhoneValue, setTwilioPhoneValue] = useState();
  const [twilioPhone, setTwilioPhone] = useState();
  const [formData, setFormData] = useState({});
  const [quickHubUserCredential, setQuickHubUserCredential] = useState({});
  const [errorMessageTwilio, setErrorMessageTwilio] = useState(true);
  const [openDialogueForConformation, setOpenDialogueForConformation] = useState(false);

  const { control, watch, reset, handleSubmit, formState, getValues, setError, setValue } = useForm(
    {
      mode: 'onChange',
      defaultValues,
      resolver: yupResolver(schema),
    }
  );
  const { isValid, dirtyFields, errors } = formState;

  const getQuickHubUserCredentialData = useCallback(async () => {
    setLoading(true);
    try {
      const payload = {
        query: manageBusinessData.getQuickHubUserCredential,
      };
      const result = await handleApiRequest(payload);
      if (result?.getQuickHubUserCredential?.status === 200) {
        const decryptedData = getDecryptData(result?.getQuickHubUserCredential?.data);
        const parsedData = JSON.parse(decryptedData);
        setQuickHubUserCredential(parsedData);
      }
    } catch (err) {
      console.error('Error fetching data:', err);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    getQuickHubUserCredentialData();
  }, [getQuickHubUserCredentialData]);

  useEffect(() => {
    if (
      isEdit &&
      quickHubUserCredential?.smsCred &&
      Object.values(quickHubUserCredential?.smsCred).length > 0
    ) {
      setServiceProviderId(quickHubUserCredential?.smsCred?.provider);
      const dialCode = quickHubUserCredential?.smsCred?.countryCode || '';
      const phoneNumber = quickHubUserCredential?.smsCred?.twilioPhoneNumber || '';
      setTwilioPhoneValue(`${dialCode} ${phoneNumber}`);
      setTwilioPhone({ dialCode, phoneNumber });
      setValue('phone_number_twilio', phoneNumber, {
        shouldDirty: true,
        shouldValidate: true,
      });
    }
  }, [isEdit, setValue, quickHubUserCredential?.smsCred]);

  const initDialog = useCallback(() => {
    if (
      isEdit &&
      quickHubUserCredential?.smsCred &&
      Object.values(quickHubUserCredential?.smsCred).length > 0
    ) {
      reset({
        accountSid: quickHubUserCredential?.smsCred?.accountSid,
        authToken: quickHubUserCredential?.smsCred?.authToken,
      });
    } else {
      reset(defaultValues);
    }
  }, [isEdit, reset, quickHubUserCredential?.smsCred]);

  useEffect(() => {
    if (isEdit) {
      initDialog();
    }
  }, [isEdit, initDialog]);

  const onSubmit = async (data) => {
    setLoadingSave(true);
    try {
      const payload = {
        query: manageBusinessData.addOrUpdateQuickHubUserCredential,
        variables: {
          accountSid: data.accountSid,
          authToken: data.authToken,
          isDataForEmail: false,
          countryCode: twilioPhone?.dialCode.includes('+')
            ? `${twilioPhone?.dialCode}`
            : `+${twilioPhone?.dialCode}`,
          twilioPhoneNumber: `${twilioPhone?.phoneNumber}`,
          provider: serviceProviderId,
          isUpdated: isEdit,
          isGmail: false,
        },
      };
      const result = await handleApiRequest(payload);
      if (result?.addOrUpdateQuickHubUserCredential?.status === 200) {
        enqueueSnackbar(result?.addOrUpdateQuickHubUserCredential?.message, {
          variant: 'success',
        });
        reset(defaultValues);
        setTwilioPhone(null);
        setTwilioPhoneValue('');
        getQuickHubUserCredentialData();
        if (isEdit) {
          setIsEdit(false);
        }
      } else {
        enqueueSnackbar(result?.addOrUpdateQuickHubUserCredential?.message, {
          variant: 'error',
        });
      }
    } catch (error) {
      console.error('Error saving data:', error);
    } finally {
      setLoadingSave(false);
    }
  };

  const handleFormChange = () => {
    setFormData(watch());
  };

  const sendTestSMS = async () => {
    setLoadingTestSms(true);
    try {
      const payload = {
        query: manageBusinessData.sendTestMessageUsingBusinessOwnerCred,
        variables: {
          accountSid: formData?.accountSid,
          authToken: formData?.authToken,
          countryCode: `+${phone?.dialCode}`,
          message: massageValue,
          phoneNumber: phone?.phoneNumber,
          twilioPhoneNumber: `+${twilioPhone?.dialCode}${twilioPhone?.phoneNumber}`,
        },
      };
      const result = await handleApiRequest(payload);
      if (result?.sendTestMessageUsingBusinessOwnerCred?.status === 200) {
        enqueueSnackbar(result?.sendTestMessageUsingBusinessOwnerCred?.message, {
          variant: 'success',
        });
        closeDialog();
      } else {
        enqueueSnackbar(result?.sendTestMessageUsingBusinessOwnerCred?.message, {
          variant: 'error',
        });
      }
    } catch (error) {
      console.error('Error sending test SMS:', error);
    } finally {
      setLoadingTestSms(false);
    }
  };

  const handleDelete = async () => {
    try {
      const payloadData = {
        query: manageBusinessData.deleteQuickHubUserCredential,
        variables: {
          isSms: true,
          isEmail: false,
        },
      };
      const result = await handleApiRequest(payloadData);
      if (result?.deleteQuickHubUserCredential?.status === 200) {
        enqueueSnackbar(result?.deleteQuickHubUserCredential?.message, {
          variant: 'success',
          autoHideDuration: 3000,
        });
        setOpenDialogueForConformation(false);
        getQuickHubUserCredentialData();
      } else {
        enqueueSnackbar(result?.deleteQuickHubUserCredential?.message, {
          variant: 'error',
          autoHideDuration: 3000,
        });
      }
    } catch (error) {
      console.error('Error', error);
    }
  };

  const closeDialog = () => {
    setOpenTestSmSDialog(false);
    setMassageValue('');
    setPhone(null);
    setPhoneValue('');
    setErrorMessage(true);
  };

  const handlePhoneChange = (event, data, from, value, formattedValue) => {
    if (from === 'testSMS') {
      setPhoneValue(event);
      setPhone({
        ...data,
        phoneNumber: Number(event.slice(data?.dialCode?.length)),
      });
      setValue('phone_number', event.slice(data?.dialCode?.length), {
        shouldDirty: true,
        shouldValidate: true,
      });
    } else {
      setTwilioPhoneValue(event);
      setTwilioPhone({
        ...data,
        phoneNumber: Number(event.slice(data?.dialCode?.length)),
      });
      setValue('phone_number_twilio', event.slice(data?.dialCode?.length), {
        shouldDirty: true,
        shouldValidate: true,
      });
    }
  };

  if (loading) {
    return <FuseLoadingWhiteBg />;
  }

  return (
    <>
      {quickHubUserCredential &&
      quickHubUserCredential?.smsCred &&
      Object.keys(quickHubUserCredential?.smsCred).length > 0 &&
      !isEdit ? (
        <>
          <div className="mb-12 mt-10">
            <span className="text-16 font-semibold">{t('configure.serviceProviderName')} : </span>
            <span className="text-16 font-medium text-grey-700">
              {quickHubUserCredential?.smsCred?.provider}
            </span>
          </div>
          <div className="mb-12">
            <span className="text-16 font-semibold">{t('configure.yourAccountSid')} : </span>
            <span className="text-16 font-medium text-grey-700">
              {quickHubUserCredential?.smsCred?.accountSid}
            </span>
          </div>
          <div className="mb-12">
            <span className="text-16 font-semibold">{t('configure.yourAuthToken')} : </span>
            <span className="text-16 font-medium text-grey-700">
              {quickHubUserCredential?.smsCred?.authToken}
            </span>
          </div>
          <div className="mb-12">
            <span className="text-16 font-semibold">{t('configure.twilioPhoneNumber')} : </span>
            <span className="text-16 font-medium text-grey-700">
              {quickHubUserCredential?.smsCred?.countryCode}{' '}
              {quickHubUserCredential?.smsCred?.twilioPhoneNumber}
            </span>
          </div>
          <div className="text-end mb-16">
            <Button
              className="rounded md:px-40 text-16 font-medium mr-24"
              variant="outlined"
              color="quickCampaign"
              onClick={() => setOpenDialogueForConformation(true)}
              type="button"
            >
              {t('common.remove')}
            </Button>
            <Button
              className="rounded md:px-40 mx-10 md:mx-0 text-16 font-medium"
              variant="contained"
              color="quickCampaign"
              onClick={() => setIsEdit(true)}
              type="button"
            >
              {t('common.edit')}
            </Button>
          </div>
        </>
      ) : (
        <>
          <form
            noValidate
            onSubmit={handleSubmit(onSubmit)}
            onChange={handleFormChange}
            className="quick-campaign"
          >
            <div className="py-12">
              <div className="block mb-24">
                <InputLabel id="country" className="text-16 font-medium mb-12 text-grey-900">
                  {t('configure.serviceProviderName')}
                </InputLabel>
                <Controller
                  name="provider"
                  control={control}
                  render={({ field }) => (
                    <Select
                      {...field}
                      className="w-full"
                      id="provider"
                      value={serviceProviderId}
                      size="small"
                      displayEmpty
                      error={!!errors.country}
                      onChange={(e) => {
                        setServiceProviderId(e?.target?.value);
                      }}
                    >
                      <MenuItem value="name" disabled>
                        {t('configure.selectServiceProvider')}
                      </MenuItem>
                      {props.providerList?.map((option, i) => (
                        <MenuItem key={i} value={option.name}>
                          {option.name}
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                />
              </div>
              <div className="block mb-24">
                <InputLabel
                  htmlFor="accountSid"
                  className="text-16 font-medium mb-12 text-grey-900 flex items-center"
                >
                  {t('configure.yourAccountSid')}
                </InputLabel>
                <Controller
                  control={control}
                  name="accountSid"
                  render={({ field }) => (
                    <TextField
                      {...field}
                      placeholder={t('configure.accountSidPlaceHolder')}
                      size="small"
                      id="accountSid"
                      error={!!errors.accountSid}
                      helperText={errors?.accountSid?.message}
                      variant="outlined"
                      required
                      fullWidth
                    />
                  )}
                />
              </div>
              <div className="block mb-24">
                <InputLabel htmlFor="authToken" className="text-16 font-medium mb-12 text-grey-900">
                  {t('configure.yourAuthToken')}
                </InputLabel>
                <Controller
                  control={control}
                  name="authToken"
                  render={({ field }) => (
                    <TextField
                      {...field}
                      placeholder={t('configure.tokenPlaceHolder')}
                      size="small"
                      id="authToken"
                      error={!!errors.authToken}
                      helperText={errors?.authToken?.message}
                      variant="outlined"
                      required
                      fullWidth
                    />
                  )}
                />
              </div>
              <div className="block">
                <InputLabel
                  htmlFor="phone_number_twilio"
                  className="text-16 font-medium mb-12 text-grey-900 flex items-center"
                >
                  {t('configure.twilioPhoneNumber')}
                </InputLabel>
                <PhoneInput
                  placeholder={t('configure.twilioPhoneNumberPlaceholder')}
                  inputStyle={{
                    width: '100%',
                    height: '37px',
                  }}
                  className="w-full"
                  name="phone_number_twilio"
                  required
                  error={!!errors.phone_number_twilio}
                  helperText={errors?.phone_number_twilio?.message}
                  value={twilioPhoneValue}
                  size="small"
                  country={loginUserData?.isoCode?.toLowerCase()}
                  enableSearch="true"
                  onChange={(value, data) => {
                    handlePhoneChange(value, data, 'twilio');
                    const validPhoneNumber = handlePhoneNumberValidationCheck(value, data);
                    setErrorMessageTwilio(validPhoneNumber);
                  }}
                />
                {errorMessageTwilio === false && twilioPhoneValue?.length > 0 && (
                  <p className="text-red mt-5 text-16">{t('productSettings.InvalidPhoneNumber')}</p>
                )}
              </div>
            </div>
            <div className="flex flex-1 w-full items-center justify-end mb-12 mt-16">
              <div className="md:flex gap-24 text-center">
                <Button
                  className="rounded md:px-60 mx-10 md:mx-0 text-16 font-medium"
                  variant="outlined"
                  color="quickCampaign"
                  onClick={() => setOpenTestSmSDialog(true)}
                  type="button"
                  disabled={
                    _.isEmpty(dirtyFields) ||
                    !isValid ||
                    !twilioPhoneValue ||
                    errorMessageTwilio === false
                  }
                >
                  {t('configure.sendTestSMS')}
                </Button>
                <Button
                  className="rounded md:px-60 mx-10 md:mx-0 text-16 font-medium"
                  variant="contained"
                  color="quickCampaign"
                  type="submit"
                  disabled={
                    loadingSave ||
                    _.isEmpty(dirtyFields) ||
                    !isValid ||
                    !twilioPhoneValue ||
                    errorMessageTwilio === false
                  }
                >
                  {isEdit ? `${t('common.update')}` : `${t('common.save')}`}
                  {loadingSave && (
                    <CircularProgress size={24} className="text-quick-campaign absolute mx-auto" />
                  )}
                </Button>
              </div>
            </div>
          </form>
          <Dialog
            classes={{
              paper: 'm-24 sm:p-28 p-16 rounded-md',
            }}
            open={openTestSmSDialog}
            onClose={() => closeDialog()}
            fullWidth
            maxWidth="sm"
          >
            <DialogTitle className="flex items-center justify-between mb-24 p-0">
              <Typography className="text-24 font-semibold text-black w-full">
                {t('configure.testSMS')}
              </Typography>
              <Icon
                onClick={() => closeDialog(false)}
                className="text-24 text-black font-normal cursor-pointer hover:text-gray-700 focus:text-gray-700"
              >
                close
              </Icon>
            </DialogTitle>

            <form noValidate className="flex flex-col md:overflow-hidden quick-campaign">
              <DialogContent className="p-0 mb-24">
                <div className="block mb-24">
                  <Typography className="font-medium text-16 mb-14">
                    {t('quickCampaignsContacts.contactNumber')}
                  </Typography>
                  <PhoneInput
                    placeholder={t('common.phoneNumber')}
                    inputStyle={{
                      width: '100%',
                      height: '37px',
                    }}
                    className="w-full"
                    name="phone_number"
                    required
                    error={!!errors.phone_number}
                    helperText={errors?.phone_number?.message}
                    value={phoneValue}
                    size="small"
                    country={loginUserData?.isoCode?.toLowerCase()}
                    enableSearch="true"
                    onChange={(value, data) => {
                      handlePhoneChange(value, data, 'testSMS');
                      const validPhoneNumber = handlePhoneNumberValidationCheck(value, data);
                      setErrorMessage(validPhoneNumber);
                    }}
                  />
                  {errorMessage === false && phoneValue?.length > 0 && (
                    <p className="text-red mt-5 text-16">
                      {t('productSettings.InvalidPhoneNumber')}
                    </p>
                  )}
                </div>
                <div className="block">
                  <Typography className="font-medium text-16 mb-14">
                    {t('sendMessage.message')}
                  </Typography>
                  <TextField
                    className="w-full"
                    value={massageValue}
                    onChange={(e) => setMassageValue(e.target.value)}
                    placeholder={t('configure.enterMessage')}
                    multiline
                    rows={5}
                  />
                </div>
              </DialogContent>
              <DialogActions className="p-0 justify-end">
                <Button
                  className="md:text-16 font-medium disabled:text-black rounded-md min-w-160"
                  variant="contained"
                  color="quickCampaign"
                  onClick={sendTestSMS}
                  type="button"
                  disabled={
                    !phoneValue || errorMessage === false || !massageValue || loadingTestSms
                  }
                >
                  {t('configure.testSMS')}
                  {loadingTestSms && (
                    <CircularProgress size={24} className="text-campaign absolute mx-auto" />
                  )}
                </Button>
              </DialogActions>
            </form>
          </Dialog>
        </>
      )}
      <ConformationDialogBox
        open={openDialogueForConformation}
        onClose={() => setOpenDialogueForConformation(false)}
        onDelete={() => handleDelete()}
        confirmationText="Are You Sure?"
        additionalInfo={`${t('configure.conformationMessage')}`}
        deleteImage="/assets/images/icon/campaignDeleteIcon.png"
        backgroundColor="quick-campaign"
        textColor="quick-campaign"
        buttonText={t('common.confirm')}
      />
    </>
  );
};

export default Sms;
