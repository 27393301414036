import { useEffect, useState } from 'react';
import { Button, CircularProgress, Typography } from '@mui/material';
import { t } from 'i18next';
import history from '@history';
import { useSelector, useDispatch } from 'react-redux';
import { getParamsId, handleApiRequest } from '../../../common/common';
import businessData from '../../../query/business';
import { storeSendSmsOrEmailDetails } from '../store/targetMessageSlice';

const SelectWhatsAppTemplate = () => {
  const sendSmsOrEmailDetailsData = useSelector(
    ({ state }) => state.targetMessageSlice?.sendSmsOrEmailDetailsData
  );
  const [templates, setTemplates] = useState([]);
  const [selectTemplate, setSelectTemplate] = useState();
  const dispatch = useDispatch();
  const id = getParamsId();
  const [refreshIcon, setRefreshIcon] = useState(false);

  if (
    !sendSmsOrEmailDetailsData?.targetMessageType === 'invite_reviews' ||
    !sendSmsOrEmailDetailsData?.businessId
  ) {
    if (id) {
      history.push(`invite-reviews/${id}`);
    } else {
      history.push('invite-reviews/');
    }
  }

  useEffect(() => {
    const fetchTemplate = async () => {
      try {
        const payload = {
          query: businessData.getCampaignStandardMessages,
          variables: {
            campaignPlatformId: 3,
            serviceType: sendSmsOrEmailDetailsData?.targetMessageType,
          },
        };
        const result = await handleApiRequest(payload);
        if (result?.getCampaignStandardMessages?.status === 200) {
          const templateData = result?.getCampaignStandardMessages?.data;
          const updatedTemplates = templateData.map((template) => {
            if (template.htmlTemplate) {
              template.htmlTemplate = template.htmlTemplate
                ?.replace(/width:\s*300px;/g, '')
                ?.replace('background-color: #F8F8FA;', '');
            }
            return template;
          });
          setTemplates(updatedTemplates);
        } else {
          console.error('Error: Invalid status from API', result);
        }
      } catch (error) {
        console.error('An error occurred while fetching the templates:', error);
      }
    };
    fetchTemplate();
  }, [sendSmsOrEmailDetailsData?.targetMessageType]);

  const sendMessage = async () => {
    try {
      setRefreshIcon(true);
      const payload = {
        query: businessData.sendCampaignWhatsAppMessages,
        variables: {
          businessId: parseInt(sendSmsOrEmailDetailsData?.businessId, 10),
          campaignPlatformId: parseInt(sendSmsOrEmailDetailsData?.campaignPlatformId, 10),
          whatsAppTemplateId: parseInt(selectTemplate.id, 10),
          servicesType: sendSmsOrEmailDetailsData?.targetMessageType,
          phoneNumber: {
            countryCode: sendSmsOrEmailDetailsData?.phone?.dialCode.includes('+')
              ? sendSmsOrEmailDetailsData?.phone?.dialCode
              : `+${sendSmsOrEmailDetailsData?.phone?.dialCode}`,
            phoneNumber: parseInt(
              sendSmsOrEmailDetailsData?.phone?.phoneNumber
                ?.toString()
                .slice(sendSmsOrEmailDetailsData?.phone?.dialCode.length),
              10
            ),
          },
          firstName: sendSmsOrEmailDetailsData.firstName,
          lastName: sendSmsOrEmailDetailsData.lastName,
        },
      };
      const result = await handleApiRequest(payload);
      setRefreshIcon(false);
      if (result?.sendCampaignWhatsAppMessages?.status === 200) {
        dispatch(storeSendSmsOrEmailDetails());
      } else {
        console.error('Error: Invalid status from API', result);
      }
    } catch (error) {
      setRefreshIcon(false);
      console.error('An error occurred while sending the message:', error);
    }
  };

  const onSelectTemplate = (data) => {
    setSelectTemplate(data);
  };

  return (
    <div className="p-20 lg:p-24">
      <div className="flex flex-1 w-full items-center justify-between mb-36">
        <div className="flex items-center">
          <Typography
            initial={{ x: -20 }}
            animate={{ x: 0, transition: { delay: 0.2 } }}
            delay={300}
            className="sm:flex text-20 md:text-28 font-bold"
          >
            {t('inviteReviews.title')}
          </Typography>
        </div>
        <div className="flex gap-24">
          <Button
            className="bg-darkgreen rounded px-20 text-white disabled:bg-gray hover:bg-darkgreen"
            onClick={() => sendMessage()}
            disabled={refreshIcon || !selectTemplate?.id}
          >
            {t('inviteReviews.selectMessage')}
          </Button>
          {refreshIcon && (
            <CircularProgress
              className="text-darkgreen absolute right-92 top-48 mx-auto"
              size={24}
            />
          )}
        </div>
      </div>
      <div className="bg-white p-16 rounded-md">
        <Typography className="font-semibold text-20">
          {t('inviteReviews.chooseExistingMessage')}
        </Typography>
        <hr className="border !border-black border-dashed my-16" />
        <div className="grid gap-16 grid-cols-3 col-span-1 mb-8">
          {templates?.length > 0 &&
            templates?.map((item, i) => {
              return (
                <div
                  className={`border p-8 w-full m-0 ${
                    selectTemplate?.id === item.id ? 'bg-darkgreen-100' : ''
                  }`}
                  key={i}
                  role="button"
                  tabIndex={0}
                  onClick={() => onSelectTemplate(item)}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter' || e.key === ' ') {
                      onSelectTemplate(item);
                    }
                  }}
                >
                  <div className="hidden">hi</div>
                  <p
                    className={`w-full ${selectTemplate?.id === item.id ? 'bg-darkgreen-100' : ''}`}
                    dangerouslySetInnerHTML={{
                      __html: item?.htmlTemplate,
                    }}
                  />
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
};

export default SelectWhatsAppTemplate;
