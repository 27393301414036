import React, { useEffect, useState, useCallback, useMemo } from 'react';
import {
  Typography,
  CircularProgress,
  MenuItem,
  Select,
  TextField,
  InputLabel,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Icon,
  InputAdornment,
  IconButton,
} from '@mui/material';
import { yupResolver } from '@hookform/resolvers/yup';
import FuseLoadingWhiteBg from '@fuse/core/FuseLoading/FuseLoadingWhiteBg';
import ConformationDialogBox from 'src/app/main/CommonComponent/ConformationDialogBox';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';
import _ from '@lodash';
import { t } from 'i18next';
import manageBusinessData from 'src/app/main/query/manageBusiness';
import { handleApiRequest, getDecryptData } from 'src/app/main/common/common';
import { useSnackbar } from 'notistack';

const defaultValues = {
  name: '',
  senderEmail: '',
  userName: '',
  password: '',
  serverName: '',
  port: '',
  senderPassword: '',
};

const Mail = (props) => {
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(true);
  const [loadingSave, setLoadingSave] = useState(false);
  const [loadingTestSms, setLoadingTestSms] = useState(false);
  const [serviceProviderId, setServiceProviderId] = useState('Gmail');
  const [openTestSmSDialog, setOpenTestSmSDialog] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [massageValue, setMassageValue] = useState();
  const [emailAddressValue, setEmailAddressValue] = useState();
  const [subjectValue, setSubjectValue] = useState();
  const [formData, setFormData] = useState({});
  const [quickHubUserCredential, setQuickHubUserCredential] = useState({});
  const [openDialogueForConformation, setOpenDialogueForConformation] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const schema = useMemo(
    () =>
      yup.object().shape({
        senderEmail: yup.string().trim().required(t('configure.requiredSenderEmail')),
        ...(serviceProviderId === 'Gmail'
          ? {
              senderPassword: yup.string().trim().required('Sender Password is required'),
            }
          : {
              name: yup.string().trim().required(t('common.requiredName')),
              userName: yup.string().trim().required(t('configure.requiredUserName')),
              password: yup.string().required(t('common.passwordValid')),
              serverName: yup.string().trim().required(t('configure.requiredServerName')),
              port: yup.string().trim().required(t('configure.portRequired')),
            }),
      }),
    [serviceProviderId]
  );

  const { control, watch, reset, handleSubmit, formState, getValues, setError, setValue } = useForm(
    {
      mode: 'onChange',
      defaultValues,
      resolver: yupResolver(schema),
    }
  );
  const { isValid, dirtyFields, errors } = formState;

  const getQuickHubUserCredentialData = useCallback(async () => {
    setLoading(true);
    try {
      const payload = {
        query: manageBusinessData.getQuickHubUserCredential,
      };
      const result = await handleApiRequest(payload);
      if (result?.getQuickHubUserCredential?.status === 200) {
        const decryptedData = getDecryptData(result?.getQuickHubUserCredential?.data);
        const parsedData = JSON.parse(decryptedData);
        setQuickHubUserCredential(parsedData);
      }
    } catch (err) {
      console.error('Error fetching data:', err);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    getQuickHubUserCredentialData();
  }, [getQuickHubUserCredentialData]);

  useEffect(() => {
    if (
      isEdit &&
      quickHubUserCredential?.emailCred &&
      Object.values(quickHubUserCredential?.emailCred).length > 0
    ) {
      setServiceProviderId(quickHubUserCredential?.emailCred?.provider);
    }
  }, [isEdit, quickHubUserCredential?.emailCred]);

  const initDialog = useCallback(() => {
    if (
      isEdit &&
      quickHubUserCredential?.emailCred &&
      Object.values(quickHubUserCredential?.emailCred).length > 0
    ) {
      reset(
        serviceProviderId === 'Gmail'
          ? {
              senderPassword: quickHubUserCredential?.emailCred?.senderPassword || '',
              senderEmail: quickHubUserCredential?.emailCred?.senderMailId || '',
              name: quickHubUserCredential?.emailCred?.from || '',
            }
          : {
              userName: quickHubUserCredential?.emailCred?.user || '',
              password: quickHubUserCredential?.emailCred?.pass || '',
              serverName: quickHubUserCredential?.emailCred?.host || '',
              port: quickHubUserCredential?.emailCred?.port || '',
              senderEmail: quickHubUserCredential?.emailCred?.senderMailId || '',
              name: quickHubUserCredential?.emailCred?.from || '',
            }
      );
    } else {
      reset(defaultValues);
    }
  }, [isEdit, reset, serviceProviderId, quickHubUserCredential?.emailCred]);

  useEffect(() => {
    if (isEdit) {
      initDialog();
    }
  }, [isEdit, initDialog]);

  const onSubmit = async (data) => {
    setLoadingSave(true);
    try {
      const payload = {
        query: manageBusinessData.addOrUpdateQuickHubUserCredential,
        variables: {
          isDataForEmail: true,
          isGmail: serviceProviderId === 'Gmail',
          senderMailId: data?.senderEmail,
          isUpdated: isEdit,
          ...(serviceProviderId === 'Gmail'
            ? {
                senderPassword: data?.senderPassword,
              }
            : {
                user: data?.userName,
                pass: data?.password,
                host: data?.serverName,
                port: Number(data?.port),
                from: data?.name,
              }),
        },
      };
      const result = await handleApiRequest(payload);
      if (result?.addOrUpdateQuickHubUserCredential?.status === 200) {
        enqueueSnackbar(result?.addOrUpdateQuickHubUserCredential?.message, {
          variant: 'success',
        });
        reset(defaultValues);
        getQuickHubUserCredentialData();
        if (isEdit) {
          setIsEdit(false);
        }
      } else {
        enqueueSnackbar(result?.addOrUpdateQuickHubUserCredential?.message, {
          variant: 'error',
        });
      }
    } catch (error) {
      console.error('Error saving data:', error);
    } finally {
      setLoadingSave(false);
    }
  };

  const handleFormChange = () => {
    setFormData(watch());
  };

  const sendTestEmail = async () => {
    setLoadingTestSms(true);
    try {
      const payload = {
        query: manageBusinessData.sendTestEmailUsingBusinessOwnerCred,
        variables: {
          html: massageValue,
          subject: subjectValue,
          emailAddress: emailAddressValue,
          isGmail: serviceProviderId === 'Gmail',
          ...(serviceProviderId === 'Gmail'
            ? {
                senderPassword: formData?.senderPassword,
                senderMailId: formData?.senderEmail,
              }
            : {
                user: formData?.userName,
                pass: formData?.password,
                host: formData?.serverName,
                port: Number(formData?.port),
                from: formData?.name,
                senderMailId: formData?.senderEmail,
              }),
        },
      };
      const result = await handleApiRequest(payload);
      if (result?.sendTestEmailUsingBusinessOwnerCred?.status === 200) {
        enqueueSnackbar(result?.sendTestEmailUsingBusinessOwnerCred?.message, {
          variant: 'success',
        });
        closeDialog();
      } else {
        enqueueSnackbar(result?.sendTestEmailUsingBusinessOwnerCred?.message, {
          variant: 'error',
        });
      }
    } catch (error) {
      console.error('Error sending test SMS:', error);
    } finally {
      setLoadingTestSms(false);
    }
  };

  const handleDelete = async () => {
    try {
      const payloadData = {
        query: manageBusinessData.deleteQuickHubUserCredential,
        variables: {
          isSms: false,
          isEmail: true,
        },
      };
      const result = await handleApiRequest(payloadData);
      if (result?.deleteQuickHubUserCredential?.status === 200) {
        enqueueSnackbar(result?.deleteQuickHubUserCredential?.message, {
          variant: 'success',
          autoHideDuration: 3000,
        });
        setOpenDialogueForConformation(false);
        getQuickHubUserCredentialData();
      } else {
        enqueueSnackbar(result?.deleteQuickHubUserCredential?.message, {
          variant: 'error',
          autoHideDuration: 3000,
        });
      }
    } catch (error) {
      console.error('Error', error);
    }
  };

  const closeDialog = () => {
    setOpenTestSmSDialog(false);
    setMassageValue('');
    setEmailAddressValue('');
    setSubjectValue('');
  };

  if (loading) {
    return <FuseLoadingWhiteBg />;
  }

  return (
    <>
      {quickHubUserCredential &&
      quickHubUserCredential?.emailCred &&
      Object.keys(quickHubUserCredential?.emailCred).length > 0 &&
      !isEdit ? (
        <>
          {quickHubUserCredential?.emailCred?.provider === 'Gmail' ? (
            <>
              <div className="mb-12 mt-10">
                <span className="text-16 font-semibold">
                  {t('configure.serviceProviderName')} :{' '}
                </span>
                <span className="text-16 font-medium text-grey-700">
                  {quickHubUserCredential?.emailCred?.provider}
                </span>
              </div>
              <div className="mb-12">
                <span className="text-16 font-semibold">{t('common.name')} : </span>
                <span className="text-16 font-medium text-grey-700">
                  {quickHubUserCredential?.emailCred?.senderMailId}
                </span>
              </div>
              <div className="mb-12">
                <span className="text-16 font-semibold">{t('configure.senderPassword')} : </span>
                <span className="text-16 font-medium text-grey-700">
                  {quickHubUserCredential?.emailCred?.senderPassword}
                </span>
              </div>
            </>
          ) : (
            <>
              <div className="mb-12 mt-10">
                <span className="text-16 font-semibold">
                  {t('configure.serviceProviderName')} :{' '}
                </span>
                <span className="text-16 font-medium text-grey-700">
                  {quickHubUserCredential?.emailCred?.provider}
                </span>
              </div>
              <div className="mb-12">
                <span className="text-16 font-semibold">{t('common.name')} : </span>
                <span className="text-16 font-medium text-grey-700">
                  {quickHubUserCredential?.emailCred?.from}
                </span>
              </div>
              <div className="mb-12">
                <span className="text-16 font-semibold">{t('configure.serverName')} : </span>
                <span className="text-16 font-medium text-grey-700">
                  {quickHubUserCredential?.emailCred?.host}
                </span>
              </div>
              <div className="mb-12">
                <span className="text-16 font-semibold">{t('common.password')} : </span>
                <span className="text-16 font-medium text-grey-700">
                  {quickHubUserCredential?.emailCred?.pass}
                </span>
              </div>
              <div className="mb-12">
                <span className="text-16 font-semibold">{t('configure.port')} : </span>
                <span className="text-16 font-medium text-grey-700">
                  {quickHubUserCredential?.emailCred?.port}
                </span>
              </div>
              <div className="mb-12">
                <span className="text-16 font-semibold">{t('configure.senderEmail')} : </span>
                <span className="text-16 font-medium text-grey-700">
                  {quickHubUserCredential?.emailCred?.senderMailId}
                </span>
              </div>
              <div className="mb-12">
                <span className="text-16 font-semibold">{t('common.userName')} : </span>
                <span className="text-16 font-medium text-grey-700">
                  {quickHubUserCredential?.emailCred?.user}
                </span>
              </div>
            </>
          )}

          <div className="text-end mb-16">
            <Button
              className="rounded md:px-40 text-16 font-medium mr-24"
              variant="outlined"
              color="quickCampaign"
              onClick={() => setOpenDialogueForConformation(true)}
              type="button"
            >
              {t('common.remove')}
            </Button>
            <Button
              className="rounded md:px-40 mx-10 md:mx-0 text-16 font-medium"
              variant="contained"
              color="quickCampaign"
              onClick={() => setIsEdit(true)}
              type="button"
            >
              {t('common.edit')}
            </Button>
          </div>
        </>
      ) : (
        <>
          <form
            noValidate
            onSubmit={handleSubmit(onSubmit)}
            onChange={handleFormChange}
            className="quick-campaign"
          >
            <div className="py-12">
              <div className="block mb-24">
                <InputLabel id="country" className="text-16 font-medium mb-12 text-grey-900">
                  {t('configure.emailServiceProvider')}
                </InputLabel>
                <Controller
                  name="provider"
                  control={control}
                  render={({ field }) => (
                    <Select
                      {...field}
                      className="w-full"
                      id="provider"
                      value={serviceProviderId}
                      size="small"
                      displayEmpty
                      error={!!errors.country}
                      onChange={(e) => {
                        setServiceProviderId(e?.target?.value);
                        reset(defaultValues);
                      }}
                    >
                      <MenuItem value="name" disabled>
                        {t('configure.selectServiceProvider')}
                      </MenuItem>
                      {props.providerList?.map((option, i) => (
                        <MenuItem key={i} value={option.name}>
                          {option.name}
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                />
              </div>
              <div className="block mb-24">
                <InputLabel
                  htmlFor="senderEmail"
                  className="text-16 font-medium mb-12 text-grey-900"
                >
                  {t('configure.senderEmail')}
                </InputLabel>
                <Controller
                  control={control}
                  name="senderEmail"
                  render={({ field }) => (
                    <TextField
                      {...field}
                      placeholder={t('configure.requiredSenderEmailPlaceHolder')}
                      size="small"
                      id="senderEmail"
                      error={!!errors.senderEmail}
                      helperText={errors?.senderEmail?.message}
                      variant="outlined"
                      required
                      fullWidth
                    />
                  )}
                />
              </div>

              {serviceProviderId === 'Gmail' ? (
                <div>
                  <div className="block mb-24">
                    <InputLabel
                      htmlFor="senderPassword"
                      className="text-16 font-medium mb-12 text-grey-900 flex items-center"
                    >
                      {t('configure.senderPassword')}
                    </InputLabel>
                    <Controller
                      control={control}
                      name="senderPassword"
                      render={({ field }) => (
                        <TextField
                          {...field}
                          size="small"
                          id="senderPassword"
                          error={!!errors.senderPassword}
                          helperText={errors?.senderPassword?.message}
                          variant="outlined"
                          required
                          fullWidth
                        />
                      )}
                    />
                  </div>
                </div>
              ) : (
                <div>
                  <div className="block mb-24">
                    <InputLabel
                      htmlFor="name"
                      className="text-16 font-medium mb-12 text-grey-900 flex items-center"
                    >
                      {t('common.name')}
                    </InputLabel>
                    <Controller
                      control={control}
                      name="name"
                      render={({ field }) => (
                        <TextField
                          {...field}
                          size="small"
                          id="name"
                          error={!!errors.name}
                          helperText={errors?.name?.message}
                          variant="outlined"
                          required
                          fullWidth
                        />
                      )}
                    />
                  </div>

                  <div className="block mb-24">
                    <InputLabel
                      htmlFor="userName"
                      className="text-16 font-medium mb-12 text-grey-900"
                    >
                      {t('common.userName')}
                    </InputLabel>
                    <Controller
                      control={control}
                      name="userName"
                      render={({ field }) => (
                        <TextField
                          {...field}
                          size="small"
                          id="userName"
                          error={!!errors.userName}
                          helperText={errors?.userName?.message}
                          variant="outlined"
                          required
                          fullWidth
                        />
                      )}
                    />
                  </div>
                  <div className="block mb-24">
                    <InputLabel
                      htmlFor="userName"
                      className="text-16 font-medium mb-12 text-grey-900"
                    >
                      {t('common.password')}
                    </InputLabel>
                    <Controller
                      control={control}
                      name="password"
                      render={({ field }) => (
                        <TextField
                          {...field}
                          size="small"
                          id="password"
                          type="password"
                          error={!!errors.password}
                          helperText={errors?.password?.message}
                          InputProps={{
                            className: 'pr-2',
                            type: showPassword ? 'text' : 'password',
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  className="bg-white"
                                  onClick={() => setShowPassword(!showPassword)}
                                >
                                  <Icon className="text-20" color="action">
                                    {showPassword ? 'visibility' : 'visibility_off'}
                                  </Icon>
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                          variant="outlined"
                          required
                          fullWidth
                        />
                      )}
                    />
                  </div>
                  <div className="block mb-24">
                    <InputLabel
                      htmlFor="serverName"
                      className="text-16 font-medium mb-12 text-grey-900"
                    >
                      {t('configure.serverName')}
                    </InputLabel>
                    <Controller
                      control={control}
                      name="serverName"
                      render={({ field }) => (
                        <TextField
                          {...field}
                          size="small"
                          id="serverName"
                          placeholder={t('configure.serverNamePlaceHolder')}
                          error={!!errors.serverName}
                          helperText={errors?.serverName?.message}
                          variant="outlined"
                          required
                          fullWidth
                        />
                      )}
                    />
                  </div>
                  <div className="block">
                    <InputLabel htmlFor="port" className="text-16 font-medium mb-12 text-grey-900">
                      {t('configure.port')}
                    </InputLabel>
                    <Controller
                      control={control}
                      name="port"
                      render={({ field }) => (
                        <TextField
                          {...field}
                          size="small"
                          id="port"
                          placeholder={t('configure.portPlaceHolder')}
                          error={!!errors.port}
                          helperText={errors?.port?.message}
                          variant="outlined"
                          required
                          fullWidth
                        />
                      )}
                    />
                  </div>
                </div>
              )}
            </div>
            <div className="flex flex-1 w-full items-center justify-end mb-12 mt-16">
              <div className="md:flex gap-24 text-center">
                <Button
                  className="rounded md:px-60 mx-10 md:mx-0 text-16 font-medium"
                  variant="outlined"
                  color="quickCampaign"
                  onClick={() => setOpenTestSmSDialog(true)}
                  type="button"
                  disabled={_.isEmpty(dirtyFields) || !isValid}
                >
                  {t('configure.sendTestEmail')}
                </Button>
                <Button
                  className="rounded md:px-60 mx-10 md:mx-0 text-16 font-medium"
                  variant="contained"
                  color="quickCampaign"
                  type="submit"
                  disabled={loadingSave || _.isEmpty(dirtyFields) || !isValid}
                >
                  {isEdit ? `${t('common.update')}` : `${t('common.save')}`}
                  {loadingSave && (
                    <CircularProgress size={24} className="text-quick-campaign absolute mx-auto" />
                  )}
                </Button>
              </div>
            </div>
          </form>
          <Dialog
            classes={{
              paper: 'm-24 sm:p-28 p-16 rounded-md',
            }}
            open={openTestSmSDialog}
            onClose={() => setOpenTestSmSDialog(false)}
            fullWidth
            maxWidth="sm"
          >
            <DialogTitle className="flex items-center justify-between mb-24 p-0">
              <Typography className="text-24 font-semibold text-black w-full">
                {t('configure.testEmail')}
              </Typography>
              <Icon
                onClick={() => closeDialog(false)}
                className="text-24 text-black font-normal cursor-pointer hover:text-gray-700 focus:text-gray-700"
              >
                close
              </Icon>
            </DialogTitle>

            <form noValidate className="flex flex-col md:overflow-hidden quick-campaign">
              <DialogContent className="p-0 mb-24">
                <div className="block mb-24">
                  <Typography className="font-medium text-16 mb-14">
                    {t('common.emailAddress')}
                  </Typography>
                  <TextField
                    className="w-full"
                    value={emailAddressValue}
                    onChange={(e) => setEmailAddressValue(e.target.value)}
                    placeholder={t('common.requiredEmail')}
                  />
                </div>
                <div className="block mb-24">
                  <Typography className="font-medium text-16 mb-14">
                    {t('inviteReviews.subject')}
                  </Typography>
                  <TextField
                    className="w-full"
                    value={subjectValue}
                    onChange={(e) => setSubjectValue(e.target.value)}
                    placeholder={t('inviteReviews.enterSubject')}
                  />
                </div>

                <div className="block">
                  <Typography className="font-medium text-16 mb-14">
                    {t('sendMessage.message')}
                  </Typography>
                  <TextField
                    className="w-full"
                    value={massageValue}
                    onChange={(e) => setMassageValue(e.target.value)}
                    placeholder={t('configure.enterMessage')}
                    multiline
                    rows={5}
                  />
                </div>
              </DialogContent>
              <DialogActions className="p-0 justify-end">
                <Button
                  className="md:text-16 font-medium disabled:text-black rounded-md min-w-160"
                  variant="contained"
                  color="quickCampaign"
                  onClick={sendTestEmail}
                  type="button"
                  disabled={!subjectValue || !emailAddressValue || loadingTestSms || !massageValue}
                >
                  {t('configure.testEmail')}
                  {loadingTestSms && (
                    <CircularProgress size={24} className="text-campaign absolute mx-auto" />
                  )}
                </Button>
              </DialogActions>
            </form>
          </Dialog>
        </>
      )}
      <ConformationDialogBox
        open={openDialogueForConformation}
        onClose={() => setOpenDialogueForConformation(false)}
        onDelete={() => handleDelete()}
        confirmationText="Are You Sure?"
        additionalInfo={`${t('configure.conformationMessageEmail')}`}
        deleteImage="/assets/images/icon/campaignDeleteIcon.png"
        backgroundColor="quick-campaign"
        textColor="quick-campaign"
        buttonText={t('common.confirm')}
      />
    </>
  );
};

export default Mail;
