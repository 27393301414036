import React, { useEffect, useRef } from 'react';
import * as venn from 'venn.js';
import * as d3 from 'd3';

const GenderMetrics = ({ data }) => {
  const chartRef = useRef();

  const Color = {
    male: '#67DF9D',
    female: '#F2B443',
    other: '#8B78DC',
  };

  const transformedData = data
    ?.filter((item) => item?.key === 'male' || item?.key === 'female' || item?.key === 'other')
    ?.map(({ key, value }) => {
      const sets = key
        ?.replace(/maleFemale/, 'male,female')
        ?.replace(/And/, ',')
        ?.replace(/other/, 'other')
        ?.split(',')
        ?.map((set) => set?.trim()?.toLowerCase());
      return { sets, size: Number(value) };
    });

  useEffect(() => {
    if (!chartRef?.current) return;
    d3?.select(chartRef?.current).selectAll('*')?.remove();
    const chart = d3?.select(chartRef?.current);
    const diagram = venn?.VennDiagram()?.width(170)?.height(150);
    chart?.datum(transformedData)?.call(diagram);
    chart
      ?.selectAll('g')
      ?.select('path')
      ?.style('fill-opacity', 0.9)
      ?.style('fill', (d) => {
        if (d?.sets?.includes('male') && d?.sets?.length === 1) return Color.male;
        if (d?.sets?.includes('female') && d?.sets?.length === 1) return Color.female;
        if (d?.sets?.includes('other') && d?.sets?.length === 1) return Color.other;
        return '#999';
      });
    chart.selectAll('text').remove();
  }, [Color.female, Color.male, Color.other, transformedData]);

  return (
    <div>
      <div className="text-center venn-center -mt-16" ref={chartRef} />
      <div className="-mt-16">
        {data
          ?.filter(
            (item) => item?.key === 'female' || item?.key === 'male' || item?.key === 'other'
          )
          ?.map((item, index) => (
            <div
              key={index}
              className="flex items-center justify-between border-b-1 border-dashed border-gray-300 py-8 last:border-b-0 last:pb-0 "
            >
              <div>
                <div
                  className="inline-block w-14 h-14 rounded-full "
                  style={{
                    backgroundColor: item?.color,
                  }}
                />
                <span className="inline-block text-16 font-medium text-grey-600">
                  {item?.key === 'female'
                    ? 'Female'
                    : item?.key === 'male'
                    ? 'Male'
                    : item?.key === 'other'
                    ? 'Others'
                    : ''}
                </span>
              </div>
              <span className="text-16 font-medium">{item?.value}</span>
            </div>
          ))}
      </div>
    </div>
  );
};

export default GenderMetrics;
