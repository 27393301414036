import React, { useCallback, useEffect, useState } from 'react';
import { t } from 'i18next';
import { Typography, Switch } from '@mui/material';
import quickChatQuery from '../../query/quickChat';
import { handleApiRequest } from '../../common/common';

const HumanInteraction = (props) => {
  const { quickChatDetails } = props;

  const [isHumanInteraction, setIsHumanInteraction] = useState(false);

  const getQuickChatHumanInteractionData = useCallback(async () => {
    try {
      if (quickChatDetails?.id) {
        const payload = {
          query: quickChatQuery.getQuickChatHumanInteraction,
          variables: {
            quickChatId: Number(quickChatDetails?.id),
          },
        };
        const result = await handleApiRequest(payload);
        if (result?.getQuickChatHumanInteraction?.status === 200) {
          setIsHumanInteraction(result?.getQuickChatHumanInteraction?.data?.isHumanInteraction);
        }
      }
    } catch (err) {
      console.error('Error fetching data:', err);
    }
  }, [quickChatDetails?.id]);

  useEffect(() => {
    getQuickChatHumanInteractionData();
  }, [getQuickChatHumanInteractionData]);

  const enableDisable = async () => {
    try {
      const payload = {
        query: quickChatQuery.setQuickChatHumanInteraction,
        variables: {
          quickChatId: Number(quickChatDetails?.id),
          status: !isHumanInteraction,
        },
      };
      const result = await handleApiRequest(payload);
      if (result?.setQuickChatHumanInteraction?.status === 200) {
        getQuickChatHumanInteractionData();
      }
    } catch (error) {
      console.error('error', error);
    }
  };

  return (
    <div className="rounded-6 bg-white md:p-24 p-16">
      <h3 className="text-16 lg:text-24 font-bold mb-16 items-center">
        {t('quickChat.botDetails.humanInteraction')}
      </h3>
      <div className="flex items-center justify-between bg-gray-A500 rounded-md max-w-400 py-8 px-16 border-1 border-solid quick-chat">
        <Typography className="text-16 font-medium">
          {t('quickChat.humanInteraction.desc')}
        </Typography>
        <Switch
          color="secondary"
          className="-mr-12"
          checked={isHumanInteraction}
          onChange={() => enableDisable()}
        />
      </div>
      <span className="block text-16 font-semibold mb-10 mt-20">{t('common.note')}:</span>
      <Typography className="text-16 font-medium">
        <span
          dangerouslySetInnerHTML={{
            __html: t('quickChat.humanInteraction.feature.off'),
          }}
        />
      </Typography>
      <Typography className="text-16 font-medium">
        <span
          dangerouslySetInnerHTML={{
            __html: t('quickChat.humanInteraction.feature.on'),
          }}
        />
      </Typography>
    </div>
  );
};

export default HumanInteraction;
