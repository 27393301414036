import {
  Button,
  CircularProgress,
  FormControl,
  FormControlLabel,
  MenuItem,
  Paper,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import {
  DatePicker,
  LocalizationProvider,
  renderTimeViewClock,
  TimePicker,
} from '@mui/x-date-pickers';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import history from '@history';
import { useSnackbar } from 'notistack';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { t } from 'i18next';
import { useState } from 'react';
import LabelOrBusinessDrawer from '../reports/LabelOrBusinessDrawer';
import quickHub from '../../../../query/quickHub';
import { multipleImageDataToSend } from '../../../../common/common';
import ErrorPopUp from '../common/ErrorPopUp';

const CreateNewPost = () => {
  const actionTypes = [
    { id: 'CAll', value: 'Call' },
    { id: 'BOOK', value: 'Book' },
    { id: 'ORDER', value: 'Order' },
    { id: 'SHOP', value: 'Shop' },
    { id: 'SIGN_UP', value: 'Sign Up' },
    { id: 'LEARN_MORE', value: 'Learn More' },
  ];
  const [postType, setPostType] = useState('Offer');
  const [imageUrl, setImageUrl] = useState('');
  const [uploadFile, setUploadFile] = useState();
  const [refreshIcon, setRefreshIcon] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [selectLabelOrBusiness, setSelectLabelOrBusiness] = useState([]);
  const [openBusinessDrawer, setOpenBusinessDrawer] = useState(false);
  const [openErrorDialog, setOpenErrorDialog] = useState(false);
  const [failedRequests, setFailedRequests] = useState([]);
  const [postDetails, setPostDetails] = useState({
    title: '',
    startDate: null,
    endDate: null,
    startTime: null,
    endTime: null,
    couponCode: '',
    redeemLink: '',
    termAndCondition: '',
    actionType: '',
    details: '',
    name: '',
  });

  const formatDate = (date) => {
    const adjustedDate = new Date(date);
    adjustedDate.setMinutes(adjustedDate.getMinutes() - adjustedDate.getTimezoneOffset());
    return adjustedDate.toISOString().split('T')[0];
  };

  const handleSelectStartTime = (data) => {
    setPostDetails({
      ...postDetails,
      startTime: data,
    });
  };

  const handleSelectCloseTime = (data) => {
    setPostDetails({
      ...postDetails,
      endTime: data,
    });
  };

  const handleSelectStartDate = (data) => {
    setPostDetails({
      ...postDetails,
      startDate: data,
    });
  };

  const handleSelectEndDate = (data) => {
    setPostDetails({
      ...postDetails,
      endDate: data,
    });
  };

  const onSubmit = async () => {
    setRefreshIcon(true);
    try {
      const payload = {
        query: quickHub.updateGoogleBusinessDetailsForBusinesses,
        variables: {
          businessIds: selectLabelOrBusiness?.map((business) => business?.id),
          mask: 'posts',
          allMedia: [{ media: uploadFile }],
          data: JSON.stringify({
            type: postType,
            title: postDetails?.title,
            summary: postDetails?.details,
            redeemLink: postDetails?.redeemLink,
            startDate: formatDate(postDetails?.startDate),
            endDate: formatDate(postDetails?.endDate),
            startTime: new Date(postDetails?.startTime).toTimeString().slice(0, 5),
            endTime: new Date(postDetails?.startTime).toTimeString().slice(0, 5),
            couponCode: postDetails?.couponCode,
            actionType: postDetails?.actionType,
            termAndCondition: postDetails?.termAndCondition,
          }),
        },
      };
      const result = await multipleImageDataToSend(payload);
      setRefreshIcon(false);
      if (result?.updateGoogleBusinessDetailsForBusinesses?.data?.failedRequests?.length > 0) {
        setOpenErrorDialog(true);
        setFailedRequests(result?.updateGoogleBusinessDetailsForBusinesses?.data?.failedRequests);
      }

      if (result?.updateGoogleBusinessDetailsForBusinesses?.status === 200) {
        history.push('quick-hub/posts');
        enqueueSnackbar(result?.updateGoogleBusinessDetailsForBusinesses?.message, {
          variant: 'success',
          autoHideDuration: 3000,
        });
      } else if (result?.updateGoogleBusinessDetailsForBusinesses?.message !== 'Bad request') {
        enqueueSnackbar(result?.updateGoogleBusinessDetailsForBusinesses?.message, {
          variant: 'error',
          autoHideDuration: 3000,
        });
      }
    } catch (error) {
      setRefreshIcon(false);
      console.error('Error updating business hours:', error);
    }
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (!file?.type?.startsWith('image/')) {
      enqueueSnackbar(`${t('tabletVideos.tabletVideoDetail.fileTypeError')}`, {
        variant: 'error',
        autoHideDuration: 2000,
      });
      return;
    }
    const reader = new FileReader();
    reader?.readAsBinaryString(file);

    reader.onload = async () => {
      setUploadFile(file);
      setImageUrl(URL.createObjectURL(file));
    };
  };

  const handleOnChange = (event) => {
    const { name, value } = event.target;
    setPostDetails((prevDetails) => ({
      ...prevDetails,
      [name]: value,
    }));
  };

  const handleRemoveImage = () => {
    setUploadFile('');
    setImageUrl('');
  };

  return (
    <div className="bg-gray-A500 min-h-screen p-16 sm:p-24">
      <Typography className="font-bold text-16 sm:text-24">{t('posts.createNewPost')}</Typography>
      <div className="border border-solid rounded-md bg-white mt-12 sm:max-w-640">
        <div className="p-12 sm:p-20">
          <Typography className="font-semibold text-18">{t('posts.selectPostType')}</Typography>
          <FormControl className="mt-12">
            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              defaultValue="female"
              name="radio-buttons-group"
              row
              value={postType}
              onChange={(e) => setPostType(e.target.value)}
            >
              <FormControlLabel
                className="border border-solid w-96 rounded-full ml-0 mr-12"
                value="Offer"
                control={<Radio />}
                label={<span className="font-medium text-16">{t('posts.offer')}</span>}
                labelPlacement="start"
              />
              <FormControlLabel
                className="border border-solid w-112 rounded-full ml-0 mr-12"
                value="Action"
                control={<Radio />}
                label={<span className="font-medium text-16">{t('posts.action')}</span>}
                labelPlacement="start"
              />
              <FormControlLabel
                className="border border-solid w-96 rounded-full ml-0 "
                value="Event"
                control={<Radio />}
                label={<span className="font-medium text-16">{t('posts.event')}</span>}
                labelPlacement="start"
              />
            </RadioGroup>
          </FormControl>
          <div className="">
            <Typography className="font-semibold text-18 py-16">
              {postType} {t('posts.postTypeTitle')}
            </Typography>
            <TextField
              className="bg-gray-A500 w-full"
              placeholder={`Enter ${postType} Title`}
              size="small"
              value={postDetails?.title}
              name="title"
              onChange={handleOnChange}
            />
            <Typography className="font-semibold text-18 py-16">
              {postType} {t('posts.postDetails')}
            </Typography>
            {imageUrl ? (
              <div className="my-12 relative">
                <img className="rounded-md h-320 w-full" src={imageUrl} alt="" />
                <CloseRoundedIcon
                  onClick={() => handleRemoveImage()}
                  className="text-18 absolute top-3 right-3 bg-white rounded-full text-black font-normal cursor-pointer hover:text-gray-700 focus:outline-none"
                />
              </div>
            ) : (
              <Paper
                className="relative w-full h-full rounded-8 shadow-none border-1 bg-gray-A500 border-solid border-gray p-40 mt-12 text-center"
                role="button"
                onDragOver={(e) => e.preventDefault()}
              >
                <span className="block text-center">
                  <img
                    src="/assets/images/business/uploadImage.png"
                    className="mx-auto mb-12"
                    alt="..."
                  />
                </span>
                <div className="block font-medium text-16">
                  {imageUrl || t('posts.dragAndDropCsv')}
                </div>
                <span className="block font-medium text-16">{t('signInPage.messages.or')}</span>
                <div className="inline-block">
                  <label htmlFor="button-file">
                    <input
                      accept="image/png, image/jpg, image/jpeg"
                      className="hidden"
                      id="button-file"
                      type="file"
                      onChange={handleFileChange}
                    />
                    <Button
                      size="small"
                      className="rounded-md block text-14 font-semibold px-32 py-8 mt-6 w-auto"
                      variant="contained"
                      color="secondary"
                      component="span"
                    >
                      {t('posts.browse')}
                    </Button>
                  </label>
                </div>
              </Paper>
            )}
            <Typography className="font-semibold text-18 py-16">
              {t('posts.startDateAndTime')}
            </Typography>
            <div className="flex justify-between items-center gap-16 w-full">
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  className="h-40 w-full bg-gray-A500"
                  id="note"
                  variant="outlined"
                  required
                  fullWidth
                  disablePast
                  format="MMM dd, yyyy"
                  value={postDetails?.startDate}
                  onChange={(data) => handleSelectStartDate(data)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      InputLabelProps={{
                        shrink: false,
                      }}
                      label={t('listManagement.selectDate')}
                    />
                  )}
                />
              </LocalizationProvider>
              <TimePicker
                className="h-40 w-full bg-gray-A500"
                required
                value={postDetails?.startTime}
                onChange={(data) => handleSelectStartTime(data)}
                viewRenderers={{
                  hours: renderTimeViewClock,
                  minutes: renderTimeViewClock,
                  seconds: renderTimeViewClock,
                }}
              />
            </div>
            <Typography className="font-semibold text-18 py-16">
              {t('posts.endDateAndTime')}
            </Typography>
            <div className="flex justify-between items-center gap-16 w-full">
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  className="h-40 w-full bg-gray-A500"
                  id="note"
                  variant="outlined"
                  required
                  fullWidth
                  disablePast
                  format="MMM dd, yyyy"
                  value={postDetails?.endDate}
                  onChange={(data) => handleSelectEndDate(data)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      InputLabelProps={{
                        shrink: false,
                      }}
                      label={t('listManagement.selectDate')}
                    />
                  )}
                />
              </LocalizationProvider>
              <TimePicker
                className="h-40 w-full bg-gray-A500"
                required
                value={postDetails?.endTime}
                onChange={(data) => handleSelectCloseTime(data)}
                viewRenderers={{
                  hours: renderTimeViewClock,
                  minutes: renderTimeViewClock,
                  seconds: renderTimeViewClock,
                }}
              />
            </div>
            <Typography className="font-semibold text-18 py-16">
              {postType} {t('posts.postDetails')}
            </Typography>
            <TextField
              className="bg-gray-A500 w-full"
              placeholder={`Enter ${postType} Details`}
              multiline
              rows={5}
              value={postDetails?.details}
              name="details"
              onChange={handleOnChange}
            />
            {postType === 'Offer' ? (
              <div className="flex justify-between items-center gap-16 w-full">
                <div className="w-full">
                  <Typography className="font-semibold text-18 py-16">
                    {t('posts.couponCode')}
                  </Typography>
                  <TextField
                    className="bg-gray-A500 w-full"
                    size="small"
                    placeholder={t('posts.enterCouponCode')}
                    value={postDetails?.couponCode}
                    name="couponCode"
                    onChange={handleOnChange}
                  />
                </div>
                <div className="w-full">
                  <Typography className="font-semibold text-18 py-16">
                    {t('listManagement.redeemLink')}
                  </Typography>
                  <TextField
                    className="bg-gray-A500 w-full"
                    size="small"
                    placeholder={t('posts.enterRedeemLink')}
                    value={postDetails?.redeemLink}
                    name="redeemLink"
                    onChange={handleOnChange}
                  />
                </div>
              </div>
            ) : (
              <div className="w-full">
                <Typography className="font-semibold text-18 py-16">
                  {t('posts.ctaButton')}
                </Typography>
                <Select
                  className="w-full bg-gray-A500"
                  size="small"
                  value={postDetails?.actionType}
                  displayEmpty
                  renderValue={(selected) =>
                    selected
                      ? actionTypes.find((action) => action.id === selected)?.value
                      : t('posts.selectCtaButton')
                  }
                  onChange={(e) => {
                    setPostDetails((prevDetails) => ({
                      ...prevDetails,
                      actionType: e.target.value,
                    }));
                  }}
                >
                  {actionTypes?.map((action) => {
                    return <MenuItem value={action?.id}>{action?.value}</MenuItem>;
                  })}
                </Select>
              </div>
            )}
            <Typography className="font-semibold text-18 py-16">
              {t('signUpPage.messages.termsAndCondition')}
            </Typography>
            <TextField
              className="bg-gray-A500 w-full"
              size="small"
              placeholder={t('posts.enterTermsAndConditions')}
              multiline
              rows={5}
              value={postDetails?.termAndCondition}
              name="termAndCondition"
              onChange={handleOnChange}
            />
            {postType !== 'Offer' && (
              <>
                <Typography className="font-semibold text-18 py-16">
                  {t('posts.websiteLink')}
                </Typography>
                <TextField
                  className="bg-gray-A500 w-full"
                  size="small"
                  placeholder={t('posts.enterWebsiteLink')}
                  name="redeemLink"
                  onChange={handleOnChange}
                />
              </>
            )}
          </div>
        </div>
        <hr className="my-8" />
        <div className="p-12 sm:p-20 flex justify-between items-center">
          <Typography className="font-semibold text-18">{t('reports.selectBusiness')}</Typography>
          {selectLabelOrBusiness?.length > 0 ? (
            <div className="flex gap-12 items-center">
              <Typography className="font-semibold text-18">
                {t('reports.selectBusiness')} ({selectLabelOrBusiness?.length})
              </Typography>
              <Button
                className="rounded-md w-112 font-semibold text-16"
                variant="outlined"
                color="secondary"
                onClick={() => setOpenBusinessDrawer(true)}
              >
                {t('common.edit')}
              </Button>
            </div>
          ) : (
            <Button
              className="rounded-md w-112 font-semibold text-16"
              variant="contained"
              color="secondary"
              onClick={() => setOpenBusinessDrawer(true)}
            >
              {t('posts.select')}
            </Button>
          )}
        </div>
      </div>
      {selectLabelOrBusiness?.length > 0 && (
        <div className="sm:max-w-640 text-end mt-16">
          <Button
            className="rounded-md w-160 font-semibold text-16"
            variant="contained"
            color="secondary"
            onClick={() => onSubmit()}
            disabled={
              refreshIcon ||
              !postDetails?.title ||
              !postDetails?.startDate ||
              !postDetails?.startTime ||
              !postDetails?.endDate ||
              !postDetails?.endTime ||
              !postDetails?.details ||
              !postDetails?.redeemLink ||
              !postDetails?.termAndCondition ||
              (postType === 'Offer' ? !postDetails?.couponCode : !postDetails?.actionType) ||
              !uploadFile
            }
          >
            {t('common.save')}
            {refreshIcon && (
              <CircularProgress className="text-quick-review absolute mx-auto" size={24} />
            )}
          </Button>
        </div>
      )}
      <LabelOrBusinessDrawer
        openBusinessDrawer={openBusinessDrawer}
        setOpenBusinessDrawer={setOpenBusinessDrawer}
        setSelectLabelOrBusiness={setSelectLabelOrBusiness}
        selectLabelOrBusiness={selectLabelOrBusiness}
        groupType="business"
        postScreen
        isGoogleConnected
      />
      <ErrorPopUp
        openErrorDialog={openErrorDialog}
        setOpenErrorDialog={setOpenErrorDialog}
        failedRequests={failedRequests}
        setFailedRequests={setFailedRequests}
      />
    </div>
  );
};

export default CreateNewPost;
