import { Button, Checkbox, Dialog, DialogContent, DialogTitle, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import React, { useState } from 'react';
import { t } from 'i18next';
import businessData from 'src/app/main/query/business';
import CheckIcon from '@mui/icons-material/Check';
import { handleApiRequest, storeCsvFileDataToServer } from 'src/app/main/common/common';
import { useSnackbar } from 'notistack';
import { useSelector } from 'react-redux';
import { selectDashboardData } from 'app/store/userSlice';

const CSVPopUp = ({ open, onClose, fetchData, file, setFile, csvFileData }) => {
  const [dragging, setDragging] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [totalCount, setTotalContact] = useState();
  const { enqueueSnackbar } = useSnackbar();
  const userCurrentBusinessDetails = useSelector(selectDashboardData);

  const handleDragOver = (event) => {
    event?.preventDefault();
    setDragging(true);
  };

  const handleDragLeave = () => {
    setDragging(false);
  };

  const handleDrop = (event) => {
    event.preventDefault();
    setDragging(false);
    const droppedFile = event.dataTransfer.files[0];
    if (droppedFile) {
      if (droppedFile.type !== 'text/csv') {
        enqueueSnackbar(t('sendMessage.dropValidCsv'), {
          variant: 'error',
          autoHideDuration: 3000,
        });
        return;
      }
      const reader = new FileReader();
      reader.onload = (e) => {
        const fileContent = e.target.result;
        if (!fileContent.trim()) {
          enqueueSnackbar(t('sendMessage.emptyFile'), {
            variant: 'error',
            autoHideDuration: 3000,
          });
        } else {
          setFile(droppedFile);
        }
      };
      reader.readAsText(droppedFile);
    }
  };

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];

    if (selectedFile && selectedFile?.type === 'text/csv') {
      setFile(selectedFile);
      const reader = new FileReader();
      reader.onload = (e) => {
        const csvData = e.target.result;
        const rows = csvData.split('\n');
        let phoneNumberCount = 0;

        rows.forEach((row, index) => {
          const columns = row.split(',');
          if (index > 0) {
            const phoneNumber = columns[1]?.trim();
            if (phoneNumber) phoneNumberCount += 1;
          }
        });
        setTotalContact(phoneNumberCount);
      };
      reader.onerror = (error) => {
        enqueueSnackbar(t('sendMessage.fileReadError'), {
          variant: 'error',
          autoHideDuration: 3000,
        });
        console.error('Error reading file:', error);
      };

      reader.readAsText(selectedFile);
    } else {
      enqueueSnackbar(t('sendMessage.onlyCscFileSelect'), {
        variant: 'error',
        autoHideDuration: 3000,
      });
    }
  };

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };

  const handleFileInputChange = async (e) => {
    const isFileNameExists = csvFileData?.userCSVData?.some(
      (item) => `${item?.fileName}.csv` === file?.name
    );

    if (isFileNameExists) {
      enqueueSnackbar('You already added this csv file', {
        variant: 'error',
        autoHideDuration: 3000,
      });
      return;
    }
    const payload = {
      query: businessData.saveUserCSV,
      variables: {
        file,
        defaultSave: isChecked,
        variables: {
          callFrom: 'campaign',
        },
        userBusinessId: Number(userCurrentBusinessDetails?.id),
      },
    };
    const result = await storeCsvFileDataToServer(payload);
    if (result?.saveUserCSV?.status === 200) {
      enqueueSnackbar(result?.saveUserCSV?.message, {
        variant: 'success',
        autoHideDuration: 3000,
      });
      fetchData();
      setFile();
      setTotalContact();
      onClose();
    } else {
      enqueueSnackbar(result?.saveUserCSV?.message, {
        variant: 'error',
        autoHideDuration: 3000,
      });
    }
  };

  const handleDownloadCsvFile = async () => {
    const payload = {
      query: businessData.getCampaignPlatformDemoCsv,
    };
    const result = await handleApiRequest(payload);
    if (result?.getCampaignPlatformDemoCsv?.status === 200) {
      const demoCsv = result?.getCampaignPlatformDemoCsv?.data?.demoCsv;
      const downloadLink = document.createElement('a');
      downloadLink.href = demoCsv;
      downloadLink.target = '_blank';
      downloadLink.download = 'demo.csv';
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    }
  };

  return (
    <Dialog
      open={open}
      aria-labelledby="delete-confirmation-dialog"
      classes={{
        paper: `m-24 p-20 rounded-sm`,
      }}
      maxWidth="sm"
    >
      <div className="flex items-center justify-between">
        <Typography className="font-bold text-16">Upload CSV FIle</Typography>
        <IconButton className="" onClick={onClose} color="inherit">
          <CloseIcon className="text-24 text-grey-700" />
        </IconButton>
      </div>
      <DialogContent className="p-0 mt-12 w-full">
        <div className="p-5 w-full">
          <div
            className={`bg-quick-campaign-50 border-2 w-auto sm:w-512 border-dashed border-quick-campaign rounded-md mb-5 p-20 sm:p-40 ${
              dragging ? 'bg-quick-campaign-100' : ''
            }`}
            onDragOver={handleDragOver}
            onDragLeave={handleDragLeave}
            onDrop={handleDrop}
          >
            {file ? (
              <div className="text-center w-full">
                <img src="/assets/images/icon/csv.png" alt="CSV" className="w-76 m-auto" />
                <Typography className="font-bold text-20">{file?.name}</Typography>
                <Typography className="text-quick-campaign text-16 font-semibold">
                  {totalCount} {t(`quickCampaignsContacts.title`)}
                </Typography>

                <div className="flex justify-center my-20 gap-8 items-center w-full">
                  <Checkbox
                    checked={isChecked}
                    onChange={handleCheckboxChange}
                    icon={<div className="h-16 w-16 border border-gray-800 rounded-sm" />}
                    checkedIcon={
                      <div className="h-16 w-16 bg-gray-900 rounded-sm flex items-center justify-center">
                        <CheckIcon className="text-white text-xs w-20 h-20" />
                      </div>
                    }
                    className="p-0 mr-2"
                  />
                  <Typography variant="body2" className=" font-semibold text-gray-900 text-16">
                    {t(`quickCampaignsContacts.dragAndDropContent`)}
                  </Typography>
                </div>

                <div className="flex justify-center gap-16 mt-6">
                  <Button
                    variant="outlined"
                    component="label"
                    className="text-quick-campaign border-quick-campaign hover:bg-white font-semibold text-16 bg-white px-32 rounded-md"
                  >
                    {t('common.change')}
                    <input type="file" accept=".csv" onChange={handleFileChange} hidden />
                  </Button>
                  <Button
                    variant="contained"
                    className="bg-quick-campaign text-white hover:bg-quick-campaign font-semibold text-16 rounded-md px-20"
                    onClick={handleFileInputChange}
                  >
                    {t(`quickCampaignsContacts.importFile`)}
                  </Button>
                </div>
              </div>
            ) : (
              <div className="w-full text-center pb-16 w-640">
                <img
                  src="/assets/images/icon/CampaignCSVUpload.png"
                  alt="Upload"
                  className="justify-center items-center m-auto"
                />
                <Typography
                  variant="body1"
                  className="text-quick-campaign text-16 text-center font-semibold mb-16"
                >
                  {t(`quickCampaignsContacts.dragAndDrop`)}
                </Typography>
                <Button
                  variant="contained"
                  component="label"
                  className="mt-4 text-white border-quick-campaign hover:bg-quick-campaign rounded-md text-16"
                  color="quickCampaign"
                >
                  {t(`quickCampaignsContacts.selectCSVFile`)}
                  <input type="file" accept=".csv" onChange={handleFileChange} hidden />
                </Button>
              </div>
            )}
          </div>
          {!open && (
            <Button
              variant="outlined"
              component="a"
              href="/quick-campaign/contact/"
              className="text-quick-campaign border-quick-campaign hover:bg-quick-campaign-100 rounded-md mt-24 text-16"
              onClick={handleDownloadCsvFile}
            >
              {t('quickCampaignsContacts.downloadDemoCSV')}
            </Button>
          )}
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default CSVPopUp;
