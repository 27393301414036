import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Icon,
  IconButton,
  Tooltip,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import React, { useState } from 'react';
import { t } from 'i18next';
import { formatDateWithTimeZone } from '../../common/common';

const AnalyticsDialogBox = ({ open, onClose, post, activeStep }) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="delete-confirmation-dialog"
      classes={{
        paper: `business-type-dropdown rounded-md`,
      }}
      PaperProps={{
        style: {
          width: '750px',
          maxWidth: '100%',
          height: '750px',
          maxHeight: '94vh',
          padding: '20px',
        },
      }}
    >
      <div className="overflow-y-auto">
        <div className="flex justify-between mb-10 ">
          <Typography className="text-20 font-600">{t('quickSocial.postAnalytics')}</Typography>
          <IconButton className="" onClick={onClose} color="inherit">
            <CloseIcon className="w-20 h-20 text-grey-700" />
          </IconButton>
        </div>

        <div className="bg-indigo-50 bg-opacity-50 p-20 rounded-md mb-16 flex gap-16 flex-col sm:flex-row items-center sm:items-normal">
          {(post?.media?.image || post?.mediaUrl || post?.media?.[0]?.entityLocation) && (
            <img
              src={post?.mediaUrl || post?.media?.image || post?.media?.[0]?.entityLocation}
              className="max-w-144 rounded-md inline-block"
              alt="..."
            />
          )}
          <div className="w-full flex flex-col justify-between">
            <div className="flex flex-col gap-20">
              <div className="flex justify-between">
                <div className="flex gap-20">
                  <img
                    src={post?.userProfile || post?.profilePictureUrl || post?.profileUrl}
                    className="w-60 h-60 rounded-full inline-block"
                    alt="..."
                  />
                  <div>
                    <span className="block font-medium text-20 mb-4">
                      {post?.userName || post?.pageName || post?.localizedName}
                    </span>
                    <span className="text-16 font-medium">
                      {formatDateWithTimeZone(
                        post?.postcreatedTime || post?.createdTime,
                        'DD MMM YYYY h:mm:ss A'
                      )}
                    </span>
                  </div>
                </div>
              </div>
              <p className="text-16 font-500">
                {post?.caption || post?.description || post?.localizedName}
              </p>
            </div>
          </div>
        </div>
        <div className="flex flex-col  ">
          <div className="flex gap-10 mb-10 ">
            <Typography className="text-20 font-600">{t('quickSocial.discovery')}</Typography>
            <Tooltip
              title={t('quickSocial.info')}
              arrow
              classes={{
                tooltip: 'bg-quick-social',
                arrow: 'text-quick-social',
              }}
            >
              <img src="/assets/images/icon/tooltipForSocial.svg" alt="good" className="ml-6" />
            </Tooltip>
          </div>
          <div className="grid gap-20 grid-cols-1 sm:grid-cols-2">
            <div className="bg-indigo-50 p-10 flex gap-20 items-center bg-opacity-50 ">
              <img
                src="/assets/images/icon/impressionIcon.png"
                alt="impression icon"
                className="ml-6"
              />
              <div>
                <p className="text-36 font-600">
                  {activeStep === 9 || activeStep === 10
                    ? post?.insights?.find((item) => item?.title === 'Impressions')?.value
                    : (activeStep === 12 || activeStep === 10) &&
                      (post?.activity?.impressionCount || 0)}
                </p>
                <p className="text-20 font-600">{t('quickSocial.impressions')}</p>
              </div>
            </div>

            <div className="bg-indigo-50 p-10 flex gap-20 items-center bg-opacity-50 ">
              <img
                src="assets/images/quick-hub/quick-social/engagement.png"
                alt="impression icon"
                className="ml-6"
              />
              <div>
                <p className="text-36 font-600">
                  {activeStep === 9 || activeStep === 10
                    ? post?.insights?.find((item) => item?.title === 'Accounts reached')?.value
                    : (activeStep === 12 || activeStep === 10) &&
                      (post?.activity?.uniqueImpressionsCount || 0)}
                </p>
                <p className="text-20 font-600">{t('quickSocial.uniqueViews')}</p>
              </div>
            </div>
          </div>
        </div>
        <div className="flex mt-10 flex-col  ">
          <div className="flex gap-10 mb-10 ">
            <Typography className="text-20 font-600">{t('quickSocial.engagements')}</Typography>
            <Tooltip
              title="It shows the engagement of the shared post in the last 6 months."
              arrow
              classes={{
                tooltip: 'bg-quick-social',
                arrow: 'text-quick-social',
              }}
            >
              <img src="/assets/images/icon/tooltipForSocial.svg" alt="good" className="ml-6" />
            </Tooltip>
          </div>
          <div className="grid gap-20 grid-cols-1 sm:grid-cols-2">
            <div className="bg-indigo-50 p-10 flex gap-20 items-center bg-opacity-50 ">
              <img src="/assets/images/icon/likeIcon.png" alt="impression icon" className="ml-6" />
              <div>
                <p className="text-36 font-600">
                  {activeStep === 9
                    ? post?.insights?.find((item) => item?.title === 'Likes')?.value
                    : (activeStep === 12 || activeStep === 10) && (post?.activity?.likeCount || 0)}
                </p>
                <p className="text-20 font-600">{t('quickSocial.likes')}</p>
              </div>
            </div>

            <div className="bg-indigo-50 p-10 flex gap-20 items-center bg-opacity-50 ">
              <img
                src="/assets/images/icon/commentsIcon.png"
                alt="impression icon"
                className="ml-6"
              />
              <div>
                <p className="text-36 font-600">
                  {activeStep === 9 || activeStep === 10
                    ? post?.insights?.find((item) => item?.title === 'Comments')?.value
                    : (activeStep === 12 || activeStep === 10) &&
                      (post?.activity?.commentCount || 0)}
                </p>
                <p className="text-20 font-600">{t('quickSocial.comments')}</p>
              </div>
            </div>

            <div className="bg-indigo-50 p-10 flex gap-20 items-center bg-opacity-50 ">
              <img
                src="/assets/images/icon/repostIcon.svg"
                alt="impression icon"
                className="ml-6"
              />
              <div>
                <p className="text-36 font-600">
                  {activeStep === 9 || activeStep === 10
                    ? post?.insights?.find((item) => item?.title === 'Shares')?.value
                    : (activeStep === 12 || activeStep === 10) && (post?.activity?.shareCount || 0)}
                </p>
                <p className="text-20 font-600">{t('quickSocial.post')}</p>
              </div>
            </div>

            <div className="bg-indigo-50 p-10 flex gap-20 items-center bg-opacity-50 ">
              <img
                src="assets/images/quick-hub/quick-social/reach.png"
                alt="impression icon"
                className="ml-6"
              />
              <div>
                <p className="text-36 font-600">
                  {activeStep === 9 || activeStep === 10
                    ? post?.insights?.find((item) => item?.title === 'Post interactions')?.value
                    : (activeStep === 12 || activeStep === 10) && (post?.activity?.clickCount || 0)}
                </p>
                <p className="text-20 font-600">{t('quickSocial.reaction')}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Dialog>
  );
};

export default AnalyticsDialogBox;
