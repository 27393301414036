import { Button, Typography } from '@mui/material';
import { t } from 'i18next';
import { useState } from 'react';
import ReportCategory from './ReportCategory';

const Reports = () => {
  const [selectCategory, setSelectCategory] = useState();
  const reportData = [
    {
      id: 1,
      categoryName: 'Online Reputation Report',
      imageUrl: 'assets/images/business/reportCategory.png',
      description:
        'Boost your brand with an Online Reputation Report! Get insights into reviews, ratings, and customer feedback to stay ahead of the competition.',
    },
    // {
    //   id: 2,
    //   categoryName: 'Social Media Engagement Report',
    //   imageUrl: '',
    //   description:
    //     'Social Media Engagement Report: Insights on followers, posts, and engagement to boost your online presence!',
    // },
    // {
    //   id: 3,
    //   categoryName: 'User Communications Report',
    //   imageUrl: '',
    //   description:
    //     'User Communications Report: Analyze chats, requests, and response times to enhance customer interactions!',
    // },
    // {
    //   id: 4,
    //   categoryName: 'Campaign Performance Report',
    //   imageUrl: '',
    //   description:
    //     'Campaign Performance Report: Track reach, leads, and engagement for better results!',
    // },
  ];

  return (
    <div className="bg-gray-A500 h-full">
      {!selectCategory ? (
        <div className="sm:p-24 p-16">
          <Typography className="text-20 sm:text-28 font-bold">
            {t('navigation.reports')}
          </Typography>
          <div className="border border-solid p-12 bg-white rounded-md mt-20">
            <Typography className="text-18 font-semibold">
              {t('reports.pickReportCategory')}
              <span className="text-14 text-gray pl-8">{t('reports.reportCategoryGenerate')}</span>
            </Typography>
            <div className="flex flex-wrap gap-16">
              {reportData?.length > 0 &&
                reportData?.map((item, index) => {
                  return (
                    <div
                      className="bg-gray-A500 p-10 w-360 mt-16 rounded-md border border-solid"
                      key={index}
                    >
                      <div className="flex items-center gap-8">
                        <img className="w-36 h-36" src={item?.imageUrl || ''} alt="" />
                        <Typography className="text-16 font-bold">{item?.categoryName}</Typography>
                      </div>
                      <Typography className="font-normal text-14 pt-16">
                        {item?.description}
                      </Typography>
                      <div className="text-end pt-12">
                        <Button
                          className="bg-quick-hub hover:bg-quick-hub text-white rounded-md text-14 font-normal"
                          variant="contained"
                          onClick={() => setSelectCategory(item)}
                        >
                          {t('reports.generate')}
                        </Button>
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      ) : (
        <ReportCategory selectCategory={selectCategory} setSelectCategory={setSelectCategory} />
      )}
    </div>
  );
};

export default Reports;
