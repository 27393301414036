import React, { useCallback, useEffect, useState } from 'react';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useLocation } from 'react-router-dom';
import { Button, CircularProgress, Typography } from '@mui/material';
import { t } from 'i18next';
import { useSnackbar } from 'notistack';
import history from '@history';
import FuseLoading from '@fuse/core/FuseLoading';
import manageBusinessData from '../../../../../query/manageBusiness';
import { handleApiRequest } from '../../../../../common/common';
import AddAttributes from './AddAttributes';

const Attributes = () => {
  const location = useLocation();
  const [loading, setLoading] = useState(true);
  const [googleBusinessData, setGoogleBusinessData] = useState();
  const [googleAttribute, setGoogleAttributes] = useState([]);
  const [defaultAttribute, setDefaultAttribute] = useState([]);
  const [refreshIcon, setRefreshIcon] = useState(false);
  const [addAttribute, setAddAttribute] = useState(!!location?.state?.isBulk);
  const [deleteAttribute, setDeleteAttribute] = useState();
  const [openBusinessDrawer, setOpenBusinessDrawer] = useState(false);
  const [selectLabelOrBusiness, setSelectLabelOrBusiness] = useState(
    location?.state?.selectBusiness || []
  );
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    const fetchGoogleAttribute = async () => {
      setLoading(true);
      try {
        const payload = {
          query: manageBusinessData.getGoogleSuggestionAttributes,
          variables: {
            businessId: selectLabelOrBusiness?.[0]?.id,
          },
        };
        const result = await handleApiRequest(payload);
        setLoading(false);
        if (result?.getGoogleSuggestionAttributes?.status === 200) {
          const data = result.getGoogleSuggestionAttributes?.data;
          const filterValueMetaData = data
            .map((group) => ({
              ...group,
              attributes: group.attributes.filter((attr) => attr.valueMetadata !== null),
            }))
            .filter((group) => group.attributes.length > 0);
          setGoogleAttributes(filterValueMetaData);
        } else {
          enqueueSnackbar(result?.getGoogleSuggestionAttributes?.message, {
            variant: 'error',
            autoHideDuration: 3000,
          });
        }
      } catch (error) {
        setLoading(false);
        console.error('Error in fetchGoogleAttribute:', error);
      }
    };
    if (!openBusinessDrawer) {
      fetchGoogleAttribute();
    }
  }, [enqueueSnackbar, selectLabelOrBusiness, openBusinessDrawer]);

  const fetchDefaultGoogleAttribute = useCallback(async () => {
    setLoading(true);
    try {
      const payload = {
        query: manageBusinessData.getDefaultGoogleAttributes,
        variables: {
          businessId: selectLabelOrBusiness?.[0]?.id,
        },
      };
      const result = await handleApiRequest(payload);
      setLoading(false);
      if (result?.getDefaultGoogleAttributes?.status === 200) {
        setDefaultAttribute(result?.getDefaultGoogleAttributes?.data);
      } else {
        enqueueSnackbar(result?.getDefaultGoogleAttributes?.message, {
          variant: 'error',
          autoHideDuration: 3000,
        });
      }
    } catch (error) {
      setLoading(false);
      console.error('Error in fetchGoogleAttribute:', error);
    }
  }, [enqueueSnackbar, selectLabelOrBusiness]);

  useEffect(() => {
    if (!location?.state?.isBulk) {
      fetchDefaultGoogleAttribute();
    }
  }, [fetchDefaultGoogleAttribute, selectLabelOrBusiness, location?.state?.isBulk]);

  const handleDeleteAttribute = async (name) => {
    setRefreshIcon(true);
    setDeleteAttribute(name);
    try {
      const payload = {
        query: manageBusinessData.updateOrDeleteGoogleAttributes,
        variables: {
          businessId: selectLabelOrBusiness?.[0]?.id,
          removeAttribute: [name],
        },
      };
      const result = await handleApiRequest(payload);
      setRefreshIcon(false);
      if (result?.updateOrDeleteGoogleAttributes?.status === 200) {
        setDeleteAttribute();
        fetchDefaultGoogleAttribute();
      } else {
        enqueueSnackbar(result?.updateOrDeleteGoogleAttributes?.message, {
          variant: 'error',
          autoHideDuration: 3000,
        });
      }
    } catch (error) {
      setRefreshIcon(false);
      console.error('Error adding photos/videos:', error);
    }
  };

  if (loading) {
    return <FuseLoading />;
  }

  return (
    <div className="bg-gray-A500 h-full p-16 sm:p-24">
      {defaultAttribute?.length > 0 && !addAttribute && !location?.state?.isBulk && (
        <div className="flex justify-between items-center mb-16">
          <Typography className="font-semibold text-16 md:text-24 flex items-center gap-4">
            {' '}
            <Button
              className="w-16 h-16 min-w-16 min-h-16"
              onClick={() => history.push('quick-hub/primaryDetails', location?.state?.activeTab)}
            >
              <ArrowBackIcon />
            </Button>
            {t('primaryDetails.attributes')}
          </Typography>
          <Button
            className="rounded-md font-semibold"
            variant="contained"
            color="secondary"
            onClick={() => setAddAttribute(true)}
          >
            {t('primaryDetails.addAttributes')}
          </Button>
        </div>
      )}
      {defaultAttribute?.length > 0 && !addAttribute && !location?.state?.isBulk ? (
        <>
          {defaultAttribute?.map((attribute, index) => {
            return (
              <div className="bg-white rounded-md p-16 mt-16" key={index}>
                <Typography className="font-semibold text-18">
                  {attribute?.groupDisplayName}
                </Typography>
                {attribute?.attributes?.length > 0 &&
                  attribute?.attributes?.map((attr, i) => {
                    return (
                      <div
                        className="flex items-center justify-between border mt-12 rounded-md px-20 py-6"
                        key={i}
                      >
                        <Typography className="font-semibold text-16">
                          {attr?.displayName}
                        </Typography>
                        <Button
                          className="p-0 min-w-36 w-36 min-h-32 h-32 bg-gray-A500 hover:bg-gray-A500"
                          onClick={() => handleDeleteAttribute(attr?.name)}
                          disabled={refreshIcon && deleteAttribute === attr?.name}
                        >
                          <img className="w-16" src="assets/images/quick-hub/delete.png" alt="" />
                          {refreshIcon && deleteAttribute === attr?.name && (
                            <CircularProgress
                              className="text-quick-review absolute mx-auto"
                              size={24}
                            />
                          )}
                        </Button>
                      </div>
                    );
                  })}
              </div>
            );
          })}
        </>
      ) : (
        <AddAttributes
          googleAttribute={googleAttribute}
          selectLabelOrBusiness={selectLabelOrBusiness}
          setAddAttribute={setAddAttribute}
          location={location}
          defaultAttribute={defaultAttribute}
          setSelectLabelOrBusiness={setSelectLabelOrBusiness}
          openBusinessDrawer={openBusinessDrawer}
          setOpenBusinessDrawer={setOpenBusinessDrawer}
          fetchDefaultGoogleAttribute={fetchDefaultGoogleAttribute}
        />
      )}
    </div>
  );
};

export default Attributes;
