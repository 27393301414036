import { Button, Dialog, DialogContent, DialogTitle } from '@mui/material';
import { t } from 'i18next';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import React from 'react';
import UserProfile from '../common/userProfile';

const FacebookPagesPopUp = ({
  pageData,
  open,
  onClose,
  checkInstagramConnectivity,
  setPages,
  saveMetaAdsDetails,
  instagramConnected,
}) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="delete-confirmation-dialog"
      classes={{
        paper: ` business-type-dropdown rounded-md`,
      }}
      PaperProps={{ style: { maxWidth: '460px' } }}
    >
      <DialogContent
        classes={{
          root: 'p-0',
        }}
      >
        <h1 className="text-20 font-600 p-20">{t('quickAds.pageSelectTitle')}</h1>
        <IconButton className="absolute top-10 right-10 " onClick={onClose} color="inherit">
          <CloseIcon className="w-24 h-24 text-grey-700" />
        </IconButton>
        <hr className="border-t-grey-800 border-5" />
        <div className="p-20">
          <div>
            {' '}
            <p className="text-18 font-500">{t('quickAds.pageSelectContent')}</p>
          </div>
          <div>
            {pageData?.pageDetails &&
              pageData?.pageDetails?.map((page, index) => (
                <div
                  key={index}
                  className="border-2 p-10 mt-16 rounded-md flex gap-16 border-gray-500 "
                >
                  {page?.profilePictureUrl ? (
                    <img
                      src={page?.profilePictureUrl}
                      alt="profile"
                      className="h-80 w-84 rounded-full"
                    />
                  ) : (
                    <div>
                      <UserProfile name={page?.name} width="85px" height="85px" />
                    </div>
                  )}
                  <div className="w-full flex justify-between items-center">
                    {' '}
                    <div>
                      <h3 className="text-18 font-500"> {page?.name} </h3>
                      <p className="text-16 font-500 text-gray-500">{page?.category}</p>
                    </div>
                    <div className="flex justify-between items-center">
                      <Button
                        variant="contained"
                        color="secondary"
                        autoFocus
                        className="rounded-md bg-quick-social-100 text-black hover:text-white"
                        size="small"
                        style={{
                          border: '1px solid #219EBC',
                        }}
                        onClick={() => {
                          checkInstagramConnectivity(page, pageData, instagramConnected);
                          setPages(false);
                        }}
                      >
                        {t('quickSocialPost.connect')}
                      </Button>
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default FacebookPagesPopUp;
