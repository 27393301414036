import { useEffect, useMemo, useState } from 'react';
import BusinessIcon from '@mui/icons-material/Business';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormLabel,
  Icon,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import { t } from 'i18next';
import { useSnackbar } from 'notistack';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import { useSelector } from 'react-redux';
import { getCountryData } from 'app/store/userSlice';
import manageBusinessData from '../../../query/manageBusiness';
import { handleApiRequest } from '../../../common/common';
import queryData from '../../../query/common';

const BasicBusinessDetails = (props) => {
  const {
    fetchBusinessDetails,
    userCurrentBusinessDetails,
    googleBusinessData,
    openDialog,
    setOpenDialog,
    statusDialog,
    addressDialog,
  } = props;

  const businessStatus = useMemo(
    () => [
      {
        key: 'open',
        value: 'Open',
        displayName: 'OPEN',
      },
      {
        key: 'openUnspecified',
        value: 'Open Unspecified',
        displayName: 'OPEN_FOR_BUSINESS_UNSPECIFIED',
      },
      {
        key: 'closeTemp',
        value: 'Close Temporary',
        displayName: 'CLOSED_TEMPORARILY',
      },
      {
        key: 'closePermanently',
        value: 'Close Permanently',
        displayName: 'CLOSED_PERMANENTLY',
      },
    ],
    []
  );

  const [primaryPhoneNumber, setPrimaryPhoneNumber] = useState(
    googleBusinessData?.phoneNumbers?.primaryPhone?.slice(1)?.replace(/\s+/g, '')
  );
  const [additionalPhoneNumber, setAdditionalPhoneNumber] = useState([
    {
      phoneNumber:
        googleBusinessData?.phoneNumbers?.additionalPhones?.[0]?.slice(1)?.replace(/\s+/g, '') ||
        '',
    },
    {
      phoneNumber:
        googleBusinessData?.phoneNumbers?.additionalPhones?.[1]?.slice(1)?.replace(/\s+/g, '') ||
        '',
    },
  ]);
  const [refreshIcon, setRefreshIcon] = useState(false);
  const [selectedBusinessStatus, setSelectedBusinessStatus] = useState();
  const { enqueueSnackbar } = useSnackbar();
  const [addressDetails, setAddressDetails] = useState({
    address: '',
    city: '',
    stateId: '',
    countryId: '',
    pinCode: '',
  });
  const [stateList, setStateList] = useState([]);
  const countriesData = useSelector(getCountryData);

  useEffect(() => {
    const fetchData = async () => {
      if (addressDetails?.countryId) {
        const payload = {
          query: queryData.getStates,
          variables: { countryId: addressDetails?.countryId },
        };
        const res = await handleApiRequest(payload);
        if (res?.getStates?.status === 200) {
          setStateList(res?.getStates?.data);
          const findState = res?.getStates?.data?.find(
            (country) => country?.name === googleBusinessData?.storefrontAddress?.administrativeArea
          );
          setAddressDetails((prevDetails) => {
            if (prevDetails?.stateId !== findState?.id) {
              return { ...prevDetails, stateId: findState?.id };
            }
            return prevDetails;
          });
        }
      }
    };
    fetchData();
  }, [addressDetails?.countryId, googleBusinessData]);

  useEffect(() => {
    const findBusinessStatus = businessStatus?.find(
      (item) => item?.displayName === googleBusinessData?.openInfo?.status
    );
    if (findBusinessStatus) {
      setSelectedBusinessStatus((prevStatus) =>
        prevStatus !== findBusinessStatus?.key ? findBusinessStatus?.key : prevStatus
      );
    }
    const findCountry = countriesData?.find(
      (country) => country?.isoCode === googleBusinessData?.storefrontAddress?.regionCode
    );
    if (findCountry) {
      setAddressDetails((prevDetails) => {
        if (prevDetails?.countryId !== findCountry?.id) {
          return { ...prevDetails, countryId: findCountry?.id };
        }
        return prevDetails;
      });
    }
    setAddressDetails((prevDetails) => {
      return {
        ...prevDetails,
        address: googleBusinessData?.storefrontAddress?.addressLines?.join(', '),
        city: googleBusinessData?.storefrontAddress?.locality,
        pinCode: googleBusinessData?.storefrontAddress?.postalCode,
      };
    });
  }, [googleBusinessData, businessStatus, countriesData]);

  const handleClose = () => {
    setAdditionalPhoneNumber([
      {
        phoneNumber:
          googleBusinessData?.phoneNumbers?.additionalPhones?.[0]?.slice(1)?.replace(/\s+/g, '') ||
          '',
      },
      {
        phoneNumber:
          googleBusinessData?.phoneNumbers?.additionalPhones?.[1]?.slice(1)?.replace(/\s+/g, '') ||
          '',
      },
    ]);
    setPrimaryPhoneNumber(
      googleBusinessData?.phoneNumbers?.primaryPhone?.slice(1)?.replace(/\s+/g, '')
    );
    setOpenDialog({ ...openDialog, phoneNumberDialog: false });
  };

  const handlePrimaryNumber = (event) => {
    setPrimaryPhoneNumber(event.target.value);
  };

  const handleAdditionalPhoneNumber = (event) => {
    setAdditionalPhoneNumber((prevNumbers) => {
      const updatedNumbers = [...prevNumbers];
      if (event.target.name === 'phoneNumber1') {
        updatedNumbers[0] = { phoneNumber: event.target.value };
      } else if (event.target.name === 'phoneNumber2') {
        updatedNumbers[1] = { phoneNumber: event.target.value };
      }
      return updatedNumbers;
    });
  };

  const onSubmit = async () => {
    setRefreshIcon(true);
    const validAdditionalPhones = additionalPhoneNumber
      .filter((phone) => !Number.isNaN(parseInt(phone.phoneNumber, 10)))
      .map((phone) => ({
        phoneNumber: parseInt(phone?.phoneNumber?.replace(/\D+/g, ''), 10),
      }));
    try {
      const payload = {
        query: manageBusinessData.updateGoogleBusinessDetails,
        variables: {
          businessId: parseInt(userCurrentBusinessDetails?.id, 10),
          mask: 'phoneNumbers',
          phoneNumbers: {
            primaryPhone: {
              phoneNumber: parseInt(primaryPhoneNumber, 10),
            },
            additionalPhones: validAdditionalPhones.length > 0 ? validAdditionalPhones : undefined,
          },
        },
      };
      const result = await handleApiRequest(payload);
      setRefreshIcon(false);
      if (result?.updateGoogleBusinessDetails?.status === 200) {
        fetchBusinessDetails();
        handleClose();
      } else {
        enqueueSnackbar(result?.updateGoogleBusinessDetails?.message, {
          variant: 'error',
          autoHideDuration: 3000,
        });
      }
    } catch (error) {
      setRefreshIcon(false);
      console.error('Error updating phone numbers:', error);
    }
  };

  const handleChangeStatus = async () => {
    try {
      setRefreshIcon(true);
      const payload = {
        query: manageBusinessData.updateGoogleBusinessBusinessStatus,
        variables: {
          status: selectedBusinessStatus,
          userBusinessId: Number(userCurrentBusinessDetails?.id),
        },
      };
      const res = await handleApiRequest(payload);
      setRefreshIcon(false);
      if (res?.updateGoogleBusinessBusinessStatus?.status === 200) {
        fetchBusinessDetails();
        setOpenDialog({ ...openDialog, statusDialog: false });
        enqueueSnackbar(res?.updateGoogleBusinessBusinessStatus?.message, {
          variant: 'success',
          autoHideDuration: 3000,
        });
      } else {
        enqueueSnackbar('Failed to update business status. Please try again.', {
          variant: 'error',
          autoHideDuration: 3000,
        });
      }
    } catch (error) {
      setRefreshIcon(false);
      console.error('Error in handleChangeStatus:', error);
    }
  };

  const handleUpdateBusinessAddress = async () => {
    setRefreshIcon(true);
    try {
      const findCountry = countriesData?.find(
        (country) => country?.id === addressDetails?.countryId
      );
      const findState = stateList?.find((state) => state?.id === addressDetails?.stateId);
      const payload = {
        query: manageBusinessData.updateGoogleBusinessBusinessAddress,
        variables: {
          addressLines: [addressDetails?.address],
          administrativeArea: findState?.name,
          userBusinessId: Number(userCurrentBusinessDetails?.id),
          city: addressDetails?.city,
          postalCode: addressDetails?.pinCode,
          regionCode: findCountry?.isoCode,
        },
      };
      const result = await handleApiRequest(payload);
      if (result?.updateGoogleBusinessBusinessAddress?.status === 200) {
        fetchBusinessDetails();
        setOpenDialog((prev) => ({ ...prev, addressLinesDialog: false }));
        enqueueSnackbar(result?.updateGoogleBusinessBusinessAddress?.message, {
          variant: 'success',
          autoHideDuration: 3000,
        });
      } else {
        enqueueSnackbar(result?.updateGoogleBusinessBusinessAddress?.message, {
          variant: 'error',
          autoHideDuration: 3000,
        });
      }
    } catch (error) {
      console.log('An error occurred while updating the address', error);
    } finally {
      setRefreshIcon(false);
    }
  };

  return (
    <div>
      <Typography className="font-bold md:text-28 text-20 mt-16">
        <BusinessIcon className="md:text-32 text-24" /> {t('listManagement.basicBusinessDetails')}
      </Typography>
      <div className="bg-gray-A500 lg:grid grid-cols-2 col-span-1 gap-16 p-28 rounded-md mt-16">
        <div className="bg-white p-24 rounded-md">
          <div className="flex justify-between items-center w-full text-end">
            <Typography className="font-bold text-16">{t('listManagement.phoneNumber')}</Typography>
            <Button
              className="p-0 h-16 min-h-16 w-16 min-w-16"
              onClick={() => setOpenDialog({ ...openDialog, phoneNumberDialog: true })}
            >
              <ModeEditOutlineOutlinedIcon />
            </Button>
          </div>
          <Typography className="font-semibold text-14">
            {t('common.phoneNumber')} : {googleBusinessData?.phoneNumbers?.primaryPhone}
          </Typography>
        </div>
        <div className="bg-white p-24 rounded-md my-16 lg:my-0">
          <div className="flex justify-between items-center w-full text-end">
            <Typography className="font-bold text-16">
              {t('listManagement.businessStatus')}
            </Typography>
            <Button
              className="p-0 h-16 min-h-16 w-16 min-w-16"
              onClick={() => setOpenDialog({ ...openDialog, statusDialog: true })}
            >
              <ModeEditOutlineOutlinedIcon />
            </Button>
          </div>
          <Typography className="font-semibold text-14">
            {t('listManagement.businessStatus')} : {googleBusinessData?.openInfo?.status}
          </Typography>
        </div>
        <div className="bg-white p-24 rounded-md">
          <div className="flex justify-between items-center w-full text-end">
            <Typography className="font-bold text-16">
              {t('listManagement.locationUpdate')}
            </Typography>
            <Button
              className="p-0 h-16 min-h-16 w-16 min-w-16"
              onClick={() => setOpenDialog({ ...openDialog, addressLinesDialog: true })}
            >
              <ModeEditOutlineOutlinedIcon />
            </Button>
          </div>
          <Typography className="font-semibold text-14">
            {googleBusinessData?.storefrontAddress?.addressLines?.[0]}{' '}
            {googleBusinessData?.storefrontAddress?.administrativeArea}{' '}
            {googleBusinessData?.storefrontAddress?.locality}{' '}
            {googleBusinessData?.storefrontAddress?.administrativeArea}{' '}
            {googleBusinessData?.storefrontAddress?.postalCode}
          </Typography>
        </div>
      </div>
      <Dialog
        classes={{
          paper: 'm-24 sm:p-28 p-20 business-type-dropdown',
        }}
        open={openDialog}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle className="p-0 mb-8">
          <div className="flex">
            <Typography className="text-20 font-bold text-black w-full">
              {t('listManagement.updatePhoneNumber')}
            </Typography>
            <Icon
              onClick={() => handleClose()}
              className="text-24 text-grey-400 font-normal cursor-pointer hover:text-gray-700 focus:text-gray-700"
            >
              cancel
            </Icon>
          </div>
          <div className="border-b-2 border-solid border-quick-review-100 pb-24">
            <Typography className="font-bold text-20">{googleBusinessData?.title}</Typography>
            <Typography className="text-gray text-16 font-semibold pb-8">
              {t('common.address')} : {googleBusinessData?.storefrontAddress?.addressLines?.[0]}{' '}
              {googleBusinessData?.storefrontAddress?.administrativeArea}{' '}
              {googleBusinessData?.storefrontAddress?.locality}{' '}
              {googleBusinessData?.storefrontAddress?.administrativeArea}{' '}
              {googleBusinessData?.storefrontAddress?.postalCode}
            </Typography>
          </div>
          <div>
            <Typography className="font-bold text-16 pt-20">
              {t('listManagement.addPrimaryAndAdditionalPhoneNumber')}
            </Typography>
            <div className="py-8">
              <Typography className="font-bold text-16 pb-8">
                {t('listManagement.primaryPhoneNumber')}
              </Typography>
              <TextField
                className="w-full"
                type="number"
                placeholder={t('listManagement.placeHolder.phoneNumber')}
                value={primaryPhoneNumber}
                onChange={handlePrimaryNumber}
                size="small"
              />
            </div>
            <div>
              <Typography className="font-bold text-16 py-8">
                {t('listManagement.additionalPhoneNumber1')} ({t('signUpPage.optional')})
              </Typography>
              <TextField
                className="w-full"
                name="phoneNumber1"
                type="number"
                placeholder={t('listManagement.placeHolder.additionalPhoneNumber1')}
                value={additionalPhoneNumber[0]?.phoneNumber?.toString()}
                onChange={(event) => handleAdditionalPhoneNumber(event)}
                size="small"
              />
            </div>
            <div>
              <Typography className="font-bold text-16 py-8">
                {t('listManagement.additionalPhoneNumber2')} ({t('signUpPage.optional')})
              </Typography>
              <TextField
                className="w-full"
                name="phoneNumber2"
                type="number"
                placeholder={t('listManagement.placeHolder.additionalPhoneNumber1')}
                value={additionalPhoneNumber[1]?.phoneNumber?.toString()}
                onChange={(event) => handleAdditionalPhoneNumber(event)}
                size="small"
              />
            </div>
          </div>
          <div className="text-center">
            <Button
              className="bg-quick-review w-1/2 text-white rounded-full mt-16 px-40 disabled:text-white font-bold text-18 disabled:bg-gray hover:bg-quick-review"
              disabled={refreshIcon || !primaryPhoneNumber}
              onClick={() => onSubmit()}
            >
              {refreshIcon && (
                <CircularProgress className="text-quick-review absolute mx-auto" size={24} />
              )}
              Save
            </Button>
          </div>
        </DialogTitle>
      </Dialog>
      <Dialog
        classes={{
          paper: 'm-24 p-28 business-type-dropdown',
        }}
        open={statusDialog}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle className="p-0 mb-8">
          <div className="flex">
            <Typography className="text-20 font-bold text-black w-full">
              {t('listManagement.updateBusinessStatus')}
            </Typography>
            <Icon
              onClick={() => setOpenDialog({ ...openDialog, statusDialog: false })}
              className="text-24 text-grey-400 font-normal cursor-pointer hover:text-gray-700 focus:text-gray-700"
            >
              cancel
            </Icon>
          </div>
          <div className="border-b-2 border-solid border-quick-review-100 pb-24">
            <Typography className="font-bold text-20">{googleBusinessData?.title}</Typography>
            <Typography className="text-gray text-16 font-semibold pb-8">
              {t('listManagement.address')} :{' '}
              {googleBusinessData?.storefrontAddress?.addressLines?.[0]}{' '}
              {googleBusinessData?.storefrontAddress?.administrativeArea}{' '}
              {googleBusinessData?.storefrontAddress?.locality}{' '}
              {googleBusinessData?.storefrontAddress?.administrativeArea}{' '}
              {googleBusinessData?.storefrontAddress?.postalCode}
            </Typography>
          </div>
          <div>
            <Typography className="font-bold text-16 pt-20">
              {t('listManagement.setYourBusinessStatus')}
            </Typography>
            <FormControl>
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                defaultValue="female"
                name="radio-buttons-group"
                onChange={(e) => setSelectedBusinessStatus(e.target.value)}
              >
                {businessStatus?.map((item, i) => {
                  return (
                    <FormControlLabel
                      key={i}
                      value={item?.key}
                      checked={selectedBusinessStatus === item?.key}
                      control={<Radio />}
                      label={item?.value}
                    />
                  );
                })}
              </RadioGroup>
            </FormControl>
          </div>
          <div className="text-center">
            <Button
              className="bg-quick-review w-1/2 text-white rounded-full mt-16 px-40 disabled:text-white font-bold text-18 disabled:bg-gray hover:bg-quick-review"
              disabled={refreshIcon || !selectedBusinessStatus}
              onClick={() => handleChangeStatus()}
            >
              {refreshIcon && (
                <CircularProgress className="text-quick-review absolute mx-auto" size={24} />
              )}
              Save
            </Button>
          </div>
        </DialogTitle>
      </Dialog>
      <Dialog
        classes={{
          paper: 'm-24 p-28 business-type-dropdown',
        }}
        open={addressDialog}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle className="p-0 mb-8">
          <div className="flex">
            <Typography className="text-20 font-bold text-black w-full">
              {t('listManagement.updateBusinessAddress')}
            </Typography>
            <Icon
              onClick={() => setOpenDialog({ ...openDialog, statusDialog: false })}
              className="text-24 text-grey-400 font-normal cursor-pointer hover:text-gray-700 focus:text-gray-700"
            >
              cancel
            </Icon>
          </div>
          <div className="border-b-2 border-solid border-quick-review-100 pb-24">
            <Typography className="font-bold text-20">{googleBusinessData?.title}</Typography>
            <Typography className="text-gray text-16 font-semibold pb-8">
              {t('listManagement.address')} :{' '}
              {googleBusinessData?.storefrontAddress?.addressLines?.[0]}{' '}
              {googleBusinessData?.storefrontAddress?.administrativeArea}{' '}
              {googleBusinessData?.storefrontAddress?.locality}{' '}
              {googleBusinessData?.storefrontAddress?.administrativeArea}{' '}
              {googleBusinessData?.storefrontAddress?.postalCode}
            </Typography>
          </div>
          <div>
            <Typography className="font-bold text-16 pt-20">
              {t('listManagement.addYourBusinessAddress')}
            </Typography>
            <div className="w-full mt-8">
              <div className="flex justify-between gap-16">
                <div className="w-full">
                  <Typography className="mb-6 font-semibold">
                    {t('listManagement.address')}
                  </Typography>
                  <TextField
                    className="w-full mb-6"
                    size="small"
                    placeholder={t('listManagement.enterYourAddress')}
                    onChange={(e) =>
                      setAddressDetails({ ...addressDetails, address: e.target.value })
                    }
                    value={addressDetails?.address}
                  />
                </div>
                <div className="w-full">
                  <Typography className="mb-6 font-semibold">{t('listManagement.city')}</Typography>
                  <TextField
                    className="w-full mb-6"
                    size="small"
                    placeholder={t('listManagement.enterYourAddress')}
                    onChange={(e) => setAddressDetails({ ...addressDetails, city: e.target.value })}
                    value={addressDetails?.city}
                  />
                </div>
              </div>
              <div className="w-full flex justify-between gap-16">
                <div className="w-full">
                  <Typography className="mb-6 font-semibold">
                    {t('listManagement.country')}
                  </Typography>
                  <Select
                    className="w-full"
                    value={addressDetails?.countryId || 0}
                    size="small"
                    onChange={(e) =>
                      setAddressDetails({ ...addressDetails, countryId: e.target.value })
                    }
                  >
                    <MenuItem className="w-full" value="0" disabled>
                      {t('listManagement.selectCountry')}
                    </MenuItem>
                    {countriesData?.length > 0 &&
                      countriesData?.map((country, i) => {
                        return (
                          <MenuItem className="w-full" key={i} value={country?.id}>
                            {country?.name}
                          </MenuItem>
                        );
                      })}
                  </Select>
                </div>
                <div className="w-full">
                  <Typography className="mb-6 font-semibold">
                    {t('listManagement.state')}
                  </Typography>
                  <Select
                    className="w-full"
                    value={addressDetails?.stateId || 0}
                    size="small"
                    onChange={(e) =>
                      setAddressDetails({ ...addressDetails, stateId: e.target.value })
                    }
                  >
                    <MenuItem className="w-full" value="0" disabled>
                      {t('listManagement.selectState')}
                    </MenuItem>
                    {stateList?.length > 0 &&
                      stateList?.map((state, i) => {
                        return (
                          <MenuItem key={i} className="w-full" value={state?.id}>
                            {state?.name}
                          </MenuItem>
                        );
                      })}
                  </Select>
                </div>
                <div className="w-full">
                  <Typography className="mb-6 font-semibold">
                    {t('listManagement.pinCode')}
                  </Typography>
                  <TextField className="w-full mt-2" size="small" value={addressDetails?.pinCode} />
                </div>
              </div>
            </div>
          </div>
          <div className="text-center">
            <Button
              className="bg-quick-review w-1/2 text-white rounded-full mt-16 px-40 disabled:text-white font-bold text-18 disabled:bg-gray hover:bg-quick-review"
              disabled={
                refreshIcon ||
                !addressDetails?.pinCode ||
                !addressDetails?.address ||
                !addressDetails?.countryId ||
                !addressDetails?.stateId ||
                !addressDetails?.city
              }
              onClick={() => handleUpdateBusinessAddress()}
            >
              {refreshIcon && (
                <CircularProgress className="text-quick-review absolute mx-auto" size={24} />
              )}
              Save
            </Button>
          </div>
        </DialogTitle>
      </Dialog>
    </div>
  );
};

export default BasicBusinessDetails;
