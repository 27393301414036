import PropTypes from 'prop-types';
import withReducer from 'app/store/withReducer';
import { useEffect, useState, useRef, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Typography from '@mui/material/Typography';
import Input from '@mui/material/Input';
import MenuItem from '@mui/material/MenuItem';
import Paper from '@mui/material/Paper';
import Icon from '@mui/material/Icon';
import Table from '@mui/material/Table';
import Select from '@mui/material/Select';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import DeleteIcon from '@mui/icons-material/Delete';
import Toolbar from '@mui/material/Toolbar';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import { motion } from 'framer-motion';
import moment from 'moment';
import FuseLoading from '@fuse/core/FuseLoading';
import { useTranslation } from 'react-i18next';
import history from '@history';
import { makeStyles } from '@mui/styles';
import { useSnackbar } from 'notistack';
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import visitedBusinessData from '../../query/visitedBusiness';
import {
  getVisitedBusinessBySalesPerson,
  resetVisitedBusiness,
  getUserBusinessStatus,
} from './store/visitedBusinessSlice';
import reducer from './store';
import selectedBusinessData from '../../query/selectedBusiness';
import { formatDateWithTimeZone, handleApiRequest } from '../../common/common';

const useStyles = makeStyles(() => ({
  root: {
    '& .MuiAutocomplete-tag': {
      backgroundColor: '#0BA861',
      color: 'white',
      borderRadius: '8px',
    },
    '& .MuiChip-deleteIcon': {
      color: 'white',
    },
  },
}));

function VisitedBusiness() {
  const classes = useStyles();

  const dayArray = [
    { id: 1, day: 1 },
    { id: 2, day: 2 },
    { id: 3, day: 3 },
    { id: 4, day: 4 },
    { id: 5, day: 5 },
    { id: 6, day: 6 },
    { id: 7, day: 7 },
  ];
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const visitedBusinessList = useSelector(
    ({ visitedBusinessReducer }) =>
      visitedBusinessReducer?.visitedBusiness?.getVisitedBusinessBySalesPersonData?.data
        ?.getVisitedBusinessBySalesPerson?.data || ''
  );
  const totalUser = useSelector(
    ({ visitedBusinessReducer }) =>
      visitedBusinessReducer?.visitedBusiness?.getVisitedBusinessBySalesPersonData?.data
        ?.getVisitedBusinessBySalesPerson?.totalCount || ''
  );

  const [timeZone] = useState(Intl.DateTimeFormat().resolvedOptions().timeZone);
  const [loading, setLoading] = useState(true);
  const [loadingPage, setLoadingPage] = useState(false);
  const [searchLoading, setSearchLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchValue, setSearchValue] = useState('');
  const [selected, setSelected] = useState([]);
  const [status, setStatus] = useState('all');
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [visitedBusinessListData, setVisitedBusinessListData] = useState([visitedBusinessList]);
  const [orderNow, setOrderNow] = useState('desc');
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('cts');
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [extendUserId, setExtendUserId] = useState();
  const [selectExtendDays, setSelectExtendDays] = useState();
  useEffect(() => {
    let unmounted = false;
    const payload = {
      query: visitedBusinessData.getVisitedBusinessBySalesPerson,
      variables: {
        searchText: '',
        pageNumber: 1,
        pageSize: 10,
        sortBy: 'cts',
        sortOrder: 'desc',
      },
    };
    dispatch(getVisitedBusinessBySalesPerson(payload));

    return () => {
      unmounted = true;
      dispatch(resetVisitedBusiness());
    };
  }, [dispatch]);

  useEffect(() => {
    const fetchData = async () => {
      const payload = {
        query: selectedBusinessData.getUserBusinessStatus,
      };
      await dispatch(getUserBusinessStatus(payload));
    };
    fetchData();
  }, [dispatch]);

  useEffect(() => {
    if (visitedBusinessList && visitedBusinessList.length) {
      setVisitedBusinessListData(visitedBusinessList);
    } else {
      setVisitedBusinessListData();
    }
  }, [visitedBusinessList]);

  useEffect(() => {
    if (visitedBusinessList || visitedBusinessList === null) {
      setLoading(false);
    } else {
      setLoading(true);
    }
  }, [visitedBusinessList]);

  function commonGetData(searchValues = '') {
    setSearchValue(searchValues);
    const payload = {
      query: visitedBusinessData.getVisitedBusinessBySalesPerson,
      variables: {
        searchText: searchValues,
        pageNumber: page,
        pageSize: rowsPerPage,
        sortBy: orderBy,
        sortOrder: orderNow,
      },
    };
    if (status !== 'all') {
      payload.variables.isBookmark = true;
    }
    dispatch(getVisitedBusinessBySalesPerson(payload));
  }

  function handleSearchEvent(event) {
    setSearchLoading(true);
    setPage(1);
    const searchText = event.target.value;
    commonGetData(searchText);
    setTimeout(() => {
      setSearchLoading(false);
    }, 800);
  }

  const clearSearch = async () => {
    await setPage(1);
    await setSearchValue('');
    await commonGetData();
  };

  function handleChangePage(event, value) {
    setPage(value + 1);
    setLoadingPage(true);
    const payload = {
      query: visitedBusinessData.getVisitedBusinessBySalesPerson,
      variables: {
        searchText: searchValue,
        pageNumber: value + 1,
        pageSize: rowsPerPage,
        sortBy: orderBy,
        sortOrder: orderNow,
      },
    };
    if (status !== 'all') {
      payload.variables.isBookmark = status;
    }
    dispatch(async (dispatchValue) => {
      await dispatchValue(getVisitedBusinessBySalesPerson(payload));
      setLoadingPage(false);
    });
  }

  function handleChangeRowsPerPage(event) {
    setLoadingPage(true);
    setRowsPerPage(event.target.value);
    setPage(1);
    const payload = {
      query: visitedBusinessData.getVisitedBusinessBySalesPerson,
      variables: {
        searchText: searchValue,
        pageNumber: 1,
        pageSize: event.target.value,
        sortBy: orderBy,
        sortOrder: orderNow,
      },
    };
    if (status !== 'all') {
      payload.variables.isBookmark = status;
    }
    dispatch(async (dispatchValue) => {
      await dispatchValue(getVisitedBusinessBySalesPerson(payload));
      setLoadingPage(false);
    });
  }
  function handleStatusDropdownChange(event) {
    const statusValue = event?.target?.value;
    const isAllStatus = statusValue === 'all';

    setStatus(statusValue);
    setLoading(true);
    setPage(1);

    const payload = {
      query: visitedBusinessData.getVisitedBusinessBySalesPerson,
      variables: {
        searchText: searchValue,
        pageNumber: 1,
        pageSize: rowsPerPage,
        sortBy: orderBy,
        sortOrder: orderNow,
      },
    };
    if (!isAllStatus) {
      payload.variables.isBookmark = Boolean(JSON.parse(statusValue));
    }

    dispatch(getVisitedBusinessBySalesPerson(payload));

    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }

  const handleSorting = async (event) => {
    const sortColumnName = event;
    setOrderBy(sortColumnName);
    if (order === 'asc') {
      const sorted = [...visitedBusinessListData].sort((sortTypeA, sortTypeB) =>
        sortTypeA.col > sortTypeB.col ? 1 : 1
      );
      setVisitedBusinessListData(sorted);
      setOrderNow('asc');
      setOrder('desc');
    }
    if (order === 'desc') {
      const sorted = [...visitedBusinessListData].sort((sortTypeA, sortTypeB) =>
        sortTypeA.col < sortTypeB.col ? 1 : 1
      );
      setVisitedBusinessListData(sorted);
      setOrderNow('desc');
      setOrder('asc');
    }
    const payload = {
      query: visitedBusinessData.getVisitedBusinessBySalesPerson,
      variables: {
        pageSize: rowsPerPage,
        pageNumber: page,
        searchText: searchValue,
        sortBy: sortColumnName,
        sortOrder: order,
      },
    };
    dispatch(getVisitedBusinessBySalesPerson(payload));
  };

  function EnhancedTableToolbar(props) {
    const { numSelected } = props;
    if (numSelected > 0) {
      return (
        <Toolbar className="absolute left-0 right-0 -top-80 bg-black text-white z-10 rounded-md">
          {numSelected > 0 ? (
            <Typography
              sx={{ flex: '1 1 100%' }}
              color="inherit"
              variant="subtitle1"
              component="div"
              className="text-white"
            >
              {numSelected} Selected
            </Typography>
          ) : (
            ''
          )}

          {numSelected > 0 ? (
            <Tooltip title="Delete">
              <IconButton className="text-white">
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          ) : (
            ''
          )}
        </Toolbar>
      );
    }
  }

  EnhancedTableToolbar.propTypes = {
    numSelected: PropTypes.number.isRequired,
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = visitedBusinessList.map((n) => n.id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;

  function EnhancedTableHead(props) {
    const { onSelectAllClick, numSelected, rowCount } = props;

    return (
      <TableHead className="bg-grey-50 rounded-md hidden md:table-header-group">
        <TableRow>
          {/* <TableCell padding="checkbox" className="rounded-tl-md">
            <Checkbox
              color="primary"
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={onSelectAllClick}
              inputProps={{
                'aria-label': 'select all User',
              }}
            />
          </TableCell> */}
          <TableCell className="text-14 font-medium" align="left">
            <TableSortLabel hideSortIcon>
              {t('business.visitedBusiness.column.srNo')}
            </TableSortLabel>
          </TableCell>
          <TableCell className="text-14 font-medium" align="left">
            <TableSortLabel hideSortIcon>{t('common.businessName')}</TableSortLabel>
          </TableCell>
          <TableCell className="text-14 font-medium" align="left">
            <TableSortLabel hideSortIcon>{t('common.userName')}</TableSortLabel>
          </TableCell>
          <TableCell className="text-14 font-medium" align="left">
            <TableSortLabel
              direction={order}
              onClick={() => {
                handleSorting('cts');
              }}
            >
              {t('business.visitedBusiness.column.date')}
            </TableSortLabel>
          </TableCell>
          <TableCell className="text-14 font-medium" align="left">
            <TableSortLabel hideSortIcon>{t('common.phoneNumber')}</TableSortLabel>
          </TableCell>
          <TableCell className="text-14 font-medium" align="left">
            <TableSortLabel hideSortIcon>{t('common.status')}</TableSortLabel>
          </TableCell>
          <TableCell className="text-14 font-medium" align="left">
            <TableSortLabel hideSortIcon>
              {t('business.searchBusiness.column.validity')}
            </TableSortLabel>
          </TableCell>
          <TableCell className="text-14 font-medium rounded-tr-md">
            <TableSortLabel hideSortIcon>{t('common.action')}</TableSortLabel>
          </TableCell>
        </TableRow>
      </TableHead>
    );
  }

  if (loading) {
    return <FuseLoading />;
  }

  function redirectBusinessDetailsRoutes(id) {
    return history.push({
      pathname: `/visited-business-details/${id}`,
    });
  }

  const handleOpenDialogue = (id) => {
    setExtendUserId(id);
    setIsDialogOpen(true);
  };

  const handleSelectExtendDays = (e) => {
    setSelectExtendDays(e.target.value);
  };

  const handleClearExtendDays = () => {
    setIsDialogOpen(false);
    setExtendUserId();
    setSelectExtendDays(0);
  };

  const handleSubmitExtendDays = async () => {
    const payload = {
      query: selectedBusinessData.extendDemoAccountValidity,
      variables: {
        extendDay: selectExtendDays,
        userId: extendUserId,
      },
    };
    const result = await handleApiRequest(payload);
    if (result?.extendDemoAccountValidity?.status === 200) {
      enqueueSnackbar(result?.extendDemoAccountValidity?.message, {
        variant: 'success',
        autoHideDuration: 3000,
      });
      handleClearExtendDays();
      commonGetData();
    }
    if (result?.extendDemoAccountValidity?.status === 400) {
      enqueueSnackbar(result?.extendDemoAccountValidity?.message, {
        variant: 'error',
        autoHideDuration: 3000,
      });
    }
  };

  return (
    <div className="p-20 lg:p-24">
      {loadingPage && (
        <div className="loader-container fixed top-0 left-0 w-full h-full flex justify-center items-center">
          <div className="loader h-40 w-40 rounded-full border-4 border-solid border-grey-300 border-t-darkgreen" />
        </div>
      )}
      <div className="md:flex flex-1 w-full items-center justify-between md:mb-36 mb-20">
        <div className="flex items-center">
          <Typography
            component={motion.span}
            initial={{ x: -20 }}
            animate={{ x: 0, transition: { delay: 0.2 } }}
            delay={300}
            className="sm:flex text-20 md:text-28 font-bold mb-16 md:mb-0"
          >
            {t('business.visitedBusiness.title')}
          </Typography>
        </div>
        <div className="flex flex-1 items-center justify-center px-0 md:px-12">
          <Paper
            component={motion.div}
            initial={{ y: -20, opacity: 0 }}
            animate={{ y: 0, opacity: 1, transition: { delay: 0.2 } }}
            className="flex items-center w-full md:max-w-512 px-8 rounded-8 shadow-none"
          >
            <Input
              placeholder={t('business.visitedBusiness.labels.searchBusinessName')}
              className="flex flex-1 mx-8 text-black text-16"
              disableUnderline
              name="searchText"
              fullWidth
              inputProps={{
                'aria-label': 'Search',
              }}
              onChange={handleSearchEvent}
              value={searchValue}
            />
            {searchValue ? (
              <Icon
                color="action"
                className="cursor-pointer"
                onClick={() => {
                  clearSearch();
                }}
              >
                close
              </Icon>
            ) : (
              <Icon color="action">search</Icon>
            )}
          </Paper>
        </div>
        <div className="items-center justify-end mt-10 md:mt-0">
          <Select
            className="rounded-full border border-darkgreen h-40 bg-white text-16 w-full min-w-192 leading-5 shadow-none"
            variant="filled"
            sx={{ '& .MuiFilledInput-input': { pt: '8px' } }}
            value={status}
            fullWidth
            onChange={handleStatusDropdownChange}
            IconComponent={() => (
              <Icon className="text-20 align-middle absolute right-14 text-black ml-2">
                filter_list
              </Icon>
            )}
          >
            <MenuItem value="all" className="capitalize">
              {t('menuItems.all')}
            </MenuItem>
            <MenuItem value="true" className="capitalize">
              {t('menuItems.bookmark')}
            </MenuItem>
          </Select>
        </div>
      </div>
      <div className="bg-white rounded-md relative shadow">
        {searchLoading ? (
          <div color="textSecondary" className="text-16 py-12 text-center font-semibold">
            {t('business.visitedBusiness.labels.loading')}
          </div>
        ) : (
          <>
            {visitedBusinessList?.length > 0 && (
              <>
                <EnhancedTableToolbar numSelected={selected.length} />
                <TableContainer>
                  <Table className="w-full" aria-labelledby="tableTitle" size="medium">
                    <EnhancedTableHead
                      numSelected={selected.length}
                      onSelectAllClick={handleSelectAllClick}
                      rowCount={visitedBusinessList.length}
                    />
                    <TableBody className="block md:table-row-group">
                      {visitedBusinessListData &&
                        visitedBusinessListData.length > 0 &&
                        visitedBusinessListData.map((row, index) => {
                          const isItemSelected = isSelected(row.id);
                          const labelId = `enhanced-table-checkbox-${index}`;

                          return (
                            <TableRow
                              hover
                              className="border border-t-2 border-t-grey-100 block md:table-row"
                              role="checkbox"
                              aria-checked={isItemSelected}
                              tabIndex={-1}
                              key={row.id}
                              selected={isItemSelected}
                            >
                              {/* <TableCell
                        padding="checkbox"
                        onClick={(event) => handleClick(event, row.id)}
                        sx={{ cursor: 'pointer' }}
                      >
                        <Checkbox
                          color="primary"
                          checked={isItemSelected}
                          inputProps={{
                            'aria-labelledby': labelId,
                          }}
                        />
                      </TableCell> */}
                              <TableCell
                                className="text-14 color-black font-semibold block md:table-cell py-5 pt-16 md:py-16"
                                align="left"
                              >
                                {(page - 1) * rowsPerPage + index + 1}
                              </TableCell>
                              <TableCell
                                className="text-14 color-black font-semibold md:max-w-136 block md:table-cell py-5 md:py-16"
                                align="left"
                              >
                                {row?.name}
                              </TableCell>
                              <TableCell
                                className={`text-14 color-black font-semibold md:max-w-136 block md:table-cell py-5 md:py-16 ${
                                  !row?.users?.userName ? 'py-0' : 'py-5 md:py-16'
                                }`}
                                align="left"
                              >
                                <span className="inline-block align-middle md:hidden mr-10 font-bold text-darkgreen">
                                  {t('common.userName')}:
                                </span>
                                {row?.users?.userName}
                              </TableCell>
                              <TableCell
                                className="text-14 color-black font-semibold md:max-w-136 block md:table-cell py-5 md:py-16"
                                align="left"
                              >
                                <span className="inline-block align-middle md:hidden mr-10 font-bold text-darkgreen">
                                  {t('business.visitedBusiness.column.visitedDate')}:
                                </span>
                                {formatDateWithTimeZone(row?.cts, 'MMM DD, YYYY')}
                              </TableCell>
                              <TableCell
                                className={`text-14 color-black font-semibold md:max-w-136 block md:table-cell ${
                                  !row?.demo_businesses_feedback?.phoneNumber
                                    ? 'py-0'
                                    : 'py-5 md:py-16'
                                }`}
                                align="left"
                              >
                                {row?.demo_businesses_feedback?.countryCode}{' '}
                                {row?.demo_businesses_feedback?.phoneNumber}
                              </TableCell>
                              <TableCell
                                className="text-14 color-black font-semibold block md:table-cell"
                                align="left"
                              >
                                <span className="inline-block align-middle md:hidden mr-10 font-bold text-darkgreen">
                                  {t('common.status')}:
                                </span>
                                <span className="inline-block align-middle">
                                  {row?.demo_businesses_feedback?.sales_person_visited_status?.type}
                                  <span className="block text-grey">
                                    {row?.demo_businesses_follow_up_feedback?.length > 0 &&
                                      formatDateWithTimeZone(
                                        row?.demo_businesses_follow_up_feedback?.[0]?.followUpDate,
                                        'MMM DD, YYYY'
                                      )}
                                  </span>
                                </span>
                              </TableCell>
                              <TableCell
                                className="text-14 color-black font-semibold md:max-w-136 block md:table-cell py-5 md:py-16"
                                align="center"
                              >
                                <div className="flex gap-4 items-center">
                                  <span className="inline-block align-middle md:hidden mr-10 font-bold text-darkgreen">
                                    {t('business.searchBusiness.column.validity')}:
                                  </span>
                                  <Typography className="text-14 font-semibold mr-20">
                                    {row?.validity} {t('common.days')}
                                  </Typography>
                                  <Button
                                    className="bg-[#006B5A45] hover:bg-[#006B5A45] px-20 rounded text-darkgreen text-14 font-medium"
                                    onClick={() => handleOpenDialogue(row.users?.id)}
                                    size="small"
                                  >
                                    {' '}
                                    {t('business.searchBusiness.labels.extend')}{' '}
                                  </Button>
                                </div>
                              </TableCell>
                              <TableCell
                                className="text-14 color-black font-semibold md:w-160 px-16 block md:table-cell py-5 pb-16 md:py-16"
                                align="left"
                              >
                                <Button
                                  variant="contained"
                                  color="secondary"
                                  className="xl:text-14 px-16 bg-green-100 text-green font-semibold rounded hover:text-white disabled:bg-gry-400"
                                  aria-label="view"
                                  onClick={() => redirectBusinessDetailsRoutes(row.id)}
                                  type="button"
                                  size="small"
                                >
                                  {t('common.viewDetails')}
                                </Button>
                              </TableCell>
                            </TableRow>
                          );
                        })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </>
            )}
            {visitedBusinessList && visitedBusinessList.length === 0 && searchValue !== '' && (
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1, transition: { delay: 0.1 } }}
                className="flex flex-1 items-center justify-center h-full"
              >
                <Typography
                  color="textSecondary"
                  variant="h5"
                  className="text-16 py-12 font-semibold"
                >
                  {t('business.visitedBusiness.noBusiness')}
                </Typography>
              </motion.div>
            )}
          </>
        )}
      </div>
      {!searchLoading &&
        visitedBusinessList &&
        visitedBusinessList.length === 0 &&
        searchValue === '' && (
          <>
            {status === 'all' ? (
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1, transition: { delay: 0.1 } }}
                className="flex flex-1 items-center justify-center h-full text-center mx-auto h-fit"
              >
                <div>
                  <img
                    src="/assets/images/sales-icon/visited_business.png"
                    className="img-fluid mx-auto max-w-216 pb-40 lg:pt-96"
                    alt="..."
                  />
                  <h5 className="block text-24 font-bold text-black">
                    {t('business.visitedBusiness.labels.doNotHaveVisitedBusiness')}
                  </h5>
                  <Typography
                    color="textPrimary"
                    variant="h5"
                    className="text-16 py-12 font-semibold max-w-640"
                  >
                    {t('business.visitedBusiness.labels.notVisitedBusiness')}
                  </Typography>
                </div>
              </motion.div>
            ) : status === 'true' ? (
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1, transition: { delay: 0.1 } }}
                className="flex flex-1 items-center justify-center h-full text-center mx-auto h-fit"
              >
                <div>
                  <img
                    src="/assets/images/sales-icon/bookmark.png"
                    className="img-fluid mx-auto max-w-216 pb-40 lg:pt-96"
                    alt="..."
                  />
                  <h5 className="block text-24 font-bold text-black">
                    {t('business.selectedBusiness.labels.notHaveBookmarked')}
                  </h5>
                  <Typography
                    color="textPrimary"
                    variant="h5"
                    className="text-16 py-12 font-semibold max-w-640"
                  >
                    {t('business.selectedBusiness.labels.notHaveBookmarkedDesc')}
                  </Typography>
                </div>
              </motion.div>
            ) : (
              ''
            )}
          </>
        )}

      {visitedBusinessList?.length > 0 && (
        <TablePagination
          sx={{
            '.MuiInputBase-root': {
              lineHeight: 'inherit',
              padding: 0,
            },
          }}
          component="div"
          count={totalUser}
          rowsPerPage={rowsPerPage}
          page={page - 1}
          backIconButtonProps={{
            'aria-label': 'Previous Page',
          }}
          nextIconButtonProps={{
            'aria-label': 'Next Page',
          }}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      )}
      <Dialog
        classes={{
          root: classes.root,
          paper:
            'm-24 p-28 business-type-dropdown border-t-8 border-solid border-t-darkgreen border-darkgreen rounded-md',
        }}
        open={isDialogOpen}
        onClose={() => {
          handleClearExtendDays();
        }}
        fullWidth
        maxWidth="xs"
      >
        <DialogTitle className="p-0 mb-20">
          <Icon
            onClick={() => {
              handleClearExtendDays();
            }}
            className="text-24 absolute top-12 right-12 text-grey-400 font-normal cursor-pointer hover:text-gray-700 focus:text-gray-700"
          >
            {t('appPromotionSlider.cancel')}
          </Icon>
          <Typography className="text-20 font-semibold text-black border-b-2 border-dashed border-grey-500 w-full pb-10">
            {t('business.searchBusiness.labels.extendValidity')}
          </Typography>
        </DialogTitle>
        <DialogContent className={`${classes.attDialog} p-0 mb-8 business-type-dropdown-content`}>
          <div>
            <div className="w-full">
              <Typography className="text-16 font-medium text-grey-900">
                {t('business.searchBusiness.labels.selectDays')}
              </Typography>
            </div>
            <div className="w-full pt-6">
              <Select
                className="h-[20px]"
                displayEmpty
                value={selectExtendDays || 0}
                id="packageType"
                onChange={handleSelectExtendDays}
                required
                fullWidth
              >
                <MenuItem value={0} disabled>
                  {t('business.searchBusiness.labels.selectDays')}
                </MenuItem>
                {dayArray?.length > 0 &&
                  dayArray.map((item, i) => {
                    return (
                      <MenuItem value={item.id}>
                        {item.day} {i === 0 ? t('common.day') : t('common.days')}
                      </MenuItem>
                    );
                  })}
              </Select>
            </div>
          </div>
        </DialogContent>
        <DialogActions className="p-0 m-auto mt-40">
          <Button
            className="md:text-18 px-40 font-semibold bg-white hover:bg-white text-darkgreen border border-solid border-darkgreen rounded-md"
            variant="contained"
            color="primary"
            type="button"
            onClick={() => handleClearExtendDays()}
          >
            {t('common.cancel')}
          </Button>
          <Button
            className="md:text-18 px-36 font-semibold disabled:bg-gray disabled:text-white hover:bg-darkgreen disabled:text-black rounded-md"
            variant="contained"
            color="secondary"
            type="button"
            disabled={!selectExtendDays}
            onClick={() => handleSubmitExtendDays()}
          >
            {t('business.searchBusiness.labels.extendValidity')}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default withReducer('visitedBusinessReducer', reducer)(VisitedBusiness);
