import { useEffect, useState, useRef, useCallback } from 'react';
import Slider from 'react-slick';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import ReactApexChart from 'react-apexcharts';
import { getAllUserBusinessSubscription, selectDashboardData } from 'src/app/store/userSlice';
import FuseLoading from '@fuse/core/FuseLoading';
import { useParams } from 'react-router-dom';
import CommonPendingTask from 'src/app/main/CommonComponent/CommonPendingTask';
import { useSnackbar } from 'notistack';
import history from '@history';

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
} from '@mui/material';
import quickAdsQuery from 'src/app/main/query/quickAds';
import CommonFreeTrial from 'src/app/main/CommonComponent/CommonFreeTrial';
import DashBoardPopUp from 'src/app/main/CommonComponent/DashBoardPopUp';
import manageBusinessData from 'src/app/main/query/manageBusiness';
import FacebookPagesPopUp from 'src/app/main/CommonComponent/FacebookPagesPopUp';
import {
  formatDateWithTimeZone,
  handleApiRequest,
  FBConnectAds,
  getDecryptData,
  getEncryptedData,
  transformUserSubscriptionData,
  getFilteredData,
  addExpiryToProductArray,
  defaultProductArr,
  findExpireDay,
} from '../../../common/common';
import InstagramConnectPopUp from '../InstagramConnectPopUp';
import NoBusinessPopUp from '../NoBusinessPopUp';
import GenderMetrics from './GenderMetrics';
import NoData from '../../matrices/noDataScreen';

function Dashboards() {
  const { t } = useTranslation();
  const params = useParams();
  const userCurrentBusinessDetails = useSelector(selectDashboardData);
  const userSubscriptionData = useSelector(getAllUserBusinessSubscription);
  const appKey = process.env.REACT_APP_WABA_APP_ID;
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [cardsData, setCardsData] = useState([]);
  const [pageData, setPageData] = useState({});
  const [pages, setPages] = useState(false);
  const [instagramConnected, setInstagramConnected] = useState(false);
  const [openPopUp, setOpenPopUp] = useState(false);
  const [productData, setProductData] = useState([]);
  const [overAllMetrics, setOverAllMetrics] = useState([]);
  const [recentCampaign, setRecentCampaign] = useState([]);
  const [noPage, setNoPage] = useState(false);
  const filteredData = userSubscriptionData?.filter(
    (subscription) => subscription?.userBusinessId === userCurrentBusinessDetails?.id
  );
  const shouldShowPopup = productData?.some(
    (item) => findExpireDay(item?.expireDate) <= 0 && item?.name === 'Quick Ads'
  );
  const [popupData, setPopupData] = useState({
    isOpen: false,
    image: '',
    position: {},
    id: null,
  });

  const facebookCard = cardsData?.find((social) => social?.name === 'Facebook');

  useEffect(() => {
    const transformedData = transformUserSubscriptionData(
      userSubscriptionData,
      userCurrentBusinessDetails
    );
    if (!transformedData) return;

    const filterData = getFilteredData(transformedData);
    const updatedProducts = addExpiryToProductArray(filterData, defaultProductArr);

    setProductData((prev) => {
      if (JSON.stringify(prev) !== JSON.stringify(updatedProducts)) {
        return updatedProducts;
      }
      return prev;
    });
  }, [userSubscriptionData, userCurrentBusinessDetails?.id, userCurrentBusinessDetails]);

  const fetchCardDetails = useCallback(async () => {
    try {
      const payload = {
        query: quickAdsQuery.getAdsConnectApis,
        variables: {
          businessId: Number(userCurrentBusinessDetails?.id),
        },
      };
      const result = await handleApiRequest(payload);
      if (result?.getAdsConnectApis?.status === 200) {
        const cards = result?.getAdsConnectApis?.data || [];
        setCardsData(cards);
      }
    } catch (error) {
      console.error('error', error);
    }
  }, [userCurrentBusinessDetails?.id]);

  useEffect(() => {
    if (userCurrentBusinessDetails?.id) fetchCardDetails();
  }, [fetchCardDetails, userCurrentBusinessDetails?.id]);

  useEffect(() => {
    const fetchAdsCampaign = async () => {
      try {
        setLoading(true);
        const payload = {
          query: quickAdsQuery.getAdsCampaign,
          variables: {
            quickHubAdsAccountId: Number(userCurrentBusinessDetails?.quickHubAdsAccountId),
            pageNumber: 1,
            pageSize: 10,
            campaignStatusFilter: '',
          },
        };
        const result = await handleApiRequest(payload);
        if (result?.getAdsCampaign?.status === 200) {
          setRecentCampaign(result?.getAdsCampaign?.data?.ads);
        }
      } catch (err) {
        console.error('Error fetching data:', err);
      } finally {
        setLoading(false);
      }
    };

    fetchAdsCampaign();
  }, [userCurrentBusinessDetails?.quickHubAdsAccountId]);

  const saveMetaAdsDetails = useCallback(
    async (response, page) => {
      const data = getEncryptedData(
        JSON.stringify({
          pageDetails: {
            name: page?.name,
            id: page?.id,
            access_token: page?.access_token,
            category: page?.category,
            profilePictureUrl: page?.profilePictureUrl,
            isVerified: page?.isVerified,
          },
          accountId: response?.accountId,
          adsAccountId: response?.adsAccountId,
          accountDetails: {
            name: response?.accountDetails?.name,
            account_id: response?.accountDetails?.account_id,
            currency: response?.accountDetails?.currency,
            account_status: response?.accountDetails?.account_status,
            business_name: response?.accountDetails?.business_name,
            id: response?.accountDetails?.id,
          },
          tokenDetails: {
            access_token: response?.tokenDetails?.access_token,
            token_type: response?.tokenDetails?.token_type,
          },
          isFacebookConnect: true,
          isInstagramConnect: instagramConnected,
          userBusinessId: Number(userCurrentBusinessDetails?.id),
        })
      );

      try {
        const payload = {
          query: quickAdsQuery.saveMetaAdsDetails,
          variables: { data: String(data) },
        };
        const result = await handleApiRequest(payload);
        if (result?.saveMetaAdsDetails?.status === 200) {
          fetchCardDetails();
          enqueueSnackbar(result?.saveMetaAdsDetails?.message, {
            variant: 'success',
            autoHideDuration: 2000,
          });
        } else {
          enqueueSnackbar(result?.saveMetaAdsDetails?.message, {
            variant: 'error',
            autoHideDuration: 2000,
          });
        }
      } catch (err) {
        console.error('Error fetching data:', err);
      }
    },
    [enqueueSnackbar, fetchCardDetails, instagramConnected, userCurrentBusinessDetails?.id]
  );

  const checkInstagramConnectivity = useCallback(
    async (response, pageData1) => {
      if (!response) {
        console.error('Response is undefined or null.');
        return;
      }
      try {
        const payload = {
          query: quickAdsQuery.checkInstagramConnectivity,
          variables: {
            userBusinessId: Number(userCurrentBusinessDetails?.id),
            accessToken: response?.access_token,
            pageId: response?.id,
          },
        };
        const result = await handleApiRequest(payload);
        if (result?.checkInstagramConnectivity?.status === 200) {
          setInstagramConnected(result?.checkInstagramConnectivity?.isConnected);
          saveMetaAdsDetails(pageData1, response, result?.checkInstagramConnectivity?.isConnected);
          result?.checkInstagramConnectivity?.isConnected
            ? setOpenPopUp(false)
            : setOpenPopUp(true);
        } else {
          console.error(
            result?.checkInstagramConnectivity?.errorMessage,
            result?.checkInstagramConnectivity?.errorCode
          );
        }
      } catch (err) {
        console.error('Error fetching data:', err);
      }
    },
    [saveMetaAdsDetails, userCurrentBusinessDetails?.id]
  );

  const verifyMetaAdsCode = useCallback(
    async (response) => {
      setIsLoading(true);
      try {
        const payload = {
          query: quickAdsQuery.verifyMetaAdsCode,
          variables: {
            accessToken: response?.accessToken,
          },
        };

        const result = await handleApiRequest(payload);
        if (result?.verifyMetaAdsCode?.status === 200) {
          setPageData(JSON.parse(getDecryptData(result?.verifyMetaAdsCode?.data)));
          setPages(true);
        } else {
          enqueueSnackbar(
            result?.verifyMetaAdsCode?.errorMessage || result?.verifyMetaAdsCode?.message,
            {
              variant: 'error',
              autoHideDuration: 2000,
            }
          );
          if (
            result?.verifyMetaAdsCode?.errorMessage ||
            result?.verifyMetaAdsCode?.message === 'Page Not Found'
          ) {
            setNoPage(true);
          }
          console.error(
            result?.verifyMetaAdsCode?.errorMessage,
            result?.verifyMetaAdsCode?.errorCode
          );
        }
      } catch (err) {
        console.error('Error fetching data:', err);
      }
      setIsLoading(false);
    },
    [enqueueSnackbar]
  );

  useEffect(() => {
    const getAdsOverallMetrics = async () => {
      try {
        setLoading(true);
        const payload = {
          query: quickAdsQuery.getAdsOverallMetrics,
          variables: {
            userBusinessId: Number(userCurrentBusinessDetails?.id),
          },
        };
        const result = await handleApiRequest(payload);
        if (result?.getAdsOverallMetrics?.status === 200) {
          setOverAllMetrics(result?.getAdsOverallMetrics?.data);
        }
      } catch (err) {
        console.error('Error fetching data:', err);
      } finally {
        setLoading(false);
      }
    };
    if (userCurrentBusinessDetails?.id) {
      getAdsOverallMetrics();
    }
  }, [userCurrentBusinessDetails?.id]);

  const handleLogin = (socialName) => {
    if (socialName === 'Facebook') {
      FBConnectAds(socialName, verifyMetaAdsCode, appKey);
    }
  };

  const settings = {
    dots: true,
    infinite: cardsData?.length > 1,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,

    responsive: [
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
    ],
  };

  if (loading) {
    return <FuseLoading />;
  }

  const channels = [
    {
      name: 'Facebook',
      clicks: 21000,
      icon: 'https://cdn-icons-png.flaticon.com/512/733/733547.png',
    },
    {
      name: 'Instagram',
      clicks: 999,
      icon: 'https://cdn-icons-png.flaticon.com/512/733/733558.png',
    },
  ];

  function getChart(data, names, color) {
    const chart = {
      series: [
        {
          name: 'Campaign Data',
          data,
        },
      ],
      options: {
        chart: {
          type: 'bar',
          height: 350,
          toolbar: {
            show: false,
          },
        },
        plotOptions: {
          bar: {
            horizontal: true,
            barHeight: '45px',
            borderRadius: 15,
            borderRadiusApplication: 'end',
          },
        },
        colors: [color],
        dataLabels: {
          enabled: true,
          position: 'end',
          offsetX: 0,
          style: {
            fontSize: '16px',
            fontWeight: 500,
            colors: ['#222'],
          },
          formatter(val) {
            return val;
          },
        },
        xaxis: {
          categories: names,
          labels: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
        },
        yaxis: {
          labels: {
            show: true,
            align: 'left',
            offsetX: -20,
            style: {
              fontSize: '16px',
              fontWeight: 500,
              colors: ['#2A75EE'],
            },
          },
        },
        grid: {
          show: false,
        },
        tooltip: {
          enabled: false,
        },
      },
    };

    return (
      <div id="chart" style={{ width: '100%' }}>
        <ReactApexChart options={chart.options} series={chart.series} type="bar" height={300} />
      </div>
    );
  }

  const maxClicks = Math.max(...channels.map((channel) => channel?.clicks));

  function formatNumber(count) {
    if (count >= 1000000) {
      return `${(count / 1000000).toFixed(1).replace(/\.0$/, '')}M`;
    }
    if (count >= 1000) {
      return `${(count / 1000).toFixed(1).replace(/\.0$/, '')}k`;
    }
    return count;
  }

  const viewPost = (event, image, rowIndex, id) => {
    setPopupData({
      isOpen: true,
      image,
      position: rowIndex < 3 ? 'below' : 'above',
      id,
    });
  };

  const closePopup = () => {
    setPopupData({ isOpen: false, image: null, position: {}, id: null });
  };

  return (
    <div className="p-20 lg:p-24 h-full l-padding">
      {isLoading ? (
        <div>
          <FuseLoading />
        </div>
      ) : (
        <div className="flex flex-col gap-32">
          <CommonFreeTrial callFrom="quickAds" productName="Quick Ads" packageBundle={11} />
          <div className="grid xl:grid-cols-3 lg:grid-cols-3 md:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-x-0 sm:gap-x-32 gap-y-28">
            <div className="col-span-2">
              <div className="flex flex-col mb-24 items-center gap-32">
                <CommonPendingTask
                  productType="quick_ads"
                  bgColor="quick-social"
                  buttonColor="quickSocial"
                  videoImg="/assets/images/icon/playVideoIcon-review.svg"
                />
              </div>
              <div className="grid xl:grid-cols-4 lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-2 grid-cols-1 md:gap-20 gap-10 mb-24">
                {!(
                  cardsData &&
                  cardsData?.length > 0 &&
                  !cardsData?.some((item) => item?.isConnect)
                ) &&
                  overAllMetrics?.brandHealthData?.metrics &&
                  overAllMetrics?.brandHealthData?.metrics?.length > 0 &&
                  overAllMetrics?.brandHealthData?.metrics?.map((metricsValue, i) => (
                    <div
                      className="bg-white rounded-md p-24 border-1 border-solid border-grey-A500 shadow"
                      key={i}
                    >
                      <div className="flex items-center">
                        <img
                          src={metricsValue?.icon}
                          width={56}
                          height={56}
                          className="h-auto max-w-full mr-10"
                          alt="..."
                        />
                        <div>
                          <span className="block font-semibold text-24 mb-6">
                            {metricsValue?.value}
                          </span>
                          <span className="block font-semibold text-14">
                            {metricsValue?.viewName}
                          </span>
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
              <div className="grid xl:grid-cols-3 lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-2 grid-cols-1 md:gap-20 gap-10 mb-32">
                <div className="bg-white rounded-md p-12 border-1 border-solid border-grey-A500 shadow">
                  <h3 className="text-18 font-semibold mb-16 mt-0">{t(`quickAds.gender`)}</h3>
                  {!(
                    cardsData &&
                    cardsData?.length > 0 &&
                    !cardsData?.some((item) => item?.isConnect)
                  ) && overAllMetrics?.brandHealthData?.gender ? (
                    <GenderMetrics data={overAllMetrics?.brandHealthData?.gender} />
                  ) : (
                    <NoData />
                  )}
                </div>
                <div className="bg-white rounded-md p-12 border-1 border-solid border-grey-A500 shadow">
                  <h3 className="text-18 font-semibold mb-16 mt-0">{t(`quickAds.topChannel`)}</h3>
                  {!(
                    cardsData &&
                    cardsData?.length > 0 &&
                    !cardsData?.some((item) => item?.isConnect)
                  ) &&
                  overAllMetrics?.brandHealthData?.topPlacement &&
                  overAllMetrics?.brandHealthData?.topPlacement?.length > 0 ? (
                    overAllMetrics?.brandHealthData?.topPlacement
                      ?.filter(
                        (channel) =>
                          channel?.key === 'Instagram Video' || channel?.key === 'Facebook Video'
                      )
                      ?.map((channel, index) => (
                        <div key={index} className="flex items-center mt-32">
                          <div
                            className="flex-grow h-32 rounded-md bg-quick-social"
                            style={{
                              width: `${(channel.values / maxClicks) * 100}%`,
                              maxWidth: 'calc(100% - 80px)',
                            }}
                          />
                          <div className="flex items-center ml-4">
                            <div className="w-44 h-44 border-2 -ml-20 bg-white border-white rounded-full">
                              <div className="w-40 h-40 rounded-full flex items-center justify-center border-2 border-quick-social">
                                <img
                                  src={
                                    channel?.key === 'Facebook Video'
                                      ? 'assets/images/quick-hub/quick-ads/facebook.png'
                                      : 'assets/images/quick-hub/quick-ads/instagram.png'
                                  }
                                  alt={channel?.name}
                                  className="w-20 h-20"
                                />
                              </div>
                            </div>
                            <span className="ml-2 font-semibold">
                              {formatNumber(Number(channel?.value))}
                            </span>
                          </div>
                        </div>
                      ))
                  ) : (
                    <NoData />
                  )}
                </div>
              </div>
              {!(cardsData?.length > 0 && !cardsData.some((item) => item?.isConnect)) &&
                recentCampaign &&
                recentCampaign?.length > 0 && (
                  <div>
                    <div className="flex items-center justify-between mb-12 ">
                      <h3 className="text-20 font-bold mt-0">
                        {t('quickCampaigns.recentCampaign')}
                      </h3>
                      <span
                        aria-hidden
                        className="text-20 font-bold capitalize text-quick-social cursor-pointer"
                        onClick={(e) => {
                          e.preventDefault();
                          history.push('/quick-ads/ads-campaign');
                        }}
                      >
                        {t('businessDashboard.seeAll')}
                      </span>
                    </div>
                    <TableContainer
                      component={Paper}
                      className="rounded-md border-1 border-solid border-grey-A500 shadow"
                    >
                      <Table>
                        <TableHead className="border-b-2 border-grey-400">
                          <TableRow>
                            <TableCell className="font-bold text-18">
                              {t('quickCampaign.campaign.srNo')}
                            </TableCell>
                            <TableCell className="font-bold text-18">
                              {t('quickAds.dateCreated')}
                            </TableCell>
                            <TableCell className="font-bold  text-18">
                              {t('quickSocialPost.post')}
                            </TableCell>
                            <TableCell className="font-bold text-18">
                              {t('quickCampaign.campaign.campaignName')}
                            </TableCell>
                            <TableCell className="font-bold  text-18">
                              {t('listManagement.startDate')}
                            </TableCell>
                            <TableCell className="font-bold  text-18">
                              {t('listManagement.endDate')}
                            </TableCell>
                            <TableCell className="font-bold  text-18">
                              {t('common.status')}
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {recentCampaign?.length > 0 &&
                            recentCampaign &&
                            recentCampaign?.map((data, index) => (
                              <TableRow
                                key={index}
                                className="cursor-pointer hover:bg-quick-social-100 hover:bg-opacity-30 border-b-2"
                                onClick={() =>
                                  history.push(`/ads-campaign-details/${data?.id}`, {
                                    ADSdata: data,
                                  })
                                }
                              >
                                <TableCell className="font-medium text-16 p-12">
                                  {index + 1}
                                </TableCell>
                                <TableCell className="font-medium text-16 p-12">
                                  {formatDateWithTimeZone(data?.cts, 'MMM DD, YYYY')}
                                </TableCell>
                                <TableCell className="font-medium text-16 p-12">
                                  <TableCell
                                    className="font-medium text-12 md:text-16 p-12 relative"
                                    onMouseEnter={(e) =>
                                      viewPost(e, data?.mediaUrl, index, data?.id)
                                    }
                                    onMouseLeave={closePopup}
                                    aria-hidden
                                    alt="Post"
                                    width={24}
                                    height={24}
                                  />
                                  {popupData?.isOpen &&
                                    popupData?.image === data?.mediaUrl &&
                                    popupData?.id === data?.id && (
                                      <div
                                        className="absolute bg-white border shadow-lg p-2 rounded z-10"
                                        style={{
                                          top: popupData?.position === 'below' ? '30px' : '-150px',
                                          left: '30%',
                                          transform: 'translateX(-50%)',
                                        }}
                                      >
                                        {popupData?.image ? (
                                          <img
                                            src={popupData?.image}
                                            alt="Preview"
                                            className="min-w-160 h-144 object-contain"
                                          />
                                        ) : (
                                          <div className="border-1 border-gray-A500 shadow-2 shadow-A500 min-w-160 h-144 flex justify-center items-center bg-quick-social-100">
                                            <p className="font-bold text-40 text-quick-social">
                                              {data?.name?.charAt(0).toUpperCase()}
                                            </p>
                                          </div>
                                        )}
                                      </div>
                                    )}
                                </TableCell>

                                <TableCell className="font-medium text-16 p-12">
                                  {formatDateWithTimeZone(data?.scheduleDate, 'MMM DD, YYYY')}
                                </TableCell>
                                <TableCell className="font-medium text-16 p-12">
                                  {formatDateWithTimeZone(data?.endDate, 'MMM DD, YYYY')}
                                </TableCell>
                                <TableCell className="items-center justify-center p-12 border-b-0">
                                  <span
                                    className={`${
                                      data?.status === 'SCHEDULED'
                                        ? 'bg-quick-social-100 text-quick-social border-1 border-quick-social'
                                        : data?.status === 'ACTIVE'
                                        ? 'bg-green-100 text-quick-review border-1 border-quick-review'
                                        : data?.status === 'COMPLETED'
                                        ? 'bg-[#f1cdea] text-[#C837AB] border-1 border-[#C837AB]'
                                        : data?.status === 'PAUSED'
                                        ? 'bg-quick-campaign-50 text-quick-campaign border-1 border-quick-campaign'
                                        : 'bg-yellow-100 text-quick-chat border-1 border-quick-chat'
                                    } rounded-full text-14 font-medium text-center px-10 py-5`}
                                  >
                                    {data?.status}
                                  </span>
                                </TableCell>
                              </TableRow>
                            ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </div>
                )}
            </div>
            <div className="flex flex-col gap-32 w-full items-center">
              {cardsData && cardsData?.length > 0 && !cardsData.some((item) => item?.isConnect) && (
                <div className="rounded-md relative shadow w-full">
                  {facebookCard && (
                    <div className="pr-0 md:pr-0">
                      <div
                        className="flex gap-16 items-center w-full h-160 justify-between p-12 md:p-24 rounded-md bg-blue"
                        style={{
                          backgroundImage: "url('/assets/images/background/FBBackground.png')",
                          backgroundSize: 'cover',
                          backgroundPosition: 'center',
                        }}
                      >
                        <div>
                          <h1
                            className="mb-16 text-20 md:text-24 font-semibold text-white"
                            style={{ color: facebookCard?.textColor || 'white' }}
                          >
                            {facebookCard?.title}
                          </h1>
                          <Button
                            className="text-12 md:text-16 text-center rounded-md bg-white text-black min-h-28 h-28 min-w-136 hover:text-white"
                            variant="contained"
                            color="quickSocial"
                            size="small"
                            onClick={() => handleLogin(facebookCard?.name)}
                          >
                            {facebookCard?.buttonName}
                          </Button>
                        </div>
                        <div className="w-1/3 flex items-center justify-center">
                          <img
                            className="hidden w-112 h-96 sm:block"
                            src={facebookCard?.bgImage}
                            alt="Card"
                          />
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              )}

              <div className="bg-white rounded-md relative border-1 border-solid border-grey-A500 shadow md:p-20 p-10 w-full">
                <h3 className="text-20 font-bold">{t(`quickAds.topCampaign`)}</h3>
                {!(
                  cardsData &&
                  cardsData?.length > 0 &&
                  !cardsData.some((item) => item?.isConnect)
                ) &&
                overAllMetrics?.brandHealthData?.topCampaign &&
                overAllMetrics?.brandHealthData?.topCampaign?.length > 0 ? (
                  <>
                    {getChart(
                      overAllMetrics?.brandHealthData?.topCampaign?.map((item) => item?.clicks),
                      overAllMetrics?.brandHealthData?.topCampaign?.map((item) => item?.name),
                      '#219EBC'
                    )}
                  </>
                ) : (
                  <NoData />
                )}
              </div>
            </div>
          </div>
        </div>
      )}
      {shouldShowPopup && (
        <DashBoardPopUp
          open={shouldShowPopup}
          filteredData={filteredData}
          productData={productData}
          from="Quick Ads"
          productId={11}
        />
      )}
      {pageData?.pageDetails && pages && (
        <FacebookPagesPopUp
          pageData={pageData}
          open={pages}
          onClose={() => setPages(false)}
          checkInstagramConnectivity={checkInstagramConnectivity}
          saveMetaAdsDetails={saveMetaAdsDetails}
          setPages={setPages}
        />
      )}
      {noPage && (
        <NoBusinessPopUp
          open={noPage}
          onClose={() => setNoPage(false)}
          setNoPage={setNoPage}
          from="quickADS"
        />
      )}
      {openPopUp && (
        <InstagramConnectPopUp pageData={pageData} open={openPopUp} setOpenPopUp={setOpenPopUp} />
      )}
    </div>
  );
}
export default Dashboards;
