import React, { useState } from 'react';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Button, CircularProgress, Typography } from '@mui/material';
import { t } from 'i18next';
import { useSnackbar } from 'notistack';
import history from '@history';
import quickHub from '../../../../../query/quickHub';
import { handleApiRequest } from '../../../../../common/common';
import LabelOrBusinessDrawer from '../../reports/LabelOrBusinessDrawer';
import ErrorPopUp from '../../common/ErrorPopUp';

const AddAttributes = (props) => {
  const {
    googleAttribute,
    selectLabelOrBusiness,
    setSelectLabelOrBusiness,
    setAddAttribute,
    location,
    defaultAttribute,
    openBusinessDrawer,
    setOpenBusinessDrawer,
    fetchDefaultGoogleAttribute,
  } = props;
  const [refreshIcon, setRefreshIcon] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [selectedAttribute, setSelectedAttribute] = useState([]);
  const [openErrorDialog, setOpenErrorDialog] = useState(false);
  const [failedRequests, setFailedRequests] = useState([]);

  const onSubmit = async () => {
    setRefreshIcon(true);
    try {
      const payload = {
        query: quickHub.updateGoogleBusinessDetailsForBusinesses,
        variables: {
          businessIds: selectLabelOrBusiness?.map((business) => business?.id),
          mask: 'attributes',
          data: JSON.stringify({
            data: selectedAttribute?.map((attribute) => ({
              name: attribute.name,
              valueType: attribute?.valueType,
              ...(attribute.valueType === 'BOOL'
                ? {
                    boolValues: [Boolean(attribute?.valueMetadata?.[0]?.value)],
                  }
                : {
                    enumValues: [attribute?.valueMetadata?.[0]?.value],
                  }),
            })),
          }),
        },
      };
      const result = await handleApiRequest(payload);
      setRefreshIcon(false);
      if (result?.updateGoogleBusinessDetailsForBusinesses?.data?.failedRequests?.length > 0) {
        setOpenErrorDialog(true);
        setFailedRequests(result?.updateGoogleBusinessDetailsForBusinesses?.data?.failedRequests);
      }
      if (result?.updateGoogleBusinessDetailsForBusinesses?.status === 200) {
        if (location?.state?.isBulk) {
          history.push('quick-hub/primaryDetails', location?.state?.activeTab);
          enqueueSnackbar(result?.updateGoogleBusinessDetailsForBusinesses?.message, {
            variant: 'success',
            autoHideDuration: 3000,
          });
        } else {
          setAddAttribute(false);
          fetchDefaultGoogleAttribute();
          enqueueSnackbar(result?.updateGoogleBusinessDetailsForBusinesses?.message, {
            variant: 'success',
            autoHideDuration: 3000,
          });
        }
      } else if (result?.updateGoogleBusinessDetailsForBusinesses?.message !== 'Bad request') {
        enqueueSnackbar(result?.updateGoogleBusinessDetailsForBusinesses?.message, {
          variant: 'error',
          autoHideDuration: 3000,
        });
      }
    } catch (error) {
      setRefreshIcon(false);
      console.error('Error updating business hours:', error);
    }
  };

  const handleAddAttribute = (data) => {
    setSelectedAttribute((preAttribute) => {
      const attributeExists = preAttribute?.some((attr) => attr?.name === data?.name);
      if (attributeExists) {
        return attributeExists?.filter((attr) => attr.name !== data.name);
      }
      return [...preAttribute, data];
    });
  };

  const handleRedirect = () => {
    !location?.state?.isBulk
      ? defaultAttribute?.length > 0
        ? setAddAttribute(false)
        : history.push('quick-hub/primaryDetails', location?.state?.activeTab)
      : history.push('quick-hub/primaryDetails', location?.state?.activeTab);
  };

  return (
    <div className="">
      <Typography className="font-semibold text-16 md:text-24 flex items-center gap-4 mb-16">
        {' '}
        <Button className="w-16 h-16 min-w-16 min-h-16" onClick={() => handleRedirect()}>
          <ArrowBackIcon />
        </Button>
        {t('primaryDetails.selectAttributes')}
      </Typography>
      {googleAttribute?.length > 0 ? (
        googleAttribute?.map((attribute, i) => {
          const isLast = i === googleAttribute.length - 1;
          return (
            <div className="bg-white rounded-md md:w-640 mt-16" key={i}>
              <div className="p-16">
                <Typography className="font-semibold text-18">
                  {attribute?.groupDisplayName}
                </Typography>
                <div className="mt-8 flex flex-wrap gap-14">
                  {attribute?.attributes?.length > 0 &&
                    attribute?.attributes?.map((item, index) => {
                      return (
                        <Button
                          className={`text-16 font-semibold ${
                            selectedAttribute?.some((value) => value?.name === item?.name)
                              ? 'bg-quick-hub hover:bg-quick-hub text-white'
                              : 'bg-gray-A500 hover:bg-gray-A500 text-black'
                          }`}
                          variant="contained"
                          color="secondary"
                          key={index}
                          onClick={() => handleAddAttribute(item)}
                        >
                          {item?.valueMetadata?.[0]?.displayName}
                        </Button>
                      );
                    })}
                </div>
              </div>
              {isLast && location?.state?.isBulk && (
                <div className="pb-20">
                  <hr className="my-8 border border-gray" />
                  <div className="flex justify-between items-center px-20 pt-12">
                    <Typography className="font-semibold text-18">
                      {t('primaryDetails.selectBusiness')}
                    </Typography>
                    <div className="flex gap-12 items-center">
                      <Typography className="font-medium text-16">
                        {`${t('primaryDetails.selectedBusiness')} (${
                          selectLabelOrBusiness?.length
                        })`}
                      </Typography>
                      <Button
                        className="rounded-md w-112 hover:bg-white"
                        variant="outlined"
                        color="secondary"
                        onClick={() => setOpenBusinessDrawer(true)}
                      >
                        {t('common.edit')}
                      </Button>
                    </div>
                  </div>
                </div>
              )}
            </div>
          );
        })
      ) : (
        <Typography className="text-center font-semibold text-20 pt-20">
          {t('primaryDetails.noSuggestionAttribute')}
        </Typography>
      )}
      {googleAttribute?.length > 0 && (
        <div className="text-end md:w-640 mt-16">
          <Button
            className="rounded-md w-160 font-semibold text-16"
            variant="contained"
            color="secondary"
            onClick={() => onSubmit()}
            disabled={refreshIcon}
          >
            {t('common.save')}
            {refreshIcon && (
              <CircularProgress className="text-quick-review absolute mx-auto" size={24} />
            )}
          </Button>
        </div>
      )}
      <LabelOrBusinessDrawer
        openBusinessDrawer={openBusinessDrawer}
        setOpenBusinessDrawer={setOpenBusinessDrawer}
        setSelectLabelOrBusiness={setSelectLabelOrBusiness}
        selectLabelOrBusiness={selectLabelOrBusiness}
        groupType="business"
        isGoogleConnected
        activeTab={location.state?.activeTab}
      />
      <ErrorPopUp
        openErrorDialog={openErrorDialog}
        setOpenErrorDialog={setOpenErrorDialog}
        failedRequests={failedRequests}
        setFailedRequests={setFailedRequests}
      />
    </div>
  );
};

export default AddAttributes;
