import React, { useEffect, useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import { useSelector } from 'react-redux';
import moment from 'moment';
import NoData from './noDataScreen';
import { formatDateWithTimeZone, handleApiRequest } from '../../common/common';
import { selectDashboardData } from '../../../store/userSlice';
import metricsData from '../../query/metrics';
import ReviewSentiment from './reviewSentiment';

const QuickReviewsCount = (props) => {
  const { reviewSentiment, reviewPlatformId, placeId } = props;
  const [newGraphData, setNewGraphData] = useState([5, 32, 15, 20, 18, 30, 35]);
  const [selectedReviewSentimentId, setSelectedReviewSentimentId] = useState(1);
  const [reviewSentimentBarData, setReviewSentimentBarData] = useState([]);
  const userCurrentBusinessDetails = useSelector(selectDashboardData);
  const [timeZone] = useState(Intl.DateTimeFormat().resolvedOptions().timeZone);

  useEffect(() => {
    try {
      const fetchData = async () => {
        if (
          (reviewPlatformId &&
            (parseInt(reviewPlatformId, 10) === 2 ||
              parseInt(reviewPlatformId, 10) === 3 ||
              parseInt(reviewPlatformId, 10) === 4 ||
              parseInt(reviewPlatformId, 10) === 7 ||
              parseInt(reviewPlatformId, 10) === 8)) ||
          (userCurrentBusinessDetails?.isOnlineBusiness && placeId && reviewPlatformId)
        ) {
          const payload = {
            query: metricsData.getQuickReviewsCountV3,
            variables: {
              reviewPlatformId: parseInt(reviewPlatformId, 10),
              reviewPeriodId: parseInt(selectedReviewSentimentId, 10),
            },
          };
          if (parseInt(reviewPlatformId, 10) === 5 || parseInt(reviewPlatformId, 10) === 6) {
            payload.variables.placeId = placeId;
          } else {
            payload.variables.userBusinessId = parseInt(userCurrentBusinessDetails?.id, 10);
          }
          const result = await handleApiRequest(payload);
          if (result?.getQuickReviewsCountV3?.status === 200) {
            const data = result.getQuickReviewsCountV3?.data?.[0]?.reviewCountJsonV1;
            if (data) {
              const keyValueObject = data?.map((item) => {
                const obj = {};
                item.forEach(({ key, value }) => {
                  obj[key] = value;
                });
                return obj;
              });
              setNewGraphData(
                keyValueObject
                  ?.sort((a, b) => b.reviewPeriod - a.reviewPeriod)
                  ?.map((item) => item?.review) || []
              );
              setReviewSentimentBarData(
                keyValueObject
                  ?.sort((a, b) => b.reviewPeriod - a.reviewPeriod)
                  ?.map((item) => item?.reviewPeriod) || []
              );
            } else {
              setNewGraphData([]);
              setReviewSentimentBarData([]);
            }
          }
        }
        if (
          userCurrentBusinessDetails?.isOnlineBusiness &&
          !placeId &&
          (parseInt(reviewPlatformId, 10) === 5 || parseInt(reviewPlatformId, 10) === 6)
        ) {
          setNewGraphData([]);
        }
      };
      fetchData();
    } catch (error) {
      console.error('An error occurred:', error);
    }
  }, [
    userCurrentBusinessDetails?.id,
    reviewPlatformId,
    selectedReviewSentimentId,
    placeId,
    userCurrentBusinessDetails?.isOnlineBusiness,
  ]);

  useEffect(() => {
    if (newGraphData?.length === 0) {
      const arrayLength =
        selectedReviewSentimentId === 2
          ? 12
          : selectedReviewSentimentId === 3
          ? Number(formatDateWithTimeZone(moment(), 'D'))
          : Math.ceil(moment().daysInMonth() / 7);

      const timestamps = Array.from({ length: arrayLength }, (_, i) => {
        if (selectedReviewSentimentId === 2) {
          return Number(moment().tz(timeZone).subtract(i, 'months').startOf('month').unix());
        }
        if (selectedReviewSentimentId === 3) {
          return Number(moment().tz(timeZone).startOf('day').subtract(i, 'days').unix());
        }
        return Number(
          moment()
            .tz(timeZone)
            .startOf('month')
            .add(i * 7, 'days')
            .unix()
        );
      });
      const sortedData = timestamps?.sort((prev, curr) => curr - prev);

      const updatedGraphData = sortedData?.map((item, index) => ({
        reviewPeriod: item,
        review: 0,
      }));

      setNewGraphData(updatedGraphData?.map((item) => item?.review));
      setReviewSentimentBarData(timestamps);
    }
  }, [newGraphData, selectedReviewSentimentId, timeZone]);

  function getChartSimple(data, categories, index, color, types) {
    const chart = {
      series: [
        {
          name: 'Reviews',
          data,
        },
      ],
      options: {
        grid: {
          strokeDashArray: 3,
          show: true,
          forceNiceScale: false,
          padding: {
            bottom: 0,
          },
        },
        chart: {
          events: {
            mounted: (charts) => {
              charts.windowResizeHandler();
            },
          },
          type: types,
          parentHeightOffset: 0,
          zoom: {
            enabled: false,
          },
          toolbar: {
            show: false,
          },
        },
        markers: {
          size: 4,
          shape: 'circle',
        },
        stroke: {
          curve: 'straight',
          width: 2,
        },
        legend: {
          markers: {
            radius: 12,
          },
        },
        fill: {
          colors: color,
          opacity: 1,
        },
        colors: color,
        dataLabels: {
          enabled: false,
        },
        xaxis: {
          axisBorder: {
            show: false,
          },
          categories,
          show: true,
          axisTicks: {
            show: true,
          },
          labels: {
            show: true,
            offsetY: 0,
          },
        },
        yaxis: {
          show: true,
          axisTicks: {
            show: true,
          },
          labels: {
            show: true,
            align: 'right',
            maxWidth: 350,
            position: 'right',
            offsetX: 0,
            style: {
              fontSize: '14px',
            },
          },
          min: 0,
          max: 10,
          tickAmount: 5,
        },
        tooltip: {
          enabled: true,
          y: {
            formatter(val) {
              return `${val}`;
            },
            title: {
              formatter() {
                return '';
              },
            },
          },
        },
      },
    };

    return (
      <div id={`chart${index}`} className="flex flex-col flex-auto w-full">
        <ReactApexChart
          options={chart?.options}
          series={chart?.series}
          type={chart?.options?.chart?.type}
          height="300px"
          width="100%"
        />
      </div>
    );
  }

  return (
    <div>
      <ReviewSentiment
        reviewSentiment={reviewSentiment}
        sentimentId={[1, 2, 3]}
        onSelectSentiment={setSelectedReviewSentimentId}
      />
      {newGraphData &&
        getChartSimple(
          newGraphData,
          reviewSentimentBarData?.map(
            (period) => moment.unix(period).tz(timeZone).format('ll')?.split(',')[0]
          ),
          3,
          ['#0BA861'],
          'line'
        )}
    </div>
  );
};

export default QuickReviewsCount;
