import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Typography from '@mui/material/Typography';
import { motion } from 'framer-motion';
import Button from '@mui/material/Button';
import Icon from '@mui/material/Icon';
import Input from '@mui/material/Input';
import Paper from '@mui/material/Paper';
import FuseLoading from '@fuse/core/FuseLoading';
import TablePagination from '@mui/material/TablePagination';
import './clients.css';
import { MenuItem, Select } from '@mui/material';
import AddEditClientPopUp from './addEditClient';
import UserProfile from '../../common/userProfile';
import { getDataToServer, handleApiRequest } from '../../common/common';
import agencyData from '../../query/agency';
import history from '../../../../@history/@history';

function Clients() {
  const options = [
    { id: 1, label: 'activeClients', value: 'Active Clients' },
    { id: 2, label: 'deactiveClients', value: 'Deactive Clients' },
  ];
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [agencyClientDetail, setAgencyClientDetail] = useState([]);
  const [totalAgencyClient, setTotalAgencyClient] = useState();
  const [pageLoading, setPageLoading] = useState(false);
  const [openAddDialogue, setOpenAddDialogue] = useState(false);
  const [page, setPage] = useState(1);
  const [rowsPerPage] = useState(12);
  const [searchValue, setSearchValue] = useState('');
  const [profileImageUrl, setProfileImageUrl] = useState();
  const [isActiveClient, setIsActiveClient] = useState(true);
  const [selectedValue, setSelectedValue] = useState(1);

  useEffect(() => {
    const fetchDashboard = async () => {
      setPageLoading(true);
      const payload = {
        query: agencyData.getAgencyClientList,
        variables: {
          pageSize: 12,
          pageNumber: 1,
          searchText: '',
          isActive: isActiveClient,
        },
      };
      const result = await getDataToServer(payload);
      if (result?.getAgencyClientList?.status === 200) {
        setPageLoading(false);
      }
      setAgencyClientDetail(result?.getAgencyClientList?.data);
      setTotalAgencyClient(result?.getAgencyClientList?.totalCount);
    };
    fetchDashboard();
  }, [dispatch, isActiveClient]);

  if (pageLoading) {
    return <FuseLoading />;
  }

  const commonGetData = async (searchValues = '') => {
    setSearchValue(searchValues);
    const payload = {
      query: agencyData.getAgencyClientList,
      variables: {
        searchText: searchValues,
        pageNumber: page,
        pageSize: rowsPerPage,
        isActive: isActiveClient,
      },
    };
    const result = await handleApiRequest(payload);
    if (result?.getAgencyClientList?.status === 200) {
      setAgencyClientDetail(result?.getAgencyClientList?.data);
      setTotalAgencyClient(result?.getAgencyClientList?.totalCount);
    }
  };

  function handleSearchEvent(event) {
    setPage(1);
    const searchText = event.target.value;
    commonGetData(searchText);
  }

  const clearSearch = async () => {
    await setPage(1);
    await setSearchValue('');
    await commonGetData();
  };

  const handleChangePage = async (event, value) => {
    setPage(value + 1);
    const payload = {
      query: agencyData.getAgencyClientList,
      variables: {
        pageSize: rowsPerPage,
        pageNumber: value + 1,
        searchText: searchValue,
        isActive: isActiveClient,
      },
    };
    const result = await handleApiRequest(payload);
    if (result?.getAgencyClientList?.status === 200) {
      setAgencyClientDetail(result?.getAgencyClientList?.data);
      setTotalAgencyClient(result?.getAgencyClientList?.totalCount);
    }
  };

  const cancelDialogue = async () => {
    setOpenAddDialogue(false);
    const payload = {
      query: agencyData.getAgencyClientList,
      variables: {
        searchText: searchValue,
        pageNumber: page,
        pageSize: rowsPerPage,
        isActive: isActiveClient,
      },
    };
    const result = await handleApiRequest(payload);
    if (result?.getAgencyClientList?.status === 200) {
      setAgencyClientDetail(result?.getAgencyClientList?.data);
      setTotalAgencyClient(result?.getAgencyClientList?.totalCount);
    }
  };
  const handleChangeActiveDeactiveClients = (e) => {
    const statusValue = e.target.value;
    setSelectedValue(statusValue);
    statusValue === 1 ? setIsActiveClient(true) : setIsActiveClient(false);
  };
  return (
    <div className="p-20 lg:p-24">
      <div className="sm:flex flex-1 w-full items-center justify-between mb-36">
        <div className="flex items-center">
          <Typography
            component={motion.span}
            initial={{ x: -20 }}
            animate={{ x: 0, transition: { delay: 0.2 } }}
            delay={300}
            className="sm:flex text-16 md:text-28 font-bold"
          >
            {t('dashboard.dashboardAgency.clients')}
          </Typography>
        </div>
        <div className="flex flex-1 items-center justify-center sm:px-12 sm:my-0 my-16">
          <Paper
            component={motion.div}
            initial={{ y: -20, opacity: 0 }}
            animate={{ y: 0, opacity: 1, transition: { delay: 0.2 } }}
            className="flex items-center w-full max-w-512 px-8 rounded-8 shadow-none border-1 border-solid border-grey-300"
          >
            <Input
              placeholder={t('agencyBusiness.clients.search')}
              className="flex flex-1 mx-8 text-black text-16"
              disableUnderline
              name="searchText"
              fullWidth
              inputProps={{
                'aria-label': 'Search',
              }}
              onChange={handleSearchEvent}
              value={searchValue}
            />
            {searchValue ? (
              <Icon
                color="action"
                className="cursor-pointer"
                onClick={() => {
                  clearSearch();
                }}
              >
                close
              </Icon>
            ) : (
              <Icon color="action">search</Icon>
            )}
          </Paper>
        </div>
        <div className="flex gap-20">
          <Select
            className="w-160 sm:0 bg-darkgreen-100"
            value={selectedValue || 'all'}
            size="small"
            onChange={handleChangeActiveDeactiveClients}
          >
            {options.map((item, i) => {
              return (
                <MenuItem value={item.id} key={i}>
                  {item.value}
                </MenuItem>
              );
            })}
          </Select>
          {agencyClientDetail && agencyClientDetail?.length > 0 && (
            <Button
              className="text-16 px-32 rounded-md"
              variant="contained"
              color="secondary"
              aria-label="Edit"
              onClick={() => setOpenAddDialogue(true)}
            >
              {t('agencyBusiness.clients.addClient')}
            </Button>
          )}
        </div>
      </div>
      {agencyClientDetail && agencyClientDetail?.length > 0 ? (
        <div className="grid xl:grid-cols-4 lg:grid-cols-4 md:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-28">
          {agencyClientDetail?.map &&
            agencyClientDetail.map((item, index) => (
              <div
                className="bg-white p-20 rounded-md  cursor-pointer"
                aria-hidden="true"
                onClick={() => history.push(`/agency/client/${item?.id}`)}
              >
                <div className="flex justify-between mb-12">
                  {item?.profileUrl ? (
                    <img
                      src={item?.profileUrl}
                      className="img-fluid w-68 h-68 rounded-full"
                      alt="..."
                    />
                  ) : (
                    <span className="userProfile">
                      <UserProfile name={item.firstName} />
                    </span>
                  )}
                  <span
                    className={`${
                      item?.user_package_subscription?.packageName ===
                      process.env.REACT_APP_PLAN_ENTERPRISE
                        ? 'bg-orange'
                        : item?.user_package_subscription?.packageName ===
                          process.env.REACT_APP_PLAN_PROFESSIONAL
                        ? 'bg-light-green-A700'
                        : item?.user_package_subscription?.packageName ===
                          process.env.REACT_APP_PLAN_STANDARD
                        ? 'bg-blue'
                        : 'bg-grey-800'
                    } text-white rounded-xl block h-24 pt-4 text-12 font-medium min-w-120 text-center`}
                  >
                    {item?.user_package_subscription?.packageName
                      ? `${item?.user_package_subscription?.packageName} ${t(
                          'agencyBusiness.clients.plan'
                        )}`
                      : `${t('agencyBusiness.clients.noPlan')}`}
                  </span>
                </div>
                <h3 className="text-16 font-bold">
                  <span className="flex items-center justify-between capitalize">
                    {item?.firstName} {item?.lastName}
                  </span>
                </h3>
                <span className="text-14 my-8 font-medium text-grey-600 block">
                  {item?.phoneNumber?.countryCode} {item?.phoneNumber?.phoneNumber}
                </span>
                <span className="block font-medium text-grey-600 min-h-20 break-all">
                  {item?.email}
                </span>

                <Typography className="text-14 font-medium text-grey-600 flex items-center justify-between border-1 border-dashed border-grey-500 rounded-md py-8 px-16 mt-16">
                  <span>{t('dashboard.dashboardAdmin.totalBusiness')}</span>{' '}
                  <span>{item?.businessCount}</span>
                </Typography>
                <Button
                  className="text-14 w-full rounded-md mt-16"
                  variant="outlined"
                  size="small"
                  color="secondary"
                  aria-label="Edit"
                  onClick={(e) => {
                    e.stopPropagation();
                    history.push(`/agency/add-business/${item?.id}`);
                  }}
                >
                  {t('agencyBusiness.addBusiness')}
                </Button>
              </div>
            ))}
        </div>
      ) : (
        <div className="bg-white rounded-md relative shadow">
          {searchValue ? (
            <div className="bg-white rounded-md relative shadow">
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1, transition: { delay: 0.1 } }}
                className="flex flex-1 items-center justify-center h-full"
              >
                <Typography color="textSecondary" variant="h5" className="text-16 py-12">
                  {t('agencyBusiness.clients.noClientsFound')}
                </Typography>
              </motion.div>
            </div>
          ) : (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1, transition: { delay: 0.1 } }}
              className="flex flex-1 items-center justify-center noOrder-height"
            >
              <div className="mx-auto text-center">
                <span className="block text-center">
                  <img
                    src="/assets/images/agency/noClinet.png"
                    className="img-fluid mx-auto w-144 h-144 mb-36"
                    alt="..."
                  />
                </span>
                <Typography
                  color="textSecondary"
                  variant="h5"
                  className="text-20 py-12 block font-semibold text-black"
                >
                  {t('agencyBusiness.clients.noClient')}
                </Typography>
                <Typography
                  variant="h5"
                  className="text-16 font-medium py-12 mb-16 text-grey-600 max-w-512"
                >
                  {t('agencyBusiness.clients.noClientDesc')}
                </Typography>
                <Button
                  className="text-18 px-32 rounded-md"
                  variant="contained"
                  color="secondary"
                  aria-label="Edit"
                  onClick={() => setOpenAddDialogue(true)}
                >
                  {t('agencyBusiness.clients.addFirst')}
                </Button>
              </div>
            </motion.div>
          )}
        </div>
      )}
      {agencyClientDetail?.length > 0 && (
        <TablePagination
          sx={{
            '.MuiInputBase-root': {
              lineHeight: 'inherit',
              padding: 0,
            },
          }}
          component="div"
          count={totalAgencyClient}
          rowsPerPage={12}
          rowsPerPageOptions={[]}
          page={page - 1}
          backIconButtonProps={{
            'aria-label': 'Previous Page',
          }}
          nextIconButtonProps={{
            'aria-label': 'Next Page',
          }}
          onPageChange={handleChangePage}
        />
      )}
      {openAddDialogue && (
        <AddEditClientPopUp
          openValue={openAddDialogue}
          cancelCall={cancelDialogue}
          profileImageUrl={profileImageUrl}
          setProfileImageUrl={setProfileImageUrl}
          type="add"
          popUpMessage={t('agencyBusiness.clients.addClient')}
          popUpButtonName={t('agencyBusiness.clients.addClients')}
        />
      )}
    </div>
  );
}
export default Clients;
