import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  CircularProgress,
  Icon,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
  Autocomplete,
} from '@mui/material';
import { t } from 'i18next';
import { makeStyles } from '@mui/styles';
import { motion } from 'framer-motion';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import { FixedSizeList as List } from 'react-window';
import promotionQuery from '../../query/promotion';
import { handleApiRequest, imageDataToServer } from '../../common/common';

const useStyles = makeStyles(() => ({
  root: {
    '& .MuiAutocomplete-tag': {
      backgroundColor: '#0BA861',
      color: 'white',
      borderRadius: '8px',
    },
    '& .MuiChip-deleteIcon': {
      color: 'white',
    },
  },
}));

const AddEditAppPromotionAndSlider = (props) => {
  const {
    isDialogOpen,
    setIsDialogOpen,
    setPage,
    setRowsPerPage,
    totalData,
    editData,
    setEditData,
    fetchData,
  } = props;

  const classes = useStyles();
  const defaultValues = {
    sliderName: '',
    des: '',
    buttonName: '',
    urlValue: '',
    packageType: '',
  };

  const schema = yup.object().shape({
    sliderName: yup
      .string()
      .required(t('appPromotionSlider.field.sliderName.validationMessage.required')),
    des: yup
      .string()
      .required(t('appPromotionSlider.field.description.validationMessage.required')),
    buttonName: yup
      .string()
      .required(t('appPromotionSlider.field.buttonText.validationMessage.required')),
    urlValue: yup
      .string()
      .required(t('appPromotionSlider.field.sliderName.validationMessage.required')),
    index: yup.string().required(t('appPromotionSlider.field.index.validationMessage.required')),
  });

  const {
    control,
    watch,
    reset,
    handleSubmit,
    formState,
    setValue,
    getValues,
    setError,
    clearErrors,
  } = useForm({
    mode: 'onChange',
    defaultValues,
    resolver: yupResolver(schema),
  });

  const { isValid, dirtyFields, errors } = formState;

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [imagePreviewName, setImagePreviewName] = useState();
  const [images, setImages] = useState();
  const [packagesData, setPackagesData] = useState([]);
  const [refreshIcon, setRefreshIcon] = useState(false);
  const [packageValue, setPackageValue] = useState([]);
  const [packageTypesId, setPackageTypesId] = useState([]);

  useEffect(() => {
    const { id, title, index, description, buttonText, url, packageType } = editData || {};
    const data = packageType?.map((item) => {
      return parseInt(item.id, 10);
    });
    const mappedValues = {
      index: index || '',
      sliderName: title || '',
      des: description || '',
      buttonName: buttonText || '',
      urlValue: url || '',
    };
    setPackageValue(packageType || []);
    setPackageTypesId(data || []);
    const dataObj = {
      index: '',
      sliderName: '',
      des: '',
      buttonName: '',
      urlValue: '',
    };
    reset(id ? mappedValues : dataObj);
  }, [editData, reset, setValue]);

  const handleFileChange = async (event) => {
    const file = event?.target?.files[0];
    if (file && file.type !== 'image/jpeg' && file.type !== 'image/png') {
      enqueueSnackbar('Only valid .png, .jpg format files', {
        variant: 'error',
        autoHideDuration: 2000,
      });
      return;
    }
    setImagePreviewName(event?.target?.files[0].name);
    const reader = new FileReader();
    reader?.readAsBinaryString(file);
    reader.onload = async () => {
      setImages(file);
    };
  };

  useEffect(() => {
    async function fetchPackageTypeData() {
      const payload = {
        query: promotionQuery.getPackageTypes,
      };
      const res = await handleApiRequest(payload);
      if (res?.getPackageTypes?.status === 200) {
        setPackagesData(res?.getPackageTypes?.data);
      }
    }
    fetchPackageTypeData();
  }, []);

  const onsubmit = async ({ sliderName, des, index, buttonName, urlValue }) => {
    setPage(1);
    if (!editData?.id) {
      if (packageTypesId.length === 0) {
        enqueueSnackbar(t('appPromotionSlider.field.packageType.validationMessage.required'), {
          variant: 'error',
          autoHideDuration: 2000,
        });
      } else if (!images) {
        enqueueSnackbar('please select image', {
          variant: 'error',
          autoHideDuration: 2000,
        });
      } else if (images && packageTypesId.length !== 0) {
        setRefreshIcon(true);
        const payload = {
          query: promotionQuery.addAppPromotionSlider,
          variables: {
            title: sliderName,
            description: des,
            buttonText: buttonName,
            url: urlValue,
            packageTypeIds: packageTypesId,
            icon: images,
            index: parseInt(index, 10),
          },
        };
        const res = await imageDataToServer(payload);
        setRefreshIcon(false);
        if (res?.addAppPromotionSlider?.status === 200) {
          setPackageTypesId([]);
          clearData();
          fetchData();
          enqueueSnackbar(res?.addAppPromotionSlider?.message, {
            variant: 'success',
            autoHideDuration: 2000,
          });
        } else {
          enqueueSnackbar(res?.addAppPromotionSlider?.message, {
            variant: 'error',
            autoHideDuration: 2000,
          });
        }
      }
    } else if (packageTypesId.length === 0) {
      enqueueSnackbar(t('appPromotionSlider.field.packageType.validationMessage.required'), {
        variant: 'error',
        autoHideDuration: 2000,
      });
    } else if (packageTypesId.length !== 0) {
      setRefreshIcon(true);
      const payload = {
        query: promotionQuery.updatePromotionSlider,
        variables: {
          index: parseInt(index, 10),
          promotionSliderId: parseInt(editData?.id, 10),
          title: sliderName,
          description: des,
          buttonText: buttonName,
          url: urlValue,
          packageTypeIds: packageTypesId,
          icon: images,
        },
      };
      const res = await imageDataToServer(payload);
      if (res?.updatePromotionSlider?.status === 200) {
        fetchData();
        setRefreshIcon(false);
        setIsDialogOpen(false);
        enqueueSnackbar(res?.updatePromotionSlider?.message, {
          variant: 'success',
          autoHideDuration: 2000,
        });
      }
    }
  };

  const clearData = () => {
    setIsDialogOpen(false);
    setEditData();
    reset();
    setImages();
    setPackageValue([]);
    setPackageTypesId([]);
    setImagePreviewName();
  };

  function handleSelectBusinessAttribute(event, newValue) {
    setPackageValue(newValue);
    setPackageTypesId(
      newValue.map((value) => {
        return parseInt(value.id, 10);
      })
    );
  }

  return (
    <div>
      <Dialog
        classes={{
          root: classes.root,
          paper: 'max-w-[30%] overflow:scroll p-28 business-type-dropdown',
        }}
        open={isDialogOpen}
        onClose={() => clearData()}
        fullWidth
        maxWidth="xs"
      >
        <DialogTitle className="p-0 mb-6">
          <Icon
            onClick={() => clearData()}
            className="text-24 absolute top-12 right-12 text-grey-400 font-normal cursor-pointer hover:text-gray-700 focus:text-gray-700"
          >
            {t('appPromotionSlider.cancel')}
          </Icon>
          <Typography className="text-20 font-semibold text-black mb-4 w-full">
            {editData ? t('appPromotionSlider.editSlider') : t('appPromotionSlider.addNewSlider')}
          </Typography>
          <hr className="border-dotted border-darkgreen" />
        </DialogTitle>
        <form
          noValidate
          className="flex flex-col md:overflow-hidden"
          onSubmit={handleSubmit(onsubmit)}
        >
          <DialogContent className={`${classes.attDialog} p-0 mb-8 business-type-dropdown-content`}>
            <div className="block mb-4">
              <InputLabel id="businessType" className="text-16 font-medium mb-4 text-grey-900">
                {t('appPromotionSlider.field.index.name')}
              </InputLabel>
              <Controller
                control={control}
                name="index"
                render={({ field }) => (
                  <TextField
                    {...field}
                    placeholder={t('appPromotionSlider.field.index.message')}
                    size="small"
                    type="number"
                    id="businessSubTitle"
                    value={field.value || ''}
                    error={!!errors.index}
                    helperText={errors?.index?.message}
                    variant="outlined"
                    required
                    fullWidth
                  />
                )}
              />
            </div>
            <div className="block mb-4">
              <InputLabel id="businessType" className="text-16 font-medium mb-4 text-grey-900">
                {t('appPromotionSlider.field.sliderName.name')}*
              </InputLabel>
              <Controller
                control={control}
                name="sliderName"
                render={({ field }) => (
                  <TextField
                    {...field}
                    placeholder={t('appPromotionSlider.field.sliderName.message')}
                    size="small"
                    id="businessSubTitle"
                    value={field.value || ''}
                    error={!!errors.sliderName}
                    helperText={errors?.sliderName?.message}
                    variant="outlined"
                    required
                    fullWidth
                  />
                )}
              />
            </div>
            <div className="block mb-4">
              <InputLabel id="businessType" className="text-16 font-medium mb-4 text-grey-900">
                {t('appPromotionSlider.field.description.name')}*
              </InputLabel>
              <Controller
                name="des"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    sx={{
                      '& .MuiInputBase-input': {
                        background: 'white',
                      },
                    }}
                    placeholder={t('appPromotionSlider.field.description.message')}
                    type="text"
                    size="small"
                    value={field.value || ''}
                    error={!!errors.des}
                    helperText={errors?.des?.message}
                    multiline
                    rows={3}
                    variant="outlined"
                    required
                    fullWidth
                  />
                )}
              />
            </div>
            <div className="block mb-6=4">
              <InputLabel
                htmlFor="businessSubTitle"
                className="text-16 font-medium mb-4 text-grey-900"
              >
                {t('appPromotionSlider.field.buttonText.name')}*
              </InputLabel>
              <Controller
                control={control}
                name="buttonName"
                render={({ field }) => (
                  <TextField
                    {...field}
                    placeholder={t('appPromotionSlider.field.buttonText.message')}
                    size="small"
                    id="businessSubTitle"
                    error={!!errors.buttonName}
                    helperText={errors?.buttonName?.message}
                    variant="outlined"
                    required
                    fullWidth
                  />
                )}
              />
            </div>
            <div className="block mb-4">
              <InputLabel
                htmlFor="businessSubTitle"
                className="text-16 font-medium mb-4 text-grey-900"
              >
                {t('appPromotionSlider.field.url.name')}*
              </InputLabel>
              <Controller
                control={control}
                name="urlValue"
                render={({ field }) => (
                  <TextField
                    {...field}
                    placeholder={t('appPromotionSlider.field.url.message')}
                    size="small"
                    id="businessSubTitle"
                    error={!!errors.urlValue}
                    helperText={errors?.urlValue?.message}
                    variant="outlined"
                    required
                    fullWidth
                  />
                )}
              />
            </div>
            <div className="block">
              <InputLabel id="packageType" className="text-16 font-medium mb-8 text-grey-900">
                {t('appPromotionSlider.selectPackageType')}
              </InputLabel>
              <Autocomplete
                multiple
                id="tags-standard"
                options={packagesData}
                name="packageType"
                size="small"
                getOptionLabel={(option) => option?.name}
                isOptionEqualToValue={(option, value) => {
                  return option.id === parseInt(value.id, 10);
                }}
                onChange={handleSelectBusinessAttribute}
                value={packageValue}
                disableCloseOnSelect
                renderOption={(prop, option, { selected }) => (
                  <MenuItem
                    key={option.id}
                    value={option?.id}
                    sx={{ justifyContent: 'space-between' }}
                    {...prop}
                  >
                    {option?.name}
                    {selected ? <Icon color="info">done</Icon> : null}
                  </MenuItem>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    size="small"
                    onChange={(e) => fetchData(e.target.id)}
                    className="text-16 font-medium mb-12 text-grey-900"
                    placeholder={t('appPromotionSlider.selectPackageType')}
                  />
                )}
              />
            </div>
            <InputLabel htmlFor="businessSubTitle" className="text-16 font-medium text-grey-900">
              {t('appPromotionSlider.field.uploadIcon.name')}*
            </InputLabel>
            <motion.div className="block mb-6 rounded-8">
              <Paper
                className="relative w-full h-full rounded-8 shadow-none border-1 border-dashed border-darkgreen p-12 text-center"
                role="button"
                // onDrop={handleDrop}
                onDragOver={(e) => e.preventDefault()}
              >
                <span className="block text-center">
                  <img
                    src="/assets/images/admin-icon/cloud_upload.png"
                    className="mx-auto mb-12"
                    width="61"
                    height="64"
                    alt="..."
                  />
                </span>
                <div className="block font-medium">
                  {imagePreviewName || t('appPromotionSlider.dragFile')}
                </div>
                <span className="block my-6">or</span>
                <div className="inline-block">
                  <label htmlFor="button-file">
                    <Controller
                      control={control}
                      name="image"
                      render={({ field }) => (
                        <TextField
                          {...field}
                          placeholder={t('appPromotionSlider.field.sliderName.message')}
                          size="small"
                          id="button-file"
                          className="hidden"
                          onChange={(event) => {
                            handleFileChange(event);
                          }}
                          type="file"
                          accept="image/*"
                          variant="outlined"
                          required
                          fullWidth
                        />
                      )}
                    />
                    <Button
                      size="small"
                      className="rounded-xxl block text-14 font-medium px-24 py-5 w-auto"
                      variant="contained"
                      color="secondary"
                      component="span"
                    >
                      {t('tabletVideos.tabletVideoDetail.browseFile')}
                    </Button>
                  </label>
                </div>
              </Paper>
            </motion.div>
          </DialogContent>
          <DialogActions className="p-0 m-auto">
            <Button
              className="md:text-18 px-40 font-semibold bg-white hover:bg-white text-darkgreen border border-solid border-darkgreen rounded-md"
              variant="contained"
              color="primary"
              type="button"
              onClick={() => clearData()}
            >
              {t('common.cancel')}
            </Button>
            <Button
              className="md:text-18 font-semibold hover:bg-darkgreen disabled:text-black rounded-md"
              variant="contained"
              color="secondary"
              type="submit"
              disabled={refreshIcon}
            >
              {t('appPromotionSlider.button.uploadSlider')}
            </Button>
            {refreshIcon && (
              <CircularProgress
                className="text-darkgreen absolute right-[200px] mx-auto"
                size={24}
              />
            )}
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
};

export default AddEditAppPromotionAndSlider;
