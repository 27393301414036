import withReducer from 'app/store/withReducer';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Icon,
  MenuItem,
  Select,
  Tooltip,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableContainer,
  TableRow,
  Typography,
  CircularProgress,
} from '@mui/material';
import reducer from './store';

function ManageAddon() {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  return (
    <div className="p-20 lg:p-24">
      <div className="bg-white rounded-me shadow p-20 max-w-640 rounded-md">
        <h3 className="text-darkgreen font-semibold border-b-1 border-dashed border-grey-500 text-20 pb-20 mb-20">
          Standard Plan
        </h3>
        <div className="flex justify-between items-center text-18 font-semibold">
          <span>Current Charges</span>
          <span className="font-bold">₹ 1499/Month</span>
        </div>
      </div>
      <div className="bg-white rounded-me shadow p-20 max-w-640 rounded-md mt-36">
        <h3 className="text-darkgreen font-semibold border-b-1 border-dashed border-grey-500 text-20 pb-20 mb-20">
          {t('plan.managePlan.activeAddon')}
        </h3>
        <div className="flex justify-between items-center text-16 font-semibold border border-grey-300 border-solid rounded-md p-12 mb-12">
          <span>5 Business Locations</span>
          <Button variant="outlined" color="secondary" size="small" className="rounded-md px-24">
            Remove Locations
          </Button>
        </div>
        <div className="flex justify-between items-center text-16 font-semibold border border-grey-300 border-solid rounded-md p-12">
          <span>Video Call</span>
          <Button variant="outlined" color="secondary" size="small" className="rounded-md px-24">
            Remove
          </Button>
        </div>
        <div className="text-center">
          <Button
            variant="contained"
            color="secondary"
            size="small"
            className="rounded-md px-24 mx-auto mt-20"
          >
            {t('plan.managePlan.exploreAddon')}
          </Button>
        </div>
      </div>
    </div>
  );
}

export default withReducer('planReducer', reducer)(ManageAddon);
