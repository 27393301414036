import FuseDialog from '@fuse/core/FuseDialog';
import { styled } from '@mui/material/styles';
import FuseMessage from '@fuse/core/FuseMessage';
import FuseSuspense from '@fuse/core/FuseSuspense';
import AppContext from 'app/AppContext';
import { memo, useContext, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation, useParams, useRoutes } from 'react-router-dom';
import { Button, Typography } from '@mui/material';
import { t } from 'i18next';
import AddIcon from '@mui/icons-material/Add';
import history from '@history';
import { selectFuseCurrentLayoutConfig } from 'app/store/fuse/settingsSlice';
import FuseLoading from '@fuse/core/FuseLoading';
import { debounce } from 'lodash';
import CommonFreeTrial from 'src/app/main/CommonComponent/CommonFreeTrial';
import reviewPlatformData from 'src/app/main/query/reviewPlatform';
import FooterLayout1 from './components/FooterLayout1';
import LeftSideLayout1 from './components/LeftSideLayout1';
import NavbarWrapperLayout1 from './components/NavbarWrapperLayout1';
import RightSideLayout1 from './components/RightSideLayout1';
import ToolbarLayout1 from './components/ToolbarLayout1';
import adminData from '../../main/query/admin';
import {
  fetchBusinesses,
  fetchCountries,
  findExpireDay,
  getDecryptData,
  getParamsId,
  handleApiRequest,
} from '../../main/common/common';
import userService from '../../services';
import {
  getAllUserBusinessSubscription,
  getCountryData,
  getReviewPlaterFormData,
  getUserBusinesses,
  selectDashboardData,
  setCountries,
  setOnlineBusinessType,
  setPhysicalBusinessType,
  setReviewPlateForm,
  setUserBusinesses,
  setUserSubscriptionData,
} from '../../store/userSlice';
import queryData from '../../main/query/common';
import CheckPlanForAddNewBusiness from '../../main/business/manage-business/checkPlanForAddNewBusiness/CheckPlanForAddNewBusiness';

const Root = styled('div')(({ theme, config }) => ({
  ...(config.mode === 'boxed' && {
    clipPath: 'inset(0)',
    maxWidth: `${config.containerWidth}px`,
    margin: '0 auto',
    boxShadow: '0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)',
  }),
  ...(config.mode === 'container' && {
    '& .container': {
      maxWidth: `${config.containerWidth}px`,
      width: '100%',
      margin: '0 auto',
    },
  }),
}));

function Layout1(props) {
  const config = useSelector(selectFuseCurrentLayoutConfig);
  const appContext = useContext(AppContext);
  const [isAppMaintenance, setIsAppMaintenance] = useState();
  const { routes } = appContext;
  const routesComponent = useRoutes(routes);
  const location = useLocation();
  const loginUserData = userService.getUserData();
  const [pageLoading, setPageLoading] = useState(false);
  const [openSelectProductDialog, setOpenSelectProductDialog] = useState(false);
  const [planAvailableDialog, setPlanAvailableDialog] = useState(false);
  const [userAvailablePlan, setUserAvailablePlan] = useState([]);
  const userSubscriptionData = useSelector(getAllUserBusinessSubscription);
  const [isFirstBusiness, setIsFirstBusiness] = useState();
  const businesses = useSelector(getUserBusinesses);
  const userCurrentBusinessDetails = useSelector(selectDashboardData);
  const [expireProduct, setExpireProduct] = useState();
  const [isManual, setIsManual] = useState(false);
  const id = getParamsId();
  const dispatch = useDispatch();

  useEffect(() => {
    if (
      (location.pathname.includes('quick-reviews') || location.pathname.includes('quick-chat')) &&
      userCurrentBusinessDetails?.isManualBusiness
    ) {
      const checkPlateFormAddedOrNot =
        userCurrentBusinessDetails?.reviewplatform?.length > 0 &&
        userCurrentBusinessDetails?.reviewplatform?.[0]?.reviewPlatformId === null;
      if (checkPlateFormAddedOrNot && !expireProduct?.isExpired) {
        location.pathname.includes('quick-reviews')
          ? history.push('quick-reviews/dashboard/')
          : location.pathname.includes('quick-chat') && history.push('quick-chat/dashboard/');
        setIsManual(true);
      }
    }
  }, [userCurrentBusinessDetails, location.pathname, expireProduct]);

  useEffect(() => {
    const fetchIsAppMaintenance = async () => {
      const payload = {
        query: adminData.getAppMaintenanceStatus,
      };
      const result = await handleApiRequest(payload);
      if (result?.getAppMaintenanceStatus?.status === 200) {
        setIsAppMaintenance(result.getAppMaintenanceStatus?.data?.isAppUnderMaintenance);
      }
    };
    fetchIsAppMaintenance();
  }, []);

  useEffect(() => {
    const fetchFilterData = async () => {
      try {
        const payload = {
          query: reviewPlatformData.getProduct,
          variables: {
            businessId: Number(userCurrentBusinessDetails?.id),
          },
        };
        const result = await handleApiRequest(payload);
        if (result?.getProduct?.status === 200) {
          setIsFirstBusiness(result?.getProduct?.isFirstBusiness);
        }
      } catch (err) {
        console.error('Error', err);
      }
    };
    if (userCurrentBusinessDetails?.id) {
      fetchFilterData();
    }
  }, [userCurrentBusinessDetails?.id]);

  useEffect(() => {
    if (localStorage.getItem('businesses')) {
      dispatch(setUserBusinesses(JSON.parse(localStorage.getItem('businesses'))));
    }
    if (localStorage.getItem('physicalBusinessType')) {
      dispatch(setPhysicalBusinessType(JSON.parse(localStorage?.getItem('physicalBusinessType'))));
    }
    if (localStorage.getItem('onlineBusinessType')) {
      dispatch(setOnlineBusinessType(JSON.parse(localStorage?.getItem('onlineBusinessType'))));
    }
    if (localStorage.getItem('reviewPlateForm')) {
      dispatch(setReviewPlateForm(JSON.parse(localStorage?.getItem('reviewPlateForm'))));
    }
    if (localStorage.getItem('countries')) {
      dispatch(setCountries(JSON.parse(localStorage?.getItem('countries'))));
    }
  }, [dispatch]);

  useEffect(() => {
    const products = [
      {
        id: 7,
        productName: 'Quick Reviews',
        product: 'quick-reviews',
        descriptionPath: 'quick-reviews/description/',
        dashboardPath: 'quick-reviews/dashboard/',
      },
      {
        id: 9,
        productName: 'Quick Chat',
        product: 'quick-chat',
        descriptionPath: 'quick-chat/description/',
        dashboardPath: 'quick-chat/dashboard/',
      },
      {
        id: 8,
        productName: 'Quick Social',
        product: 'quick-social',
        descriptionPath: 'quick-social/description/',
        dashboardPath: 'quick-social/dashboard/',
      },
      {
        id: 10,
        productName: 'Quick Campaigns',
        product: 'quick-campaign',
        descriptionPath: 'quick-campaign/description/',
        dashboardPath: 'quick-campaign/dashboard/',
      },
      {
        id: 11,
        productName: 'Quick Ads',
        product: 'quick-ads',
        descriptionPath: 'quick-ads/description/',
        dashboardPath: 'quick-ads/dashboard/',
      },
    ].map((product) => ({ ...product, isExpired: false, isPurchasedOneTime: false }));
    const currentDate = new Date();
    const userBusinessSubscriptions =
      userSubscriptionData?.filter(
        ({ userBusinessId }) => userBusinessId === userCurrentBusinessDetails?.id
      ) || [];
    const activeSubscriptions = userBusinessSubscriptions.filter(
      ({ expireDate }) => new Date(expireDate) > currentDate
    );
    if (userCurrentBusinessDetails?.id && userSubscriptionData) {
      const purchaseProductsOrNot = products.map((product) => ({
        ...product,
        isPurchasedOneTime: userBusinessSubscriptions.some(
          (pkg) =>
            pkg.name === product.productName ||
            pkg.comboDetails?.some(({ name }) => name === product.productName) ||
            pkg.packageBundleDetails?.some(({ packageTypeId }) => packageTypeId === product.id)
        ),
      }));
      const updatedProducts = purchaseProductsOrNot.map((product) => {
        const matchingPackage = activeSubscriptions.find(
          (pkg) =>
            pkg.name === product.productName ||
            pkg.comboDetails?.some(({ name }) => name === product.productName) ||
            pkg.packageBundleDetails?.some(({ packageTypeId }) => packageTypeId === product.id)
        );
        return {
          ...product,
          isExpired: !matchingPackage || new Date(matchingPackage.expireDate) <= currentDate,
        };
      });
      updatedProducts.forEach(
        ({ product, isExpired, isPurchasedOneTime, descriptionPath, dashboardPath }) => {
          setExpireProduct({
            product,
            isExpired,
            isPurchasedOneTime,
            descriptionPath,
            dashboardPath,
          });
          if (location.pathname.includes(product)) {
            if (isExpired && isPurchasedOneTime) {
              history.push(dashboardPath);
            } else if (isExpired) {
              history.push(descriptionPath);
            }
          }
        }
      );
    }
  }, [userSubscriptionData, userCurrentBusinessDetails?.id, location.pathname]);

  useEffect(() => {
    const fetchData = async () => {
      if (!localStorage.getItem('businesses')) {
        const userBusinesses = await fetchBusinesses();
        if (userBusinesses?.length > 0) {
          dispatch(setUserBusinesses(userBusinesses));
          await localStorage.setItem('businesses', JSON.stringify(userBusinesses));
          const businessId = userBusinesses[0]?.id;
          const storedBusinessId = await localStorage.getItem('selectedBusinessId');
          if (!storedBusinessId && businessId) {
            await localStorage.setItem('selectedBusinessId', businessId);
          }
        }
      }
      if (!localStorage.getItem('countries')) {
        const countries = await fetchCountries();
        await localStorage.setItem('countries', JSON.stringify(countries));
      }
    };
    fetchData();
  }, [dispatch]);

  const fetchUserSubscriptionData = async () => {
    const payload = {
      query: queryData.getAllUserBusinessSubscription,
    };
    try {
      const res = await handleApiRequest(payload);
      if (res?.getAllUserBusinessSubscription?.status === 200) {
        const decryptedData = JSON.parse(getDecryptData(res?.getAllUserBusinessSubscription?.data));
        dispatch(setUserSubscriptionData(decryptedData));
      }
    } catch (error) {
      console.error('Failed to fetch subscription data:', error);
    }
  };

  const debouncedFetchData = debounce(() => {
    if (!userSubscriptionData && loginUserData) {
      fetchUserSubscriptionData();
    }
  }, 300);

  useEffect(() => {
    debouncedFetchData();
    return () => {
      debouncedFetchData.cancel();
    };
  }, [userSubscriptionData, loginUserData, debouncedFetchData]);

  const handleAddBusiness = () => {
    const findAvailablePlan = userSubscriptionData?.filter((item) => !item?.userBusinessId);
    const hasUserBusinessId = userSubscriptionData?.some((item) => item.userBusinessId !== null);
    if (findAvailablePlan?.length > 0) {
      if (hasUserBusinessId) {
        setUserAvailablePlan(findAvailablePlan);
        setPlanAvailableDialog(true);
      } else {
        history.push('manage-business', {
          usePackageSubscriptionId: userSubscriptionData,
          addNewBusiness: 'firstBusiness',
        });
      }
    } else {
      setOpenSelectProductDialog(true);
    }
  };

  return (
    <Root id="fuse-layout" config={config} className="w-full flex">
      {config.leftSidePanel.display && <LeftSideLayout1 />}
      <div className="flex flex-auto min-w-0">
        {config.navbar.display && config.navbar.position === 'left' && <NavbarWrapperLayout1 />}

        <main id="fuse-main" className="flex flex-col flex-auto min-h-full min-w-0 relative z-10">
          {config.toolbar.display && (
            <ToolbarLayout1 className={config.toolbar.style === 'fixed' && 'sticky top-0'} />
          )}

          <div className="sticky top-0 z-99">{/* <SettingsPanel /> */}</div>
          {loginUserData?.roles?.type !== 'admin' &&
          location.pathname !== '/sign-in' &&
          location.pathname !== '/sign-up' &&
          isAppMaintenance ? (
            <div className="bg-darkgreen-100 flex items-center justify-center h-full w-full">
              <div>
                <img className="w-480 m-auto" src="/assets/video/Maintenance_gif.gif" alt="" />

                <Typography className="text-center text-16 font-bold mt-24 py-16">
                  {t('appMaintenance.webServerMaintenance')}
                </Typography>
                <Typography className="text-center max-w-512 lg:max-w-480 m-auto">
                  {t('appMaintenance.underMaintenance')}
                </Typography>
              </div>
            </div>
          ) : (
            <>
              {pageLoading ? (
                <FuseLoading />
              ) : (
                <>
                  {loginUserData &&
                  loginUserData?.roles?.type !== 'admin' &&
                  (!businesses || businesses?.length === 0) &&
                  location.pathname !== '/select-business' &&
                  location.pathname !== `/select-business/${id}` &&
                  location.pathname !== '/manage-business' &&
                  location.pathname !== `/manage-business/${id}` &&
                  location.pathname !== '/user-profile' &&
                  location.pathname !== `/user-profile/${id}` &&
                  location.pathname !== '/your-plan' &&
                  location.pathname !== '/sign-in' &&
                  location.pathname !== '/sign-up' &&
                  location.pathname !== '/verifyOtp' &&
                  // !location.pathname.includes('verifyOtp') &&
                  !location.pathname.includes(`/your-plan/${id}`) &&
                  !location.pathname.includes(`/your-plan/${id}`) &&
                  !location.pathname.includes('/agency-profile') &&
                  !location.pathname.includes('/mySubscription') &&
                  !location.pathname.includes('/description') &&
                  !location.pathname.includes('offer-pricing') &&
                  !location.pathname.includes('selectProduct') &&
                  !location.pathname.includes('/scanner') &&
                  !location.pathname.includes('/landing') &&
                  !location.pathname.includes('welcome') &&
                  !location.pathname === location.pathname.includes('/pay') ? (
                    <div className="p-24">
                      <div>
                        <CommonFreeTrial callFrom="Layout1" />
                      </div>
                      <div className="bg-gray-A500 h-full grid xl:grid-cols-3 lg:grid-cols-3 md:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-x-0 sm:gap-x-32 gap-y-28">
                        <div className="col-span-2">
                          <div className="mb-32 bg-white shadow rounded-md py-20 px-24">
                            <div className="flex flex-col-reverse md:flex-row justify-between items-center">
                              <div className="sm:mt-0 mt-20">
                                <h3 className="text-16 lg:text-20 font-bold">
                                  {t('quickHubDashboard.welcomeText')}
                                </h3>
                                <Typography className="text-16 lg:text-20 font-medium my-16 sm:my-24 max-w-512">
                                  {' '}
                                  {t('quickHubDashboard.mainDesc')}
                                </Typography>
                                <div className="flex gap-20 md:justify-start justify-center">
                                  <a
                                    role="button"
                                    target="_blank"
                                    href="https://itunes.apple.com/app/id6449814972"
                                    rel="noreferrer"
                                  >
                                    <img
                                      src="assets/images/quick-hub/app-store.png"
                                      className="inline-block max-w-120 lg:max-w-160"
                                      alt="..."
                                    />
                                  </a>
                                  <a
                                    role="button"
                                    target="_blank"
                                    href="https://play.google.com/store/apps/details?id=reviewApp.dev"
                                    rel="noreferrer"
                                  >
                                    <img
                                      src="assets/images/quick-hub/play-store.png"
                                      className="inline-block max-w-120 lg:max-w-160"
                                      alt="..."
                                    />
                                  </a>
                                </div>
                              </div>
                              <div>
                                <img
                                  src="assets/images/quick-hub/welcome.png"
                                  className="max-w-256"
                                  alt="..."
                                />
                              </div>
                            </div>
                          </div>
                          <div className="mb-32 bg-[#FFF1F6] shadow rounded-md py-20 px-24">
                            <div className="flex flex-col md:flex-row md:gap-60 items-center">
                              <div>
                                <img
                                  src="/assets/images/business/businessShop.svg"
                                  className="max-w-216"
                                  alt="..."
                                />
                              </div>
                              <div className="sm:mt-0 mt-20">
                                <Typography className="font-bold text-24 my-16">
                                  {t('businessDashboard.message')}
                                </Typography>
                                <Typography className="text-16 lg:text-20 font-medium my-16 sm:my-24 max-w-512">
                                  {' '}
                                  {t('businessDashboard.desc')}
                                </Typography>
                                <Button
                                  className="rounded px-44 mt-16"
                                  variant="contained"
                                  color="secondary"
                                  onClick={() => handleAddBusiness()}
                                >
                                  <AddIcon className="mr-8" /> {t('businessDashboard.addBusiness')}
                                </Button>
                              </div>
                            </div>
                          </div>
                        </div>
                        {(planAvailableDialog || openSelectProductDialog) && (
                          <CheckPlanForAddNewBusiness
                            openSelectProductDialog={openSelectProductDialog}
                            planAvailableDialog={planAvailableDialog}
                            userAvailablePlan={userAvailablePlan}
                            setOpenSelectProductDialog={setOpenSelectProductDialog}
                            setPlanAvailableDialog={setPlanAvailableDialog}
                            setUserAvailablePlan={setUserAvailablePlan}
                          />
                        )}
                      </div>
                    </div>
                  ) : (
                    <div className="flex flex-col flex-auto min-h-0 relative z-10">
                      <FuseDialog />

                      <FuseSuspense>{routesComponent}</FuseSuspense>

                      {props.children}
                    </div>
                  )}
                </>
              )}
            </>
          )}

          {config.footer.display && (
            <FooterLayout1 className={config.footer.style === 'fixed' && 'sticky bottom-0'} />
          )}
        </main>

        {config.navbar.display && config.navbar.position === 'right' && <NavbarWrapperLayout1 />}
      </div>

      {config.rightSidePanel.display && <RightSideLayout1 />}
      <FuseMessage />
    </Root>
  );
}

export default memo(Layout1);
