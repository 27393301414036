import React from 'react';
import { Button, Dialog, DialogContent, DialogTitle } from '@mui/material';
import { t } from 'i18next';
import CachedIcon from '@mui/icons-material/Cached';
import InstagramIcon from '@mui/icons-material/Instagram';

const NoBusinessPopUp = ({ open, setNoPage, from }) => {
  return (
    <Dialog
      open={open}
      onClose={() => setNoPage(false)}
      aria-labelledby="delete-confirmation-dialog"
      classes={{
        paper: ` business-type-dropdown rounded-md`,
      }}
      PaperProps={{ style: { maxWidth: '460px' } }}
    >
      <DialogContent
        classes={{
          root: 'p-0',
        }}
        className="p-48 flex flex-col justify-center items-center text-center"
      >
        <hr className="border-t-grey-800 border-5" />
        <img src="/assets/images/icon/noFBPage.png" alt="noFBPage" className="h-120 w-120 mb-14" />
        <h1 className="mb-14 text-20 font-600">{t('quickSocial.noBusinessPage')}</h1>
        <p className="text-18 font-500 text-gray mb-28">{t('quickSocial.noBusinessPageContent')}</p>
        <div className="flex justify-center items-center">
          <Button
            variant="outlined"
            color="quickSocial"
            type="button"
            className="rounded-md bg-quick-social-100 text-black"
            onClick={() => {
              setNoPage(false);
              if (from === 'quickADS') {
                window.open('https://www.facebook.com/pages/create', '_blank');
              } else {
                window.open('https://www.linkedin.com/company/setup/new/', '_blank');
              }
            }}
          >
            {t('quickSocial.createBusinessPage')}
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default NoBusinessPopUp;
