import { io } from 'socket.io-client';
import userService from './app/services/userService';

let socket = null;
let chatSocket = null;

const connectSocket = () => {
  const businessId = localStorage.getItem('selectedBusinessId');
  console.log('businessId', businessId);
  if (!socket && businessId) {
    const URL = process.env.REACT_APP_SOCKET;
    const headers = {};
    const token = userService.getAccessToken();
    if (token) headers.Authorization = `Bearer ${token}`;
    if (businessId) headers['User-Business-Id'] = Number(businessId);
    console.log('headers', headers);
    socket = io(URL, {
      extraHeaders: headers,
    });

    socket.on('connect', () => {
      console.log('Wp Socket connected');
    });
  }
  return socket;
};

const connectChatSocket = () => {
  if (!chatSocket) {
    const chatURL = process.env.REACT_APP_CHAT_SOCKET;
    chatSocket = io(chatURL, {
      extraHeaders: {
        Authorization: `Bearer ${userService.getAccessToken() || process.env.REACT_APP_CHAT_TOKEN}`,
      },
    });

    chatSocket.on('connect', () => {
      console.log('Chat Socket connected');
    });
  }
  return chatSocket;
};

export { connectSocket, connectChatSocket };
