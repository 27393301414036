import withReducer from 'app/store/withReducer';
import { useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { motion } from 'framer-motion';
import ArrowDropDownOutlinedIcon from '@mui/icons-material/ArrowDropDownOutlined';
import { useTranslation } from 'react-i18next';
import history from '@history';
import {
  Button,
  Icon,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from '@mui/material';
import FuseLoading from '@fuse/core/FuseLoading';
import promotionQuery from '../../query/promotion';
import Pagination from '../../../component/table/Pagination';
import TableHeader from '../../../component/table/TableHeader';
import {
  deletePromotion,
  getPromotionType,
  getPromotions,
  reSetPromotion,
} from './store/promotionSlice';
import reducer from './store';
import { formatDateWithTimeZone, getParamsId } from '../../common/common';

function Promotion() {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const headerData = [
    t('promotion.column.srNo'),
    t('common.businessName'),
    t('promotion.column.type'),
    t('promotion.column.createdDate'),
    t('promotion.column.buttonName'),
    t('common.action'),
  ];

  const promotionData = useSelector(
    ({ promotionReducer }) =>
      promotionReducer?.promotion?.getPromotionsData?.getPromotions?.data || []
  );

  const totalCount = useSelector(
    ({ promotionReducer }) =>
      promotionReducer?.promotion?.getPromotionsData?.getPromotions?.count || 0
  );

  const promotionDataMessage = useSelector(
    ({ promotionReducer }) =>
      promotionReducer?.promotion?.getPromotionsData?.getPromotions?.message || ''
  );

  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [loadingPage, setLoadingPage] = useState(false);
  const [promotionTypeData, setPromotionTypeData] = useState([]);
  const [filterValue, setFilterValue] = useState('all');

  useEffect(() => {
    setLoading(true);
    const payload = {
      query: promotionQuery.getPromotions,
      variables: { pageNumber: 1, pageSize: 10 },
    };
    dispatch(getPromotions(payload));
    return () => {
      dispatch(reSetPromotion());
    };
  }, [dispatch]);

  useEffect(() => {
    const fetchPromotionType = async () => {
      const payload = {
        query: promotionQuery.getPromotionType,
      };
      const result = await dispatch(getPromotionType(payload));
      setPromotionTypeData(result?.payload?.getPromotionType?.data);
    };
    fetchPromotionType();
  }, [dispatch]);

  useEffect(() => {
    if (promotionData?.length > 0 || promotionDataMessage === 'Not found.') {
      setLoading(false);
    }
  }, [promotionData, promotionDataMessage]);

  if (loading) {
    return <FuseLoading />;
  }

  const handleChangePage = async (event, value) => {
    setPage(value + 1);
    setLoadingPage(true);
    const payload = {
      query: promotionQuery.getPromotions,
      variables: {
        pageNumber: value + 1,
        pageSize: rowsPerPage,
        promotionTypeId: parseInt(filterValue, 10),
      },
    };
    dispatch(async (dispatchValue) => {
      await dispatchValue(getPromotions(payload));
      setLoadingPage(false);
    });
  };

  const handleChangeRowsPerPage = async (event) => {
    setLoadingPage(true);
    const targetStatus = event.target.value;
    setRowsPerPage(targetStatus);
    setPage(1);
    const payload = {
      query: promotionQuery.getPromotions,
      variables: {
        pageNumber: 1,
        pageSize: targetStatus,
        promotionTypeId: parseInt(filterValue, 10),
      },
    };
    dispatch(async (dispatchValue) => {
      await dispatchValue(getPromotions(payload));
      setLoadingPage(false);
    });
  };

  const handleDeletePromotion = async (id) => {
    const payload = {
      query: promotionQuery.deletePromotion,
      variables: {
        promotionInfoId: parseInt(id, 10),
      },
    };
    const result = await dispatch(deletePromotion(payload));
    if (result?.payload?.deletePromotion?.status === 200) {
      setLoadingPage(true);
      const obj = {
        query: promotionQuery.getPromotions,
        variables: {
          promotionTypeId: parseInt(filterValue, 10),
          pageNumber: 1,
          pageSize: rowsPerPage,
        },
      };
      dispatch(async (dispatchValue) => {
        await dispatchValue(getPromotions(obj));
        setLoadingPage(false);
      });
    }
  };

  const handleRedirect = (id) => {
    const paramId = getParamsId();
    if (paramId) {
      history.push(`promotions/${id}/${paramId}`);
    } else {
      history.push(`promotions/${id}`);
    }
  };

  const handleDropdownChange = async (event) => {
    const data = event.target.value;
    setPage(1);
    setFilterValue(data);
    setLoadingPage(true);
    const payload = {
      query: promotionQuery.getPromotions,
      variables: {
        pageSize: rowsPerPage,
        pageNumber: 1,
      },
    };
    if (data !== 'all') {
      payload.variables.promotionTypeId = data;
    }
    dispatch(async (dispatchValue) => {
      await dispatchValue(getPromotions(payload));
      setLoadingPage(false);
    });
  };

  return (
    <div className="p-20 lg:p-24">
      {loadingPage && (
        <div className="loader-container fixed top-0 left-0 w-full h-full flex justify-center items-center">
          <div className="loader h-40 w-40 rounded-full border-4 border-solid border-grey-300 border-t-darkgreen" />
        </div>
      )}
      <div className="md:flex flex-1 w-full items-center justify-between mb-36">
        <div className="flex items-center">
          <Typography
            component={motion.span}
            initial={{ x: -20 }}
            animate={{ x: 0, transition: { delay: 0.2 } }}
            delay={300}
            className="sm:flex text-20 md:text-28 font-bold mb-16 md:mb-0"
          >
            {t('promotion.title')}
          </Typography>
        </div>
        <div className="md:flex gap-24 text-center">
          <div className="items-center justify-end md:flex mb-20 md:mb-0 text-left">
            <Select
              className="rounded-md h-40 bg-white hover:bg-white text-16 w-full md:min-w-[32rem] leading-5 shadow-none capitalize"
              variant="filled"
              sx={{ '& .MuiFilledInput-input': { pt: '8px', zIndex: '10' } }}
              value={filterValue}
              fullWidth
              onChange={handleDropdownChange}
              IconComponent={() => (
                <ArrowDropDownOutlinedIcon className="text-28 align-middle absolute right-14 text-black ml-2" />
              )}
            >
              <MenuItem value="all" className="capitalize">
                {t('menuItems.all')}
              </MenuItem>
              {promotionTypeData?.length > 0 &&
                promotionTypeData?.map((item, index) => {
                  return (
                    <MenuItem key={index} value={item.id} className="capitalize">
                      {item.type}
                    </MenuItem>
                  );
                })}
            </Select>
          </div>
          <Button
            className="bg-darkgreen rounded md:px-20 text-white hover:bg-darkgreen mx-10 md:mx-0"
            type="button"
            onClick={() => {
              const id = getParamsId();
              if (id) {
                history.push(`redeem/${id}`);
              } else {
                history.push('redeem');
              }
            }}
          >
            {t('promotion.redeemPromotions')}
          </Button>
          <Button
            className="bg-darkgreen rounded md:px-20 text-white hover:bg-darkgreen mx-10 md:mx-0"
            onClick={() => {
              const id = getParamsId();
              if (id) {
                history.push(`new-promotion/${id}`);
              } else {
                history.push('new-promotion');
              }
            }}
          >
            {t('promotion.newPromotions')}
          </Button>
        </div>
      </div>
      {promotionData && promotionData?.length > 0 && (
        <>
          <div className="bg-white rounded-md relative shadow">
            <TableContainer>
              <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle" size="medium">
                <TableHeader headerData={headerData} />
                <TableBody>
                  {promotionData?.length > 0 &&
                    promotionData?.map((item, i) => {
                      return (
                        <TableRow
                          hover
                          className="border border-t-2 border-t-grey-100"
                          role="checkbox"
                          key={i}
                          tabIndex={-1}
                        >
                          <TableCell
                            className="text-16 color-black font-semibold w-[10%]"
                            align="left"
                          >
                            {i + 1 + (page - 1) * rowsPerPage}
                          </TableCell>
                          <TableCell
                            className="text-16 color-black font-semibold break-all"
                            align="left"
                          >
                            {item.businessName}
                          </TableCell>
                          <TableCell
                            className="text-16 color-black font-semibold capitalize"
                            align="left"
                          >
                            {item.promotionType}
                          </TableCell>
                          <TableCell className="text-16 color-black font-semibold" align="left">
                            {formatDateWithTimeZone(item?.cts, 'MMM DD, YYYY')}
                          </TableCell>
                          <TableCell className="text-16 color-black font-semibold" align="left">
                            {item.button}
                          </TableCell>
                          <TableCell className="text-16 color-black font-semibold" align="left">
                            <Select
                              className="rounded-md border border-grey-100 font-semibold text-grey-600 h-28 bg-grey-100 text-16 w-96 min-h-0 p-0 leading-4 shadow-none pt-0"
                              sx={{ '& .MuiFilledInput-input': { pt: '14px' } }}
                              variant="filled"
                              value="action"
                              fullWidth
                            >
                              <MenuItem
                                value="action"
                                className="capitalize mx-5 rounded-md"
                                disabled
                              >
                                {t('common.action')}{' '}
                              </MenuItem>
                              <MenuItem
                                value="view"
                                className="capitalize hover:bg-darkgreen-100 hover:text-darkgreen mx-5 rounded-md p-6"
                                onClick={() => handleRedirect(item.id)}
                              >
                                {t('common.viewDetails')}
                              </MenuItem>
                              <MenuItem
                                value="view"
                                className="capitalize hover:bg-darkgreen-100 hover:text-darkgreen mx-5 rounded-md p-6"
                                onClick={() => handleDeletePromotion(item.id)}
                              >
                                {t('common.delete')}
                              </MenuItem>
                            </Select>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
          {totalCount > 10 && (
            <Pagination
              totalCount={totalCount}
              rowsPerPage={rowsPerPage}
              page={page}
              handleChangePage={handleChangePage}
              handleChangeRowsPerPage={handleChangeRowsPerPage}
            />
          )}
        </>
      )}
      {promotionData && promotionData?.length === 0 && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1, transition: { delay: 0.1 } }}
          className="flex flex-1 items-center justify-center h-full text-center mx-auto h-fit"
        >
          <div>
            <img
              src="/assets/images/business/promotion.png"
              className="img-fluid mx-auto max-w-216 pb-40"
              alt="..."
            />
            <h5 className="block text-24 font-semibold text-black">
              {t('promotion.noPromotionMessage')}
            </h5>
            <Typography
              color="textPrimary"
              variant="h5"
              className="text-16 pt-12 font-medium max-w-640"
            >
              {t('promotion.noPromotionDesc')}
            </Typography>
            <Button
              className="md:text-16 font-medium rounded-md min-w-288 mt-32"
              variant="contained"
              color="secondary"
              type="button"
              size="small"
              onClick={() => {
                const id = getParamsId();
                if (id) {
                  history.push(`new-promotion/${id}`);
                } else {
                  history.push('new-promotion');
                }
              }}
            >
              {t('promotion.createPromotion')}
            </Button>
          </div>
        </motion.div>
      )}
    </div>
  );
}

export default withReducer('promotionReducer', reducer)(Promotion);
