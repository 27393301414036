import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  CircularProgress,
} from '@mui/material';
import { t } from 'i18next';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import React from 'react';

const LogOut = ({
  open,
  onClose,
  onLogout,
  confirmationText,
  additionalInfo,
  logoutImage,
  refresh,
}) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="logout-confirmation-dialog"
      classes={{
        paper:
          'm-24 py-60 p-20 business-type-dropdown border-t-8 border-solid text-center border-t-transparent border-transparent rounded-md',
      }}
      PaperProps={{ style: { maxWidth: '550px' } }}
    >
      <div className="overflow-auto">
        <IconButton className="absolute top-4 right-4" onClick={onClose} color="inherit">
          <CloseIcon className="w-28 h-28 text-grey-700" />
        </IconButton>
        <div className="relative w-full flex justify-center items-center">
          <div className="w-128 h-128 flex justify-center items-center rounded-full">
            <img src={logoutImage} alt="..." />
          </div>
        </div>
        <DialogTitle id="logout-confirmation-dialog" className="text-24 font-600">
          {confirmationText}
        </DialogTitle>
        <DialogContent className="p-0 mb-24">
          <div className="w-full flex justify-center items-center">
            <Typography
              className="text-16 font-500 px-40 text-black w-full"
              dangerouslySetInnerHTML={{ __html: additionalInfo }}
            />
          </div>
        </DialogContent>
        <DialogActions className="p-0 flex justify-between gap-14">
          <Button
            className="md:text-16 font-medium rounded-md w-full max-w-256"
            variant="outlined"
            color="secondary"
            onClick={onClose}
          >
            {t('common.cancel')}
          </Button>
          <Button
            className="md:text-16 font-medium rounded-md bg-darkgreen w-full max-w-256"
            variant="contained"
            disabled={refresh}
            color="secondary"
            onClick={onLogout}
          >
            {t('manageBusiness.button.logout')}{' '}
            {refresh && <CircularProgress size={24} className="text-darkgreen absolute mx-auto" />}
          </Button>
        </DialogActions>
      </div>
    </Dialog>
  );
};

export default LogOut;
