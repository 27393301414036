import { Typography } from '@mui/material';
import React, { useState, useMemo, useEffect } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import history from '@history';
import { t } from 'i18next';
import { useDispatch, useSelector } from 'react-redux';
import { publicIpv4 } from 'public-ip';
import { getDecryptData, getEncryptedData } from '../../common/common';
import { getLocation } from '../../sign-up/store/signUpSlice';
import queryData from '../../query/common';

const Assistance = ({ setAssistanceDialog }) => {
  const dispatch = useDispatch();
  const [ipBasedData, setIpBasedData] = useState(
    localStorage.getItem('ipData') ? JSON.parse(getDecryptData(localStorage.getItem('ipData'))) : ''
  );
  useEffect(() => {
    if (!ipBasedData) {
      const ipAddress = publicIpv4();
      const ipAddressData = async () => {
        console.log('await ipAddress', await ipAddress);
        const payload = {
          query: queryData.getLocation,

          variables: {
            data: getEncryptedData(`{"ipAddress": "${await ipAddress}"}`),
          },
        };
        const locationResponse = await dispatch(getLocation(payload));
        if (locationResponse?.payload?.data?.getLocation?.data) {
          const locationData = locationResponse?.payload?.data?.getLocation?.data;
          if (locationData) {
            setIpBasedData(JSON.parse(getDecryptData(locationData)));
            localStorage.setItem('ipData', locationData);
          }
        }
      };
      ipAddressData();
    }
  }, [dispatch, ipBasedData]);

  return (
    <div className="border-2 !border-quick-hub rounded-md min-w-320 max-w-320 sm:min-w-360 sm:max-w-360 p-10">
      <div className="flex justify-between items-center">
        <div className="flex gap-6 items-center">
          <img className="" src="assets/images/icon/headphone.svg" alt="" />
          <Typography className="font-semibold">{t('navigation.needAssistance')}</Typography>
        </div>
        <CloseIcon className="cursor-pointer" onClick={() => setAssistanceDialog(false)} />
      </div>
      <div className="flex justify-between items-center border border-gray rounded-md p-6 mt-8">
        <div className="flex gap-6 items-center">
          <img src="assets/images/icon/whatsapp.svg" alt="" />
          <div className="text-start">
            <Typography className="text-quick-hub font-bold">
              {t('navigation.chatOnWhatsApp')}
            </Typography>
            <Typography className="font-semibold">{t('navigation.getHelpONWhatsapp')}</Typography>
          </div>
        </div>
        <ArrowForwardIcon
          className="cursor-pointer"
          onClick={() =>
            window.open(
              ipBasedData?.isoCode === 'IN'
                ? process.env.REACT_APP_WHATSAPP_HELP_CHAT_IN_URL
                : process.env.REACT_APP_WHATSAPP_HELP_CHAT_USA_URL
            )
          }
        />
      </div>
      <div className="flex justify-between items-center border border-gray rounded-md p-6 mt-16">
        <div className="flex gap-6 items-center">
          <img src="assets/images/icon/ticket.svg" alt="" />
          <div className="text-start">
            <Typography className="text-[#4285F4] font-bold">
              {t('navigation.raiseTicket')}
            </Typography>
            <Typography className="font-semibold">{t('navigation.submitIssue')}</Typography>
          </div>
        </div>
        <ArrowForwardIcon
          className="cursor-pointer"
          onClick={() => window.open(process.env.REACT_APP_HELP_URL)}
        />
      </div>
    </div>
  );
};

export default Assistance;
