import PropTypes from 'prop-types';
import withReducer from 'app/store/withReducer';
import { useEffect, useState, useRef, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';
import ReactSelect from 'react-select';
import { FixedSizeList as List } from 'react-window';
import { motion } from 'framer-motion';
import { yupResolver } from '@hookform/resolvers/yup';
import axios from 'axios';
import * as yup from 'yup';
import TuneIcon from '@mui/icons-material/Tune';
import GradeIcon from '@mui/icons-material/Grade';
import { useTranslation } from 'react-i18next';
import Slider from '@mui/material/Slider';
import Button from '@mui/material/Button';
import StarRateIcon from '@mui/icons-material/StarRate';
import CloseIcon from '@mui/icons-material/Close';
import CircularProgress from '@mui/material/CircularProgress';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Collapse from '@mui/material/Collapse';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import Checkbox from '@mui/material/Checkbox';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import _ from '@lodash';
import Icon from '@mui/material/Icon';
import FuseLoading from '@fuse/core/FuseLoading';
import history from '@history';
import { useSnackbar } from 'notistack';
import { InputAdornment, Autocomplete as MTAutocomplete } from '@mui/material';
import {
  GoogleMap,
  Marker,
  LoadScript,
  StandaloneSearchBox,
  Autocomplete,
} from '@react-google-maps/api';
import { makeStyles } from '@mui/styles';
import searchBusinessData from '../../query/searchBusiness';
import queryData from '../../query/common';
import {
  getCountries,
  getStates,
  getBusinessPlaceType,
  searchBusinessNearBy,
  resetSearchBusiness,
  addBusinessBySalesPerson,
  getBusinessType,
} from './store/searchBusinessSlice';
import reducer from './store';
import { getPhysicalBusinessTypeData, getCountryData } from '../../../store/userSlice';

const useStyles = makeStyles(() => ({
  root: {
    '& .MuiAutocomplete-tag': {
      backgroundColor: '#0BA861',
      color: 'white',
      borderRadius: '8px',
    },
    '& .MuiChip-deleteIcon': {
      color: 'white',
    },
  },
}));

/**
 * Form Validation Schema
 */
const schema = yup.object().shape({
  // country: yup.string().required('Please select Country').default(),
  // state: yup.string().required('Please select state').default(),
  // businessType: yup.string().required('Please Business Type').default(),
  // city: yup.string().required('City is required'),
  // zipcode: yup
  //   .string()
  //   // .required('Zip code is required')
  //   .test('len', 'Zip code should be 5 or 9 digits', (val) => val.length === 5 || val.length === 9)
  //   .matches(/^[0-9]+$/, 'Must be only digits'),
});

const defaultValues = {
  city: '',
  businessType: '',
  zipcode: '',
  country: 0,
};

function SearchBusiness() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { control, formState, handleSubmit, reset, setError, clearErrors, setValue } = useForm({
    mode: 'onChange',
    defaultValues,
    resolver: yupResolver(schema),
  });

  const businessSearchList = useSelector(
    ({ searchBusinessReducer }) =>
      searchBusinessReducer?.searchBusiness?.searchBusinessNearByData?.data?.searchBusinessNearBy
        ?.data || ''
  );

  const { isValid, dirtyFields, errors } = formState;
  const lib = ['places'];
  const key = process.env.REACT_APP_GOOGLE_MAPS_API_KEY; // PUT GMAP API KEY HERE
  const [loading, setLoading] = useState(false);
  const [selected, setSelected] = useState([]);
  const [viewSelected, setViewSelected] = useState([]);
  const [changeBusinessType, setChangeBusinessType] = useState([]);
  const [countryId, setCountryId] = useState(1);
  const [stateName, setStateName] = useState('select State');
  const [stateId, setStateId] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [zipcodeValue, setZipcodeValue] = useState();
  const [cityValue, setCityValue] = useState();
  const [businessTypeId, setBusinessTypeId] = useState();
  const [businessName, setBusinessName] = useState('');
  const [businessTypeDataId, setBusinessTypeDataId] = useState();
  const [countryList, setCountryList] = useState();
  const [stateList, setStateList] = useState();
  const [businessList, setBusinessList] = useState();
  const [businessTypeDataList, setBusinessTypeDataList] = useState();
  const [refreshIcon, setRefreshIcon] = useState(false);
  const [refreshAddIcon, setRefreshAddIcon] = useState(false);
  const [refreshViewIcon, setRefreshViewIcon] = useState(false);
  const [open, setOpen] = useState(false);
  const [radiusValue, setRadiusValue] = useState(30);
  const [selectBusiness, setSelectBusiness] = useState([]);
  const [selectViewBusiness, setSelectViewBusiness] = useState([]);
  const [viewSelectBusiness, setViewSelectBusiness] = useState([]);
  const [currentLocation, setCurrentLocation] = useState({ lat: 34.0522342, lng: -118.2436849 });
  const [selectedMapFullAddress, setSelectedMapFullAddress] = useState();
  const [addressData, setAddressData] = useState({});
  const [autocompleteMap, setAutocompleteMap] = useState({});
  const [openDialog, setOpenDialog] = useState(false);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [sortDialogOpen, setSortDialogOpen] = useState(false);
  const [minRatingValue, setMinRatingValue] = useState();
  const [maxRatingValue, setMaxRatingValue] = useState();
  const [minReviewValue, setMinReviewValue] = useState();
  const [maxReviewValue, setMaxReviewValue] = useState();
  const [showRatingValue, setShowRatingValue] = useState(false);
  const [showReviewValue, setShowReviewValue] = useState(false);
  const [businessTypeData, setBusinessTypeData] = useState([]);
  const [searchNearByBusinessData, setSearchNearByBusinessData] = useState(businessSearchList);
  const businessTypes = useSelector(getPhysicalBusinessTypeData);
  const countriesData = useSelector(getCountryData);

  useEffect(() => {
    setCountryList(countriesData);
  }, [countriesData]);

  useEffect(() => {
    setSearchNearByBusinessData(businessSearchList);
  }, [businessSearchList]);

  const CustomMenuList = (props) => {
    const itemHeight = 35;
    const { options, children, maxHeight, getValue } = props;
    const [value] = getValue();
    const initialOffset = options.indexOf(value) * itemHeight;
    if (options.length > 0) {
      return (
        <div>
          <List
            height={maxHeight}
            itemCount={children.length}
            itemSize={itemHeight}
            initialScrollOffset={initialOffset}
          >
            {({ index, style }) => (
              <div style={style} className="bg-white relative">
                {children[index]}
              </div>
            )}
          </List>
        </div>
      );
    }
    return <div className="p-16">Loading....</div>;
  };

  const stateListData = useSelector(
    ({ searchBusinessReducer }) =>
      searchBusinessReducer?.searchBusiness?.getStatesData?.data?.getStates?.data || ''
  );

  const businessTypeList = useSelector(
    ({ searchBusinessReducer }) =>
      searchBusinessReducer?.searchBusiness?.getBusinessPlaceTypeData?.data?.getBusinessPlaceType
        ?.data || ''
  );

  const businessSearchStatus = useSelector(
    ({ searchBusinessReducer }) =>
      searchBusinessReducer?.searchBusiness?.searchBusinessNearByData?.data?.searchBusinessNearBy
  );

  const businessSearchListStatus = useSelector(
    ({ searchBusinessReducer }) =>
      searchBusinessReducer?.searchBusiness?.searchBusinessNearByData?.data?.searchBusinessNearBy ||
      ''
  );

  const addBusinessBySalesPersonStatus = useSelector(
    ({ searchBusinessReducer }) =>
      searchBusinessReducer?.searchBusiness?.addBusinessBySalesPersonData?.data
        ?.addBusinessBySalesPerson
  );

  const reverseGeocode = async (location) => {
    try {
      const response = await axios.get(
        `https://nominatim.openstreetmap.org/reverse?format=json&lat=${location.lat}&lon=${location.lng}`
      );

      if (response.data) {
        const dataAddress = {
          city: response.data.address.city ?? '',
          country: response.data.address.country ?? '',
          zipCode: response.data.address.postcode ?? '',
          state: response.data.address.state ?? '',
        };

        setAddressData(dataAddress);
        await setSelectedMapFullAddress(response.data.display_name ?? '');
        setOpenDialog(false);
      }
    } catch (error) {
      console.error('Error in reverse geocoding:', error);
    }
  };
  useEffect(() => {
    if (businessSearchList) {
      setRefreshIcon(false);
    }
  }, [businessSearchList]);

  useEffect(() => {
    if (countryId) {
      const payloadState = {
        query: queryData.getStates,
        variables: { countryId },
      };
      dispatch(getStates(payloadState));
    }
  }, [dispatch, countryId]);

  useEffect(() => {
    const payload = {
      query: searchBusinessData.getBusinessPlaceType,
    };
    dispatch(getBusinessPlaceType(payload));

    return () => {
      dispatch(resetSearchBusiness());
    };
  }, [dispatch]);

  useEffect(() => {
    const businessType = businessTypes.map((idx) => ({
      value: idx.id,
      label: idx.bType,
    }));
    setBusinessTypeData(businessType);
  }, [businessTypes]);

  useEffect(() => {
    if (stateListData && stateListData.length) {
      setStateList(stateListData);
    } else {
      setStateList();
    }
  }, [stateListData]);

  useEffect(() => {
    if (businessTypeList?.length > 0) {
      setBusinessList(businessTypeList);
    } else {
      setBusinessList([]);
    }
  }, [businessTypeList]);

  useEffect(() => {
    if (businessTypeData && businessTypeData.length) {
      setBusinessTypeDataList(
        businessTypeData.map((idx) => ({
          value: idx.id,
          label: idx.bType,
        }))
      );
    } else {
      setBusinessTypeDataList([]);
    }
  }, [businessTypeData]);

  useEffect(() => {
    if (countryId !== 0) {
      setError('country', {
        undefined,
      });
      clearErrors('country');
      setValue('country', '', { shouldDirty: true, shouldValidate: true });
    }
  }, [countryId, setError, clearErrors, setValue]);

  useEffect(() => {
    if (stateName === 'select State') {
      setError('state', {
        undefined,
      });
      clearErrors('state');
      setValue('state', '', { shouldDirty: true, shouldValidate: true });
    }
  }, [stateName, setError, clearErrors, setValue]);

  useEffect(() => {
    if (businessSearchStatus?.status === 200) {
      reset(defaultValues);
      setRefreshIcon(false);
    } else if (businessSearchStatus?.status === 400) {
      enqueueSnackbar(businessSearchStatus.message, {
        variant: 'error',
        autoHideDuration: 3000,
      });
      reset(defaultValues);
      setRefreshIcon(false);
    }
  }, [reset, dispatch, businessSearchStatus, enqueueSnackbar, closeSnackbar]);

  useEffect(() => {
    if (businessSearchListStatus?.status === 404) {
      enqueueSnackbar(businessSearchListStatus.message, {
        variant: 'error',
        autoHideDuration: 3000,
      });
    }
  }, [businessSearchListStatus, enqueueSnackbar, closeSnackbar]);

  useEffect(() => {
    const mapCountryId = countryList?.find((option) => {
      return option.name === addressData.country;
    });
    if (mapCountryId) {
      setCountryId(parseInt(mapCountryId?.id, 10));
      const mapStateId = stateList?.find((option) => {
        return option.name === addressData.state;
      });
      if (mapStateId) {
        setStateId(parseInt(mapStateId?.id, 10));
      }
    }

    setZipcodeValue(addressData?.zipCode);
    setCityValue(addressData?.city);
  }, [
    dispatch,
    addressData.country,
    addressData.state,
    addressData.zipCode,
    addressData.city,
    countryList,
    stateList,
  ]);

  useEffect(() => {
    if (addBusinessBySalesPersonStatus?.status === 200) {
      if (addBusinessBySalesPersonStatus?.data?.existedBusiness?.length > 0) {
        const message = viewSelected?.length > 1 ? 'Other business added successfully' : '';
        enqueueSnackbar(
          `${addBusinessBySalesPersonStatus?.data?.existedBusiness?.toString()} " already exits."
         ${message}`,
          {
            variant: 'info',
            autoHideDuration: 3000,
          }
        );
      } else {
        enqueueSnackbar(addBusinessBySalesPersonStatus.message, {
          variant: 'success',
          autoHideDuration: 3000,
        });
      }
      setRefreshAddIcon(false);
      history.push({
        pathname: `/selected-business`,
      });
    } else if (addBusinessBySalesPersonStatus?.status === 400) {
      enqueueSnackbar(addBusinessBySalesPersonStatus.message, {
        variant: 'error',
        autoHideDuration: 3000,
      });
      setRefreshAddIcon(false);
    }
  }, [dispatch, addBusinessBySalesPersonStatus, enqueueSnackbar, closeSnackbar, viewSelected]);

  useEffect(() => {
    if (zipcodeValue?.length > 0) {
      if ((zipcodeValue?.length !== 5 || zipcodeValue?.length !== 9) && countryId === 1) {
        setError('zipcode', {
          type: 'manual',
          message: 'zipcode should be 5 or 9 digits',
        });
      }
      if ((zipcodeValue?.length === 5 || zipcodeValue?.length === 9) && countryId === 1) {
        clearErrors('zipcode');
      }
      if (zipcodeValue?.length !== 6 && countryId === 2) {
        setError('zipcode', {
          type: 'manual',
          message: 'zipcode should be 6 digits',
        });
      }
      if (zipcodeValue?.length === 6 && countryId === 2) {
        clearErrors('zipcode');
      }
    }
  }, [clearErrors, countryId, setError, zipcodeValue?.length]);

  useEffect(() => {
    if (businessTypeId) {
      clearErrors('businessType');
    }
  }, [businessTypeId, clearErrors]);

  const visibleRows = useMemo(
    () => searchNearByBusinessData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage),
    [searchNearByBusinessData, page, rowsPerPage]
  );

  if (loading) {
    return <FuseLoading />;
  }

  function handleSelectCountry(event) {
    setCountryId(event.target.value);
  }

  function handleSelectState(event) {
    setStateId(event.target.value);
    const currentStateName = stateList?.find((option) => {
      return option.id === event.target.value;
    });
    setStateName(currentStateName?.name);
  }

  function valueText(event) {
    setRadiusValue(event.target.value);
  }

  const handleSetRadius = () => {
    setOpen(!open);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = businessSearchList.map((n) => n?.place_id);
      setSelected(newSelected);
      setSelectBusiness(businessSearchList);
      return;
    }
    setSelected([]);
    setSelectBusiness([]);
  };

  const handleViewSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = viewSelectBusiness.map((n) => n?.reviewSite?.place_id);
      setViewSelected(newSelected);
      setSelectViewBusiness(viewSelectBusiness);
      return;
    }
    setViewSelected([]);
    setSelectViewBusiness([]);
  };

  const handleClick = (event, name, data) => {
    const selectedIndex = selected.indexOf(name);
    const selectBusinessIndex = selectBusiness.findIndex((a) => a.place_id === name);
    let newSelected = [];
    let newSelectedBusiness = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    if (selectBusinessIndex === -1) {
      newSelectedBusiness = [...selectBusiness, data];
    } else if (selectBusinessIndex === 0) {
      newSelectedBusiness = newSelectedBusiness.concat(selectBusiness.slice(1));
    } else if (selectBusinessIndex === selected.length - 1) {
      newSelectedBusiness = newSelectedBusiness.concat(selectBusiness.slice(0, -1));
    } else if (selectBusinessIndex > 0) {
      newSelectedBusiness = newSelectedBusiness.concat(
        selectBusiness.slice(0, selectBusinessIndex),
        selectBusiness.slice(selectBusinessIndex + 1)
      );
    }
    setSelected(newSelected);
    setSelectBusiness(newSelectedBusiness);
  };

  const handleViewClick = (event, name, data) => {
    const selectedIndex = viewSelected.indexOf(name);
    const selectBusinessIndex = selectViewBusiness.findIndex((a) => a.place_id === name);
    let newSelected = [];
    let newSelectedBusiness = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(viewSelected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(viewSelected.slice(1));
    } else if (selectedIndex === viewSelected.length - 1) {
      newSelected = newSelected.concat(viewSelected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        viewSelected.slice(0, selectedIndex),
        viewSelected.slice(selectedIndex + 1)
      );
    }

    if (selectBusinessIndex === -1) {
      newSelectedBusiness = [...selectViewBusiness, data];
    } else if (selectBusinessIndex === 0) {
      newSelectedBusiness = newSelectedBusiness.concat(selectViewBusiness.slice(1));
    } else if (selectBusinessIndex === viewSelected.length - 1) {
      newSelectedBusiness = newSelectedBusiness.concat(selectViewBusiness.slice(0, -1));
    } else if (selectBusinessIndex > 0) {
      newSelectedBusiness = newSelectedBusiness.concat(
        selectViewBusiness.slice(0, selectBusinessIndex),
        selectViewBusiness.slice(selectBusinessIndex + 1)
      );
    }
    setViewSelected(newSelected);
    setSelectViewBusiness(newSelectedBusiness);
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;

  const isViewSelected = (id) => viewSelected.indexOf(id) !== -1;

  function StoreBusinessRecord() {
    setRefreshViewIcon(true);
    const allBusinessRecord = [];

    let businessTypeIdValue;
    businessTypeList?.forEach((option) => {
      if (option?.id === businessTypeId?.id) {
        businessTypeIdValue = option?.type;
      }
    });

    selectBusiness.map((record, i) =>
      allBusinessRecord.push({
        name: record.name,
        stateId,
        countryId,
        city: cityValue || addressData?.city || addressData?.state,
        zipCode: zipcodeValue || addressData.zipCode,
        address: record.formatted_address,
        // place_id,
        reviewSite: {
          place_id: record.place_id,
          url: record?.location_url,
          businessPlatformName: '',
          totalReviewRatings: record?.rating,
          totalReview: record?.user_ratings_total,
          geometry: record?.geometry,
          interestOfBusiness: record?.types,
        },
      })
    );
    setPage(0);
    setViewSelectBusiness(allBusinessRecord);
    setViewSelected(allBusinessRecord);
    setSelectViewBusiness(allBusinessRecord);
  }
  function viewSelectedRecord() {
    const allBusinessRecord = [];

    selectViewBusiness.forEach((record) => {
      const businessTypeIdData = changeBusinessType.find(
        (value) => value?.place_Id === record?.reviewSite?.place_id
      )?.id;
      if (businessTypeIdData) {
        allBusinessRecord.push({
          name: record.name,
          stateId,
          countryId,
          city: cityValue || addressData?.city || addressData?.state,
          zipCode: zipcodeValue || addressData.zipCode,
          address: record?.address,
          reviewSite: {
            placeId: record?.reviewSite?.place_id,
            url: '',
            businessPlatformName: '',
          },
          businessTypeId: businessTypeIdData,
        });
      } else {
        enqueueSnackbar('Please Select business type', {
          variant: 'error',
          autoHideDuration: 3000,
        });
      }
    });
    const payload = {
      query: searchBusinessData.addBusinessBySalesPerson,
      variables: {
        data: JSON.stringify(allBusinessRecord),
      },
    };
    dispatch(addBusinessBySalesPerson(payload));
  }

  function onLoad(autocompleteData) {
    setAutocompleteMap(autocompleteData);
  }
  function onPlacesChanged() {
    if (autocompleteMap !== null) {
      const place = autocompleteMap.getPlace();
      setCurrentLocation({
        lat: place.geometry?.location?.lat(),
        lng: place.geometry?.location?.lng(),
      });
      const dataAddress = {
        city:
          place.address_components.find((component) => component.types.includes('locality'))
            ?.long_name || '',
        country:
          place.address_components.find((component) => component.types.includes('country'))
            ?.long_name || '',
        zipCode:
          place.address_components.find((component) => component.types.includes('postal_code'))
            ?.long_name || '',
        state:
          place.address_components.find((component) =>
            component.types.includes('administrative_area_level_1')
          )?.long_name || '',
      };

      setAddressData(dataAddress);
      const formattedAddress = place.formatted_address;
      setSelectedMapFullAddress(formattedAddress);
      setOpenDialog(false);
    }
  }

  async function onDragChanged(event) {
    if (event) {
      const newLocation = {
        lat: event.latLng.lat(),
        lng: event.latLng.lng(),
      };
      setCurrentLocation(newLocation);
      await reverseGeocode(newLocation);
    }
  }

  function EnhancedTableToolbar(props) {
    const { numSelected } = props;
    if (numSelected > 0) {
      return (
        <Toolbar className="md:absolute left-0 right-0 md:-top-80 bg-black text-white z-10 rounded-md">
          {numSelected > 0 ? (
            <Typography color="inherit" variant="subtitle1" component="div" className="text-white">
              {numSelected} selected
            </Typography>
          ) : (
            ''
          )}
          {numSelected > 0 ? (
            <>
              {/* <Tooltip title="Delete">
                <IconButton className="text-white">
                  <DeleteIcon />
                </IconButton>
              </Tooltip> */}
              <Button
                variant="contained"
                color="secondary"
                className="w-160 md:px-20 md:text-16 font-semibold rounded-2xl disabled:text-black disabled:bg-gry-400 ml-auto"
                aria-label="Save"
                onClick={StoreBusinessRecord}
                disabled={refreshViewIcon}
                type="submit"
                size="small"
              >
                {t('business.searchBusiness.button.viewSelected')}
                {refreshViewIcon && (
                  <CircularProgress size={24} className="text-darkgreen absolute mx-auto" />
                )}
              </Button>
            </>
          ) : (
            ''
          )}
        </Toolbar>
      );
    }
  }

  function ViewEnhancedTableToolbar(props) {
    const { numSelected } = props;
    if (numSelected > 0) {
      return (
        <Toolbar className="md:absolute left-0 right-0 md:-top-80 bg-black text-white z-10 rounded-md">
          {numSelected > 0 ? (
            <Typography color="inherit" variant="subtitle1" component="div" className="text-white">
              {numSelected > 1 ? `${numSelected} Businesses` : `${numSelected} Business`}
            </Typography>
          ) : (
            ''
          )}
          {numSelected > 0 ? (
            <>
              {/* <Tooltip title="Delete">
                <IconButton className="text-white">
                  <DeleteIcon />
                </IconButton>
              </Tooltip> */}
              <Button
                variant="contained"
                color="secondary"
                className="w-160 md:px-20 md:text-16 font-semibold rounded-2xl disabled:text-white disabled:bg-gry-400 ml-auto"
                aria-label="Save"
                onClick={viewSelectedRecord}
                disabled={refreshAddIcon}
                type="submit"
                size="small"
              >
                {t('business.searchBusiness.button.saveBusiness')}
                {refreshAddIcon && (
                  <CircularProgress size={24} className="text-darkgreen absolute mx-auto" />
                )}
              </Button>
            </>
          ) : (
            ''
          )}
        </Toolbar>
      );
    }
  }

  EnhancedTableToolbar.propTypes = {
    numSelected: PropTypes.number.isRequired,
  };

  ViewEnhancedTableToolbar.propTypes = {
    numSelected: PropTypes.number.isRequired,
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  function onSubmit(searchData) {
    let businessTypeSlug;

    if (businessTypeId) {
      businessTypeList?.forEach((option) => {
        if (option?.id === businessTypeId?.id) {
          businessTypeSlug = option?.slug;
        }
      });
    }

    setRefreshIcon(true);
    const payload = {
      query: searchBusinessData.searchBusinessNearBy,
      variables: {
        radius: radiusValue * 1000,
        type: businessTypeSlug,
        search: businessName,
      },
    };

    payload.variables = { ...payload.variables, ...currentLocation };

    dispatch(searchBusinessNearBy(payload));
    setTimeout(() => {
      setSelected([]);
    }, 1000);
  }

  function EnhancedTableHead(props) {
    const { onSelectAllClick, numSelected, rowCount } = props;

    return (
      <TableHead className="bg-grey-50 rounded-md hidden md:table-header-group">
        <TableRow>
          <TableCell padding="checkbox" className="rounded-tl-md w-[3%]">
            <Checkbox
              color="primary"
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={onSelectAllClick}
              inputProps={{
                'aria-label': 'select all Business Type',
              }}
            />
          </TableCell>
          <TableCell className="text-16 font-medium" align="left w-[5%]">
            <TableSortLabel hideSortIcon> {t('appWebsites.users.column.srNo')}</TableSortLabel>
          </TableCell>
          <TableCell className="text-16 font-medium" align="left w-[17%]">
            <TableSortLabel hideSortIcon> {t('common.businessName')}</TableSortLabel>
          </TableCell>
          <TableCell className="text-16 font-medium" align="left w-[35%]">
            <TableSortLabel hideSortIcon>
              {t('business.searchBusiness.column.businessLocation')}
            </TableSortLabel>
          </TableCell>
          <TableCell className="text-16 font-medium" align="left w-[10%]">
            <TableSortLabel hideSortIcon>
              {t('business.searchBusiness.column.rating')}
            </TableSortLabel>
          </TableCell>
          <TableCell className="text-16 font-medium" align="left w-[10%]">
            <TableSortLabel hideSortIcon>
              {t('business.searchBusiness.column.noOfReviews')}
            </TableSortLabel>
          </TableCell>
          <TableCell className="text-16 font-medium" align="left w-[10%]">
            <TableSortLabel hideSortIcon>
              {t('business.searchBusiness.column.openStatus')}
            </TableSortLabel>
          </TableCell>
        </TableRow>
      </TableHead>
    );
  }

  function ViewEnhancedTableHead(props) {
    const { onSelectAllClick, numSelected, rowCount } = props;

    return (
      <TableHead className="bg-grey-50 rounded-md hidden md:table-header-group">
        <TableRow>
          {/* <TableCell padding="checkbox" className="rounded-tl-md">
            <Checkbox
              color="primary"
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={onSelectAllClick}
              inputProps={{
                'aria-label': 'select all Business Type',
              }}
            />
          </TableCell> */}
          <TableCell className="text-16 font-medium" align="left">
            <TableSortLabel hideSortIcon> {t('appWebsites.users.column.srNo')}</TableSortLabel>
          </TableCell>
          <TableCell className="text-16 font-medium" align="left">
            <TableSortLabel hideSortIcon> {t('common.businessName')}</TableSortLabel>
          </TableCell>
          <TableCell className="text-16 font-medium" align="left">
            <TableSortLabel hideSortIcon>
              {t('business.searchBusiness.column.businessLocation')}
            </TableSortLabel>
          </TableCell>
          {/* <TableCell className="text-16 font-medium" align="left">
            <TableSortLabel hideSortIcon>
              {t('business.searchBusiness.column.businessType')}
            </TableSortLabel>
          </TableCell> */}
        </TableRow>
      </TableHead>
    );
  }

  // function handleBusiness(event, placeId) {
  //   let val;
  //   businessTypeDataList.find((value) => {
  //     if (value?.value === event.value) {
  //       val = value.label;
  //     }
  //     return '';
  //   });
  //   if (val) {
  //     if (changeBusinessType.find((obj) => obj.place_Id === placeId)) {
  //       const foundIndex = changeBusinessType.findIndex((x) => x.place_Id === placeId);
  //       changeBusinessType[foundIndex] = {
  //         id: event.value,
  //         place_Id: placeId,
  //         bName: val,
  //       };
  //       setChangeBusinessType([...changeBusinessType]);
  //     } else {
  //       setChangeBusinessType([
  //         ...changeBusinessType,
  //         {
  //           id: event.value,
  //           place_Id: placeId,
  //           bName: val,
  //         },
  //       ]);
  //     }
  //   } else {
  //     const filteredArray = changeBusinessType.filter((element) => {
  //       return element.place_Id !== placeId;
  //     });
  //     setChangeBusinessType(filteredArray);
  //   }
  // }

  const handleOpenSort = () => {
    setSortDialogOpen(!sortDialogOpen);
  };

  const handleChangeReviewSliderValue = (value) => {
    if (value) {
      setMaxRatingValue(value);
    }
  };

  const handleSortApply = () => {
    setRowsPerPage(10);
    setPage(0);
    const filteredList = businessSearchList.filter((business) => {
      const isRatingInRange =
        (!minRatingValue || business.rating >= minRatingValue) &&
        (!maxRatingValue || business.rating <= maxRatingValue);

      const isReviewCountInRange =
        (!minReviewValue || business.user_ratings_total >= minReviewValue) &&
        (!maxReviewValue || business.user_ratings_total <= maxReviewValue);

      if (maxRatingValue !== 0 && maxReviewValue !== 0) {
        setShowRatingValue(true);
        setShowReviewValue(true);
        return isRatingInRange && isReviewCountInRange;
      }

      if (minRatingValue !== undefined && maxRatingValue !== 0) {
        setShowRatingValue(true);
        return isRatingInRange;
      }

      if (minReviewValue !== undefined && maxReviewValue !== 0) {
        setShowReviewValue(true);
        return isReviewCountInRange;
      }

      return true;
    });

    setSearchNearByBusinessData(filteredList);
    setSortDialogOpen(false);
  };

  const handleCancel = () => {
    setMinRatingValue();
    setMaxRatingValue();
    setMinReviewValue();
    setMaxReviewValue();
    setSearchNearByBusinessData(businessSearchList);
    setSortDialogOpen(false);
  };

  const handleCancelRating = () => {
    setRowsPerPage(10);
    setPage(0);
    setMinRatingValue();
    setMaxRatingValue();
    const filteredList = businessSearchList.filter((business) => {
      const isReviewCountInRange =
        (!minReviewValue || business.user_ratings_total >= minReviewValue) &&
        (!maxReviewValue || business.user_ratings_total <= maxReviewValue);

      const maxReview = !maxReviewValue || business.user_ratings_total <= maxReviewValue;

      if (minReviewValue !== undefined && maxReviewValue !== 0) {
        setShowReviewValue(true);
        return isReviewCountInRange;
      }
      if (maxReviewValue !== 0) {
        setShowReviewValue(true);
        return maxReview;
      }
      return true;
    });
    setSearchNearByBusinessData(filteredList);
  };

  const handleCancelReview = () => {
    setRowsPerPage(10);
    setPage(0);
    setMinReviewValue();
    setMaxReviewValue();
    const filteredList = businessSearchList.filter((business) => {
      const isRatingInRange =
        (!minRatingValue || business.rating >= minRatingValue) &&
        (!maxRatingValue || business.rating <= maxRatingValue);

      const maxRating = !maxRatingValue || business.rating <= maxRatingValue;

      if (minRatingValue !== undefined && maxRatingValue !== 0) {
        setShowRatingValue(true);
        return isRatingInRange;
      }
      if (maxRatingValue !== 0) {
        setShowRatingValue(true);
        return maxRating;
      }

      return true;
    });
    setSearchNearByBusinessData(filteredList);
  };

  return (
    <div className="p-20 lg:p-24">
      <div className="flex flex-1 w-full items-center justify-between md:mb-36 mb-20">
        <div className="flex items-center">
          <Typography
            component={motion.span}
            initial={{ x: -20 }}
            animate={{ x: 0, transition: { delay: 0.2 } }}
            delay={300}
            className="sm:flex text-20 md:text-28 font-bold"
          >
            {t('business.searchBusiness.title')}
          </Typography>
        </div>
      </div>

      {businessSearchList?.length > 0 ? (
        <>
          {viewSelectBusiness?.length > 0 ? (
            <div className="h-auto bg-white rounded-md relative shadow">
              <div>
                {' '}
                <ViewEnhancedTableToolbar numSelected={viewSelected.length} />
                <TableContainer className="overflow-x-visible">
                  <Table className="w-full" aria-labelledby="tableTitle" size="medium">
                    <ViewEnhancedTableHead
                      numSelected={viewSelected.length}
                      onSelectAllClick={handleViewSelectAllClick}
                      rowCount={viewSelectBusiness.length}
                    />
                    <TableBody className="block md:table-row-group">
                      {viewSelectBusiness &&
                        viewSelectBusiness.length > 0 &&
                        viewSelectBusiness.map((row, index) => {
                          const isItemSelectedView = isViewSelected(row?.reviewSite?.place_id);
                          const labelId = `enhanced-table-checkbox-${index}`;

                          return (
                            <TableRow
                              hover
                              className="border border-t-2 border-t-grey-100 block md:table-row"
                              role="checkbox"
                              aria-checked={isItemSelectedView}
                              tabIndex={-1}
                              key={row.place_id}
                              selected={isItemSelectedView}
                            >
                              {/* <TableCell
                                padding="checkbox"
                                onClick={(event) =>
                                  handleViewClick(event, row?.reviewSite?.place_id, row)
                                }
                                sx={{ cursor: 'pointer' }}
                              >
                                <Checkbox
                                  color="primary"
                                  checked={isItemSelectedView}
                                  inputProps={{
                                    'aria-labelledby': labelId,
                                  }}
                                />
                              </TableCell> */}
                              <TableCell
                                className="text-16 color-black font-semibold block md:table-cell md:max-w-96 py-5 md:py-16"
                                align="left"
                              >
                                {page * rowsPerPage + index + 1}
                              </TableCell>
                              <TableCell
                                className="text-16 color-black font-semibold md:max-w-136 block md:table-cell md:max-w-96 py-5 md:py-16"
                                align="left"
                              >
                                {row?.name}
                              </TableCell>
                              <TableCell
                                className="text-16 color-black font-semibold md:max-w-136 block md:table-cell md:max-w-96 py-5 md:py-16"
                                align="left"
                              >
                                {row?.address}
                              </TableCell>
                              {/* <TableCell className="text-16 color-black font-semibold" align="left">
                                <ReactSelect
                                  labelId="businessType"
                                  id="businessType"
                                  name="businessType"
                                  inputValue={inputValue}
                                  onChange={(event) => {
                                    handleBusiness(event, row?.reviewSite?.place_id);
                                  }}
                                  options={businessTypeDataList}
                                  components={{
                                    MenuList: CustomMenuList,
                                  }}
                                />
                                {errors.businessType && (
                                  <p className="text-red -mt-24 absolute">
                                    {errors.businessType.message}
                                  </p>
                                )}
                              </TableCell> */}
                            </TableRow>
                          );
                        })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
            </div>
          ) : (
            <>
              <div className="md:flex">
                <div className="bg-white rounded-md relative shadow p-16 md:w-1/4 w-full mr-40 h-fit md:mb-0 mb-20">
                  <h3 className="text-18 font-semibold border-b-2 border-solid border-darkgreen-100 pb-10">
                    {t('users.UserList.Filter.mainBtn')}
                  </h3>
                  <span role="button" className="mb-6 mt-20 flex items-center justify-between">
                    <span className="font-semibold text-14">
                      {' '}
                      {t('business.searchBusiness.labels.radius')}
                    </span>{' '}
                    <span className="font-semibold text-14">{radiusValue}km</span>
                  </span>
                  <form
                    name="serchBusiness"
                    noValidate
                    className="w-full"
                    onSubmit={handleSubmit(onSubmit)}
                  >
                    <div className="block w-full mb-24">
                      <Slider
                        aria-label="Temperature"
                        className="block pb-0"
                        defaultValue={30}
                        value={radiusValue}
                        onChange={valueText}
                        size="small"
                        min={1}
                        max={50}
                        color="secondary"
                      />
                    </div>
                    <div>
                      <InputLabel
                        id="businessTypeFilter"
                        className="font-semibold text-14 mb-10 text-black"
                      >
                        {t('business.searchBusiness.column.businessType')}
                      </InputLabel>
                      <FormControl variant="filled" className="mb-20 w-full">
                        {businessList && (
                          <MTAutocomplete
                            disablePortal
                            id="businessType"
                            options={businessList}
                            value={businessTypeId}
                            name="businessType"
                            sx={{ '& .MuiFilledInput-root': { pt: '0px' } }}
                            size="small"
                            className="w-full"
                            getOptionLabel={(option) => option?.type}
                            onChange={(event, newBusinessValue) => {
                              setBusinessTypeId(newBusinessValue);
                            }}
                            renderOption={(props, option) => (
                              <MenuItem
                                key={option.id}
                                value={option?.id}
                                sx={{ justifyContent: 'space-between' }}
                                {...props}
                              >
                                {option?.type}
                              </MenuItem>
                            )}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                variant="filled"
                                size="small"
                                placeholder={t(
                                  'business.searchBusiness.placeholder.selectBusinessAttribute'
                                )}
                              />
                            )}
                          />
                        )}
                        {errors.businessType && (
                          <p className="text-red -bottom-20 absolute">
                            {errors.businessType.message}
                          </p>
                        )}
                      </FormControl>
                    </div>
                    <div className="block mb-20">
                      <span role="button" className="mb-10 flex items-center justify-between">
                        <InputLabel htmlFor="location" className="text-14 font-semibold text-black">
                          {t('business.searchBusiness.labels.location')}
                        </InputLabel>
                        <span
                          className="text-14 font-semibold text-darkgreen"
                          aria-hidden="true"
                          onClick={() => {
                            setOpenDialog(true);
                          }}
                        >
                          {t('business.searchBusiness.labels.changeLocation')}
                        </span>
                      </span>
                      <Controller
                        control={control}
                        name="location"
                        render={({ field }) => (
                          <TextField
                            {...field}
                            label={
                              !selectedMapFullAddress ? (
                                <span className="text-black">
                                  {t('business.searchBusiness.placeholder.enterLocation')}
                                </span>
                              ) : (
                                <span className="text-black">{selectedMapFullAddress}</span>
                              )
                            }
                            sx={{ '& .MuiFilledInput-input': { pt: '8px' } }}
                            size="small"
                            disabled
                            id="location"
                            error={!!errors.location}
                            variant="filled"
                            required
                            fullWidth
                          />
                        )}
                      />
                    </div>
                    <div>
                      <InputLabel
                        htmlFor="location"
                        className="text-14 mb-10 font-semibold text-black"
                      >
                        {t('business.searchBusiness.labels.businessName')}
                      </InputLabel>
                      <Controller
                        name="businessName"
                        control={control}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            className="mb-20"
                            sx={{ '& .MuiFilledInput-input': { pt: '8px' } }}
                            placeholder={t('business.searchBusiness.labels.searchYourBusiness')}
                            value={businessName}
                            onChange={(event) => {
                              setBusinessName(event.target.value);
                            }}
                            error={!!errors.businessName}
                            size="small"
                            variant="filled"
                            fullWidth
                          />
                        )}
                      />
                    </div>
                    <div className="text-center">
                      <Button
                        variant="contained"
                        color="secondary"
                        className="mt-36 mb-36 mx-auto md:px-52 md:text-16 font-semibold disabled:text-black"
                        aria-label="Search"
                        disabled={refreshIcon}
                        type="submit"
                        size="small"
                      >
                        {t('business.searchBusiness.button.apply')}
                        {refreshIcon && (
                          <CircularProgress size={24} className="text-darkgreen absolute mx-auto" />
                        )}
                      </Button>
                    </div>
                  </form>
                </div>
                <div className="md:w-3/4 h-auto bg-white rounded-md relative shadow w-full">
                  <div className="flex items-center h-[100%] justify-center">
                    {' '}
                    {selected.length !== 0 ? (
                      <EnhancedTableToolbar numSelected={selected.length} />
                    ) : (
                      <>
                        <div className="md:absolute text-end left-0 right-0 -top-80 text-white z-10 rounded-md">
                          <div className="flex flex-col-reverse md:flex-row px-8 py-8 justify-between">
                            {!sortDialogOpen ? (
                              <div className="text-start flex gap-8 items-center">
                                {((showRatingValue && maxRatingValue) ||
                                  (showReviewValue && maxReviewValue)) && (
                                  <Typography className="text-black mt-8">
                                    {searchNearByBusinessData?.length}{' '}
                                    {t('business.searchBusiness.labels.business')}
                                  </Typography>
                                )}
                                {showRatingValue && maxRatingValue > 0 && (
                                  <div className="border border-solid border-[#cccccc] rounded-md items-center h-28 flex mt-8">
                                    <Typography className="text-black px-8">
                                      <StarRateIcon className="text-[#707070] w-[18px] pr-6" />
                                      {minRatingValue || 0} to{' '}
                                      <StarRateIcon className="text-[#707070] w-[21px] px-4" />
                                      {maxRatingValue}
                                    </Typography>
                                    <Button
                                      className="hover:bg-transparent p-0 min-w-0 min-h-0 rounded-0 h-auto px-8 border-l-1 border-solid border-[#cccccc]"
                                      onClick={() => handleCancelRating()}
                                    >
                                      <CloseIcon className="text-[#707070] w-[18px]" />
                                    </Button>
                                  </div>
                                )}
                                {showReviewValue && maxReviewValue > 0 && (
                                  <div className="border border-solid border-[#cccccc] rounded-md items-center h-28 flex mt-8">
                                    <Typography className="text-black px-8">
                                      <StarRateIcon className="text-[#707070] w-[18px] pr-6" />
                                      {minReviewValue || 0} to{' '}
                                      <StarRateIcon className="text-[#707070] w-[21px] px-4" />
                                      {maxReviewValue}
                                    </Typography>
                                    <Button
                                      className="hover:bg-transparent p-0 min-w-0 min-h-0 rounded-0 h-auto px-8 border-l-1 border-solid border-[#cccccc]"
                                      onClick={() => handleCancelReview()}
                                    >
                                      <CloseIcon className="text-[#707070] w-[18px]" />
                                    </Button>
                                  </div>
                                )}
                              </div>
                            ) : (
                              <div />
                            )}
                            <div className="bg-white w-full sm:w-[65%] md:w-[50%] lg:w-[30%] shadow px-16 rounded-md">
                              <div className="flex justify-between items-center">
                                <Typography className="text-black text-16 font-bold">
                                  Sort{' '}
                                </Typography>
                                <div className="button_width">
                                  <Button
                                    className="bg-white hover:bg-white"
                                    onClick={() => handleOpenSort()}
                                  >
                                    <TuneIcon className="text-darkgreen" />
                                  </Button>
                                </div>
                              </div>
                              {sortDialogOpen && (
                                <div className="pb-20">
                                  <hr className="text-darkgreen" />
                                  <Typography className="text-black font-bold text-start pt-8">
                                    {t('business.searchBusiness.businessRatings')}
                                  </Typography>
                                  <Slider
                                    aria-label="Temperature"
                                    className="block py-16 "
                                    value={maxRatingValue}
                                    onChange={(event, value) =>
                                      handleChangeReviewSliderValue(value)
                                    }
                                    size="small"
                                    min={1}
                                    max={5}
                                    color="secondary"
                                  />
                                  <div className="flex gap-12 pb-12">
                                    <div className="flex justify-between input_text_align">
                                      <TextField
                                        className="h-[20px]"
                                        type="number"
                                        value={minRatingValue}
                                        placeholder="0"
                                        InputProps={{
                                          style: { textAlign: 'end', border: 'none' },
                                          startAdornment: (
                                            <InputAdornment position="start">
                                              <StarRateIcon className="text-[#707070] w-[18px]" />
                                            </InputAdornment>
                                          ),
                                        }}
                                        onChange={(e) => {
                                          e.target.value = e.target.value.slice(0, 1);
                                          setMinRatingValue(parseInt(e.target.value, 10));
                                        }}
                                      />
                                    </div>
                                    <div>
                                      <Typography className="text-black pt-10">to</Typography>
                                    </div>
                                    <div className="flex justify-between input_text_align">
                                      <TextField
                                        className="h-[20px] text-end"
                                        type="number"
                                        value={maxRatingValue}
                                        placeholder="0"
                                        InputProps={{
                                          style: { textAlign: 'end', border: 'none' },
                                          startAdornment: (
                                            <InputAdornment position="start">
                                              <StarRateIcon className="text-[#707070] w-[18px]" />
                                            </InputAdornment>
                                          ),
                                        }}
                                        onChange={(e) => {
                                          e.target.value = e.target.value.slice(0, 1);
                                          setMaxRatingValue(parseInt(e.target.value, 10));
                                        }}
                                      />
                                    </div>
                                  </div>
                                  <hr className="mt-20 mb-12 text-darkgreen" />
                                  <Typography className="text-black font-bold text-start">
                                    {t('business.searchBusiness.businessReviews')}
                                  </Typography>
                                  <Slider
                                    aria-label="Temperature"
                                    className="block"
                                    defaultValue={30}
                                    value={maxReviewValue}
                                    onChange={(e) =>
                                      setMaxReviewValue(parseInt(e.target.value, 10))
                                    }
                                    size="small"
                                    min={1}
                                    max={1000}
                                    color="secondary"
                                  />
                                  <div className="flex gap-12">
                                    <div className="flex justify-between input_text">
                                      <TextField
                                        className="h-[20px]"
                                        type="number"
                                        value={minReviewValue}
                                        placeholder="0"
                                        InputProps={{
                                          style: { textAlign: 'end', border: 'none' },
                                        }}
                                        onChange={(e) => {
                                          setMinReviewValue(parseInt(e.target.value, 10));
                                        }}
                                      />
                                    </div>
                                    <div>
                                      <Typography className="text-black pt-10">to</Typography>
                                    </div>
                                    <div className="flex justify-between input_text">
                                      <TextField
                                        className="h-[20px]"
                                        type="number"
                                        value={maxReviewValue}
                                        placeholder="0"
                                        onChange={(e) => {
                                          setMaxReviewValue(parseInt(e.target.value, 10));
                                        }}
                                      />
                                    </div>
                                  </div>
                                  <hr className="mt-36 text-darkgreen" />
                                  <div className="flex justify-between items-center">
                                    <Typography className="text-black mt-12 font-bold text-start">
                                      {t('dashboard.dashboardAdmin.totalBusiness')}
                                    </Typography>
                                    <Typography className="text-black">
                                      {maxRatingValue || maxReviewValue
                                        ? searchNearByBusinessData?.length
                                        : 0}
                                    </Typography>
                                  </div>
                                  <div>
                                    <Button
                                      className="border-none hover:bg-white text-darkgreen"
                                      onClick={() => handleCancel()}
                                    >
                                      Cancel
                                    </Button>
                                    <Button
                                      className="text-white bg-darkgreen hover:bg-darkgreen border-darkgreen rounded px-40 py-8"
                                      onClick={() => handleSortApply()}
                                    >
                                      Apply
                                    </Button>
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                    {visibleRows?.length > 0 ? (
                      <TableContainer>
                        <Table className="w-full" aria-labelledby="tableTitle" size="medium">
                          <EnhancedTableHead
                            numSelected={selected.length}
                            onSelectAllClick={handleSelectAllClick}
                            rowCount={businessSearchList.length}
                          />
                          <TableBody className="block md:table-row-group">
                            {visibleRows &&
                              visibleRows.length > 0 &&
                              visibleRows.map((row, index) => {
                                const isItemSelected = isSelected(row.place_id);
                                const labelId = `enhanced-table-checkbox-${index}`;

                                return (
                                  <TableRow
                                    hover
                                    className="border border-t-2 border-t-grey-100 block md:table-row"
                                    role="checkbox"
                                    aria-checked={isItemSelected}
                                    tabIndex={-1}
                                    key={row.place_id}
                                    selected={isItemSelected}
                                  >
                                    <TableCell
                                      padding="checkbox"
                                      onClick={(event) => handleClick(event, row.place_id, row)}
                                      className="text-14 color-black font-semibold block md:table-cell md:max-w-96 py-5 md:py-16"
                                      sx={{ cursor: 'pointer' }}
                                    >
                                      <Checkbox
                                        color="primary"
                                        checked={isItemSelected}
                                        inputProps={{
                                          'aria-labelledby': labelId,
                                        }}
                                      />
                                    </TableCell>
                                    <TableCell
                                      className="text-14 color-black font-semibold block md:table-cell md:max-w-96 py-5 md:py-16"
                                      align="left"
                                    >
                                      {page * rowsPerPage + index + 1}
                                    </TableCell>
                                    <TableCell
                                      className="text-14 color-black font-semibold md:max-w-136 block md:table-cell md:max-w-96 py-5 pt-16 md:py-16"
                                      align="left"
                                    >
                                      {row?.name}
                                    </TableCell>
                                    <TableCell
                                      className="text-14 color-black font-semibold md:max-w-136 block md:table-cell md:max-w-96 py-5 pt-16 md:py-16"
                                      align="left"
                                    >
                                      {row?.formatted_address}
                                    </TableCell>
                                    <TableCell
                                      className="text-14 color-black font-semibold block md:table-cell md:max-w-96 py-5 pb-16 md:py-16"
                                      align="left"
                                    >
                                      <GradeIcon className="text-[#FFBD23]" /> {row?.rating}
                                    </TableCell>
                                    <TableCell
                                      className="text-14 color-black font-semibold block md:table-cell md:max-w-96 py-5 pb-16 md:py-16"
                                      align="left"
                                    >
                                      {row?.user_ratings_total}
                                    </TableCell>
                                    <TableCell
                                      className="text-14 color-black font-semibold block md:table-cell md:max-w-96 py-5 pb-16 md:py-16"
                                      align="left"
                                    >
                                      {row?.business_status === 'OPERATIONAL'
                                        ? 'Open'
                                        : row?.business_status === 'CLOSED_TEMPORARILY'
                                        ? 'Temporarily closed'
                                        : row?.business_status === null
                                        ? '-'
                                        : ''}
                                    </TableCell>
                                  </TableRow>
                                );
                              })}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    ) : (
                      <Typography className="text-center text-16">
                        {t('navigation.noData')}
                      </Typography>
                    )}
                  </div>
                </div>
              </div>
              <TablePagination
                sx={{
                  '.MuiInputBase-root': {
                    lineHeight: 'inherit',
                    padding: 0,
                  },
                }}
                component="div"
                count={searchNearByBusinessData.length}
                rowsPerPage={rowsPerPage}
                page={page}
                backIconButtonProps={{
                  'aria-label': 'Previous Page',
                }}
                nextIconButtonProps={{
                  'aria-label': 'Next Page',
                }}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </>
          )}
        </>
      ) : (
        <>
          <div className="bg-white rounded-md relative shadow p-24">
            <form
              name="serchBusiness"
              noValidate
              className="w-full"
              onSubmit={handleSubmit(onSubmit)}
            >
              <div className="lg:w-3/4 w-full mx-auto">
                <LoadScript
                  id="script-loader"
                  googleMapsApiKey={key}
                  libraries={lib}
                  loadingElement={<div> {t('business.searchBusiness.labels.loading')}</div>}
                >
                  <GoogleMap
                    mapContainerStyle={{
                      height: '300px',
                      width: '100%',
                      position: 'relative',
                    }}
                    className="custom-height rounded-md"
                    zoom={8}
                    center={currentLocation}
                  >
                    <Marker position={currentLocation} onDragEnd={onDragChanged} draggable />
                    <Autocomplete onLoad={onLoad} onPlaceChanged={onPlacesChanged} zIndex="999">
                      <StandaloneSearchBox zIndex="999">
                        <input
                          type="text"
                          className="xs-googleSearch"
                          value={selectedMapFullAddress}
                          onChange={(e) => setSelectedMapFullAddress(e.target.value)}
                          placeholder={t('business.searchBusiness.placeholder.findYourLocation')}
                          style={{
                            boxSizing: `border-box`,
                            border: `1px solid transparent`,
                            width: `240px`,
                            height: `32px`,
                            padding: `0 12px`,
                            borderRadius: `3px`,
                            boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
                            fontSize: `14px`,
                            outline: `none`,
                            textOverflow: `ellipses`,
                            position: 'absolute',
                            margin: '0 auto',
                            top: '14px',
                            left: '0',
                            right: '0',
                            zIndex: '1',
                          }}
                        />
                      </StandaloneSearchBox>
                    </Autocomplete>
                  </GoogleMap>
                </LoadScript>
              </div>
              <div className="lg:w-3/4 w-full mx-auto mt-24 max-w-512">
                {/* <div className="inline-block"> */}
                {/* <FormControl variant="filled" className="mb-24 w-full">
                    <InputLabel id="country">Country</InputLabel>
                    <Select
                      labelId="country"
                      id="country"
                      size="small"
                      value={countryId}
                      onChange={(e) => {
                        handleSelectCountry(e);
                      }}
                    >
                      <MenuItem value={0} disabled>
                        Select Country
                      </MenuItem>
                      {countryList?.map((option) => (
                        <MenuItem key={option.id} value={option.id}>
                          {option.name}
                        </MenuItem>
                      ))}
                    </Select>
                    {errors.country && (
                      <p className="text-red -mt-24 absolute">{errors.country.message}</p>
                    )}
                  </FormControl> */}
                {/* {countryId !== 0 ? (
                    <FormControl variant="filled" className="mb-20 w-full">
                      <InputLabel id="state">State</InputLabel>
                      <Select
                        labelId="state"
                        id="state"
                        size="small"
                        required
                        value={stateId}
                        onChange={(e) => {
                          handleSelectState(e);
                        }}
                      >
                        <MenuItem value="select State" disabled>
                          Select State
                        </MenuItem>
                        {stateList?.map((option) => (
                          <MenuItem key={option.id} value={option.id}>
                            {option.name}
                          </MenuItem>
                        ))}
                      </Select>
                      {errors.state && (
                        <p className="text-red -mt-24 absolute">{errors.state.message}</p>
                      )}
                    </FormControl>
                  ) : (
                    ''
                  )} */}
                {/* <div className="relative">
                    <Controller
                      name="zipcode"
                      control={control}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          className="mb-20"
                          label="Zipcode"
                          error={!!errors.zipcode}
                          InputLabelProps={zipcodeValue && { shrink: true }}
                          onChange={(e) => {
                            handleZipcode(e);
                          }}
                          value={zipcodeValue}
                          type="number"
                          size="small"
                          variant="filled"
                          fullWidth
                        />
                      )}
                    />
                    {errors.zipcode && (
                      <p className="text-red -bottom-0 absolute">{errors.zipcode.message}</p>
                    )}
                  </div> */}
                {/* <Controller
                    name="city"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        className="mb-20"
                        label="City"
                        value={cityValue}
                        InputLabelProps={cityValue && { shrink: true }}
                        onChange={(e) => {
                          setCityValue(e?.target?.value);
                        }}
                        type="text"
                        size="small"
                        error={!!errors.city}
                        variant="filled"
                        fullWidth
                      />
                    )}
                  /> */}
                {/* </div> */}
                <div>
                  <span
                    role="button"
                    className="bg-grey-200 rounded-sm px-12 h-44 mb-20 flex items-center justify-between"
                    tabIndex={0}
                    onClick={handleSetRadius}
                    onKeyDown={handleSetRadius}
                  >
                    {radiusValue}
                    {t('business.searchBusiness.labels.kmRadius')}
                    {open ? <ExpandLess /> : <ExpandMore />}
                  </span>
                  <Collapse in={open} timeout="auto" unmountOnExit>
                    <span className="bg-grey-200 rounded-sm px-12 py-20  flex items-center justify-between mb-24">
                      <div className="block w-full">
                        <div className="block font-medium">
                          {t('business.searchBusiness.labels.radius')}
                        </div>
                        <Slider
                          aria-label="Temperature"
                          className="block pb-0"
                          defaultValue={30}
                          value={radiusValue}
                          onChange={valueText}
                          size="small"
                          min={1}
                          max={50}
                          color="secondary"
                        />
                      </div>
                    </span>
                  </Collapse>
                  <FormControl variant="filled" className="mb-20 w-full">
                    <>
                      {businessList && (
                        <MTAutocomplete
                          disablePortal
                          id="businessType"
                          options={businessList}
                          value={businessTypeId}
                          name="businessType"
                          size="small"
                          className="w-full"
                          sx={{
                            margin: '8px 0',
                          }}
                          getOptionLabel={(option) => option?.type}
                          onChange={(event, newPet) => {
                            setBusinessTypeId(newPet);
                          }}
                          renderOption={(props, option) => (
                            <MenuItem
                              key={option.id}
                              value={option?.id}
                              sx={{ justifyContent: 'space-between' }}
                              {...props}
                            >
                              {option?.type}
                              {/* {selected ? <Icon color="info">done</Icon> : null} */}
                            </MenuItem>
                          )}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              variant="filled"
                              size="small"
                              label={
                                !businessTypeId ? (
                                  <span className="text-black mb-[5px]">
                                    {t(
                                      'business.searchBusiness.placeholder.selectBusinessAttribute'
                                    )}
                                  </span>
                                ) : undefined
                              }
                              sx={{
                                '& .MuiFilledInput-root': {
                                  paddingTop: '8px !important',
                                  marginBottom: '4px',
                                  height: '44px',
                                },
                              }}
                            />
                          )}
                        />
                      )}

                      {errors.businessType && (
                        <p className="text-red -bottom-20 absolute">
                          {errors.businessType.message}
                        </p>
                      )}
                    </>
                  </FormControl>
                  <Controller
                    name="search"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        className="mb-20"
                        label={
                          <span className="text-black">
                            {t('business.searchBusiness.labels.searchYourBusiness')}
                          </span>
                        }
                        onChange={(event) => {
                          setBusinessName(event.target.value);
                        }}
                        error={!!errors.search}
                        size="small"
                        variant="filled"
                        fullWidth
                      />
                    )}
                  />
                </div>
              </div>
              <div className="text-center">
                <Button
                  variant="contained"
                  color="secondary"
                  className="mt-24 sm:min-w-xs mx-auto md:px-52 md:text-16 font-semibold disabled:text-black rounded-lg"
                  aria-label="Search"
                  disabled={refreshIcon}
                  type="submit"
                  size="large"
                >
                  {t('signUpPage.buttons.next')} <Icon className="text-16 ml-5">east</Icon>
                  {refreshIcon && (
                    <CircularProgress size={24} className="text-darkgreen absolute mx-auto" />
                  )}
                </Button>
              </div>
            </form>
          </div>
        </>
      )}

      {businessSearchList?.length > 0 && (
        <>
          {openDialog && (
            <Dialog
              classes={{
                paper: 'max-w-640',
              }}
              open={openDialog}
              onClose={(event, reason) => {
                if (reason !== 'backdropClick') {
                  setOpenDialog(false);
                }
              }}
              fullWidth
              maxWidth="xs"
            >
              <DialogTitle className="p-0 text-center">
                <Icon
                  onClick={() => {
                    setOpenDialog(false);
                  }}
                  className="text-24 absolute top-12 right-12 text-grey-400 font-normal cursor-pointer hover:text-gray-700 focus:text-gray-700"
                >
                  cancel
                </Icon>
              </DialogTitle>
              {/* <DialogContent className="p-0"> */}
              {/* <DialogContentText
                  id="alert-dialog-description"
                  className="text-16 font-medium text-center text-black w-full"
                > */}
              <LoadScript
                id="script-loader"
                googleMapsApiKey={key}
                libraries={lib}
                loadingElement={<div>{t('business.searchBusiness.labels.loading')}</div>}
              >
                <GoogleMap
                  mapContainerStyle={{
                    height: '300px',
                    width: '100%',
                    position: 'relative',
                  }}
                  className="custom-height rounded-md"
                  zoom={8}
                  center={currentLocation}
                >
                  <Marker position={currentLocation} onDragEnd={onDragChanged} draggable />
                  <Autocomplete onLoad={onLoad} onPlaceChanged={onPlacesChanged} zIndex="999">
                    <StandaloneSearchBox zIndex="999">
                      <input
                        type="text"
                        value={selectedMapFullAddress}
                        onChange={(e) => setSelectedMapFullAddress(e.target.value)}
                        placeholder={t('business.searchBusiness.placeholder.findYourLocation')}
                        style={{
                          boxSizing: `border-box`,
                          border: `1px solid transparent`,
                          width: `240px`,
                          height: `32px`,
                          padding: `0 12px`,
                          borderRadius: `3px`,
                          boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
                          fontSize: `14px`,
                          outline: `none`,
                          textOverflow: `ellipses`,
                          position: 'absolute',
                          margin: '0 auto',
                          top: '14px',
                          left: '0',
                          right: '0',
                          zIndex: '1',
                        }}
                      />
                    </StandaloneSearchBox>
                  </Autocomplete>
                </GoogleMap>
              </LoadScript>
              {/* </DialogContentText> */}
              {/* </DialogContent> */}
            </Dialog>
          )}
        </>
      )}
    </div>
  );
}

export default withReducer('searchBusinessReducer', reducer)(SearchBusiness);
