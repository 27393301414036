import { Button, Typography } from '@mui/material';
import { selectDashboardData } from 'app/store/userSlice';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import ConformationDialogBox from 'src/app/main/CommonComponent/ConformationDialogBox';
import {
  FBConnectAds,
  getDecryptData,
  getEncryptedData,
  handleApiRequest,
} from 'src/app/main/common/common';
import FacebookPagesPopUp from 'src/app/main/CommonComponent/FacebookPagesPopUp';
import { useSnackbar } from 'notistack';
import quickAdsQuery from 'src/app/main/query/quickAds';
import NoBusinessPopUp from '../NoBusinessPopUp';
import InstagramConnectPopUp from '../InstagramConnectPopUp';

const AdsSocialConnect = () => {
  const { t } = useTranslation();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const userCurrentBusinessDetails = useSelector(selectDashboardData);
  const appKey = process.env.REACT_APP_WABA_APP_ID;
  const [cardsData, setCardsData] = useState([]);
  const [pageData, setPageData] = useState();
  const [pages, setPages] = useState(false);
  const [instagramConnected, setInstagramConnected] = useState(false);
  const [loading, setLoading] = useState(false);
  const [openPopUp, setOpenPopUp] = useState(false);
  const [delPopUp, setDelPopUp] = useState(false);
  const [noPage, setNoPage] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const getMetaAdsDetails = useCallback(async () => {
    try {
      const payload = {
        query: quickAdsQuery.getMetaAdsDetails,
        variables: {
          userBusinessId: Number(userCurrentBusinessDetails?.id),
        },
      };
      const result = await handleApiRequest(payload);
      if (result?.getMetaAdsDetails?.status === 200) {
        setPageData(JSON.parse(getDecryptData(result?.getMetaAdsDetails?.data)));
      }
    } catch (err) {
      console.error('Error fetching data:', err);
    }
  }, [userCurrentBusinessDetails?.id]);

  useEffect(() => {
    if (userCurrentBusinessDetails?.id) {
      getMetaAdsDetails();
    }
  }, [getMetaAdsDetails, userCurrentBusinessDetails?.id]);

  const fetchCardDetails = useCallback(async () => {
    try {
      const payload = {
        query: quickAdsQuery.getAdsConnectApis,
        variables: {
          businessId: Number(userCurrentBusinessDetails?.id),
        },
      };
      const result = await handleApiRequest(payload);
      if (result?.getAdsConnectApis?.status === 200) {
        const cards = result?.getAdsConnectApis?.data || [];
        setCardsData(cards);
      }
    } catch (error) {
      console.error('Error fetching card details:', error);
    }
  }, [userCurrentBusinessDetails?.id]);

  useEffect(() => {
    if (userCurrentBusinessDetails?.id) {
      fetchCardDetails();
    }
  }, [fetchCardDetails, userCurrentBusinessDetails?.id]);

  const disconnectAds = async () => {
    try {
      const payload = {
        query: quickAdsQuery.disconnectAds,
        variables: {
          quickHubAdsAccountId: Number(userCurrentBusinessDetails?.quickHubAdsAccountId),
        },
      };
      const result = await handleApiRequest(payload);
      if (result?.disconnectAds?.status === 200) {
        setDelPopUp(false);
        getMetaAdsDetails();
        fetchCardDetails();
        enqueueSnackbar(result?.disconnectAds?.message, {
          variant: 'success',
          autoHideDuration: 2000,
        });
      }
    } catch (err) {
      console.error('Error fetching data:', err);
    }
  };

  const saveMetaAdsDetails = useCallback(
    async (response, page, instagramConnect) => {
      const data = getEncryptedData(
        JSON.stringify({
          pageDetails: {
            name: page?.name,
            id: page?.id,
            access_token: page?.access_token,
            category: page?.category,
            profilePictureUrl: page?.profilePictureUrl,
            isVerified: page?.isVerified,
          },
          accountId: response?.accountId,
          adsAccountId: response?.adsAccountId,
          accountDetails: {
            name: response?.accountDetails?.name,
            account_id: response?.accountDetails?.account_id,
            currency: response?.accountDetails?.currency,
            account_status: response?.accountDetails?.account_status,
            business_name: response?.accountDetails?.business_name,
            id: response?.accountDetails?.id,
          },
          tokenDetails: {
            access_token: response?.tokenDetails?.access_token,
            token_type: response?.tokenDetails?.token_type,
          },
          isFacebookConnect: true,
          isInstagramConnect: instagramConnect,
          userBusinessId: Number(userCurrentBusinessDetails?.id),
        })
      );

      try {
        const payload = {
          query: quickAdsQuery.saveMetaAdsDetails,
          variables: { data: String(data) },
        };
        const result = await handleApiRequest(payload);
        if (result?.saveMetaAdsDetails?.status === 200) {
          fetchCardDetails();
          enqueueSnackbar(result?.saveMetaAdsDetails?.message, {
            variant: 'success',
            autoHideDuration: 2000,
          });
        }
      } catch (err) {
        console.error('Error fetching data:', err);
      }
    },
    [enqueueSnackbar, fetchCardDetails, userCurrentBusinessDetails?.id]
  );

  const checkInstagramConnectivity = useCallback(
    async (response, pageData1) => {
      if (!response) {
        console.error('Response is undefined or null.');
        return;
      }
      try {
        const payload = {
          query: quickAdsQuery.checkInstagramConnectivity,
          variables: {
            userBusinessId: Number(userCurrentBusinessDetails?.id),
            accessToken: response?.access_token,
            pageId: response?.id,
          },
        };
        const result = await handleApiRequest(payload);
        if (result?.checkInstagramConnectivity?.status === 200) {
          setInstagramConnected(result?.checkInstagramConnectivity?.isConnected);
          saveMetaAdsDetails(pageData1, response, result?.checkInstagramConnectivity?.isConnected);
          result?.checkInstagramConnectivity?.isConnected
            ? setOpenPopUp(false)
            : setOpenPopUp(true);
        } else {
          console.error(
            result?.checkInstagramConnectivity?.errorMessage,
            result?.checkInstagramConnectivity?.errorCode
          );
        }
      } catch (err) {
        console.error('Error fetching data:', err);
      }
    },
    [saveMetaAdsDetails, userCurrentBusinessDetails?.id]
  );

  const verifyMetaAdsCode = useCallback(
    async (response) => {
      setIsLoading(true);
      try {
        const payload = {
          query: quickAdsQuery.verifyMetaAdsCode,
          variables: {
            accessToken: response?.accessToken,
          },
        };

        const result = await handleApiRequest(payload);
        if (result?.verifyMetaAdsCode?.status === 200) {
          setPageData(JSON.parse(getDecryptData(result?.verifyMetaAdsCode?.data)));
          setPages(true);
        } else {
          enqueueSnackbar(
            result?.verifyMetaAdsCode?.errorMessage || result?.verifyMetaAdsCode?.message,
            {
              variant: 'error',
              autoHideDuration: 2000,
            }
          );
          if (
            result?.verifyMetaAdsCode?.errorMessage ||
            result?.verifyMetaAdsCode?.message === 'Page Not Found'
          ) {
            setNoPage(true);
          }
          console.error(
            result?.verifyMetaAdsCode?.errorMessage,
            result?.verifyMetaAdsCode?.errorCode
          );
        }
      } catch (err) {
        console.error('Error fetching data:', err);
      }
      setIsLoading(false);
    },
    [enqueueSnackbar]
  );

  const handleLogin = (socialName) => {
    if (socialName === 'Facebook') {
      FBConnectAds(socialName, verifyMetaAdsCode, appKey);
    }
  };

  return (
    <div className=" bg-gray-A500 lg:p-24 p-16 h-full">
      <Typography className="text-20 sm:text-28 font-bold">
        {' '}
        {t('quickAds.socialConnect')}{' '}
      </Typography>
      <div className="max-w-512  p-20">
        {pageData && cardsData?.some((item) => item?.isConnect) && (
          <div className=" sm:flex gap-20 p-20 border-b-1 border-dashed border-gray justify-center items-center bg-white">
            <div className="sm:mb-0 mb-10">
              <img
                src={pageData?.pageDetails?.profilePictureUrl}
                alt="Preview"
                className="h-192 w-192 shadow-2"
              />
            </div>
            <div>
              <p className=" mb-16 py-5 px-9 text-15 font-medium bg-quick-review-100 text-quick-hub border-quick-hub border-2 rounded-md text-center max-w-96">
                {' '}
                {t('quickSocial.connected')}
              </p>
              <p className="text-20 font-semibold">{pageData?.pageDetails?.name} </p>
              <p className="text-18 font-medium text-gray">{pageData?.pageDetails?.category}</p>
              <p className="text-18 text-quick-social font-medium">
                {pageData?.accountDetails?.name}
              </p>
            </div>
          </div>
        )}

        {cardsData &&
          cardsData?.length > 0 &&
          cardsData?.map((data, index) => (
            <div
              key={index}
              className=" flex  justify-between p-11 items-center rounded-md mb-10 bg-white "
            >
              <div className="flex gap-10 justify-center items-center">
                <img src={data?.image} alt={data?.name} className="h-24 w-24" />
                <p className="text-18 font-medium"> {data?.name}</p>
              </div>
              {data?.isConnect ? (
                <div>
                  <img src="/assets/images/icon/Frame.svg" alt="Connected" className="w-20 h-20" />{' '}
                </div>
              ) : (
                <div className="justify-center flex items-center bg-white">
                  <Button
                    size="small"
                    className="rounded mt-10 block text-black bg-quick-social-50"
                    variant="outlined"
                    color="quickSocial"
                    component="span"
                    onClick={() => {
                      if (data?.name === 'Facebook') {
                        handleLogin(data?.name);
                      }
                      if (data?.name === 'Instagram') {
                        window.open(
                          'https://www.facebook.com/business/m/meta-business-suite?content_id=Q4fzhXrAfv8acZw&ref=sem_smb&utm_term=meta%20business%20suite&gclid=Cj0KCQiAs5i8BhDmARIsAGE4xHzzppBx8and5kIPS-0y3CzayvAGnA0PEt3kyemoSiORJ9Uu_My-r-IaAlnkEALw_wcB&gad_source=1',
                          '_blank'
                        );
                      }
                    }}
                  >
                    {t('quickSocial.connect')}
                  </Button>
                </div>
              )}
            </div>
          ))}
        {pageData && cardsData?.some((item) => item?.isConnect) && (
          <Button
            size="large"
            className="rounded mt-10 w-full "
            variant="outlined"
            color="quickSocial"
            component="span"
            onClick={() => setDelPopUp(true)}
          >
            {t('quickSocial.disconnect')}
          </Button>
        )}
      </div>
      {pageData?.pageDetails && pages && (
        <FacebookPagesPopUp
          pageData={pageData}
          open={pages}
          onClose={() => setPages(false)}
          saveMetaAdsDetails={saveMetaAdsDetails}
          checkInstagramConnectivity={checkInstagramConnectivity}
          setPages={setPages}
        />
      )}
      {noPage && (
        <NoBusinessPopUp
          open={noPage}
          onClose={() => setNoPage(false)}
          setNoPage={setNoPage}
          from="quickADS"
        />
      )}
      {openPopUp && (
        <InstagramConnectPopUp pageData={pageData} open={openPopUp} setOpenPopUp={setOpenPopUp} />
      )}

      {delPopUp && (
        <ConformationDialogBox
          open={delPopUp}
          onClose={() => setDelPopUp(false)}
          onDelete={() => disconnectAds()}
          additionalInfo={t('quickAds.disconnectContent')}
          confirmationText={t('quickSocial.areYouSure')}
          deleteImage="/assets/images/icon/disConnectAds.svg"
          backgroundColor="quick-social"
          textColor="quick-social"
          buttonText={t('quickSocial.disconnect')}
        />
      )}
    </div>
  );
};

export default AdsSocialConnect;
