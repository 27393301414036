import {
  Button,
  CircularProgress,
  Drawer,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import React, { useState } from 'react';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { t } from 'i18next';
import { useLocation } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import history from '@history';
import LabelOrBusinessDrawer from '../../reports/LabelOrBusinessDrawer';
import quickHub from '../../../../../query/quickHub';
import { handleApiRequest } from '../../../../../common/common';
import userService from '../../../../../../services';
import manageBusinessData from '../../../../../query/manageBusiness';
import ErrorPopUp from '../../common/ErrorPopUp';

const AddEditServices = (props) => {
  const {
    selectLabelOrBusiness,
    setSelectLabelOrBusiness,
    setAddServices,
    services,
    openDrawer,
    setOpenDrawer,
    editServiceData,
    setEditServiceData,
    fetchActiveGoogleServices,
  } = props;
  const priceData = ['Free', 'Fixed'];
  const location = useLocation();
  const [openBusinessDrawer, setOpenBusinessDrawer] = useState(false);
  const [refreshIcon, setRefreshIcon] = useState(false);
  const loginUserData = userService.getUserData();
  const [openErrorDialog, setOpenErrorDialog] = useState(false);
  const [failedRequests, setFailedRequests] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const [customServiceDetails, setCustomServiceDetails] = useState({
    name: editServiceData ? editServiceData?.displayName : '',
    price: editServiceData ? editServiceData?.price?.units : '',
    priceType: editServiceData ? (editServiceData?.price?.units ? 'Fixed' : 'Free') : 'Free',
    description: editServiceData ? editServiceData?.description : '',
    isCustom: true,
  });

  const onSubmit = async () => {
    setRefreshIcon(true);
    try {
      const payload = {
        query: quickHub.updateGoogleBusinessDetailsForBusinesses,
        variables: {
          businessIds: selectLabelOrBusiness?.map((business) => business?.id),
          mask: 'services',
          data: JSON.stringify({
            service: customServiceDetails?.name,
            description: customServiceDetails?.description,
            price: {
              currencyCode: loginUserData?.countryCode === 2 ? 'INR' : 'USD',
              units: parseInt(customServiceDetails?.price, 10),
            },
            isCustom: true || customServiceDetails?.isCustom,
          }),
        },
      };
      const result = await handleApiRequest(payload);
      setRefreshIcon(false);
      if (result?.updateGoogleBusinessDetailsForBusinesses?.data?.failedRequests?.length > 0) {
        setOpenErrorDialog(true);
        setFailedRequests(result?.updateGoogleBusinessDetailsForBusinesses?.data?.failedRequests);
      }
      if (result?.updateGoogleBusinessDetailsForBusinesses?.status === 200) {
        if (location?.state?.isBulk) {
          history.push('quick-hub/primaryDetails', location?.state?.activeTab);
          enqueueSnackbar(result?.updateGoogleBusinessDetailsForBusinesses?.message, {
            variant: 'success',
            autoHideDuration: 3000,
          });
        } else {
          setAddServices(false);
          setEditServiceData('');
          setCustomServiceDetails({
            name: '',
            price: '',
            priceType: 'Free',
            description: '',
            isCustom: true,
          });
          fetchActiveGoogleServices();
          enqueueSnackbar(result?.updateGoogleBusinessDetailsForBusinesses?.message, {
            variant: 'success',
            autoHideDuration: 3000,
          });
        }
      } else if (result?.updateGoogleBusinessDetailsForBusinesses?.message !== 'Bad request') {
        enqueueSnackbar(result?.updateGoogleBusinessDetailsForBusinesses?.message, {
          variant: 'error',
          autoHideDuration: 3000,
        });
      }
    } catch (error) {
      setRefreshIcon(false);
      console.error('Error updating business hours:', error);
    }
  };

  const onEditService = async () => {
    try {
      setRefreshIcon(true);
      const payload = {
        query: manageBusinessData.addAndDeleteServicesInGoogle,
        variables: {
          businessId: Number(selectLabelOrBusiness?.[0]?.id),
          locationId: selectLabelOrBusiness?.[0]?.locationId?.split('/')[1],
          data: {
            addServices: [
              {
                service: customServiceDetails?.name,
                description: customServiceDetails?.description,
                price: {
                  currencyCode: loginUserData?.countryCode === 2 ? 'INR' : 'USD',
                  units: parseInt(customServiceDetails?.price, 10),
                },
                isCustom: customServiceDetails?.isCustom,
              },
            ],
          },
        },
      };
      const result = await handleApiRequest(payload);
      setRefreshIcon(false);
      if (result?.addAndDeleteServicesInGoogle?.status === 200) {
        fetchActiveGoogleServices();
        setOpenDrawer();
        setCustomServiceDetails({
          name: '',
          price: '',
          priceType: '',
          description: '',
          isCustom: false,
        });
      } else {
        enqueueSnackbar(result?.addAndDeleteServicesInGoogle?.message, {
          variant: 'error',
          autoHideDuration: 3000,
        });
      }
    } catch (error) {
      console.error('Error adding services:', error);
      setRefreshIcon(false);
    }
  };

  const handleRedirect = () => {
    selectLabelOrBusiness?.length === 1
      ? services?.length > 0
        ? setAddServices(false)
        : history.push('quick-hub/primaryDetails', location?.state?.activeTab)
      : history.push('quick-hub/primaryDetails', location?.state?.activeTab);
  };

  const handleBackArrow = () => {
    setCustomServiceDetails({
      name: '',
      price: '',
      priceType: 'Free',
      description: '',
      isCustom: true,
    });
    setOpenDrawer(false);
    setAddServices(false);
  };

  return (
    <div className="">
      {!openDrawer && (
        <>
          <Typography className="font-semibold text-16 md:text-24 flex items-center gap-4">
            {' '}
            <Button className="w-16 h-16 min-w-16 min-h-16" onClick={() => handleRedirect()}>
              <ArrowBackIcon />
            </Button>
            {t('primaryDetails.addService')}
          </Typography>
          <div className="md:w-[900px]">
            <div className="bg-white rounded-md mt-16 w-full">
              <div className="p-20">
                <div className="mt-8">
                  <Typography className="font-semibold text-14 md:text-18 mb-8">
                    {t('primaryDetails.serviceName')}
                  </Typography>
                  <TextField
                    className="w-full font-semibold"
                    placeholder={t('primaryDetails.enterServiceName')}
                    size="small"
                    value={customServiceDetails?.name}
                    onChange={(e) =>
                      setCustomServiceDetails({ ...customServiceDetails, name: e.target.value })
                    }
                  />
                </div>
                <div className="flex gap-16 mt-20">
                  <div className="w-full">
                    <Typography className="font-semibold text-14 md:text-18 mb-8">
                      {t('listManagement.priceType')}
                    </Typography>
                    <Select
                      className="w-full"
                      value={customServiceDetails?.priceType}
                      size="small"
                      displayEmpty
                      onChange={(e) =>
                        setCustomServiceDetails({
                          ...customServiceDetails,
                          priceType: e.target.value,
                        })
                      }
                      renderValue={(value) =>
                        value || `${t('listManagement.selectType')} (${t('signUpPage.optional')})`
                      }
                    >
                      {priceData?.map((item, index) => {
                        return (
                          <MenuItem key={index} value={item}>
                            {item}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </div>
                  <div className="w-full">
                    <Typography className="font-semibold text-14 md:text-18 mb-8">
                      {t('listManagement.price')}
                    </Typography>
                    <TextField
                      className="w-full"
                      size="small"
                      type="number"
                      value={customServiceDetails?.price}
                      disabled={customServiceDetails?.priceType !== 'Fixed'}
                      placeholder={t('primaryDetails.enterAmount')}
                      onChange={(e) =>
                        setCustomServiceDetails({ ...customServiceDetails, price: e.target.value })
                      }
                    />
                  </div>
                </div>
                <div className="w-full mt-20">
                  <Typography className="font-semibold text-14 md:text-18 mb-8">
                    {t('listManagement.description')}
                  </Typography>
                  <TextField
                    className="w-full"
                    multiline
                    rows={5}
                    value={customServiceDetails?.description}
                    placeholder={t('primaryDetails.addDescription')}
                    size="small"
                    onChange={(e) =>
                      setCustomServiceDetails({
                        ...customServiceDetails,
                        description: e.target.value,
                      })
                    }
                  />
                </div>
              </div>
              {location?.state?.isBulk && (
                <div className="pb-20">
                  <hr className="my-8 border border-gray" />
                  <div className="flex justify-between items-center px-20 pt-12">
                    <Typography className="font-semibold text-18">
                      {t('primaryDetails.selectBusiness')}
                    </Typography>
                    <div className="flex gap-12 items-center">
                      <Typography className="font-medium text-16">
                        {`${t('primaryDetails.selectedBusiness')} (${
                          selectLabelOrBusiness?.length
                        })`}
                      </Typography>
                      <Button
                        className="rounded-md w-112"
                        variant="outlined"
                        color="secondary"
                        onClick={() => setOpenBusinessDrawer(true)}
                      >
                        {t('common.edit')}
                      </Button>
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div className="text-end mt-16">
              <Button
                className="rounded-md w-160 font-semibold text-16"
                variant="contained"
                color="secondary"
                onClick={() => onSubmit()}
                disabled={!customServiceDetails?.name || refreshIcon}
              >
                {t('common.save')}
                {refreshIcon && (
                  <CircularProgress className="text-quick-review absolute mx-auto" size={24} />
                )}
              </Button>
            </div>
          </div>
        </>
      )}
      <Drawer className="" anchor="right" open={openDrawer} transitionDuration={700}>
        <div className="md:w-400">
          <div className="px-12 py-16">
            <Typography className="font-semibold text-16 md:text-20 flex items-center gap-4">
              {' '}
              <Button className="w-24 h-24 min-w-4 min-h-24" onClick={() => handleBackArrow()}>
                <ArrowBackIcon />
              </Button>
              {t('primaryDetails.editService')}
            </Typography>
          </div>
          <hr />
          <div className="p-16">
            <div className="mt-8">
              <Typography className="font-semibold text-14 md:text-18 mb-8">
                {t('primaryDetails.serviceName')}
              </Typography>
              <TextField
                className="w-full font-semibold"
                placeholder={t('primaryDetails.enterServiceName')}
                size="small"
                disabled
                value={customServiceDetails?.name}
                onChange={(e) =>
                  setCustomServiceDetails({ ...customServiceDetails, name: e.target.value })
                }
              />
            </div>
            <div className="flex gap-16 mt-20">
              <div className="w-full">
                <Typography className="font-semibold text-14 md:text-18 mb-8">
                  {t('listManagement.priceType')}
                </Typography>
                <Select
                  className="w-full"
                  value={customServiceDetails?.priceType}
                  size="small"
                  displayEmpty
                  onChange={(e) =>
                    setCustomServiceDetails({ ...customServiceDetails, priceType: e.target.value })
                  }
                  renderValue={(value) =>
                    value || `${t('listManagement.selectType')} (${t('signUpPage.optional')})`
                  }
                >
                  {priceData?.map((item, index) => {
                    return (
                      <MenuItem key={index} value={item}>
                        {item}
                      </MenuItem>
                    );
                  })}
                </Select>
              </div>
              <div className="w-full">
                <Typography className="font-semibold text-14 md:text-18 mb-8">
                  {t('listManagement.price')}
                </Typography>
                <TextField
                  className="w-full"
                  size="small"
                  type="number"
                  value={customServiceDetails.price}
                  disabled={customServiceDetails?.priceType !== 'Fixed'}
                  placeholder={t('primaryDetails.enterAmount')}
                  onChange={(e) =>
                    setCustomServiceDetails({ ...customServiceDetails, price: e.target.value })
                  }
                />
              </div>
            </div>
            <div className="w-full mt-20">
              <Typography className="font-semibold text-14 md:text-18 mb-8">
                {t('listManagement.description')}
              </Typography>
              <TextField
                className="w-full"
                multiline
                rows={5}
                value={customServiceDetails?.description}
                placeholder={t('primaryDetails.addDescription')}
                size="small"
                onChange={(e) =>
                  setCustomServiceDetails({ ...customServiceDetails, description: e.target.value })
                }
              />
            </div>
          </div>
          <div className="text-end mt-16 mr-16">
            <Button
              className="rounded-md w-160 font-semibold text-16"
              variant="contained"
              color="secondary"
              onClick={() => onEditService()}
              disabled={refreshIcon}
            >
              {t('common.save')}
              {refreshIcon && (
                <CircularProgress className="text-quick-review absolute mx-auto" size={24} />
              )}
            </Button>
          </div>
        </div>
      </Drawer>
      <LabelOrBusinessDrawer
        openBusinessDrawer={openBusinessDrawer}
        setOpenBusinessDrawer={setOpenBusinessDrawer}
        setSelectLabelOrBusiness={setSelectLabelOrBusiness}
        selectLabelOrBusiness={selectLabelOrBusiness}
        groupType="business"
        isGoogleConnected
        activeTab={location.state?.activeTab}
      />
      <ErrorPopUp
        openErrorDialog={openErrorDialog}
        setOpenErrorDialog={setOpenErrorDialog}
        failedRequests={failedRequests}
        setFailedRequests={setFailedRequests}
      />
    </div>
  );
};

export default AddEditServices;
