import { Button, Dialog, DialogContent, Icon, Typography } from '@mui/material';
import { t } from 'i18next';
import React from 'react';

const ErrorPopUp = (props) => {
  const { openErrorDialog, setOpenErrorDialog, failedRequests, setFailedRequests } = props;

  const handleCancel = () => {
    setOpenErrorDialog(false);
    setFailedRequests([]);
  };

  return (
    <Dialog
      classes={{
        paper:
          'm-24 sm:p-28 p-10 border-t-8 border-solid border-t-darkgreen border-darkgreen rounded-md',
      }}
      open={openErrorDialog}
      fullWidth
      maxWidth="sm"
    >
      <Icon
        onClick={() => handleCancel()}
        className="text-24 absolute top-12 right-12 text-grey-400 font-normal cursor-pointer hover:text-gray-700 focus:text-gray-700"
      >
        cancel
      </Icon>
      <img className="m-auto" src="assets/images/business/error.png" alt="" />
      <Typography className="font-bold text-20 text-center py-12">
        {t('primaryDetails.unableMakeChanges')}
      </Typography>
      <Typography className="font-semibold text-16 mb-16">
        {t('primaryDetails.unableMakeChangesMessage')}
      </Typography>
      {failedRequests?.length > 0 &&
        failedRequests?.map((business, index) => {
          return (
            <div
              className="sm:flex gap-12 bg-gray-A500 border-b rounded-md p-6 border-solid border-gray"
              key={index}
            >
              <div className="max-w-144">
                <img
                  className="rounded-md min-w-128 h-76"
                  src={business?.profileURL || '/assets/images/agency/businessprofile.png'}
                  alt=""
                />
              </div>
              <div>
                <Typography className="font-bold text-20">{business?.name}</Typography>
                <Typography className="font-medium text-14">{business?.address}</Typography>
                <Typography className="text-red font-medium text-14">
                  Error: {business?.reason}
                </Typography>
              </div>
            </div>
          );
        })}
      <Typography className="text-14 font-semibold text-black w-full my-28">
        {t('primaryDetails.tryAgainMessage')}
      </Typography>
      <div className="text-center">
        <Button
          className="rounded-md w-144"
          variant="contained"
          color="secondary"
          onClick={() => handleCancel()}
        >
          {t('primaryDetails.gotIt')}
        </Button>
      </div>
    </Dialog>
  );
};

export default ErrorPopUp;
