import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import history from '@history';
import { selectDashboardData } from 'app/store/userSlice';
import { useSelector } from 'react-redux';
import React, { useEffect, useState } from 'react';
import { findExpireDay } from '../common/common';

const DashBoardPopUp = (props) => {
  const userCurrentBusinessDetails = useSelector(selectDashboardData);
  const [packageType, setPackageType] = useState('');
  const [content, setContent] = useState('');
  const handleGoBack = () => {
    history.push('/business/dashboard/');
  };
  const {
    open,
    filteredData,
    productData,
    onDelete,
    refresh,
    backgroundColor,
    textColor,
    from,
    productId,
  } = props;
  const { t } = useTranslation();
  const filteredProductData = productData?.filter((product) => product?.name === from);

  useEffect(() => {
    if (filteredProductData) {
      const product = filteredProductData[0];
      const expired = findExpireDay(product?.expireDate) < 0;
      if (product?.isFreeTrial && expired) {
        setPackageType('Free Trial');
        setContent(t('DashboardPopUp.freeTrailExp'));
      } else {
        setPackageType('Subscription');
        setContent(t('DashboardPopUp.subscriptionExp'));
      }
    }
  }, [filteredProductData, t]);

  return (
    <Dialog
      open={open}
      aria-labelledby="delete-confirmation-dialog"
      classes={{
        paper: `m-24 p-28 business-type-dropdown text-center rounded-md`,
      }}
      PaperProps={{ style: { maxWidth: '550px', overflowY: 'auto', maxHeight: '90vh' } }}
    >
      <DialogContent>
        <div className=" relative mt-32 mb-10">
          <img
            src="/assets/images/icon/dashboardPopUp.png"
            className="mx-auto mb-12 max-h-288 max-w-288"
            alt="..."
          />
        </div>
        <DialogTitle id="delete-confirmation-dialog" className="text-24 font-600 text-red mb-10">
          {t('quickHubDashboard.your')} {packageType} {t('DashboardPopUp.for')} {from}{' '}
          {t('DashboardPopUp.hasEnded')}
        </DialogTitle>
        <DialogContent className="p-0 mb-28">
          <div className="block w-full flex justify-center items-center">
            <Typography className="text-18 font-400 text-black w-11/12">{content}</Typography>
          </div>
        </DialogContent>
        <DialogActions className="p-0 flex justify-between gap-14">
          <Button
            className={`md:text-16 font-medium rounded-md !border-${backgroundColor} text-${textColor} w-full max-w-256`}
            variant="outlined"
            color="secondary"
            onClick={handleGoBack}
            style={{
              borderColor: backgroundColor,
            }}
          >
            Go Back
          </Button>

          <Button
            className={`md:text-16 font-medium rounded-md bg-${backgroundColor} w-full max-w-256`}
            variant="contained"
            disabled={refresh}
            color="secondary"
            onClick={() => {
              history.push('selectProduct/', {
                packageBundle: [productId],
                intervalType: 'month',
                paymentDetails: 'subscription',
                isBusinessAdded: !!userCurrentBusinessDetails?.id,
              });
            }}
          >
            {t('quickHubDashboard.upgradeNow')}
            {refresh && <CircularProgress size={24} className="text-darkgreen absolute mx-auto" />}
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
};

export default DashBoardPopUp;
