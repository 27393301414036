function Loading() {
  return (
    <div className="flex flex-1 flex-col items-center justify-center p-24 bg-white">
      <div className="logo">
        <img width="200" src="assets/images/logo/logo.png" alt="logo" />
      </div>
      <div id="spinner">
        <div className="bounce1 !bg-quick-review" />
        <div className="bounce1 !bg-quick-chat" />
        <div className="bounce2 !bg-quick-social" />
        <div className="bounce3 !bg-quick-campaign" />
      </div>
    </div>
  );
}

export default Loading;
