import Landing from './Landing';
import WelCome from './WelCome';

const LandingConfig = {
  settings: {
    layout: {
      config: {
        navbar: {
          display: false,
        },
        toolbar: {
          display: false,
        },
        footer: {
          display: false,
        },
        leftSidePanel: {
          display: false,
        },
        rightSidePanel: {
          display: false,
        },
      },
    },
  },
  routes: [
    {
      path: 'landing',
      element: <Landing />,
    },
    {
      path: 'welcome',
      element: <WelCome />,
    },
  ],
};

export default LandingConfig;
