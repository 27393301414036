import { Button, Icon, Typography } from '@mui/material';
import { GoogleMap, LoadScript, Circle, OverlayView } from '@react-google-maps/api';
import { useSnackbar } from 'notistack';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { useLocation } from 'react-router-dom';
import { t } from 'i18next';
import manageBusinessData from '../../../query/manageBusiness';
import { selectDashboardData } from '../../../../store/userSlice';
import {
  capitalizeFirstLetter,
  formatDateWithTimeZone,
  formatRating,
  handleApiRequest,
} from '../../../common/common';

const ScanHistoryDetails = (props) => {
  const { historyData, setIsHistoryDetails } = props;
  const key = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;
  const lib = ['places'];
  const [selectCircleValue, setSelectCircleValue] = useState();
  const [zoomLevel, setZoomLevel] = useState(12);
  const [currentLocation, setCurrentLocation] = useState({
    lat: 34.0522342,
    lng: -118.2436849,
  });
  const userCurrentBusinessDetails = useSelector(selectDashboardData);
  const { enqueueSnackbar } = useSnackbar();
  const [circles, setCircles] = useState([]);
  const [businessOwnerData, setBusinessOwnerData] = useState();
  const [reviewData, setReviewData] = useState();
  const [physicalBusinessTypeList, setPhysicalBusinessTypeList] = useState([]);
  const initialRadius = 600;
  const mapRef = useRef(null);

  useEffect(() => {
    const findBusinessOwnerData = historyData?.rankerJson?.find(
      (item) => item?.is_self_listing === true
    );
    setBusinessOwnerData(findBusinessOwnerData);
  }, [historyData?.rankerJson]);

  useEffect(() => {
    if (localStorage.getItem('physicalBusinessType')) {
      setPhysicalBusinessTypeList(JSON.parse(localStorage?.getItem('physicalBusinessType')));
    }
  }, []);

  const fetchGridData = useCallback(async () => {
    if (historyData?.queryParams?.latitude) {
      try {
        const payload = {
          query: manageBusinessData.getGridPoints,
          variables: {
            latitude: historyData?.queryParams?.latitude,
            longitude: historyData?.queryParams?.longitude,
            radius: parseInt(historyData?.queryParams?.radius, 10),
            gridSize: parseInt(historyData?.queryParams?.grid_size, 10),
          },
        };
        const result = await handleApiRequest(payload);
        if (result?.getGridPoints?.status === 200) {
          const data = result?.getGridPoints?.data?.map(([lat, lng]) => ({ lat, lng }));
          const selfRankData = historyData?.rankHistory?.points;
          const updatedCircleArray = data.map((item, index) => ({
            ...item,
            self_rank: selfRankData[index]?.self_rank,
          }));
          setCircles(updatedCircleArray);
        } else {
          enqueueSnackbar(result?.getGridPoints?.message, {
            variant: 'error',
            autoHideDuration: 2000,
          });
        }
      } catch (error) {
        console.error('Error fetching grid data:', error);
      }
    }
  }, [enqueueSnackbar, historyData?.queryParams, historyData?.rankHistory?.points]);

  useEffect(() => {
    fetchGridData();
  }, [fetchGridData]);

  useEffect(() => {
    const reviewPlatform = userCurrentBusinessDetails?.reviewplatform;
    const findDefaultPlatform = reviewPlatform?.find(
      (item) => parseInt(item.reviewPlatformId, 10) === 2
    );
    setReviewData(findDefaultPlatform);
    const convertParse = findDefaultPlatform?.geometry && JSON.parse(findDefaultPlatform?.geometry);
    setCurrentLocation({
      lat: convertParse?.location?.lat || 34.0522342,
      lng: convertParse?.location?.lng || -118.2436849,
    });
  }, [userCurrentBusinessDetails?.reviewplatform]);

  const handleLoad = (map) => {
    mapRef.current = map;
    if (window.google && window.google.maps) {
      const bounds = new window.google.maps.LatLngBounds();
      circles?.forEach((circle) => {
        bounds.extend(new window.google.maps.LatLng(circle.lat, circle.lng));
      });
      map.fitBounds(bounds);
      window.google.maps.event.addListenerOnce(map, 'idle', () => {
        const center = map.getCenter();
        const scale = 2 ** map.getZoom();

        const worldCoordinateCenter = map.getProjection().fromLatLngToPoint(center);
        const pixelOffset = new window.google.maps.Point(100 / scale || 0, 0);
        const newCenter = map
          .getProjection()
          .fromPointToLatLng(
            new window.google.maps.Point(
              worldCoordinateCenter.x - pixelOffset.x,
              worldCoordinateCenter.y - pixelOffset.y
            )
          );

        map.setCenter(newCenter);
      });
      map.addListener('zoom_changed', () => {
        setZoomLevel(map.getZoom());
      });
    }
  };

  const calculateRadius = (index) => {
    const factor = 2 ** (zoomLevel - 12);
    return selectCircleValue === index ? initialRadius / (factor * 0.75) : initialRadius / factor;
  };

  const createOverlayContent = (rank, i) => {
    return (
      <div className="text-white text-16 text-center flex items-center justify-center h-full font-semibold">
        {rank}
      </div>
    );
  };

  return (
    <div className="p-12 sm:p-40 bg-white border rounded-md">
      <Typography className="font-bold text-24 pb-20">
        <Button
          className="min-w-28 max-w-28 hover:bg-white m-auto text-center"
          onClick={() => setIsHistoryDetails(false)}
        >
          <ArrowBackIosIcon className="text-center" />
        </Button>{' '}
        {t('businessCompetitor.geoGridReport')}
      </Typography>
      <Typography className="font-bold text-gray text-16">
        <span className="bg-orange-200 px-8 rounded">
          {formatDateWithTimeZone(historyData.cts, 'DD MMM YYYY')}
        </span>
      </Typography>
      <hr className="mt-12 border-2 border-gray" />
      <div className="pt-28">
        <Typography className="font-semibold text-xl pb-10">
          {t('businessCompetitor.targetKeyword')} :{' '}
          <span className="font-[700]">{historyData?.queryParams?.keyword}</span>
        </Typography>
        <Typography className="font-semibold text-xl pb-10">
          {t('businessCompetitor.gridSize')} :{' '}
          <span className="font-bold">
            {' '}
            {historyData?.queryParams?.grid_size} x {historyData?.queryParams?.grid_size} grid
          </span>
        </Typography>
        <Typography className="font-semibold text-xl pb-10">
          {t('businessCompetitor.radius')} :{' '}
          <span className="font-bold">{historyData?.queryParams?.radius}</span>
        </Typography>
        <Typography className="font-semibold text-xl pb-10">
          {t('businessCompetitor.centerOfGrid')} :{' '}
          <span className="font-bold">
            {historyData?.queryParams?.latitude}, {historyData?.queryParams?.longitude}
          </span>
        </Typography>
        <Typography className="font-semibold text-xl pb-10">
          {t('businessCompetitor.searchPerformedOn')} :{' '}
          <span className="font-bold">
            {formatDateWithTimeZone(historyData.cts, 'MMM D, YYYY')}
          </span>
        </Typography>
        <Typography className="font-semibold text-xl pb-10">
          {t('businessCompetitor.channelUseForSearchResult')} :{' '}
          <span className="font-bold">{t('businessCompetitor.googleMaps')} </span>
        </Typography>
        <div className="bg-quick-review-100 my-28 border border-gray rounded-md p-16 mb-16 lg:w-3/4">
          <div className="flex justify-between">
            <div>
              <div className="flex gap-16 items-center">
                <div className="w-40 h-40 text-20 font-bold rounded-full bg-[#b91c1c] flex items-center justify-center text-white">
                  {businessOwnerData ? businessOwnerData?.rank : '20+'}
                </div>
                <div>
                  <Typography className="font-bold text-14 sm:text-16 max-w-144 sm:max-w-256 md:max-w-512">
                    {userCurrentBusinessDetails?.name}
                    <span className="bg-orange text-white px-12 ml-6 py-2 text-12 rounded-full">
                      You
                    </span>
                  </Typography>
                  <Typography className="py-4 font-semibold text-gray-600 text-14">
                    {
                      physicalBusinessTypeList?.find(
                        (item) => item?.id === userCurrentBusinessDetails?.businessTypeId
                      )?.bType
                    }
                  </Typography>
                  <Typography className="py-4 font-semibold text-gray-600 text-14 max-w-144 sm:max-w-256 md:max-w-512">
                    {userCurrentBusinessDetails?.address}
                  </Typography>
                </div>
              </div>
            </div>
            <Typography className="font-semibold text-center flex text-gray-600 text-16 py-2">
              <Icon className="text-yellow-800 text-16 mt-4 mr-4">star</Icon>{' '}
              {reviewData?.totalRatingStars} . {formatRating(reviewData?.totalReview)}
            </Typography>
          </div>
          <hr className="border-b-1 text-black" />
          <div className="flex justify-center pt-8 m-auto text-center">
            <div className="flex gap-16 sm:gap-84 md:gap-192 item-center">
              <Typography className="font-bold text-16 text-gray-800">
                {t('businessCompetitor.avgRank')} :{' '}
                {businessOwnerData ? businessOwnerData?.rank : '20+'}
              </Typography>
              <Typography className="font-bold text-16 text-gray-800">
                {t('businessCompetitor.distFromPoint')} : --
              </Typography>
            </div>
          </div>
        </div>
        <div className="flex items-center gap-24 mt-4">
          <Typography className="font-semibold text-xl">
            {' '}
            {t('businessCompetitor.topKeywords')}:{' '}
          </Typography>
          <Button className="bg-quick-review hover:bg-quick-review px-24 text-white font-semibold text-16">
            {capitalizeFirstLetter(historyData?.queryParams?.keyword)}
          </Button>
        </div>
        <div className="mt-24 lg:w-3/4">
          <LoadScript
            id="script-loader"
            googleMapsApiKey={key}
            libraries={lib}
            loadingElement={<div className="text-center">Loading...</div>}
          >
            {circles?.length > 0 && (
              <GoogleMap
                mapContainerStyle={{
                  height: '600px',
                  width: '100%',
                }}
                zoom={12}
                center={currentLocation}
                options={{
                  disableDefaultUI: false,
                  draggable: true,
                  scrollwheel: false,
                  zoomControl: false,
                  disableDoubleClickZoom: true,
                  gestureHandling: 'none',
                  fullscreenControl: false,
                  mapTypeControl: false,
                  streetViewControl: false,
                }}
                onLoad={handleLoad}
              >
                {circles?.map((circle, index) => {
                  return (
                    <div>
                      <Circle
                        className="relative"
                        key={index}
                        center={{ lat: circle.lat, lng: circle.lng }}
                        radius={calculateRadius(index)}
                        options={{
                          fillColor: circle?.self_rank
                            ? circle?.self_rank < 6
                              ? '#22c55e'
                              : circle.self_rank < 11
                              ? '#84cc16'
                              : circle.self_rank < 16
                              ? '#fb923c'
                              : '#b91c1c'
                            : '#6b7280',
                          fillOpacity: 1,
                          strokeColor:
                            selectCircleValue === index + 1
                              ? '#fff'
                              : circle?.self_rank
                              ? circle?.self_rank < 6
                                ? '#22c55e'
                                : circle.self_rank < 11
                                ? '#84cc16'
                                : circle.self_rank < 16
                                ? '#fb923c'
                                : '#b91c1c'
                              : '#6b7280',
                          strokeOpacity: 1,
                          strokeWeight: 4,
                        }}
                      />
                      <OverlayView
                        position={{ lat: circle.lat, lng: circle.lng }}
                        mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
                      >
                        {createOverlayContent(circle.self_rank, index + 1)}
                      </OverlayView>
                    </div>
                  );
                })}
              </GoogleMap>
            )}
          </LoadScript>
        </div>
        <div className="lg:w-3/4">
          {historyData?.rankerJson?.length > 0 && (
            <>
              <Typography className="font-semibold text-xl my-24">
                {t('businessCompetitor.topCompetitors')}
              </Typography>
              {historyData?.rankerJson?.map((item, i) => {
                return (
                  <div className="bg-white border border-gray rounded-md p-16 mb-16" key={i}>
                    <div className="flex justify-between">
                      <div>
                        <div className="flex gap-16 items-center">
                          <div
                            className={`w-40 h-40 text-20 font-bold rounded-full ${
                              item?.rank < 6
                                ? `bg-[#22c55e]`
                                : item.rank < 11
                                ? 'bg-[#84cc16]'
                                : item.rank < 16
                                ? 'bg-[#fb923c]'
                                : 'bg-[#b91c1c]'
                            } flex items-center justify-center text-white`}
                          >
                            {item?.rank}
                          </div>
                          <div>
                            <Typography className="font-bold text-14 sm:text-16 max-w-144 sm:max-w-256 md:max-w-512">
                              {item?.name}
                            </Typography>
                            <Typography className="py-4 font-semibold text-gray-600 text-14">
                              {item?.primary_category}
                            </Typography>
                            <Typography className="py-4 font-semibold text-gray-600 text-14 max-w-144 sm:max-w-256 md:max-w-512">
                              {item?.address}
                            </Typography>
                          </div>
                        </div>
                      </div>
                      <Typography className="font-semibold text-center flex text-gray-600 text-16 py-2">
                        <Icon className="text-yellow-800 text-16 mt-4 mr-4">star</Icon>{' '}
                        {item?.rating} . {item?.total_reviews}
                      </Typography>
                    </div>
                    <hr className="border-b-1 text-black" />
                    <div className="flex justify-center pt-8 m-auto text-center">
                      <div className="flex gap-16 sm:gap-84 md:gap-192 item-center">
                        <Typography className="font-bold text-14 md:text-16 text-gray-800">
                          {t('businessCompetitor.avgRank')} : {item?.avg_rank}
                        </Typography>
                        <Typography className="font-bold text-14 md:text-16 text-gray-800">
                          {t('businessCompetitor.distFromPoint')} :{' '}
                          {item?.distance_of_point_from_center?.toFixed(2)}
                        </Typography>
                      </div>
                    </div>
                  </div>
                );
              })}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default ScanHistoryDetails;
