import { TablePagination } from '@mui/material';

const Pagination = (props) => {
  const {
    totalCount,
    rowsPerPage,
    page,
    handleChangePage,
    handleChangeRowsPerPage,
    rowsPerPageOptions,
  } = props;
  return (
    <TablePagination
      sx={{
        '.MuiInputBase-root': {
          lineHeight: 'inherit',
          padding: 0,
        },
      }}
      component="div"
      count={totalCount}
      rowsPerPage={rowsPerPage}
      page={page - 1}
      backIconButtonProps={{
        'aria-label': 'Previous Page',
      }}
      nextIconButtonProps={{
        'aria-label': 'Next Page',
      }}
      onPageChange={handleChangePage}
      onRowsPerPageChange={handleChangeRowsPerPage}
      rowsPerPageOptions={rowsPerPageOptions}
    />
  );
};

export default Pagination;
