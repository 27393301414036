import { Tab, Tabs, Typography } from '@mui/material';
import { t } from 'i18next';
import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import Businesses from '../businesses/Businesses';

const PrimaryDetails = () => {
  const tabData = [
    {
      id: 1,
      label: t('primaryDetails.workingHours'),
      buttonName: t('primaryDetails.setWorkingHour'),
      url: 'quick-hub/openingAndClosingHour',
      value: 'WORKING_HOURS',
    },
    {
      id: 2,
      label: t('primaryDetails.services'),
      buttonName: t('primaryDetails.addServices'),
      url: 'quick-hub/services',
      value: 'SERVICES',
    },
    {
      id: 3,
      label: t('primaryDetails.attributes'),
      buttonName: t('primaryDetails.addAttributes'),
      url: 'quick-hub/attributes',
      value: 'ATTRIBUTES',
    },
    {
      id: 4,
      label: t('primaryDetails.menu'),
      buttonName: t('primaryDetails.addMenu'),
      url: 'quick-hub/menu',
      value: 'MENU',
    },
    {
      id: 5,
      label: t('primaryDetails.images'),
      buttonName: t('primaryDetails.addImage'),
      url: 'quick-hub/addImagesAndVideos',
      value: 'IMAGES',
    },
    {
      id: 6,
      label: t('primaryDetails.videos'),
      buttonName: t('primaryDetails.addVideos'),
      url: 'quick-hub/addImagesAndVideos',
      value: 'VIDEOS',
    },
    {
      id: 7,
      label: t('primaryDetails.profilePhotos'),
      buttonName: t('primaryDetails.addProfilePhoto'),
      url: 'quick-hub/addImagesAndVideos',
      value: 'PROFILE_PHOTO',
    },
    {
      id: 8,
      label: t('primaryDetails.coverPhoto'),
      buttonName: t('primaryDetails.addCoverPhoto'),
      url: 'quick-hub/addImagesAndVideos',
      value: 'COVER_PHOTO',
    },
  ];
  const location = useLocation();
  const [activeTab, setActiveTab] = useState(location?.state?.id || 1);

  return (
    <div className="bg-gray-A500 min-h-screen">
      <Typography className="text-20 sm:text-28 font-bold pt-16 sm:pt-16 px-16 sm:px-24">
        {t('navigation.primaryDetails')}
      </Typography>
      <div className="p-16 sm:p-24">
        <Tabs
          value={activeTab}
          onChange={(e, newValue) => setActiveTab(newValue)}
          // textColor="quickReview"
          indicatorColor="secondary"
          aria-label="secondary tabs example"
        >
          {tabData?.map((tab, index) => {
            return (
              <Tab
                className={`sm:text-20 font-medium p-0 sm:mr-36 mr-16 ${
                  Number(activeTab) === Number(tab?.id) ? '!text-quick-hub' : 'text-black'
                }`}
                value={tab?.id}
                label={tab?.label}
                key={index}
              />
            );
          })}
        </Tabs>
        <hr className="border-1 border-solid !border-gray" />
      </div>
      <Businesses primaryDetailsScreen activeTab={tabData?.find((tab) => tab?.id === activeTab)} />
    </div>
  );
};

export default PrimaryDetails;
