import { useTimeout } from '@fuse/hooks';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import { useState } from 'react';
import clsx from 'clsx';
import Box from '@mui/material/Box';

function FuseLoading(props) {
  const [showLoading, setShowLoading] = useState(!props.delay);

  useTimeout(() => {
    setShowLoading(true);
  }, props.delay);

  return (
    <div
      className={clsx(
        'flex flex-1 flex-col items-center justify-center p-24 bg-gray-A500',
        !showLoading && 'hidden'
      )}
    >
      <div className="logo">
        <img width="200" src="assets/images/logo/logo.png" alt="logo" />
      </div>
      <Box
        id="spinner"
        sx={{
          '& > .bounce1': {
            backgroundColor: '#2DB67C',
          },
          '& > .bounce2': {
            backgroundColor: '#EBB32F',
          },
          '& > .bounce3': {
            backgroundColor: '#219EBC',
          },
          '& > .bounce4': {
            backgroundColor: '#8B78DC',
          },
        }}
      >
        <div className="bounce1" />
        <div className="bounce2" />
        <div className="bounce3" />
        <div className="bounce4" />
      </Box>
    </div>
  );
}

FuseLoading.propTypes = {
  delay: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
};

FuseLoading.defaultProps = {
  delay: false,
};

export default FuseLoading;
