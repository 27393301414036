const getQuickSocialChannelWiseData = ` 
query($businessId: Int, $domain: String){
	getQuickSocialChannelWiseData(businessId: $businessId, domain: $domain) {
    status
    message
    data {
      reviewPlatformId
      name
      logo
      isConnected
      pageId
    }
	}
  }  
`;

const getFacebookPageDetails = `query ($accessToken: String!, $userId: String){
  getFacebookPageDetails(accessToken: $accessToken, userId: $userId) {
    status
    message
    data {
      id
      name
      accessToken
    }
  }
}`;

const getFacebookBusiness = `query ($access_token: String!){
  getFacebookBusiness(access_token: $access_token) {
    status
    message
    data
  }
}`;

const saveFacebookAuthToken = ` 
mutation($accessToken: String!, $facebookUserId: String!, $pageId: String!, $pageName: String!, $userBusinessId: Int!){
	saveFacebookAuthToken(accessToken: $accessToken, facebookUserId: $facebookUserId, pageId: $pageId, pageName: $pageName, userBusinessId: $userBusinessId) {
    status
    message
	}
  }  
`;

const saveInstagramBusiness = ` 
mutation ($access_token: String!, $pageId: String!, $userBusinessId: Int!){
  saveInstagramBusiness(access_token: $access_token, pageId: $pageId, userBusinessId: $userBusinessId) {
    message
    status
  }
}`;

const suggestionPostV2 = ` query ($pageNumber: Int, $pageSize: Int, $platformId: Int!, $rating: Int, $userBusinessId: Int!){
  suggestionPostV2(pageNumber: $pageNumber, pageSize: $pageSize, platformId: $platformId, rating: $rating, userBusinessId: $userBusinessId) {
    status
    message
    totalCount
    data {
      editableText{
key
value
}
      postJsonData
      htmlTemplate
      rating
      reviewId
      sharePostImage
    }
  }
}
`;

const getQuickSocialSchedulePost = `query($userBusinessId: Int!, $pageSize:Int , $pageNumber: Int) {
  getQuickSocialSchedulePost(userBusinessId: $userBusinessId, pageSize: $pageSize, pageNumber: $pageNumber){
    status
    message
    data {
      id
      sharedChannels{
        id
        logo
      }
      scheduledDate
      isScheduleDone
      postData{
        imageUrl
        caption
      }
      editableText{
        key
        value
      }
      html
    }
    }
  }`;
const getQuickSocialBrandHealthMatrices = ` 
query($mediaChannelId: Int!, $userBusinessId: Int!){
	getQuickSocialBrandHealthMatrices(mediaChannelId: $mediaChannelId, userBusinessId: $userBusinessId) {
    status
    message
    data{
      brandHealthData
    }
	}
  }  
`;

const getQuickSocialEngagementMetrics = ` 
query($mediaChannelId: Int!, $userBusinessId: Int!){
	getQuickSocialEngagementMetrics(mediaChannelId: $mediaChannelId, userBusinessId: $userBusinessId) {
    status
    message
    data{
      engagementData
    }
	}
  }  
`;

const getQuickSocialMatrices = ` 
query($mediaChannelId: Int!, $userBusinessId: Int!){
	getQuickSocialMatrices(mediaChannelId: $mediaChannelId, userBusinessId: $userBusinessId) {
    status
    message
    data{
      followerGrowthData{
        key
        value
      }
      summary{
        key
        value
      }
      title
    }
	}
  }  
`;

const getAllQuickSocialProfileDetails = `
query ($userBusinessId: Int!, $mediaChannelId: Int!){
  getAllQuickSocialProfileDetails(userBusinessId: $userBusinessId, mediaChannelId: $mediaChannelId) {
    status
    message
    data {
      id
      name
      profilePictureUrl
      userName
      followersCount
      followsCount
      totalPosts
      fbPageUrl
      fbPageCategory
      fbPageAbout
      emails
      igId
      igFacebookPageId
      ldVanityName
      ldOrganizationId
      ldStaffCountRange
    }
  }
}`;

const disconnectInstagramAndFacebook = `
mutation ($isFacebook: Boolean!, $isInstagram: Boolean!, $userBusinessId: Int!){
  disconnectInstagramAndFacebook(isFacebook: $isFacebook, isInstagram: $isInstagram, userBusinessId: $userBusinessId){
    message
    status
  }
}`;

const getAllFacebookChannelData = ` 
query($userBusinessId: Int!, $pageSize: Int, $pageNumber: Int){
	getAllFacebookChannelData(userBusinessId: $userBusinessId, pageSize: $pageSize, pageNumber: $pageNumber) {
    status
    message
    totalCount
      data{
      id
      isLike
      pageName
      profilePictureUrl
      uts
      postId
      createdTime
      description
      media {
        image
        permalink_url
      }
      activity {
        likes
        comments
        shares
      }
      insights {
        title
        value
      }
    }
	}
  }  
`;

const getInstagramPosts = `
query ($userBusinessId: Int!, $pageSize: Int, $pageNumber: Int){
  getInstagramPosts(userBusinessId: $userBusinessId, pageSize: $pageSize, pageNumber: $pageNumber){
    data{
      id
      postUrl
      userName
      userProfile
      postId
      mediaUrl
      mediaType
      caption
      activity{
        comments_count
        impression
        like_count
      }
      createTime
     insights{
      title
      value
    }
    }
    message
    status
    totalCount
  }
}`;

const getPostCommentsAndSubCommentsForFacebook = `
query ($postId:Int! $userBusinessId:Int! ){
getPostCommentsAndSubCommentsForFacebook(postId:$postId userBusinessId:$userBusinessId 
){
    status
    message
    totalCount
    data {
      id
      commentId
      message
      createdTime
      isAuthor
      isLike
      commenter {
        name
        picture
      }
      activity {
        likes
      }
      media {
        type
        image
        url
      }
      replies {
        id
        commentId
        message
        createdTime
        isAuthor
        isLike
        commenter {
          name
          picture
        }
        activity {
          likes
        }
        media {
          type
          image
          url
        }
        replies {
          id
          commentId
          message
          createdTime
          isAuthor
          isLike
          commenter {
            name
            picture
          }
          activity {
            likes
          }
          media {
            type
            image
            url
          }
        }
      }
    }
  }}`;

const commentOrReplyOnFacebookPost = ` mutation ($userBusinessId: Int!, $media: Upload, $message: String, $postId: Int, $commentId: Int) {
    commentOrReplyOnFacebookPost(
      userBusinessId: $userBusinessId,
      media: $media,
      message: $message
      postId: $postId
      commentId: $commentId
    ) {
      status
      message
    }
   }`;

const updateFacebookComment = `mutation ($userBusinessId: Int!, $media: Upload, $message: String,$commentId: Int, $prevMediaUrl: String) {
    updateFacebookComment(
      userBusinessId: $userBusinessId
      media: $media
      message: $message
      commentId: $commentId
      prevMediaUrl: $prevMediaUrl
    ) {
      status
      message
    }
   }`;

const deleteFacebookComment = `mutation ( $commentId:Int! $userBusinessId:Int! ) {
    deleteFacebookComment(commentId:$commentId , userBusinessId:$userBusinessId){
      status
      message
    }
  }`;

const likeAndUnlikeCommentAndPost = `mutation($commentId:Int $postId:Int $isLike:Boolean!
 $userBusinessId:Int!) {
    likeAndUnlikeCommentAndPost(
      commentId: $commentId
      postId: $postId
      isLike: $isLike
      userBusinessId: $userBusinessId
    ) {
      message
      status
    }
  }`;

const updatePost = `mutation($description:String $postId:Int! $userBusinessId:Int! ) {
  updatePost(description:$description, postId:$postId, userBusinessId:$userBusinessId) {
    message
    status
  }
}`;

const deletePost = `mutation ($postId:Int! $userBusinessId:Int!) {
  deletePost(postId:$postId, userBusinessId:$userBusinessId) {
    message
    status
  }
}
`;

const getLinkedinBusiness = `query($code:String!, $userBusinessId:Int!) {
  getLinkedinBusiness(code: $code, userBusinessId: $userBusinessId) {
    data{
      localizedName
      organizationId
      vanityName
      profileUrl
      staffCountRange
      followerCount
    }
    message
    status
  }
}`;

const connectLinkedinBusiness = `mutation ($organizationId: String!, $userBusinessId: Int!) {
  connectLinkedinBusiness(organizationId: $organizationId, userBusinessId: $userBusinessId) {
    message
    status
  }
}`;

const disconnectLinkedinBusiness = `mutation ($businessId: Int!){
  disconnectLinkedinBusiness(businessId: $businessId) {
    message
    status
  }
}`;

const getLinkedinPosts = `query ($businessId: Int!, $pageSize: Int, $pageCount: Int) {
  getLinkedinPosts(businessId: $businessId, pageSize: $pageSize, pageNumber: $pageCount ) {
    data {
      localizedName
      activity {
        clickCount
        commentCount
        engagement
        impressionCount
        likeCount
        shareCount
        uniqueImpressionsCount
      }
      profileUrl
      vanityName
      description
      createdTime
      media {
        description
        entity
        entityLocation
      }
      postId
      id
    }
    message
    status
  }
}`;

const createLinkedinPost = `mutation createLinkedinPost($userBusinessId: Int!, $media: Upload, , $caption: String!, $isTemplate: Boolean, $templateData: String) {
  createLinkedinPost(
    userBusinessId: $userBusinessId
    media: $media
    caption: $caption
    isTemplate: $isTemplate
    templateData: $templateData
  ) {
    status
    message
  }
}`;

const updateQuickSocialSchedulePost = `mutation updateQuickSocialSchedulePost($description: String,$editableText: [CommonKeyValueArgs!],$html: String,$scheduleDate: DateTime,$schedulePostId: Int,$sharedChannels: [Int!],$userBusinessId: Int!) {
  updateQuickSocialSchedulePost(description: $description,editableText: $editableText,html: $html,scheduleDate:$scheduleDate,schedulePostId:$schedulePostId,sharedChannels: $sharedChannels,userBusinessId: $userBusinessId) {
    message
    status
  }
}`;

const getQuickSocialTemplatesCategoryV1 = `query ($relevantTemplateIds:[Int!], $userBusinessId: Int!, $searchText:String  ){ 
  getQuickSocialTemplatesCategoryV1(
    relevantTemplateIds:$relevantTemplateIds
    userBusinessId:$userBusinessId
    searchText: $searchText
  ) 
  {
    message
    status
    data {
      mainCategoryId
      subCategoryId
      icon
      name
      isSubCategory
      isPinCategory
      isRelevantCategory
    }
  }
}`;

const pinTemplateCategories = ` mutation ($quickSocialTemplatesCategoryId:Int, $userBusinessId:Int!, $quickSocialTemplatesSubCategoryId:Int ){
  pinTemplateCategories(userBusinessId:$userBusinessId, quickSocialTemplatesCategoryId:$quickSocialTemplatesCategoryId, quickSocialTemplatesSubCategoryId:$quickSocialTemplatesSubCategoryId ){
    message
    status
  }
}`;

const unPinTemplateCategories = `mutation($userBusinessId: Int!, $quickSocialTemplatesSubCategoryId: Int, $quickSocialTemplatesCategoryId: Int){
  unPinTemplateCategories(userBusinessId:$userBusinessId, quickSocialTemplatesSubCategoryId:$quickSocialTemplatesSubCategoryId, quickSocialTemplatesCategoryId:$quickSocialTemplatesCategoryId ){
    message
    status
  }
}`;

const searchQuickSocialTemplatesCategories = `query ($searchText: String) {
  searchQuickSocialTemplatesCategories(searchText: $searchText) {
    status
    message
    data {
      id
      name
      isMainCategory
      isSubCategory
    }
  }
}`;

const getQuickSocialTemplatesSubCategoryV1 = `query (
    $socialTemplatesCategoryId: Int!
    $userBusinessId: Int
  ) {
  getQuickSocialTemplatesSubCategoryV1(
    socialTemplatesCategoryId: $socialTemplatesCategoryId
    userBusinessId: $userBusinessId
  ) {
    message
    status
    data {
      id
      icon
      name
      isLiked
    }
  }
}`;

const quickSocialQuery = {
  getQuickSocialChannelWiseData,
  getFacebookPageDetails,
  getFacebookBusiness,
  saveFacebookAuthToken,
  saveInstagramBusiness,
  suggestionPostV2,
  getQuickSocialSchedulePost,
  getQuickSocialBrandHealthMatrices,
  getQuickSocialEngagementMetrics,
  getQuickSocialMatrices,
  getAllQuickSocialProfileDetails,
  disconnectInstagramAndFacebook,
  getAllFacebookChannelData,
  getInstagramPosts,
  getPostCommentsAndSubCommentsForFacebook,
  likeAndUnlikeCommentAndPost,
  commentOrReplyOnFacebookPost,
  deleteFacebookComment,
  updatePost,
  deletePost,
  getLinkedinBusiness,
  connectLinkedinBusiness,
  disconnectLinkedinBusiness,
  getLinkedinPosts,
  createLinkedinPost,
  updateQuickSocialSchedulePost,
  getQuickSocialTemplatesCategoryV1,
  pinTemplateCategories,
  unPinTemplateCategories,
  searchQuickSocialTemplatesCategories,
  getQuickSocialTemplatesSubCategoryV1,
};

export default quickSocialQuery;
