import { Button } from '@mui/material';
import { t } from 'i18next';
import { capitalize } from 'lodash';
import React, { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import history from '@history';
import ConformationDialogBox from 'src/app/main/CommonComponent/ConformationDialogBox';
import { selectDashboardData } from 'app/store/userSlice';
import FuseLoading from '@fuse/core/FuseLoading';
import quickSocialQuery from '../../query/quickSocial';
import { handleApiRequest, formatRating } from '../../common/common';

const SocialProfile = () => {
  const params = useParams();
  const location = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  const [openDialogueForConformation, setOpenDialogueForConformation] = useState(false);
  const userCurrentBusinessDetails = useSelector(selectDashboardData);
  const [loading, setLoading] = useState(false);
  const { selectedAccount, profileData } = location.state || {};
  const handleCloseConformationDialog = () => setOpenDialogueForConformation(false);

  const disConnectFacebook = useCallback(async () => {
    try {
      setLoading(true);
      console.log('userCurrentBusinessDetails', userCurrentBusinessDetails);
      if (userCurrentBusinessDetails?.id) {
        const payloadData = {
          query:
            selectedAccount?.reviewPlatformId === 12
              ? quickSocialQuery.disconnectLinkedinBusiness
              : quickSocialQuery.disconnectInstagramAndFacebook,
          variables: {
            ...(selectedAccount?.reviewPlatformId === 12
              ? {
                  businessId: Number(userCurrentBusinessDetails?.id, 10),
                }
              : {
                  userBusinessId: Number(userCurrentBusinessDetails?.id, 10),
                }),
            ...(selectedAccount?.reviewPlatformId === 9 && {
              isFacebook: true,
              isInstagram: false,
            }),
            ...(selectedAccount?.reviewPlatformId === 10 && {
              isFacebook: false,
              isInstagram: true,
            }),
          },
        };
        console.log('payloadData', payloadData);
        const resultData = await handleApiRequest(payloadData);
        console.log('resultData', resultData);
        setLoading(false);
        const result =
          selectedAccount?.reviewPlatformId === 12
            ? resultData?.disconnectLinkedinBusiness
            : resultData?.disconnectInstagramAndFacebook;
        console.log('result', result);
        if (result?.status === 200) {
          enqueueSnackbar(result?.message, {
            variant: 'success',
            autoHideDuration: 2000,
          });
          setOpenDialogueForConformation(false);
          console.log('params?.id ?', params?.id);
          history.push(`quick-social/social-connect/${params?.id ? params?.id : ''}`);
        } else {
          console.log('setOpenDialogueForConformation', openDialogueForConformation);
          setOpenDialogueForConformation(false);
          history.push(`quick-social/social-connect`);
          enqueueSnackbar('token expired. please, connect again', {
            variant: 'error',
            autoHideDuration: 2000,
          });
        }
      }
      console.log('outside');
    } catch (error) {
      console.log('error', error);
      console.error('An error occurred:', error);
      setLoading(false);
    }
  }, [
    enqueueSnackbar,
    openDialogueForConformation,
    params?.id,
    selectedAccount?.reviewPlatformId,
    userCurrentBusinessDetails,
  ]);

  if (loading) {
    return <FuseLoading />;
  }

  return (
    <div className="p-20 lg:p-24">
      <div className="flex flex-row mb-20 justify-between">
        <p className="text-20 sm:text-28 font-600">
          {capitalize(selectedAccount?.name)} {t('quickSocial.profile')}
        </p>
        <Button
          className="rounded-md text-14 px-5 sm:px-40 font-500"
          size="medium"
          variant="outlined"
          color="quickSocial"
          onClick={() => setOpenDialogueForConformation(true)}
        >
          {t('quickSocial.disconnect')}
        </Button>
      </div>
      <div className="bg-white p-8 sm:p-16 lg:p-28 rounded-6 gap-8 sm:gap-14 lg:gap-40 flex flex-col md:flex-row max-w-5xl items-center">
        <div>
          <img
            className="mx-auto rounded-full w-56 h-56 sm:w-112 sm:h-112 lg:w-224 lg:h-224"
            src={profileData?.profilePictureUrl}
            alt={profileData?.name}
          />
        </div>
        <div className="flex flex-col justify-center gap-8 sm:gap-10 lg:gap-28">
          <div>
            <p className="text-16 sm:text-24 lg:text-36 font-semibold max-w-586">
              {profileData?.name}
            </p>
            <p className="text-14 sm:text-16 lg:text-20">{profileData?.userName}</p>
          </div>
          <div className="flex flex-row gap-20 sm:gap-12 lg:gap-16">
            <div className="bg-indigo-50 bg-opacity-50 py-4 sm:py-6 lg:py-8 px-6 sm:px-10 lg:px-72 flex flex-col items-center rounded-lg">
              <p className="text-16 sm:text-24 lg:text-36 font-semibold">
                {formatRating(profileData?.followersCount)}
              </p>
              <p className="text-10 sm:text-16 lg:text-16 font-medium">
                {t('quickSocial.followers')}
              </p>
            </div>
            <div className="bg-indigo-50 bg-opacity-50 py-4 sm:py-6 lg:py-8 px-6 sm:px-10 lg:px-72 flex flex-col items-center rounded-lg">
              <p className="text-16 sm:text-24 lg:text-36 font-semibold">
                {formatRating(profileData?.followsCount)}
              </p>
              <p className="text-10 sm:text-16 lg:text-16 font-medium">
                {t('quickSocial.following')}
              </p>
            </div>
            <div className="bg-indigo-50 bg-opacity-50 py-4 sm:py-6 lg:py-8 px-6 sm:px-10 lg:px-72 flex flex-col items-center rounded-lg">
              <p className="text-16 sm:text-24 lg:text-36 font-semibold">
                {formatRating(profileData?.totalPosts)}
              </p>
              <p className="text-10 sm:text-16 lg:text-16 font-medium">{t('quickSocial.posts')}</p>
            </div>
          </div>
        </div>
      </div>
      <ConformationDialogBox
        open={openDialogueForConformation}
        onClose={handleCloseConformationDialog}
        onDelete={() => disConnectFacebook()}
        confirmationText="Are You Sure?"
        additionalInfo={`${t('quickSocial.desc1')} ${capitalize(selectedAccount?.name)} ${t(
          'quickSocial.desc2'
        )}`}
        deleteImage="/assets/images/icon/socialDeleteIcon.png"
        backgroundColor="quick-social"
        textColor="quick-social"
        buttonText={t('common.confirm')}
      />
    </div>
  );
};

export default SocialProfile;
