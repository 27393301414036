import { Button, CircularProgress, Dialog, Icon, TextField, Typography } from '@mui/material';
import { t } from 'i18next';
import { useCallback, useEffect, useState } from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';
import manageBusinessData from 'src/app/main/query/manageBusiness';
import PhoneInput from 'react-phone-input-2';
import { useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import FuseLoading from '@fuse/core/FuseLoading';
import UserProfile from '../../../common/userProfile';
import { selectDashboardData } from '../../../../store/userSlice';
import { capitalizeFirstLetter, countryData, handleApiRequest } from '../../../common/common';
import Pagination from '../../../../component/table/Pagination';
import userService from '../../../../services';

import Loading from '../../manage-business/Loading';

const InviteUser = () => {
  const [refreshIcon, setRefreshIcon] = useState(false);
  const [openDialogue, setOpenDialogue] = useState(false);
  const [tabValue, setTabValue] = useState(0);
  const [inviteData, setInviteData] = useState([]);
  const [acceptedInvititionData, setAcceptedInvititionData] = useState([]);
  const [pageLoading, setPageLoading] = useState(false);
  const [searchEmailAndPhoneData, setSearchEmailAndPhoneData] = useState([]);
  const [addUser, setAddUser] = useState([]);
  const [totalCount, setTotalCount] = useState();
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(1);
  const [totalCountNew, setTotalCountNew] = useState();
  const [acceptRowsPerPage, setAcceptRowsPerPage] = useState(10);
  const [acceptPage, setAcceptPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [countryId, setCountryId] = useState();
  const userCurrentBusinessDetails = useSelector(selectDashboardData);
  const [checkInputData, setCheckInputData] = useState();
  const loginUserData = userService.getUserData();
  const [cancelInviteId, setCancelInviteId] = useState();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    const country = countryData(loginUserData?.countryId);
    setCountryId(country?.phoneCountry);
  }, [loginUserData?.countryId]);

  const fetchInviteUsers = useCallback(async () => {
    if (userCurrentBusinessDetails?.id) {
      const payload = {
        query: manageBusinessData.getInvitationRequestedAndAcceptedRequestUserData,
        variables: {
          status: 'requested',
          userBusinessId: parseInt(userCurrentBusinessDetails?.id, 10),
          pageNumber: page,
          pageSize: rowsPerPage,
        },
      };
      try {
        setPageLoading(true);
        const result = await handleApiRequest(payload);
        setPageLoading(false);
        if (result?.getInvitationRequestedAndAcceptedRequestUserData?.status === 200) {
          setInviteData(result?.getInvitationRequestedAndAcceptedRequestUserData?.data);
          setTotalCount(result?.getInvitationRequestedAndAcceptedRequestUserData?.totalCount);
        } else {
          enqueueSnackbar(result?.getInvitationRequestedAndAcceptedRequestUserData?.message, {
            variant: 'error',
            autoHideDuration: 2000,
          });
        }
        return result;
      } catch (error) {
        console.log('An error occurred while fetching invite users', error);
      }
    }
    return null;
  }, [userCurrentBusinessDetails?.id, enqueueSnackbar, page, rowsPerPage]);
  const fetchAcceptedUsers = useCallback(async () => {
    if (userCurrentBusinessDetails?.id) {
      const payload = {
        query: manageBusinessData.getInvitationRequestedAndAcceptedRequestUserData,
        variables: {
          status: 'accepted',
          userBusinessId: parseInt(userCurrentBusinessDetails?.id, 10),
          pageSize: acceptRowsPerPage,
          pageNumber: acceptPage,
        },
      };
      try {
        setPageLoading(true);
        const result = await handleApiRequest(payload);
        setPageLoading(false);
        if (result?.getInvitationRequestedAndAcceptedRequestUserData?.status === 200) {
          setAcceptedInvititionData(result?.getInvitationRequestedAndAcceptedRequestUserData?.data);
          setTotalCountNew(result?.getInvitationRequestedAndAcceptedRequestUserData?.totalCount);
        } else {
          enqueueSnackbar(result?.getInvitationRequestedAndAcceptedRequestUserData?.message, {
            variant: 'error',
            autoHideDuration: 2000,
          });
        }
      } catch (error) {
        console.log('An error occurred while fetching accepted users', error);
      }
    }
  }, [userCurrentBusinessDetails?.id, enqueueSnackbar, acceptRowsPerPage, acceptPage]);

  useEffect(() => {
    fetchInviteUsers();
  }, [fetchInviteUsers]);

  useEffect(() => {
    fetchAcceptedUsers();
  }, [fetchAcceptedUsers]);

  const handleClose = () => {
    setOpenDialogue(false);
    setAddUser([]);
    setSearchEmailAndPhoneData([]);
    setTabValue(0);
  };

  const handleSearchUserByEmail = async (value) => {
    setCheckInputData(value);
    if (value?.trim()?.length > 0) {
      const payload = {
        query: manageBusinessData.searchUserOnEmailAndPhoneNumber,
        variables: {
          email: value,
          businessId: parseInt(userCurrentBusinessDetails?.id, 10),
        },
      };
      try {
        const res = await handleApiRequest(payload);
        if (res?.searchUserOnEmailAndPhoneNumber?.status === 200) {
          setSearchEmailAndPhoneData(res?.searchUserOnEmailAndPhoneNumber?.data);
        } else {
          enqueueSnackbar(res?.searchUserOnEmailAndPhoneNumber?.message, {
            variant: 'error',
            autoHideDuration: 2000,
          });
        }
      } catch (error) {
        console.log('An error occurred while searching user by email', error);
      }
    }
    if (value?.trim()?.length === 0) {
      setSearchEmailAndPhoneData([]);
    }
  };

  const handleSearchUserByPhone = async (value, data) => {
    setCheckInputData(value?.slice(data?.dialCode?.length));
    if (value?.slice(data?.dialCode?.length)?.trim()?.length > 0) {
      const payload = {
        query: manageBusinessData.searchUserOnEmailAndPhoneNumber,
        variables: {
          businessId: parseInt(userCurrentBusinessDetails?.id, 10),
          phoneNumberDetails: {
            countryCode: `+${data?.dialCode}`,
            isoCode: data?.countryCode,
            phoneNumber: parseInt(value?.slice(data?.dialCode?.length), 10),
          },
        },
      };

      try {
        const res = await handleApiRequest(payload);
        if (res?.searchUserOnEmailAndPhoneNumber?.status === 200) {
          setSearchEmailAndPhoneData(res?.searchUserOnEmailAndPhoneNumber?.data);
        } else {
          enqueueSnackbar(res?.searchUserOnEmailAndPhoneNumber?.message, {
            variant: 'error',
            autoHideDuration: 2000,
          });
        }
      } catch (error) {
        console.log('An error occurred while searching user by phone', error);
      }
    }
    if (value?.slice(data?.dialCode?.length)?.trim()?.length === 0) {
      setSearchEmailAndPhoneData([]);
    }
  };

  const addUserForInvite = (user) => {
    const findUser = addUser?.find((item) => item.id === user?.id);
    if (findUser) {
      enqueueSnackbar(t('inviteMember.alreadyAddedUser'), {
        variant: 'error',
        autoHideDuration: 2000,
      });
    } else {
      setAddUser([...addUser, user]);
    }
  };

  const handleTable = (value) => {
    setTabValue(value);
    setSearchEmailAndPhoneData([]);
  };

  const sendRequest = async () => {
    const inviteUserData = addUser?.map((user) => {
      return {
        userBusinessId: parseInt(userCurrentBusinessDetails?.id, 10),
        invitedUserId: parseInt(user?.id, 10),
      };
    });
    const payload = {
      query: manageBusinessData.invitedUserToJoinBusinessServices,
      variables: {
        invitationData: inviteUserData,
      },
    };
    try {
      setRefreshIcon(true);
      const result = await handleApiRequest(payload);
      setRefreshIcon(false);
      if (result?.invitedUserToJoinBusinessServices?.status === 200) {
        if (page === 1) {
          fetchInviteUsers();
        }
        handleClose();
        setPage(1);
        enqueueSnackbar(result?.invitedUserToJoinBusinessServices?.message, {
          variant: 'success',
          autoHideDuration: 2000,
        });
      } else {
        enqueueSnackbar(result?.invitedUserToJoinBusinessServices?.message, {
          variant: 'error',
          autoHideDuration: 2000,
        });
      }
    } catch (error) {
      console.log('An error occurred while invite user by join business service', error);
    }
  };

  const removeUser = (id) => {
    const data = addUser?.filter((item) => item?.id !== id);
    setAddUser(data);
  };

  const cancelInviteOrDelete = async (id) => {
    setLoading(true);
    setCancelInviteId(id);
    const payload = {
      query: manageBusinessData.cancelRequestedInvitationAndAcceptedInvitation,
      variables: {
        userBusinessRoleMappingId: parseInt(id, 10),
        status: 'removed',
      },
    };
    try {
      const result = await handleApiRequest(payload);
      setLoading(false);
      if (result?.cancelRequestedInvitationAndAcceptedInvitation?.status === 200) {
        if (page === 1) {
          fetchInviteUsers();
        }
        const resultData = await fetchInviteUsers();
        if (resultData?.getInvitationRequestedAndAcceptedRequestUserData?.data.length === 0) {
          setPage(page - 1);
        }
        enqueueSnackbar(result?.cancelRequestedInvitationAndAcceptedInvitation?.message, {
          variant: 'success',
          autoHideDuration: 2000,
        });
      } else {
        enqueueSnackbar(result?.cancelRequestedInvitationAndAcceptedInvitation?.message, {
          variant: 'error',
          autoHideDuration: 2000,
        });
      }
    } catch (error) {
      console.log('An error occurred while cancel request invite and accept user', error);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage + 1);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));

    setPage(1);
  };

  const handleChangePageNew = (event, newPage) => {
    setAcceptPage(newPage + 1);
  };

  const handleChangeRowsPerPageNew = (event) => {
    setAcceptRowsPerPage(parseInt(event.target.value, 10));
    setAcceptPage(1);
  };

  if (pageLoading) {
    return <FuseLoading />;
  }

  return (
    <div className="h-full lg:p-24 p-16">
      <div className="flex w-full items-center justify-between lg:mb-20 mb-16">
        <div className="flex items-center">
          <Typography
            initial={{ x: -20 }}
            animate={{ x: 0, transition: { delay: 0.2 } }}
            delay={300}
            className="sm:flex md:text-28 text-20  font-bold mb-16 md:mb-0"
          >
            {t('inviteMember.title')}
          </Typography>
        </div>
        <div className="text-center">
          <Button
            className="bg-quick-review rounded px-40 text-white disabled:bg-gray hover:bg-quick-review disabled:border-gray disabled:text-black border border-solid border-quick-review mx-10 md:mx-0"
            type="button"
            onClick={() => setOpenDialogue(true)}
          >
            {t('inviteMember.button.inviteUser')}
          </Button>
        </div>
      </div>
      {inviteData?.length > 0 || acceptedInvititionData?.length > 0 ? (
        <div>
          {inviteData?.length > 0 && (
            <div className="bg-white p-28">
              <Typography className="text-24 font-semibold">
                {t('inviteMember.invitedUser')}
              </Typography>
              <hr className="border border-solid my-16 !border-gray border-dashed" />
              {inviteData?.map((inviteUser, i) => {
                return (
                  <div className="bg-gray-50 flex justify-between p-16 mb-16 rounded" key={i}>
                    <div className="flex justify-between w-full">
                      <div className="flex gap-16">
                        {inviteUser?.profile ? (
                          <img className="w-48 h-48 rounded-3xl" src={inviteUser?.profile} alt="" />
                        ) : (
                          <UserProfile
                            name={inviteUser?.firstName}
                            width="50px"
                            height="50px"
                            fontSize="20px"
                            fontWeight="bold"
                          />
                        )}
                        <div>
                          <Typography className="font-semibold text-14 ">
                            {inviteUser?.firstName && capitalizeFirstLetter(inviteUser.firstName)}{' '}
                            {inviteUser?.lastName}
                          </Typography>

                          <div className="flex items-center space-y-2">
                            {inviteUser?.email && (
                              <div className="flex items-center">
                                <EmailIcon className="text-grey-700 mr-5 text-lg" />
                                <span>{inviteUser.email}</span>
                              </div>
                            )}

                            {inviteUser?.email && inviteUser?.phoneNumber && (
                              <Typography className="space-x-72 mx-5">|</Typography>
                            )}

                            {inviteUser?.phoneNumber && (
                              <div className="flex items-center space-y-2">
                                <PhoneIcon className="text-grey-700 mr-5 text-lg" />
                                <span>{inviteUser.phoneNumber}</span>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>

                      <Button
                        className="bg-quick-review-100 rounded-3xl px-36 text-quick-review disabled:bg-gray hover:bg-quick-review-A300 disabled:border-gray disabled:text-black mx-10 md:mx-0"
                        type="button"
                        onClick={() => cancelInviteOrDelete(inviteUser?.id)}
                        disabled={
                          loading && parseInt(cancelInviteId, 10) === parseInt(inviteUser?.id, 10)
                        }
                      >
                        {t('inviteMember.button.cancelInvitation')}
                        {loading &&
                          parseInt(cancelInviteId, 10) === parseInt(inviteUser?.id, 10) && (
                            <CircularProgress
                              size={24}
                              className="text-quick-review absolute mx-auto"
                            />
                          )}
                      </Button>
                    </div>
                  </div>
                );
              })}
            </div>
          )}
          {inviteData?.length > 0 && (
            <Pagination
              totalCount={totalCount}
              rowsPerPage={rowsPerPage}
              page={page}
              handleChangePage={handleChangePage}
              handleChangeRowsPerPage={handleChangeRowsPerPage}
            />
          )}
          {acceptedInvititionData?.length > 0 && (
            <div className="bg-white p-28 mt-40">
              <Typography className="text-24 font-semibold">
                {t('inviteMember.acceptedUser')}
              </Typography>
              <hr className="border border-solid my-16 !border-gray border-dashed" />
              {acceptedInvititionData?.map((acceptedtUser, i) => {
                return (
                  <div className="bg-gray-100 flex justify-between p-16 rounded mb-16" key={i}>
                    <div className="flex gap-12 items-center">
                      {acceptedtUser?.profile ? (
                        <img
                          className="w-48 h-48 rounded-3xl"
                          src={acceptedtUser?.profile}
                          alt=""
                        />
                      ) : (
                        <UserProfile
                          name={acceptedtUser?.firstName}
                          width="50px"
                          height="50px"
                          fontSize="20px"
                          fontWeight="bold"
                        />
                      )}
                      <div>
                        <Typography className="font-semibold text-14">
                          {acceptedtUser?.firstName &&
                            capitalizeFirstLetter(acceptedtUser.firstName)}{' '}
                          {acceptedtUser?.firstName}
                        </Typography>
                        <Typography>{acceptedtUser?.email}</Typography>
                      </div>
                    </div>
                    <div>
                      <Button
                        className="bg-red-A500 rounded-3xl px-48 text-red-A300 disabled:bg-gray hover:bg-red-A500 disabled:border-gray disabled:text-black mx-10 md:mx-0"
                        type="button"
                        onClick={() => cancelInviteOrDelete(acceptedtUser?.id)}
                        disabled={
                          loading &&
                          parseInt(cancelInviteId, 10) === parseInt(acceptedtUser?.id, 10)
                        }
                      >
                        {t('inviteMember.button.deleteUser')}
                        {loading &&
                          parseInt(cancelInviteId, 10) === parseInt(acceptedtUser?.id, 10) && (
                            <CircularProgress
                              size={24}
                              className="text-quick-review absolute mx-auto"
                            />
                          )}
                      </Button>
                    </div>
                  </div>
                );
              })}
            </div>
          )}
          {acceptedInvititionData?.length > 0 && (
            <Pagination
              totalCount={totalCountNew}
              rowsPerPage={acceptRowsPerPage}
              page={acceptPage}
              handleChangePage={handleChangePageNew}
              handleChangeRowsPerPage={handleChangeRowsPerPageNew}
            />
          )}
        </div>
      ) : (
        <div className="mt-256 text-center">
          <img className="m-auto mb-28" src="/assets/images/business/inviteUser.svg" alt="" />
          <Typography className="font-semibold text-20 mb-8">
            {t('inviteMember.noUserInvited')}
          </Typography>
          <Typography className="text-14 max-w-640 m-auto mb-16">
            {t('inviteMember.messageForNotSendInvite')}
          </Typography>
          <Button
            className="bg-quick-review rounded px-80 text-white disabled:bg-gray hover:bg-quick-review disabled:border-gray disabled:text-black border border-solid border-quick-review mx-10 md:mx-0"
            type="button"
            onClick={() => setOpenDialogue(true)}
          >
            {t('inviteMember.button.inviteUser')}
          </Button>
        </div>
      )}
      <Dialog
        classes={{
          paper:
            'm-24 p-28 business-type-dropdown border-t-8 h-max relative border-solid border-t-quick-review border-red-A300 rounded-md',
        }}
        open={openDialogue}
        fullWidth
        maxWidth="xs"
      >
        <Icon
          className="text-24 absolute top-12 right-12 text-grey-400 font-normal cursor-pointer hover:text-gray-700 focus:text-gray-700"
          onClick={() => handleClose()}
        >
          cancel
        </Icon>
        <Typography className="text-20 font-semibold text-black w-full text-left">
          {t('inviteMember.button.inviteUser')}
        </Typography>
        <hr className="border border-dashed border-solid my-20 !border-gray" />
        <div className="bg-gray-100 rounded-md mb-12 px-12 h-640 overflow-scroll">
          <Tabs
            value={tabValue}
            onChange={(e, newValue) => handleTable(newValue)}
            textColor="secondary"
            indicatorColor="secondary"
            aria-label="secondary tabs example"
          >
            <Tab
              className="sm:text-18 font-semibold p-0 sm:mr-28 mr-14"
              value={0}
              label={t('inviteMember.inviteByMail')}
            />
            <Tab
              className="sm:text-18 font-semibold p-0"
              value={1}
              label={t('inviteMember.inviteByPhone')}
            />
          </Tabs>
          <div className="mt-20">
            {tabValue === 0 ? (
              <TextField
                className="bg-white w-full"
                placeholder={t('inviteMember.enterEmail')}
                size="small"
                onChange={(e) => handleSearchUserByEmail(e.target.value)}
              />
            ) : (
              <PhoneInput
                inputStyle={{
                  width: '100%',
                  height: '40px',
                }}
                className="w-full max-w-sm w-full"
                name="phone_number"
                required
                country={countryId}
                enableSearch="true"
                onChange={handleSearchUserByPhone}
              />
            )}
          </div>
          <div className=" overflow-scroll">
            <div className="flex gap-8 max-w-360  flex-wrap">
              {addUser?.length > 0 &&
                addUser?.map((user, index) => {
                  return (
                    <div key={index} className="bg-white p-6  flex items-center gap-8 rounded-md">
                      <Typography>
                        {user?.firstName} {user?.lastName}
                      </Typography>
                      <Icon className="text-16" onClick={() => removeUser(user?.id)}>
                        close
                      </Icon>
                    </div>
                  );
                })}
            </div>
          </div>
          <div className="max-h-480 overflow-scroll">
            {searchEmailAndPhoneData?.length > 0 && checkInputData
              ? searchEmailAndPhoneData.map((item, i) => (
                  <div key={i}>
                    <div
                      className="flex bg-white rounded-md p-8 gap-12 my-16 items-center"
                      role="button"
                      tabIndex={0}
                      onClick={() => addUserForInvite(item)}
                      onKeyDown={(e) => {
                        if (e.key === 'Enter' || e.key === ' ') {
                          addUserForInvite(item);
                        }
                      }}
                    >
                      {item?.profileUrl ? (
                        <img className="w-36 h-36 rounded-3xl" src={item?.profileUrl} alt="" />
                      ) : (
                        <UserProfile name={item?.firstName} width="36px" height="36px" />
                      )}
                      <div>
                        <Typography className="font-semibold text-14">
                          {item?.firstName} {item?.lastName}
                        </Typography>
                        {tabValue === 0 ? (
                          <Typography>{item?.email}</Typography>
                        ) : (
                          <Typography>
                            {item?.phoneNumber?.phoneNumber &&
                              `${item?.phoneNumber?.countryCode} ${item?.phoneNumber?.phoneNumber}`}
                          </Typography>
                        )}
                      </div>
                    </div>
                  </div>
                ))
              : searchEmailAndPhoneData?.length !== 0 &&
                checkInputData && (
                  <Typography className="font-semibold text-16 flex items-center justify-center">
                    {t('manageBusiness.addBusinessSteps.businessLocation.noDataAvailable')}
                  </Typography>
                )}
          </div>
        </div>
        <div className="text-center">
          <div className="mt-12">
            <Button
              className="bg-quick-review rounded mt-20 disabled:bg-gray disabled:text-black md:px-32 text-white hover:bg-quick-review mx-10 md:mx-0"
              disabled={refreshIcon || addUser?.length === 0}
              onClick={() => sendRequest()}
            >
              {refreshIcon && (
                <CircularProgress size={24} className="text-quick-review absolute mx-auto" />
              )}
              {t('inviteMember.button.sendRequest')}
            </Button>
          </div>
        </div>
      </Dialog>
    </div>
  );
};

export default InviteUser;
