import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import ReactApexChart from 'react-apexcharts';
import { Button, Icon, Tooltip, Typography } from '@mui/material';
import { t } from 'i18next';
import metricsData from '../../../query/metrics';
import NoData from '../../matrices/noDataScreen';
import { capitalizeFirstLetter, getDataToServer } from '../../../common/common';
import { selectDashboardData } from '../../../../store/userSlice';

function ResponseMatrices(props) {
  const { metricsIconInfo } = props;
  const [newGraphData, setNewGraphData] = useState([]);
  const [summaryData, setSummaryData] = useState();
  const [selectBotTypeData, setSelectBotTypeData] = useState('byHuman');
  const [botTypeData, setBotTypeData] = useState();
  const userCurrentBusinessDetails = useSelector(selectDashboardData);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const payload = {
          query: metricsData.getQuickChatAverageResponseTimeMatrix,
          variables: {
            businessId: Number(userCurrentBusinessDetails?.id),
            botTypeId: 1,
          },
        };
        const result = await getDataToServer(payload);
        setBotTypeData(result?.getQuickChatAverageResponseTimeMatrix?.data?.response);
      } catch (error) {
        console.error('An error occurred:', error);
      }
    };
    if (userCurrentBusinessDetails?.id) {
      fetchData();
    }
  }, [userCurrentBusinessDetails?.id]);

  useEffect(() => {
    if (botTypeData?.length > 1 && selectBotTypeData) {
      const findDataByBotType = botTypeData?.find((value) => value?.key === selectBotTypeData);

      if (findDataByBotType) {
        const obj = {};
        findDataByBotType.summary?.forEach(({ key, value }) => {
          obj[key] = value;
        });

        setNewGraphData(findDataByBotType?.value);
        setSummaryData(obj);
      }
    }
  }, [botTypeData, selectBotTypeData]);
  function getChartSimple(data) {
    const chart = {
      series: [
        {
          name: 'Response Time',
          data: data.map((item) => item.value),
        },
      ],
      options: {
        chart: {
          type: 'area',
          height: 350,
          toolbar: {
            show: false,
          },
          zoom: {
            enabled: false,
          },
        },
        stroke: {
          curve: 'smooth',
          width: 1,
        },
        fill: {
          type: 'gradient',
          gradient: {
            shadeIntensity: 1,
            opacityFrom: 0.7,
            opacityTo: 0.1,
            stops: [0, 100],
            colorStops: [
              {
                offset: 0,
                color: '#3E8CF3',
                opacity: 0.7,
              },
              {
                offset: 100,
                color: '#3E8CF3',
                opacity: 0.1,
              },
            ],
          },
        },
        dataLabels: {
          enabled: false,
        },
        markers: {
          size: 0,
        },
        xaxis: {
          categories: Array.from({ length: data.length }, (_, index) => index + 1),
          labels: {
            show: true,
            rotate: -45,
            style: {
              colors: '#000',
              fontSize: '12px',
            },
          },
          forceNiceScale: true,
          axisBorder: {
            show: false,
          },
          tickPlacement: 'between',
        },
        yaxis: {
          categories: data.map((period) => period?.count),
          tickAmount: 10,
          labels: {
            style: {
              colors: '#000',
              fontSize: '12px',
            },
          },
        },
        grid: {
          show: true,
          borderColor: '#C5C5C5',
          strokeDashArray: 4,
        },
        tooltip: {
          enabled: true,
          theme: 'light',
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              xaxis: {
                labels: {
                  rotate: 0,
                  style: {
                    fontSize: '10px',
                  },
                },
              },
            },
          },
        ],
      },
    };

    return (
      <div className="overflow-x-hidden w-full">
        <ReactApexChart
          options={chart.options}
          series={chart.series}
          type="area"
          height={500}
          width="100%"
        />
      </div>
    );
  }

  const handleSelectBotType = (bot) => {
    const findDataByBotType = botTypeData?.find((item) => item?.key === bot);
    const obj = {};
    findDataByBotType?.summary?.forEach(({ key, value }) => {
      obj[key] = value;
      return obj;
    });
    setSummaryData(obj);
    setSelectBotTypeData(bot);
    setNewGraphData(findDataByBotType?.value);
  };

  return (
    <>
      <div className="flex justify-between w-full flex-col sm:gap-0 gap-10 sm:flex-row items-center">
        <div className="flex flex-row items-center">
          <h3 className="text-16 lg:text-18 font-bold items-center">
            {t('metrics.averageResponseTime')}
          </h3>
          <Tooltip
            title={metricsIconInfo}
            arrow
            classes={{
              tooltip: 'bg-quick-chat',
              arrow: 'text-quick-chat',
            }}
          >
            <img
              className="w-14 h-14 md:w-16 md:h-16 ml-8"
              src="/assets/images/icon/tooltip_info_yellow.svg"
              alt="good"
            />
          </Tooltip>
        </div>
        <div className="flex gap-10 sm:w-auto w-full">
          <Button
            className={`${
              selectBotTypeData === 'byHuman'
                ? 'bg-[#323952] text-white hover:bg-[#323952]'
                : 'bg-gray-A500 text-black'
            } rounded-md w-full sm:w-96 py-4`}
            onClick={() => handleSelectBotType('byHuman')}
          >
            {t('metrics.byHuman')}
          </Button>
          <Button
            className={`${
              selectBotTypeData === 'byBot'
                ? 'bg-[#323952] text-white hover:bg-[#323952]'
                : 'bg-gray-A500 text-black'
            } rounded-md w-full sm:w-96 py-4`}
            onClick={() => handleSelectBotType('byBot')}
          >
            {t('metrics.byBot')}
          </Button>
        </div>
      </div>
      {newGraphData?.length > 0 ? (
        <>
          <div>{getChartSimple(newGraphData)}</div>
          <div className="bg-gray-A500 rounded-md py-8 px-24 mx-16">
            <div className="flex gap-16">
              <img className="w-60 h-60" src="/assets/images/business/responsetime.png" alt="" />
              <div className="grid">
                <Typography className="font-semibold text-20">
                  {summaryData?.avgResponseTime}
                </Typography>
                <Typography className="font-normal">
                  {capitalizeFirstLetter(summaryData?.text)}
                </Typography>
              </div>
            </div>
          </div>
        </>
      ) : (
        <NoData />
      )}
    </>
  );
}

export default ResponseMatrices;
