import { useCallback, useEffect, useState } from 'react';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogTitle,
  Icon,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import { t } from 'i18next';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import BuildIcon from '@mui/icons-material/Build';
import AddIcon from '@mui/icons-material/Add';
import { useSnackbar } from 'notistack';
import manageBusinessData from '../../../query/manageBusiness';
import { capitalizeFirstLetter, handleApiRequest } from '../../../common/common';
import userService from '../../../../services';

const ServiceSection = (props) => {
  const { userCurrentBusinessDetails, googleBusinessData, openDialog, setOpenDialog } = props;

  const [customServiceDetails, setCustomServiceDetails] = useState({
    name: '',
    price: '',
    priceType: '',
    description: '',
    isCustom: false,
  });
  const [openDetailsDialogue, setOpenDetailsDialogue] = useState(false);
  const [refreshIcon, setRefreshIcon] = useState(false);
  const [servicesData, setServicesData] = useState([]);
  const [suggestionServiceData, setSuggestionServiceData] = useState([]);
  const [deleteServiceName, setDeleteServiceName] = useState();
  const loginUserData = userService.getUserData();
  const { enqueueSnackbar } = useSnackbar();
  const priceData = ['Free', 'Fixed'];

  const fetchGoogleServices = useCallback(async () => {
    try {
      const payload = {
        query: manageBusinessData.getServicesSuggestionsForGoogle,
        variables: {
          businessId: Number(userCurrentBusinessDetails?.id),
        },
      };
      const result = await handleApiRequest(payload);
      if (result?.getServicesSuggestionsForGoogle?.status === 200) {
        setSuggestionServiceData(result?.getServicesSuggestionsForGoogle?.data);
      }
    } catch (error) {
      console.log('An error occurred while fetching service suggestions', error);
    }
  }, [userCurrentBusinessDetails?.id]);

  const fetchActiveGoogleServices = useCallback(async () => {
    const payload = {
      query: manageBusinessData.getActiveGoogleServices,
      variables: {
        businessId: Number(userCurrentBusinessDetails?.id),
      },
    };
    const result = await handleApiRequest(payload);
    if (result?.getActiveGoogleServices?.status === 200) {
      setServicesData(result?.getActiveGoogleServices?.data?.customServices);
    }
  }, [userCurrentBusinessDetails?.id]);

  useEffect(() => {
    fetchActiveGoogleServices();
  }, [fetchActiveGoogleServices]);

  useEffect(() => {
    fetchGoogleServices();
  }, [fetchGoogleServices]);

  const onSubmit = async () => {
    try {
      setRefreshIcon(true);
      const payload = {
        query: manageBusinessData.addAndDeleteServicesInGoogle,
        variables: {
          businessId: parseInt(userCurrentBusinessDetails?.id, 10),
          locationId: googleBusinessData?.name?.split('/')[1],
          data: {
            addServices: [
              {
                service: customServiceDetails?.name,
                description: customServiceDetails?.description,
                price: {
                  currencyCode: loginUserData?.countryCode === 2 ? 'INR' : 'USD',
                  units: parseInt(customServiceDetails?.price, 10),
                },
                isCustom: customServiceDetails?.isCustom,
              },
            ],
          },
        },
      };
      const result = await handleApiRequest(payload);
      setRefreshIcon(false);
      if (result?.addAndDeleteServicesInGoogle?.status === 200) {
        fetchActiveGoogleServices();
        setOpenDetailsDialogue(false);
        setOpenDialog({ ...openDialog, serviceDialog: false });
        setCustomServiceDetails({
          name: '',
          price: '',
          priceType: '',
          description: '',
          isCustom: false,
        });
      } else {
        enqueueSnackbar(result?.addAndDeleteServicesInGoogle?.message, {
          variant: 'error',
          autoHideDuration: 3000,
        });
      }
    } catch (error) {
      console.error('Error adding services:', error);
      setRefreshIcon(false);
    }
  };

  const deleteService = async (name, isCustomService) => {
    setRefreshIcon(true);
    setDeleteServiceName(name);
    try {
      const payload = {
        query: manageBusinessData.addAndDeleteServicesInGoogle,
        variables: {
          businessId: Number(userCurrentBusinessDetails?.id),
          locationId: googleBusinessData?.name?.split('/')[1],
          data: {
            deleteServices: [
              {
                service: name,
                isCustom: isCustomService,
              },
            ],
          },
        },
      };
      const result = await handleApiRequest(payload);
      setRefreshIcon(false);
      if (result?.addAndDeleteServicesInGoogle?.status === 200) {
        fetchActiveGoogleServices();
        setOpenDetailsDialogue(false);
        setDeleteServiceName('');
        enqueueSnackbar(result?.addAndDeleteServicesInGoogle?.message, {
          variant: 'success',
          autoHideDuration: 3000,
        });
      } else {
        enqueueSnackbar(result?.addAndDeleteServicesInGoogle?.message, {
          variant: 'error',
          autoHideDuration: 3000,
        });
      }
    } catch (error) {
      setRefreshIcon(false);
      console.error('Error in deleteService:', error);
    }
  };

  const editServiceDetails = (data) => {
    setCustomServiceDetails({
      name: data?.displayName,
      price: data?.price?.units,
      description: data?.description,
      priceType: data?.price?.units ? 'Fixed' : 'Free',
      isCustom: true,
    });
    setOpenDetailsDialogue(true);
  };

  return (
    <div>
      <div className="bg-white rounded-md">
        <div className="flex justify-between items-center mt-16">
          <Typography className="font-bold text-24">
            <BuildIcon className="text-32 mr-6" /> {t('listManagement.services')}
          </Typography>
          <Button
            className="rounded-md bg-quick-review px-24 hover:bg-quick-review text-white font-semibold"
            variant="contained"
            onClick={() => setOpenDialog({ ...openDialog, serviceDialog: true })}
          >
            <AddIcon />
            {t('listManagement.addServices')}
          </Button>
        </div>
      </div>
      <div className="mt-16 bg-gray-A500 overflow-scroll p-28">
        {servicesData?.length > 0 ? (
          servicesData?.map((item, index) => {
            return (
              <div key={index} className="bg-white rounded-md px-16 py-12 my-8">
                <div className="flex justify-between items-center">
                  <Typography className="font-bold text-16">
                    {capitalizeFirstLetter(item?.displayName)}
                  </Typography>
                  <Typography className="font-semibold text-gray text-14">
                    {item?.price?.units ? `$ ${item?.price?.units} (Fixed)` : `(Free)`}
                  </Typography>
                </div>
                <div className="text-gray mt-12">
                  {item?.description ? (
                    <Typography className="font-semibold bg-gray-A500 p-12 rounded-md text-gray text-14">
                      {item?.description}
                    </Typography>
                  ) : (
                    <Button className="font-semibold text-gray text-14 p-0 hover:bg-white">
                      <AddIcon className="text-16" /> {t('listManagement.add')}{' '}
                      {t('listManagement.details')}
                    </Button>
                  )}
                </div>
                <div className="flex items-center justify-end gap-16 mt-8">
                  <Button
                    className="w-20 min-w-20 hover:bg-white py-0"
                    onClick={() => editServiceDetails(item)}
                  >
                    <EditIcon className="text-gray text-20" />
                  </Button>
                  <Button
                    className="w-20 min-w-20 hover:bg-white py-0"
                    onClick={() => deleteService(item?.displayName, true)}
                    disabled={refreshIcon}
                  >
                    <DeleteIcon className="text-gray text-20" />
                    {refreshIcon && deleteServiceName === item?.displayName && (
                      <CircularProgress className="text-quick-review absolute mx-auto" size={20} />
                    )}
                  </Button>
                </div>
              </div>
            );
          })
        ) : (
          <div className="bg-gray-A500 gap-16 rounded-md mt-16">
            <div className="bg-white p-24 rounded-md">
              <Typography className="font-bold text-16">
                {t('listManagement.addBusinessService')}
              </Typography>
              <Typography className="font-semibold text-14">
                {t('listManagement.addServiceAndDescription')}
              </Typography>
            </div>
          </div>
        )}
      </div>
      <Dialog
        classes={{
          paper: 'm-24 sm:p-28 p-20 business-type-dropdown',
        }}
        open={openDialog}
        onClose={() => setOpenDialog({ ...openDialog, serviceDialog: false })}
        fullWidth
        maxWidth="md"
      >
        <DialogTitle className="p-0 mb-8">
          <div className="flex">
            <Typography className="text-20 font-bold text-black w-full">
              {t('listManagement.services')}
            </Typography>
            <Icon
              onClick={() => setOpenDialog({ ...openDialog, serviceDialog: false })}
              className="text-24 text-grey-400 font-normal cursor-pointer hover:text-gray-700 focus:text-gray-700"
            >
              cancel
            </Icon>
          </div>
          <div className="border-b-2 border-solid border-quick-review-100 pb-24">
            <Typography className="font-bold text-20">{googleBusinessData?.title}</Typography>
            <Typography className="text-gray text-16 font-semibold pb-8">
              Address : {googleBusinessData?.storefrontAddress?.addressLines?.[0]}{' '}
              {googleBusinessData?.storefrontAddress?.administrativeArea}{' '}
              {googleBusinessData?.storefrontAddress?.locality}{' '}
              {googleBusinessData?.storefrontAddress?.administrativeArea}{' '}
              {googleBusinessData?.storefrontAddress?.postalCode}
            </Typography>
          </div>
          <div>
            <Typography className="font-bold text-16 pt-20">
              {t('listManagement.selectServiceType')}
            </Typography>
            {suggestionServiceData?.length > 0 && (
              <Typography className="font-bold text-16 mt-8">
                {googleBusinessData?.categories?.primaryCategory?.displayName}
              </Typography>
            )}
          </div>
          {suggestionServiceData?.length > 0 && (
            <div className="h-192 overflow-scroll my-8">
              {suggestionServiceData?.map((item, index) => {
                return (
                  <Button
                    key={index}
                    className={`${
                      customServiceDetails?.name === item?.displayName
                        ? 'bg-quick-review hover:bg-quick-review text-white'
                        : 'bg-gray-300 hover:bg-gray-300'
                    } px-40 font-semibold text-16 my-8 ml-6`}
                    onClick={() => {
                      setCustomServiceDetails({
                        ...customServiceDetails,
                        name: item?.displayName,
                        isCustom: true,
                      });
                      setOpenDetailsDialogue(true);
                    }}
                  >
                    {item?.displayName}
                  </Button>
                );
              })}
            </div>
          )}
          <div>
            <TextField
              className="w-full font-semibold"
              placeholder={t('listManagement.placeHolder.typeCustomFields')}
              size="small"
              value={customServiceDetails?.name}
              onChange={(e) =>
                setCustomServiceDetails({
                  ...customServiceDetails,
                  name: e.target.value,
                  isCustom: true,
                })
              }
            />
            {customServiceDetails.name && !openDetailsDialogue && (
              <Button
                className="w-full flex justify-start font-bold pl-8 border border-gray border-solid rounded-md mt-8 text-left"
                onClick={() => {
                  setOpenDetailsDialogue(true);
                }}
              >
                <AddIcon className="text-16" /> {t('listManagement.add')}{' '}
                {customServiceDetails.name}
              </Button>
            )}
          </div>
        </DialogTitle>
      </Dialog>
      <Dialog
        classes={{
          paper: 'm-24 p-28 business-type-dropdown',
        }}
        open={openDetailsDialogue}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle className="p-0 mb-8">
          <div className="flex">
            <Icon
              onClick={() => {
                setOpenDetailsDialogue(false);
                setCustomServiceDetails({
                  name: '',
                  price: '',
                  priceType: '',
                  description: '',
                  isCustom: false,
                });
              }}
              className="text-24 absolute right-24 text-grey-400 font-normal cursor-pointer hover:text-gray-700 focus:text-gray-700"
            >
              cancel
            </Icon>
          </div>
          <div className="border-b-2 border-solid border-quick-review-100 pb-24">
            <Typography className="font-bold text-20">{googleBusinessData?.title}</Typography>
            <Typography className="text-gray text-16 font-semibold pb-8">
              {t('common.address')} : {googleBusinessData?.storefrontAddress?.addressLines?.[0]}{' '}
              {googleBusinessData?.storefrontAddress?.administrativeArea}{' '}
              {googleBusinessData?.storefrontAddress?.locality}{' '}
              {googleBusinessData?.storefrontAddress?.administrativeArea}{' '}
              {googleBusinessData?.storefrontAddress?.postalCode}
            </Typography>
          </div>
          <div>
            <Typography className="font-bold text-16 pt-16">
              {t('listManagement.addCustomServiceDetails')}
            </Typography>
          </div>
          <div className="mt-8">
            <Typography className="text-14 font-semibold mb-4">{t('common.name')}</Typography>
            <TextField
              className="w-full font-semibold"
              placeholder={t('listManagement.placeHolder.typeCustomFields')}
              size="small"
              value={customServiceDetails?.name}
              onChange={(e) =>
                setCustomServiceDetails({ ...customServiceDetails, name: e.target.value })
              }
            />
          </div>
          <div className="flex gap-16 mt-8">
            <div className="w-full">
              <Typography className="text-14 font-semibold">
                {t('listManagement.priceType')}
              </Typography>
              <Select
                className="w-full"
                value={customServiceDetails?.priceType || 1}
                size="small"
                onChange={(e) =>
                  setCustomServiceDetails({ ...customServiceDetails, priceType: e.target.value })
                }
              >
                <MenuItem value={1} disabled>
                  {t('listManagement.selectType')} ({t('signUpPage.optional')})
                </MenuItem>
                {priceData?.map((item, index) => {
                  return (
                    <MenuItem key={index} value={item}>
                      {item}
                    </MenuItem>
                  );
                })}
              </Select>
            </div>
            <div className="w-full">
              <Typography className="text-14 font-semibold">{t('listManagement.price')}</Typography>
              <TextField
                className="w-full"
                size="small"
                type="number"
                value={customServiceDetails.price}
                disabled={customServiceDetails?.priceType !== 'Fixed'}
                placeholder={t('listManagement.placeHolder.price')}
                onChange={(e) =>
                  setCustomServiceDetails({ ...customServiceDetails, price: e.target.value })
                }
              />
            </div>
          </div>
          <div className="w-full mt-8">
            <Typography className="text-14 font-semibold">
              {t('listManagement.description')}
            </Typography>
            <TextField
              className="w-full"
              multiline
              rows={5}
              value={customServiceDetails?.description}
              placeholder={t('listManagement.placeHolder.serviceDescription')}
              size="small"
              onChange={(e) =>
                setCustomServiceDetails({ ...customServiceDetails, description: e.target.value })
              }
            />
          </div>
          <div className="text-center">
            <Button
              className="bg-quick-review w-1/2 text-white rounded-full mt-16 px-40 disabled:text-white font-bold text-18 disabled:bg-gray hover:bg-quick-review"
              disabled={refreshIcon}
              onClick={() => onSubmit()}
            >
              {refreshIcon && (
                <CircularProgress className="text-quick-review absolute mx-auto" size={24} />
              )}
              Save
            </Button>
          </div>
        </DialogTitle>
      </Dialog>
    </div>
  );
};

export default ServiceSection;
