const getReviewsSentimentBarDataV1 = `
query ($businessId: Int!, $reviewPlatformId: Int!, $reviewsSentimentPeriodsId: Int) {
  getReviewsSentimentBarDataV1(businessId: $businessId, reviewPlatformId: $reviewPlatformId, reviewsSentimentPeriodsId: $reviewsSentimentPeriodsId) {
    message
    status
    data{
      id
      reviewsSentimentPeriodsId
      cts
      userBusinessId
      reviewsSentimentJsonV1 {
      key
      value
      }
    }
  }
}
`;

const getHomePageReviewBarChartData = `
query ($businessId: Int!, $reviewSentimentPeriodId: Int!) {
  getHomePageReviewBarChartData(businessId: $businessId, reviewSentimentPeriodId: $reviewSentimentPeriodId) {
    message
    status
     data{
      summary{
        key
        value
      }
      reviewCountData{
        key
        value
      }
    }
  }
}
`;

const getReviewHeatmapData = `
query ($businessId: Int!) {
  getReviewHeatmapData(businessId: $businessId) {
    status
    message
    data{
      userBusinessId
      reviewsHeatmapData{
        Rating
        Sentiment
        cumulativeRating
        cumulativeSentiment
        score
        timestamp
        totalReviews
      }
      reviewsHeatmapDataV1{
              all {
          score
          cumulativeSentiment
          cumulativeRating
          timestamp
          totalReviews
        }
        google {
          score
          cumulativeSentiment
          cumulativeRating
          timestamp
          totalReviews
        }
        yelp {
          score
          cumulativeSentiment
          cumulativeRating
          timestamp
          totalReviews
        }
        zomato {
          score
          cumulativeSentiment
          cumulativeRating
          timestamp
          totalReviews
        }

      }
    }
  }
}
`;

const getReviewSentimentPeriodsData = `
query {
  getReviewSentimentPeriodsData {
    message
    status
    data{
      id
      period
      name
      uts
      cts
    }
  }
}
`;

const getSentimentDataV1 = `
query ($businessId: Int, $reviewPlatformId: Int!, $reviewsSentimentPeriodsId: Int, $placeId: String) {
  getSentimentDataV1(businessId: $businessId, reviewPlatformId: $reviewPlatformId, reviewsSentimentPeriodsId: $reviewsSentimentPeriodsId, placeId: $placeId) {
    message
    status
    data{
      id
      reviewsSentimentPeriodsId
      userBusinessId
      reviewsSentimentJsonV1 {
        key
        value
      }
    }
  }
}
`;

const getReviewRatingDistributionV1 = `
query ($businessId: Int, $reviewPlatformId: Int!, $placeId: String) {
  getReviewRatingDistributionV1(businessId: $businessId, reviewPlatformId: $reviewPlatformId, placeId: $placeId) {
    message
    status
    data{
      id
      userBusinessId
      reviewsDistributionJsonV1{
        key
        value
      }
      user_businesses{
        amazon_product{
          rating
          totalReviews
        }
        flipkart_product{
          rating
          totalReviews
        }
        user_business_review_platforms{
          platform
          totalReviewRatings
          totalReviews
        }
      }
    }
  }
}
`;

const getReviewVelocityData = `
query ($businessId: Int!) {
  getReviewVelocityData(businessId: $businessId) {
    message
    status
    data{
      reviewsVelocityDataV1
      reviewsVelocityData{
        count
        isOwner
        name
        totalReviewRatings
        totalReviews
      }
      userBusinessId
      dateValidation {
        key
        value
      }
      uts
    }
  }
}
`;

const getReviewsAttributeComparisonV1 = `
query ($businessId: Int, $reviewPlatformId: Int!, $reviewsSentimentPeriodsId: Int, $placeId: String) {
  getReviewsAttributeComparisonV1(businessId: $businessId, reviewPlatformId: $reviewPlatformId, reviewsSentimentPeriodsId: $reviewsSentimentPeriodsId, placeId: $placeId) {
    message
    status
    data{
      comparisonJsonV1 {
        key
        value
      }
      userBusinessId
      reviewPlatformId
    }
  }
}
`;

const getCustomerSayData = `
query ($businessId: Int!, $reviewsSentimentPeriodsId: Int!) {
  getCustomerSayData(businessId: $businessId, reviewsSentimentPeriodsId: $reviewsSentimentPeriodsId) {
    message
    status
    data{
      attributesWithSentiment{
        attributeName
        sentimentColor
      }
      userBusinessesId
      reviewPlatformsId
      reviewsSentimentPeriodId
      summary
    }
  }
}
`;

const getCompetitorBenchmarking = `
query ($businessId: Int!, $reviewsSentimentPeriodsId: Int) {
  getCompetitorBenchmarking(businessId: $businessId, reviewsSentimentPeriodsId: $reviewsSentimentPeriodsId) {
    message
    status
    data{
      businessAttributeRatings
      competitorBenchmarkingV1 {
      overall {
        key
        value
      }
      competitor {
          name
          status
          placeId
          value{
            key
            value
          }
        }
      }
      businessAttributeRatingsV1{
        key
        value
      }
      cts
      id
      reviewsSentimentPeriodsId
      userBusinessId
      uts
    }
  }
}
`;

const getReviewsTrendsV1 = `
query ($businessId: Int!,$reviewPlatformId: Int!, $reviewSentimentPeriodsId: Int) {
  getReviewsTrendsV1(businessId: $businessId, reviewPlatformId: $reviewPlatformId, reviewSentimentPeriodsId: $reviewSentimentPeriodsId) {
    message
    status
    data{
      trendJsonV1 {
        key
        value
      }
      cts
      id
      reviewSentimentPeriodsId
      userBusinessId
    }
  }
}
`;

const getCustomerSatisfactionScoreV1 = `
query ($businessId: Int,$reviewPlatformId: Int!, $placeId: String) {
  getCustomerSatisfactionScoreV1(businessId: $businessId, reviewPlatformId: $reviewPlatformId, placeId: $placeId) {
    message
    status
    data{
     id
     placeId
     score
     userBusinessId
    }
  }
}
`;
const getMetricsInfoIconData = `
query{
  getMetricsInfoIconData{
    status
    message
    data{
      info{
        quickReviewCount
        reviewTrends
        receivedReview
        reviewSentiment
        reviewDistribution
        attributeComparison
        reviewSitesComparison
        competitorBenchmarking
        customerSatisfactionScore
        netPromoterScore
        reviewVelocity
        reviewsHeatmap
        customerSay
        googleVsYelp
        attributeSentiments
        reviewsCloud
        reviewsOverview
        reviewSentimentOverTime
        textVsNoText
        repliedVsNotReplied
      }
      quick_chat_info{
        averageResponseTime
        conversations
        queryInsights
      }
      quick_social_info{
        brandHealth
        engagement
        followersGrowth
      }
    }
  }
}
`;

const getBusinessAndProductTotalReviewRating = `query getBusinessAndProductTotalReviewRating($productId: Int, $productType: String, $userBusinessId: Int, $platformId: Int){
  getBusinessAndProductTotalReviewRating(productId: $productId, productType: $productType, userBusinessId: $userBusinessId, platformId: $platformId){
    data {
      aggregateData {
        aggregateAverageRating
        aggregateTotalReview
      }
      rating
      totalReviews
    }
    message
    status
  }
}`;

const getLineChartData = `
query getLineChartData($businessId: Int!){
  getLineChartData(businessId: $businessId) {
    data {
      userBusinessId
      id
      cts
      lineChartJson {
        google {
          month
          reviewrating
          timestamp
        }
        yelp {
          month
          reviewrating
          timestamp
        }
      }
    }
    message
    status
  }
}`;

const getReviewSentimentOverTime = `query getReviewsSentimentOverTimeDataV1($businessId: Int!,$reviewsSentimentPeriodsId: Int!, $reviewPlatformId: Int!) {
  getReviewsSentimentOverTimeDataV1(
    businessId: $businessId,
    reviewsSentimentPeriodsId: $reviewsSentimentPeriodsId,
    reviewPlatformId: $reviewPlatformId
  ) {
    message
    status
    data{
      id
      reviewSentimentOverTimeDataV1 {
        key
        value
      }
    }
  }
}
`;

const getAttributesSentimentChartDataV1 = `query getAttributesSentimentChartDataV1($businessId: Int!, $reviewsSentimentPeriodsId: Int!, $reviewPlatformId: Int!) {
  getAttributesSentimentChartDataV1(
    businessId: $businessId,
    reviewsSentimentPeriodsId: $reviewsSentimentPeriodsId, 
    reviewPlatformId: $reviewPlatformId
  ) {
    message
    status
    data{
      id
      cts
      reviewsSentimentPeriodsId
      userBusinessId
      attributesSentimentChartDataV1
     attributesSentimentChartData{
      sentiment{
        sentiment
        attribute
      }
      timePeriod
      timestampV1
    }
    }
  }
}
`;

const getNetPromoterScoreV1 = `query getNetPromoterScoreV1($businessId: Int, $reviewSentimentPeriodId: Int!, $reviewPlatformId: Int!, $placeId: String ){
  getNetPromoterScoreV1(businessId: $businessId, reviewSentimentPeriodId: $reviewSentimentPeriodId, reviewPlatformId: $reviewPlatformId, placeId: $placeId){
    status
    message
    data {
      id
      nps
      placeId
      userBusinessId
      scoreV1{
        key
        value
      }
    }
  }
}`;

const getQuickReviewsCountV3 = `query getQuickReviewsCountV3($placeId: String, $reviewPeriodId: Int!, $userBusinessId: Int, $reviewPlatformId: Int!){
  getQuickReviewsCountV3(placeId: $placeId, reviewPeriodId: $reviewPeriodId,userBusinessId: $userBusinessId, reviewPlatformId: $reviewPlatformId) {
    status
    data{
      id
      cts
      placeId
      reviewPeriodId
      userBusinessId
      reviewCountJsonV1 {
        key
        value
      }
    }
  }
}`;

const getRepliedVsNonRepliedMatrices = `
query ($userBusinessId: Int!, $reviewPlatformId: Int!, $reviewsSentimentPeriodId: Int!, $placeId: String){
  getRepliedVsNonRepliedMatrices(
    userBusinessId: $userBusinessId,
    reviewPlatformId: $reviewPlatformId,
    reviewsSentimentPeriodId: $reviewsSentimentPeriodId,
    placeId: $placeId
  ) {
    message
    status
    data {
      id
      cts
      userBusinessId
      reviewPlatformId
      reviewsSentimentPeriodId
     repliedVsNonrepliedData {
      key
      value
    }}
  }
}`;

const getTextVsNoTextMatrices = `query ($userBusinessId: Int!, $reviewPlatformId: Int!, $reviewsSentimentPeriodId: Int!, $placeId: String){
  getTextVsNoTextMatrices(userBusinessId:$userBusinessId,reviewPlatformId:$reviewPlatformId, reviewsSentimentPeriodId:$reviewsSentimentPeriodId, placeId: $placeId){
    message
    status
    data{
      id
      cts
      userBusinessId
      reviewPlatformId
      reviewsSentimentPeriodId
      textVsNotextData{
        key
        value
      }
    }
  }
}`;

const getReviewCloudData = `query getReviewCloudData(
  $businessId: Int!
  $reviewPeriodId: Int!
  $reviewPlatformId: Int!
){
  getReviewCloudData(
    businessId: $businessId
    reviewPeriodId: $reviewPeriodId
    reviewPlatformId: $reviewPlatformId
  ) {
    status
    message
    data {
      word
      sentiment
      wordOccurrence
    }
  }
}`;

const getReviewsOverview = `query getReviewsOverview(
  $businessId: Int!
  $reviewPeriodId: Int!
  $reviewPlatformId: Int!
){
  getReviewsOverview(
    businessId: $businessId
    reviewPeriodId: $reviewPeriodId
    reviewPlatformId: $reviewPlatformId
  ) {
   status
    message
    data {
      reviewsOverviewJson {
        changeInPercentage
        overallReviewsCount {
          startDate
          endDate
          reviewCount
        }
      }
      reviewsOverviewJsonV1
    }
  }}
`;

const getReviewHeatmap = `query getReviewHeatmapDataV1($businessId: Int!, $reviewPlatformId: Int!) {
  getReviewHeatmapDataV1(businessId: $businessId, reviewPlatformId: $reviewPlatformId){
    message
    status
    data{
     userBusinessId
     reviewsHeatmapData{
      score
      timestamp
      cumulativeSentiment
      totalReviews
      cumulativeRating
    }
    }
  }
}
`;

const addCompetitorBenchmarkingBusiness = `mutation addCompetitorBenchmarkingBusiness($businessName: String!, $geometry: String, $placeId: String!, $totalReviewRatings: Float, $totalReviews: Int, $url: String!, $userBusinessId: Int!){
addCompetitorBenchmarkingBusiness(businessName: $businessName, geometry: $geometry, placeId: $placeId, totalReviewRatings: $totalReviewRatings, totalReviews: $totalReviews, url: $url, userBusinessId: $userBusinessId){
 message
 status
}}`;

const getQuickChatConversationMatrix = `query ($botTypeId: Int!, $businessId: Int!){
  getQuickChatConversationMatrix(botTypeId: $botTypeId, businessId: $businessId) {
    status
    message
    data {
      summary {
        key
        value
      }
      conversations {
        key
        value
      }
    }
  }
}
`;

const getQuickChatAverageResponseTimeMatrix = `query ($botTypeId: Int!, $businessId: Int!){
  getQuickChatAverageResponseTimeMatrix(botTypeId: $botTypeId, businessId: $businessId) {
    status
    message
    data {
      filter
      response {
        key
        value {
          key
          value
        }
        summary {
          key
          value
        }
      }
    }
  }
}`;

const getQuickChatCountAndInsightsMatrix = `query ($botTypeId: Int!, $businessId: Int!){
  getQuickChatCountAndInsightsMatrix(botTypeId: $botTypeId, businessId: $businessId) {
    status
    message
    data {
     count
     name
    }
  }
}`;

const metricsData = {
  getReviewsSentimentBarDataV1,
  getHomePageReviewBarChartData,
  getReviewHeatmapData,
  getReviewSentimentPeriodsData,
  getSentimentDataV1,
  getReviewRatingDistributionV1,
  getReviewVelocityData,
  getReviewsAttributeComparisonV1,
  getCustomerSayData,
  getCompetitorBenchmarking,
  getReviewsTrendsV1,
  getCustomerSatisfactionScoreV1,
  getLineChartData,
  getMetricsInfoIconData,
  getBusinessAndProductTotalReviewRating,
  getReviewSentimentOverTime,
  getAttributesSentimentChartDataV1,
  getNetPromoterScoreV1,
  getQuickReviewsCountV3,
  getRepliedVsNonRepliedMatrices,
  getTextVsNoTextMatrices,
  getReviewCloudData,
  getReviewsOverview,
  getReviewHeatmap,
  addCompetitorBenchmarkingBusiness,
  getQuickChatConversationMatrix,
  getQuickChatAverageResponseTimeMatrix,
  getQuickChatCountAndInsightsMatrix,
};

export default metricsData;
