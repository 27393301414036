/* global FB */
import { Button, Typography, Icon } from '@mui/material';
import { t } from 'i18next';
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import './Waba.css';
import FuseLoading from '@fuse/core/FuseLoading';
import { selectDashboardData } from 'src/app/store/userSlice';
import WabaDashBoard from './WabaDashBoard';
import wabaQuery from '../../query/waba';
import { handleWabaApiRequest, getDecryptData } from '../../common/common';

const Waba = () => {
  const [loading, setLoading] = useState(false);
  const appKey = process.env.REACT_APP_WABA_APP_ID;
  const configId = process.env.REACT_APP_WABA_CONFIG_ID;
  const [whatsAppBusinessAccountId, setWhatsAppBusinessAccountId] = useState('');
  const [whatsAppPhoneNumberId, setWhatsAppPhoneNumberId] = useState('');
  const [tokenCode, setTokenCode] = useState();
  const [whatAppUserDetails, setWhatAppUserDetails] = useState({});
  const [userDetails, setUserDetails] = useState();
  const userCurrentBusinessDetails = useSelector(selectDashboardData);

  useEffect(() => {
    window.fbAsyncInit = function fbAsyncInit() {
      FB.init({
        appId: appKey,
        xfbml: true,
        version: 'v20.0',
      });
    };

    // Load the JavaScript SDK asynchronously
    (function loadSDK(d, s, id) {
      let js = d.getElementById(id);
      if (js) return;
      const fjs = d.getElementsByTagName(s)[0];
      js = d.createElement(s);
      js.id = id;
      js.src = 'https://connect.facebook.net/en_US/sdk.js';
      fjs.parentNode.insertBefore(js, fjs);
    })(document, 'script', 'facebook-jssdk');
  }, [appKey]);

  useEffect(() => {
    const sessionInfoListener = async (event) => {
      if (!event.origin.includes('facebook.com')) return;
      try {
        const data = JSON.parse(event.data);
        if (data.type === 'WA_EMBEDDED_SIGNUP') {
          if (data.event === 'FINISH') {
            const { phone_number_id: phoneNumberId, waba_id: wabaId } = data.data;
            await setWhatsAppBusinessAccountId(wabaId);
            await setWhatsAppPhoneNumberId(phoneNumberId);
            console.log('Finished Signup', phoneNumberId, wabaId);
          } else {
            const { current_step: currentStep } = data.data;
            console.log('Current Step', currentStep);
          }
        }
      } catch {
        console.log('Non JSON Response', event.data);
      }
    };

    window.addEventListener('message', sessionInfoListener);
    return () => {
      window.removeEventListener('message', sessionInfoListener);
    };
  }, []);

  useEffect(() => {
    const saveUserDetails = async () => {
      try {
        if (!userDetails && whatsAppPhoneNumberId) {
          const payload = {
            query: wabaQuery.saveWhatAppUserDetails,
            variables: {
              whatsAppBusinessAccountId,
              whatsAppPhoneNumberId,
              tokenCode,
              quickReviewBusinessId: parseInt(userCurrentBusinessDetails?.id, 10),
            },
          };
          const res = await handleWabaApiRequest(payload);

          if (res?.saveWhatAppUserDetails?.status === 200) {
            setWhatAppUserDetails(res?.saveWhatAppUserDetails);
          }
        }
      } catch (error) {
        setLoading(false);
        console.error('An error occurred:', error);
      }
    };
    saveUserDetails();
  }, [
    whatsAppBusinessAccountId,
    whatsAppPhoneNumberId,
    tokenCode,
    userDetails,
    userCurrentBusinessDetails?.id,
  ]);

  useEffect(() => {
    const fetchUserDetails = async () => {
      if (userCurrentBusinessDetails?.id) {
        setLoading(true);

        try {
          const payload = {
            query: wabaQuery.getUserDetail,
            variables: {
              quickReviewBusinessId: userCurrentBusinessDetails?.id,
            },
          };
          const res = await handleWabaApiRequest(payload);
          setLoading(false);
          if (res?.getUserDetail?.status === 200) {
            if (res?.getUserDetail?.data) {
              const decryptedData = JSON.parse(getDecryptData(res.getUserDetail.data));
              setUserDetails(decryptedData);
              setWhatsAppPhoneNumberId(decryptedData?.whatsAppPhoneNumberId);
            }
          }
        } catch (error) {
          setLoading(false);
          console.error('An error occurred:', error);
        }
      }
    };
    fetchUserDetails();
  }, [whatAppUserDetails, userCurrentBusinessDetails?.id]);

  const launchWhatsAppSignup = () => {
    FB.login(
      function handleFBLogin(response) {
        if (response.authResponse) {
          const { code } = response.authResponse;
          console.log('CODE', code);
          setTokenCode(code);
        } else {
          console.log('User cancelled login or did not fully authorize.');
        }
      },
      {
        config_id: configId,
        response_type: 'code',
        override_default_response_type: true,
        extras: {
          feature: 'whatsapp_embedded_signup',
          sessionInfoVersion: 2,
        },
      }
    );
  };

  if (loading) {
    return <FuseLoading />;
  }

  return (
    <>
      {userDetails?.waAccessToken ? (
        <div>
          <WabaDashBoard status={userDetails?.status} />
        </div>
      ) : (
        <div className="lg:p-24 p-16">
          <>
            <div className="bg-white rounded-md py-28 lg:px-80 p-16 mb-28">
              <div className="grid xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-2 sm:grid-cols-1 grid-cols-1 gap-36 items-center">
                <div>
                  <h2 className="sm:text-32 text-24 font-bold mb-32">{t('waba.title')}</h2>
                  <Typography className="font-medium text-16 lg:text-18 max-w-586">
                    {t('waba.desc')}
                  </Typography>

                  <Button
                    onClick={launchWhatsAppSignup}
                    className="rounded-md mt-36 sm:mt-56 w-full xs:w-auto text-14 xs:text-16"
                    variant="contained"
                    color="quickChat"
                  >
                    <Icon className="ml-3">add</Icon> {t('waba.btnLabel')}
                  </Button>
                </div>
                <div>
                  <img
                    src="/assets/images/business/waba.png"
                    className="img-fluid w-full mx-auto max-w-586"
                    alt="..."
                  />
                </div>
              </div>
            </div>

            <div className="bg-white rounded-md py-28 lg:px-80 p-20">
              <div className="grid xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-2 sm:grid-cols-1 grid-cols-1 gap-28 items-center mb-80">
                <div>
                  <img
                    src="/assets/images/business/waba_1.png"
                    className="img-fluid w-full max-w-512"
                    alt="..."
                  />
                </div>
                <div>
                  <h2 className="sm:text-32 text-24 font-semibold mb-32">
                    {t('waba.feature.title')}
                  </h2>
                  <ul className="list-none sm:text-18 text-16 font-medium">
                    <li
                      className="mb-20"
                      dangerouslySetInnerHTML={{
                        __html: t('waba.feature.fea1'),
                      }}
                    />
                    <li
                      className="mb-20"
                      dangerouslySetInnerHTML={{
                        __html: t('waba.feature.fea2'),
                      }}
                    />
                    <li
                      className="mb-20"
                      dangerouslySetInnerHTML={{
                        __html: t('waba.feature.fea3'),
                      }}
                    />
                    <li
                      dangerouslySetInnerHTML={{
                        __html: t('waba.feature.fea4'),
                      }}
                    />
                  </ul>
                </div>
              </div>
              <div className="grid xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-2 sm:grid-cols-1 grid-cols-1 gap-28 items-center">
                <div>
                  <h2 className="sm:text-32 text-24 font-semibold mb-32">
                    {t('waba.feature_1.title')}
                  </h2>
                  <ul className="list-none sm:text-18 text-16 font-medium">
                    <li
                      className="mb-20"
                      dangerouslySetInnerHTML={{
                        __html: t('waba.feature_1.fea1'),
                      }}
                    />
                    <li
                      className="mb-20"
                      dangerouslySetInnerHTML={{
                        __html: t('waba.feature_1.fea2'),
                      }}
                    />
                    <li
                      className="mb-20"
                      dangerouslySetInnerHTML={{
                        __html: t('waba.feature_1.fea3'),
                      }}
                    />
                    <li
                      dangerouslySetInnerHTML={{
                        __html: t('waba.feature_1.fea4'),
                      }}
                    />
                  </ul>
                </div>
                <div>
                  <img
                    src="/assets/images/business/waba_2.png"
                    className="img-fluid w-full max-w-512 ml-auto"
                    alt="..."
                  />
                </div>
              </div>
            </div>
          </>
        </div>
      )}
    </>
  );
};

export default Waba;
