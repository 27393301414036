import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Icon,
  InputLabel,
  TextField,
  Typography,
} from '@mui/material';
import { t } from 'i18next';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import history from '@history';
import { useCallback, useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import UserProfile from 'src/app/main/common/userProfile';
import { selectDashboardData } from '../../../../store/userSlice';
import manageBusinessData from '../../../query/manageBusiness';
import { capitalizeFirstLetter, getParamsId, handleApiRequest } from '../../../common/common';
import DeletePopUp from '../../manage-business/PopUp';
import Loading from '../../manage-business/Loading';

const StaffProfile = () => {
  const [staffDetails, setStaffDetails] = useState();
  const [pageLoading, setPageLoading] = useState(true);
  const [qrData, setQrData] = useState([]);
  const [openDialogue, setOpenDialogue] = useState(false);
  const [openDeleteDialogue, setOpenDeleteDialogue] = useState(false);
  const [isEditStaff, setIsEditStaff] = useState(false);
  const [refreshIcon, setRefreshIcon] = useState(false);
  const [agentData, setAgentData] = useState({
    name: '',
    role: '',
  });
  const [isEmpty, setIsEmpty] = useState({ name: false, role: false });
  const userCurrentBusinessDetails = useSelector(selectDashboardData);
  const { enqueueSnackbar } = useSnackbar();
  const paramsId = useParams();
  const fetchAgentData = useCallback(async () => {
    if (userCurrentBusinessDetails?.id) {
      setPageLoading(true);
      const payload = {
        query: manageBusinessData.getBusinessAgentList,
        variables: {
          businessId: parseInt(userCurrentBusinessDetails?.id, 10),
        },
      };
      const result = await handleApiRequest(payload);
      setPageLoading(false);
      if (result?.getBusinessAgentList?.status === 200) {
        const data = result.getBusinessAgentList?.data;
        const findStaffDetails = data.find(
          (item) => parseInt(item.id, 10) === parseInt(paramsId.profileId, 10)
        );
        setStaffDetails(findStaffDetails);
        setAgentData({
          name: findStaffDetails?.name,
          role: findStaffDetails?.role,
        });
      } else {
        enqueueSnackbar(result?.getBusinessAgentList?.message, {
          variant: 'error',
          autoHideDuration: 3000,
        });
      }
    }
  }, [userCurrentBusinessDetails?.id, enqueueSnackbar, paramsId.profileId]);

  useEffect(() => {
    const fetchBusinessData = async () => {
      setPageLoading(true);
      const payload = {
        query: manageBusinessData.getBusinessQRIdBaseOnBusinessId,
        variables: {
          businessId: parseInt(userCurrentBusinessDetails?.id, 10),
        },
      };
      const result = await handleApiRequest(payload);
      setPageLoading(false);
      if (result?.getBusinessQRIdBaseOnBusinessId?.status === 200) {
        setQrData(result?.getBusinessQRIdBaseOnBusinessId?.data);
      } else {
        enqueueSnackbar(result?.getBusinessQRIdBaseOnBusinessId?.message, {
          variant: 'error',
          autoHideDuration: 3000,
        });
      }
    };
    if (userCurrentBusinessDetails?.id) {
      fetchBusinessData();
    }
  }, [userCurrentBusinessDetails?.id, enqueueSnackbar]);

  useEffect(() => {
    fetchAgentData();
  }, [fetchAgentData]);

  const assignQrCode = async (value) => {
    setRefreshIcon(true);
    const payload = {
      query: manageBusinessData.addBusinessQRIdToBusinessAgent,
      variables: {
        agentId: parseInt(paramsId.profileId, 10),
        businessQRId: parseInt(value.id, 10),
      },
    };
    const result = await handleApiRequest(payload);
    setRefreshIcon(false);
    if (result?.addBusinessQRIdToBusinessAgent?.status === 200) {
      fetchAgentData();
    } else {
      enqueueSnackbar(result?.addBusinessQRIdToBusinessAgent?.message, {
        variant: 'error',
        autoHideDuration: 3000,
      });
    }
  };

  const handleCancel = () => {
    setOpenDialogue(false);
  };

  const deleteAssignQrCode = async () => {
    const payload = {
      query: manageBusinessData.deleteQRCode,
      variables: {
        agentId: parseInt(paramsId.profileId, 10),
      },
    };
    const result = await handleApiRequest(payload);
    if (result?.deleteQRCode?.status === 200) {
      fetchAgentData();
      handleCancel();
    } else {
      enqueueSnackbar(result?.deleteQRCode?.message, {
        variant: 'error',
        autoHideDuration: 3000,
      });
    }
  };

  const deleteStaffMember = async () => {
    if (paramsId.profileId) {
      setRefreshIcon(true);
      const payload = {
        query: manageBusinessData.deleteBusinessAgent,
        variables: {
          businessAgentId: parseInt(paramsId.profileId, 10),
        },
      };
      const result = await handleApiRequest(payload);
      setRefreshIcon(false);
      if (result?.deleteBusinessAgent?.status === 200) {
        setOpenDeleteDialogue(false);
        const id = getParamsId();
        if (id) {
          history.push(`quick-reviews/staff/${id}`);
        } else {
          history.push('quick-reviews/staff');
        }
      }
    }
  };

  const cancelDeleteStaff = () => {
    setOpenDeleteDialogue(false);
  };

  const handleInputChange = (field) => (e) => {
    const { value } = e.target;

    setAgentData((prevAgentData) => ({
      ...prevAgentData,
      [field]: value,
    }));

    setIsEmpty((prevIsEmpty) => ({
      ...prevIsEmpty,
      [field]: value.trim() === '',
    }));
  };

  const editStaffMember = async () => {
    const isNumeric = (str) => /^\d+$/.test(str);
    if (isNumeric(agentData?.name) || isNumeric(agentData?.role)) {
      enqueueSnackbar(t('manageBusiness.addBusinessSteps.staffMember.validationMessage'), {
        variant: 'error',
        autoHideDuration: 3000,
      });
      return;
    }
    setRefreshIcon(true);
    const payload = {
      query: manageBusinessData.updateBusinessAgent,
      variables: {
        businessId: parseInt(userCurrentBusinessDetails?.id, 10),
        name: agentData?.name,
        role: agentData?.role,
        businessAgentId: parseInt(paramsId.profileId, 10),
      },
    };
    const result = await handleApiRequest(payload);
    setRefreshIcon(false);
    const res = result?.updateBusinessAgent;
    if (res?.status === 200) {
      fetchAgentData();
      handleRemove();
    } else {
      enqueueSnackbar(res?.message, {
        variant: 'error',
        autoHideDuration: 3000,
      });
    }
  };

  const handleRemove = () => {
    setIsEditStaff(false);
    setAgentData({
      name: '',
      role: '',
    });
  };

  if (pageLoading) {
    return <Loading />;
  }

  return (
    <div className="lg:p-24 p-16">
      <div className="flex items-center gap-12">
        <ArrowBackIosNewIcon
          className="cursor-pointer"
          onClick={() => {
            const id = getParamsId();
            if (id) {
              history.push(`quick-reviews/staff/${id}`);
            } else {
              history.push('quick-reviews/staff');
            }
          }}
        />
        <Typography
          initial={{ x: -20 }}
          animate={{ x: 0, transition: { delay: 0.2 } }}
          delay={300}
          className="sm:flex text-20 md:text-28 font-bold"
        >
          {t('staff.profile')}
        </Typography>
      </div>
      <div className="sm:flex sm:items-center justify-between  bg-white p-24 sm:p-40 mt-20 mb-16">
        <div className="flex items-center gap-24">
          <div>
            {staffDetails?.name && (
              <UserProfile
                name={staffDetails?.name}
                width="75px"
                height="75px"
                fontSize="35px"
                fontWeight="bold"
              />
            )}
          </div>
          <div>
            <Typography className="font-bold text-20">
              {staffDetails?.name && capitalizeFirstLetter(staffDetails.name)}
            </Typography>
            <Typography className="font-normal text-14 pt-8">
              {staffDetails?.role && capitalizeFirstLetter(staffDetails.role)}
            </Typography>
          </div>
        </div>
        <div className="mt-12 flex gap-16 flex-col sm:flex-row   sm:mt-0">
          <Button
            className="bg-quick-review-100 rounded-3xl md:px-36 px-20 font-bold text-quick-review"
            onClick={() => setIsEditStaff(true)}
          >
            {t('staff.button.editDetails')}
          </Button>
          <Button
            className="bg-red-A500 rounded-3xl md:px-36 px-20 text-red hover:bg-red-A500 font-bold"
            onClick={() => setOpenDeleteDialogue(true)}
          >
            {t('staff.button.deleteProfile')}
          </Button>
        </div>
      </div>
      <div className="bg-white p-24 sm:p-40 mt-20">
        <Typography className="font-bold text-20">{t('staff.availableQrCodes')}</Typography>
        <hr className="border border-dashed my-16 !border-gray" />
        {staffDetails?.businessQRId ? (
          <div className="flex items-center justify-evenly flex-col gap-10  sm:0 sm:flex-row sm:justify-between mt-16 bg-gray-100 p-16 rounded-lg">
            <Typography className="font-semibold text-16">
              {staffDetails?.businessQRId.toUpperCase()}
            </Typography>
            <Button
              className="bg-red-A500 rounded-md sm:rounded-3xl px-6 sm:px-36 hover:bg-red-A500 font-semibold text-red"
              onClick={() => setOpenDialogue(true)}
            >
              {t('staff.button.deleteQrCode')}
            </Button>
          </div>
        ) : (
          <>
            {qrData?.length > 0 ? (
              qrData?.map((item, i) => {
                return (
                  <div
                    className="flex items-center justify-evenly flex-col sm:flex-row gap-10  sm:0 sm:justify-between mt-16 bg-gray-100 p-16 rounded-lg"
                    key={i}
                  >
                    <Typography className="font-semibold text-16 pb-10 sm:pb-0">
                      {item.businessQRId.toUpperCase()}
                    </Typography>
                    <Button
                      className="bg-quick-review-100 rounded-md sm:rounded-3xl px-6 sm:px-36 font-bold text-quick-review"
                      onClick={() => assignQrCode(item)}
                    >
                      {t('staff.button.assignQrCode')}
                    </Button>
                  </div>
                );
              })
            ) : (
              <Typography className="text-center font-bold">{t('staff.noQrCode')}</Typography>
            )}
          </>
        )}
      </div>
      <DeletePopUp
        openValue={openDialogue}
        eventCall={deleteAssignQrCode}
        cancelCall={handleCancel}
        refreshIcon={refreshIcon}
        popUpMessage={t('staff.deleteAssignedQrCode')}
        popUpDescription={t('staff.deleteMessage')}
        popUpButtonName={t('staff.button.deleteCode')}
      />
      <DeletePopUp
        openValue={openDeleteDialogue}
        eventCall={deleteStaffMember}
        cancelCall={cancelDeleteStaff}
        refreshIcon={refreshIcon}
        popUpMessage={t('manageBusiness.addBusinessSteps.staffMember.confirmStaffDeletion')}
        popUpDescription={t('manageBusiness.addBusinessSteps.staffMember.deleteMessage')}
        popUpButtonName={t('manageBusiness.addBusinessSteps.staffMember.dialog.deleteAgent')}
      />
      <Dialog
        classes={{
          paper:
            'm-24 p-28 business-type-dropdown border-t-8 border-solid border-t-quick-review border-quick-review rounded-md',
        }}
        open={isEditStaff}
        fullWidth
        maxWidth="xs"
      >
        <DialogTitle className="p-0 mb-28">
          <Icon
            onClick={handleRemove}
            className="text-24 absolute top-12 right-12 text-grey-400 font-normal cursor-pointer hover:text-gray-700 focus:text-gray-700"
          >
            cancel
          </Icon>
          <Typography className="text-20 font-semibold text-black border-b-1 border-dashed border-grey-500 w-full pb-10">
            {t('common.edit')} {t('manageBusiness.addBusinessSteps.staffMember.dialog.staff')}
          </Typography>
        </DialogTitle>

        <form noValidate className="flex flex-col md:overflow-hidden">
          <DialogContent className="p-0 mb-24">
            <div className="block mb-24">
              <InputLabel
                htmlFor="bAttribute"
                className="text-16 font-medium mb-12 text-grey-900 flex items-center justify-between"
              >
                {t('staff.field.staff.name')}
              </InputLabel>
              <TextField
                placeholder={t('staff.field.staff.placeholder')}
                onChange={handleInputChange('name')}
                value={agentData?.name}
                name="name"
                size="small"
                id="bAttribute"
                variant="outlined"
                required
                fullWidth
              />
              {isEmpty.name && (
                <Typography className="text-red pt-8 text-14">
                  {t('staff.field.staff.placeholder')}
                </Typography>
              )}
            </div>
            <div className="block">
              <InputLabel htmlFor="bAttribute" className="text-16 font-medium mb-12 text-grey-900">
                {t('staff.field.role.name')}
              </InputLabel>
              <TextField
                placeholder={t('staff.field.role.placeholder')}
                onChange={handleInputChange('role')}
                value={agentData?.role}
                name="role"
                size="small"
                id="bAttribute"
                variant="outlined"
                required
                fullWidth
              />
              {isEmpty.role && (
                <Typography className="text-red pt-8 text-14">
                  {t('staff.field.role.placeholder')}
                </Typography>
              )}
            </div>
          </DialogContent>
          <DialogActions className="p-0 justify-center">
            <Button
              className="md:text-16 font-medium disabled:text-black rounded-md w-full xs:w-256"
              variant="contained"
              color="secondary"
              type="button"
              size="small"
              disabled={
                !agentData?.name ||
                !agentData?.role ||
                (agentData?.name === staffDetails?.name && agentData?.role === staffDetails?.role)
              }
              onClick={() => editStaffMember()}
            >
              {t('common.save')}
              {refreshIcon && (
                <CircularProgress size={24} className="text-quick-review absolute mx-auto" />
              )}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
};

export default StaffProfile;
