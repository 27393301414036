import { Button, CircularProgress, Switch, Typography } from '@mui/material';
import { t } from 'i18next';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { renderTimeViewClock, TimePicker } from '@mui/x-date-pickers';
import BedtimeIcon from '@mui/icons-material/Bedtime';
import { useLocation } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import history from '@history';
import FuseLoading from '@fuse/core/FuseLoading';
import { handleApiRequest } from '../../../../../common/common';
import manageBusinessData from '../../../../../query/manageBusiness';
import quickHub from '../../../../../query/quickHub';
import LabelOrBusinessDrawer from '../../reports/LabelOrBusinessDrawer';
import ErrorPopUp from '../../common/ErrorPopUp';

const OpeningAndClosingHours = () => {
  const daysOfWeek = useMemo(
    () => ['MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY', 'SATURDAY', 'SUNDAY'],
    []
  );
  const location = useLocation();
  const [timeData, setTimeData] = useState();
  const [refreshIcon, setRefreshIcon] = useState(false);
  const [googleBusinessData, setGoogleBusinessData] = useState();
  const [loading, setLoading] = useState(false);
  const [openBusinessDrawer, setOpenBusinessDrawer] = useState(false);
  const [openErrorDialog, setOpenErrorDialog] = useState(false);
  const [failedRequests, setFailedRequests] = useState([]);
  const [selectLabelOrBusiness, setSelectLabelOrBusiness] = useState(
    location?.state?.selectBusiness || []
  );
  const { enqueueSnackbar } = useSnackbar();

  const fetchBusinessDetails = useCallback(async () => {
    setLoading(true);
    try {
      const payload = {
        query: manageBusinessData.getUserBusinessesGoogleDetails,
        variables: {
          businessId: Number(selectLabelOrBusiness?.[0]?.id),
        },
      };
      const result = await handleApiRequest(payload);
      setLoading(false);
      if (result?.getUserBusinessesGoogleDetails?.status === 200) {
        const data = result.getUserBusinessesGoogleDetails?.data;
        const parsedData = JSON.parse(data);
        setGoogleBusinessData(parsedData);
      } else {
        enqueueSnackbar(result?.getUserBusinessesGoogleDetails?.message, {
          variant: 'error',
          autoHideDuration: 3000,
        });
      }
    } catch (error) {
      setLoading(false);
      console.error('Error fetching business details:', error);
    }
  }, [enqueueSnackbar, selectLabelOrBusiness]);

  useEffect(() => {
    if (!location?.state?.isBulk) {
      fetchBusinessDetails();
    }
  }, [fetchBusinessDetails, location]);

  const formatTime = (time) => {
    if (time && time.hour && time.minute) {
      const date = new Date();
      date.setHours(parseInt(time.hour, 10), parseInt(time.minute, 10), 0);
      return date;
    }
    return null;
  };

  const formatGoogleBusinessHours = (googleBusinessHours, days) => {
    const dayTimeData = days?.reduce((acc, day) => {
      acc[day] = { start: { hour: '', minute: '' }, end: { hour: '', minute: '' }, open: false };
      return acc;
    }, {});

    if (googleBusinessHours?.regularHours?.periods) {
      const updatedTimeData = { ...dayTimeData };
      googleBusinessHours.regularHours.periods?.forEach((period) => {
        const { openDay, closeDay, openTime, closeTime } = period;
        updatedTimeData[openDay] = {
          start: {
            hour: openTime?.hours ? String(openTime.hours).padStart(2, '0') : '00',
            minute: openTime?.minutes ? String(openTime.minutes).padStart(2, '0') : '00',
          },
          end: updatedTimeData[openDay]?.end,
          open: true,
        };
        updatedTimeData[closeDay] = {
          start: updatedTimeData[closeDay]?.start,
          end: {
            hour: closeTime?.hours ? String(closeTime.hours).padStart(2, '0') : '00',
            minute: closeTime?.minutes ? String(closeTime.minutes).padStart(2, '0') : '00',
          },
          open: true,
        };
      });
      return updatedTimeData;
    }
    return dayTimeData;
  };

  useEffect(() => {
    const updatedTimeData = formatGoogleBusinessHours(googleBusinessData, daysOfWeek);
    setTimeData(updatedTimeData);
  }, [googleBusinessData, daysOfWeek]);

  const handleToggleDay = (day) => {
    setTimeData((prevState) => ({
      ...prevState,
      [day]: {
        ...prevState?.[day],
        open: !prevState?.[day]?.open,
        ...(!prevState?.[day]?.open && {
          start: { hour: '00', minute: '00' },
          end: { hour: '00', minute: '00' },
        }),
      },
    }));
  };

  const handleSelectStartTime = (day, data) => {
    const selectedDate = new Date(data);
    const hours = selectedDate.getHours();
    const minutes = selectedDate.getMinutes();
    setTimeData((prevData) => ({
      ...prevData,
      [day]: {
        ...prevData[day],
        start: {
          hour: hours ? String(hours).padStart(2, '0') : '00',
          minute: minutes ? String(minutes).padStart(2, '0') : '00',
        },
      },
    }));
  };

  const handleSelectCloseTime = (day, data) => {
    const selectedDate = new Date(data);
    const hours = selectedDate.getHours();
    const minutes = selectedDate.getMinutes();
    setTimeData((prevData) => ({
      ...prevData,
      [day]: {
        ...prevData[day],
        end: {
          hour: hours ? String(hours).padStart(2, '0') : '00',
          minute: minutes ? String(minutes).padStart(2, '0') : '00',
        },
      },
    }));
  };

  const formatHoursFor = (hours) => {
    return JSON.stringify(
      Object.entries(hours)
        ?.filter(([day, dayData]) => dayData?.open)
        .map(([day, times]) => ({
          openDay: day,
          closeDay: day,
          openTime: {
            hours: Number(times.start.hour) || 0,
            minutes: Number(times.start.minute) || 0,
          },
          closeTime: {
            hours: Number(times.end.hour) || 0,
            minutes: Number(times.end.minute) || 0,
          },
        }))
    );
  };

  const onSubmit = async () => {
    setRefreshIcon(true);
    try {
      const payload = {
        query: quickHub.updateGoogleBusinessDetailsForBusinesses,
        variables: {
          businessIds: selectLabelOrBusiness?.map((business) => business?.id),
          mask: 'openingClosingHours',
          data: formatHoursFor(timeData),
        },
      };
      const result = await handleApiRequest(payload);
      setRefreshIcon(false);
      if (result?.updateGoogleBusinessDetailsForBusinesses?.data?.failedRequests?.length > 0) {
        setOpenErrorDialog(true);
        setFailedRequests(result?.updateGoogleBusinessDetailsForBusinesses?.data?.failedRequests);
      }
      if (result?.updateGoogleBusinessDetailsForBusinesses?.status === 200) {
        if (location?.state?.isBulk) {
          history.push('quick-hub/primaryDetails', location?.state?.activeTab);
          enqueueSnackbar(result?.updateGoogleBusinessDetailsForBusinesses?.message, {
            variant: 'success',
            autoHideDuration: 3000,
          });
        } else {
          fetchBusinessDetails();
          enqueueSnackbar(result?.updateGoogleBusinessDetailsForBusinesses?.message, {
            variant: 'success',
            autoHideDuration: 3000,
          });
        }
      } else if (result?.updateGoogleBusinessDetailsForBusinesses?.message !== 'Bad request') {
        enqueueSnackbar(result?.updateGoogleBusinessDetailsForBusinesses?.message, {
          variant: 'error',
          autoHideDuration: 3000,
        });
      }
    } catch (error) {
      setRefreshIcon(false);
      console.error('Error updating business hours:', error);
    }
  };

  if (loading) {
    return <FuseLoading />;
  }

  const handleRedirect = () => {
    history.push('quick-hub/primaryDetails', location?.state?.activeTab);
  };

  return (
    <div className="bg-gray-A500 h-full p-16 sm:p-24">
      <Typography className="font-semibold text-16 md:text-24 flex items-center gap-4">
        {' '}
        <ArrowBackIcon className="cursor-pointer" onClick={() => handleRedirect()} />
        {t('primaryDetails.setBusinessOpeningAndClosingHours')}
      </Typography>
      <div className="md:w-[900px]">
        <div className="bg-white rounded-md mt-16 w-full">
          <Typography className="font-semibold text-14 px-20 pt-20 md:text-18">
            {t('primaryDetails.selectWorkingAndClosingHour')}
          </Typography>
          <div className="p-20">
            {daysOfWeek?.map((day, i) => {
              return (
                <div className="sm:flex mb-8 gap-8 md:gap-16 items-center" key={i}>
                  <div className="flex min-w-200 items-center gap-16 justify-between">
                    <Typography className="font-semibold text-14 pt-8">{day}</Typography>
                    <Typography className="pt-12 text-14 font-normal">
                      {timeData?.[day]?.open ? t('primaryDetails.open') : t('primaryDetails.close')}
                      <Switch
                        className="ml-4"
                        checked={timeData?.[day]?.open}
                        onChange={() => handleToggleDay(day)}
                      />
                    </Typography>
                  </div>
                  {timeData?.[day]?.open ? (
                    <div className="flex w-full items-center justify-between gap-8 md:gap-16">
                      <div>
                        <TimePicker
                          className="h-24 md:w-288"
                          id={`time-${day}-start`}
                          required
                          value={formatTime(timeData?.[day]?.start)}
                          onChange={(data) => handleSelectStartTime(day, data)}
                          viewRenderers={{
                            hours: renderTimeViewClock,
                            minutes: renderTimeViewClock,
                            seconds: renderTimeViewClock,
                          }}
                        />
                      </div>
                      <Typography className="text-14 font-normal pt-8">
                        {t('reports.to')}
                      </Typography>
                      <div>
                        <TimePicker
                          className="h-24 md:w-288"
                          id={`time-${day}-end`}
                          required
                          value={formatTime(timeData?.[day]?.end)}
                          onChange={(data) => handleSelectCloseTime(day, data)}
                          viewRenderers={{
                            hours: renderTimeViewClock,
                            minutes: renderTimeViewClock,
                            seconds: renderTimeViewClock,
                          }}
                        />
                      </div>
                    </div>
                  ) : (
                    <div className="bg-gray-A500 h-40 flex items-center w-full md:w-288 rounded-md border border-solid border-gray-400 mt-12 p-4">
                      <BedtimeIcon className="text-gray ml-8 mr-10 text-20" />{' '}
                      {t('primaryDetails.closed')}
                    </div>
                  )}
                </div>
              );
            })}
          </div>
          {location?.state?.isBulk && (
            <div className="pt-20">
              <hr className="my-8 border border-gray" />
              <div className="flex justify-between items-center px-20 py-12">
                <Typography className="font-semibold text-18">
                  {t('primaryDetails.selectBusiness')}
                </Typography>
                <div className="flex gap-12 items-center">
                  <Typography className="font-medium text-16">
                    {`${t('primaryDetails.selectedBusiness')} (${selectLabelOrBusiness?.length})`}
                  </Typography>
                  <Button
                    className="rounded-md w-112"
                    variant="outlined"
                    color="secondary"
                    onClick={() => setOpenBusinessDrawer(true)}
                  >
                    {t('common.edit')}
                  </Button>
                </div>
              </div>
            </div>
          )}
        </div>
        <div className="text-end mt-16">
          <Button
            className="rounded-md w-160 font-semibold text-16"
            variant="contained"
            color="secondary"
            onClick={() => onSubmit()}
            disabled={refreshIcon}
          >
            {t('common.save')}
            {refreshIcon && (
              <CircularProgress className="text-quick-review absolute mx-auto" size={24} />
            )}
          </Button>
        </div>
      </div>
      <LabelOrBusinessDrawer
        openBusinessDrawer={openBusinessDrawer}
        setOpenBusinessDrawer={setOpenBusinessDrawer}
        setSelectLabelOrBusiness={setSelectLabelOrBusiness}
        selectLabelOrBusiness={selectLabelOrBusiness}
        groupType="business"
        isGoogleConnected
        activeTab={location.state?.activeTab}
      />
      <ErrorPopUp
        openErrorDialog={openErrorDialog}
        setOpenErrorDialog={setOpenErrorDialog}
        failedRequests={failedRequests}
        setFailedRequests={setFailedRequests}
      />
    </div>
  );
};

export default OpeningAndClosingHours;
