import {
  Button,
  MenuItem,
  Select,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Tabs,
  Typography,
} from '@mui/material';
import { t } from 'i18next';
import history from '@history';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import withReducer from 'app/store/withReducer';
import { useLocation } from 'react-router-dom';
import FuseLoading from '@fuse/core/FuseLoading';
import { selectDashboardData } from 'src/app/store/userSlice';
import Pagination from '../../../../component/table/Pagination';
import TableHeader from '../../../../component/table/TableHeader';
import { capitalizeFirstLetter, getParamsId, handleApiRequest } from '../../../common/common';
import businessData from '../../../query/business';
import { storeViewMessageDetails } from '../store/targetMessageSlice';
import reducer from '../store';

const History = () => {
  const bulkHeaderData = [
    t('common.srNo'),
    t('inviteReviews.titles'),
    t('inviteReviews.date'),
    t('inviteReviews.users'),
    t('inviteReviews.plateForm'),
    t('common.status'),
    t('inviteReviews.actions'),
  ];

  const individualHeaderData = [
    t('common.srNo'),
    t('inviteReviews.templateName'),
    t('common.firstName'),
    t('inviteReviews.date'),
    t('inviteReviews.plateForm'),
    t('common.status'),
    t('inviteReviews.actions'),
  ];

  const [tabValue, setTabValue] = useState(0);
  const [loading, setLoading] = useState(false);
  const [messageData, setMessageData] = useState([]);
  const [totalCount, setTotalCount] = useState();
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const dispatch = useDispatch();
  const location = useLocation();
  const userCurrentBusinessDetails = useSelector(selectDashboardData);

  useEffect(() => {
    const id = getParamsId();
    setLoading(true);
    async function fetchData() {
      const payload = {
        query: businessData.getSendCampaignMessages,
        variables: {
          servicesType:
            location.pathname === '/invite-review-history' ||
            location.pathname === `/invite-review-history/${id}`
              ? 'invite_reviews'
              : 'send_messages',
          messageType: 'individual_message',
          userBusinessId: parseInt(userCurrentBusinessDetails?.id, 10),
        },
      };
      const result = await handleApiRequest(payload);
      setLoading(false);
      if (result?.getSendCampaignMessages?.status === 200) {
        setMessageData(result?.getSendCampaignMessages?.data);
        setTotalCount(result?.getSendCampaignMessages?.totalCount);
      }
    }
    fetchData();
  }, [location.pathname, userCurrentBusinessDetails?.id]);

  const handleTabChange = async (event, newValue) => {
    setPage(1);
    setRowsPerPage(10);
    setTabValue(newValue);
    const id = getParamsId();
    const payload = {
      query: businessData.getSendCampaignMessages,
      variables: {
        servicesType:
          location.pathname === '/invite-review-history' ||
          location.pathname === `/invite-review-history/${id}`
            ? 'invite_reviews'
            : 'send_messages',
        messageType: newValue === 1 ? 'bulk_message' : 'individual_message',
        userBusinessId: parseInt(userCurrentBusinessDetails?.id, 10),
      },
    };
    const result = await handleApiRequest(payload);
    if (result?.getSendCampaignMessages?.status === 200) {
      setMessageData(result?.getSendCampaignMessages?.data);
      setTotalCount(result?.getSendCampaignMessages?.totalCount);
    }
  };

  const handleViewIndividualDetails = async (value) => {
    const id = getParamsId();
    if (id) {
      history.push(`view-individual-details/${value?.id}/${id}`);
    } else {
      history.push(`view-individual-details/${value?.id}`);
    }
    await dispatch(storeViewMessageDetails(value));
  };

  const handleViewBulkDetails = async (value) => {
    const id = getParamsId();
    if (id) {
      history.push(`view-bulk-details/${value?.id}/${id}`);
    } else {
      history.push(`view-bulk-details/${value?.id}`);
    }
    await dispatch(storeViewMessageDetails(value));
  };

  if (loading) {
    return <FuseLoading />;
  }

  const handleChangePage = async (event, value) => {
    await setPage(value + 1);
    const id = getParamsId();
    const payload = {
      query: businessData.getSendCampaignMessages,
      variables: {
        servicesType:
          location.pathname === '/invite-review-history' ||
          location.pathname === `/invite-review-history/${id}`
            ? 'invite_reviews'
            : 'send_messages',
        messageType: tabValue === 1 ? 'bulk_message' : 'individual_message',
        userBusinessId: parseInt(userCurrentBusinessDetails?.id, 10),
        pageSize: rowsPerPage,
        pageNumber: value + 1,
      },
    };
    const result = await handleApiRequest(payload);
    if (result?.getSendCampaignMessages?.status === 200) {
      setMessageData(result?.getSendCampaignMessages?.data);
      setTotalCount(result?.getSendCampaignMessages?.totalCount);
    }
  };

  const handleChangeRowsPerPage = async (event) => {
    await setRowsPerPage(event.target.value);
    await setPage(1);
    const id = getParamsId();
    const payload = {
      query: businessData.getSendCampaignMessages,
      variables: {
        servicesType:
          location.pathname === '/invite-review-history' ||
          location.pathname === `/invite-review-history/${id}`
            ? 'invite_reviews'
            : 'send_messages',
        messageType: tabValue === 1 ? 'bulk_message' : 'individual_message',
        userBusinessId: parseInt(userCurrentBusinessDetails?.id, 10),
        pageSize: event.target.value,
        pageNumber: 1,
      },
    };
    const result = await handleApiRequest(payload);
    if (result?.getSendCampaignMessages?.status === 200) {
      setMessageData(result?.getSendCampaignMessages?.data);
      setTotalCount(result?.getSendCampaignMessages?.totalCount);
    }
  };

  const handleRedirect = () => {
    const id = getParamsId();
    if (
      location.pathname === '/invite-review-history' ||
      location.pathname === `/invite-review-history/${id}`
    ) {
      if (id) {
        history.push(`invite-reviews/${id}`);
      } else {
        history.push('invite-reviews/');
      }
    }
    if (
      location.pathname === '/send-message-history' ||
      location.pathname === `/send-message-history/${id}`
    ) {
      if (id) {
        history.push(`send-message/${id}`);
      } else {
        history.push('send-message/');
      }
    }
  };
  return (
    <div className="p-20 lg:p-24">
      <div className="flex pb-24 items-center">
        <Typography
          initial={{ x: -20 }}
          animate={{ x: 0, transition: { delay: 0.2 } }}
          delay={300}
          className="sm:flex text-20 md:text-28 font-bold"
        >
          {location.pathname === '/invite-review-history' ||
          location.pathname === `/invite-review-history/${getParamsId()}`
            ? t('inviteReviews.inviteReviewHistory')
            : t('inviteReviews.sendMessageHistory')}
        </Typography>
      </div>
      <div className="bg-white px-20 md:px-36 rounded-md relative shadow">
        <Tabs
          value={tabValue}
          className="rounded-tl-md rounded-tr-md overflow-scroll"
          sx={{
            '& .MuiTabs-scroller': {
              overflow: 'scroll !important',
            },
          }}
          onChange={handleTabChange}
          textColor="secondary"
          indicatorColor="secondary"
          aria-label="secondary tabs example"
        >
          <Tab
            className="md:text-18 text-14 font-semibold px-5 md:px-16"
            value={0}
            label={t('inviteReviews.individualMessage')}
          />
          <Tab
            className="md:text-18 text-14 ml-24 font-semibold px-5 md:px-16"
            value={1}
            label={t('inviteReviews.bulkMessage')}
          />
        </Tabs>
      </div>
      <div className="px-8 rounded-md relative">
        <div className="mt-28">
          {tabValue === 0 && (
            <div>
              {messageData?.length > 0 ? (
                <TableContainer className="bg-white shadow">
                  <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle" size="medium">
                    <TableHeader headerData={individualHeaderData} />
                    <TableBody>
                      {messageData?.length > 0 &&
                        messageData?.map((item, i) => {
                          return (
                            <TableRow>
                              <TableCell
                                className="text-16 color-black font-semibold w-[5%]"
                                align="left"
                              >
                                {i + 1}
                              </TableCell>
                              <TableCell
                                className="text-16 color-black font-semibold w-[15%]"
                                align="left"
                              >
                                {item?.campaign_services_standard_messages?.title}
                              </TableCell>
                              <TableCell
                                className="text-16 color-black font-semibold w-[10%]"
                                align="left"
                              >
                                {item?.campaign_services_queue?.[0]?.name}
                              </TableCell>
                              <TableCell
                                className="text-16 color-black font-semibold w-[10%]"
                                align="left"
                              >
                                {new Date(parseInt(item?.cts, 10)).toDateString()}
                              </TableCell>
                              <TableCell
                                className="text-16 color-black font-semibold w-[10%]"
                                align="left"
                              >
                                {item?.campaign_platform?.name}
                              </TableCell>
                              <TableCell
                                className={`text-16 capitalize ${
                                  item?.campaign_services_queue[0]?.status === 'delivered' &&
                                  'text-[#00BF26]'
                                } ${
                                  item?.campaign_services_queue[0]?.status === 'failed' &&
                                  'text-[#FF0000]'
                                } ${
                                  item?.campaign_services_queue[0]?.status === 'scheduled' &&
                                  'text-[#FF8800]'
                                } font-semibold w-[10%]`}
                                align="left"
                              >
                                {item?.campaign_services_queue[0]?.status
                                  ?.split(' ')
                                  .map((word) => capitalizeFirstLetter(word))
                                  .join(' ')}
                              </TableCell>
                              <TableCell
                                className="text-16 color-black font-semibold w-[10%]"
                                align="left"
                              >
                                <span
                                  className="inline-block bg-darkgreen-100 rounded text-darkgreen py-4 px-12 text-14 font-semibold cursor-pointer hover:bg-darkgreen hover:text-white"
                                  aria-hidden="true"
                                  onClick={() => handleViewIndividualDetails(item)}
                                >
                                  {t('common.viewDetails')}
                                </span>
                              </TableCell>
                            </TableRow>
                          );
                        })}
                    </TableBody>
                  </Table>
                </TableContainer>
              ) : (
                <div className="flex flex-1  items-center justify-center h-full text-center mx-auto h-fit">
                  <div className="md:mt-120">
                    <img
                      src="/assets/images/business/Group36597.png"
                      className="img-fluid mx-auto max-w-216 pb-40"
                      alt="..."
                    />
                    <h5 className="block text-24 font-semibold text-black">
                      {location.pathname === '/invite-review-history' ||
                      location.pathname === `/invite-review-history/${getParamsId()}`
                        ? t('inviteReviews.noInvitationSend')
                        : t('inviteReviews.noSmsSendYet')}
                    </h5>
                    <Typography
                      color="textPrimary"
                      variant="h5"
                      className="text-16 pt-12 font-medium max-w-640"
                    >
                      {tabValue === 0
                        ? t('inviteReviews.sendSmsOrEmail')
                        : t('inviteReviews.bulkSendSmsOrEmail')}
                    </Typography>
                    <Button
                      className="md:text-16 font-medium rounded-md min-w-288 mt-32"
                      variant="contained"
                      color="secondary"
                      type="button"
                      size="small"
                      onClick={() => handleRedirect()}
                    >
                      {t('dashboard.exploreFeatures.campaigns.sendMessage')}
                    </Button>
                  </div>
                </div>
              )}
            </div>
          )}
          {tabValue === 1 && (
            <div className="text-center mt-28 mb-16">
              <div>
                {messageData?.length > 0 ? (
                  <TableContainer className="bg-white shadow">
                    <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle" size="medium">
                      <TableHeader headerData={bulkHeaderData} />
                      <TableBody>
                        {messageData?.length > 0 &&
                          messageData?.map((item, i) => {
                            return (
                              <TableRow>
                                <TableCell
                                  className="text-16 color-black font-semibold w-[5%]"
                                  align="left"
                                >
                                  {i + 1}
                                </TableCell>
                                <TableCell
                                  className="text-16 color-black font-semibold w-[18%]"
                                  align="left"
                                >
                                  {item?.campaign_services_standard_messages?.title}
                                </TableCell>
                                <TableCell
                                  className="text-16 color-black font-semibold w-[10%]"
                                  align="left"
                                >
                                  {new Date(parseInt(item?.cts, 10)).toDateString()}
                                </TableCell>
                                <TableCell
                                  className="text-16 color-black font-semibold w-[10%]"
                                  align="left"
                                >
                                  {item?.totalCustomers}
                                </TableCell>
                                <TableCell
                                  className="text-16 color-black font-semibold w-[10%]"
                                  align="left"
                                >
                                  {item?.campaign_platform?.id === 2
                                    ? 'Send an SMS'
                                    : 'Send an Email'}
                                </TableCell>
                                <TableCell
                                  className={`text-16 capitalize ${
                                    item?.campaign_services_queue[0]?.status === 'delivered' &&
                                    'text-[#00BF26]'
                                  } ${
                                    item?.campaign_services_queue[0]?.status === 'failed' &&
                                    'text-[#FF0000]'
                                  } ${
                                    item?.campaign_services_queue[0]?.status === 'scheduled' &&
                                    'text-[#FF8800]'
                                  } font-semibold w-[10%]`}
                                  align="left"
                                >
                                  {item?.campaign_services_queue[0]?.status}
                                </TableCell>
                                <TableCell
                                  className="text-16 color-black font-semibold w-[10%]"
                                  align="left"
                                >
                                  <span
                                    className="inline-block bg-darkgreen-100 rounded text-darkgreen py-4 px-12 text-14 font-semibold cursor-pointer hover:bg-darkgreen hover:text-white"
                                    aria-hidden="true"
                                    onClick={() => handleViewBulkDetails(item)}
                                  >
                                    {t('common.viewDetails')}
                                  </span>
                                </TableCell>
                              </TableRow>
                            );
                          })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                ) : (
                  <div className="flex flex-1  items-center justify-center h-full text-center mx-auto h-fit">
                    <div className="md:mt-120">
                      <img
                        src="/assets/images/business/Group36597.png"
                        className="img-fluid mx-auto max-w-216 pb-40"
                        alt="..."
                      />
                      <h5 className="block text-24 font-semibold text-black">
                        {location.pathname === '/invite-review-history' ||
                        location.pathname === `/invite-review-history/${getParamsId()}`
                          ? t('inviteReviews.bulkInvitation')
                          : t('inviteReviews.bulkSms')}
                      </h5>
                      <Typography
                        color="textPrimary"
                        variant="h5"
                        className="text-16 pt-12 font-medium max-w-640"
                      >
                        {tabValue === 0
                          ? t('inviteReviews.sendSmsOrEmail')
                          : t('inviteReviews.bulkSendSmsOrEmail')}
                      </Typography>
                      <Button
                        className="md:text-16 font-medium rounded-md min-w-288 mt-32"
                        variant="contained"
                        color="secondary"
                        type="button"
                        size="small"
                        onClick={() => handleRedirect()}
                      >
                        {t('dashboard.exploreFeatures.campaigns.sendMessage')}
                      </Button>
                    </div>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
      {messageData?.length > 0 && (
        <Pagination
          totalCount={totalCount}
          rowsPerPage={rowsPerPage}
          page={page}
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
        />
      )}
    </div>
  );
};

export default withReducer('state', reducer)(History);
