import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import ReactApexChart from 'react-apexcharts';
import moment from 'moment';
import StarBorderOutlinedIcon from '@mui/icons-material/StarBorderOutlined';
import { selectDashboardData } from 'src/app/store/userSlice';
import metricsData from '../../query/metrics';
import NoData from './noDataScreen';
import ReviewSentiment from './reviewSentiment';
import { getDataToServer } from '../../common/common';

function ReceivedReviewsDash(props) {
  const [timeZone] = useState(Intl.DateTimeFormat().resolvedOptions().timeZone);
  const { reviewSentiment, reviewPlatformId, useArea, setDateDetails } = props;
  const [reviewsSentimentBarData, setReviewsSentimentBarData] = useState([]);
  const todayDate = moment().tz(timeZone).startOf('day');
  const [reviewsSentimentBarDetails, setReviewsSentimentBarDetails] = useState([]);
  const [selectedReviewSentimentId, setSelectedReviewSentimentId] = useState(1);
  const userCurrentBusinessDetails = useSelector(selectDashboardData);
  const [planStatus, setPlanStatus] = useState();
  const totalReviewCount = reviewsSentimentBarDetails?.find((item) => item.key === 'totalCount');
  const totalReviewText = reviewsSentimentBarDetails?.find((item) => item.key === 'text');

  useEffect(() => {
    if (useArea === 'dashboard') {
      setDateDetails(reviewsSentimentBarData);
    }
  }, [reviewsSentimentBarData, setDateDetails, useArea]);

  const [newGraphData, setNewGraphData] = useState([
    {
      name: 'Negative',
      data: [],
    },
    {
      name: 'Neutral',
      data: [],
    },
    {
      name: 'Positive',
      data: [],
    },
  ]);

  useEffect(() => {
    try {
      const fetchData = async () => {
        if (reviewPlatformId) {
          const payload = {
            query: metricsData.getHomePageReviewBarChartData,
            variables: {
              businessId: Number(userCurrentBusinessDetails?.id),
              reviewSentimentPeriodId: Number(selectedReviewSentimentId),
            },
          };
          const result = await getDataToServer(payload);
          setPlanStatus(result?.getHomePageReviewBarChartData?.status);
          setReviewsSentimentBarDetails(result?.getHomePageReviewBarChartData?.data?.summary);
          const keyValueObject = result?.getHomePageReviewBarChartData?.data?.reviewCountData
            ?.filter((item) => {
              return item.some(({ key, value }) => key === 'timeStamp' && value !== 0);
            })
            ?.map((item) => {
              const obj = {};
              item.forEach(({ key, value }) => {
                obj[key] = value;
              });
              return obj;
            });
          const sortData = keyValueObject?.sort((a, b) => b.reviewPeriod - a.reviewPeriod);
          setReviewsSentimentBarData(sortData || []);
          const updatedGraphData = [
            {
              name: 'Positive',
              data:
                sortData
                  ?.map((item) => item?.numberOfPositiveReviews)
                  ?.filter((value) => value !== undefined) || [],
            },
            {
              name: 'Neutral',
              data:
                sortData
                  ?.map((item) => item?.numberOfNeutralReviews)
                  ?.filter((value) => value !== undefined) || [],
            },
            {
              name: 'Negative',
              data:
                sortData
                  ?.map((item) => item?.numberOfNegativeReviews)
                  ?.filter((value) => value !== undefined) || [],
            },
          ];
          setNewGraphData(updatedGraphData);
        }
      };
      fetchData();
    } catch (error) {
      console.error('An error occurred:', error);
    }
  }, [userCurrentBusinessDetails?.id, reviewPlatformId, selectedReviewSentimentId]);
  function getChartSimple(datas, business, index, color, types) {
    const chart = {
      series: datas,
      options: {
        grid: {
          strokeDashArray: 3,
          show: true,
          forceNiceScale: false,
          padding: {
            bottom: 0,
          },
        },
        chart: {
          events: {
            mounted: (charts) => {
              charts.windowResizeHandler();
            },
          },
          type: types,
          stacked: true,
          parentHeightOffset: 0,
          zoom: {
            enabled: false,
          },
          toolbar: {
            show: false,
          },
        },
        plotOptions: {
          bar: {
            dataLabels: {
              position: 'right',
            },
            horizontal: false,
            borderRadius: 3,
            columnWidth: useArea === 'dashboard' ? '50px' : '25px',
          },
        },
        legend: {
          show: false,
          onItemClick: {
            toggleDataSeries: false,
          },
        },
        fill: {
          colors: color,
          opacity: 1,
        },
        colors: color,
        dataLabels: {
          enabled: false,
        },
        xaxis: {
          axisBorder: {
            show: false,
          },
          categories: business,
          show: true,
          axisTicks: {
            show: false,
          },
          labels: {
            show: true,
            offsetY: 0,
          },
        },
        yaxis: {
          show: true,
          axisTicks: {
            show: false,
          },
          labels: {
            show: true,
            align: 'left',
            maxWidth: 350,
            position: 'left',
            offsetX: -15,
            style: {
              fontSize: '14px',
            },
            formatter: (value) => Math.floor(value),
          },
          tickAmount: 5,
          min: 0,
          max: (maxValue) => (maxValue < 5 ? 5 : maxValue),
          forceNiceScale: true,
        },
        tooltip: {
          shared: true,
          intersect: false,
        },
      },
    };

    return (
      <>
        <div
          id={`chart${index}`}
          className="w-full"
          style={{ overflowX: 'auto', overflowY: 'hidden' }}
        >
          <ReactApexChart
            options={chart?.options}
            series={chart?.series}
            type={chart?.options?.chart?.type}
            height="300px"
            width={business.length > 8 ? `${business.length * 60}px` : '100%'}
          />
        </div>
        <div className="flex mt-16 w-full justify-evenly items-center">
          {datas.map((dataSet, idx) => (
            <div key={idx} className="items-center">
              <span
                className="w-12 h-12 rounded-full mx-10 inline-block"
                style={{ backgroundColor: color[idx] }}
              />
              <span className="text-14 font-medium inline-block">{dataSet.name}</span>
            </div>
          ))}
        </div>
      </>
    );
  }
  const xAxisLabels = Array.from({ length: selectedReviewSentimentId === 1 ? 7 : 30 }, (_, i) => {
    let dummyDate;

    if (selectedReviewSentimentId === 1) {
      dummyDate = moment().tz(timeZone).startOf('isoWeek').add(i, 'days');
    } else {
      dummyDate = moment().tz(timeZone).startOf('month').add(i, 'days');
    }
    const isToday = todayDate.isSame(dummyDate, 'day');

    if (isToday) {
      return 'Today';
    }

    return selectedReviewSentimentId === 1 ? dummyDate.format('ddd') : dummyDate.format('DD MMM');
  });
  return (
    <>
      {((planStatus !== 403 && !userCurrentBusinessDetails?.isOnlineBusiness) ||
        Number(reviewPlatformId) === 7) && (
        <ReviewSentiment
          reviewSentiment={reviewSentiment}
          sentimentId={[1, 2]}
          onSelectSentiment={setSelectedReviewSentimentId}
          dashboard
        />
      )}
      {reviewsSentimentBarData?.length > 0 ? (
        <>
          <div>
            {reviewsSentimentBarData &&
              getChartSimple(
                newGraphData,
                reviewsSentimentBarData
                  ?.filter((item) => item?.timeStamp !== 0)
                  ?.map((period) => {
                    const isToday = moment
                      .unix(period?.timeStamp)
                      .tz(timeZone)
                      .isSame(moment().tz(timeZone), 'day');
                    if (isToday) {
                      return 'Today';
                    }
                    return selectedReviewSentimentId === 1
                      ? moment.unix(period?.timeStamp).tz(timeZone).format('ddd')?.split(',')[0]
                      : moment.unix(period?.timeStamp).tz(timeZone).format('DD MMM')?.split(',')[0];
                  }),
                1,
                ['#67DF9D', '#3E8CF3', '#F2B443'],
                'bar'
              )}
          </div>
          <div className="bg-gray-A500 rounded-md flex items-center py-8 px-12 mt-24">
            <span className="w-36 h-36 rounded-lg bg-quick-review flex items-center mr-14 text-white">
              <StarBorderOutlinedIcon className="mx-auto" />
            </span>
            <div>
              {totalReviewCount && (
                <span className="block text-16 font-semibold">{totalReviewCount.value}</span>
              )}
              {totalReviewText && <span className="block">{totalReviewText.value}</span>}
            </div>
          </div>
        </>
      ) : (
        getChartSimple(
          newGraphData,
          xAxisLabels.length
            ? xAxisLabels
            : Array.from({ length: selectedReviewSentimentId === 1 ? 7 : 30 }, (_, i) =>
                moment().tz(timeZone).subtract(i, 'days').format('DD MMM')
              ),
          1,
          ['#67DF9D', '#3E8CF3', '#F2B443'],
          'bar'
        )
      )}
    </>
  );
}

export default ReceivedReviewsDash;
