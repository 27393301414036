import { Button, Dialog, DialogActions, DialogContent, Tabs, Tab, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import React from 'react';
import FuseLoading from '@fuse/core/FuseLoading';

const platforms = [
  {
    id: 1,
    name: 'Facebook',
    logo: 'https://upload.wikimedia.org/wikipedia/commons/5/51/Facebook_f_logo_%282019%29.svg',
    enabled: true,
  },
  {
    id: 2,
    name: 'Instagram',
    logo: 'https://upload.wikimedia.org/wikipedia/commons/a/a5/Instagram_icon.png',
    enabled: true,
  },
];

const PreviewPopUp = ({
  open,
  onClose,
  refresh,
  backgroundColor,
  Image,
  activeTab,
  setActiveTab,
}) => {
  const { t } = useTranslation();

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="createPost-confirmation-dialog"
      classes={{
        paper: `m-24 p-28 business-type-dropdown text-center rounded-md`,
      }}
      PaperProps={{ style: { Width: '2000px' } }}
    >
      <div>
        <Typography className="text-20 font-600">
          {t('quickCampaign.campaign.title')} {t('promotion.preview')}
        </Typography>
        <IconButton className="absolute top-4 right-4" onClick={onClose} color="inherit">
          <CloseIcon className="w-32 h-32 text-grey-700" />
        </IconButton>
      </div>
      <DialogContent className="p-0 mb-24 mt-14">
        <Tabs
          value={activeTab}
          onChange={handleTabChange}
          className="mb-16"
          indicatorColor="primary"
          textColor="inherit"
          centered
        >
          {platforms?.map((item, index) => (
            <Tab
              key={index}
              label={
                <div className=" flex gap-10 justify-center items-center">
                  <p className="text-18 font-500">{item?.name}</p>
                  <img src={item?.logo} alt="preview" className="h-24 w-24" />
                </div>
              }
            />
          ))}
        </Tabs>
        {refresh ? (
          <FuseLoading />
        ) : (
          <div className=" w-full flex justify-center items-center">
            <iframe
              src={Image?.previewUrl}
              title="Preview"
              scrolling="no"
              className="text-16 font-500 text-black w-400 h-400 overflow-hidden"
            />
          </div>
        )}
      </DialogContent>
      <DialogActions className="p-0 flex justify-center gap-14">
        <Button
          className={`md:text-16 font-medium rounded-md bg-${backgroundColor} w-full max-w-256`}
          variant="contained"
          color="quickSocial"
          onClick={onClose}
        >
          {t('manageBusiness.button.back')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default PreviewPopUp;
