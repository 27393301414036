import { Button, CircularProgress, Typography } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import history from '@history';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { t } from 'i18next';
import { useSnackbar } from 'notistack';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import { handleApiRequest } from '../../../../../common/common';
import quickHub from '../../../../../query/quickHub';
import AddEditMenu from './AddEditMenu';

const Menu = () => {
  const location = useLocation();
  const [addEditMenu, setAddEditMenu] = useState(!!location?.state?.isBulk);
  const [menuData, setMenuData] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const [menuSectionName, setMenuSectionName] = useState();
  const [selectDeleteMenu, setSelectDeleteMenu] = useState();
  const [openDrawer, setOpenDrawer] = useState(false);
  const [refreshIcon, setRefreshIcon] = useState(false);
  const [editMenuData, setEditMenuData] = useState();
  const [selectLabelOrBusiness, setSelectLabelOrBusiness] = useState(
    location?.state?.selectBusiness || []
  );

  useEffect(() => {
    if (!openDrawer) {
      setEditMenuData();
    }
  }, [openDrawer]);

  const fetchMenu = useCallback(async () => {
    if (selectLabelOrBusiness?.[0]?.id) {
      try {
        const payload = {
          query: quickHub.getMenuForGoogleBusiness,
          variables: {
            businessId: Number(selectLabelOrBusiness?.[0]?.id),
          },
        };
        const result = await handleApiRequest(payload);
        if (result?.getMenuForGoogleBusiness?.status === 200) {
          setMenuData(result?.getMenuForGoogleBusiness?.data);
        } else {
          enqueueSnackbar(
            result?.getMenuForGoogleBusiness?.message || 'Failed to fetch menu data.',
            {
              variant: 'error',
              autoHideDuration: 3000,
            }
          );
        }
      } catch (error) {
        console.error('Error fetching menu data:', error);
      }
    }
  }, [enqueueSnackbar, selectLabelOrBusiness]);

  useEffect(() => {
    if (!location?.state?.isBulk) {
      fetchMenu();
    }
  }, [fetchMenu, location?.state?.isBulk]);

  const deleteMenu = async (sectionName, menuName) => {
    setSelectDeleteMenu(menuName);
    setRefreshIcon(true);
    try {
      const payload = {
        query: quickHub.deleteMenuForGoogleBusiness,
        variables: {
          menuName,
          sectionName,
          userBusinessId: Number(selectLabelOrBusiness?.[0]?.id),
        },
      };
      const result = await handleApiRequest(payload);
      setRefreshIcon(false);
      if (result?.deleteMenuForGoogleBusiness?.status === 200) {
        fetchMenu();
        setSelectDeleteMenu('');
        enqueueSnackbar(result?.deleteMenuForGoogleBusiness?.message, {
          variant: 'success',
          autoHideDuration: 3000,
        });
      } else {
        enqueueSnackbar(
          result?.deleteMenuForGoogleBusiness?.message || 'Failed to delete menu data.',
          {
            variant: 'error',
            autoHideDuration: 3000,
          }
        );
      }
    } catch (error) {
      setRefreshIcon(false);
      console.error('Error delete menu data:', error);
    }
  };

  const handleEditMenu = (data, menuName) => {
    setEditMenuData(data);
    setOpenDrawer(true);
    setAddEditMenu(true);
    setMenuSectionName(menuName);
  };

  return (
    <div className="bg-gray-A500 h-full p-16 sm:p-24">
      {(!addEditMenu || openDrawer) && menuData?.length > 0 && !location?.state?.isBulk && (
        <div className="flex items-center justify-between">
          <Typography className="font-semibold text-16 md:text-24 flex items-center gap-8 mb-16">
            {' '}
            <Button
              className="w-16 h-16 min-w-16 min-h-16"
              onClick={() => history.push('quick-hub/primaryDetails', location?.state?.activeTab)}
            >
              <ArrowBackIcon />
            </Button>
            {t('primaryDetails.addMenu')}
          </Typography>
          <Button
            className="rounded-md font-semibold w-160"
            variant="contained"
            color="secondary"
            onClick={() => {
              setAddEditMenu(true);
              setMenuSectionName('');
            }}
          >
            <AddIcon className="mr-6" /> {t('primaryDetails.addMenu')}
          </Button>
        </div>
      )}
      {(!addEditMenu || openDrawer) &&
        menuData?.length > 0 &&
        !location?.state?.isBulk &&
        menuData?.map((menu, i) => {
          return (
            <div key={i}>
              <div className="bg-white md:w-640 p-16 rounded-md mb-16">
                <div className="flex items-center justify-between">
                  <Typography className="font-semibold text-24">{menu?.sectionName}</Typography>
                  <Button
                    className="rounded-md font-semibold"
                    variant="outlined"
                    color="secondary"
                    onClick={() => {
                      setMenuSectionName(menu?.sectionName);
                      setAddEditMenu(true);
                    }}
                  >
                    <AddIcon className="mr-6" /> {t('primaryDetails.addMenuItem')}
                  </Button>
                </div>
                {menu?.menus?.length > 0 &&
                  menu?.menus?.map((item, index) => {
                    return (
                      <div
                        className="flex items-center justify-between border border-solid rounded-md p-12 gap-16 mt-16"
                        key={index}
                      >
                        <div>
                          <div className="flex items-center gap-16">
                            <Typography className="font-semibold text-20">{item?.name}</Typography>
                            <div className="flex gap-16">
                              <Button
                                className="p-0 min-w-28 w-28 min-h-28 h-28 bg-gray-A500 hover:bg-gray-A500"
                                onClick={() => handleEditMenu(item, menu?.sectionName)}
                              >
                                <EditIcon className="w-20" />
                              </Button>
                              <Button
                                className="p-0 min-w-32 w-32 min-h-32 h-32 bg-gray-A500 hover:bg-gray-A500"
                                onClick={() => deleteMenu(menu?.sectionName, item?.name)}
                                disabled={refreshIcon && selectDeleteMenu === item?.name}
                              >
                                <img
                                  className="w-16"
                                  src="assets/images/quick-hub/delete.png"
                                  alt=""
                                />
                                {refreshIcon && selectDeleteMenu === item?.name && (
                                  <CircularProgress
                                    className="text-quick-review absolute mx-auto"
                                    size={24}
                                  />
                                )}
                              </Button>
                            </div>
                          </div>
                          <Typography className="text-gray font-semibold text-14 py-6">
                            {item?.description}
                          </Typography>
                          <Typography className="font-medium text-14 py-6">
                            {item.dietaryRestriction &&
                              item.dietaryRestriction.charAt(0).toUpperCase() +
                                item.dietaryRestriction.slice(1).toLowerCase()}
                          </Typography>
                          <Typography className="font-semibold text-20">
                            {item?.price?.currencyCode === 'INR' ? '₹' : '$'} {item?.price?.unit}
                          </Typography>
                        </div>
                        <img className="max-w-160 rounded-md" src={item?.image} alt="" />
                      </div>
                    );
                  })}
              </div>
            </div>
          );
        })}
      {(openDrawer || addEditMenu || menuData?.length === 0 || location?.state?.isBulk) && (
        <AddEditMenu
          location={location}
          selectLabelOrBusiness={selectLabelOrBusiness}
          setSelectLabelOrBusiness={setSelectLabelOrBusiness}
          menuSectionName={menuSectionName}
          setAddEditMenu={setAddEditMenu}
          menuData={menuData}
          openDrawer={openDrawer}
          setOpenDrawer={setOpenDrawer}
          setEditMenuData={setEditMenuData}
          editMenuData={editMenuData}
          fetchMenu={fetchMenu}
        />
      )}
    </div>
  );
};

export default Menu;
