import {
  Button,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { selectDashboardData } from 'app/store/userSlice';
import { t } from 'i18next';
import { useCallback, useEffect, useState } from 'react';
import ArrowDropDownOutlinedIcon from '@mui/icons-material/ArrowDropDownOutlined';
import { useSnackbar } from 'notistack';
import { useSelector } from 'react-redux';
import FuseLoading from '@fuse/core/FuseLoading';
import Pagination from 'src/app/component/table/Pagination';
import manageBusinessData from '../../../query/manageBusiness';
import { formatDateWithTimeZone, handleApiRequest } from '../../../common/common';

const UserNumber = () => {
  const filterData = [
    { id: 1, name: 'All' },
    { id: 2, name: 'Newest' },
    { id: 3, name: 'Oldest' },
  ];
  const [mobileNumberData, setMobileNumberData] = useState([]);
  const [selectFilterId, setSelectFilterId] = useState(1);
  const [copyNumberId, setCopyNumberId] = useState();
  const [copyNumber, setCopyNumber] = useState(false);
  const userCurrentBusinessDetails = useSelector(selectDashboardData);
  const { enqueueSnackbar } = useSnackbar();
  const [totalCount, setTotalCount] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(1);
  const [pageLoading, setPageLoading] = useState(true);

  const fetchUserPhoneNumbers = useCallback(async () => {
    if (userCurrentBusinessDetails?.id) {
      setPageLoading(true);
      const payload = {
        query: manageBusinessData.getContactFromBusinessReview,
        variables: {
          userBusinessId: parseInt(userCurrentBusinessDetails?.id, 10),
          pageNumber: page,
          pageSize: rowsPerPage,
        },
      };
      const result = await handleApiRequest(payload);
      setPageLoading(false);
      if (result?.getContactFromBusinessReview?.status === 200) {
        setMobileNumberData(result?.getContactFromBusinessReview?.data);
        setTotalCount(result.getContactFromBusinessReview?.totalCount);
      } else {
        enqueueSnackbar(result?.getContactFromBusinessReview?.message, {
          variant: 'error',
          autoHideDuration: 3000,
        });
      }
    }
  }, [userCurrentBusinessDetails?.id, enqueueSnackbar, page, rowsPerPage]);

  useEffect(() => {
    fetchUserPhoneNumbers();
  }, [fetchUserPhoneNumbers]);

  const changeFilter = async (id) => {
    setSelectFilterId(id);
    const payload = {
      query: manageBusinessData.getContactFromBusinessReview,
      variables: {
        userBusinessId: parseInt(userCurrentBusinessDetails?.id, 10),
        ...(id !== 1 && {
          isNewest: id === 2,
          pageSize: rowsPerPage,
        }),
      },
    };
    setPage(1);
    setRowsPerPage(rowsPerPage);
    const result = await handleApiRequest(payload);
    if (result?.getContactFromBusinessReview?.status === 200) {
      setMobileNumberData(result?.getContactFromBusinessReview?.data);
    } else {
      enqueueSnackbar(result?.getContactFromBusinessReview?.message, {
        variant: 'error',
        autoHideDuration: 3000,
      });
    }
  };

  const handleCopyClick = async (id) => {
    const textToCopy = document.getElementById(id).innerText;

    navigator.clipboard.writeText('').then(() => {
      setCopyNumber(true);
      setCopyNumberId(id);
      return navigator.clipboard.writeText(textToCopy);
    });
    await setTimeout(() => {
      setCopyNumber(false);
      setCopyNumberId();
    }, 1000);
  };

  if (pageLoading) {
    return <FuseLoading />;
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage + 1);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
  };

  return (
    <div className="h-full lg:p-24 p-16">
      <div className="flex w-full items-center justify-between lg:mb-20 mb-16">
        <div className="flex items-center">
          <Typography
            initial={{ x: -20 }}
            animate={{ x: 0, transition: { delay: 0.2 } }}
            delay={300}
            className="sm:flex md:text-28 text-20 font-bold"
          >
            {t('userNumber.title')}
          </Typography>
        </div>
        <div className="text-center">
          <Select
            className="rounded-md h-40 bg-white text-16 w-160 sm:w-256 text-left leading-5 shadow-none capitalize"
            variant="filled"
            sx={{ '& .MuiFilledInput-input': { pt: '8px', zIndex: '10' } }}
            value={parseInt(selectFilterId, 10)}
            onChange={(e) => changeFilter(e?.target?.value)}
            IconComponent={() => (
              <ArrowDropDownOutlinedIcon className="text-28 absolute right-14 text-black" />
            )}
          >
            {filterData.map((item, i) => (
              <MenuItem className="capitalize text-left" value={item.id} key={i}>
                {item.name}
              </MenuItem>
            ))}
          </Select>
        </div>
      </div>
      {mobileNumberData?.length > 0 ? (
        <div className="mt-28 bg-white rounded-md w-full">
          <TableContainer>
            <Table className="w-full" aria-labelledby="tableTitle" size="medium">
              <TableHead className="bg-grey-50">
                <TableCell className="w-1/4 text-12 sm:text-16 text-gray font-semibold sm:p-20 p-10">
                  {t('userNumber.column.srNo')}
                </TableCell>
                <TableCell className="w-1/4 text-gray text-12 sm:text-16 font-semibold sm:p-20 p-10">
                  {t('userNumber.column.contactNumber')}
                </TableCell>
                <TableCell className="w-1/4 text-gray text-12 sm:text-16 font-semibold sm:p-20 p-10">
                  {t('userNumber.column.date')}
                </TableCell>
                <TableCell className="w-1/4 text-gray text-12 sm:text-16 font-semibold text-center sm:p-20 p-10">
                  {t('common.action')}
                </TableCell>
              </TableHead>
              <TableBody>
                {mobileNumberData?.map((item, i) => {
                  return (
                    <TableRow hover className="border border-t-2 border-t-grey-100" role="checkbox">
                      <TableCell className="text-16 color-black font-semibold" align="left">
                        {(page - 1) * rowsPerPage + (i + 1)}
                      </TableCell>
                      <TableCell
                        className="text-12 sm:text-16 color-black font-semibold"
                        id={item?.id}
                        align="left"
                      >
                        {item?.phoneNumber && `${item?.countryCode} ${item?.phoneNumber}`}
                      </TableCell>
                      <TableCell
                        className="text-12 sm:text-16 color-black font-semibold capitalize"
                        align="left"
                      >
                        {formatDateWithTimeZone(item.cts, 'MMM DD, YYYY')}
                      </TableCell>
                      <TableCell
                        className="relative text-12 sm:text-16 color-black font-semibold"
                        align="center"
                      >
                        <Button
                          className="bg-quick-review-100 text-quick-review text-12 sm:text-16 px-20 rounded-md sm:rounded-3xl sm:px-36 font-semibold hover:bg-quick-review-100"
                          onClick={() => handleCopyClick(item?.id)}
                        >
                          {t('userNumber.button.copyNumber')}
                        </Button>
                        {copyNumber && copyNumberId === item?.id && (
                          <span className="absolute bg-black text-white text-12 px-8 rounded top-4 duration-300 animate-bounce">
                            {t('business.selectedBusinessDetail.labels.copied')}
                          </span>
                        )}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
            {mobileNumberData?.length > 0 && (
              <Pagination
                totalCount={totalCount}
                rowsPerPage={rowsPerPage}
                page={page}
                handleChangePage={handleChangePage}
                handleChangeRowsPerPage={handleChangeRowsPerPage}
              />
            )}
          </TableContainer>
        </div>
      ) : (
        <div className="flex h-full items-center justify-center">
          <div>
            <img className="w-160 m-auto" src="/assets/images/business/note.png" alt="" />
            <Typography className="font-semibold text-center mt-28 text-20">
              {t('userNumber.noNumberAvailable')}
            </Typography>
            <Typography className="mt-20 max-w-640 m-auto text-center text-16">
              {t('userNumber.message')}
            </Typography>
          </div>
        </div>
      )}
    </div>
  );
};

export default UserNumber;
