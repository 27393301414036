import { Button, CircularProgress, Drawer, Paper, TextField, Typography } from '@mui/material';
import { t } from 'i18next';
import React, { useState } from 'react';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import history from '@history';
import quickHub from 'src/app/main/query/quickHub';
import {
  handleApiRequest,
  imageDataToServer,
  imageSendToServer,
  multipleImageDataToSend,
} from 'src/app/main/common/common';
import { useSnackbar } from 'notistack';
import LabelOrBusinessDrawer from '../../reports/LabelOrBusinessDrawer';
import userService from '../../../../../../services';
import ErrorPopUp from '../../common/ErrorPopUp';

const AddEditMenu = (props) => {
  const {
    location,
    selectLabelOrBusiness,
    setSelectLabelOrBusiness,
    menuSectionName,
    setAddEditMenu,
    menuData,
    openDrawer,
    setOpenDrawer,
    editMenuData,
    setEditMenuData,
    fetchMenu,
  } = props;
  const [imageUrl, setImageUrl] = useState(editMenuData?.image || '');
  const [refreshIcon, setRefreshIcon] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [uploadFile, setUploadFile] = useState();
  const loginUserData = userService.getUserData();
  const [openErrorDialog, setOpenErrorDialog] = useState(false);
  const [failedRequests, setFailedRequests] = useState([]);
  const [dietaryRestriction, setMenuDietaryRestriction] = useState(
    editMenuData?.dietaryRestriction || ''
  );
  const [openBusinessDrawer, setOpenBusinessDrawer] = useState(false);
  const [menuDetails, setMenuDetails] = useState({
    menuSection: menuSectionName || '',
    menuName: editMenuData?.name || '',
    menuPrice: editMenuData?.price?.unit || '',
    menuDescription: editMenuData?.description || '',
  });

  const onSubmit = async () => {
    setRefreshIcon(true);
    try {
      const payload = {
        query: quickHub.addOrUpdateMenuForGoogleBusiness,
        variables: {
          isNewSection: !menuSectionName,
          menuDetails: {
            description: menuDetails?.menuDescription,
            dietaryRestriction,
            name: menuDetails?.menuName,
            price: {
              currencyCode: loginUserData?.countryCode === 2 ? 'INR' : 'USD',
              units: Number(menuDetails?.menuPrice),
            },
          },
          sectionName: menuDetails?.menuSection,
          userBusinessId: selectLabelOrBusiness?.[0]?.id,
          ...(editMenuData
            ? {
                ...(!uploadFile
                  ? {
                      preMediaKey: editMenuData?.mediaKey,
                    }
                  : {
                      media: uploadFile,
                    }),
                isUpdate: true,
              }
            : {
                media: uploadFile,
              }),
        },
      };
      const result = await imageDataToServer(payload);
      setRefreshIcon(false);
      if (result?.addOrUpdateMenuForGoogleBusiness?.status === 200) {
        fetchMenu();
        setOpenDrawer(false);
        setAddEditMenu(false);
        setEditMenuData();
        enqueueSnackbar(result?.addOrUpdateMenuForGoogleBusiness?.message, {
          variant: 'success',
          autoHideDuration: 3000,
        });
      } else {
        enqueueSnackbar(result?.addOrUpdateMenuForGoogleBusiness?.message, {
          variant: 'error',
          autoHideDuration: 3000,
        });
      }
    } catch (error) {
      setRefreshIcon(false);
      console.error('Error updating business hours:', error);
    }
  };

  const addMenuInBulk = async () => {
    setRefreshIcon(true);
    try {
      const payload = {
        query: quickHub.updateGoogleBusinessDetailsForBusinesses,
        variables: {
          businessIds: selectLabelOrBusiness?.map((business) => business?.id),
          mask: 'menu',
          allMedia: [{ media: uploadFile }],
          data: JSON.stringify({
            isNewSection: true,
            menuDetails: {
              description: menuDetails?.menuDescription,
              dietaryRestriction,
              name: menuDetails?.menuName,
              price: {
                currencyCode: loginUserData?.countryCode === 2 ? 'INR' : 'USD',
                units: Number(menuDetails?.menuPrice),
              },
            },
            sectionName: menuDetails?.menuSection,
          }),
        },
      };
      const result = await multipleImageDataToSend(payload);
      setRefreshIcon(false);

      if (result?.updateGoogleBusinessDetailsForBusinesses?.data?.failedRequests?.length > 0) {
        setOpenErrorDialog(true);
        setFailedRequests(result?.updateGoogleBusinessDetailsForBusinesses?.data?.failedRequests);
      } else {
        result?.updateGoogleBusinessDetailsForBusinesses?.status === 200 &&
          history.push('quick-hub/primaryDetails', location?.state?.activeTab);
        enqueueSnackbar(result?.updateGoogleBusinessDetailsForBusinesses?.message, {
          variant:
            result?.updateGoogleBusinessDetailsForBusinesses?.status === 200 ? 'success' : 'error',
          autoHideDuration: 3000,
        });
      }
    } catch (error) {
      setRefreshIcon(false);
      console.error('Error updating business hours:', error);
    }
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (!file?.type?.startsWith('image/')) {
      enqueueSnackbar(`${t('tabletVideos.tabletVideoDetail.fileTypeError')}`, {
        variant: 'error',
        autoHideDuration: 2000,
      });
      return;
    }
    const reader = new FileReader();
    reader?.readAsBinaryString(file);

    reader.onload = async () => {
      setUploadFile(file);
      setImageUrl(URL.createObjectURL(file));
    };
  };

  const handleRemoveImage = () => {
    setUploadFile(null);
    setImageUrl();
  };

  const handleMenuChange = (e) => {
    const { name, value } = e.target;
    setMenuDetails((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleRedirect = () => {
    !location?.state?.isBulk
      ? menuData?.length > 0
        ? setAddEditMenu(false)
        : history.push('quick-hub/primaryDetails', location?.state?.activeTab)
      : history.push('quick-hub/primaryDetails', location?.state?.activeTab);
  };

  return (
    <div>
      {!openDrawer && (
        <>
          <Typography className="font-semibold text-16 md:text-24 flex items-center gap-8 mb-16">
            {' '}
            <Button className="w-16 h-16 min-w-16 min-h-16" onClick={() => handleRedirect()}>
              <ArrowBackIcon />
            </Button>
            {t('primaryDetails.addMenu')}
          </Typography>
          <div className="bg-white rounded-md md:w-640 mt-16">
            <div className="p-16">
              <Typography className="font-semibold text-18">
                {t('primaryDetails.addMenuSection')}
              </Typography>
              <TextField
                className="w-full py-12"
                size="small"
                placeholder={t('primaryDetails.enterMenuSection')}
                name="menuSection"
                disabled={menuSectionName}
                value={menuDetails?.menuSection}
                onChange={handleMenuChange}
              />
              <hr className="border border-solid !border-gray my-8" />
              <Typography className="font-semibold text-18 py-8">
                {t('primaryDetails.addMenuItem')}
              </Typography>
              <Typography className="font-semibold text-14 text-[#667085]">
                {t('primaryDetails.menuItemMessage')}
              </Typography>
              <div className="flex gap-12 items-center justify-between pt-12">
                <div className="w-full">
                  <Typography className="text-16 font-medium">
                    {t('primaryDetails.addItemName')}
                  </Typography>
                  <TextField
                    className="w-full py-12"
                    size="small"
                    placeholder={t('primaryDetails.enterItemName')}
                    name="menuName"
                    value={menuDetails?.menuName}
                    onChange={handleMenuChange}
                  />
                </div>
                <div className="w-full">
                  <Typography className="text-16 font-medium">
                    {t('primaryDetails.addItemPrice')}
                  </Typography>
                  <TextField
                    className="w-full py-12"
                    size="small"
                    placeholder={t('primaryDetails.enterItemPrice')}
                    type="number"
                    name="menuPrice"
                    value={menuDetails?.menuPrice}
                    onChange={handleMenuChange}
                  />
                </div>
              </div>
              <Typography className="text-16 font-medium">
                {t('primaryDetails.addMenuSection')}
              </Typography>
              <TextField
                className="w-full py-12"
                size="small"
                multiline
                rows={5}
                placeholder={t('primaryDetails.addDescription')}
                name="menuDescription"
                value={menuDetails?.menuDescription}
                onChange={(e) => {
                  const { value } = e.target;
                  if (value.length <= 1000) {
                    handleMenuChange(e);
                  }
                }}
              />
              <p className="text-right text-14 text-black mt-1">
                {menuDetails?.menuDescription?.length || 0}/1000 characters
              </p>
              <Typography className="text-16 font-medium">
                {t('primaryDetails.addImageOfItem')}
              </Typography>
              {imageUrl ? (
                <div className="my-12 relative">
                  <img className="rounded-md h-320 w-full" src={imageUrl} alt="" />
                  <CloseRoundedIcon
                    onClick={() => handleRemoveImage()}
                    className="text-18 absolute top-3 right-3 bg-white rounded-full text-black font-normal cursor-pointer hover:text-gray-700 focus:outline-none"
                  />
                </div>
              ) : (
                <Paper
                  className="relative w-full h-full rounded-8 shadow-none border-1 border-solid border-gray p-40 mt-12 text-center"
                  role="button"
                  onDragOver={(e) => e.preventDefault()}
                >
                  <span className="block text-center">
                    <img
                      src="/assets/images/business/uploadImage.png"
                      className="mx-auto mb-12"
                      alt="..."
                    />
                  </span>
                  <div className="block font-medium text-16">
                    {imageUrl || t('posts.dragAndDropCsv')}
                  </div>
                  <span className="block font-medium text-16">{t('signInPage.messages.or')}</span>
                  <div className="inline-block">
                    <label htmlFor="button-file">
                      <input
                        accept="image/png, image/jpg, image/jpeg"
                        className="hidden"
                        id="button-file"
                        type="file"
                        onChange={handleFileChange}
                      />
                      <Button
                        size="small"
                        className="rounded-md block text-14 font-semibold px-32 py-8 mt-6 w-auto"
                        variant="contained"
                        color="secondary"
                        component="span"
                      >
                        {t('posts.browse')}
                      </Button>
                    </label>
                  </div>
                </Paper>
              )}
              <Typography className="font-semibold text-18 pt-12">
                {t('primaryDetails.dietaryRestriction')}{' '}
                <span className="font-normal text-18 pl-6">({t('primaryDetails.optional')})</span>
              </Typography>
              <div className="mt-16 flex gap-12">
                <Button
                  variant="outlined"
                  color="secondary"
                  className={`rounded-md text-black border-gray font-semibold ${
                    dietaryRestriction === 'VEGETARIAN'
                      ? 'bg-darkgreen-100 hover:bg-darkgreen-100 border-quick-hub'
                      : 'hover:bg-white hover:border-gray'
                  }`}
                  onClick={() => setMenuDietaryRestriction('VEGETARIAN')}
                >
                  {t('primaryDetails.vegetarian')}
                </Button>
                <Button
                  variant="outlined"
                  color="secondary"
                  className={`rounded-md text-black border-gray font-semibold ${
                    dietaryRestriction === 'VEGAN'
                      ? 'bg-darkgreen-100 hover:bg-darkgreen-100 border-quick-hub'
                      : 'hover:bg-white hover:border-gray'
                  }`}
                  onClick={() => setMenuDietaryRestriction('VEGAN')}
                >
                  {t('primaryDetails.vegan')}
                </Button>
              </div>
            </div>
            {location?.state?.isBulk && (
              <div className="pb-20">
                <hr className="my-8 border border-gray" />
                <div className="flex justify-between items-center px-20 pt-12">
                  <Typography className="font-semibold text-18">
                    {t('primaryDetails.selectBusiness')}
                  </Typography>
                  <div className="flex gap-12 items-center">
                    <Typography className="font-medium text-16">
                      {`${t('primaryDetails.selectedBusiness')} (${selectLabelOrBusiness?.length})`}
                    </Typography>
                    <Button
                      className="rounded-md w-112 hover:bg-white"
                      variant="outlined"
                      color="secondary"
                      onClick={() => setOpenBusinessDrawer(true)}
                    >
                      {t('common.edit')}
                    </Button>
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="text-end md:w-640 mt-16">
            <Button
              className="rounded-md w-160 font-semibold text-16"
              variant="contained"
              color="secondary"
              onClick={() => {
                location?.state?.isBulk ? addMenuInBulk() : onSubmit();
              }}
              disabled={
                refreshIcon ||
                !menuDetails?.menuName ||
                !menuDetails?.menuPrice ||
                !menuDetails?.menuDescription ||
                !menuDetails?.menuSection ||
                !imageUrl
              }
            >
              {t('common.save')}
              {refreshIcon && (
                <CircularProgress className="text-quick-review absolute mx-auto" size={24} />
              )}
            </Button>
          </div>
        </>
      )}
      <LabelOrBusinessDrawer
        openBusinessDrawer={openBusinessDrawer}
        setOpenBusinessDrawer={setOpenBusinessDrawer}
        setSelectLabelOrBusiness={setSelectLabelOrBusiness}
        selectLabelOrBusiness={selectLabelOrBusiness}
        groupType="business"
        isGoogleConnected
        activeTab={location.state?.activeTab}
      />
      <Drawer
        className="flex flex-col h-full"
        anchor="right"
        open={openDrawer}
        transitionDuration={700}
      >
        <div className="flex-grow overflow-y-auto">
          <Typography className="font-semibold text-16 md:text-24 flex items-center gap-8 px-20 py-16">
            <Button
              className="w-16 h-16 min-w-16 min-h-16"
              onClick={() => {
                setOpenDrawer(false);
                setAddEditMenu(false);
              }}
            >
              <ArrowBackIcon />
            </Button>
            {t('primaryDetails.addMenu')}
          </Typography>
          <hr className="border border-solid !border-gray" />
          <div className="bg-white rounded-md md:w-640 p-20">
            <div className="flex gap-12 items-center justify-between">
              <div className="w-full">
                <Typography className="text-16 font-medium">
                  {t('primaryDetails.addItemName')}
                </Typography>
                <TextField
                  className="w-full py-12"
                  size="small"
                  placeholder={t('primaryDetails.enterItemName')}
                  name="menuName"
                  disabled
                  value={menuDetails.menuName}
                  onChange={handleMenuChange}
                />
              </div>
              <div className="w-full">
                <Typography className="text-16 font-medium">
                  {t('primaryDetails.addItemPrice')}
                </Typography>
                <TextField
                  className="w-full py-12"
                  size="small"
                  placeholder={t('primaryDetails.enterItemPrice')}
                  type="number"
                  name="menuPrice"
                  value={menuDetails.menuPrice}
                  onChange={handleMenuChange}
                />
              </div>
            </div>
            <Typography className="text-16 font-medium">
              {t('primaryDetails.addMenuSection')}
            </Typography>
            <TextField
              className="w-full py-12"
              size="small"
              multiline
              rows={5}
              placeholder={t('primaryDetails.addDescription')}
              name="menuDescription"
              value={menuDetails.menuDescription}
              onChange={handleMenuChange}
            />
            <Typography className="text-16 font-medium">
              {t('primaryDetails.addImageOfItem')}
            </Typography>
            {imageUrl ? (
              <div className="my-12 relative">
                <img className="rounded-md h-320 w-full" src={imageUrl} alt="" />
                <CloseRoundedIcon
                  onClick={() => handleRemoveImage()}
                  className="text-18 absolute top-3 right-3 bg-white rounded-full text-black font-normal cursor-pointer hover:text-gray-700 focus:outline-none"
                />
              </div>
            ) : (
              <Paper
                className="relative w-full h-full rounded-8 shadow-none border-1 border-solid border-gray p-40 mt-12 text-center"
                role="button"
                onDragOver={(e) => e.preventDefault()}
              >
                <span className="block text-center">
                  <img
                    src="/assets/images/business/uploadImage.png"
                    className="mx-auto mb-12"
                    alt="..."
                  />
                </span>
                <div className="block font-medium text-16">
                  {imageUrl || t('posts.dragAndDropCsv')}
                </div>
                <span className="block font-medium text-16">{t('signInPage.messages.or')}</span>
                <div className="inline-block">
                  <label htmlFor="button-file">
                    <input
                      accept="image/png, image/jpg, image/jpeg"
                      className="hidden"
                      id="button-file"
                      type="file"
                      onChange={handleFileChange}
                    />
                    <Button
                      size="small"
                      className="rounded-md block text-14 font-semibold px-32 py-8 mt-6 w-auto"
                      variant="contained"
                      color="secondary"
                      component="span"
                    >
                      {t('posts.browse')}
                    </Button>
                  </label>
                </div>
              </Paper>
            )}
            <Typography className="font-semibold text-18 pt-12">
              {t('primaryDetails.dietaryRestriction')}{' '}
              <span className="font-normal text-18 pl-6">({t('primaryDetails.optional')})</span>
            </Typography>
            <div className="mt-16 flex gap-12">
              <Button
                variant="outlined"
                color="secondary"
                className={`rounded-md text-black border-gray font-semibold ${
                  dietaryRestriction === 'VEGETARIAN'
                    ? 'bg-darkgreen-100 hover:bg-darkgreen-100 border-quick-hub'
                    : 'hover:bg-white hover:border-gray'
                }`}
                onClick={() => setMenuDietaryRestriction('VEGETARIAN')}
              >
                {t('primaryDetails.vegetarian')}
              </Button>
              <Button
                variant="outlined"
                color="secondary"
                className={`rounded-md text-black border-gray font-semibold ${
                  dietaryRestriction === 'VEGAN'
                    ? 'bg-darkgreen-100 hover:bg-darkgreen-100 border-quick-hub'
                    : 'hover:bg-white hover:border-gray'
                }`}
                onClick={() => setMenuDietaryRestriction('VEGAN')}
              >
                {t('primaryDetails.vegan')}
              </Button>
            </div>
          </div>
        </div>
        <div>
          <hr className="border border-solid !border-gray" />
          <div className="text-end flex justify-end gap-16 my-16 px-20">
            <Button
              className="rounded-md w-160 font-semibold text-16"
              variant="outlined"
              color="secondary"
              onClick={() => onSubmit()}
            >
              {t('common.cancel')}
            </Button>
            <Button
              className="rounded-md w-160 font-semibold text-16"
              variant="contained"
              color="secondary"
              onClick={() => onSubmit()}
              disabled={
                refreshIcon ||
                !menuDetails?.menuName ||
                !menuDetails?.menuPrice ||
                !menuDetails?.menuDescription ||
                !imageUrl
              }
            >
              {t('common.save')}
              {refreshIcon && (
                <CircularProgress className="text-quick-review absolute mx-auto" size={24} />
              )}
            </Button>
          </div>
        </div>
      </Drawer>
      <ErrorPopUp
        openErrorDialog={openErrorDialog}
        setOpenErrorDialog={setOpenErrorDialog}
        failedRequests={failedRequests}
        setFailedRequests={setFailedRequests}
      />
    </div>
  );
};

export default AddEditMenu;
