import PropTypes from 'prop-types';
import withReducer from 'app/store/withReducer';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { motion } from 'framer-motion';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Input from '@mui/material/Input';
import DeleteIcon from '@mui/icons-material/Delete';
import history from '@history';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import _ from '@lodash';
import './OrderedQrcode.css';
import Icon from '@mui/material/Icon';
import FuseLoading from '@fuse/core/FuseLoading';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import qrcodeData from '../../query/qrcodeOrder';
import {
  getBusinessQrOrder,
  getBusinessQrOrderStatusField,
  reSetOrderedQrcode,
} from './store/orderedQrcodeSlice';
import reducer from './store';

function EnhancedTableToolbar(props) {
  const { numSelected } = props;
  if (numSelected > 0) {
    return (
      <Toolbar className="absolute left-0 right-0 -top-80 bg-black text-white z-10 rounded-md">
        {numSelected > 0 ? (
          <Typography
            sx={{ flex: '1 1 100%' }}
            color="inherit"
            variant="subtitle1"
            component="div"
            className="text-white"
          >
            {numSelected} Selected
          </Typography>
        ) : (
          ''
        )}

        {numSelected > 0 ? (
          <Tooltip title="Delete">
            <IconButton className="text-white">
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        ) : (
          ''
        )}
      </Toolbar>
    );
  }
}

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

function OrderedQrcode() {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const orderedQrcodeList = useSelector(
    ({ orderedQrcodeReducer }) =>
      orderedQrcodeReducer?.orderedQrcode?.getBusinessQrOrderData?.data?.getBusinessQrOrder?.data ||
      []
  );

  const totalQueries = useSelector(
    ({ orderedQrcodeReducer }) =>
      orderedQrcodeReducer?.orderedQrcode?.getBusinessQrOrderData?.data?.getBusinessQrOrder
        ?.totalCount || 0
  );

  const updateQuickReviewContactStatusValue = useSelector(
    ({ orderedQrcodeReducer }) =>
      orderedQrcodeReducer?.orderedQrcode?.updateQuickReviewContactStatusData?.data
        ?.updateQuickReviewContactStatus
  );

  const [loading, setLoading] = useState(true);
  const [loadingPage, setLoadingPage] = useState(false);
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchValue, setSearchValue] = useState('');
  const [data, setData] = useState();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [orderStatusId, setOrderStatusId] = useState('all' || 0);
  const [orderStatus, setOrderStatus] = useState();

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = data?.map((n) => n.id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;

  useEffect(() => {
    async function fetchData() {
      setLoading(true);
      const payload = {
        query: qrcodeData.getBusinessQrOrder,
        variables: {
          pageSize: 10,
          pageNumber: 1,
          searchText: '',
        },
      };
      const res = await dispatch(getBusinessQrOrder(payload));
      if (res?.payload?.data?.getBusinessQrOrder?.status === 200) {
        setLoading(false);
      }
      const payloadData = {
        query: qrcodeData.getBusinessQrOrderStatusField,
      };
      const result = await dispatch(getBusinessQrOrderStatusField(payloadData));
      if (result?.payload?.data?.getBusinessQrOrderStatusField?.status === 200) {
        setOrderStatus(result?.payload?.data?.getBusinessQrOrderStatusField?.data);
      }
    }
    fetchData();
    return () => {
      dispatch(reSetOrderedQrcode());
    };
  }, [dispatch]);

  useEffect(() => {
    if (orderedQrcodeList && orderedQrcodeList?.length) {
      setData(orderedQrcodeList);
    } else {
      setData();
    }
  }, [orderedQrcodeList]);

  useEffect(() => {
    if (updateQuickReviewContactStatusValue && updateQuickReviewContactStatusValue.status === 400) {
      enqueueSnackbar(updateQuickReviewContactStatusValue.message, {
        variant: 'error',
        autoHideDuration: 3000,
      });
      closeSnackbar(3000);
    } else if (
      updateQuickReviewContactStatusValue &&
      updateQuickReviewContactStatusValue.status === 200
    ) {
      enqueueSnackbar(updateQuickReviewContactStatusValue.message, {
        variant: 'success',
        autoHideDuration: 3000,
      });
      closeSnackbar(3000);
    }
  }, [updateQuickReviewContactStatusValue, enqueueSnackbar, closeSnackbar]);

  function commonGetData(searchValues = '') {
    setSearchValue(searchValues);
    const payload = {
      query: qrcodeData.getBusinessQrOrder,
      variables: {
        searchText: searchValues,
        pageNumber: page,
        pageSize: rowsPerPage,
      },
    };
    if (orderStatusId !== 'all') {
      payload.variables.filterStatus = parseInt(orderStatusId, 10);
    }
    dispatch(getBusinessQrOrder(payload));
  }

  function handleSearchEvent(event) {
    setPage(1);
    const searchText = event.target.value;
    commonGetData(searchText);
  }

  const clearSearch = async () => {
    await setPage(1);
    await setSearchValue('');
    await commonGetData();
  };

  function handleChangePage(event, value) {
    setPage(value + 1);
    setLoadingPage(true);
    const payload = {
      query: qrcodeData.getBusinessQrOrder,
      variables: {
        pageSize: rowsPerPage,
        pageNumber: value + 1,
        searchText: searchValue,
      },
    };
    if (orderStatusId !== 'all') {
      payload.variables.filterStatus = parseInt(orderStatusId, 10);
    }
    dispatch(async (dispatchValue) => {
      await dispatchValue(getBusinessQrOrder(payload));
      setLoadingPage(false);
    });
  }

  function handleChangeRowsPerPage(event) {
    setLoadingPage(true);
    setRowsPerPage(event.target.value);
    setPage(1);
    const payload = {
      query: qrcodeData.getBusinessQrOrder,
      variables: {
        pageSize: event.target.value,
        pageNumber: 1,
        searchText: searchValue,
      },
    };
    if (orderStatusId !== 'all') {
      payload.variables.filterStatus = parseInt(orderStatusId, 10);
    }
    dispatch(async (dispatchValue) => {
      await dispatchValue(getBusinessQrOrder(payload));
      setLoadingPage(false);
    });
  }

  const handleOrderStatusFilter = (event) => {
    const orderIdData = event.target.value;
    setLoadingPage(true);
    setRowsPerPage(10);
    setPage(1);
    setOrderStatusId(orderIdData);
    const payload = {
      query: qrcodeData.getBusinessQrOrder,
      variables: {
        pageSize: 10,
        pageNumber: 1,
        searchText: searchValue,
      },
    };
    if (orderIdData !== 'all') {
      payload.variables.filterStatus = parseInt(orderIdData, 10);
    }
    dispatch(async (dispatchValue) => {
      await dispatchValue(getBusinessQrOrder(payload));
      setLoadingPage(false);
    });
  };

  function EnhancedTableHead(props) {
    const { onSelectAllClick, numSelected, rowCount } = props;

    return (
      <TableHead className="bg-grey-50 rounded-md">
        <TableRow>
          {/* <TableCell padding="checkbox" className="rounded-tl-md">
            <Checkbox
              color="primary"
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={onSelectAllClick}
              inputProps={{
                'aria-label': 'select all User',
              }}
            />
          </TableCell> */}
          <TableCell className="text-16 font-medium" align="left">
            <TableSortLabel hideSortIcon>{t('orderedQrCode.column.srNo')}</TableSortLabel>
          </TableCell>
          <TableCell className="text-16 font-medium" align="left">
            <TableSortLabel hideSortIcon>{t('common.name')}</TableSortLabel>
          </TableCell>
          <TableCell className="text-16 font-medium" align="left">
            <TableSortLabel hideSortIcon>{t('common.businessName')}</TableSortLabel>
          </TableCell>
          <TableCell className="text-16 font-medium" align="left">
            <TableSortLabel hideSortIcon>{t('common.phoneNo')}</TableSortLabel>
          </TableCell>
          <TableCell className="text-16 font-medium" align="left">
            <TableSortLabel hideSortIcon>{t('orderedQrCode.column.quantity')}</TableSortLabel>
          </TableCell>
          <TableCell className="text-16 font-medium min-w-136" align="left">
            <TableSortLabel hideSortIcon>{t('common.status')}</TableSortLabel>
          </TableCell>
          <TableCell className="text-16 font-medium rounded-tr-md">
            <TableSortLabel hideSortIcon>{t('common.action')}</TableSortLabel>
          </TableCell>
        </TableRow>
      </TableHead>
    );
  }

  if (loading) {
    return <FuseLoading />;
  }

  return (
    <div className="p-20 lg:p-24">
      {loadingPage && (
        <div className="loader-container fixed top-0 left-0 w-full h-full flex justify-center items-center">
          <div className="loader h-40 w-40 rounded-full border-4 border-solid border-white border-t-darkgreen" />
        </div>
      )}
      <div className="flex flex-1 w-full items-center justify-between mb-36">
        <div className="flex items-center">
          <Typography
            component={motion.span}
            initial={{ x: -20 }}
            animate={{ x: 0, transition: { delay: 0.2 } }}
            delay={300}
            className="sm:flex text-16 md:text-28 font-bold"
          >
            {t('orderedQrCode.orderList.title')}
          </Typography>
        </div>
        <div className="flex flex-1 items-center justify-center px-12">
          <Paper
            component={motion.div}
            initial={{ y: -20, opacity: 0 }}
            animate={{ y: 0, opacity: 1, transition: { delay: 0.2 } }}
            className="flex items-center w-full max-w-512 px-8 rounded-8 shadow-none"
          >
            <Input
              placeholder={t('common.searchPeople')}
              className="flex flex-1 mx-8 text-black text-16"
              disableUnderline
              name="searchText"
              fullWidth
              inputProps={{
                'aria-label': 'Search',
              }}
              onChange={handleSearchEvent}
              value={searchValue}
            />
            {searchValue ? (
              <Icon
                color="action"
                className="cursor-pointer"
                onClick={() => {
                  clearSearch();
                }}
              >
                close
              </Icon>
            ) : (
              <Icon color="action">search</Icon>
            )}
          </Paper>
        </div>
        <div className="items-center justify-end">
          <Select
            id="order"
            name="order"
            variant="filled"
            sx={{ '& .MuiFilledInput-input': { pt: '4px' } }}
            className="rounded-full border border-darkgreen h-40 bg-white text-14 w-full min-w-160 leading-5 shadow-none capitalize"
            size="small"
            defaultValue="all"
            value={orderStatusId}
            onChange={(e) => {
              handleOrderStatusFilter(e);
            }}
            IconComponent={() => (
              <Icon className="text-20 align-middle absolute right-14 text-black ml-2">
                filter_list
              </Icon>
            )}
          >
            <MenuItem
              value="all"
              className="capitalize hover:bg-darkgreen-100 hover:text-darkgreen mx-5 rounded-md p-6"
            >
              {t('menuItems.all')}
            </MenuItem>
            {orderStatus?.map((option) => (
              <MenuItem
                className="capitalize hover:bg-darkgreen-100 hover:text-darkgreen mx-5 rounded-md p-6"
                key={option.id}
                value={option.id}
              >
                {option.status}
              </MenuItem>
            ))}
          </Select>
        </div>
      </div>
      <div>
        {data?.length > 0 ? (
          <div className="bg-white rounded-md relative shadow">
            <EnhancedTableToolbar numSelected={selected.length} />
            <TableContainer>
              <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle" size="medium">
                <EnhancedTableHead
                  numSelected={selected.length}
                  onSelectAllClick={handleSelectAllClick}
                  rowCount={data?.length}
                />
                <TableBody>
                  {data &&
                    data.length > 0 &&
                    data.map((row, index) => {
                      const isItemSelected = isSelected(row.id);
                      const labelId = `enhanced-table-checkbox-${index}`;

                      return (
                        <TableRow
                          hover
                          className="border border-t-2 border-t-grey-100"
                          role="checkbox"
                          aria-checked={isItemSelected}
                          tabIndex={-1}
                          key={row.id}
                          selected={isItemSelected}
                        >
                          {/* <TableCell
                            padding="checkbox"
                            onClick={(event) => handleClick(event, row.id)}
                            sx={{ cursor: 'pointer' }}
                          >
                            <Checkbox
                              color="primary"
                              checked={isItemSelected}
                              inputProps={{
                                'aria-labelledby': labelId,
                              }}
                            />
                          </TableCell> */}
                          <TableCell className="text-16 color-black font-semibold" align="left">
                            {(page - 1) * rowsPerPage + index + 1}
                          </TableCell>
                          <TableCell className="text-16 color-black font-semibold" align="left">
                            {row?.fullName}
                          </TableCell>
                          <TableCell className="text-16 color-black font-semibold" align="left">
                            {row.businessName}
                          </TableCell>
                          <TableCell className="text-16 color-black font-semibold" align="left">
                            {row?.countryCode} {row?.phoneNumber}
                          </TableCell>
                          <TableCell className="text-16 color-black font-semibold" align="left">
                            {row?.quantity}
                          </TableCell>
                          <TableCell
                            className={`text-16 color-black font-semibold ${
                              row?.qrCodeOrderStatusField?.id === 1
                                ? 'text-[#00D207]'
                                : row?.qrCodeOrderStatusField?.id === 2
                                ? 'text-[#646464]'
                                : row?.qrCodeOrderStatusField?.id === 3
                                ? 'text-[#0A45E6]'
                                : row?.qrCodeOrderStatusField?.id === 4
                                ? 'text-[#E88B00]'
                                : row?.qrCodeOrderStatusField?.id === 5
                                ? 'text-[#00731A]'
                                : 'text-black'
                            }`}
                            align="left"
                          >
                            {row?.qrCodeOrderStatusField?.status}
                          </TableCell>
                          <TableCell className="text-16 color-black font-semibold" align="left">
                            <span
                              className="inline-block bg-darkgreen-100 rounded text-darkgreen py-4 px-12 text-16 font-semibold cursor-pointer hover:bg-darkgreen hover:text-white"
                              aria-hidden="true"
                              onClick={() => {
                                history.push(`qrcode-orders-details/${row?.id}`);
                              }}
                            >
                              {t('common.viewDetails')}
                            </span>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        ) : (
          <>
            {searchValue || orderStatusId !== 'all' ? (
              <div className="bg-white rounded-md relative shadow">
                <motion.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1, transition: { delay: 0.1 } }}
                  className="flex flex-1 items-center justify-center h-full"
                >
                  <Typography color="textSecondary" variant="h5" className="text-16 py-12">
                    {t('orderedQrCode.orderList.messages.noQueries')}
                  </Typography>
                </motion.div>
              </div>
            ) : (
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1, transition: { delay: 0.1 } }}
                className="flex flex-1 items-center justify-center noOrder-height"
              >
                <div className="mx-auto text-center">
                  <span className="block text-center">
                    <img
                      src="/assets/images/admin-icon/no_order.png"
                      className="mx-auto mb-12"
                      width="213"
                      height="212"
                      alt="..."
                    />
                  </span>
                  <Typography
                    color="textSecondary"
                    variant="h5"
                    className="text-24 py-12 block font-semibold text-black"
                  >
                    {t('orderedQrCode.orderList.messages.noQueries')}
                  </Typography>
                  <Typography
                    variant="h5"
                    className="text-18 font-medium py-12 text-black max-w-[900px]"
                  >
                    {t('orderedQrCode.orderList.messages.desc')}
                  </Typography>
                </div>
              </motion.div>
            )}
          </>
        )}
      </div>

      {data?.length > 0 && (
        <TablePagination
          sx={{
            '.MuiInputBase-root': {
              lineHeight: 'inherit',
              padding: 0,
            },
          }}
          component="div"
          count={totalQueries}
          rowsPerPage={rowsPerPage}
          page={page - 1}
          backIconButtonProps={{
            'aria-label': 'Previous Page',
          }}
          nextIconButtonProps={{
            'aria-label': 'Next Page',
          }}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      )}
    </div>
  );
}

export default withReducer('orderedQrcodeReducer', reducer)(OrderedQrcode);
