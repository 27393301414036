import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Tab, Tabs } from '@mui/material';
import { useLocation } from 'react-router-dom';
import { selectDashboardData } from 'src/app/store/userSlice';
import { handleApiRequest } from '../../common/common';
import quickSocialQuery from '../../query/quickSocial';

const SocialPlatformTab = ({ onSelectTab, socialData, setSocialData }) => {
  const location = useLocation();
  const userCurrentBusinessDetails = useSelector(selectDashboardData);
  const [activeStep, setActiveStep] = useState(location?.state?.activeStep);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (userCurrentBusinessDetails?.id) {
          const payloadData = {
            query: quickSocialQuery.getQuickSocialChannelWiseData,
            variables: {
              businessId: parseInt(userCurrentBusinessDetails?.id, 10),
            },
          };
          const resultData = await handleApiRequest(payloadData);
          if (resultData?.getQuickSocialChannelWiseData?.status === 200) {
            const fetchedData = resultData?.getQuickSocialChannelWiseData?.data;
            setSocialData(fetchedData);
            if (!location?.state?.activeStep && fetchedData?.length > 0) {
              setActiveStep(fetchedData[0]?.reviewPlatformId);
              onSelectTab(fetchedData[0]?.reviewPlatformId);
            }
          }
        }
      } catch (error) {
        console.error('An error occurred while fetching data:', error);
      }
    };
    fetchData();
  }, [userCurrentBusinessDetails?.id, location?.state?.activeStep, setSocialData, onSelectTab]);

  const handleTabClick = (event, id) => {
    setActiveStep(id);
    if (onSelectTab) {
      onSelectTab(id);
    }
  };

  return (
    <div className="bg-white rounded-md mb-24">
      {socialData && socialData?.length > 0 && (
        <Tabs
          value={activeStep}
          className="sm:overflow-hidden overflow-scroll"
          sx={{
            '& .MuiTabs-scroller': {
              overflow: { xs: 'scroll !important', sm: 'hidden !important' },
            },
            '& .MuiTabs-indicator': {
              backgroundColor: 'skyblue',
            },
          }}
          onChange={handleTabClick}
          textColor=""
          indicatorColor="secondary"
          aria-label="secondary tabs example"
        >
          {socialData?.map((social, i) => (
            <Tab
              key={i}
              className={`sm:text-16 font-semibold px-24 py-16 cursor-pointer ${
                activeStep === social?.reviewPlatformId
                  ? 'bg-quick-social-100 text-quick-social'
                  : 'bg-transparent'
              }`}
              value={social?.reviewPlatformId}
              label={
                <div className="flex items-center">
                  <img
                    className="mr-12"
                    width="20"
                    height="20"
                    src={social?.logo}
                    alt={social?.name}
                  />
                  <span className="capitalize">{social?.name}</span>
                </div>
              }
            />
          ))}
          {window.location.pathname === '/quick-social/share-post/' && (
            <Tab
              className={`sm:text-16 font-semibold px-24 py-16 cursor-pointer 
    ${activeStep === 1 ? 'bg-quick-social-100 text-quick-social' : 'bg-transparent'}`}
              value={1}
              label={
                <div className="flex items-center">
                  <img
                    className="mr-12"
                    width="20"
                    height="20"
                    src="/assets/images/icon/share.png"
                    alt="share"
                  />
                  <span className="capitalize">Direct Share</span>
                </div>
              }
            />
          )}
        </Tabs>
      )}
    </div>
  );
};

export default SocialPlatformTab;
