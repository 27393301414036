import { selectDashboardData } from 'app/store/userSlice';
import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams, useLocation } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { t } from 'i18next';
import history from '@history';
import { Button, Icon, Select, Toolbar, Typography } from '@mui/material';
import FuseLoading from '@fuse/core/FuseLoading';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { getParamsId } from 'src/app/services/userService';
import manageBusinessData from '../../query/manageBusiness';
import { handleApiRequest } from '../../common/common';
import DeletePopUp from '../manage-business/PopUp';
import QrCodePopup from './QrCodePopup';

const ProductDetails = () => {
  const params = useParams();
  const productsId = useParams();
  const location = useLocation();
  const { plateFormID, productIds, paramsId } = location.state || {};
  const userCurrentBusinessDetails = useSelector(selectDashboardData);
  const [productDetailsData, setProductDetailsData] = useState();
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const [openDeleteDialogue, setOpenDeleteDialogue] = useState(false);
  const [refreshIcon, setRefreshIcon] = useState(false);
  const [productQrCode, setProductQrCode] = useState();
  const [qrCodeDialog, setQrCodeDialog] = useState(false);
  const [productName, setProductName] = useState(false);
  const [productId, setProductId] = useState(productIds);

  const redirect = (type) => {
    try {
      if (type === 'review-mode') {
        if (paramsId) {
          history.push(`/productDetails/reviewMode/${Number(productsId.productId)}/${paramsId}`, {
            plateFormID,
          });
        } else {
          history.push(`/productDetails/reviewMode/${Number(productsId.productId)}`, {
            plateFormID,
          });
        }
      }
      if (type === 'auto-generate-text') {
        if (paramsId) {
          history.push(
            `/productDetails/autoGenerateText/${Number(productsId.productId)}/${paramsId}`,
            {
              plateFormID,
            }
          );
        } else {
          history.push(`/productDetails/autoGenerateText/${Number(productsId.productId)}`, {
            plateFormID,
          });
        }
      }
      if (type === 'custom-attribute') {
        if (paramsId) {
          history.push(
            `/productDetails/customAttribute/${Number(productsId.productId)}/${paramsId}`,
            {
              plateFormID,
              productDetailsData,
            }
          );
        } else {
          history.push(`/productDetails/customAttribute/${Number(productsId.productId)}`, {
            plateFormID,
            productDetailsData,
          });
        }
      }
      if (type === 'invite-reviews') {
        if (paramsId) {
          history.push(
            `/productDetails/inviteReviews/${Number(productsId.productId)}/${paramsId}`,
            {
              plateFormID,
              productDetailsData,
            }
          );
        } else {
          history.push(`/productDetails/inviteReviews/${Number(productsId.productId)}`, {
            plateFormID,
            productDetailsData,
          });
        }
      }
      if (type === 'service-recovery') {
        if (paramsId) {
          history.push(
            `/productDetails/serviceRecovery/${Number(productsId.productId)}/${paramsId}`,
            {
              plateFormID,
              productDetailsData,
            }
          );
        } else {
          history.push(`/productDetails/serviceRecovery/${Number(productsId.productId)}`, {
            plateFormID,
            productDetailsData,
            paramsId,
          });
        }
      }
    } catch (error) {
      console.error('error', error);
    }
  };

  useEffect(() => {
    const fetchProducts = async () => {
      setLoading(true);
      if (userCurrentBusinessDetails?.id && plateFormID) {
        const payload = {
          query:
            Number(plateFormID) === 5
              ? manageBusinessData.getAmazonProduct
              : Number(plateFormID) === 6 && manageBusinessData.getFlipkartProduct,
          variables: {
            userBusinessId: Number(userCurrentBusinessDetails?.id),
            ...(Number(plateFormID) === 5 && { amazonProductId: Number(productId) }),
            ...(Number(plateFormID) === 6 && { flipkartProductId: Number(productId) }),
          },
        };

        const result = await handleApiRequest(payload);
        const res =
          Number(plateFormID) === 5
            ? result?.getAmazonProduct
            : Number(plateFormID) === 6 && result?.getFlipkartProduct;
        setLoading(false);
        if (res?.status === 200) {
          const filteredProducts = res?.data?.filter((item) => item.id);
          setProductDetailsData(filteredProducts);
          setProductQrCode(filteredProducts[0]?.businessQRRedirectUrl);
          setProductName(filteredProducts[0]?.title);
        } else {
          enqueueSnackbar(res?.message, {
            variant: 'error',
            autoHideDuration: 3000,
          });
        }
      }
    };
    fetchProducts();
  }, [enqueueSnackbar, plateFormID, productId, userCurrentBusinessDetails?.id]);

  const deleteAmazonOrFlipkartProduct = async () => {
    setLoading(true);
    if (params?.productId) {
      const obj =
        Number(plateFormID) === 5
          ? {
              query: manageBusinessData.deleteAmazonProduct,
              variables: {
                amazonProductId: Number(params?.productId),
              },
            }
          : Number(plateFormID) === 6 && {
              query: manageBusinessData.deleteFlipkartProduct,
              variables: {
                flipkartProductId: Number(params?.productId),
              },
            };
      try {
        setRefreshIcon(true);
        const result = await handleApiRequest(obj);
        setRefreshIcon(false);
        const res =
          Number(plateFormID) === 5
            ? result?.deleteAmazonProduct
            : Number(plateFormID) === 6 && result?.deleteFlipkartProduct;
        if (res?.status === 200) {
          handelCancelDeleteProduct();
          setLoading(false);
          history.push('quick-reviews/product/');
        } else {
          enqueueSnackbar(res?.message, {
            variant: 'error',
            autoHideDuration: 2000,
          });
        }
      } catch (error) {
        console.error('Error deleting product:', error);
      }
    }
  };

  const openDeletePopup = (id) => {
    setProductId(id);
    setOpenDeleteDialogue(true);
  };

  const handelCancelDeleteProduct = () => {
    setOpenDeleteDialogue(false);
    setProductId('');
  };

  const openQrCodeDialog = (id) => {
    setProductId(id);
    setQrCodeDialog(true);
  };

  const handelCancelQrCode = () => {
    setQrCodeDialog(false);
  };

  if (loading) {
    return <FuseLoading />;
  }
  return (
    <div className="p-20 lg:p-24 h-full">
      {userCurrentBusinessDetails?.isOnlineBusiness && (
        <div>
          <Toolbar className="px-0 font-semibold min-h-fit mb-20">
            <Icon className="text-20 cursor-pointer" onClick={() => history.go(-1)}>
              west
            </Icon>
            <Typography
              variant="subtitle1"
              color="inherit"
              className="flex-1 px-12 font-bold text-18"
            >
              {t('products.title')}
            </Typography>
          </Toolbar>
          <div className="sm:flex w-full items-center justify-between sm:mb-36 mb-20">
            <Typography
              initial={{ x: -20 }}
              animate={{ x: 0, transition: { delay: 0.2 } }}
              delay={300}
              className="sm:flex text-20 md:text-28 font-bold"
            >
              {t('products.productSettings')}
            </Typography>
          </div>

          {productDetailsData && productDetailsData.length > 0 && (
            <>
              {productDetailsData?.map((product) => (
                <div className="bg-white rounded-md relative shadow" key={product?.id}>
                  <div className="pb-10">
                    <div className="p-10 w-full flex justify-start items-center flex-col sm:flex-row gap-20 sm:gap-0">
                      <img
                        src={product?.profileUrl}
                        className="max-w-160 max-h-160-h-36 rounded-md sm:mr-28 mr-20"
                        alt="..."
                      />
                      <Typography className="sm:w-4/6 w-full font-600 sm:text-20 text-16">
                        {product?.genericName}
                      </Typography>
                    </div>
                    <div className="px-24">
                      <hr className="w-full border-t-2 border-dashed border-gray-500 my-24" />
                    </div>
                    <div>
                      <div className="flex flex-row  justify-between sm:m-20 m-10 sm:p-12 p-6 rounded-12  border border-gray">
                        <div className=" flex flex-row w-11/12 items-center">
                          <img
                            src="/assets/images/icon/autogenerate_text.svg"
                            className="w-full  max-w-32 max-h-32 sm:mr-28 mr-20"
                            alt="..."
                          />
                          <Typography className="w-4/6 font-600 sm:text-16 text-14 flex items-center">
                            {t('autoGenerateText.title')}
                          </Typography>
                        </div>
                        <div>
                          <Button
                            className="border w-full border-quick-review rounded-full flex items-center p-2 hover:border-quick-review hover:bg-transparent"
                            onClick={() => redirect('auto-generate-text')}
                          >
                            <KeyboardArrowRightIcon className="text-quick-review border border-quick-review rounded-full p-2" />
                          </Button>
                        </div>
                      </div>
                      <div className="flex flex-row  justify-between sm:m-20 m-10 sm:p-12 p-6 rounded-12  border border-gray">
                        <div className=" flex flex-row w-11/12 items-center">
                          <img
                            src="/assets/images/icon/qr__code.svg"
                            className="w-full  max-w-32 max-h-32 sm:mr-28 mr-20"
                            alt="..."
                          />
                          <Typography className="w-4/6 font-600 sm:text-16 text-14 flex items-center">
                            {t('qrCode.title')}
                          </Typography>
                        </div>
                        <div>
                          <Button
                            className="border w-full border-quick-review rounded-full flex items-center p-2 hover:border-quick-review hover:bg-transparent"
                            onClick={() => openQrCodeDialog(product?.id)}
                          >
                            <KeyboardArrowRightIcon className="text-quick-review border border-quick-review rounded-full p-2" />
                          </Button>
                        </div>
                      </div>
                      <div className="flex flex-row  justify-between sm:m-20 m-10 sm:p-12 p-6 rounded-12  border border-gray">
                        <div className=" flex flex-row w-11/12 items-center">
                          <img
                            src="/assets/images/icon/delete.svg"
                            className="w-full  max-w-32 max-h-32 sm:mr-28 mr-20"
                            alt="..."
                          />
                          <Typography className="w-4/6 font-600 sm:text-16 text-14 flex items-center">
                            {t('common.delete')}
                          </Typography>
                        </div>
                        <div>
                          <Button
                            className="border w-full border-quick-review rounded-full flex items-center p-2 hover:border-quick-review hover:bg-transparent"
                            onClick={() => openDeletePopup(params?.productId)}
                            type="button"
                          >
                            <KeyboardArrowRightIcon className="text-quick-review border border-quick-review rounded-full p-2" />
                          </Button>
                        </div>
                      </div>
                      <div className="flex flex-row  justify-between sm:m-20 m-10 sm:p-12 p-6 rounded-12  border border-gray">
                        <div className=" flex flex-row w-11/12 items-center">
                          <img
                            src="/assets/images/icon/custom_attributes.svg"
                            className="w-full  max-w-32 max-h-32 sm:mr-28 mr-20"
                            alt="..."
                          />
                          <Typography className="w-4/6 font-600 sm:text-16 text-14 flex items-center">
                            {t('manageBusiness.addBusinessSteps.customAttribute.dialog.title')}
                          </Typography>
                        </div>
                        <div>
                          <Button
                            className="border w-full border-quick-review rounded-full flex items-center p-2 hover:border-quick-review hover:bg-transparent"
                            onClick={() => redirect('custom-attribute')}
                          >
                            <KeyboardArrowRightIcon className="text-quick-review border border-quick-review rounded-full p-2" />
                          </Button>
                        </div>
                      </div>
                      {/*   <div className="flex flex-row  justify-between sm:m-20 m-10 sm:p-12 p-6 rounded-12  border border-gray">
                        <div className=" flex flex-row w-11/12 items-center">
                          <img
                            src="/assets/images/icon/invite-reviews.svg"
                            className="w-full  max-w-32 max-h-32 sm:mr-28 mr-20"
                            alt="..."
                          />
                          <Typography className="w-4/6 font-600 sm:text-16 text-14 flex items-center">
                            {t('inviteReviews.title')}
                          </Typography>
                        </div>
                        <div>
                          <Button
                            className="border w-full border-quick-review rounded-full flex items-center p-2 hover:border-quick-review hover:bg-transparent"
                            onClick={() => redirect('invite-reviews')}
                          >
                            <KeyboardArrowRightIcon className="text-quick-review border border-quick-review rounded-full p-2" />
                          </Button>
                        </div>
                      </div> */}
                      <div className="flex flex-row  justify-between sm:m-20 m-10 sm:p-12 p-6 rounded-12  border border-gray">
                        <div className=" flex flex-row w-11/12 items-center">
                          <img
                            src="/assets/images/icon/review_mode.svg"
                            className="w-full  max-w-32 max-h-32 sm:mr-28 mr-20"
                            alt="..."
                          />
                          <Typography className="w-4/6 font-600 sm:text-16 text-14 flex items-center">
                            {t('reviewScreenMode.title')}
                          </Typography>
                        </div>
                        <div>
                          <Button
                            className="border w-full border-quick-review rounded-full flex items-center p-2 hover:border-quick-review hover:bg-transparent"
                            onClick={() => redirect('review-mode')}
                          >
                            <KeyboardArrowRightIcon className="text-quick-review border border-quick-review rounded-full p-2" />
                          </Button>
                        </div>
                      </div>
                      <div className="flex flex-row  justify-between sm:m-20 m-10 sm:p-12 p-6 rounded-12  border border-gray">
                        <div className=" flex flex-row w-11/12 items-center">
                          <img
                            src="/assets/images/icon/service_recovery.svg"
                            className="w-full  max-w-32 max-h-32 sm:mr-28 mr-20"
                            alt="..."
                          />
                          <Typography className="w-4/6 font-600 sm:text-16 text-14 flex items-center">
                            {t('serviceRecovery.title')}
                          </Typography>
                        </div>
                        <div>
                          <Button
                            className="border w-full border-quick-review rounded-full flex items-center p-2 hover:border-quick-review hover:bg-transparent"
                            onClick={() => redirect('service-recovery')}
                          >
                            <KeyboardArrowRightIcon className="text-quick-review border border-quick-review rounded-full p-2" />
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </>
          )}
          <DeletePopUp
            openValue={openDeleteDialogue}
            eventCall={deleteAmazonOrFlipkartProduct}
            cancelCall={handelCancelDeleteProduct}
            refreshIcon={refreshIcon}
            popUpDescription={t('products.deleteProductMessage')}
            popUpMessage={t('common.areYouSure')}
            popUpButtonName={t('products.delete')}
          />
          <QrCodePopup
            value={productQrCode}
            productId={productDetailsData}
            openValue={qrCodeDialog}
            productName={productName}
            cancelCall={handelCancelQrCode}
          />
        </div>
      )}
    </div>
  );
};

export default ProductDetails;
