import { useEffect, useState } from 'react';
import { Button, TextField, Typography } from '@mui/material';
import { motion } from 'framer-motion';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';
import { t } from 'i18next';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';
import _ from '@lodash';
import { yupResolver } from '@hookform/resolvers/yup';
import ReactFlagsSelect from 'react-flags-select';
import { publicIpv4 } from 'public-ip';
import Icon from '@mui/material/Icon';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FuseLoading from '@fuse/core/FuseLoading';
import history from '@history';
import { useSnackbar } from 'notistack';
import { useSelector } from 'react-redux';
import salesData from '../../query/sales';
import {
  getDataToServer,
  getDecryptData,
  getEncryptedData,
  getDataToServerForAuth,
} from '../../common/common';
import queryData from '../../query/common';
import UserService from '../../../services/userService';
import { getCountryData } from '../../../store/userSlice';

const defaultValues = {
  email: '',
  password: '',
  firstname: '',
  lastname: '',
};

const CreateSales = () => {
  const schema = yup.object().shape({
    email: yup
      .string()
      .required(t('signInPage.emailValidation'))
      .email(t('signInPage.formField.email.validationMessages.validEmail'))
      .trim(),
    firstname: yup
      .string()
      .required(t('signUpPage.formField.firstName.validationMessages.required'))
      .max(15, t('signUpPage.formField.firstName.validationMessages.max'))
      .matches(/^[a-zA-Z]+$/, t('signUpPage.formField.firstName.validationMessages.matches')),
    lastname: yup
      .string()
      .required(t('signUpPage.formField.lastName.validationMessages.required'))
      .max(15, t('signUpPage.formField.lastName.validationMessages.max'))
      .matches(/^[a-zA-Z]+$/, t('signUpPage.formField.lastName.validationMessages.matches')),
    middlename: yup
      .string()
      .notRequired()
      .when('middle_name', {
        is: (value) => value?.length,
        then: (rule) =>
          rule
            .max(15, t('signUpPage.formField.middleName.validationMessages.max'))
            .matches(
              /^[a-zA-Z ]+$/,
              t('signUpPage.formField.middleName.validationMessages.matches')
            ),
      }),
    password: yup
      .string()
      .required(t('signInPage.formField.password.validationMessages.required'))
      .min(8, t('signInPage.formField.password.validationMessages.min'))
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{4,}$/,
        t('signInPage.formField.password.validationMessages.matches')
      ),
    passwordConfirm: yup
      .string()
      .required(t('signUpPage.formField.passwordConfirm.validationMessages.required'))
      .oneOf(
        [yup.ref('password'), null],
        t('signUpPage.formField.passwordConfirm.validationMessages.passwordMatch')
      ),
  });

  const { control, formState, handleSubmit, reset, setValue, getValues, watch, clearErrors } =
    useForm({
      mode: 'onChange',
      defaultValues,
      resolver: yupResolver(schema),
    });

  const form = watch();

  const { isValid, dirtyFields, errors, setError } = formState;

  const customLabels =
    ('Custom Labels',
    {
      IN: { primary: 'IN', secondary: '+91' },
      US: { primary: 'US', secondary: '+1' },
    });
  const loginUserData = UserService.getUserData();
  const [selected, setSelected] = useState('IN');
  const [countryData, setCountryData] = useState([]);
  const [stateData, setStateData] = useState([]);
  const [country, setCountry] = useState(0);
  const [state, setState] = useState(0);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const countriesData = useSelector(getCountryData);

  useEffect(() => {
    setCountryData(countriesData);
  }, [countriesData]);

  const handleSelectCountry = (event) => {
    setCountry(event.target.value);
  };

  const handleSelectState = (event) => {
    setState(event.target.value);
  };

  useEffect(() => {
    async function fetchData() {
      if (country) {
        const countryId = parseInt(country, 10);
        const payload = {
          query: queryData.getStates,
          variables: { countryId },
        };
        const res = await getDataToServer(payload);
        if (res?.getStates?.status === 200) {
          setStateData(res.getStates?.data);
        }
      }
    }
    fetchData();
  }, [country]);

  const onSubmit = async ({
    email,
    password,
    firstname,
    zipcode,
    cityName,
    middlename,
    salesid,
    lastname,
    address1,
    mobileNumber,
  }) => {
    const checkPhoneNumber = /^(\+\d{1,2}\s?)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/.test(
      getValues('mobileNumber')
    );
    if (!getValues('mobileNumber')) {
      enqueueSnackbar(t('signInPage.enterPhoneNumber'), {
        variant: 'error',
        autoHideDuration: 3000,
      });
    } else if (!checkPhoneNumber) {
      enqueueSnackbar(t('signInPage.enterCorrectPhoneNumber'), {
        variant: 'error',
        autoHideDuration: 3000,
      });
    } else if (!country) {
      enqueueSnackbar(t('signUpPage.countryRequired'), {
        variant: 'error',
        autoHideDuration: 3000,
      });
    } else if (country && !state) {
      enqueueSnackbar(t('signUpPage.stateRequired'), {
        variant: 'error',
        autoHideDuration: 3000,
      });
    } else {
      const data = getEncryptedData(
        JSON.stringify({
          e: email,
          p: password,
          firstName: firstname,
          middleName: middlename,
          lastName: lastname,
          salesId: salesid,
          phoneNumber: {
            countryCode: `+${loginUserData?.countryCode}`,
            phoneNumber: parseInt(mobileNumber, 10),
            isoCode: loginUserData?.isoCode,
          },
          countryId: parseInt(country, 10),
          stateId: parseInt(state, 10),
          zipCode: parseInt(zipcode, 10),
          city: cityName,
          address: address1,
          timeZone: '',
        })
      );
      const payload = {
        query: salesData.signUpForSalesPerson,
        variables: { data },
      };
      const res = await getDataToServerForAuth(payload);
      if (
        res?.signUpForSalesPerson?.status === 200 &&
        res?.signUpForSalesPerson?.message !== 'Email address already exists' &&
        res?.signUpForSalesPerson?.message !== 'Phone Number already exists'
      ) {
        enqueueSnackbar(t('signUpPage.salePersonAccountCreatedAnsEmailSend'), {
          variant: 'success',
          autoHideDuration: 3000,
        });
        history.push('/sales-person');
      } else {
        enqueueSnackbar(res?.signUpForSalesPerson?.message, {
          variant: 'error',
          autoHideDuration: 3000,
        });
      }
    }
  };

  const handleCancel = () => {
    history.push('/sales-person');
  };

  if (_.isEmpty(form)) {
    return <FuseLoading />;
  }
  return (
    <div className="p-20 lg:p-24">
      <div className="flex items-center gap-12">
        <Icon className="text-20 cursor-pointer" onClick={() => history.go(-1)}>
          west
        </Icon>
        <Typography
          component={motion.span}
          initial={{ x: -20 }}
          animate={{ x: 0, transition: { delay: 0.2 } }}
          delay={300}
          className="sm:flex text-16 md:text-20 font-bold"
        >
          {t('salesPerson.title')}
        </Typography>
      </div>
      <div className="flex items-center gap-20 mt-8">
        <div className="bg-white p-8">
          <PersonAddAlt1Icon />
        </div>
        <Typography className="sm:flex text-16 md:text-28 font-bold">
          {t('salesPerson.createSalesPerson')}
        </Typography>
      </div>
      <div className="bg-white mt-40 w-[40%] overflow-y-auto max-h-screen">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="bg-lightgray pt-12">
            <Typography className="sm:flex pl-16 pr-0 text-16 md:text-16 font-medium">
              {t('salesPerson.addNewPerson')}
            </Typography>
          </div>
          <div className="pl-16 pt-6 pr-16">
            <div className="pt-24">
              <Controller
                name="firstname"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    className="w-full"
                    label={t('common.firstName')}
                    type="text"
                    size="small"
                    error={!!errors.firstname}
                    helperText={errors?.firstname?.message}
                    variant="outlined"
                    fullWidth
                    required
                  />
                )}
              />
            </div>
            <div className="pt-16">
              <Controller
                name="middlename"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    className="w-full"
                    label={`${t('userProfile.middleName')}`}
                    type="text"
                    size="small"
                    variant="outlined"
                    fullWidth
                  />
                )}
              />
            </div>
            <div className="pt-16">
              <Controller
                name="lastname"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    className="w-full"
                    label={t('common.lastName')}
                    size="small"
                    type="text"
                    error={!!errors.lastname}
                    helperText={errors?.lastname?.message}
                    variant="outlined"
                    fullWidth
                    required
                  />
                )}
              />
            </div>
            <div className="pt-16">
              <Controller
                name="salesid"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    className="w-full"
                    label={`${t('signUpPage.formField.salesId.name')}`}
                    type="text"
                    size="small"
                    variant="outlined"
                    fullWidth
                  />
                )}
              />
            </div>
            <div className="pt-16">
              <Controller
                name="email"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    className="w-full"
                    label={t('common.email')}
                    size="small"
                    type="text"
                    error={!!(errors.email || (formState.isSubmitted && !getValues('email')))}
                    helperText={
                      errors?.email?.message ||
                      (formState.isSubmitted && !getValues('email') && 'Email is required')
                    }
                    variant="outlined"
                    fullWidth
                    required
                  />
                )}
              />
            </div>
            <div className="pt-16">
              <Controller
                name="password"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    className="w-full"
                    label={t('common.password')}
                    type="text"
                    size="small"
                    error={!!errors.password}
                    helperText={errors?.password?.message}
                    variant="outlined"
                    fullWidth
                    required
                  />
                )}
              />
            </div>
            <div className="pt-16">
              <Controller
                name="passwordConfirm"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    className="w-full"
                    label={t('userProfile.confirmPassword')}
                    size="small"
                    type="text"
                    error={!!errors.passwordConfirm}
                    helperText={errors?.passwordConfirm?.message}
                    variant="outlined"
                    fullWidth
                    required
                  />
                )}
              />
            </div>
            <div className="flex flex-col justify-center w-full pt-16">
              <span className="relative">
                <span className="absolute z-20">
                  <ReactFlagsSelect
                    className="react-flag-button w-auto"
                    selected={selected}
                    onSelect={(code) => setSelected(code)}
                    countries={['US', 'IN']}
                    searchable
                    customLabels={customLabels}
                    showSelectedLabel={false}
                  />
                </span>
                <Controller
                  name="mobileNumber"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      className="rounded-md"
                      placeholder={`${t('common.phoneNumber')}*`}
                      style={{ height: '44px' }}
                      type="number"
                      sx={{
                        '& .MuiInputBase-input': {
                          border: '1px solid #666666',
                          color: 'rgb(107, 114, 128)',
                          opacity: 1,
                          textIndent: '80px !important',
                          transition: 'all .2s ease-in-out',
                          borderRadius: '7px',
                        },
                      }}
                      size="small"
                      variant="outlined"
                      fullWidth
                    />
                  )}
                />
              </span>
            </div>
            <div className="pt-16">
              <Controller
                name="address1"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    className="w-full"
                    size="small"
                    label={`${t('common.address')}*`}
                    type="text"
                    variant="outlined"
                    fullWidth
                  />
                )}
              />
            </div>
            <div className="pt-16">
              <Controller
                name="country"
                control={control}
                render={({ field }) => (
                  <Select
                    {...field}
                    className="w-full"
                    id="country"
                    size="small"
                    value={country}
                    displayEmpty
                    error={!!errors.country}
                    onChange={(e) => {
                      handleSelectCountry(e);
                    }}
                  >
                    <MenuItem value={0} disabled>
                      {`${t('signUpPage.formField.country.selectCountry')}*`}
                    </MenuItem>
                    {countryData?.map((option) => (
                      <MenuItem key={option.id} value={option.id}>
                        {option.name}
                      </MenuItem>
                    ))}
                  </Select>
                )}
              />
            </div>
            <div className="pt-16">
              <Controller
                name="state"
                control={control}
                render={({ field }) => (
                  <Select
                    {...field}
                    className="w-full"
                    size="small"
                    value={state}
                    displayEmpty
                    id="state"
                    onChange={(e) => {
                      handleSelectState(e);
                    }}
                    fullWidth
                  >
                    <MenuItem value={0} disabled>
                      {`${t('signUpPage.formField.state.selectState')}*`}
                    </MenuItem>
                    {stateData?.map((option) => (
                      <MenuItem key={option.id} value={option.id}>
                        {option.name}
                      </MenuItem>
                    ))}
                  </Select>
                )}
              />
            </div>
            <div className="pt-16">
              <Controller
                name="cityName"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    className="w-full"
                    size="small"
                    label={`${t('common.city')}*`}
                    type="text"
                    variant="outlined"
                    fullWidth
                  />
                )}
              />
            </div>
            <div className="pt-16">
              <Controller
                name="zipcode"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    className="w-full"
                    label={`${t('common.zipCode')}*`}
                    size="small"
                    type="text"
                    variant="outlined"
                    fullWidth
                  />
                )}
              />
            </div>
          </div>
          <div className="text-center my-40">
            <Button
              type="button"
              className="text-16 px-48 ml-10"
              variant="contained"
              onClick={() => handleCancel()}
            >
              {t('common.cancel')}
            </Button>
            <Button
              type="submit"
              className="text-16 px-24 ml-10 bg-darkgreen hover:bg-darkgreen text-white"
              variant="contained"
            >
              {t('salesPerson.button.createAccount')}
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default CreateSales;
