import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import withReducer from 'app/store/withReducer';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Typography from '@mui/material/Typography';
import { useParams } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';
import Icon from '@mui/material/Icon';
import Toolbar from '@mui/material/Toolbar';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import CircularProgress from '@mui/material/CircularProgress';
import Button from '@mui/material/Button';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { motion } from 'framer-motion';
import FuseLoading from '@fuse/core/FuseLoading';
import _ from '@lodash';
import history from '@history';
import { useSnackbar } from 'notistack';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import InputLabel from '@mui/material/InputLabel';
import QrCode from '../selected-business/business-informaion-tabs/QrCode';
import selectedBusinessData from '../../query/selectedBusiness';
import {
  getDemoBusinessWithDemoUserDetail,
  convertedAccountExtendExpireDate,
  resetConvertedBusiness,
} from './store/convertedBusinessSlice';
import reducer from './store';
import { formatDateWithTimeZone } from '../../common/common';

const defaultValues = {
  // reason: '',
};

function ConvertedBusinessDetails() {
  const dispatch = useDispatch();
  const routeParams = useParams();
  const { t } = useTranslation();
  const [timeZone] = useState(Intl.DateTimeFormat().resolvedOptions().timeZone);

  const schema = yup.object().shape({
    reason: yup.string().required(t('business.businessType.validationMessage.required')),
  });

  const selectedBusinessListDetails = useSelector(
    ({ convertedBusinessReducer }) =>
      convertedBusinessReducer?.convertedBusiness?.getDemoBusinessWithDemoUserDetailData?.data
        ?.getDemoBusinessWithDemoUserDetail?.data || ''
  );

  const convertedAccountExtendExpireDateStatus = useSelector(
    ({ convertedBusinessReducer }) =>
      convertedBusinessReducer?.convertedBusiness?.convertedAccountExtendExpireDateData?.data
        ?.convertedAccountExtendExpireDate || ''
  );

  const [loading, setLoading] = useState(true);
  const [tabValue, setTabValue] = useState(0);
  const [refreshIcon, setRefreshIcon] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [extendUserId, setExtendUserId] = useState();
  const [businessId, setBusinessId] = useState(parseInt(routeParams.businessId, 10));
  const [numberOfDay, setNumberOfDay] = useState(0);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);
  const { control, watch, reset, handleSubmit, formState, getValues, setError } = useForm({
    mode: 'onChange',
    defaultValues,
    resolver: yupResolver(schema),
  });
  const { isValid, dirtyFields, errors } = formState;

  useEffect(() => {
    let unmounted = false;
    setLoading(true);
    if (businessId) {
      const payload = {
        query: selectedBusinessData.getDemoBusinessWithDemoUserDetail,
        variables: {
          businessId,
        },
      };
      dispatch(getDemoBusinessWithDemoUserDetail(payload));
    }

    return () => {
      unmounted = true;
      dispatch(resetConvertedBusiness());
    };
  }, [dispatch, routeParams.businessId, businessId]);

  useEffect(() => {
    if (selectedBusinessListDetails || selectedBusinessListDetails === null) {
      setLoading(false);
    } else {
      setLoading(true);
    }
  }, [selectedBusinessListDetails]);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }

      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(interval);
        } else {
          setSeconds(59);
          setMinutes(minutes - 1);
        }
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [minutes, seconds]);

  useEffect(() => {
    if (
      convertedAccountExtendExpireDateStatus &&
      convertedAccountExtendExpireDateStatus.status === 400
    ) {
      enqueueSnackbar(convertedAccountExtendExpireDateStatus.message, {
        variant: 'error',
        autoHideDuration: 3000,
      });
      setRefreshIcon(false);
      closeSnackbar(3000);
      setOpenDialog(false);
    } else if (
      convertedAccountExtendExpireDateStatus &&
      convertedAccountExtendExpireDateStatus.status === 200
    ) {
      enqueueSnackbar(convertedAccountExtendExpireDateStatus.message, {
        variant: 'success',
        autoHideDuration: 3000,
      });
      const payload = {
        query: selectedBusinessData.getDemoBusinessWithDemoUserDetail,
        variables: {
          businessId,
        },
      };
      dispatch(getDemoBusinessWithDemoUserDetail(payload));
      setRefreshIcon(false);
      closeSnackbar(3000);
      setOpenDialog(false);
    }
  }, [
    dispatch,
    convertedAccountExtendExpireDateStatus,
    businessId,
    enqueueSnackbar,
    closeSnackbar,
  ]);

  if (loading) {
    return <FuseLoading />;
  }

  function formateTime(date) {
    const today = date;
    return new Intl.DateTimeFormat('en-US', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
    }).format(today);
  }

  function remainingDay(startDate, endDate) {
    let remainingLabel;
    const oneDay = 24 * 60 * 60 * 1000;
    const remainingDayCount = Math.ceil((new Date(endDate) - new Date()) / oneDay);
    if (remainingDayCount > 0) {
      remainingLabel = remainingDayCount;
    } else {
      remainingLabel = 'Trial Expire';
    }
    return remainingLabel;
  }

  function onSubmitButton() {
    setRefreshIcon(true);
    const payload = {
      query: selectedBusinessData.convertedAccountExtendExpireDate,
      variables: { extendDate: numberOfDay, userId: extendUserId },
    };
    dispatch(convertedAccountExtendExpireDate(payload));
    setNumberOfDay(0);
  }

  return (
    <div className="p-20 lg:p-24">
      <div className="flex flex-1 w-full items-center justify-between mb-16">
        <div className="flex items-center">
          <div>
            <Toolbar className="px-0 font-semibold min-h-fit">
              <Icon
                className="text-20 cursor-pointer"
                onClick={() => {
                  history.go(-1);
                  setBusinessId();
                }}
              >
                west
              </Icon>
              <Typography
                variant="subtitle1"
                color="inherit"
                className="flex-1 px-12 font-bold text-18"
              >
                {t('business.convertedBusinessDetail.title')}
              </Typography>
            </Toolbar>
          </div>
        </div>
      </div>
      <div>
        <Typography
          component={motion.span}
          initial={{ x: -20 }}
          animate={{ x: 0, transition: { delay: 0.2 } }}
          delay={300}
          className="sm:flex text-16 md:text-28 font-bold md:mb-36 mb-20 block"
        >
          {t('common.viewDetails')}
        </Typography>
      </div>
      <div className="bg-white rounded-md relative shadow">
        <Tabs
          value={tabValue}
          className="bg-grey-50 rounded-tl-md rounded-tr-md overflow-scroll"
          sx={{
            '& .MuiTabs-scroller': {
              overflow: 'scroll !important',
            },
          }}
          onChange={handleTabChange}
          textColor="secondary"
          indicatorColor="secondary"
          aria-label="secondary tabs example"
        >
          <Tab
            className="md:text-18 text-14 font-semibold px-5 md:px-16"
            value={0}
            label={t('business.convertedBusinessDetail.titleTab')}
          />
          <Tab
            className="md:text-18 text-14 font-semibold px-5 md:px-16"
            value={1}
            label={t('business.selectedBusinessDetail.labels.qrCode')}
          />
        </Tabs>
        <div className="p-20 lg:p-24">
          <div>
            <div>
              {selectedBusinessListDetails?.length > 0 ? (
                <>
                  {selectedBusinessListDetails &&
                    selectedBusinessListDetails?.length > 0 &&
                    selectedBusinessListDetails?.map((row, index) => {
                      const startDate = formatDateWithTimeZone(
                        formateTime(row?.users?.user_package_subscription[0]?.startDate)
                      );
                      const expireDate = formatDateWithTimeZone(
                        formateTime(row?.users?.user_package_subscription[0]?.expireDate)
                      );
                      const daysDifference = expireDate.diff(startDate, 'days');
                      return (
                        <div key={row.id}>
                          <div className={tabValue !== 0 ? 'hidden' : ''}>
                            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 gap-20 lg:gap-40 mb-40">
                              <div className="bg-grey-100 p-20 rounded-md">
                                <Typography className="text-18 font-semibold border-b-2 border-dotted border-grey-400 mb-20 pb-12">
                                  {t('business.selectedBusinessDetail.labels.businessInformation')}
                                </Typography>
                                <div className="mb-20">
                                  <span className="text-16 font-semibold text-black block mb-3">
                                    {t('common.businessName')}
                                  </span>
                                  <h2 className="text-14 text-grey-600 font-medium">{row?.name}</h2>
                                </div>
                                <div className="mb-20">
                                  <div className="inline-block">
                                    <span className="text-16 font-semibold text-black block">
                                      {t('common.address')}
                                    </span>
                                    <Typography className="text-14 text-grey-600 font-medium">
                                      {row?.address ? row?.address : '-'}
                                    </Typography>
                                  </div>
                                </div>
                                <div className="mb-20">
                                  <span className="text-16 font-semibold text-black block">
                                    {t('common.country')}
                                  </span>
                                  <Typography className="text-14 text-grey-600 font-medium">
                                    {row?.countries?.name ? row?.countries?.name : '-'}
                                  </Typography>
                                </div>
                                <div className="mb-20">
                                  <span className="text-16 font-semibold text-black block">
                                    {t('common.state')}
                                  </span>
                                  <Typography className="text-14 text-grey-600 font-medium">
                                    {row?.states?.name ? row?.states?.name : '-'}
                                  </Typography>
                                </div>
                                <div className="mb-20">
                                  <span className="text-16 font-semibold text-black block">
                                    {t('common.city')}
                                  </span>
                                  <Typography className="text-14 text-grey-600 font-medium">
                                    {row?.city ? row?.city : '-'}
                                  </Typography>
                                </div>
                                <div className="mb-20">
                                  <span className="text-16 font-semibold text-black block">
                                    {t('common.zipCode')}
                                  </span>
                                  <Typography className="text-14 text-grey-600 font-medium">
                                    {row?.zipcode ? row?.zipcode : '-'}
                                  </Typography>
                                </div>
                                <div className="mb-20">
                                  <span className="text-16 font-semibold text-black block">
                                    {t('common.phoneNumber')}
                                  </span>
                                  <Typography className="text-14 text-grey-600 font-medium">
                                    {row?.phoneNumber?.phoneNumber ? '+' : ''}
                                    {row?.phoneNumber?.countryCode}{' '}
                                    {row?.phoneNumber?.phoneNumber
                                      ? row?.phoneNumber?.phoneNumber
                                      : '-'}
                                  </Typography>
                                </div>
                                <div className="mb-20">
                                  <span className="text-16 font-semibold text-black block">
                                    {t('business.selectedBusinessDetail.labels.businessEmail')}
                                  </span>
                                  <Typography className="text-14 text-grey-600 font-medium">
                                    {row?.email ? row?.email : '-'}
                                  </Typography>
                                </div>
                                <div className="mb-20">
                                  <span className="text-16 font-semibold text-black block">
                                    {t('business.selectedBusinessDetail.labels.businessType')}
                                  </span>
                                  <Typography className="text-14 text-grey-600 font-medium capitalize">
                                    {row?.business_types?.bType}
                                  </Typography>
                                </div>
                                <div className="mb-20">
                                  <div className="inline-block">
                                    <span className="text-16 font-semibold text-black block">
                                      {t('business.selectedBusinessDetail.labels.reviewSites')}
                                    </span>
                                    {row?.review_platforms?.map((item, i) => {
                                      return (
                                        <div className="pt-8 flex gap-12">
                                          <img
                                            className="w-[25px] object-contain"
                                            src={item?.logo}
                                            alt=""
                                          />
                                          <div>
                                            <Typography className="font-semibold text-15">
                                              {row?.name}
                                            </Typography>
                                            <Typography>{row?.address}</Typography>
                                          </div>
                                        </div>
                                      );
                                    })}
                                  </div>
                                </div>
                              </div>
                              <div className="bg-grey-100 p-20 rounded-md">
                                {' '}
                                <Typography className="text-18 font-semibold border-b-2 border-dotted border-grey-400 mb-20 pb-12">
                                  {t('common.personalInformation')}
                                </Typography>
                                <div className="mb-20">
                                  <span className="text-14 font-semibold text-black block mb-3">
                                    {t('common.name')}
                                  </span>
                                  <h2 className="text-14 text-grey-600 font-medium">
                                    {row?.users?.fullName}
                                  </h2>
                                </div>
                                <div className="mb-20">
                                  <div className="inline-block">
                                    <span className="text-14 font-semibold text-black block">
                                      {t('common.emailAddress')}
                                    </span>
                                    <Typography className="text-14 text-grey-600 font-medium">
                                      {row?.users?.email ? row?.users?.email : '-'}
                                    </Typography>
                                  </div>
                                </div>
                                <div className="mb-20">
                                  <span className="text-14 font-semibold text-black block">
                                    {t('common.phoneNumber')}
                                  </span>
                                  <Typography className="text-14 text-grey-600 font-medium">
                                    {row?.users?.phoneNumber?.phoneNumber ? '+' : ''}
                                    {row?.users?.phoneNumber?.countryCode}{' '}
                                    {row?.users?.phoneNumber?.phoneNumber
                                      ? row?.users?.phoneNumber?.phoneNumber
                                      : '-'}
                                  </Typography>
                                </div>
                                <div className="mb-20 bg-darkgreen-100 border-1 border-darkgreen rounded-md border-dashed p-16">
                                  <div className="flex justify-between items-center">
                                    <div>
                                      <span className="md:text-18 text-16 font-bold text-black block mb-12">
                                        <span className="bg-darkgreen w-24 h-24 rounded-full mr-10 inline-block text-center md:pt-0 pt-2">
                                          <Icon className="text-14 text-white">military_tech</Icon>
                                        </span>
                                        {t('common.currentPlanDetails')}
                                      </span>
                                    </div>
                                    {row?.isFreeTrial && (
                                      <div>
                                        {new Date(
                                          moment(
                                            formateTime(
                                              row?.users?.user_package_subscription[0]?.expireDate
                                            )
                                          )
                                            .tz(timeZone)
                                            .format('MMM DD, YYYY')
                                        ) <
                                        new Date(moment().tz(timeZone).format('MMM DD, YYYY')) ? (
                                          <Typography className="bg-[#FF0000] text-14 text-white px-20 py-4 rounded">
                                            {t('common.freeTrialExpired')}
                                          </Typography>
                                        ) : (
                                          <div>
                                            <Typography className="bg-[#DEB510] text-14 text-white px-28 py-4 rounded">
                                              {t(
                                                'business.selectedBusinessDetail.labels.freeTrial'
                                              )}
                                            </Typography>
                                          </div>
                                        )}
                                      </div>
                                    )}
                                    {!row?.isFreeTrial && (
                                      <div>
                                        {new Date(
                                          moment(
                                            formateTime(
                                              row?.users?.user_package_subscription[0]?.expireDate
                                            )
                                          )
                                            .tz(timeZone)
                                            .format('MMM DD, YYYY')
                                        ) <
                                        new Date(moment().tz(timeZone).format('MMM DD, YYYY')) ? (
                                          <Typography className="bg-[#FF0000] text-14 text-white px-20 py-4 rounded">
                                            {t(
                                              'business.selectedBusinessDetail.labels.planCancelled'
                                            )}
                                          </Typography>
                                        ) : (
                                          <Typography className="bg-[#0B9E00] text-14 text-white px-20 py-4 rounded">
                                            {t('business.selectedBusinessDetail.labels.activePlan')}
                                          </Typography>
                                        )}
                                      </div>
                                    )}
                                  </div>
                                  <div className="flex items-center mb-8">
                                    <span className="md:text-16 font-semibold text-grey-700 md:min-w-224">
                                      {t('business.selectedBusinessDetail.labels.planName')}
                                    </span>
                                    <span className="mr-5">:</span>
                                    <span className="md:text-16 text-14 font-semibold text-black capitalize">
                                      {
                                        row?.users?.user_package_subscription[0]?.packages
                                          ?.package_types?.name
                                      }
                                    </span>
                                  </div>
                                  <div className="flex items-center mb-8">
                                    <span className="md:text-16 font-semibold text-grey-700 md:min-w-224">
                                      {t('business.selectedBusinessDetail.labels.registerDate')}
                                    </span>
                                    <span className="mr-5">:</span>
                                    <span className="md:text-16 text-14 font-semibold text-black capitalize">
                                      {formatDateWithTimeZone(row?.cts, 'MMM DD, YYYY')}
                                    </span>
                                  </div>
                                  {!row?.isFreeTrial && (
                                    <div>
                                      <div className="flex items-center mb-8">
                                        <span className="md:text-16 font-semibold text-grey-700 md:min-w-224">
                                          {t('business.selectedBusinessDetail.labels.startDate')}
                                        </span>
                                        <span className="mr-5">:</span>
                                        <span className="md:text-16 text-14 font-semibold text-black capitalize">
                                          {formatDateWithTimeZone(
                                            formateTime(
                                              row?.users?.user_package_subscription[0]?.startDate
                                            ),
                                            'MMM DD, YYYY'
                                          )}
                                        </span>
                                      </div>
                                    </div>
                                  )}
                                  {!row?.isFreeTrial && (
                                    <div>
                                      <div className="flex items-center mb-8">
                                        {new Date(
                                          moment(
                                            formateTime(
                                              row?.users?.user_package_subscription[0]?.expireDate
                                            )
                                          )
                                            .tz(timeZone)
                                            .format('MMM DD, YYYY')
                                        ) < new Date(moment().format('MMM DD, YYYY')) ? (
                                          <span className="md:text-16 font-semibold text-grey-700 md:min-w-224">
                                            {t(
                                              'business.selectedBusinessDetail.labels.planExpireDate'
                                            )}
                                          </span>
                                        ) : (
                                          <span className="md:text-16 font-semibold text-grey-700 md:min-w-224">
                                            {t(
                                              'business.selectedBusinessDetail.labels.nextRecurringDate'
                                            )}
                                          </span>
                                        )}

                                        <span className="mr-5">:</span>
                                        <span className="md:text-16 text-14 font-semibold text-black capitalize">
                                          {formatDateWithTimeZone(
                                            formateTime(
                                              row?.users?.user_package_subscription[0]?.expireDate
                                            ),
                                            'MMM DD, YYYY'
                                          )}
                                        </span>
                                      </div>
                                    </div>
                                  )}
                                  {row?.isFreeTrial && (
                                    <>
                                      <div className="flex items-center mb-8">
                                        <span className="md:text-16 font-semibold text-grey-700 md:min-w-224">
                                          {t(
                                            'business.selectedBusinessDetail.labels.freeTrailExpiryDate'
                                          )}
                                        </span>
                                        <span className="mr-5">:</span>
                                        <span className="md:text-16 text-14 font-semibold text-black capitalize">
                                          {formatDateWithTimeZone(
                                            formateTime(
                                              row?.users?.user_package_subscription[0]?.expireDate
                                            ),
                                            'MMM DD, YYYY'
                                          )}
                                        </span>
                                      </div>
                                      {daysDifference < 28 && (
                                        <Button
                                          color="secondary"
                                          className="md:text-16 font-semibold disabled:text-black rounded-3xl px-16 pb-5 mt-20"
                                          aria-label={t('common.extendedPlan')}
                                          onClick={() => {
                                            setOpenDialog(true);
                                            setExtendUserId(row?.users?.id);
                                          }}
                                          type="button"
                                          variant="outlined"
                                          size="small"
                                        >
                                          {t('common.extendedPlan')}
                                        </Button>
                                      )}
                                    </>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className={tabValue !== 1 ? 'hidden' : ''}>
                            <QrCode selectedBusinessListDetails={selectedBusinessListDetails[0]} />
                          </div>
                        </div>
                      );
                    })}
                </>
              ) : (
                <motion.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1, transition: { delay: 0.1 } }}
                  className="flex flex-1 items-center justify-center h-full"
                >
                  <Typography color="textSecondary" variant="h5" className="text-16 py-12">
                    {t('business.selectedBusinessDetail.validationMessage.noBusinessDetails')}
                  </Typography>
                </motion.div>
              )}
            </div>
          </div>
        </div>
      </div>
      {openDialog && (
        <Dialog
          classes={{
            paper: 'm-24 p-24 max-w-586 rounded-lg border-t-8 border-solid border-darkgreen',
          }}
          open={openDialog}
          onClose={(event, reason) => {
            if (reason !== 'backdropClick') {
              setOpenDialog(false);
            }
          }}
          fullWidth
          maxWidth="xs"
        >
          <DialogTitle className="p-0 mb-24">
            <Icon
              onClick={() => {
                setOpenDialog(false);
              }}
              className="text-24 absolute top-12 right-12 text-grey-400 font-normal cursor-pointer hover:text-gray-700 focus:text-gray-700"
            >
              close
            </Icon>
            <Typography className="text-24 font-semibold text-black w-full pb-10 border-b-1 border-dashed border-grey">
              {t('business.convertedBusinessDetail.extendPlan.title')}
            </Typography>
          </DialogTitle>
          <form
            noValidate
            // onSubmit={handleSubmit(onSubmitButton)}
            className="flex flex-col md:overflow-hidden"
          >
            <DialogContent className="p-0 mb-24">
              {/* <div className="block">
                <InputLabel htmlFor="reason" className="text-16 font-medium mb-12 text-grey-900">
                  {t('business.convertedBusinessDetail.extendPlan.formField.reason.label')}
                </InputLabel>
                <Controller
                  control={control}
                  name="reason"
                  render={({ field }) => (
                    <TextField
                      {...field}
                      placeholder={t(
                        'business.convertedBusinessDetail.extendPlan.formField.reason.placeHolder'
                      )}
                      size="small"
                      id="reason"
                      multiline
                      rows={4}
                      error={!!errors.reason}
                      helperText={errors?.reason?.message}
                      variant="outlined"
                      required
                      fullWidth
                    />
                  )}
                />
              </div> */}
              <div className="block">
                <InputLabel htmlFor="days" className="text-16 font-medium mb-12 text-grey-900">
                  {t('business.convertedBusinessDetail.extendPlan.formField.extend.label')}
                </InputLabel>
                <Select
                  id="days"
                  name="days"
                  className="w-full"
                  size="small"
                  value={numberOfDay}
                  onChange={(e) => {
                    setNumberOfDay(e.target.value);
                  }}
                >
                  <MenuItem value={0} disabled>
                    {t('business.convertedBusinessDetail.extendPlan.formField.extend.placeHolder')}
                  </MenuItem>

                  <MenuItem value={1}>1</MenuItem>
                  <MenuItem value={2}>2</MenuItem>
                  <MenuItem value={3}>3</MenuItem>
                  <MenuItem value={4}>4</MenuItem>
                  <MenuItem value={5}>5</MenuItem>
                  <MenuItem value={6}>6</MenuItem>
                  <MenuItem value={7}>7</MenuItem>
                </Select>
              </div>
            </DialogContent>
            <DialogActions className="p-0 justify-center">
              <Button
                className="md:text-18 font-semibold disabled:text-black rounded-md"
                variant="contained"
                color="secondary"
                onClick={() => onSubmitButton()}
                disabled={refreshIcon || numberOfDay === 0}
              >
                {t('business.convertedBusinessDetail.extendPlan.title')}
                {refreshIcon && (
                  <CircularProgress size={24} className="text-darkgreen absolute mx-auto" />
                )}
              </Button>

              <Button
                className="md:text-18 font-semibold bg-white text-darkgreen border border-solid border-darkgreen rounded-md hover:text-white hover:bg-darkgreen"
                variant="contained"
                color="primary"
                type="button"
                onClick={() => setOpenDialog(false)}
              >
                {t('common.cancel')}
              </Button>
            </DialogActions>
          </form>
        </Dialog>
      )}
    </div>
  );
}

export default withReducer('convertedBusinessReducer', reducer)(ConvertedBusinessDetails);
