import React, { useEffect, useState } from 'react';
import { Icon, Tooltip, Typography } from '@mui/material';
import { t } from 'i18next';
import { useSelector } from 'react-redux';
import ConversationMatrices from './ConversationMatricex';
import ResponseMatrices from './ResponseMatrices';
import metricsData from '../../../query/metrics';
import { handleApiRequest } from '../../../common/common';
import { selectDashboardData } from '../../../../store/userSlice';

const Matrices = () => {
  const [metricsIconInfo, setMetricsIconInfo] = useState();
  const [insights, setInsights] = useState([]);
  const userCurrentBusinessDetails = useSelector(selectDashboardData);

  useEffect(() => {
    const getMetricsInfoIconData = async () => {
      try {
        const payload = {
          query: metricsData.getMetricsInfoIconData,
        };
        const res = await handleApiRequest(payload);
        if (res?.getMetricsInfoIconData?.status === 200) {
          setMetricsIconInfo(res?.getMetricsInfoIconData?.data?.quick_chat_info ?? null);
        }
      } catch (error) {
        console.error('An error occurred while fetching metrics icon info:', error);
      }
    };

    getMetricsInfoIconData();
  }, []);

  useEffect(() => {
    const sortOrder = [
      'Total Conversations',
      'Success Rate',
      "Today's Conversations",
      'Total Query',
    ];
    const getMetricsInfoIconData = async () => {
      try {
        const payload = {
          query: metricsData.getQuickChatCountAndInsightsMatrix,
          variables: {
            businessId: parseInt(userCurrentBusinessDetails?.id, 10),
            botTypeId: 1,
          },
        };
        const res = await handleApiRequest(payload);
        if (res?.getQuickChatCountAndInsightsMatrix?.status === 200) {
          const data = res.getQuickChatCountAndInsightsMatrix?.data;
          const sortedData = data?.sort((a, b) => {
            return sortOrder.indexOf(a?.name) - sortOrder.indexOf(b?.name);
          });
          const addImages = sortedData?.map((item) => {
            if (item?.name === 'Total Query') {
              return {
                ...item,
                image: '/assets/images/business/query.png',
              };
            }
            if (item?.name === "Today's Conversations") {
              return {
                ...item,
                image: '/assets/images/business/todayConversations.png',
              };
            }
            if (item?.name === 'Success Rate') {
              return {
                ...item,
                count: item?.count > 0 ? `${item?.count}%` : item?.count,
                image: '/assets/images/business/sucessrates.png',
              };
            }
            if (item?.name === 'Total Conversations') {
              return {
                ...item,
                image: '/assets/images/business/conversations.png',
              };
            }

            return item;
          });

          setInsights(addImages);
        }
      } catch (error) {
        console.error('An error occurred while fetching metrics icon info:', error);
      }
    };
    if (userCurrentBusinessDetails?.id) {
      getMetricsInfoIconData();
    }
  }, [userCurrentBusinessDetails?.id]);

  return (
    <div className="lg:p-24 p-16">
      <div className="grid lg:grid-cols-4 sm:grid-cols-2 col-span-1 gap-28">
        {insights?.length > 0 &&
          insights?.map((item, index) => {
            return (
              <div
                className="bg-white rounded-md py-8 px-24 flex items-center  w-full h-84 sm:h-112"
                key={index}
              >
                <div className="flex items-center gap-16">
                  <img className="sm:w-76 sm:h-76 w-40 h-40" src={item?.image} alt="" />
                  <div className="grid">
                    <Typography className="font-bold lg:text-32 text-20">{item?.count}</Typography>
                    <Typography className="font-semibold text-16">{item?.name}</Typography>
                  </div>
                </div>
              </div>
            );
          })}
      </div>
      <div className="md:flex gap-28 justify-between w-full mt-28">
        <div className="w-full bg-white rounded-md p-16 mb-20 lg:mb-0">
          <ConversationMatrices metricsIconInfo={metricsIconInfo?.conversations} />
        </div>
        <div className="w-full bg-white rounded-md p-16">
          <ResponseMatrices metricsIconInfo={metricsIconInfo?.averageResponseTime} />
        </div>
      </div>
    </div>
  );
};

export default Matrices;
