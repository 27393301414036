import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { t } from 'i18next';
import { useState } from 'react';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { tr } from 'date-fns/locale';
import LabelOrBusinessDrawer from './LabelOrBusinessDrawer';
import ReportMatrix from './ReportMatrix';

const ReportCategory = (props) => {
  const { matrixPage, selectCategory, setSelectCategory } = props;
  const [openBusinessDrawer, setOpenBusinessDrawer] = useState(false);
  const [duration, setDuration] = useState('');
  const [groupType, setGroupType] = useState('business');
  const [selectLabelOrBusiness, setSelectLabelOrBusiness] = useState([]);
  const [isMatrix, setIsMatrix] = useState(false);
  const [customDates, setCustomDates] = useState({
    from: null,
    to: null,
  });

  const durationData = [
    { id: 1, value: 'last7days', label: t('reports.last7Days') },
    { id: 1, value: 'lastMonth', label: t('reports.lastMonth') },
    { id: 1, value: 'custom', label: t('reports.custom') },
  ];

  const handleDurationChange = (event) => {
    setDuration(event.target.value);
  };

  const handleDateChange = (key, value) => {
    setCustomDates((prev) => ({ ...prev, [key]: value }));
  };

  const handleBackArrow = () => {
    setSelectCategory('');
  };

  return (
    <div className="bg-gray-A500 min-h-screen">
      {!isMatrix ? (
        <>
          <div className="sm:p-24 p-16 min-h-screen ">
            <Typography className="font-bold text-28">
              <Button
                className="min-w-16 w-16 hover:bg-transparent mr-8"
                onClick={() => handleBackArrow()}
              >
                <ArrowBackIcon />
              </Button>
              {matrixPage ? t('reports.editReport') : selectCategory?.categoryName}
            </Typography>
            <div className="border border-solid bg-white max-w-400 p-20 rounded-md my-20">
              <Typography className="text-16 font-semibold mb-12">
                {t('reports.selectDuration')}
              </Typography>
              <FormControl fullWidth>
                <Select
                  value={duration}
                  onChange={handleDurationChange}
                  size="small"
                  displayEmpty
                  renderValue={(selected) => (
                    <span className="font-medium text-16">
                      {durationData?.find((item) => item?.value === selected)?.label ||
                        t('reports.selectDuration')}
                    </span>
                  )}
                >
                  {durationData?.map((item, index) => {
                    return (
                      <MenuItem value={item.value} key={index}>
                        <FormControlLabel
                          value={item.value}
                          control={<Radio checked={duration === item?.value} />}
                          label={<span className="font-medium text-16">{item?.label}</span>}
                        />
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
              {duration === 'custom' && (
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <Box display="flex" justifyContent="space-between" gap={2} mt={4}>
                    <DatePicker
                      label={
                        <span className="font-semibold text-black text-18">
                          {t('reports.from')}
                        </span>
                      }
                      value={customDates.from}
                      onChange={(date) => handleDateChange('from', date)}
                      renderInput={(params) => <TextField {...params} />}
                      disableFuture
                    />
                    <DatePicker
                      label={
                        <span className="font-semibold text-black text-18">{t('reports.to')}</span>
                      }
                      value={customDates.to}
                      onChange={(date) => handleDateChange('to', date)}
                      renderInput={(params) => <TextField {...params} />}
                      disableFuture
                    />
                  </Box>
                </LocalizationProvider>
              )}
              <Typography className="my-20 text-16 font-semibold">
                {t('reports.selectGroup')}
              </Typography>
              <FormControl>
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  name="radio-buttons-group"
                  row
                  value={groupType}
                  onChange={(e) => {
                    setGroupType(e.target.value);
                    setSelectLabelOrBusiness([]);
                  }}
                >
                  <FormControlLabel
                    value="business"
                    control={<Radio />}
                    label={<span className="font-semibold text-16">{t('reports.business')}</span>}
                  />
                  <FormControlLabel
                    value="label"
                    control={<Radio />}
                    label={<span className="font-semibold text-16">{t('reports.label')}</span>}
                  />
                </RadioGroup>
              </FormControl>
              <Typography className="text-16 font-semibold mt-20">
                {groupType === 'business' ? t('reports.selectBusiness') : t('reports.selectLabel')}
              </Typography>
              <Button
                className="w-full rounded-md border hover:bg-transparent border-solid border-gray flex justify-between text-16 font-medium mt-20"
                variant="outlined"
                onClick={() => setOpenBusinessDrawer(true)}
              >
                {groupType === 'business'
                  ? `${t('reports.selectBusiness')} ${
                      selectLabelOrBusiness?.length > 0 ? `(${selectLabelOrBusiness?.length})` : ''
                    }`
                  : `${t('reports.selectLabel')} ${
                      selectLabelOrBusiness?.length > 0 ? `(${selectLabelOrBusiness?.length})` : ''
                    }`}
                <ArrowForwardIosIcon className="w-20" />
              </Button>
              <Typography className="text-14 pt-6 px-6 font-semibold text-black">
                ({t('reports.requiredMessage')})
              </Typography>
              <div className="mt-24">
                <Button
                  className="w-full rounded-md text-white font-semibold text-16 bg-quick-hub hover:bg-quick-hub"
                  variant="contained"
                  disabled={
                    (selectLabelOrBusiness?.length ?? 0) < 2 ||
                    !duration ||
                    (duration === 'custom' && (!customDates?.from || !customDates?.to))
                  }
                  onClick={() => setIsMatrix(true)}
                >
                  {t('reports.createReport')}
                </Button>
              </div>
            </div>
          </div>
          <LabelOrBusinessDrawer
            openBusinessDrawer={openBusinessDrawer}
            setOpenBusinessDrawer={setOpenBusinessDrawer}
            setSelectLabelOrBusiness={setSelectLabelOrBusiness}
            selectLabelOrBusiness={selectLabelOrBusiness}
            setIsMatrix={setIsMatrix}
            groupType={groupType}
          />
        </>
      ) : (
        <ReportMatrix
          selectLabelOrBusiness={selectLabelOrBusiness}
          groupType={groupType}
          setGroupType={setGroupType}
          setSelectLabelOrBusiness={setSelectLabelOrBusiness}
          setIsMatrix={setIsMatrix}
          selectCategory={selectCategory}
          customDates={customDates}
          setCustomDates={setCustomDates}
          duration={duration}
          setDuration={setDuration}
          durationData={durationData}
          setSelectCategory={setSelectCategory}
        />
      )}
    </div>
  );
};

export default ReportCategory;
