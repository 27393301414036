import { useCallback, useEffect, useMemo, useState } from 'react';
import { t } from 'i18next';
import ConformationDialogBox from 'src/app/main/CommonComponent/ConformationDialogBox';
import {
  Button,
  InputAdornment,
  Paper,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  TextField,
  Checkbox,
  Pagination,
  Icon,
  Typography,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import EditIcon from '@mui/icons-material/Edit';
import CheckIcon from '@mui/icons-material/Check';
import AddIcon from '@mui/icons-material/Add';
import SearchIcon from '@mui/icons-material/Search';
import {
  formatDateWithTimeZone,
  handleApiRequest,
  storeCsvFileDataToServer,
} from 'src/app/main/common/common';
import { useSelector } from 'react-redux';
import { selectDashboardData } from 'app/store/userSlice';
import { useSnackbar } from 'notistack';
import { ReactComponent as CsvIcon } from 'src/app/main/image/csv-icon.svg';
import wabaQuery from '../../../query/waba';
import businessData from '../../../query/business';
import CSVPopUp from './CSVPopUp';
import AddContact from '../../waba/AddContact';

const Contacts = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState('');
  const [contactNumber, setContactNumber] = useState();
  const [csvFileData, setCsvFileData] = useState([]);
  const [selectedTab, setSelectedTab] = useState(0);
  const userCurrentBusinessDetails = useSelector(selectDashboardData);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [csvSelectAll, setCSVSelectAll] = useState(false);
  const [csvSelectedRows, setCSVSelectedRows] = useState([]);
  const [hoveredRow, setHoveredRow] = useState(null);
  const [page, setPage] = useState(1);
  const [isDelPopupOpen, setIsDelPopupOpen] = useState(false);
  const [openCreateDialogue, setOpenCreateDialogue] = useState(false);
  const [openDialogType, setOpenDialogType] = useState('add');
  const [selectedRow, setSelectedRow] = useState(null);
  const [csvUpload, setCSVUpload] = useState(false);
  const [csvContactData, setCsvContactData] = useState([]);
  const [file, setFile] = useState(null);
  const [totalContact, setTotalContact] = useState();
  const [totalCSVContact, setTotalCSVContact] = useState(0);
  const [csvPage, setCSVPage] = useState(1);
  const [multiDelPopupOpen, setMultiDelPopupOpen] = useState(false);

  const fetchData = useCallback(async () => {
    if (selectedTab === 1) {
      try {
        setIsLoading(true);
        const payload = {
          query: businessData.getUserCSV,
          variables: {
            callFrom: 'campaign',
            defaultSave: false,
            userBusinessId: parseInt(userCurrentBusinessDetails?.id, 10),
            searchText: searchTerm,
            pageNumber: csvPage,
            pageSize: 10,
          },
        };
        const result = await handleApiRequest(payload);
        if (result?.getUserCSV?.status === 200) {
          setCsvFileData(result?.getUserCSV?.data);
          setTotalCSVContact(result?.getUserCSV?.totalCount);
        }
      } catch (error) {
        setIsLoading(false);
        console.error('Error fetching data:', error);
      }
    }
  }, [userCurrentBusinessDetails?.id, searchTerm, csvPage, selectedTab]);

  useEffect(() => {
    if (userCurrentBusinessDetails?.id) {
      fetchData();
    }
  }, [userCurrentBusinessDetails?.id, fetchData]);

  const fetchContactData = useCallback(async () => {
    if (selectedTab === 0) {
      try {
        setIsLoading(true);
        const payload = {
          query: businessData.getUserCampaignContact,
          variables: {
            callFrom: 'campaign',
            userBusinessId: userCurrentBusinessDetails?.id,
            defaultSave: false,
            pageNumber: page,
            searchText: searchTerm,
            pageSize: 10,
          },
        };
        const result = await handleApiRequest(payload);
        if (result?.getUserCampaignContact?.status === 200) {
          setCsvContactData(result?.getUserCampaignContact?.data);
          setTotalContact(result?.getUserCampaignContact?.totalCount);
        }
      } catch (error) {
        setIsLoading(false);
        console.error('Error fetching data:', error);
      }
    }
  }, [page, searchTerm, userCurrentBusinessDetails?.id, selectedTab]);

  useEffect(() => {
    if (userCurrentBusinessDetails?.id) {
      fetchContactData();
    }
  }, [userCurrentBusinessDetails?.id, fetchContactData]);

  const deleteCampaignContacts = async () => {
    try {
      setIsLoading(true);
      const payload = {
        query: businessData.deleteCampaignContacts,
        variables: {
          businessId: parseInt(userCurrentBusinessDetails?.id, 10),
          contactIds: selectedRows,
        },
      };
      const result = await handleApiRequest(payload);
      if (result?.deleteCampaignContacts?.status === 200) {
        fetchContactData();
        setSelectedRows([]);
        handleMultipleDelClosePopUp();
        enqueueSnackbar(result?.deleteCampaignContacts?.message, {
          variant: 'success',
          autoHideDuration: 2000,
        });
      } else {
        enqueueSnackbar(result?.deleteCampaignContacts?.message, {
          variant: 'error',
          autoHideDuration: 2000,
        });
      }
    } catch (error) {
      console.error('Error deleting campaign contacts:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const deleteCampaignCSV = async () => {
    try {
      setIsLoading(true);
      const payload = {
        query: businessData.deleteUserCsv,
        variables: {
          businessId: parseInt(userCurrentBusinessDetails?.id, 10),
          csvIds: csvSelectedRows.length === 0 ? [Number(contactNumber?.id)] : csvSelectedRows,
        },
      };
      const result = await handleApiRequest(payload);
      if (result?.deleteUserCsv?.status === 200) {
        fetchData();
        setCSVSelectedRows([]);
        enqueueSnackbar(result?.deleteUserCsv?.message, {
          variant: 'success',
          autoHideDuration: 2000,
        });
        setIsDelPopupOpen(false);
      } else {
        enqueueSnackbar(result?.deleteUserCsv?.message, {
          variant: 'error',
          autoHideDuration: 2000,
        });
      }
    } catch (error) {
      console.error('Error deleting campaign contacts:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleDeleteContact = async () => {
    const payloadData = {
      query: wabaQuery.deleteUserCsvContactOrCampaignContact,
      variables: {
        phoneNumber: `${contactNumber?.phoneNumber}`,
        id: contactNumber?.id,
      },
    };
    if (contactNumber?.dataFrom) {
      payloadData.variables.dataFrom = contactNumber?.dataFrom;
    }
    if (contactNumber?.email) {
      payloadData.variables.email = contactNumber?.email;
    }
    const result = await handleApiRequest(payloadData);
    if (result?.deleteUserCsvContactOrCampaignContact?.status === 200) {
      enqueueSnackbar(result?.deleteUserCsvContactOrCampaignContact?.message, {
        variant: 'success',
        autoHideDuration: 3000,
      });
      setIsDelPopupOpen(false);
      setSelectedRows([]);
      fetchData();
      fetchContactData();
    }
  };

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
    setCSVSelectedRows([]);
    setSelectedRows([]);
    setSearchTerm('');
  };

  const handleCheckboxClick = (id) => {
    setSelectedRows((prevSelectedRows) => {
      let updatedSelectedRows;
      if (prevSelectedRows.includes(id)) {
        updatedSelectedRows = prevSelectedRows.filter((rowId) => rowId !== id);
      } else {
        updatedSelectedRows = [...prevSelectedRows, id];
      }

      if (selectAll && updatedSelectedRows?.length !== csvFileData?.allContact?.length) {
        setSelectAll(false);
      }

      return updatedSelectedRows;
    });
  };

  const handleSelectAll = () => {
    if (selectAll) {
      setSelectedRows([]);
    } else {
      setSelectedRows(csvContactData?.map((row) => row?.id));
    }
    setSelectAll(selectAll);
  };

  useEffect(() => {
    if (csvContactData && selectedRows?.length === csvContactData?.length) {
      setSelectAll(true);
    } else if (selectAll && csvContactData && selectedRows?.length !== csvContactData?.length) {
      setSelectAll(false);
    }
  }, [csvContactData, selectAll, selectedRows?.length]);

  const handleCSVCheckboxClick = (id) => {
    setCSVSelectedRows((prevSelectedRows) => {
      let updatedSelectedRows;
      if (prevSelectedRows?.includes(id)) {
        updatedSelectedRows = prevSelectedRows?.filter((rowId) => rowId !== id);
      } else {
        updatedSelectedRows = [...prevSelectedRows, id];
      }
      if (csvSelectAll && updatedSelectedRows?.length !== csvFileData?.userCSVData?.length) {
        setCSVSelectAll(false);
      }

      return updatedSelectedRows;
    });
  };

  const handleCSVSelectAll = () => {
    if (csvSelectAll) {
      setCSVSelectedRows([]);
    } else {
      setCSVSelectedRows(csvFileData?.userCSVData?.map((row) => row?.id));
    }
    setCSVSelectAll(!csvSelectAll);
  };

  useEffect(() => {
    if (csvFileData?.userCSVData && csvSelectedRows?.length === csvFileData?.userCSVData?.length) {
      setCSVSelectAll(true);
    } else if (
      csvSelectAll &&
      csvFileData?.userCSVData &&
      csvSelectedRows?.length !== csvFileData?.userCSVData?.length
    ) {
      setCSVSelectAll(false);
    }
  }, [csvFileData?.userCSVData, csvSelectAll, csvSelectedRows?.length]);

  const handleDeleteClick = (data) => {
    setContactNumber(data);
    setIsDelPopupOpen(true);
  };

  const handleDelClosePopUp = () => {
    setIsDelPopupOpen(false);
  };

  const handleMultipleDelClosePopUp = () => {
    setMultiDelPopupOpen(false);
  };

  const cancelDialogue = async () => {
    setOpenCreateDialogue(false);
    setSelectedRow({});
    setOpenDialogType('add');
  };

  const handleCloseUploadCSV = () => {
    setCSVUpload(false);
  };

  const handleClearSearch = async () => {
    await setPage(1);
    await setSearchTerm('');
  };

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const handleCsvPageChange = (event, value) => {
    setCSVPage(value);
  };

  const handleDownloadCsvFile = async () => {
    const payload = {
      query: businessData.getCampaignPlatformDemoCsv,
    };
    const result = await handleApiRequest(payload);
    if (result?.getCampaignPlatformDemoCsv?.status === 200) {
      const demoCsv = result?.getCampaignPlatformDemoCsv?.data?.demoCsv;
      const downloadLink = document.createElement('a');
      downloadLink.href = demoCsv;
      downloadLink.target = '_blank';
      downloadLink.download = 'demo.csv';
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    }
  };

  const handleSearchEvent = async (e) => {
    setSearchTerm(e.target.value);
    setPage(1);
  };

  return (
    <div className="lg:p-24 p-16">
      <div className="flex items-center justify-between lg:mb-20 mb-16">
        <h1 className="font-bold md:text-28 text-20 items-center flex">
          {t('quickCampaignsContacts.title')}
        </h1>
        {selectedTab === 0 && csvContactData?.length > 0 && (
          <Button
            variant="contained"
            color="quickCampaign"
            autoFocus
            onClick={() => setOpenCreateDialogue(true)}
            size="medium"
            className="rounded-md gap-10 pr-10 pl-5 py-10 justify-center items-center flex"
          >
            <AddIcon />
            <p className="text-16 font-medium">{t('quickCampaignsContacts.addContact')}</p>
          </Button>
        )}
        {selectedTab === 1 && csvFileData?.userCSVData?.length > 0 && (
          <Button
            variant="contained"
            color="quickCampaign"
            autoFocus
            onClick={() => {
              setCSVUpload(true);
              setFile(null);
            }}
            size="medium"
            className="rounded-md gap-10 pr-10 pl-5 py-10 justify-center items-center flex"
          >
            <AddIcon />
            <p className="text-16 font-medium">{t('quickCampaignsContacts.addCSVFile')}</p>
          </Button>
        )}
      </div>
      <div className="border-solid border-grey sm:flex justify-between items-center border-b-2 overflow-x-auto quick-campaign">
        <Tabs
          value={selectedTab}
          onChange={handleTabChange}
          textColor="secondary"
          indicatorColor="quickCampaign"
          aria-label="secondary tabs example"
        >
          <Tab
            className={`sm:text-18 font-bold mr-20 p-0 ${
              selectedTab === 0 ? 'text-quick-campaign' : 'text-black'
            }`}
            value={0}
            label={t('quickCampaignsContacts.tab1')}
          />
          <Tab
            className={`sm:text-18 font-bold mr-20 p-0 ${
              selectedTab === 1 ? 'text-quick-campaign' : 'text-black'
            }`}
            value={1}
            label={t('quickCampaignsContacts.tab2')}
          />
        </Tabs>
      </div>
      {selectedTab === 0 && (
        <div>
          {(csvContactData?.length > 0 || searchTerm) && (
            <div className="mb-24 sm:flex mt-16 md:mt-0 justify-between">
              <h1 className="text-20 font-semibold items-center flex">
                {t('quickCampaignsContacts.tab1')}
              </h1>
              <div className="flex gap-20 w-full sm:w-1/2 justify-end">
                {selectedRows?.length > 0 && (
                  <div className="gap-12 flex justify-center items-center">
                    <p className="text-16 font-medium flex justify-center items-center">
                      <p>{t('dashboard.dashboardSales.selected')}</p>
                      <p>({selectedRows?.length})</p>
                    </p>
                    <Button
                      variant="contained"
                      autoFocus
                      size="small"
                      className="rounded-md gap-10 justify-center items-center flex p-0 h-40 w-112 bg-quick-campaign hover:bg-quick-campaign"
                      onClick={() => setMultiDelPopupOpen(true)}
                    >
                      <p className="text-16 font-medium text-white">{t('common.delete')}</p>
                      <div className="bg-white rounded-full p-4">
                        <img className="w-14" src="assets/images/quick-hub/delete.png" alt="" />
                      </div>
                    </Button>
                  </div>
                )}
                <div className="w-full justify-end flex quick-campaign">
                  <TextField
                    placeholder="Search Name or Number"
                    variant="outlined"
                    fullWidth
                    className="bg-white w-full sm:w-512"
                    margin="normal"
                    value={searchTerm}
                    onChange={handleSearchEvent}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon />
                        </InputAdornment>
                      ),
                      endAdornment: searchTerm && (
                        <InputAdornment position="end">
                          <Icon
                            color="quickChat"
                            className="cursor-pointer text-20"
                            onClick={handleClearSearch}
                          >
                            close
                          </Icon>
                        </InputAdornment>
                      ),
                    }}
                  />
                </div>
              </div>
            </div>
          )}
          {selectedTab === 0 && csvContactData?.length > 0 ? (
            <>
              <>
                <TableContainer component={Paper} className="rounded-md border-none shadow-0 ">
                  <Table className="">
                    <TableHead>
                      <TableRow>
                        <TableCell className="font-semi-bold p-12 px-24 text-14 md:text-18 border-b-2 border-grey-300">
                          <Checkbox
                            checked={selectAll}
                            onChange={handleSelectAll}
                            icon={
                              <span className="h-24 w-24 border border-gray-400 rounded-md flex items-center justify-center" />
                            }
                            checkedIcon={
                              <span className="h-24 w-24 bg-quick-campaign rounded-md flex items-center justify-center">
                                <CheckIcon className="text-white text-12 md:text-16" />
                              </span>
                            }
                            className="p-0"
                          />
                        </TableCell>
                        <TableCell className="font-semi-bold p-12 px-24 text-14 md:text-18 border-b-2 border-grey-300">
                          {t('common.name')}
                        </TableCell>
                        <TableCell className="font-semi-bold p-12 px-24 text-14 md:text-18 border-b-2 border-grey-300">
                          {t('quickCampaignsContacts.contactNumber')}
                        </TableCell>
                        <TableCell className="font-semi-bold p-12 px-24 text-14 md:text-18 border-b-2 border-grey-300">
                          {t('common.email')}
                        </TableCell>
                        <TableCell className="font-semi-bold p-12 px-24 text-14 md:text-18 border-b-2 border-grey-300">
                          {t('quickCampaignsContacts.importDate')}
                        </TableCell>
                        <TableCell className="font-semi-bold p-12 px-24 text-14 md:text-18 border-b-2 border-grey-300" />
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {csvContactData?.map((data, index) => (
                        <TableRow
                          key={data.id}
                          className="cursor-pointer hover:bg-quick-campaign-100 hover:bg-opacity-30"
                          onMouseEnter={() => setHoveredRow(index)}
                          onMouseLeave={() => setHoveredRow(null)}
                        >
                          <TableCell className="font-medium p-12 px-24 text-12 md:text-16 border-b-2 border-grey-300">
                            <Checkbox
                              checked={selectedRows.includes(data?.id)}
                              onChange={() => handleCheckboxClick(data?.id)}
                              icon={
                                <span className="h-24 w-24 border border-gray-400 rounded-md flex items-center justify-center" />
                              }
                              checkedIcon={
                                <span className="h-24 w-24 bg-quick-campaign rounded-md flex items-center justify-center">
                                  <CheckIcon className="text-white text-12 md:text-16" />
                                </span>
                              }
                              className="p-0"
                            />
                          </TableCell>
                          <TableCell className="font-medium items-center p-12 px-24 text-12 md:text-16 h-64 flex gap-12 border-b-2 border-grey-300">
                            <p className="bg-quick-campaign-100 md:w-32 md:h-32 h-24 w-24 flex text-quick-campaign px-6 rounded-full justify-center items-center text-12 md:text-16 font-semibold">
                              {data?.name?.charAt(0).toUpperCase()}
                            </p>
                            {data?.name}
                          </TableCell>
                          <TableCell className="font-medium p-12 px-24 text-12 md:text-16 border-b-2 border-grey-300">
                            {data?.countryCode} {data?.phoneNumber}
                          </TableCell>
                          <TableCell className="font-medium p-12 px-24 text-12 md:text-16 border-b-2 border-grey-300">
                            {data?.email}
                          </TableCell>
                          <TableCell className="font-medium p-12 px-24 text-12 md:text-16 border-b-2 border-grey-300">
                            {formatDateWithTimeZone(Number(data?.cts) / 1000, 'MMM DD, YYYY')}
                          </TableCell>
                          <TableCell className="pr-24 border-b-2 border-grey-300">
                            {hoveredRow === index ? (
                              <div className="flex gap-16 justify-end px-12">
                                <button
                                  type="button"
                                  className="cursor-pointer bg-quick-campaign-100 hover:bg-black rounded-full p-3 opacity-100 flex justify-center items-center"
                                  onClick={() => {
                                    setOpenCreateDialogue(true);
                                    setSelectedRow(data);
                                    setOpenDialogType('edit');
                                  }}
                                  onKeyDown={(e) => {
                                    if (e.key === 'Enter' || e.key === ' ') {
                                      setOpenCreateDialogue(true);
                                      setSelectedRow(data);
                                      setOpenDialogType('edit');
                                    }
                                  }}
                                  aria-label="Edit"
                                >
                                  <EditIcon className="h-24 w-24 text-grey-600" />
                                </button>
                                <button
                                  type="button"
                                  className="cursor-pointer bg-quick-campaign-100 hover:bg-black rounded-full p-3 opacity-100 flex justify-center items-center"
                                  onClick={() => handleDeleteClick(data)}
                                  onKeyDown={(e) => {
                                    if (e.key === 'Enter' || e.key === ' ') {
                                      handleDeleteClick(data);
                                    }
                                  }}
                                  aria-label="Delete"
                                >
                                  <DeleteIcon className="h-24 w-24 text-grey-600" />
                                </button>
                              </div>
                            ) : (
                              <div className="flex gap-16 pr-24">
                                <div className="p-3">
                                  <div className="bg-transparent rounded-full h-24 w-24" />
                                </div>
                                <div className="p-3">
                                  <div className="bg-transparent rounded-full h-24 w-24" />
                                </div>
                              </div>
                            )}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
                <div className="bg-white px-28 sm:px-52 py-20 sm:py-32 bottom-0 right-0 w-full flex justify-end">
                  <Pagination
                    count={Math.ceil(totalContact / 10)}
                    page={page}
                    onChange={handlePageChange}
                    showFirstButton
                    showLastButton
                  />
                </div>
              </>
              <ConformationDialogBox
                open={isDelPopupOpen}
                onClose={handleDelClosePopUp}
                additionalInfo={
                  selectedRows?.length > 1
                    ? t('quickCampaignsContacts.multipleDeleteContacts')
                    : t('quickCampaignsContacts.deleteContent')
                }
                onDelete={handleDeleteContact}
                confirmationText={t('quickCampaignsContacts.confirmMessage')}
                deleteImage="/assets/images/icon/campaignDeleteIcon.png"
                backgroundColor="quick-campaign"
                textColor="quick-campaign"
                buttonText={t('common.delete')}
              />
            </>
          ) : (
            <>
              {searchTerm ? (
                <div className="md:w-max bg-white py-20 m-auto flex justify-center mt-56">
                  <div className="flex md:max-w-586 justify-center items-center ">
                    <div className="flex flex-col items-center justify-center text-center ">
                      <img
                        src="assets/images/icon/noSearchData.png"
                        alt="connect"
                        className="mb-16"
                      />
                      <h1 className="text-24 font-bold mb-16">
                        {t('quickCampaignsContacts.noSearchData')}sdfsdf
                      </h1>
                      <p className="text-gray-600 font-medium text-20 mb-16">
                        {t('quickCampaignsContacts.noSearchDataContent')}
                      </p>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="flex justify-center items-center pt-112">
                  <div className="text-center">
                    <img className="m-auto" src="assets/images/icon/noContactCampaign.png" alt="" />
                    <Typography className="font-600 py-14 text-24">
                      {t('quickCampaignsContacts.noContacts')}
                    </Typography>
                    <Typography className="max-w-256 m-auto text-16 text-grey-700">
                      {t('quickCampaignsContacts.noContactContent')}
                    </Typography>
                    <div>
                      <Button
                        variant="contained"
                        color="quickCampaign"
                        autoFocus
                        onClick={() => setOpenCreateDialogue(true)}
                        size="medium"
                        className="rounded-md mt-16 px-60"
                      >
                        <AddIcon />
                        <p className="text-14 font-medium">
                          {t('quickCampaignsContacts.addContact')}
                        </p>
                      </Button>
                    </div>
                  </div>
                </div>
              )}
            </>
          )}
          {openCreateDialogue && (
            <AddContact
              openValue={openCreateDialogue}
              cancelCall={cancelDialogue}
              type={openDialogType}
              popUpMessage={
                openDialogType === 'edit'
                  ? t('waba.broadcast.contactList.editContact')
                  : t('waba.broadcast.contactList.addContact')
              }
              popUpButtonName={
                openDialogType === 'edit'
                  ? t('waba.broadcast.contactList.editContact')
                  : t('waba.broadcast.contactList.addContact')
              }
              selectedRow={selectedRow}
              initialValues={{
                firstName: selectedRow?.firstName || selectedRow?.name?.split(' ')?.[0],
                lastName: selectedRow?.lastName || selectedRow?.name?.split(' ')?.[1],
                phone_number:
                  selectedRow && `${selectedRow?.countryCode} ${selectedRow?.phoneNumber}`,
                mail: selectedRow?.email ? selectedRow?.email : '',
              }}
              borderColor="quick-campaign"
              textColor="quick-campaign"
              callFrom="campaign"
              getData={fetchData}
              fetchContactData={fetchContactData}
              csvContactData={csvContactData}
            />
          )}
        </div>
      )}

      {selectedTab === 1 && (
        <div>
          {(csvFileData?.userCSVData?.length > 0 || searchTerm) && (
            <div className="mb-24 sm:flex items-center mt-16 md:mt-0 justify-between">
              <h1 className="text-20 font-semibold items-center flex">
                {t('quickCampaignsContacts.tab2')}
              </h1>
              <div className="flex gap-20 w-full sm:w-1/2">
                {csvSelectedRows?.length > 0 && (
                  <div className="gap-12 flex justify-center items-center">
                    <p className="text-16 font-medium flex justify-center items-center gap-3 ">
                      <p>{t('dashboard.dashboardSales.selected')}</p>
                      <p>({csvSelectedRows?.length})</p>
                    </p>
                    <Button
                      variant="contained"
                      autoFocus
                      size="small"
                      className="rounded-md gap-10 justify-center items-center flex p-0 h-40 w-112 bg-transparent"
                      onClick={() => setIsDelPopupOpen(true)}
                    >
                      <p className="text-16 font-medium text-quick-campaign">
                        {t('common.delete')}
                      </p>
                      <DeleteIcon className=" bg-white rounded-full" />
                    </Button>
                  </div>
                )}
                <div className="w-full  justify-end flex quick-campaign">
                  <TextField
                    placeholder="Search name or number"
                    variant="outlined"
                    fullWidth
                    className="bg-white w-full sm:w-512"
                    margin="normal"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon />
                        </InputAdornment>
                      ),
                      endAdornment: searchTerm && (
                        <InputAdornment position="end">
                          <Icon
                            color="quickChat"
                            className="cursor-pointer text-20"
                            onClick={handleClearSearch}
                          >
                            close
                          </Icon>
                        </InputAdornment>
                      ),
                    }}
                  />
                </div>
              </div>
            </div>
          )}
          {csvFileData?.userCSVData?.length > 0 ? (
            <>
              <TableContainer component={Paper} className="rounded-md border-none shadow-0">
                <Table>
                  <TableHead className="border-b-2 border-black">
                    <TableRow>
                      <TableCell className="font-semi-bold  p-12  px-24  text-18 border-b-2 border-grey-300 w-80">
                        <Checkbox
                          checked={csvSelectAll}
                          onChange={handleCSVSelectAll}
                          icon={
                            <span className="h-24 w-24 border border-gray-400 rounded-md flex items-center justify-center" />
                          }
                          checkedIcon={
                            <span className="h-24 w-24 bg-quick-campaign rounded-md flex items-center justify-center">
                              <CheckIcon className="text-white text-16" />
                            </span>
                          }
                          className="p-0"
                        />
                      </TableCell>
                      <TableCell className="font-semi-bold  p-12  px-24  text-14 md:text-18 border-b-2 border-grey-300 w-586">
                        {t('common.name')}
                      </TableCell>
                      <TableCell className="font-semi-bold  p-12  px-24  text-14 md:text-18 border-b-2 border-grey-300 w-200">
                        {t('quickCampaignsContacts.totalContactCount')}
                      </TableCell>
                      <TableCell className="font-semi-bold  p-12  px-24  text-14 md:text-18 border-b-2 border-grey-300 w-200">
                        {t('quickCampaignsContacts.importDate')}
                      </TableCell>
                      <TableCell className="font-semi-bold  p-12  px-24  text-14 md:text-18 border-b-2 border-grey-300" />
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {(csvFileData?.allContact?.length > 0
                      ? csvFileData?.allContact
                      : csvFileData?.userCSVData
                    )?.map((data, index) => (
                      <TableRow
                        key={data?.id}
                        className="cursor-pointer hover:bg-quick-campaign-100 hover:bg-opacity-30 border-b-2"
                        onMouseEnter={() => setHoveredRow(index)}
                        onMouseLeave={() => setHoveredRow(null)}
                      >
                        <TableCell className="font-medium p-12 px-24 text-12 md:text-16 border-b-2 border-grey-300">
                          <Checkbox
                            checked={csvSelectedRows.includes(data?.id)}
                            onChange={() => handleCSVCheckboxClick(data?.id)}
                            icon={
                              <span className="h-24 w-24 border border-gray-400 rounded-md flex items-center justify-center" />
                            }
                            checkedIcon={
                              <span className="h-24 w-24 bg-quick-campaign rounded-md flex items-center justify-center">
                                <CheckIcon className="text-white text-12 md:text-16" />
                              </span>
                            }
                            className="p-0"
                          />
                        </TableCell>
                        <TableCell className="font-medium p-12 h-64 items-center px-24 text-12 md:text-16 border-b-2 border-grey-300 flex gap-12">
                          <span className="block text-center">
                            <CsvIcon className="campaign-svg w-20" />
                          </span>
                          <p className="text-12 md:text-16">{data?.fileName}</p>
                        </TableCell>
                        <TableCell className="font-medium p-12 px-24 text-12 md:text-16 border-b-2 border-grey-300">
                          {data?.csvContactCount}
                        </TableCell>
                        <TableCell className="font-medium p-12 px-24 text-12 md:text-16 border-b-2 border-grey-300">
                          {formatDateWithTimeZone(
                            Number(data?.csvImportDate) / 1000,
                            'MMM DD, YYYY'
                          )}
                        </TableCell>
                        <TableCell className="pr-24 border-b-2 border-grey-300">
                          {hoveredRow === index ? (
                            <div className="flex gap-16 justify-end">
                              <button
                                type="button"
                                className="cursor-pointer bg-quick-campaign-100 hover:bg-black p-3 rounded-full opacity-100 flex justify-center items-center"
                                onClick={() => handleDeleteClick(data)}
                                aria-label="Delete"
                              >
                                <DeleteIcon className="h-24 w-24 text-grey-600" />
                              </button>
                            </div>
                          ) : (
                            <div className="flex gap-16">
                              <div className="p-3">
                                <div className="bg-transparent rounded-full h-24 w-24" />
                              </div>
                            </div>
                          )}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <div className="bg-white px-28 sm:px-52 py-20 sm:py-32 bottom-0 right-0 w-full flex justify-end">
                <Pagination
                  count={Math.ceil(totalCSVContact / 10)}
                  page={csvPage}
                  onChange={handleCsvPageChange}
                  showFirstButton
                  showLastButton
                />
              </div>
            </>
          ) : (
            <>
              {searchTerm ? (
                <div className="w-max m-auto bg-white py-20 flex justify-center mt-56">
                  <div className="flex md:max-w-586 justify-center items-center ">
                    <div className="flex flex-col items-center justify-center text-center ">
                      <img
                        src="assets/images/icon/noSearchData.png"
                        alt="connect"
                        className="mb-16"
                      />
                      <h1 className="text-24 font-bold mb-16">
                        {t('quickCampaignsContacts.noSearchData')}
                      </h1>
                      <p className="text-gray-600 font-medium text-20 mb-16">
                        {t('quickCampaignsContacts.noSearchDataContent')}
                      </p>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="flex justify-center items-center pt-112">
                  <div className="text-center">
                    <img className="m-auto" src="assets/images/business/csv.png" alt="" />
                    <Typography className="font-600 py-12 text-24">
                      {t('quickCampaignsContacts.notAddCsv')}
                    </Typography>
                    <Typography className="max-w-512 text-16 m-auto">
                      {t('quickCampaignsContacts.notYetCsvMessage')}
                    </Typography>
                    <Button
                      variant="contained"
                      color="quickCampaign"
                      autoFocus
                      onClick={() => setCSVUpload(true)}
                      size="medium"
                      className="rounded-md mt-16 px-88"
                    >
                      <AddIcon />
                      <p className="text-14 font-medium">
                        {t('quickCampaignsContacts.addCSVFile')}
                      </p>
                    </Button>
                    <div>
                      <Button
                        variant="outlined"
                        color="quickCampaign"
                        className="rounded-md mt-16 px-72"
                        onClick={handleDownloadCsvFile}
                      >
                        {t('quickCampaignsContacts.downloadDemoCSV')}
                      </Button>
                    </div>
                  </div>
                </div>
              )}
            </>
          )}
          <ConformationDialogBox
            open={isDelPopupOpen}
            onClose={handleDelClosePopUp}
            additionalInfo={t('quickCampaignsContacts.deleteContent')}
            onDelete={deleteCampaignCSV}
            confirmationText={t('quickCampaignsContacts.confirmMessage')}
            deleteImage="/assets/images/icon/campaignDeleteIcon.png"
            backgroundColor="quick-campaign"
            textColor="quick-campaign"
            buttonText={t('common.delete')}
          />
        </div>
      )}
      <ConformationDialogBox
        open={multiDelPopupOpen}
        onClose={handleMultipleDelClosePopUp}
        additionalInfo={
          selectedRows?.length > 1
            ? t('quickCampaignsContacts.multipleDeleteContacts')
            : t('quickCampaignsContacts.deleteContent')
        }
        onDelete={deleteCampaignContacts}
        confirmationText={t('quickCampaignsContacts.confirmMessage')}
        deleteImage="/assets/images/icon/campaignDeleteIcon.png"
        backgroundColor="quick-campaign"
        textColor="quick-campaign"
        buttonText={t('common.delete')}
      />
      <CSVPopUp
        open={csvUpload}
        onClose={handleCloseUploadCSV}
        fetchData={fetchData}
        file={file}
        setFile={setFile}
        csvFileData={csvFileData?.userCSVData}
      />
    </div>
  );
};

export default Contacts;
