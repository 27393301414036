import { t } from 'i18next';
import {
  Button,
  Icon,
  MenuItem,
  Select,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableContainer,
  TableSortLabel,
  TablePagination,
  Input,
  Pagination,
} from '@mui/material';
import { useEffect, useState, useRef, useCallback } from 'react';
import moment from 'moment-timezone';
import { useLocation } from 'react-router-dom';
import Dialog from '@mui/material/Dialog';
import { useSelector } from 'react-redux';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import DialogContentText from '@mui/material/DialogContentText';
import { useSnackbar } from 'notistack';
import './Waba.css';
import AddContact from './AddContact';
import wabaQuery from '../../query/waba';
import businessData from '../../query/business';
import {
  formatDateWithTimeZone,
  handleApiRequest,
  storeCsvFileDataToServer,
} from '../../common/common';
import { selectDashboardData } from '../../../store/userSlice';

const WabaContact = () => {
  const location = useLocation();
  const userCurrentBusinessDetails = useSelector(selectDashboardData);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchValue, setSearchValue] = useState('');
  const [ContactList, setContactList] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [totalCount, setTotalCount] = useState();
  const [openDialogType, setOpenDialogType] = useState('add');
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [selectedRowAction, setSelectedRowAction] = useState('action');
  const [selectedRow, setSelectedRow] = useState(null);
  const [openCreateDialogue, setOpenCreateDialogue] = useState(false);
  const fileRef = useRef();
  const userTimezone = moment.tz.guess();

  useEffect(() => {
    if (location.state?.openDialog) {
      setOpenCreateDialogue(true);
      setOpenDialogType('add');
    }
  }, [location.state]);

  const handleContactList = useCallback(async () => {
    if (userCurrentBusinessDetails?.id) {
      try {
        const payload = {
          query: businessData.getUserCampaignContact,
          variables: {
            callFrom: 'whatsapp',
            userBusinessId: userCurrentBusinessDetails?.id,
            defaultSave: false,
            searchText: searchValue,
            pageNumber: page,
          },
        };
        const result = await handleApiRequest(payload);
        if (result?.getUserCampaignContact?.status === 200) {
          setContactList(result?.getUserCampaignContact?.data);
          setTotalCount(result?.getUserCampaignContact?.totalCount);
        }
      } catch (error) {
        console.error('An error occurred:', error);
      }
    }
  }, [searchValue, userCurrentBusinessDetails?.id, page]);

  useEffect(() => {
    handleContactList();
  }, [handleContactList]);

  const cancelDialogue = async () => {
    setOpenCreateDialogue(false);
    setSelectedRow({});
    setOpenDialogType('add');
    handleContactList();
  };

  function handleChangePage(event, value) {
    setPage(value + 1);
  }

  function handleChangeRowsPerPage(event) {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
  }

  const handleSearchEvent = async (event) => {
    setSearchValue(event.target.value);
    setPage(1);
  };

  const handleClearSearch = async () => {
    await setPage(1);
    await setSearchValue('');
  };

  const handleDownloadCsvFile = async () => {
    const payload = {
      query: businessData.getCampaignPlatformDemoCsv,
    };
    const result = await handleApiRequest(payload);
    if (result?.getCampaignPlatformDemoCsv?.status === 200) {
      const demoCsv = result?.getCampaignPlatformDemoCsv?.data?.demoCsv;
      const downloadLink = document.createElement('a');
      downloadLink.href = demoCsv;
      downloadLink.target = '_blank';
      downloadLink.download = 'demo.csv';
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    }
  };

  function EnhancedTableHead() {
    return (
      <TableHead className="bg-grey-50 rounded-md hidden md:table-header-group">
        <TableRow>
          <TableCell className="text-16 font-medium" align="left">
            <TableSortLabel hideSortIcon>
              {t('waba.broadcast.contactList.table.srNo')}
            </TableSortLabel>
          </TableCell>
          <TableCell className="text-16 font-medium" align="left">
            <TableSortLabel hideSortIcon>
              {t('waba.broadcast.contactList.table.name')}
            </TableSortLabel>
          </TableCell>
          <TableCell className="text-16 font-medium" align="left">
            <TableSortLabel hideSortIcon>{t('common.phone')}</TableSortLabel>
          </TableCell>
          <TableCell className="text-16 font-medium" align="left">
            <TableSortLabel hideSortIcon>
              {t('waba.broadcast.contactList.table.createdDate')}
            </TableSortLabel>
          </TableCell>
          <TableCell className="text-16 font-medium rounded-tr-md w-192">
            <TableSortLabel hideSortIcon>{t('common.action')}</TableSortLabel>
          </TableCell>
        </TableRow>
      </TableHead>
    );
  }

  const handleCancelSubscription = async () => {
    const payloadData = {
      query: wabaQuery.deleteUserCsvContactOrCampaignContact,
      variables: {
        phoneNumber: `${selectedRow?.phoneNumber}`,

        id: selectedRow?.id,
      },
    };
    if (selectedRow?.dataFrom) {
      payloadData.variables.dataFrom = selectedRow?.dataFrom;
    }
    if (selectedRow?.email) {
      payloadData.variables.email = selectedRow?.email;
    }
    const result = await handleApiRequest(payloadData);
    if (result?.deleteUserCsvContactOrCampaignContact?.status === 200) {
      enqueueSnackbar(result?.deleteUserCsvContactOrCampaignContact?.message, {
        variant: 'success',
        autoHideDuration: 3000,
      });
      setOpenDialog(false);
      setSelectedRow(null);
      handleContactList();
    }
  };

  function handleActionDropdownChange(event) {
    setSelectedRowAction(event.target.value);
  }

  const handleWabaFileInputChange = async (e) => {
    const selectedFile = e?.target?.files[0];
    if (selectedFile?.type === 'text/csv' || selectedFile?.name?.endsWith('.csv')) {
      const payload = {
        query: businessData.saveUserCSV,
        variables: {
          file: selectedFile,
          variables: {
            callFrom: 'whatsapp',
          },
          defaultSave: true,
          userBusinessId: parseInt(userCurrentBusinessDetails?.id, 10),
        },
      };
      const result = await storeCsvFileDataToServer(payload);
      if (result?.saveUserCSV?.status === 200) {
        e.target.value = '';
        handleContactList();
        enqueueSnackbar(result?.saveUserCSV?.message, {
          variant: 'success',
          autoHideDuration: 3000,
        });
      } else {
        enqueueSnackbar(result?.saveUserCSV?.message, {
          variant: 'error',
          autoHideDuration: 3000,
        });
      }
    } else {
      enqueueSnackbar(t('sendMessage.onlyCscFileSelect'), {
        variant: 'error',
        autoHideDuration: 3000,
      });
    }
  };

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  return (
    <>
      <div className="lg:p-24 p-16">
        <div className="flex w-full items-center justify-between lg:mb-20 mb-16 ">
          <div className="flex items-center ">
            <Typography
              initial={{ x: -20 }}
              animate={{ x: 0, transition: { delay: 0.2 } }}
              delay={300}
              className="sm:flex md:text-28 text-20 font-bold"
            >
              {t('navigation.wabaContacts')}
            </Typography>
          </div>
        </div>
        <div className="bg-white rounded-md">
          <div className="md:flex items-center justify-between border-b-1 border-solid border-grey-300 py-12 px-14 sm:px-24">
            <div className="flex items-start sm:items-center justify-between sm:flex-row flex-col w-full md:w-3/5">
              <h3 className="text-20 font-semibold">{t('waba.broadcast.contactList.title')}</h3>
              <div className="inline-block w-full sm:w-360  md:my-0 my-12 shadow-none border-1 border-solid border-grey-400 rounded-md relative align-middle">
                <Input
                  placeholder={t('waba.broadcast.contactList.search')}
                  className="text-black text-16 h-40 px-12 w-full sm:w-auto"
                  disableUnderline
                  name="searchText"
                  fullWidth
                  onChange={handleSearchEvent}
                  value={searchValue}
                  inputProps={{
                    'aria-label': 'Search',
                  }}
                />
                <span className="absolute right-8 top-10">
                  {searchValue ? (
                    <Icon
                      color="quickChat"
                      className="cursor-pointer text-20"
                      onClick={() => handleClearSearch()}
                    >
                      close
                    </Icon>
                  ) : (
                    <Icon color="quickChat" className="text-20">
                      search
                    </Icon>
                  )}
                </span>
              </div>
            </div>
            <div className="flex items-center justify-start md:justify-end w-full md:w-2/5 gap-10 flex-col xs:flex-row">
              <label htmlFor="button-file" className="xs:w-auto w-full">
                <input
                  className="hidden"
                  id="button-file"
                  type="file"
                  ref={fileRef}
                  onChange={handleWabaFileInputChange}
                  aria-labelledby="upload-label"
                />
                <Button
                  className="border rounded-md w-full xs:w-auto"
                  variant="outlined"
                  color="quickChat"
                  component="span"
                  id="upload-label"
                >
                  {t('waba.broadcast.newBroadcast.importContact')}
                </Button>
              </label>
              <Button
                onClick={() => setOpenCreateDialogue(true)}
                className="rounded-md md:px-40 text-14 font-medium inline-block align-middle w-full xs:w-auto"
                variant="contained"
                color="quickChat"
              >
                <Icon className="mr-5 align-middle">add</Icon>
                {t('waba.broadcast.contactList.addContact')}
              </Button>
            </div>
          </div>
          <div>
            <div className="p-16 w-full">
              <div
                className={`${
                  ContactList?.length === 0 && 'flex justify-center items-center'
                } h-full`}
              >
                {ContactList && ContactList?.length > 0 ? (
                  <div className="w-full h-full overflow-scroll">
                    <TableContainer className="border border-solid border-grey-300 rounded-md">
                      <Table className="w-full" aria-labelledby="tableTitle" size="medium">
                        <EnhancedTableHead />
                        <TableBody className="block md:table-row-group">
                          {ContactList?.map((row, index) => {
                            return (
                              <TableRow
                                hover
                                className="border border-t-2 border-t-grey-100 block md:table-row"
                                role="checkbox"
                                tabIndex={-1}
                                key={index}
                              >
                                <TableCell
                                  className="text-14 color-black font-semibold block md:table-cell md:w-60 py-5 pt-16 md:py-16"
                                  align="left"
                                >
                                  {(page - 1) * rowsPerPage + index + 1}
                                </TableCell>
                                <TableCell
                                  className="text-14 color-black font-semibold block md:table-cell md:max-w-256 py-5 md:py-16"
                                  align="left"
                                >
                                  {row?.name}
                                </TableCell>
                                <TableCell
                                  className="text-14 color-black font-semibold block md:table-cell md:max-w-136 py-5 md:py-16"
                                  align="left"
                                >
                                  <span className="inline-block align-middle md:hidden mr-10 font-bold text-quick-chat">
                                    {t('users.deactivatedAccount.labels.deactivatedDate')}:
                                  </span>
                                  {row?.phoneNumber
                                    ? `${row?.countryCode} ${row?.phoneNumber}`
                                    : '-'}
                                </TableCell>
                                <TableCell
                                  className="text-14 color-black font-semibold block md:table-cell py-5 pb-16 md:py-16 capitalize"
                                  align="left"
                                >
                                  <span>
                                    {row.cts
                                      ? formatDateWithTimeZone(row.cts / 1000, 'MMM DD, YYYY')
                                      : '-'}
                                  </span>
                                </TableCell>
                                <TableCell
                                  className="text-14 color-black font-semibold block md:table-cell py-5 pb-16 md:py-16"
                                  align="left"
                                >
                                  <Select
                                    className="rounded-md border border-grey-100 font-semibold text-grey-600 h-28 bg-grey-100 text-16 w-96 min-h-0 p-0 leading-4 shadow-none pt-0"
                                    sx={{ '& .MuiFilledInput-input': { pt: '14px' } }}
                                    variant="filled"
                                    value={selectedRow === row ? selectedRowAction : 'action'}
                                    fullWidth
                                    onChange={handleActionDropdownChange}
                                  >
                                    <MenuItem
                                      value="action"
                                      className="capitalize mx-5 rounded-md"
                                      disabled
                                    >
                                      {t('common.action')}{' '}
                                      {/* {refreshIcon && row.id === selectedBusinessId && (
                                        <CircularProgress
                                          size={20}
                                          className="text-darkgreen absolute mx-auto right-5"
                                        />
                                      )} */}
                                    </MenuItem>
                                    <MenuItem
                                      value="view"
                                      className="capitalize hover:bg-quick-chat-100 hover:text-quick-chat mx-5 rounded-md p-6"
                                      onClick={() => {
                                        setOpenCreateDialogue(true);
                                        setSelectedRow(row);
                                        setOpenDialogType('edit');
                                      }}
                                    >
                                      {t('common.edit')}
                                    </MenuItem>
                                    <MenuItem
                                      value="delete"
                                      className="capitalize hover:bg-quick-chat-100 hover:text-quick-chat mx-5 rounded-md p-6"
                                      onClick={() => {
                                        // handleDelete(row.id);
                                        setSelectedRow(row);
                                        setOpenDialog(true);
                                      }}
                                    >
                                      {t('common.delete')}
                                    </MenuItem>
                                  </Select>
                                </TableCell>
                              </TableRow>
                            );
                          })}
                        </TableBody>
                      </Table>
                    </TableContainer>

                    <div className="bg-white px-20 py-20 sm:px-52 sm:py-32 bottom-0 right-0 w-full flex justify-end">
                      <Pagination
                        count={Math.ceil(totalCount / 10)}
                        page={page}
                        onChange={handlePageChange}
                        showFirstButton
                        showLastButton
                      />
                    </div>
                  </div>
                ) : (
                  <>
                    {searchValue ? (
                      <div className="text-center h-full flex items-center">
                        <div className="mx-auto">
                          <img
                            src="/assets/images/business/contact.png"
                            className="img-fluid w-full mx-auto max-w-144"
                            alt="..."
                          />
                          <Typography className="text-24 font-semibold mt-36">
                            {t('waba.broadcast.contactList.noContactWithSearch')}
                          </Typography>
                          <Typography className="text-16 font-medium max-w-640 mt-16 mb-20">
                            {t('waba.broadcast.contactList.noContactDescWithSearch')}
                          </Typography>
                        </div>
                      </div>
                    ) : (
                      <div className="flex justify-center items-center py-112 text-center">
                        <div className="mx-auto">
                          <img
                            src="/assets/images/business/contact.png"
                            className="img-fluid w-full mx-auto max-w-144"
                            alt="..."
                          />
                          <Typography className="text-24 font-semibold mt-36">
                            {t('waba.broadcast.contactList.noContact')}
                          </Typography>
                          <Typography className="text-16 font-medium max-w-640 mt-16 mb-20">
                            {t('waba.broadcast.contactList.noContactDesc')}
                          </Typography>
                          <Button
                            onClick={() => setOpenCreateDialogue(true)}
                            className="rounded-md md:px-40 text-16 font-semibold"
                            variant="contained"
                            color="quickChat"
                          >
                            {t('waba.broadcast.contactList.addContact')}
                          </Button>
                          <div>
                            <Button
                              variant="outlined"
                              color="quickChat"
                              className="rounded-md mt-16 md:px-16"
                              onClick={handleDownloadCsvFile}
                            >
                              {t('quickCampaignsContacts.downloadDemoCSV')}
                            </Button>
                          </div>
                        </div>
                      </div>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
        {openCreateDialogue && (
          <AddContact
            openValue={openCreateDialogue}
            cancelCall={cancelDialogue}
            type={openDialogType}
            popUpMessage={
              openDialogType === 'edit'
                ? t('waba.broadcast.contactList.editContact')
                : t('waba.broadcast.contactList.addContact')
            }
            popUpButtonName={
              openDialogType === 'edit'
                ? t('waba.broadcast.contactList.editContact')
                : t('waba.broadcast.contactList.addContact')
            }
            selectedRow={selectedRow}
            initialValues={{
              firstName: selectedRow?.firstName || selectedRow?.name?.split(' ')?.[0],
              lastName: selectedRow?.lastName || selectedRow?.name?.split(' ')?.[1],
              phone_number:
                selectedRow && `${selectedRow?.countryCode} ${selectedRow?.phoneNumber}`,
              mail: selectedRow?.email ? selectedRow?.email : '',
            }}
            callFrom="whatsapp"
            getData={handleContactList}
          />
        )}
        {openDialog && (
          <>
            <Dialog
              classes={{
                paper: 'm-24 p-28 business-type-dropdown border-t-8 border-solid border-t-red-600',
              }}
              open={openDialog}
              onClose={(event, reason) => {
                if (reason !== 'backdropClick') {
                  setOpenDialog(false);
                }
              }}
              fullWidth
              maxWidth="sm"
            >
              <DialogTitle className="p-0 text-center">
                <img
                  src="/assets/images/business/warning_alert.png"
                  className="img-fluid mx-auto w-136 h-136"
                  alt="..."
                />
                <Typography className="text-20 font-semibold text-black text-center w-full pb-10 mt-28 mb-0">
                  {t('waba.broadcast.contactList.confirmDeletion')}
                </Typography>
                <Icon
                  onClick={() => {
                    setOpenDialog(false);
                  }}
                  className="text-24 absolute top-12 right-12 text-grey-400 font-normal cursor-pointer hover:text-gray-700 focus:text-gray-700"
                >
                  close
                </Icon>
              </DialogTitle>
              <DialogContent className="p-0 mb-28">
                <DialogContentText
                  id="alert-dialog-description"
                  className="text-14 font-medium text-center text-black max-w-sm mx-auto"
                >
                  {t('waba.broadcast.contactList.confirmDeletionDesc')}
                </DialogContentText>
              </DialogContent>
              <DialogActions className="p-0 justify-center">
                <Button
                  variant="outlined"
                  color="quickChat"
                  className="md:text-16 font-medium disabled:text-black rounded-md px-10 xs:px-24 pb-6"
                  aria-label={t('common.cancel')}
                  onClick={() => {
                    setOpenDialog(false);
                  }}
                  type="button"
                  size="small"
                >
                  {t('common.cancel')}
                </Button>
                <Button
                  variant="contained"
                  color="error"
                  className="md:text-16 font-medium disabled:text-black rounded-md px-10 xs:px-24 pb-6"
                  aria-label={t('waba.broadcast.contactList.deleteContact')}
                  onClick={() => handleCancelSubscription()}
                  // disabled={refreshCancelSubscriptionIcon}
                  type="button"
                  size="small"
                >
                  {t('waba.broadcast.contactList.deleteContact')}
                  {/* {refreshCancelSubscriptionIcon && (
                    <CircularProgress size={24} className="text-darkgreen absolute mx-auto" />
                  )} */}
                </Button>
              </DialogActions>
            </Dialog>
          </>
        )}
      </div>
    </>
  );
};

export default WabaContact;
