import { Button, Icon, MenuItem, Paper, Select, TextField, Typography } from '@mui/material';
import { t } from 'i18next';
import { useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import promotionQuery from 'src/app/main/query/promotion';
import { useDispatch } from 'react-redux';
import history from '@history';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { motion } from 'framer-motion';
import { getParamsId } from 'src/app/main/common/common';
import { createRedeemPromotion, getPromotionAndRedeemPreviewImages } from '../store/promotionSlice';

const PromotionInfo = (props) => {
  const settings = {
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    initialSlide: 0,

    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const { selectBusiness } = props;
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const [previewImages, setPreviewImages] = useState([]);
  const [imagePreview, setImagePreview] = useState();
  const [images, setImages] = useState();
  const [redeem, setRedeem] = useState({
    productName: '',
    points: '',
  });

  useEffect(() => {
    const fetchPromotionType = async () => {
      const payload = {
        query: promotionQuery.getPromotionAndRedeemPreviewImages,
        variables: {
          type: 'promotion',
        },
      };
      const result = await dispatch(getPromotionAndRedeemPreviewImages(payload));
      setPreviewImages(result?.payload?.getPromotionAndRedeemPreviewImages?.data);
      setImagePreview(result?.payload?.getPromotionAndRedeemPreviewImages?.data?.[0]?.image);
      setImages(result?.payload?.getPromotionAndRedeemPreviewImages?.data?.[0]?.image);
    };
    fetchPromotionType();
  }, [dispatch]);

  const handleFileChange = async (event) => {
    const file = event?.target?.files[0];
    if (file && file.type !== 'image/jpeg' && file.type !== 'image/png') {
      enqueueSnackbar('Only valid .png, .jpg format files', {
        variant: 'error',
        autoHideDuration: 2000,
      });
      return;
    }
    setImages(file);
    const reader = new FileReader();
    reader.onload = () => {
      setImagePreview(reader.result);
    };
    reader.readAsDataURL(file);
  };

  const onSubmit = async () => {
    if (!redeem?.productName || !redeem?.points) {
      enqueueSnackbar('All fields are required', {
        variant: 'error',
        autoHideDuration: 2000,
      });
    } else {
      let imageData = {};
      if (typeof images === 'object') {
        imageData = {
          image: images,
        };
      }
      if (typeof images === 'string') {
        imageData = {
          imageUrl: images,
        };
      }
      const payload = {
        query: promotionQuery.createRedeemPromotion,
        variables: {
          userBusinessId: parseInt(selectBusiness, 10),
          ...imageData,
          htmlTemplate: encodeURIComponent(`<!DOCTYPE html>
          <html lang="en">
          <head>
              <meta charset="UTF-8">
              <meta name="viewport" content="width=device-width, initial-scale=1.0">
              <link rel="preconnect" href="https://fonts.googleapis.com">
              <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
              <link href="https://fonts.googleapis.com/css2?family=Quicksand:wght@400;500;600&display=swap" rel="stylesheet">
          </head>
          <body style="font-family: 'Quicksand', sans-serif; color: #333; margin: 0; padding: 0;">
          <div style="max-width: 800px; margin: 0 auto; padding: 20px;">
            <div style="padding: 25px;">
                    <div style="border: 1px solid black; padding: 20px; border-radius: 10px; width: 80%;">
                    <div style="display: flex; gap: 20px;">
                    <img style="width: 100px; height: 75px; border: 1px solid; border-radius: 20px;" src="./assets/Rectangle9315.png" alt="" />
                    <div>
                        <p style="margin-top: 0px; margin-bottom: 8px;">Free ${redeem?.productName}</p>
                        <p style="color: black; font-weight: 400; font-size: 15px; margin-top: 0px; margin-bottom: 0px;">Redeem ${redeem?.points} points for a complimentary ${redeem?.productName} <br /> as a loyalty reward.</p>
                    </div>
                        <div style="display: flex; align-items: center; gap: 10px; margin-bottom: 10px;">
                        <button style="padding: 8px 25px 8px 25px; border-radius: 23px; border: 1px solid #0BA861; background-color: white; color: #0BA861;">Get Offer</button>
                    </div>
                </div>
                </div>
            </div>
          </div>
          </body>
          </html>`),
          productName: redeem?.productName,
          points: parseInt(redeem?.points, 10),
        },
      };
      const result = await dispatch(createRedeemPromotion(payload));
      if (result?.payload?.createRedeemPromotion?.status === 200) {
        const id = getParamsId();
        if (id) {
          history.push(`redeem/${id}`);
        } else {
          history.push('redeem');
        }
      }
      if (result?.payload?.createRedeemPromotion?.status === 403) {
        enqueueSnackbar(result?.payload?.createRedeemPromotion.message, {
          variant: 'error',
          autoHideDuration: 3000,
        });
      }
    }
  };

  const handleSelectImage = (value) => {
    setImages(value);
    setImagePreview(value);
  };

  const handleCancel = () => {
    history.push('promotion');
  };
  return (
    <div className="md:grid md:grid-cols-8 gap-16">
      <div className="bg-white col-span-5 rounded-md relative rounded-lg shadow p-20 mt-24">
        <Typography
          initial={{ x: -20 }}
          animate={{ x: 0, transition: { delay: 0.2 } }}
          delay={300}
          className="sm:flex text-16 md:text-24 mb-24 font-medium"
        >
          {t('promotion.promotionInfo')}
        </Typography>
        <form>
          <div>
            <Typography className="pb-8">
              {t('promotion.productName')} <span className=" text-2xl text-red-500">*</span>
            </Typography>
            <TextField
              className="w-full"
              type="text"
              size="small"
              onChange={(e) =>
                setRedeem((prevState) => ({
                  ...prevState,
                  productName: e.target.value,
                }))
              }
            />
          </div>
          <div className="py-8">
            <Typography className="pb-8">
              {t('promotion.points')} <span className=" text-2xl text-red-500">*</span>
            </Typography>
            <TextField
              className="w-full"
              type="number"
              size="small"
              onChange={(e) =>
                setRedeem((prevState) => ({
                  ...prevState,
                  points: e.target.value,
                }))
              }
            />
          </div>
          <div className="text-center sm:flex item-center justify-center gap-16 my-16">
            <Button
              className="bg-white hover:bg-white border border-solid rounded md:px-60 text-darkgreen border-darkgreen"
              onClick={() => handleCancel()}
            >
              {t('common.cancel')}
            </Button>
            <Button
              className="text-white bg-darkgreen md:px-28 text-4 rounded hover:bg-darkgreen ml-8 md:ml-0"
              type="button"
              onClick={() => onSubmit()}
            >
              {t('promotion.createPromotion')}
            </Button>
          </div>
        </form>
      </div>
      <div className="col-span-3 bg-white rounded-md relative rounded-lg shadow p-20 mt-24">
        <div>
          <Typography
            initial={{ x: -20 }}
            animate={{ x: 0, transition: { delay: 0.2 } }}
            delay={300}
            className="sm:flex text-16 md:text-24 mb-24 font-medium"
          >
            {t('promotion.preview')}
          </Typography>
          <div className="flex items-center gap-12 border border-solid border-black rounded-md p-12">
            <div className="w-[15%]">
              <img className="w-[150px] h-[50px] border rounded-xl" src={imagePreview} alt="" />
            </div>
            <div className="w-[55%]">
              <Typography>Free {redeem?.productName || 'Products'}</Typography>
              <Typography>
                Redeem {redeem?.points || '00'} points for a complimentary {redeem?.productName} as
                a loyalty reward
              </Typography>
            </div>
            <div className="w-[30%]">
              <Button className="text-darkgreen text-4 w-full max-w-full min-w-full bg-white hover:bg-white border border-darkgreen border-solid px-20">
                {t('promotion.redeemNow')}
              </Button>
            </div>
          </div>

          <div className="mt-24 slider">
            <Slider {...settings} className="">
              <div style={{ marginLeft: '10px' }}>
                <motion.div className="block rounded-6">
                  <Paper
                    className="relative rounded-8 shadow-none border-1 bg-[#B4D4CF] border-[#B4D4CF] text-center"
                    role="button"
                    onDragOver={(e) => e.preventDefault()}
                  >
                    <span className="block text-center">
                      <img
                        src="/assets/images/admin-icon/upload_image.png"
                        className="mx-auto w-[40px]"
                        alt="..."
                      />
                    </span>
                    <div className="inline-block">
                      <label htmlFor="button-file">
                        <TextField
                          placeholder={t('appPromotionSlider.field.sliderName.message')}
                          size="small"
                          id="button-file"
                          className="hidden"
                          onChange={(event) => {
                            handleFileChange(event);
                          }}
                          type="file"
                          accept="image/*"
                          variant="outlined"
                          required
                          fullWidth
                        />
                        <Button
                          size="small"
                          className="py-0 bg-[#B4D4CF] hover:bg-[#B4D4CF] pb-6 text-darkgreen font-medium w-full"
                          variant="contained"
                          color="secondary"
                          component="span"
                        >
                          {t('promotion.uploadImage')}
                        </Button>
                      </label>
                    </div>
                  </Paper>
                </motion.div>
              </div>
              {previewImages?.length > 0 &&
                previewImages?.map((item, index) => {
                  return (
                    <div key={index}>
                      <Button
                        className="hover:bg-white"
                        onClick={() => handleSelectImage(item.image)}
                      >
                        <img className="px-8" src={item.image} alt="" />
                      </Button>
                    </div>
                  );
                })}
            </Slider>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PromotionInfo;
