import PropTypes from 'prop-types';
import withReducer from 'app/store/withReducer';
import { useEffect, useState, useRef, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Typography from '@mui/material/Typography';
import Input from '@mui/material/Input';
import Paper from '@mui/material/Paper';
import Icon from '@mui/material/Icon';
import Toolbar from '@mui/material/Toolbar';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import DialogContentText from '@mui/material/DialogContentText';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import DeleteIcon from '@mui/icons-material/Delete';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import { motion } from 'framer-motion';
import FuseLoading from '@fuse/core/FuseLoading';
import { useSnackbar } from 'notistack';
import history from '@history';
import CircularProgress from '@mui/material/CircularProgress';
import { useTranslation } from 'react-i18next';
import salesData from '../../query/sales';
import {
  getDeactivateBusiness,
  resetDeactivatedAccount,
  activateBusinessAccountByAdmin,
} from './store/deactivatedAccountSlice';
import reducer from './store';
import { formatDateWithTimeZone } from '../../common/common';

function DeactivatedAccount() {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const deactivatedAccountList = useSelector(
    ({ deactivatedAccountReducer }) =>
      deactivatedAccountReducer?.deactivatedAccount?.getDeactivateBusinessData?.data
        ?.getDeactivateBusiness?.data
  );

  const activateBusinessAccountStatus = useSelector(
    ({ deactivatedAccountReducer }) =>
      deactivatedAccountReducer?.deactivatedAccount?.activateBusinessAccountByAdminData?.data
        ?.activateBusinessAccountByAdmin
  );
  const totalUser = useSelector(
    ({ deactivatedAccountReducer }) =>
      deactivatedAccountReducer?.deactivatedAccount?.getDeactivateBusinessData?.data
        ?.getDeactivateBusiness?.totalCount || ''
  );

  const [loading, setLoading] = useState(true);
  const [loadingPage, setLoadingPage] = useState(false);
  const [searchLoading, setSearchLoading] = useState(false);
  const [userId, setUserId] = useState();
  const [activateLoading, setActivateLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchValue, setSearchValue] = useState('');
  const [selected, setSelected] = useState([]);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [openDialog, setOpenDialog] = useState(false);

  useEffect(() => {
    let unmounted = false;
    const payload = {
      query: salesData.getDeactivateBusiness,
      variables: {
        searchText: '',
        pageNumber: 1,
        pageSize: 10,
      },
    };
    dispatch(getDeactivateBusiness(payload));

    return () => {
      unmounted = true;
      dispatch(resetDeactivatedAccount());
    };
  }, [dispatch]);

  useEffect(() => {
    if (deactivatedAccountList || deactivatedAccountList === null) {
      setLoading(false);
    } else {
      setLoading(true);
    }
  }, [deactivatedAccountList]);

  useEffect(() => {
    if (activateBusinessAccountStatus && activateBusinessAccountStatus.status === 400) {
      enqueueSnackbar(activateBusinessAccountStatus.message, {
        variant: 'error',
        autoHideDuration: 3000,
      });
      setActivateLoading(false);
      setOpenDialog(false);
      closeSnackbar(3000);
    } else if (activateBusinessAccountStatus && activateBusinessAccountStatus.status === 200) {
      enqueueSnackbar(activateBusinessAccountStatus.message, {
        variant: 'success',
        autoHideDuration: 3000,
      });
      setActivateLoading(false);
      setOpenDialog(false);
      closeSnackbar(3000);
    }
  }, [activateBusinessAccountStatus, enqueueSnackbar, closeSnackbar]);

  function commonGetData(searchValues = '') {
    setSearchValue(searchValues);
    const payload = {
      query: salesData.getDeactivateBusiness,
      variables: {
        searchText: searchValues,
        pageNumber: page,
        pageSize: rowsPerPage,
      },
    };
    dispatch(getDeactivateBusiness(payload));
  }

  const clearSearch = async () => {
    await setPage(1);
    await setSearchValue('');
    await commonGetData();
  };

  function handleSearchEvent(event) {
    setSearchLoading(true);
    setPage(1);
    const searchText = event.target.value;
    commonGetData(searchText);
    setTimeout(() => {
      setSearchLoading(false);
    }, 800);
  }

  function handleChangePage(event, value) {
    setPage(value + 1);
    setLoadingPage(true);
    const payload = {
      query: salesData.getDeactivateBusiness,
      variables: {
        searchText: searchValue,
        pageNumber: value + 1,
        pageSize: rowsPerPage,
      },
    };
    dispatch(async (dispatchValue) => {
      await dispatchValue(getDeactivateBusiness(payload));
      setLoadingPage(false);
    });
  }

  function handleChangeRowsPerPage(event) {
    setLoadingPage(true);
    setRowsPerPage(event.target.value);
    setPage(1);
    const payload = {
      query: salesData.getDeactivateBusiness,
      variables: {
        searchText: searchValue,
        pageNumber: 1,
        pageSize: event.target.value,
      },
    };
    dispatch(async (dispatchValue) => {
      await dispatchValue(getDeactivateBusiness(payload));
      setLoadingPage(false);
    });
  }

  function EnhancedTableToolbar(props) {
    const { numSelected } = props;
    if (numSelected > 0) {
      return (
        <Toolbar className="absolute left-0 right-0 -top-80 bg-black text-white z-10 rounded-md">
          {numSelected > 0 ? (
            <Typography
              sx={{ flex: '1 1 100%' }}
              color="inherit"
              variant="subtitle1"
              component="div"
              className="text-white"
            >
              {numSelected} selected
            </Typography>
          ) : (
            ''
          )}

          {numSelected > 0 ? (
            <Tooltip title="Delete">
              <IconButton className="text-white">
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          ) : (
            ''
          )}
        </Toolbar>
      );
    }
  }

  EnhancedTableToolbar.propTypes = {
    numSelected: PropTypes.number.isRequired,
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = deactivatedAccountList.map((n) => n.id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  function activateAccont() {
    setActivateLoading(true);
    const payload = {
      query: salesData.activateBusinessAccountByAdmin,
      variables: { userId },
    };
    dispatch(activateBusinessAccountByAdmin(payload));
    const payloadData = {
      query: salesData.getDeactivateBusiness,
      variables: {
        searchText: '',
        pageNumber: page,
        pageSize: rowsPerPage,
      },
    };
    setTimeout(() => {
      dispatch(getDeactivateBusiness(payloadData));
    }, 1000);
  }

  function redirectBusinessDetailsRoutes(id) {
    return history.push({
      pathname: `/deactive-business-details/${id}`,
    });
  }

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;

  function EnhancedTableHead(props) {
    const { onSelectAllClick, numSelected, rowCount } = props;

    return (
      <TableHead className="bg-grey-50 rounded-md hidden md:table-header-group">
        <TableRow>
          {/* <TableCell padding="checkbox" className="rounded-tl-md">
            <Checkbox
              color="primary"
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={onSelectAllClick}
              inputProps={{
                'aria-label': 'select all User',
              }}
            />
          </TableCell> */}
          <TableCell className="text-16 font-medium" align="left">
            <TableSortLabel hideSortIcon>
              {t('users.deactivatedAccount.labels.srNo')}
            </TableSortLabel>
          </TableCell>
          <TableCell className="text-16 font-medium" align="left">
            <TableSortLabel hideSortIcon>{t('common.businessName')}</TableSortLabel>
          </TableCell>
          <TableCell className="text-16 font-medium" align="left">
            <TableSortLabel hideSortIcon>
              {t('users.deactivatedAccount.labels.deactivatedDate')}
            </TableSortLabel>
          </TableCell>
          <TableCell className="text-16 font-medium" align="left">
            <TableSortLabel hideSortIcon>
              {t('users.deactivatedAccount.labels.activateAccount')}
            </TableSortLabel>
          </TableCell>
          <TableCell className="text-16 font-medium rounded-tr-md w-192">
            <TableSortLabel hideSortIcon>{t('common.action')}</TableSortLabel>
          </TableCell>
        </TableRow>
      </TableHead>
    );
  }

  if (loading) {
    return <FuseLoading />;
  }

  return (
    <div className="p-20 lg:p-24">
      {loadingPage && (
        <div className="loader-container fixed top-0 left-0 w-full h-full flex justify-center items-center">
          <div className="loader h-40 w-40 rounded-full border-4 border-solid border-white border-t-darkgreen" />
        </div>
      )}
      <div className="md:flex flex-1 w-full items-center justify-between md:mb-36 mb-20">
        <div className="flex items-center">
          <Typography
            component={motion.span}
            initial={{ x: -20 }}
            animate={{ x: 0, transition: { delay: 0.2 } }}
            delay={300}
            className="sm:flex text-20 md:text-28 font-bold mb-16 md:mb-0"
          >
            {t('users.deactivatedAccount.title')}
          </Typography>
        </div>
        <div className="flex flex-1 items-center justify-center px-0 md:px-12">
          <Paper
            component={motion.div}
            initial={{ y: -20, opacity: 0 }}
            animate={{ y: 0, opacity: 1, transition: { delay: 0.2 } }}
            className="flex items-center w-full md:max-w-512 px-8 rounded-8 shadow-none"
          >
            <Input
              placeholder={t('business.visitedBusiness.labels.searchBusinessName')}
              className="flex flex-1 mx-8 text-black text-16"
              disableUnderline
              name="searchText"
              fullWidth
              inputProps={{
                'aria-label': 'Search',
              }}
              onChange={handleSearchEvent}
              value={searchValue}
            />
            {searchValue ? (
              <Icon
                color="action"
                className="cursor-pointer"
                onClick={() => {
                  clearSearch();
                }}
              >
                close
              </Icon>
            ) : (
              <Icon color="action">search</Icon>
            )}
          </Paper>
        </div>
        <div className="md:flex md:flex-1" />
      </div>
      <div className="bg-white rounded-md relative shadow">
        {searchLoading ? (
          <div color="textSecondary" className="text-16 py-12 text-center font-semibold">
            {t('business.searchBusiness.labels.loading')}
          </div>
        ) : (
          <>
            {deactivatedAccountList?.length > 0 && (
              <>
                <EnhancedTableToolbar numSelected={selected.length} />
                <TableContainer>
                  <Table className="w-full" aria-labelledby="tableTitle" size="medium">
                    <EnhancedTableHead
                      numSelected={selected.length}
                      onSelectAllClick={handleSelectAllClick}
                      rowCount={deactivatedAccountList.length}
                    />
                    <TableBody className="block md:table-row-group">
                      {deactivatedAccountList &&
                        deactivatedAccountList.length > 0 &&
                        deactivatedAccountList.map((row, index) => {
                          const isItemSelected = isSelected(row.id);
                          const labelId = `enhanced-table-checkbox-${index}`;

                          return (
                            <TableRow
                              hover
                              className="border border-t-2 border-t-grey-100 block md:table-row"
                              role="checkbox"
                              aria-checked={isItemSelected}
                              tabIndex={-1}
                              key={row.id}
                              selected={isItemSelected}
                            >
                              {/* <TableCell
                            padding="checkbox"
                            onClick={(event) => handleClick(event, row.id)}
                            sx={{ cursor: 'pointer' }}
                          >
                            <Checkbox
                              color="primary"
                              checked={isItemSelected}
                              inputProps={{
                                'aria-labelledby': labelId,
                              }}
                            />
                          </TableCell> */}
                              <TableCell
                                className="text-14 color-black font-semibold block md:table-cell md:max-w-96 py-5 pt-16 md:py-16"
                                align="left"
                              >
                                {(page - 1) * rowsPerPage + index + 1}
                              </TableCell>
                              <TableCell
                                className="text-14 color-black font-semibold block md:table-cell md:max-w-256 py-5 md:py-16"
                                align="left"
                              >
                                {row?.name}
                              </TableCell>
                              <TableCell
                                className="text-14 color-black font-semibold block md:table-cell md:max-w-136 py-5 md:py-16"
                                align="left"
                              >
                                <span className="inline-block align-middle md:hidden mr-10 font-bold text-darkgreen">
                                  {t('users.deactivatedAccount.labels.deactivatedDate')}:
                                </span>
                                {row?.users?.uts
                                  ? formatDateWithTimeZone(row?.users?.uts, 'MMM DD, YYYY')
                                  : '-'}
                              </TableCell>

                              <TableCell
                                className="text-14 color-black font-semibold block md:table-cell md:w-200 py-5 md:py-16"
                                align="left"
                              >
                                <Button
                                  variant="contained"
                                  color="secondary"
                                  className="md:text-14 h-28 px-16 bg-green-100 md:w-200 text-green font-semibold rounded hover:text-white disabled:bg-gry-400"
                                  aria-label="view"
                                  onClick={() => {
                                    setOpenDialog(true);
                                    setUserId(row?.userId);
                                  }}
                                  type="button"
                                  size="small"
                                >
                                  {t('users.deactivatedAccount.labels.activateAccount')}
                                </Button>
                              </TableCell>
                              <TableCell
                                className="text-14 color-black font-semibold block md:table-cell py-5 pb-16 md:py-16"
                                align="left"
                              >
                                <Button
                                  variant="contained"
                                  color="secondary"
                                  className="xl:text-14 px-16 bg-green-100 text-green font-semibold rounded hover:text-white disabled:bg-gry-400"
                                  aria-label="view"
                                  onClick={() => redirectBusinessDetailsRoutes(row.id)}
                                  type="button"
                                  size="small"
                                >
                                  {t('common.viewDetails')}
                                </Button>
                              </TableCell>
                            </TableRow>
                          );
                        })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </>
            )}
            {deactivatedAccountList &&
              deactivatedAccountList.length === 0 &&
              searchValue !== '' && (
                <motion.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1, transition: { delay: 0.1 } }}
                  className="flex flex-1 items-center justify-center h-full"
                >
                  <Typography color="textSecondary" variant="h5" className="text-16 py-12">
                    {t('users.deactivatedAccount.labels.noDeactivatedAccount')}
                  </Typography>
                </motion.div>
              )}
          </>
        )}
      </div>
      {!searchLoading &&
        deactivatedAccountList &&
        deactivatedAccountList.length === 0 &&
        searchValue === '' && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1, transition: { delay: 0.1 } }}
            className="flex flex-1 items-center justify-center h-full text-center mx-auto h-fit"
          >
            <div>
              <img
                src="/assets/images/sales-icon/deactivate_account.png"
                className="img-fluid mx-auto max-w-216 pb-40 lg:pt-96"
                alt="..."
              />
              <h5 className="block text-24 font-bold text-black">
                {t('users.deactivatedAccount.labels.notDeactivateAccount')}
              </h5>
              <Typography
                color="textPrimary"
                variant="h5"
                className="text-16 py-12 font-semibold max-w-640"
              >
                {t('users.deactivatedAccount.labels.notDeactivateAccountMessage')}
              </Typography>
            </div>
          </motion.div>
        )}

      {deactivatedAccountList?.length > 0 && (
        <TablePagination
          sx={{
            '.MuiInputBase-root': {
              lineHeight: 'inherit',
              padding: 0,
            },
          }}
          component="div"
          count={totalUser}
          rowsPerPage={rowsPerPage}
          page={page - 1}
          backIconButtonProps={{
            'aria-label': 'Previous Page',
          }}
          nextIconButtonProps={{
            'aria-label': 'Next Page',
          }}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      )}

      {openDialog && (
        <Dialog
          classes={{
            paper: 'm-24 p-28 max-w-400',
          }}
          open={openDialog}
          onClose={(event, reason) => {
            if (reason !== 'backdropClick') {
              setOpenDialog(false);
            }
          }}
          fullWidth
          maxWidth="xs"
        >
          <DialogTitle className="p-0 text-center">
            <Icon
              onClick={() => {
                setOpenDialog(false);
              }}
              className="text-24 absolute top-12 right-12 text-grey-400 font-normal cursor-pointer hover:text-gray-700 focus:text-gray-700"
            >
              cancel
            </Icon>
            <Icon className="mx-auto text-64 text-yellow-700 font-normal">info</Icon>
            <Typography className="text-18 font-bold text-black text-center w-full pb-10">
              {t('users.deactivatedAccount.labels.isActivateAccount')}
            </Typography>
          </DialogTitle>
          <DialogContent className="p-0 mb-24">
            <DialogContentText
              id="alert-dialog-description"
              className="text-16 font-medium text-center text-black max-w-sm"
            >
              {t('users.deactivatedAccount.validationMessages.reallyWantToActivateAccount')}
            </DialogContentText>
          </DialogContent>
          <DialogActions className="p-0 justify-center">
            <Button
              variant="contained"
              color="secondary"
              className="md:text-18 font-semibold disabled:text-black rounded-3xl px-28 pb-6"
              aria-label="deactive account"
              onClick={() => {
                activateAccont();
              }}
              disabled={activateLoading}
              type="button"
              size="small"
            >
              {t('users.deactivatedAccount.buttons.activateAccount')}
              {activateLoading && (
                <CircularProgress size={24} className="text-darkgreen absolute mx-auto" />
              )}
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </div>
  );
}

export default withReducer('deactivatedAccountReducer', reducer)(DeactivatedAccount);
