const getReviewPlatformForPortal = `
query getReviewPlatformForPortal(
  $reviewPlatformId: Int
  $pageNumber: Int
  $pageSize: Int
  $sortBy: String
  $sortOrder: OrderDirection
  $isActive: Boolean
) {
  getReviewPlatformForPortal(
    reviewPlatformId: $reviewPlatformId
    pageNumber: $pageNumber
    pageSize: $pageSize
    sortBy: $sortBy
    sortOrder: $sortOrder
    isActive: $isActive
  ) {
    data {
      id
      countryId
      cts
      isActive
      logo
      name
      url
    }
    totalCount
    message
    status
  }
}

`;

const addReviewPlatform = `
mutation addReviewPlatform($name: String!,  $logo: Upload!, $countryId: [Int!]!, $url: String!) {
   addReviewPlatform(
     name: $name
     logo: $logo
     countryId: $countryId
     url: $url
   ) {
     status
     message
   }
 }
`;

const updateReviewPlatform = `
mutation updateReviewPlatform($reviewPlatformId: Int!, $name: String, $logo: Upload, $countryId: [Int!], $url: String, $isActive: Boolean) {
  updateReviewPlatform(reviewPlatformId: $reviewPlatformId, name: $name, logo: $logo, countryId: $countryId, url: $url, isActive: $isActive) {
    status
    message
  }
}
`;
const enableDisableReviewType = `
mutation ($reviewPlatformId: Int!, $isEnable: Boolean!) {
  enableDisableReviewType(reviewPlatformId: $reviewPlatformId, isEnable: $isEnable) {
      message
      status 
		}
	}
`;

const getAllBusinessReviews = `
query getAllBusinessReviews($placeId: String!, $filterReviewRating: [String!], $platformId: Int!, $pageNumber:Int, $pageSize: Int, $sortBy: String, $sortOrder: OrderDirection, $attributeId: Int, $ratingType: String){
  getAllBusinessReviews(
  placeId: $placeId
  filterReviewRating: $filterReviewRating
  platformId: $platformId
  pageNumber: $pageNumber
  pageSize: $pageSize,
  sortBy:$sortBy,
  sortOrder:$sortOrder,
  attributeId: $attributeId,
  ratingType: $ratingType
) {
  data {
    dateOfExperienced
    id
    googleBusinessReviewId
    title
    review
    reviewCts
    reviewRating
     businessReplies{
      reply_id
      reply_text
      reply_datetime_utc
      business_owner_id
      business_owner_name
      business_owner_image_url
    }
    reviewerName
    responseFromOnwer
    reviewerProfileUrl
    imageLinks
    reviewRedirectUrl
    emotionPair {
      emoji
      emotion
    }
    videoLinks {
      link
      thumb
    }
  }
  status
  message
  totalReviews
}
}
`;

const getReviewFilter = `query {
  getReviewFilter{
    data{
      cts
      id
      uts
      filterReviewRating
      type
      isActive
    }
    status
    message
  
  }
}`;

const defaultReviewPlatform = `mutation($businessId: Int!, $defaultPlatformId: Int!) {
  defaultReviewPlatform(businessId: $businessId, defaultPlatformId: $defaultPlatformId){
    status
    message
  
  }
}`;

const getOnlineBusinessReview = `query getOnlineBusinessReview(
  $businessId: Int!,
  $orderBy: OrderDirection,
  $pageNumber: Int,
  $pageSize: Int,
  $reviewPlatformId: Int!,
  $filterReviewRating: [String!]
  $sortBy: String,
){
getOnlineBusinessReview(
  businessId: $businessId,
  orderBy: $orderBy,
  pageNumber: $pageNumber,
  pageSize: $pageSize,
  reviewPlatformId: $reviewPlatformId,
  filterReviewRating: $filterReviewRating
  sortBy: $sortBy,
){
    status
    message
    totalReview
    data {
      id
      review
      businessId
      rating
      cts
      reviewerName
    }
  }
}`;

const getPackages = `query($packageTypeId:Int,$packageInterval: String,$countryId:Int) {
  getPackages( packageTypeId:$packageTypeId,countryId:$countryId, packageInterval: $packageInterval){
    data{
     featureInsideSection
     amount
    appleProductId
    currency
    expireDate
    googleProductId
    id
    image
    name
    offerAmount
    packageDetails
    packageIndex
    packageInterval
    packageTypeId
    productId
    razorpayPlanId
    startDate
    stripePriceId
    }
    message
    status
  }
}`;
const startFreeTrail = `mutation startFreeTrail($packageIds: [Int!]!, $userBusinessId: Int){
  startFreeTrail(packageIds: $packageIds, userBusinessId: $userBusinessId){
    status
    message
  }
}`;

const getProduct = `query getProduct($businessId: Int!){
  getProduct(businessId: $businessId){
   cts
   data {
   image
   isSubscription
   name
   }
   id
   isFirstBusiness
   message
   status
   uts
  }
}`;

const getBusinessRatingFilterWise = `query($placeId: String, $businessId: Int, $platform: String){
  getBusinessRatingFilterWise(placeId: $placeId, businessId: $businessId, platform: $platform){
    message
    status
    data {
      starRating {
        key
        value
      }
      totalReviews
      totalReviewRatings
      zomatoDinningReviews
      zomatoDinningReviewsRating
      reviewsDistributionJsonV1 {
        key
        value
        delivery 
        dining 
      }
    }
  }
}`;

const reviewPlatformData = {
  getReviewPlatformForPortal,
  addReviewPlatform,
  updateReviewPlatform,
  enableDisableReviewType,
  getAllBusinessReviews,
  getReviewFilter,
  defaultReviewPlatform,
  getOnlineBusinessReview,
  getPackages,
  startFreeTrail,
  getProduct,
  getBusinessRatingFilterWise,
};

export default reviewPlatformData;
