import withReducer from 'app/store/withReducer';
import ReactApexChart from 'react-apexcharts';
import { useEffect, useState, useRef, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Typography from '@mui/material/Typography';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { motion } from 'framer-motion';
import Button from '@mui/material/Button';
import Icon from '@mui/material/Icon';
import Paper from '@mui/material/Paper';
import { selectDashboardData } from 'src/app/store/userSlice';
import history from '@history';
import { handleApiRequest } from '../../common/common';
import agencyData from '../../query/agency';

import reducer from './store';

function Dashboard() {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [agencyDetail, setAgencyDetail] = useState();

  useEffect(() => {
    const fetchDashboard = async () => {
      const payload = {
        query: agencyData.getAgencyDetails,
      };
      const result = await handleApiRequest(payload);
      setAgencyDetail(result?.getAgencyDetails?.data);
    };
    fetchDashboard();
  }, [dispatch]);
  return (
    <div className="p-20 lg:p-24 l-padding">
      <div className="grid xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-2 sm:grid-cols-1 grid-cols-1 gap-28">
        <div className="bg-white shadow md:p-28 p-12 rounded-md">
          <div className="flex justify-between items-center">
            <span className="flex items-center">
              <img
                src="/assets/images/agency/business.png"
                className="img-fluid mx-auto w-80 h-80 mr-20"
                alt="..."
              />
              <span className="block">
                <span className="block sm:text-20 text-16 font-medium">
                  {t('dashboard.dashboardAgency.business')}
                </span>
                <span className="block text-28 font-semibold">{agencyDetail?.businessCount}</span>
              </span>
            </span>
            <Button
              size="small"
              type="button"
              variant="contained"
              color="secondary"
              onClick={() => history.push('/agency/business')}
              className="bg-blue-800 text-white sm:text-15 font-semibold rounded-md max-h-40"
            >
              {t('dashboard.dashboardAgency.viewBusiness')}
            </Button>
          </div>
        </div>
        <div className="bg-white shadow  md:p-28 p-12  rounded-md">
          <div className="flex justify-between items-center">
            <span className="flex items-center">
              <img
                src="/assets/images/agency/client.png"
                className="img-fluid mx-auto w-80 h-80 mr-20"
                alt="..."
              />
              <span className="block">
                <span className="block sm:text-20 text-16 font-medium">
                  {t('dashboard.dashboardAgency.clients')}
                </span>
                <span className="block text-28 font-semibold">{agencyDetail?.clientCount}</span>
              </span>
            </span>
            <Button
              size="small"
              type="button"
              variant="contained"
              color="secondary"
              onClick={() => history.push('/agency/clients')}
              className="bg-orange-900 text-white sm:text-15 font-semibold rounded-md max-h-40"
            >
              {t('dashboard.dashboardAgency.viewClients')}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}
export default withReducer('businessDashboardReducer', reducer)(Dashboard);
