import { Button, Tab, Tabs, Typography } from '@mui/material';
import { t } from 'i18next';
import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import VerifiedIcon from '@mui/icons-material/Verified';
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';
import FuseLoading from '@fuse/core/FuseLoading';
import AddCircleOutlineRoundedIcon from '@mui/icons-material/AddCircleOutlineRounded';
import { selectDashboardData } from '../../../../store/userSlice';
import LogoPhotoVideo from './LogoPhotoVideo';
import BasicBusinessDetails from './BasicBusinessDetails';
import { handleApiRequest } from '../../../common/common';
import BusinessOpeningAndClosingHours from './OpeningAndClosingHours';
import ConnectYourBusiness from '../../businessCompetitor/businessCompetitors/ConnectYourBusiness';
import manageBusinessData from '../../../query/manageBusiness';
import PostSection from '../postUpdates/PostSection';
import WebsiteAppointment from './WebsiteLinkAppointments';
import ServiceSection from './ServicesSection';
import AttributeSection from './AttributesSection';

const PrimaryDetails = () => {
  const [isBusinessConnect, setIsBusinessConnect] = useState(false);
  const [loading, setLoading] = useState(true);
  const userCurrentBusinessDetails = useSelector(selectDashboardData);
  const [googleBusinessData, setGoogleBusinessData] = useState();
  const [tabValue, setTabValue] = useState(1);
  const [linkType, setLinkType] = useState();
  const [openDialog, setOpenDialog] = useState({
    phoneNumberDialog: false,
    statusDialog: false,
    addressLinesDialog: false,
    openingHoursAndHoursDialog: false,
    logoPhotoVideoDialog: false,
    websiteAndAppointmentDialog: false,
    serviceDialog: false,
    attributesDialog: false,
  });
  const { enqueueSnackbar } = useSnackbar();
  const fetchBusinessDetails = useCallback(async () => {
    if (userCurrentBusinessDetails?.id) {
      setLoading(true);
      try {
        const payload = {
          query: manageBusinessData.getUserBusinessesGoogleDetails,
          variables: {
            businessId: parseInt(userCurrentBusinessDetails?.id, 10),
          },
        };
        const result = await handleApiRequest(payload);
        setLoading(false);
        if (result?.getUserBusinessesGoogleDetails?.status === 200) {
          const data = result.getUserBusinessesGoogleDetails?.data;
          const parsedData = JSON.parse(data);
          setGoogleBusinessData(parsedData);
        } else {
          enqueueSnackbar(result?.getUserBusinessesGoogleDetails?.message, {
            variant: 'error',
            autoHideDuration: 3000,
          });
        }
      } catch (error) {
        setLoading(false);
        console.error('Error fetching business details:', error);
      }
    }
  }, [userCurrentBusinessDetails?.id, enqueueSnackbar]);

  useEffect(() => {
    fetchBusinessDetails();
  }, [fetchBusinessDetails]);

  if (loading) {
    return <FuseLoading />;
  }

  return (
    <div>
      {isBusinessConnect ? (
        <div className="bg-white rounded-md px-28 py-8">
          <div className="pt-28">
            <div className="border border-black rounded-md lg:w-1/2">
              <div className="relative">
                {googleBusinessData?.coverImage ? (
                  <img
                    className="w-full rounded-t-md min-h-256 max-h-256"
                    src={googleBusinessData?.coverImage}
                    alt=""
                  />
                ) : (
                  <div className="bg-quick-review-100 min-h-256 max-h-256 text-center flex items-center justify-center">
                    <Button
                      className="font-bold text-16 hover:bg-transparent"
                      onClick={() => {
                        setOpenDialog({
                          ...openDialog,
                          logoPhotoVideoDialog: true,
                        });
                        setTabValue(3);
                      }}
                    >
                      {t('listManagement.addCoverPhoto')}{' '}
                      <AddCircleOutlineRoundedIcon className="ml-4" />
                    </Button>
                  </div>
                )}
                {googleBusinessData?.profileImage ? (
                  <img
                    className="w-96 h-96 rounded-full absolute -bottom-24 left-36"
                    src={googleBusinessData?.profileImage}
                    alt=""
                  />
                ) : (
                  <div className="w-112 h-112 flex items-center border border-solid justify-center rounded-full bg-white absolute -bottom-24 left-36">
                    <div className="bg-gray rounded-full w-76 h-76 hover:bg-transparent flex items-center justify-center">
                      <Button
                        className="font-bold text-14"
                        onClick={() => {
                          setOpenDialog({
                            ...openDialog,
                            logoPhotoVideoDialog: true,
                          });
                          setTabValue(4);
                        }}
                      >
                        {t('listManagement.logo')} <AddCircleOutlineRoundedIcon className="w-20" />
                      </Button>
                    </div>
                  </div>
                )}
              </div>
              <div className="mt-32 pl-20 pr-20 pb-20">
                <Typography className="font-bold md:text-28 text-20">
                  {googleBusinessData?.title}{' '}
                  <VerifiedIcon
                    className={`ml-6 ${googleBusinessData?.isVerifiedOnGoogle ? 'text-blue' : ''}`}
                  />
                </Typography>
                <Typography className="text-black text-16 font-bold">
                  {t('listManagement.category')} :{' '}
                  <span className="text-gray-600">
                    {' '}
                    {googleBusinessData?.categories?.primaryCategory?.displayName}
                  </span>
                </Typography>
                <Typography className="text-16 text-black font-bold py-4">
                  {t('common.status')}:{' '}
                  <span className="text-gray-600">{googleBusinessData?.openInfo?.status}</span>
                </Typography>
                <Typography className="text-gray text-16 font-semibold pb-8">
                  {t('common.address')} : {googleBusinessData?.storefrontAddress?.addressLines?.[0]}{' '}
                  {googleBusinessData?.storefrontAddress?.administrativeArea}{' '}
                  {googleBusinessData?.storefrontAddress?.locality}{' '}
                  {googleBusinessData?.storefrontAddress?.administrativeArea}{' '}
                  {googleBusinessData?.storefrontAddress?.postalCode}
                </Typography>
                <div className="flex flex-col xs:flex-row gap-8  pr-0 xs:pr-40">
                  <Typography className="border w-full border-gray rounded-md p-8 text-gray text-16 font-semibold">
                    {t('listManagement.primaryCode')}: {googleBusinessData?.storeCode}
                  </Typography>
                  <Typography className="border w-full border-gray rounded-md p-8 text-gray text-16 font-semibold">
                    {t('listManagement.accountName')}: -
                  </Typography>
                </div>
              </div>
            </div>
            <div className="pt-16">
              <Typography className="font-bold md:text-28 text-20">
                {t('listManagement.missingInformation')}
              </Typography>
              <div className="pt-16 flex flex-wrap gap-16">
                <Button
                  className="bg-quick-review hover:bg-quick-review disabled:text-white text-white text-16 font-bold rounded-md px-16"
                  disabled={googleBusinessData?.phoneNumbers?.primaryPhone}
                  onClick={() =>
                    setOpenDialog({
                      ...openDialog,
                      phoneNumberDialog: true,
                    })
                  }
                >
                  {t('common.phoneNumber')}{' '}
                  {googleBusinessData?.phoneNumbers?.primaryPhone ? (
                    <DoneIcon className="border-2 border-quick-review-100 ml-8 text-quick-review p-2 bg-quick-review-100 rounded-full" />
                  ) : (
                    <CloseIcon className="border-2 border-red ml-8 p-2 w-20 h-20 bg-red rounded-full" />
                  )}
                </Button>
                <Button
                  className="bg-quick-review hover:bg-quick-review disabled:text-white text-white text-16 font-bold rounded-md px-16"
                  disabled={googleBusinessData?.websiteUri}
                  onClick={() => {
                    setOpenDialog({
                      ...openDialog,
                      websiteAndAppointmentDialog: true,
                    });
                    setLinkType('websiteUri');
                  }}
                >
                  {t('listManagement.website')} {t('listManagement.link')}{' '}
                  {googleBusinessData?.websiteUri ? (
                    <DoneIcon className="border-2 border-quick-review-100 ml-8 text-quick-review p-2 bg-quick-review-100 rounded-full" />
                  ) : (
                    <CloseIcon className="border-2 border-red ml-8 p-2 w-20 h-20 bg-red rounded-full" />
                  )}
                </Button>
                <Button
                  className="bg-quick-review hover:bg-quick-review disabled:text-white text-white text-16 font-bold rounded-md px-16"
                  disabled={googleBusinessData?.appointmentUrl}
                  onClick={() => {
                    setOpenDialog({
                      ...openDialog,
                      websiteAndAppointmentDialog: true,
                    });
                    setLinkType('URL');
                  }}
                >
                  {t('listManagement.appointment')} {t('listManagement.link')}
                  {googleBusinessData?.appointmentUrl ? (
                    <DoneIcon className="border-2 border-quick-review-100 ml-8 text-quick-review p-2 bg-quick-review-100 rounded-full" />
                  ) : (
                    <CloseIcon className="border-2 border-red ml-8 p-2 w-20 h-20 bg-red rounded-full" />
                  )}
                </Button>
                <Button
                  className="bg-quick-review hover:bg-quick-review disabled:text-white text-white text-16 font-bold rounded-md px-16"
                  disabled={googleBusinessData?.regularHours?.periods?.length > 0}
                  onClick={() =>
                    setOpenDialog({
                      ...openDialog,
                      openingHoursAndHoursDialog: true,
                    })
                  }
                >
                  {t('listManagement.openingDate')}{' '}
                  {googleBusinessData?.regularHours?.periods?.length > 0 ? (
                    <DoneIcon className="border-2 border-quick-review-100 ml-8 text-quick-review p-2 bg-quick-review-100 rounded-full" />
                  ) : (
                    <CloseIcon className="border-2 border-red ml-8 p-2 w-20 h-20 bg-red rounded-full" />
                  )}
                </Button>
                <Button
                  className="bg-quick-review hover:bg-quick-review disabled:text-white text-white text-16 font-bold rounded-md px-16"
                  disabled={googleBusinessData?.regularHours?.periods?.length > 0}
                  onClick={() =>
                    setOpenDialog({
                      ...openDialog,
                      openingHoursAndHoursDialog: true,
                    })
                  }
                >
                  {t('listManagement.openingHours')}
                  {googleBusinessData?.regularHours?.periods?.length > 0 ? (
                    <DoneIcon className="border-2 border-quick-review-100 ml-8 text-quick-review p-2 bg-quick-review-100 rounded-full" />
                  ) : (
                    <CloseIcon className="border-2 border-red ml-8 p-2 w-20 h-20 bg-red rounded-full" />
                  )}
                </Button>
                <Button
                  className="bg-quick-review hover:bg-quick-review disabled:text-white text-white text-16 font-bold rounded-md px-16"
                  disabled={googleBusinessData?.coverImage}
                  onClick={() => {
                    setOpenDialog({
                      ...openDialog,
                      logoPhotoVideoDialog: true,
                    });
                    setTabValue(3);
                  }}
                >
                  {t('userProfile.photo')}
                  {googleBusinessData?.coverImage ? (
                    <DoneIcon className="border-2 border-quick-review-100 ml-8 text-quick-review p-2 bg-quick-review-100 rounded-full" />
                  ) : (
                    <CloseIcon className="border-2 border-red ml-8 p-2 w-20 h-20 bg-red rounded-full" />
                  )}
                </Button>
                <Button
                  className="bg-quick-review hover:bg-quick-review text-white disabled:text-white text-16 font-bold rounded-md px-16"
                  disabled={googleBusinessData?.videos?.length > 0}
                  onClick={() => {
                    setOpenDialog({
                      ...openDialog,
                      logoPhotoVideoDialog: true,
                    });
                    setTabValue(1);
                  }}
                >
                  {t('listManagement.video')}
                  {googleBusinessData?.videos?.length > 0 ? (
                    <DoneIcon className="border-2 border-quick-review-100 ml-8 text-quick-review p-2 bg-quick-review-100 rounded-full" />
                  ) : (
                    <CloseIcon className="border-2 border-red ml-8 p-2 w-20 h-20 bg-red rounded-full" />
                  )}
                </Button>
                <Button
                  className="bg-quick-review hover:bg-quick-review disabled:text-white text-white text-16 font-bold rounded-md px-16"
                  disabled={googleBusinessData?.profileImage}
                  onClick={() => {
                    setOpenDialog({
                      ...openDialog,
                      logoPhotoVideoDialog: true,
                    });
                    setTabValue(4);
                  }}
                >
                  {t('listManagement.businessLogo')}
                  {googleBusinessData?.profileImage ? (
                    <DoneIcon className="border-2 border-quick-review-100 ml-8 text-quick-review p-2 bg-quick-review-100 rounded-full" />
                  ) : (
                    <CloseIcon className="border-2 border-red ml-8 p-2 w-20 h-20 bg-red rounded-full" />
                  )}
                </Button>
                <Button
                  className="bg-quick-review hover:bg-quick-review disabled:text-white text-white text-16 font-bold rounded-md px-16"
                  disabled={googleBusinessData?.regularHours?.periods?.length > 0}
                >
                  {t('listManagement.attributes')}
                  {googleBusinessData?.regularHours?.periods?.length > 0 ? (
                    <DoneIcon className="border-2 border-quick-review-100 ml-8 text-quick-review p-2 bg-quick-review-100 rounded-full" />
                  ) : (
                    <CloseIcon className="border-2 border-red ml-8 p-2 w-20 h-20 bg-red rounded-full" />
                  )}
                </Button>
              </div>
              <BasicBusinessDetails
                userCurrentBusinessDetails={userCurrentBusinessDetails}
                googleBusinessData={googleBusinessData}
                setOpenDialog={setOpenDialog}
                openDialog={openDialog?.phoneNumberDialog}
                statusDialog={openDialog?.statusDialog}
                addressDialog={openDialog?.addressLinesDialog}
                fetchBusinessDetails={fetchBusinessDetails}
              />
              <BusinessOpeningAndClosingHours
                userCurrentBusinessDetails={userCurrentBusinessDetails}
                googleBusinessData={googleBusinessData}
                setOpenDialog={setOpenDialog}
                openDialog={openDialog?.openingHoursAndHoursDialog}
                fetchBusinessDetails={fetchBusinessDetails}
              />
              <LogoPhotoVideo
                userCurrentBusinessDetails={userCurrentBusinessDetails}
                googleBusinessData={googleBusinessData}
                setOpenDialog={setOpenDialog}
                openDialog={openDialog?.logoPhotoVideoDialog}
                fetchBusinessDetails={fetchBusinessDetails}
                setTabValue={setTabValue}
                tabValue={tabValue}
              />
              <WebsiteAppointment
                userCurrentBusinessDetails={userCurrentBusinessDetails}
                googleBusinessData={googleBusinessData}
                setOpenDialog={setOpenDialog}
                openDialog={openDialog?.websiteAndAppointmentDialog}
                fetchBusinessDetails={fetchBusinessDetails}
                linkType={linkType}
                setLinkType={setLinkType}
              />
              <ServiceSection
                userCurrentBusinessDetails={userCurrentBusinessDetails}
                googleBusinessData={googleBusinessData}
                setOpenDialog={setOpenDialog}
                openDialog={openDialog?.serviceDialog}
                fetchBusinessDetails={fetchBusinessDetails}
              />
              <AttributeSection
                userCurrentBusinessDetails={userCurrentBusinessDetails}
                googleBusinessData={googleBusinessData}
                setOpenDialog={setOpenDialog}
                openDialog={openDialog?.attributesDialog}
              />
            </div>
          </div>
        </div>
      ) : (
        <ConnectYourBusiness setIsBusinessConnect={setIsBusinessConnect} />
      )}
    </div>
  );
};

export default PrimaryDetails;
