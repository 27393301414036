import './signIn.css';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import * as yup from 'yup';
import { AnimatePresence, motion } from 'framer-motion';
import { useDispatch, useSelector } from 'react-redux';
import _ from '@lodash';
import { useSnackbar } from 'notistack';
import QRCode from 'qrcode.react';
import Divider from '@mui/material/Divider';
import * as React from 'react';
import Paper from '@mui/material/Paper';
import history from '@history';
import { useCallback, useEffect, useState, useMemo } from 'react';
import withReducer from 'app/store/withReducer';
import { useTranslation } from 'react-i18next';
import ReactFlagsSelect from 'react-flags-select';
import { GoogleLogin, GoogleOAuthProvider } from '@react-oauth/google';
import { useParams, Link } from 'react-router-dom';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import Icon from '@mui/material/Icon';
import { publicIpv4 } from 'public-ip';
import AppleLogin from 'react-apple-login';
import jwtDecode from 'jwt-decode';
import { MuiOtpInput } from 'mui-one-time-password-input';
import {
  getCountryData,
  setCountries,
  setOnlineBusinessType,
  setPhysicalBusinessType,
  setReviewPlateForm,
  setUserBusinesses,
} from 'app/store/userSlice';
import userService from 'src/app/services';
import SignInBoxContent from './SignInBoxContent';
import Query from '../query/auth';
import queryData from '../query/common';
import reducer from '../auth/store';
import {
  submitLogin,
  sendOTPOnPhoneNumberForLogin,
  checkEmail,
  checkUserName,
  resetLoginAction,
  googleAuth,
  asPreviouslyRegesterInGoogle,
  asPreviouslyRegesterInAppleThunk,
  appleAuthThunk,
  appleTokenThunk,
} from '../auth/store/loginAuthSlice';
import { getDecryptData, getEncryptedData, fetchCountries } from '../common/common';
import { getCountries, getLocation } from '../sign-up/store/signUpSlice';
import { connectChatSocket, connectSocket } from '../../../socket';
import Assistance from '../business/assistance/Assistance';

/**
 * Form Validation Schema
 */

const defaultValues = {
  email: '',
  // password: '',
};

function SignInPage() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const routeParams = useParams();
  const schema = yup.object().shape({
    // email: yup.string().required(t('signInPage.formField.email.validationMessages.required')),
    // password: yup
    //   .string()
    //   .required(t('signInPage.formField.password.validationMessages.required'))
    //   .min(4, t('signInPage.formField.password.validationMessages.min')),
  });

  const login = useSelector(({ signInReducer }) => signInReducer?.login?.submitLoginData);
  const googleLogin = useSelector(({ signInReducer }) => signInReducer?.login?.googleAuthData);
  const appleLogin = useSelector(({ signInReducer }) => signInReducer?.login?.appleAuthData);

  const phoneOtpStatus = useSelector(
    ({ signInReducer }) =>
      signInReducer?.login?.sendOTPOnPhoneNumberForLoginData?.data?.sendOTPOnPhoneNumberForLogin
  );

  const emailAvailableStatus = useSelector(
    ({ signInReducer }) => signInReducer?.login?.checkEmailData?.data?.checkEmail
  );

  const userAvailableStatus = useSelector(
    ({ signInReducer }) => signInReducer?.login?.checkUserNameData?.data?.checkUserName
  );

  const { control, formState, handleSubmit, setError, setValue, clearErrors } = useForm({
    mode: 'onChange',
    defaultValues,
    resolver: yupResolver(schema),
  });

  const [showForgotPassword, setShowForgotPassword] = useState(false);
  const [googleLoading, setGoogleLoading] = useState(false);
  const [appleLoading, setAppleLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [OTPLoading, setOTPLoading] = useState(false);
  const { isValid, dirtyFields, errors } = formState;
  const [showPassword, setShowPassword] = useState(false);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [userValue, setUserValue] = useState();
  const [isValueChange, setIsValueChange] = useState();
  const [showPhoneDropdown, setShowPhoneDropdown] = useState(false);
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(59);
  const [resendOtpStatus, setResendOtpStatus] = useState(false);
  const [selected, setSelected] = useState('IN');
  const [otp, setOtp] = useState('');
  const [isWhatsapp, setIsWhatsapp] = useState(false);
  const [passwordValue, setPasswordValue] = useState();
  const [nextStep, setNextStep] = useState(false);
  const [countryIsoData, setCountryIsoData] = useState([]);
  const [checkExistPassword, setCheckExistPassword] = useState('');
  const userServiceData = userService.getUserData();
  const [countryList, setCountryList] = useState([]);
  const [assistanceDialog, setAssistanceDialog] = useState(false);
  const [ipBasedData, setIpBasedData] = useState(
    localStorage.getItem('ipData') ? JSON.parse(getDecryptData(localStorage.getItem('ipData'))) : ''
  );
  const countriesData = useSelector(getCountryData);
  const countryDataString = localStorage.getItem('countries');
  useEffect(() => {
    if (countryDataString) {
      const countryData = JSON.parse(countryDataString);

      if (countryData?.length > 0) {
        const isoCodes = countryData.map((country) => country.isoCode);
        setCountryIsoData(isoCodes);
      }
    }
  }, [countryDataString]);

  useEffect(() => {
    const preventBack = () => {
      window.history.pushState(null, '', window.location.href);
    };
    preventBack();
    window.addEventListener('popstate', preventBack);

    return () => {
      window.removeEventListener('popstate', preventBack);
    };
  }, []);

  useEffect(() => {
    setCountryList(countriesData || JSON.parse(countryDataString));
  }, [countriesData, countryDataString]);

  useEffect(() => {
    if (Array.isArray(countryList)) {
      const isoCode = countryList?.map((country) => country.isoCode);
      setCountryIsoData(isoCode);
    }
  }, [countryList]);

  const message = encodeURIComponent('Send this message to sign-in on quick hub dashboard');
  const whatsAppURL = `https://wa.me/${process.env.REACT_APP_WHATSAPP_NUMBER}?text=${message}`;

  const handleWhatsappClick = () => {
    const URL = `https://wa.me/${process.env.REACT_APP_WHATSAPP_NUMBER}?text=${message}`;
    window.open(URL, '_blank');
  };

  const customLabels = useMemo(() => {
    if (!Array.isArray(countryList)) {
      return {};
    }

    return countryList.reduce((acc, country) => {
      acc[country.isoCode] = {
        primary: country.name,
        secondary: `+${country.countryCode}`,
        isoCode: country.isoCode,
      };
      return acc;
    }, {});
  }, [countryList]);

  const loginRedirect = useCallback(
    async (loginData) => {
      if (Object.keys(loginData).length > 0) {
        if (
          loginData?.status === 'fail' ||
          loginData?.status === 'error' ||
          loginData?.status !== 200
        ) {
          enqueueSnackbar(loginData.message, {
            variant: 'error',
            autoHideDuration: 2000,
          });
        } else if (loginData?.status === 200 && loginData?.user) {
          const userData = loginData.user;
          enqueueSnackbar(t('signInPage.messages.successMessage'), {
            variant: 'success',
            autoHideDuration: 2000,
          });
          if (userData?.roles?.type === 'sales_user') {
            history.push('/dashboard');
          } else if (userData?.roles?.type === 'business_user') {
            history.push('/business/dashboard');

            const countries = await fetchCountries();
            dispatch(setCountries(countries));
            localStorage.setItem('countries', JSON.stringify(countries));

            connectSocket();
            connectChatSocket();
            // window.location.href = '/business/dashboard';
          } else if (userData?.roles?.type === 'admin') {
            history.push('/admin/dashboard');
          } else if (userData?.roles?.type === 'demo_user') {
            history.push('/business/dashboard');
          } else if (userData?.roles?.type === 'agent') {
            history.push('/agency/dashboard');
          }
        }
      }
    },
    [enqueueSnackbar, t, dispatch]
  );

  useEffect(() => {
    const token = localStorage.getItem('jwt_access_token');
    if (token) {
      if (userServiceData?.roles?.type === 'sales_user') {
        history.push('/dashboard');
      } else if (userServiceData?.roles?.type === 'business_user') {
        // history.push('/business/dashboard');
      } else if (userServiceData?.roles?.type === 'admin') {
        history.push('/admin/dashboard');
      } else if (userServiceData?.roles?.type === 'demo_user') {
        history.push('/business/dashboard');
      } else if (userServiceData?.roles?.type === 'agent') {
        history.push('/agency/dashboard');
      }
    }
  }, [userServiceData]);

  useEffect(() => {
    if (!ipBasedData) {
      const ipAddress = publicIpv4();
      const ipAddressData = async () => {
        const payload = {
          query: queryData.getLocation,
          variables: {
            data: getEncryptedData(`{"ipAddress": "${await ipAddress}"}`),
          },
        };
        const locationResponse = await dispatch(getLocation(payload));
        if (locationResponse?.payload?.data?.getLocation?.data) {
          const locationData = locationResponse?.payload?.data?.getLocation?.data;
          if (locationData) {
            setIpBasedData(JSON.parse(getDecryptData(locationData)));
            localStorage.setItem('ipData', locationData);
          }
        }
      };
      ipAddressData();
    }
  }, [dispatch, ipBasedData]);

  useEffect(() => {
    if (ipBasedData?.countryCode && customLabels && Object.keys(customLabels).length > 0) {
      const matchingLabel = Object.entries(customLabels).find(
        ([key, value]) => value.secondary === `+${ipBasedData.countryCode}`
      );
      if (matchingLabel) {
        setSelected(matchingLabel[1]?.isoCode);
      }
    }
  }, [ipBasedData, customLabels]);

  useEffect(() => {
    if (login) {
      setLoading(false);
      loginRedirect(login);
    }
  }, [enqueueSnackbar, login, t, loginRedirect]);

  useEffect(() => {
    setLoading(false);
    if (googleLogin) {
      loginRedirect(googleLogin);
    }
  }, [enqueueSnackbar, googleLogin, t, loginRedirect]);

  useEffect(() => {
    return () => {
      /**
       * Reset login action on component unload
       */
      dispatch(resetLoginAction());
    };
  }, [dispatch]);

  const handleForgotPasswordClick = () => {
    setShowForgotPassword(true);
    history.push('forget-password');
  };

  useEffect(() => {
    if (userValue) {
      clearErrors('email');
    } else {
      setNextStep(false);
    }
  }, [userValue, clearErrors]);

  useEffect(() => {
    if (phoneOtpStatus?.status) {
      enqueueSnackbar(phoneOtpStatus?.message, {
        variant: phoneOtpStatus?.status === 200 ? 'success' : 'error',
        autoHideDuration: 3000,
      });
      closeSnackbar(3000);
    }
    if (phoneOtpStatus?.status === 200) {
      setNextStep(true);
    }
  }, [phoneOtpStatus, enqueueSnackbar, closeSnackbar]);

  useEffect(() => {
    if (emailAvailableStatus?.status === 200 && emailAvailableStatus?.available) {
      enqueueSnackbar(
        'This Email Address cannot be found. Please use a different account or sign up for a new account.',
        {
          variant: 'error',
          autoHideDuration: 3000,
        }
      );
      closeSnackbar(3000);
    }
    if (emailAvailableStatus?.status === 202 && !emailAvailableStatus?.available) {
      setNextStep(true);
    }
  }, [emailAvailableStatus, enqueueSnackbar, closeSnackbar]);

  useEffect(() => {
    if (userAvailableStatus?.status === 200 && userAvailableStatus?.available) {
      enqueueSnackbar(
        'This User Name cannot be found. Please use a different account or sign up for a new account.',
        {
          variant: 'error',
          autoHideDuration: 3000,
        }
      );
      closeSnackbar(3000);
    } else if (userAvailableStatus?.status === 400) {
      enqueueSnackbar(userAvailableStatus.message, {
        variant: 'error',
        autoHideDuration: 3000,
      });
      closeSnackbar(3000);
    }
    if (userAvailableStatus?.status === 202 && !userAvailableStatus?.available) {
      setNextStep(true);
    }
  }, [userAvailableStatus, enqueueSnackbar, closeSnackbar]);

  useEffect(() => {
    if (phoneOtpStatus?.status === 200) {
      const interval = setInterval(() => {
        if (seconds > 0) {
          setSeconds(seconds - 1);
        }
        if (seconds === 0) {
          if (minutes === 0) {
            clearInterval(interval);
          } else {
            setSeconds(59);
            setMinutes(minutes - 1);
          }
        }
      }, 1000);
      return () => {
        clearInterval(interval);
      };
    }
    return () => {};
  }, [minutes, seconds, phoneOtpStatus]);

  useEffect(() => {
    setLoading(false);
    if (appleLogin) {
      loginRedirect(appleLogin);
    }
  }, [enqueueSnackbar, appleLogin, t, loginRedirect]);

  useEffect(() => {
    setLoading(false);
    const appleResponse = async (token) => {
      setAppleLoading(true);
      try {
        const appleToken = JSON.stringify({
          accessToken: token,
        });
        const registrationPayload = {
          query: Query.asPreviouslyRegesterInApple,
          variables: { data: getEncryptedData(appleToken) },
        };
        const registrationResult = await dispatch(
          asPreviouslyRegesterInAppleThunk(registrationPayload)
        );

        if (registrationResult.payload?.available === false) {
          const applePayload = {
            query: Query.appleAuthV2,
            variables: { data: getEncryptedData(appleToken) },
          };
          await dispatch(appleAuthThunk(applePayload));
        }
        if (registrationResult.payload?.available) {
          const decodeData = jwtDecode(token);
          await dispatch(appleTokenThunk({ token, decodeData }));
          if (
            localStorage.getItem('userPackageIds') &&
            JSON.parse(localStorage.getItem('userPackageIds'))
          ) {
            history.push('/userInformation', {
              isAppleSignUp: true,
              token,
              decodeData,
              isEmailVerified: true,
              isPhoneNumberVerified: false,
              email: decodeData?.email,
              userPackageIds:
                localStorage.getItem('userPackageIds') &&
                JSON.parse(localStorage.getItem('userPackageIds')),
            });
          } else {
            history.push('/sign-up', {
              isAppleSignUp: true,
              token,
              decodeData,
              isEmailVerified: true,
              isPhoneNumberVerified: false,
              email: decodeData?.email,
              userPackageIds: [],
            });
          }
        }
      } catch (error) {
        enqueueSnackbar(t('signInPage.messages.errorOccurred'), {
          variant: 'error',
          autoHideDuration: 2000,
        });
      } finally {
        setAppleLoading(false);
      }
    };
    if (routeParams?.token) {
      appleResponse(routeParams.token);
    }
  }, [routeParams?.token, enqueueSnackbar, dispatch, t]);

  const getEmailOrPhoneInput = async () => {
    if (isValueChange) {
      if (!userValue) {
        setError('email', {
          type: 'manual',
          message: t('signInPage.formField.email.validationMessages.required'),
        });
        setLoading(false);
        return;
      }
      setOTPLoading(true);
      const findCountryCode = Object.entries(customLabels).find(
        ([key, value]) => value.isoCode === selected
      );
      if (showPhoneDropdown) {
        try {
          const payload = {
            query: Query.sendOTPOnPhoneNumberForLogin,
            variables: {
              countryCode: findCountryCode[1].secondary,
              phoneNumber: parseFloat(userValue),
            },
          };
          await dispatch(sendOTPOnPhoneNumberForLogin(payload));
          setOTPLoading(false);
        } catch (error) {
          console.error('An error occurred:', error);
        }
      } else {
        const email = /\S+@\S+\.\S+/.test(userValue);
        try {
          if (email === true) {
            const payload = {
              query: queryData.checkEmail,
              variables: {
                email: userValue,
              },
            };
            await dispatch(checkEmail(payload));
            setOTPLoading(false);
          } else {
            const payload = {
              query: queryData.checkUserName,
              variables: {
                userName: userValue,
              },
            };
            await dispatch(checkUserName(payload));
            setOTPLoading(false);
          }
        } catch (error) {
          console.error('An error occurred:', error);
        }
      }
    }
  };

  function onChangeCountryDrop(value) {
    setSelected(value);
  }

  async function handleSignIn() {
    const findCountryCode = Object.entries(customLabels).find(
      ([key, value]) => value.isoCode === selected
    );
    let data;
    if (showPhoneDropdown) {
      data = getEncryptedData(
        JSON.stringify({
          countryCode: findCountryCode[1].secondary,
          phoneNumber: parseInt(userValue, 10),
          otp: parseInt(otp, 10),
        })
      );
      const payload = {
        query: Query.authLoginMutation,
        variables: { data },
      };
      await dispatch(submitLogin(payload));
    } else if (userValue && !passwordValue) {
      enqueueSnackbar(t('signInPage.enterYourPassword'), {
        variant: 'error',
        autoHideDuration: 3000,
      });
    } else if (passwordValue === checkExistPassword) {
      enqueueSnackbar(t('signInPage.enterValidPassword'), {
        variant: 'error',
        autoHideDuration: 3000,
      });
    } else {
      setCheckExistPassword(passwordValue);
      setLoading(true);
      data = getEncryptedData(`{"e": "${userValue}", "p": "${passwordValue}"}`);
      const payload = {
        query: Query.authLoginMutation,
        variables: { data },
      };
      dispatch(submitLogin(payload));
    }
  }

  function handleKeyPress(event) {
    if (event.key === 'Enter') {
      event.preventDefault();
      getEmailOrPhoneInput();
    }
  }

  function handleKeyPressSignIn(event) {
    if (!loading) {
      if (event.key === 'Enter') {
        event.preventDefault();
        handleSignIn();
      }
    }
  }

  const resendOTP = async () => {
    setResendOtpStatus(true);
    const findCountryCode = Object.entries(customLabels).find(
      ([key, value]) => value.isoCode === selected
    );
    const payload = {
      query: Query.sendOTPOnPhoneNumberForLogin,
      variables: {
        countryCode: findCountryCode[1].secondary,
        phoneNumber: parseInt(userValue, 10),
      },
    };
    const result = await dispatch(sendOTPOnPhoneNumberForLogin(payload));
    if (result.payload?.sendOTPOnPhoneNumberForLogin?.status === 400) {
      enqueueSnackbar(result.payload?.sendOTPOnPhoneNumberForLogin?.message, {
        variant: 'error',
        autoHideDuration: 3000,
      });
    }

    setMinutes(1);
    setSeconds(0);
    setTimeout(() => {
      setResendOtpStatus(false);
      setMinutes(0);
      setSeconds(59);
    }, 1000);
  };

  const responseGoogle = async (response) => {
    if (response) {
      // const data = await axios.get(
      //   'https://accounts.google.com/o/oauth2/v2/auth?client_id=51666468051-hpv7ad36fr0v4p51b8vesm97akogv5h7.apps.googleusercontent.com&redirect_uri=https://app-uat.quickreviews.click&response_type=token&scope=https://www.googleapis.com/auth/plus.business.manage&state=xcoVv98y2kd44vuqwye3kcq&include_granted_scopes=true'
      // );
      setGoogleLoading(true);
      let successMessage = '';
      try {
        const googleResponse = JSON.stringify({
          accessToken: response.credential,
        });
        const registrationPayload = {
          query: Query.asPreviouslyRegesterInGoogle,
          variables: { data: getEncryptedData(googleResponse) },
        };
        const registrationResult = await dispatch(
          asPreviouslyRegesterInGoogle(registrationPayload)
        );
        if (registrationResult.payload?.available === false) {
          const googlePayload = {
            query: Query.googleAuth,
            variables: { data: getEncryptedData(googleResponse) },
          };
          await dispatch(googleAuth(googlePayload));
        } else {
          successMessage = t('signInPage.messages.gmailIsNotRegistered');
        }
      } catch (error) {
        enqueueSnackbar(t('signInPage.messages.errorOccurred'), {
          variant: 'error',
          autoHideDuration: 2000,
        });
      } finally {
        setGoogleLoading(false);
        if (successMessage) {
          enqueueSnackbar(successMessage, {
            variant: 'error',
            autoHideDuration: 2000,
          });
        }
      }
    }
  };

  return (
    <>
      <div className="flex items-center justify-center bg-white h-full w-full sm:px-0 px-16 sm:py-0 py-40">
        <img
          src="assets/images/background/top-round.png"
          className="absolute top-0 right-0 hidden lg:block"
          alt="..."
        />
        <div>
          <img
            src="assets/images/logo/quick_hub.png"
            className="mx-auto md:max-w-88 max-w-80"
            alt="logo"
          />
          <Typography className="mt-10 mb-32 text-24 font-bold text-center text-black">
            {t('signInPage.messages.welcome')}
          </Typography>
          <Paper className="shadow-none">
            <div>
              {!isWhatsapp ? (
                <div className="bg-grey-50 w-full md:w-[816px] mx-auto rounded-2xl items-center justify-center sm:shadow md:shadow-none py-20 px-20 md:py-40 md:px-80">
                  <Typography className="text-20 font-bold tracking-tight leading-tight">
                    {t('signInPage.title')}
                  </Typography>
                  <div className="sm:flex items-top">
                    <div className="block">
                      <div className="w-full max-w-320 md:w-320 mx-auto sm:mx-0">
                        <form
                          name="loginForm"
                          noValidate
                          className="flex flex-col justify-center w-full mt-32"
                        >
                          <span className="relative">
                            {showPhoneDropdown && (
                              <span className="absolute z-20">
                                <ReactFlagsSelect
                                  className={
                                    userValue && nextStep
                                      ? 'pointer-events-none react-flag-button'
                                      : 'react-flag-button'
                                  }
                                  selected={selected}
                                  onSelect={(code) => onChangeCountryDrop(code)}
                                  countries={countryIsoData}
                                  searchable
                                  customLabels={customLabels}
                                  showSelectedLabel={false}
                                />
                              </span>
                            )}

                            <TextField
                              className="mb-28 rounded-sm"
                              name="email"
                              control={control}
                              style={{ height: '40px' }}
                              placeholder={t('signInPage.formField.email.userName')}
                              sx={{
                                '& .MuiInputBase-input': {
                                  backgroundColor: '#fff',
                                  paddingTop: 0,
                                  paddingBottom: 0,
                                  height: '40px',
                                  fontWeight: '500',
                                  textIndent: showPhoneDropdown ? '105px !important' : 'initial',
                                  transition: 'all .2s ease-in-out',
                                  borderRadius: '5px',
                                },
                              }}
                              autoFocus
                              disabled={userValue && nextStep}
                              onKeyDown={(event) => {
                                handleKeyPress(event);
                              }}
                              onChange={(e) => {
                                const inputValue = e.target.value;
                                setUserValue(inputValue);
                                setIsValueChange(true);
                                if (/^\d+$/.test(inputValue)) {
                                  e.target.type = 'tel';
                                  setShowPhoneDropdown(true);
                                  const digitCount = inputValue.replace(/\D/g, '').length;
                                  if (digitCount > 15) {
                                    e.target.value = inputValue.substring(0, 10);
                                  }
                                } else {
                                  e.target.type = 'text';
                                  setShowPhoneDropdown(false);
                                }
                              }}
                              size="small"
                              error={!!errors.email}
                              helperText={errors.email ? errors.email.message : ''}
                              variant="outlined"
                              required
                              fullWidth
                            />
                          </span>

                          {nextStep && (
                            <>
                              {showPhoneDropdown ? (
                                <>
                                  <MuiOtpInput
                                    className="max-w-xs my-24"
                                    TextFieldsProps={{
                                      size: 'small',
                                      placeholder: '-',
                                      type: 'number',
                                    }}
                                    type="number"
                                    gap={2}
                                    length={6}
                                    value={otp}
                                    onChange={(newValue) => setOtp(newValue)}
                                  />

                                  <div className="countdown-text flex items-center justify-between sm:justify-start text-12 sm:text-14 font-medium my-10">
                                    {seconds > 0 || minutes > 0 ? (
                                      <p>
                                        {t('signUpPage.messages.codeResend')}:{' '}
                                        {minutes < 10 ? `0${minutes}` : minutes}:
                                        {seconds < 10 ? `0${seconds}` : seconds}
                                      </p>
                                    ) : (
                                      <p>{t('signUpPage.messages.notReceivedCode')}</p>
                                    )}
                                    {resendOtpStatus ? (
                                      <CircularProgress
                                        size={24}
                                        className="text-14 ml-10 mt-1 text-darkgreen mx-auto"
                                      />
                                    ) : (
                                      <Button
                                        disabled={seconds > 0 || minutes > 0}
                                        className="underline decoration-1 text-14 font-medium"
                                        color="secondary"
                                        style={{
                                          color: seconds > 0 || minutes > 0 ? '#DFE3E8' : '#FF5630',
                                        }}
                                        onClick={resendOTP}
                                      >
                                        {t('common.resendOTP')}
                                      </Button>
                                    )}
                                  </div>
                                </>
                              ) : (
                                <Controller
                                  name="password"
                                  control={control}
                                  render={({ field }) => (
                                    <TextField
                                      {...field}
                                      className="mb-24 bg-white h-40"
                                      placeholder={t('common.password')}
                                      type="password"
                                      onChange={(e) => setPasswordValue(e.target.value)}
                                      onKeyDown={(event) => {
                                        handleKeyPressSignIn(event);
                                      }}
                                      size="small"
                                      error={!!errors.password}
                                      helperText={errors?.password?.message}
                                      InputProps={{
                                        className: 'pr-2',
                                        type: showPassword ? 'text' : 'password',
                                        endAdornment: (
                                          <InputAdornment position="end">
                                            <IconButton
                                              className="bg-white"
                                              onClick={() => setShowPassword(!showPassword)}
                                            >
                                              <Icon className="text-20" color="action">
                                                {showPassword ? 'visibility' : 'visibility_off'}
                                              </Icon>
                                            </IconButton>
                                          </InputAdornment>
                                        ),
                                      }}
                                      variant="outlined"
                                      required
                                      fullWidth
                                    />
                                  )}
                                />
                              )}
                            </>
                          )}
                          {nextStep ? (
                            <div>
                              <Button
                                variant="contained"
                                color="secondary"
                                className="w-full md:text-14 font-semibold disabled:text-black rounded-md"
                                aria-label="Sign in"
                                disabled={loading || (showPhoneDropdown && otp?.length !== 6)}
                                onClick={() => handleSignIn()}
                                type="button"
                              >
                                {t('signInPage.buttons.signIn')}{' '}
                                {loading && (
                                  <CircularProgress
                                    size={24}
                                    className="text-darkgreen absolute mx-auto"
                                  />
                                )}
                              </Button>
                            </div>
                          ) : (
                            <div>
                              <Button
                                variant="contained"
                                color="secondary"
                                className="w-full md:text-14 font-semibold disabled:text-black rounded-md"
                                aria-label="Sign in"
                                disabled={loading}
                                onClick={() => getEmailOrPhoneInput()}
                                type="button"
                              >
                                {OTPLoading && (
                                  <CircularProgress
                                    size={20}
                                    className="text-white absolute mx-auto mr-64"
                                  />
                                )}
                                {t('signUpPage.buttons.next')}
                              </Button>
                            </div>
                          )}
                          <div className="text-right mt-16">
                            <button
                              type="button"
                              className="inline-block text-14 text-darkgreen font-semibold cursor-pointer ml-4"
                              onClick={handleForgotPasswordClick}
                            >
                              {t('signInPage.link.forgotPassword')}
                            </button>
                          </div>
                        </form>
                        <div className="text-left">
                          <Typography className="text-14 font-semibold mt-16 inline-block">
                            {t('signInPage.messages.doNotHaveAccount')}
                          </Typography>
                          <Link
                            className="text-14 font-bold text-darkgreen inline-block ml-5"
                            role="button"
                            to="/sign-up"
                          >
                            {t('signUpPage.title')}
                          </Link>
                        </div>
                      </div>
                      <div className="text-12 mt-20 max-w-320">
                        By continuing you agree to our
                        <a
                          href={process.env.REACT_APP_PRIVACY_POLICY_LINK}
                          target="_blank"
                          rel="noreferrer noopener"
                          role="button"
                          className="text-darkgreen link-color cursor-pointer !bg-grey-50 font-medium ml-5"
                        >
                          {t('signUpPage.labels.privacyPolicy')}
                        </a>
                        ,{' '}
                        <a
                          href={process.env.REACT_APP_TERMS_OF_SERVICE_LINK}
                          target="_blank"
                          rel="noreferrer noopener"
                          role="button"
                          className="text-darkgreen link-color cursor-pointer !bg-grey-50 font-medium"
                        >
                          {t('signUpPage.labels.termsService')}
                        </a>
                        ,{' '}
                        <a
                          href={process.env.REACT_APP_EULA_LINK}
                          target="_blank"
                          rel="noreferrer noopener"
                          role="button"
                          className="text-darkgreen link-color cursor-pointer !bg-grey-50 font-medium"
                        >
                          {t('signUpPage.labels.eula')}
                        </a>{' '}
                        and{' '}
                        <a
                          href={process.env.REACT_APP_DISCLAIMER_LINK}
                          target="_blank"
                          rel="noreferrer noopener"
                          role="button"
                          className="text-darkgreen link-color cursor-pointer !bg-grey-50 font-medium"
                        >
                          {t('signUpPage.labels.disclaimers')}
                        </a>{' '}
                      </div>
                    </div>
                    <div className="sm:grid flex items-center justify-center md:mx-40 sm:mx-20 sm:my-0 my-20">
                      <Divider className="sm:h-80 sm:w-2 h-1 w-28 bg-gray-300 sm:mx-auto mx-6" />
                      <span className="font-medium text-16">{t('signInPage.messages.or')}</span>
                      <Divider className="sm:h-80 sm:w-2 h-1 w-28 bg-gray-300 sm:mx-auto mx-6" />
                    </div>
                    <div className="block sm:max-w-256 sm:min-w-256 sm:mt-32">
                      <div className="w-full mb-16 text-left relative">
                        <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
                          <div className="w-full">
                            <GoogleLogin
                              logo_alignment="center"
                              width="256"
                              height="40"
                              onSuccess={responseGoogle}
                              onError={responseGoogle}
                            />
                            {googleLoading && (
                              <CircularProgress
                                size={24}
                                className="text-darkgreen absolute mx-auto top-10 right-0"
                              />
                            )}
                          </div>
                        </GoogleOAuthProvider>
                      </div>
                      <div className="w-full mb-16 cursor-pointer">
                        <AppleLogin
                          clientId="org.reactjs.native.example.happyReview"
                          redirectURI={`${process.env.REACT_APP_APPLE_REDIRECT_URL}appleVerify`}
                          scope="email name   "
                          responseMode="form_post"
                          responseType="id_token code"
                          render={(renderProps) => (
                            <Button
                              onClick={renderProps.onClick}
                              disabled={renderProps.disabled}
                              className="w-full max-w-[256px] border-1 border-solid border-grey-300 bg-white font-semibold text-[#3c4043] rounded h-10 flex items-center justify-center text-center px-12"
                            >
                              <img
                                src="/assets/images/icon/apple.png"
                                alt="Apple logo"
                                className="w-16 h-16 mr-8"
                              />
                              Sign in with Apple{' '}
                              {appleLoading && (
                                <CircularProgress
                                  size={24}
                                  className="text-darkgreen absolute mx-auto top-10 right-0"
                                />
                              )}
                            </Button>
                          )}
                        />
                      </div>
                      {/*  <div className="mx-auto cursor-pointer">
                      <Button
                        className="border text-14 border-solid border-grey-300 bg-white font-bold rounded w-256 h-40 hover:text-darkgreen flex items-center justify-start px-12"
                        onClick={() => setIsWhatsapp(true)}
                      >
                        <img
                          className="mr-8 w-16 h-16"
                          src="/assets/images/business/whatsapp.svg"
                          alt=""
                        />
                        {t('whatsapp.continueWhatsappSignIn')}
                      </Button>
                    </div>
                    <span className="font-medium text-14 block text-center mt-6 w-256">
                      {t('signInPage.messages.or')}
                    </span>
                    <Button
                      className="text-darkgreen underline w-256 m-auto hover:bg-white text-center p-0 min-h-0 max-h-0"
                      onClick={() => handleWhatsappClick()}
                    >
                      {' '}
                      {t('whatsapp.openWhatsapp')}
                    </Button> */}
                    </div>
                  </div>
                </div>
              ) : (
                <>
                  <Icon
                    className="text-20 mb-16 cursor-pointer"
                    onClick={() => setIsWhatsapp(false)}
                  >
                    west
                  </Icon>
                  <div className="bg-gradient-to-b from-darkgreen-A500 to-darkgreen text-center p-40 max-w-360 rounded-xl">
                    <Typography className="text-white w-full text-28 py-8 font-semibold">
                      {t('whatsapp.signInContinue')}
                    </Typography>
                    <div className="text-center m-auto bg-white max-w-160 p-16 rounded-md my-20">
                      <QRCode value={whatsAppURL} />
                    </div>
                    <Typography className="text-white text-20 max-w-384 py-24 font-normal">
                      {t('whatsapp.scanQrCode')}
                    </Typography>
                  </div>
                </>
              )}
            </div>
          </Paper>
        </div>
        <img
          src="assets/images/background/bottom-round.png"
          className="absolute bottom-0 left-0 hidden lg:block"
          alt="..."
        />
      </div>
      <div className="mb-16 mr-16 bg-white flex justify-end absolute bottom-0 right-0">
        <AnimatePresence>
          {assistanceDialog ? (
            <motion.div
              initial={{ opacity: 0, y: 100 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: 100 }}
              transition={{
                type: 'spring',
                stiffness: 120,
                damping: 14,
              }}
              className="absolute bottom-0 right-0"
            >
              <Assistance setAssistanceDialog={setAssistanceDialog} />
            </motion.div>
          ) : (
            <Button
              className="rounded-md font-semibold flex items-center gap-6 text-16 mb-8 mr-8"
              variant="contained"
              color="secondary"
              onClick={() => setAssistanceDialog(true)}
            >
              {t('navigation.needAssistance')}{' '}
              <img className="w-24" src="assets/images/icon/assistance.svg" alt="" />
            </Button>
          )}
        </AnimatePresence>
      </div>
    </>
  );
}

export default withReducer('signInReducer', reducer)(SignInPage);
