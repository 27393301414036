import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Button, Tab, Tabs, Typography } from '@mui/material';
import { t } from 'i18next';
import { useSelector } from 'react-redux';
import FuseLoading from '@fuse/core/FuseLoading';
import history from '@history';
import { useLocation } from 'react-router-dom';
import CompetitorBusiness from './CompetitorBusiness';
import GeoGridPoints from './GeoGridPoints';
import { selectDashboardData } from '../../../../store/userSlice';
import SupportForPhysicalOrOnline from '../../business-setup/supportForPhysicalOrOnline/SupportForPhysicalOrOnline';
import { handleApiRequest } from '../../../common/common';
import manageBusinessData from '../../../query/manageBusiness';

const BusinessCompetitors = () => {
  const [tabValue, setTabValue] = useState(1);
  const [placeId, setPlaceId] = useState('');
  const [loading, setLoading] = useState(true);
  const [reviewPlatformId, setReviewPlatFormId] = useState();
  const [refreshIcon, setRefreshIcon] = useState(false);
  const [competitorData, setCompetitorData] = useState([]);
  const userCurrentBusinessDetails = useSelector(selectDashboardData);
  const location = useLocation();
  const [currentLocation, setCurrentLocation] = useState({
    lat: location?.state?.queryParams?.latitude || 34.0522342,
    lng: location?.state?.queryParams?.longitude || -118.2436849,
  });

  const fetchCompetitorData = useCallback(async () => {
    if (userCurrentBusinessDetails?.id && reviewPlatformId) {
      const payload = {
        query: manageBusinessData.getUserBusinessRanker,
        variables: {
          userBusinessId: parseInt(userCurrentBusinessDetails?.id, 10),
          reviewPlatformId: parseInt(reviewPlatformId, 10),
        },
      };
      const result = await handleApiRequest(payload);
      if (result?.getUserBusinessRanker?.status === 200) {
        const data = result?.getUserBusinessRanker?.data?.rankerJson;
        setCompetitorData(data);
      }
    }
  }, [userCurrentBusinessDetails?.id, reviewPlatformId]);

  useEffect(() => {
    fetchCompetitorData();
  }, [fetchCompetitorData]);

  useEffect(() => {
    const reviewPlatform = userCurrentBusinessDetails?.reviewplatform;
    const findDefaultPlatform = reviewPlatform?.find(
      (item) => parseInt(item.reviewPlatformId, 10) === 2
    );
    const convertParse = findDefaultPlatform?.geometry && JSON.parse(findDefaultPlatform?.geometry);
    setPlaceId(findDefaultPlatform?.placeId);
    setReviewPlatFormId(findDefaultPlatform?.reviewPlatformId || 0);
    setCurrentLocation({
      lat: convertParse?.location?.lat || 34.0522342,
      lng: convertParse?.location?.lng || -118.2436849,
    });
    setLoading(false);
  }, [userCurrentBusinessDetails?.reviewplatform]);

  if (loading) {
    return <FuseLoading />;
  }

  return (
    <div className="lg:p-24 p-16 h-full relative">
      <div className="sm:flex justify-between items-center lg:mb-20 mb-16">
        <Typography className="md:text-28 text-20 font-bold sm:mb-0 mb-16">
          {t('businessCompetitor.businessCompetitor')}
        </Typography>
        <Button
          variant="contained"
          color="secondary"
          className="rounded-md font-semibold px-40"
          onClick={() => history.push('quick-reviews/scanHistory')}
        >
          {t('businessCompetitor.scanHistory')}
        </Button>
      </div>
      {parseInt(reviewPlatformId, 10) === 2 ? (
        <>
          <div className="bg-white rounded-md p-24 pb-40 w-full">
            <div className="border-b-1 border-solid border-grey mb-20 flex justify-between items-center">
              <Tabs
                value={tabValue}
                onChange={(e, newValue) => setTabValue(newValue)}
                textColor="secondary"
                indicatorColor="secondary"
                aria-label="secondary tabs example"
              >
                <Tab
                  className="sm:text-18 font-semibold p-0 sm:mr-28 mr-14"
                  value={1}
                  label={t('businessCompetitor.geoGridScan')}
                />
                {competitorData?.length > 0 && (
                  <Tab
                    className="sm:text-18 font-semibold p-0"
                    value={3}
                    label={t('businessCompetitor.competitorBusiness')}
                    disabled={refreshIcon}
                  />
                )}
              </Tabs>
            </div>
            {parseInt(tabValue, 10) === 1 && (
              <GeoGridPoints
                currentLocation={currentLocation}
                placeId={placeId}
                reviewPlatformId={reviewPlatformId}
                businessId={userCurrentBusinessDetails?.id}
                refreshIcon={refreshIcon}
                setRefreshIcon={setRefreshIcon}
                fetchCompetitorData={fetchCompetitorData}
              />
            )}
            {parseInt(tabValue, 10) === 3 && (
              <CompetitorBusiness
                currentLocation={currentLocation}
                businessId={userCurrentBusinessDetails?.id}
                reviewPlatformId={reviewPlatformId}
              />
            )}
          </div>
        </>
      ) : (
        <SupportForPhysicalOrOnline message={t('businessCompetitor.functionalitySupport')} />
      )}
    </div>
  );
};

export default BusinessCompetitors;
