import React, { useCallback, useEffect, useState } from 'react';
import PushPinIcon from '@mui/icons-material/PushPin';
import PushPinOutlinedIcon from '@mui/icons-material/PushPinOutlined';
import ClearIcon from '@mui/icons-material/Clear';
import SearchIcon from '@mui/icons-material/Search';
import { t } from 'i18next';
import history from '@history';
import { useSelector, useDispatch } from 'react-redux';
import { useSnackbar } from 'notistack';
import Pagination from 'src/app/component/table/Pagination';
import FuseLoading from '@fuse/core/FuseLoading/FuseLoading';
import { selectDashboardData } from 'app/store/userSlice';
import { useLocation } from 'react-router-dom';
import { CircularProgress, IconButton, InputAdornment, TextField } from '@mui/material';
import manageBusinessData from '../../query/manageBusiness';
import { handleApiRequest } from '../../common/common';
import CreatePostPopUp from './CreatePostPopUp';
import Loading from '../manage-business/Loading';
import CreateReviewPost from './CreateReviewPost';
import quickSocialQuery from '../../query/quickSocial';
import userData from '../../query/user';

const categoryData = {
  icon: 'https://quick-review.s3.us-east-1.amazonaws.com/user-image/1726654136_89.png',
  isPinCategory: false,
  isRelevantCategory: false,
  isSubCategory: false,
  mainCategoryId: 1,
  name: 'For You',
  subCategoryId: null,
};

const Template = () => {
  const location = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  const [getSocialTemplateCateDataV1, setGetSocialTemplateCateDataV1] = useState('');
  const [getSocialTemplateSubCateDataV1, setGetSocialTemplateSubCateDataV1] = useState('');
  const [favTemplate, setFavTemplate] = useState([]);
  const [isFavoriteTemp, setIsFavoriteTemp] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [pageSize, setPageSize] = useState(12);
  const [pageNumber, setPageNumber] = useState(1);
  const [totalCount, setTotalCount] = useState();
  const [favTotal, setFavTotal] = useState();
  const [openDialogueForDelete, setOpenDialogueForDelete] = useState(false);
  const [templateImage, setTemplateImage] = useState();
  const [showIcon, setShowIcon] = useState(null);
  const [subShowIcon, setSubShowIcon] = useState(null);
  const [subCategoryIcon, setSubCategoryIcon] = useState(null);
  const [selectedCategoryId, setSelectedCategoryId] = useState(
    location?.state?.selectedCategoryId || 1
  );
  const [selectedSubcategoryId, setSelectedSubcategoryId] = useState(
    location?.state?.selectedSubcategoryId || null
  );
  const [isLoading, setIsLoading] = useState(false);
  const userCurrentBusinessDetails = useSelector(selectDashboardData);
  const [reviewPopUp, setReviewPopUp] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedCategories, setSelectedCategories] = useState(categoryData);
  const [searchText, setSearchText] = useState('');
  const [suggestion, setSuggestion] = useState('');
  const [searchTerm, setSearchTerm] = useState({});
  const [suggestList, setSuggestList] = useState(false);
  const [pinned, setPinned] = useState(false);
  const [pinnedCategoryId, setPinnedCategoryId] = useState(null);
  const [pinnedSubCategoryId, setPinnedSubCategoryId] = useState(null);
  const dispatch = useDispatch();
  const [currentBusiness, setCurrentBusiness] = useState(null);

  useEffect(() => {
    const fetchDashBoard = async () => {
      try {
        const obj = {
          query: userData.getDashboard,
          variables: {
            businessId: Number(userCurrentBusinessDetails?.id),
          },
        };
        const res = await handleApiRequest(obj);
        setCurrentBusiness(res?.getDashboard?.data?.[0]);
      } catch (error) {
        console.error('Error fetching dashboard data:', error);
      }
    };

    if (userCurrentBusinessDetails?.id) {
      fetchDashBoard();
    }
  }, [userCurrentBusinessDetails?.id, dispatch]);

  useEffect(() => {
    const searchQuickSocialTemplatesCategories = async () => {
      try {
        const payload = {
          query: quickSocialQuery.searchQuickSocialTemplatesCategories,
          variables: {
            searchText,
          },
        };
        const result = await handleApiRequest(payload);
        if (result?.searchQuickSocialTemplatesCategories?.status === 200) {
          setSuggestion(result?.searchQuickSocialTemplatesCategories?.data);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    if (searchText) {
      searchQuickSocialTemplatesCategories();
    }
  }, [searchText]);

  const getQuickSocialTemplatesCategoryV1 = useCallback(
    async (data) => {
      setPinnedCategoryId(data?.mainCategoryId);
      setPinnedSubCategoryId(data?.subCategoryId);
      setPinned(true);
      try {
        const payload = {
          query: quickSocialQuery.getQuickSocialTemplatesCategoryV1,
          variables: {
            userBusinessId: Number(userCurrentBusinessDetails?.id),
            relevantTemplateIds: currentBusiness?.relevantTemplateId,
            searchText: searchTerm?.name || '',
          },
        };
        const result = await handleApiRequest(payload);
        if (result?.getQuickSocialTemplatesCategoryV1?.status === 200) {
          setGetSocialTemplateCateDataV1(result?.getQuickSocialTemplatesCategoryV1?.data);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
      setPinned(false);
      setPinnedCategoryId(null);
      setPinnedSubCategoryId(null);
    },
    [searchTerm, userCurrentBusinessDetails?.id, currentBusiness?.relevantTemplateId]
  );

  useEffect(() => {
    if (userCurrentBusinessDetails?.id && currentBusiness) {
      getQuickSocialTemplatesCategoryV1();
    }
  }, [currentBusiness, getQuickSocialTemplatesCategoryV1, userCurrentBusinessDetails]);

  const getQuickSocialTemplatesSubCategoryV1 = useCallback(async () => {
    try {
      const payload = {
        query: quickSocialQuery.getQuickSocialTemplatesSubCategoryV1,
        variables: {
          socialTemplatesCategoryId: Number(selectedCategoryId),
          userBusinessId: Number(userCurrentBusinessDetails?.id),
        },
      };
      const result = await handleApiRequest(payload);
      if (result?.getQuickSocialTemplatesSubCategoryV1?.status === 200) {
        setGetSocialTemplateSubCateDataV1(result?.getQuickSocialTemplatesSubCategoryV1?.data);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }, [selectedCategoryId, userCurrentBusinessDetails?.id]);

  useEffect(() => {
    if (selectedCategories?.isSubCategory) {
      getQuickSocialTemplatesSubCategoryV1();
    }
  }, [selectedCategories?.isSubCategory, getQuickSocialTemplatesSubCategoryV1]);

  const getSharePostTemplateData = useCallback(async () => {
    setLoading(true);
    try {
      const variables =
        selectedCategoryId === 1
          ? {
              pageNumber,
              pageSize,
              quickSocialTemplatesCategoryId: selectedCategoryId,
              userBusinessId: parseInt(userCurrentBusinessDetails?.id, 10),
            }
          : {
              pageNumber,
              pageSize,
              quickSocialTemplatesCategoryId: selectedCategoryId,
              quickSocialTemplatesSubCategoryId: selectedSubcategoryId,
            };
      const payload = {
        query: manageBusinessData.getSharePostTemplate,
        variables,
      };
      const result = await handleApiRequest(payload);
      if (result?.getSharePostTemplate?.status === 200) {
        setTemplateImage(result?.getSharePostTemplate?.data);
        setTotalCount(result?.getSharePostTemplate?.totalCount);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
    setLoading(false);
  }, [
    pageNumber,
    pageSize,
    selectedCategoryId,
    selectedSubcategoryId,
    userCurrentBusinessDetails?.id,
  ]);

  useEffect(() => {
    getSharePostTemplateData();
  }, [getSharePostTemplateData]);

  const getFavoriteTemplateData = useCallback(async () => {
    setLoading(true);
    try {
      const payload = {
        query: manageBusinessData.getFavoriteTemplate,
        variables: {
          businessId: parseInt(userCurrentBusinessDetails?.id, 10),
          pageNumber,
          pageSize,
        },
      };
      const result = await handleApiRequest(payload);
      if (result?.getFavoriteTemplate?.status === 200) {
        setFavTemplate(result?.getFavoriteTemplate?.data);
        setFavTotal(result?.getFavoriteTemplate?.totalCount);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
    setLoading(false);
  }, [pageNumber, pageSize, userCurrentBusinessDetails?.id]);

  useEffect(() => {
    getFavoriteTemplateData();
  }, [getFavoriteTemplateData]);

  const handleFavTemp = async () => {
    setPageNumber(1);
    setIsFavoriteTemp(true);
    setSelectedCategories(null);
    setSelectedCategoryId(null);
    setPageSize(12);
    getFavoriteTemplateData();
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter' || event.key === ' ') {
      handleFavTemp();
    }
  };

  const handleImageClick = (image) => {
    setSelectedImage(image);
    setOpenDialogueForDelete(true);
  };

  const handleCloseDeleteDialog = () => {
    setOpenDialogueForDelete(false);
  };

  const handleCategoryClick = (data) => {
    setSelectedCategories(data);
    setPageNumber(1);
    setSelectedCategoryId(data?.mainCategoryId);
    setPageSize(12);
    setIsFavoriteTemp(false);
  };

  useEffect(() => {
    if (
      (selectedCategories?.mainCategoryId === 3 || selectedCategories?.mainCategoryId === 4) &&
      !selectedCategories?.subCategoryId &&
      getSocialTemplateSubCateDataV1?.length > 0
    ) {
      setSelectedSubcategoryId(getSocialTemplateSubCateDataV1[0]?.id);
    } else {
      setSelectedSubcategoryId(selectedCategories?.subCategoryId);
    }
  }, [getSocialTemplateSubCateDataV1, selectedCategories]);

  const handleSubcategoryClick = (id) => {
    setSelectedSubcategoryId(id);
  };

  const handleFavoriteToggle = async (id, data) => {
    try {
      const isAlreadyFavorite = favTemplate?.some(
        (template) => template?.sharePostTemplateId === id
      );
      if (isAlreadyFavorite) {
        const payload = {
          query: manageBusinessData.deleteFavoriteTemplate,
          variables: {
            userBusinessId: Number(userCurrentBusinessDetails?.id),
            sharePostTemplateId: id,
          },
        };
        const result = await handleApiRequest(payload);
        if (result?.deleteFavoriteTemplate?.status === 200) {
          getFavoriteTemplateData();
          setFavTemplate((prevFavorites) =>
            prevFavorites?.filter((template) => template?.sharePostTemplateId !== id)
          );
        } else {
          getFavoriteTemplateData();
          enqueueSnackbar(result?.deleteFavoriteTemplate?.message, {
            variant: 'error',
            autoHideDuration: 3000,
          });
        }
      } else {
        const payload = {
          query: manageBusinessData.addFavoriteTemplate,
          variables: {
            userBusinessId: parseInt(userCurrentBusinessDetails?.id, 10),
            sharePostTemplateId: id,
          },
        };
        const result = await handleApiRequest(payload);
        if (result?.addFavoriteTemplate?.status === 200) {
          setFavTemplate((prevFavorites) => [...prevFavorites, data]);
          getFavoriteTemplateData();
        } else {
          getFavoriteTemplateData();
          enqueueSnackbar(result?.addFavoriteTemplate?.message, {
            variant: 'error',
            autoHideDuration: 3000,
          });
        }
      }
    } catch (error) {
      console.error('Error toggling favorite:', error);
    }
  };

  const isFavorite = (id) => favTemplate?.some((template) => template?.id === id);

  const handleCreatePost = () => {
    if (
      selectedCategoryId === 5 ||
      selectedImage?.share_post_template?.editableText === null ||
      selectedImage?.editableText === null
    ) {
      setReviewPopUp(true);
      setOpenDialogueForDelete(false);
    } else {
      history.push('/quick-social/post', {
        data: selectedImage,
        selectedSubcategoryId: selectedSubcategoryId || null,
        socialId: selectedCategoryId || null,
        passFrom: 'Template',
      });
      setOpenDialogueForDelete(false);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPageNumber(newPage + 1);
  };
  const handleChangeRowsPerPage = async (event) => {
    setPageSize(Number(event.target.value));
    setPageNumber(1);
  };

  const handlePinnedCategory = async (data) => {
    setPinnedCategoryId(data?.mainCategoryId);
    setPinnedSubCategoryId(data?.subCategoryId);
    setPinned(true);
    try {
      const payload = {
        query: quickSocialQuery.pinTemplateCategories,
        variables: {
          userBusinessId: Number(userCurrentBusinessDetails?.id),
          quickSocialTemplatesCategoryId:
            data?.subCategoryId || data?.id ? null : Number(data?.mainCategoryId),
          quickSocialTemplatesSubCategoryId: data?.subCategoryId || data?.id,
        },
      };
      const result = await handleApiRequest(payload);
      if (result?.pinTemplateCategories?.status === 200) {
        getQuickSocialTemplatesCategoryV1();
        getQuickSocialTemplatesSubCategoryV1();
      } else {
        enqueueSnackbar(result?.pinTemplateCategories?.message, {
          variant: 'error',
          autoHideDuration: 3000,
        });
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
    setPinned(false);
    setPinnedCategoryId(null);
    setPinnedSubCategoryId(null);
  };

  const handleUnPinnedCategory = async (data) => {
    setPinnedCategoryId(data?.mainCategoryId);
    setPinnedSubCategoryId(data?.subCategoryId);
    setPinned(true);
    try {
      const payload = {
        query: quickSocialQuery.unPinTemplateCategories,
        variables: {
          userBusinessId: Number(userCurrentBusinessDetails?.id),
          quickSocialTemplatesCategoryId: data?.subCategoryId ? null : Number(data?.mainCategoryId),
          quickSocialTemplatesSubCategoryId: Number(data?.subCategoryId),
        },
      };
      const result = await handleApiRequest(payload);
      if (result?.unPinTemplateCategories?.status === 200) {
        getQuickSocialTemplatesCategoryV1(data);
      } else {
        enqueueSnackbar(result?.unPinTemplateCategories?.message, {
          variant: 'error',
          autoHideDuration: 3000,
        });
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
    setPinned(false);
    setPinnedCategoryId(null);
    setPinnedSubCategoryId(null);
  };

  const handleSelectedCategory = (data) => {
    setSearchTerm(data);
    setSearchText(data?.name);
    setSuggestList(false);
  };

  if (isLoading) {
    return <FuseLoading />;
  }

  return (
    <div className="lg:p-24 p-16  bg-gray-A500">
      <div className="flex justify-between items-center">
        <h1 className="md:text-28 text-20 font-bold lg:mb-20 mb-16">
          {t('quickSocialTemplate.template')}
        </h1>
        <div className=" max-w-640 relative">
          <TextField
            placeholder="Search Template Category"
            variant="outlined"
            fullWidth
            className="bg-white my-0 quick-campaign w-full sm:w-384"
            margin="normal"
            size="small"
            value={searchText}
            onChange={(e) => {
              setSearchText(e.target.value);
              setSuggestList(true);
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
              endAdornment: searchText && (
                <InputAdornment position="end">
                  <IconButton
                    className="hover:bg-transparent"
                    onClick={() => {
                      setSearchText('');
                      setSearchTerm({});
                    }}
                  >
                    <ClearIcon />
                  </IconButton>
                </InputAdornment>
              ),
              sx: {
                '& .MuiOutlinedInput-notchedOutline': {
                  border: 'none',
                },
              },
            }}
          />
          {suggestList && searchText && (
            <div className="bg-white h-144 w-384 overflow-y-auto z-20 rounded-8 absolute p-7 mt-3">
              {suggestion && suggestion?.length > 0 ? (
                suggestion?.map((interest, index) => (
                  <div
                    key={index}
                    className="h-60 p-4 flex font-medium items-center justify-between border-b-1 border-b-gray-A100 last:border-b-0"
                    role="button"
                    tabIndex={0}
                    onClick={() => {
                      handleSelectedCategory(interest);
                    }}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter' || e.key === ' ') {
                        handleSelectedCategory(interest);
                      }
                    }}
                  >
                    <p>{interest?.name} </p>
                    <p className="text-black capitalize">{interest?.type}</p>
                  </div>
                ))
              ) : (
                <div className=" left-0 right-14 bg-white h-92 w-384  z-20 rounded-8 absolute p-7 text-black flex justify-center items-center">
                  {t('quickAds.noDataAvailable')}
                </div>
              )}
            </div>
          )}
        </div>
      </div>
      <div className="lg:flex md:flex gap-24 w-full">
        <div className="mb-10 sm:mb-10 md:max-w-256 max-w-full w-full overflow-x-auto md:overscroll-hidden">
          <div className="sm:flex flex md:block">
            <div
              role="button"
              tabIndex={0}
              className={`px-12 py-16 flex cursor-pointer gap-8 items-center flex-shrink-0  ${
                isFavoriteTemp ? 'bg-quick-social text-white' : ' bg-white'
              }`}
              onClick={handleFavTemp}
              onKeyDown={handleKeyDown}
              aria-pressed={isFavoriteTemp}
            >
              <img src="/assets/images/icon/favorite.png" alt="Favorite" className="h-32 w-32" />
              <h3 className="text-16 text-ellipsis whitespace-nowrap">
                {t('quickSocialTemplate.favorite')}
              </h3>
            </div>
            {getSocialTemplateCateDataV1?.length > 0 &&
              getSocialTemplateCateDataV1
                ?.sort((a, b) => a.id - b.id)
                ?.map((cate, index) => (
                  <div
                    key={index}
                    role="button"
                    tabIndex={0}
                    onMouseEnter={() => {
                      setShowIcon(cate?.mainCategoryId);
                      cate?.subCategoryId
                        ? setSubShowIcon(cate?.subCategoryId)
                        : setSubShowIcon(null);
                    }}
                    onMouseLeave={() => {
                      setShowIcon(null);
                      setSubShowIcon(null);
                    }}
                    className={`flex px-12 py-16 cursor-pointer gap-8  items-center flex-shrink-0  ${
                      selectedCategories?.mainCategoryId === cate?.mainCategoryId &&
                      selectedCategories?.subCategoryId === cate?.subCategoryId
                        ? 'bg-quick-social text-white '
                        : ' bg-white '
                    }`}
                    onClick={() => handleCategoryClick(cate)}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter' || e.key === ' ') {
                        handleCategoryClick(cate);
                      }
                    }}
                  >
                    <span
                      className={`h-32 w-32 bg-grey-100 rounded-full p-5 ${
                        selectedCategoryId === cate?.id ? ' bg-blue-200' : ''
                      }`}
                    >
                      <img src={cate?.icon} alt={cate?.name} />
                    </span>

                    <h3 className="text-16 flex-grow">
                      {cate?.name?.toLowerCase() === 'for you' ? 'Suggested For You' : cate?.name}
                    </h3>
                    {!cate?.isPinCategory &&
                      cate?.mainCategoryId !== 1 &&
                      showIcon === cate?.mainCategoryId &&
                      subShowIcon === cate?.subCategoryId && (
                        <div
                          className={`bg-gray-A500 p-3 rounded-full flex justify-center items-center ${
                            pinnedCategoryId === cate?.mainCategoryId &&
                            pinnedSubCategoryId === cate?.subCategoryId &&
                            pinned
                              ? 'opacity-50 cursor-default'
                              : 'cursor-pointer'
                          }`}
                        >
                          {pinned &&
                          pinnedCategoryId === cate?.mainCategoryId &&
                          pinnedSubCategoryId === cate?.subCategoryId ? (
                            <CircularProgress size="small" className="h-16 w-16" />
                          ) : (
                            <PushPinOutlinedIcon
                              className="text-quick-social h-20 w-20"
                              style={{ transform: 'rotate(45deg)' }}
                              onClick={() => {
                                if (!pinned) {
                                  handlePinnedCategory(cate);
                                }
                              }}
                            />
                          )}
                        </div>
                      )}
                    {cate?.isPinCategory && (
                      <div
                        className={`bg-gray-A500 p-3 rounded-full flex justify-center items-center ${
                          pinnedCategoryId === cate?.mainCategoryId &&
                          pinnedSubCategoryId === cate?.subCategoryId &&
                          pinned
                            ? 'opacity-50 cursor-default'
                            : 'cursor-pointer'
                        }`}
                      >
                        {pinned &&
                        pinnedCategoryId === cate?.mainCategoryId &&
                        pinnedSubCategoryId === cate?.subCategoryId ? (
                          <CircularProgress size="small" className="h-16 w-16" />
                        ) : (
                          <PushPinIcon
                            className="text-quick-social h-20 w-20"
                            style={{ transform: 'rotate(45deg)' }}
                            onClick={() => {
                              if (!pinned) {
                                handleUnPinnedCategory(cate);
                              }
                            }}
                          />
                        )}
                      </div>
                    )}
                  </div>
                ))}
          </div>
        </div>
        <div className="w-full overflow-x-auto">
          <div className="w-auto overflow-x-auto">
            {selectedCategories?.isSubCategory === true && (
              <div className=" gap-10 flex ">
                {getSocialTemplateSubCateDataV1?.length > 0 &&
                  getSocialTemplateSubCateDataV1
                    ?.sort((a, b) => a.id - b.id)
                    ?.map((subcategory) => (
                      <div
                        key={subcategory?.id}
                        role="button"
                        tabIndex={0}
                        className={` flex gap-8 border-1 px-32 py-20 text-14 rounded-full justify-between items-center cursor-pointer mb-24 h-32 relative ${
                          selectedSubcategoryId === subcategory?.id
                            ? 'bg-quick-social text-white '
                            : 'border-grey-300'
                        }`}
                        onClick={() => handleSubcategoryClick(subcategory?.id)}
                        onKeyDown={(e) => {
                          if (e.key === 'Enter' || e.key === ' ') {
                            handleSubcategoryClick(subcategory?.id);
                          }
                        }}
                        onMouseEnter={() => {
                          setSubCategoryIcon(subcategory?.id);
                        }}
                        onMouseLeave={() => {
                          setSubCategoryIcon(null);
                        }}
                      >
                        <div className="flex justify-start items-center">
                          <span className="h-24 w-24 bg-white p-4 rounded-full">
                            <img src={subcategory?.icon} alt={subcategory?.name} className="" />
                          </span>
                          <p className="text-ellipsis whitespace-nowrap ml-5 ">
                            {subcategory?.name}
                          </p>
                        </div>
                        {!subcategory?.isLiked && subCategoryIcon === subcategory?.id && (
                          <div
                            className={`bg-gray-A500 p-3 rounded-full flex justify-center items-center absolute right-5 ${
                              pinned ? 'opacity-50 cursor-not-allowed' : 'cursor-pointer'
                            }`}
                          >
                            <PushPinOutlinedIcon
                              className="text-quick-social h-20 w-20"
                              style={{ transform: 'rotate(45deg)' }}
                              onClick={() => {
                                if (!pinned) {
                                  handlePinnedCategory(subcategory);
                                }
                              }}
                            />
                          </div>
                        )}
                        {subcategory?.isLiked && (
                          <div className="bg-gray-A500 p-3 rounded-full flex justify-center items-center absolute ">
                            <PushPinIcon
                              className="text-quick-social h-20 w-20"
                              style={{ transform: 'rotate(45deg)' }}
                              onClick={() => {
                                if (!pinned) {
                                  handleUnPinnedCategory(subcategory);
                                }
                              }}
                            />
                          </div>
                        )}
                      </div>
                    ))}
              </div>
            )}
          </div>
          {!loading ? (
            <div
              className={`${
                (templateImage?.length > 0 && !isFavoriteTemp) ||
                (isFavoriteTemp && favTemplate?.length > 0)
                  ? 'grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-16'
                  : ''
              }`}
            >
              {templateImage?.length > 0 && !isFavoriteTemp ? (
                templateImage?.map((item) => (
                  <div className="">
                    <div key={item?.sharePostTemplateId} className="relative">
                      <button
                        onClick={() => handleFavoriteToggle(item?.id, item)}
                        className={`absolute top-10 right-10 rounded-full border-2 h-20 w-20 ${
                          favTemplate?.some((fav) => fav?.sharePostTemplateId === item?.id)
                            ? 'bg-gray-300'
                            : 'bg-gray-300'
                        }`}
                        type="button"
                        aria-label={
                          favTemplate?.some((fav) => fav?.sharePostTemplateId === item?.id)
                            ? 'Unfavorite template'
                            : 'Favorite template'
                        }
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className={`h-16 w-16 ${
                            favTemplate?.some((fav) => fav?.sharePostTemplateId === item?.id)
                              ? 'text-red-500'
                              : 'text-gray-500'
                          }`}
                          viewBox="0 0 24 24"
                          fill="currentColor"
                        >
                          <path d="M12 21.35l-1.45-1.32C5.4 15.36 2 12.28 2 8.5 2 5.42 4.42 3 7.5 3c1.74 0 3.41.81 4.5 2.09C13.09 3.81 14.76 3 16.5 3 19.58 3 22 5.42 22 8.5c0 3.78-3.4 6.86-8.55 11.54L12 21.35z" />
                        </svg>
                      </button>
                      <div
                        role="button"
                        tabIndex={0}
                        className="w-full object-cover cursor-pointer"
                        onClick={() => handleImageClick(item)}
                        onKeyDown={(e) => {
                          if (e.key === 'Enter' || e.key === ' ') {
                            handleImageClick(item);
                          }
                        }}
                      >
                        <img
                          src={item?.templateImage}
                          alt={item?.name}
                          className="w-full object-cover rounded-md"
                        />
                      </div>
                    </div>
                  </div>
                ))
              ) : !isFavoriteTemp ? (
                <div className="flex w-full justify-center items-center mt-112">
                  <div className="flex flex-col items-center justify-center text-center w-7xl">
                    <img
                      src="assets/images/icon/socialNoTemp.png"
                      alt="connect"
                      className="mb-16"
                    />
                    <h1 className="text-24 font-bold mb-16">
                      {t('quickSocialTemplate.noTemplate')}
                    </h1>
                    <p className="text-gray-600 font-medium mb-16 text-20">
                      {t('quickSocialTemplate.noTemplateContent')}
                    </p>
                  </div>
                </div>
              ) : null}
              {isFavoriteTemp ? (
                <>
                  {favTemplate?.length > 0 ? (
                    favTemplate?.map((item) => (
                      <div key={item?.id} className="relative">
                        <button
                          onClick={() => handleFavoriteToggle(item?.sharePostTemplateId, item)}
                          className={`absolute top-10 right-10 h-20 w-20 rounded-full border-2 ${
                            isFavorite(item?.sharePostTemplateId) ? 'bg-grey-300' : 'bg-grey-300'
                          }`}
                          type="button"
                          aria-label={
                            isFavorite(item?.sharePostTemplateId)
                              ? 'Unfavorite template'
                              : 'Favorite template'
                          }
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className={`h-16 w-16 ${
                              isFavorite(item?.sharePostTemplateId) ? 'text-white' : ' text-red-500'
                            }`}
                            viewBox="0 0 24 24"
                            fill="currentColor"
                          >
                            <path d="M12 21.35l-1.45-1.32C5.4 15.36 2 12.28 2 8.5 2 5.42 4.42 3 7.5 3c1.74 0 3.41.81 4.5 2.09C13.09 3.81 14.76 3 16.5 3 19.58 3 22 5.42 22 8.5c0 3.78-3.4 6.86-8.55 11.54L12 21.35z" />
                          </svg>
                        </button>
                        <div
                          role="button"
                          tabIndex={0}
                          className="w-full object-cover cursor-pointer rounded-md"
                          onClick={() => handleImageClick(item)}
                          onKeyDown={(e) => {
                            if (e.key === 'Enter' || e.key === ' ') {
                              handleImageClick(item);
                            }
                          }}
                        >
                          <img
                            src={item?.share_post_template?.templateImage}
                            alt="Template"
                            className="w-full object-cover"
                          />
                        </div>
                      </div>
                    ))
                  ) : (
                    <div className="flex max-w-7xl justify-center items-center mt-112">
                      <div className=" ml-500 flex flex-col items-center justify-center text-center w-7xl">
                        <img
                          src="assets/images/icon/socialNoTemp.png"
                          alt="connect"
                          className="mb-16"
                        />
                        <h1 className="text-24 font-bold mb-16">
                          {t('quickSocialTemplate.noTemplate')}
                        </h1>
                        <p className="text-gray-600 font-medium text-20 mb-16">
                          {t('quickSocialTemplate.noTemplateContent')}
                        </p>
                      </div>
                    </div>
                  )}
                </>
              ) : null}
            </div>
          ) : (
            <div className="w-7xl h-640 justify-center items-center flex">
              <FuseLoading />
            </div>
          )}
          {selectedImage && (
            <CreatePostPopUp
              open={openDialogueForDelete}
              onClose={handleCloseDeleteDialog}
              onCreatePost={handleCreatePost}
              temp={
                selectedImage?.templateImage || selectedImage?.share_post_template?.templateImage
              }
              alt={t(`quickSocialTemplate.selectedTemplate`)}
              backgroundColor="quick-social"
              textColor="white"
              buttonText="Create Post"
            />
          )}
          {selectedImage && (
            <CreateReviewPost
              open={reviewPopUp}
              onClose={() => setReviewPopUp(false)}
              selectedImage={selectedImage}
              selectedCategories={selectedCategories}
            />
          )}
          {((!isFavoriteTemp && totalCount > 12) || (isFavoriteTemp && favTotal > 12)) && (
            <Pagination
              totalCount={!isFavoriteTemp ? totalCount : favTotal}
              rowsPerPage={pageSize}
              page={pageNumber}
              handleChangePage={handleChangePage}
              handleChangeRowsPerPage={handleChangeRowsPerPage}
              rowsPerPageOptions={[12, 24, 60, 120]}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default Template;
