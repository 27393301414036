import { Button, CircularProgress, Typography } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useLocation } from 'react-router-dom';
import { t } from 'i18next';
import history from '@history';
import EditIcon from '@mui/icons-material/Edit';
import { useSnackbar } from 'notistack';
import FuseLoading from '@fuse/core/FuseLoading';
import AddEditServices from './AddEditServices';
import manageBusinessData from '../../../../../query/manageBusiness';
import { handleApiRequest } from '../../../../../common/common';

const Services = () => {
  const location = useLocation();
  const [addServices, setAddServices] = useState(location?.state?.isBulk);
  const [suggestionServiceData, setSuggestionServiceData] = useState([]);
  const [services, setServices] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const [deleteServiceName, setDeleteServiceName] = useState();
  const [refreshIcon, setRefreshIcon] = useState(false);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [editServiceData, setEditServiceData] = useState();
  const [loading, setLoading] = useState(!location?.state?.isBulk);
  const [selectLabelOrBusiness, setSelectLabelOrBusiness] = useState(
    location?.state?.selectBusiness || []
  );

  useEffect(() => {
    if (!openDrawer) {
      setEditServiceData();
    }
  }, [openDrawer]);

  useEffect(() => {
    const fetchGoogleServices = async () => {
      try {
        const payload = {
          query: manageBusinessData.getServicesSuggestionsForGoogle,
          variables: {
            businessId: selectLabelOrBusiness?.[0]?.id,
          },
        };
        const result = await handleApiRequest(payload);
        if (result?.getServicesSuggestionsForGoogle?.status === 200) {
          setSuggestionServiceData(result?.getServicesSuggestionsForGoogle?.data);
        }
      } catch (error) {
        console.log('An error occurred while fetching service suggestions', error);
      }
    };
    fetchGoogleServices();
  }, [selectLabelOrBusiness]);

  const fetchActiveGoogleServices = useCallback(async () => {
    setLoading(true);
    try {
      const payload = {
        query: manageBusinessData.getActiveGoogleServices,
        variables: {
          businessId: Number(selectLabelOrBusiness?.[0]?.id),
        },
      };
      const result = await handleApiRequest(payload);
      setLoading(false);
      if (result?.getActiveGoogleServices?.status === 200) {
        setServices(result?.getActiveGoogleServices?.data?.customServices);
        setAddServices(!(result?.getActiveGoogleServices?.data?.customServices?.length > 0));
      } else {
        enqueueSnackbar('Failed to fetch active Google services.', {
          variant: 'error',
          autoHideDuration: 3000,
        });
      }
    } catch (error) {
      enqueueSnackbar(
        'An error occurred while fetching active Google services. Please try again.',
        {
          variant: 'error',
          autoHideDuration: 3000,
        }
      );
      console.error('Error in fetchActiveGoogleServices:', error);
    }
  }, [enqueueSnackbar, selectLabelOrBusiness]);

  useEffect(() => {
    if (!location?.state?.isBulk) {
      fetchActiveGoogleServices();
    }
  }, [fetchActiveGoogleServices, location?.state?.isBulk]);

  const deleteService = async (name, isCustomService) => {
    setRefreshIcon(true);
    setDeleteServiceName(name);
    try {
      const payload = {
        query: manageBusinessData.addAndDeleteServicesInGoogle,
        variables: {
          businessId: Number(selectLabelOrBusiness?.[0]?.id),
          locationId: selectLabelOrBusiness?.[0]?.locationId?.split('/')[1],
          data: {
            deleteServices: [
              {
                service: name,
                isCustom: isCustomService,
              },
            ],
          },
        },
      };
      const result = await handleApiRequest(payload);
      setRefreshIcon(false);
      if (result?.addAndDeleteServicesInGoogle?.status === 200) {
        fetchActiveGoogleServices();
        setDeleteServiceName('');
        enqueueSnackbar(result?.addAndDeleteServicesInGoogle?.message, {
          variant: 'success',
          autoHideDuration: 3000,
        });
      } else {
        enqueueSnackbar(result?.addAndDeleteServicesInGoogle?.message, {
          variant: 'error',
          autoHideDuration: 3000,
        });
      }
    } catch (error) {
      setRefreshIcon(false);
      console.error('Error in deleteService:', error);
    }
  };

  const handleEdit = (data) => {
    setEditServiceData(data);
    setOpenDrawer(true);
    setAddServices(true);
  };

  if (loading) {
    return <FuseLoading />;
  }

  return (
    <div className="bg-gray-A500 h-full p-16 sm:p-24">
      {(!addServices || openDrawer) && services?.length > 0 && !location?.state?.isBulk && (
        <>
          <div className="flex justify-between items-center">
            <Typography className="font-semibold text-16 md:text-24 flex items-center gap-4">
              {' '}
              <Button
                className="w-16 h-16 min-w-16 min-h-16"
                onClick={() => history.push('quick-hub/primaryDetails', location?.state?.activeTab)}
              >
                <ArrowBackIcon />
              </Button>
              {t('primaryDetails.services')}
            </Typography>
            <Button
              className="rounded-md font-semibold"
              variant="contained"
              color="secondary"
              onClick={() => setAddServices(true)}
            >
              {t('primaryDetails.addServices')}
            </Button>
          </div>
          <div className="bg-white grid md:grid-cols-2 gap-24 rounded-md p-20 mt-16 w-full">
            {services?.length > 0 &&
              services?.map((service, index) => {
                return (
                  <div className="border border-solid rounded-md" key={index}>
                    <div className="p-12 flex items-center justify-between">
                      <div>
                        <Typography className="font-semibold text-16">
                          {service?.displayName}
                        </Typography>
                        <Typography className="font-semibold text-gray">
                          {service?.price?.units && (
                            <>
                              {service?.price?.currencyCode === 'INR' ? '₹' : '$'}{' '}
                              {service?.price?.units} {`${service?.price?.units ? '(Fixed)' : ''}`}
                            </>
                          )}
                        </Typography>
                      </div>
                      <div className="flex gap-16">
                        <Button
                          className="p-0 min-w-36 w-36 min-h-36 h-36 bg-gray-A500 hover:bg-gray-A500"
                          onClick={() => handleEdit(service)}
                        >
                          <EditIcon />
                        </Button>
                        <Button
                          className="p-0 min-w-36 w-36 min-h-36 h-36 bg-gray-A500 hover:bg-gray-A500"
                          onClick={() => deleteService(service?.displayName, true)}
                          disabled={refreshIcon && deleteServiceName === service?.displayName}
                        >
                          <img className="w-18" src="assets/images/quick-hub/delete.png" alt="" />
                          {refreshIcon && deleteServiceName === service?.displayName && (
                            <CircularProgress
                              className="text-quick-review absolute mx-auto"
                              size={24}
                            />
                          )}
                        </Button>
                      </div>
                    </div>
                    <hr />
                    <div className="p-12">
                      <Typography className="bg-gray-A500 p-12 font-medium text-16 rounded-md">
                        {service?.description || '-'}
                      </Typography>
                    </div>
                  </div>
                );
              })}
          </div>
        </>
      )}
      {(addServices || services?.length === 0 || location?.state?.isBulk) && (
        <AddEditServices
          setAddServices={setAddServices}
          setSelectLabelOrBusiness={setSelectLabelOrBusiness}
          selectLabelOrBusiness={selectLabelOrBusiness}
          services={services}
          openDrawer={openDrawer}
          setOpenDrawer={setOpenDrawer}
          editServiceData={editServiceData}
          setEditServiceData={setEditServiceData}
          fetchActiveGoogleServices={fetchActiveGoogleServices}
        />
      )}
    </div>
  );
};

export default Services;
