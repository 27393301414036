import { Button, Typography } from '@mui/material';
import { selectDashboardData } from 'app/store/userSlice';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Slider from 'react-slick';
import history from '@history';
import { useSelector } from 'react-redux';
import businessData from '../../query/business';
import { handleApiRequest } from '../../common/common';

const DashboardAlerts = () => {
  const [cardData, setCardData] = useState([]);
  const userCurrentBusinessDetails = useSelector(selectDashboardData);
  const params = useParams();

  const newSettings = {
    dots: true,
    infinite: cardData?.length > 1,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    responsive: [
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
    ],
  };

  useEffect(() => {
    const fetchCardData = async () => {
      try {
        const payload = {
          query: businessData.getBusinessWhatsNewCardV1,
          variables: {
            businessId: parseInt(userCurrentBusinessDetails?.id, 10),
            productTypeId: 9,
          },
        };
        const result = await handleApiRequest(payload);
        if (
          result?.getBusinessWhatsNewCardV1?.status === 200 &&
          result.getBusinessWhatsNewCardV1?.data
        ) {
          setCardData(JSON.parse(result.getBusinessWhatsNewCardV1?.data?.cardJson));
        }
      } catch (error) {
        console.error('Error fetching card data:', error);
      }
    };

    if (userCurrentBusinessDetails?.id) {
      fetchCardData();
    }
  }, [userCurrentBusinessDetails?.id]);

  return (
    <>
      {cardData?.length > 0 && (
        <div className="bg-white rounded-md relative shadow p-10 sm:p-16 w-full">
          <Slider {...newSettings}>
            {cardData?.map(
              (item, index) =>
                item && (
                  <div className="pr-0 md:pr-16" key={index}>
                    <div
                      className="flex gap-16 items-center justify-between w-full min-h-144 max-h-144 sm:min-h-200 sm:max-h-200 p-20 py-16 rounded-md"
                      style={{ backgroundColor: item?.backgroundColor }}
                    >
                      <div>
                        <Typography
                          className="font-semibold text-20"
                          style={{ color: item?.textColor }}
                        >
                          {item?.description}
                        </Typography>
                        <Button
                          className="rounded-md text-quick-chat max-w-112 min-w-112 sm:max-w-120 sm:min-w-120 mt-20"
                          style={{
                            backgroundColor: item?.buttonColor,
                            '--hover-bg': item?.buttonColor,
                            color: item?.buttonTextColor,
                          }}
                          onClick={() => {
                            const path = `/${item?.portalURL}/${params?.id || ''}`;
                            history.push(
                              { pathname: path },
                              (item?.cardName === 'quickChatImproveBotReply' ||
                                item?.cardName === 'chatBotConversation') &&
                                item
                            );
                          }}
                        >
                          {item?.ctaButton}
                        </Button>
                      </div>
                      <div>
                        <img
                          className="hidden w-80 sm:w-112 border-2 border-white rounded-12 p-2 sm:block"
                          src={item?.icon}
                          alt="icon"
                        />
                      </div>
                    </div>
                  </div>
                )
            )}
          </Slider>
        </div>
      )}
    </>
  );
};

export default DashboardAlerts;
