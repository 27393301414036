import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import history from '@history';

const WelCome = () => {
  const [message, setMessage] = useState('Welcome to Quick Hub');

  useEffect(() => {
    const messageTimeout = setTimeout(() => {
      setMessage(
        "We're thrilled to have you on board! Get ready to simplify your business and unlock new growth opportunities."
      );
    }, 3000);

    const redirectTimeout = setTimeout(() => {
      history.push('/business/dashboard');
    }, 8000);

    return () => {
      clearTimeout(messageTimeout);
      clearTimeout(redirectTimeout);
    };
  }, []);

  return (
    <div className="relative w-full h-screen overflow-hidden">
      <video className="absolute top-0 left-0 w-full h-full object-cover" autoPlay loop muted>
        <source src="assets/images/landing/welcome.mp4" type="video/mp4" />
      </video>
      <div className="relative z-10 flex items-center justify-center h-full bg-black bg-opacity-50 px-6 text-center">
        <motion.h1
          className="text-white text-4xl font-bold max-w-640"
          key={message}
          initial={{ opacity: 0, y: 10 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: -10 }}
          transition={{ duration: 1 }}
        >
          {message}
        </motion.h1>
      </div>
    </div>
  );
};

export default WelCome;
