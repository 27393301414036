import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Button from '@mui/material/Button';
import { getAllUserBusinessSubscription, selectDashboardData } from 'src/app/store/userSlice';
import FuseLoading from '@fuse/core/FuseLoading';
import { useLocation, useParams } from 'react-router-dom';
import history from '@history';
import { Typography } from '@mui/material';
import CommonFreeTrial from 'src/app/main/CommonComponent/CommonFreeTrial';
import DashBoardPopUp from 'src/app/main/CommonComponent/DashBoardPopUp';
import {
  addExpiryToProductArray,
  defaultProductArr,
  findExpireDay,
  getFilteredData,
  handleApiRequest,
  transformUserSubscriptionData,
} from '../../../common/common';
import quickHub from '../../../query/quickHub';
import CreateAndRecentCampaigns from './CreateAndRecentCampaigns';
import CommonPendingTask from '../../../CommonComponent/CommonPendingTask';
import DashboardAlerts from './DashboardAlert';

function Dashboards() {
  const { t } = useTranslation();
  const params = useParams();
  const userCurrentBusinessDetails = useSelector(selectDashboardData);
  const userSubscriptionData = useSelector(getAllUserBusinessSubscription);
  const [loading, setLoading] = useState(true);
  const [campaignsDataPointData, setCampaignsDataPointData] = useState([]);
  const [productData, setProductData] = useState([]);

  const filteredData = userSubscriptionData?.filter(
    (subscription) => subscription?.userBusinessId === userCurrentBusinessDetails?.id
  );
  const shouldShowPopup = productData?.some(
    (item) => findExpireDay(item?.expireDate) < 0 && item?.name === 'Quick Campaigns'
  );

  useEffect(() => {
    const transformedData = transformUserSubscriptionData(
      userSubscriptionData,
      userCurrentBusinessDetails
    );
    if (!transformedData) return;

    const filterData = getFilteredData(transformedData);
    const updatedProducts = addExpiryToProductArray(filterData, defaultProductArr);

    setProductData((prev) => {
      if (JSON.stringify(prev) !== JSON.stringify(updatedProducts)) {
        return updatedProducts;
      }
      return prev;
    });
  }, [userSubscriptionData, userCurrentBusinessDetails?.id, userCurrentBusinessDetails]);

  useEffect(() => {
    const getCampaignsDataPoint = async () => {
      setLoading(true);
      try {
        const payloadData = {
          query: quickHub.getQuickCampaignDataPoint,
          variables: {
            businessId: Number(userCurrentBusinessDetails?.id),
          },
        };
        const resultData = await handleApiRequest(payloadData);
        if (resultData?.getQuickCampaignDataPoint?.status === 200) {
          setCampaignsDataPointData(resultData?.getQuickCampaignDataPoint?.data);
        } else {
          console.error('Unexpected status:', resultData?.getQuickCampaignDataPoint?.status);
        }
      } catch (error) {
        console.error('Error fetching suggestion posts:', error);
      } finally {
        setLoading(false);
      }
    };
    if (userCurrentBusinessDetails?.id) {
      getCampaignsDataPoint();
    }
  }, [userCurrentBusinessDetails]);

  if (loading) {
    return <FuseLoading />;
  }
  return (
    <div className="lg:p-24 p-16 h-full l-padding">
      <div className="flex flex-col gap-32">
        <CommonFreeTrial
          callFrom="quickCampaigns"
          productName="Quick Campaigns"
          packageBundle={10}
        />
        <div className="grid xl:grid-cols-3 lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-2 grid-cols-1 gap-x-0 sm:gap-x-32 gap-y-28">
          <div className="col-span-2">
            <div className="flex flex-col gap-32">
              <CommonPendingTask
                productType="quick_campaigns"
                bgColor="quick-campaign"
                buttonColor="quickCampaign"
                videoImg="/assets/images/icon/playVideo-campaigns.svg"
              />
              {campaignsDataPointData?.length > 0 && (
                <div>
                  <p className="text-24 mb-10 font-700">{t('quickHubDashboard.matrices')}</p>
                  <div className="w-full grid grid-cols-1 sm:grid-cols-2 gap-10 lg:grid-cols-3">
                    {campaignsDataPointData?.map((itemData, index) => (
                      <div
                        key={index}
                        className="w-full p-14 flex items-center  h-144 rounded-4 justify-between space-y-4"
                        style={{ backgroundColor: itemData?.backgroundColor }}
                      >
                        <div>
                          {itemData?.key === 'Open Rate' || itemData?.key === 'Click Rate' ? (
                            <h1 className="text-28 sm:text-36 font-600">{itemData?.value} %</h1>
                          ) : (
                            <h1 className="text-28 sm:text-36 font-600">{itemData?.value}</h1>
                          )}
                          <p className="text-14 sm:text-20 font-600">{itemData?.key}</p>
                        </div>
                        <div className="rounded-full flex items-center justify-center  bg-white w-80 h-80">
                          <img src={itemData?.icon} alt={itemData?.name} />
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </div>
          </div>

          <div className="flex flex-col gap-32">
            <DashboardAlerts loading={loading} />
            <div className="bg-quick-campaign rounded-md relative shadow p-28 py-36 w-full">
              <div className="">
                <div className="flex items-center  w-full max-h-200 rounded-md">
                  <div className="w-2/3 flex flex-col gap-16 justify-center">
                    <Typography className="text-white font-500 text-14 md:text-16">
                      {t('quickCampaigns.createCampaignsDesc')}
                    </Typography>
                    <div>
                      <Button
                        className="rounded-md text-quick-campaign text-12 px-5 md:px-7 md:text-16 bg-white hover:bg-white mt-8"
                        variant="contained"
                        onClick={() =>
                          history.push(`quick-campaign/template/${params?.id ? params.id : ''}`)
                        }
                        aria-label="Edit"
                      >
                        <span className="text-16 md:text-24 mr-2 md:mr-5">+</span>
                        {t('quickCampaigns.createCampaigns')}
                      </Button>
                    </div>
                  </div>
                  <div className="w-1/3 flex justify-center">
                    <img
                      className="block rounded w-60 md:w-112"
                      name="logo"
                      src="/assets/images/icon/createCampaigns.svg"
                      alt="bell notification icon"
                    />
                  </div>
                </div>
              </div>
            </div>
            <CreateAndRecentCampaigns loading={loading} />
          </div>
        </div>
      </div>
      {shouldShowPopup && (
        <DashBoardPopUp
          open={shouldShowPopup}
          productData={productData}
          filteredData={filteredData}
          from="Quick Campaigns"
          productId={10}
        />
      )}
    </div>
  );
}
export default Dashboards;
