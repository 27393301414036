import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import ReactApexChart from 'react-apexcharts';
import { useTranslation } from 'react-i18next';
import PersonIcon from '@mui/icons-material/Person';
import { selectDashboardData } from 'src/app/store/userSlice';
import metricsData from '../../query/metrics';
import { reviewFormate, getDataToServer } from '../../common/common';
import NoData from './noDataScreen';

function ReviewDistribution(props) {
  const { reviewPlatformId, placeId, color, categories, useArea } = props;
  const { t } = useTranslation();
  const userCurrentBusinessDetails = useSelector(selectDashboardData);
  const [newGraphData, setNewGraphData] = useState([]);

  useEffect(() => {
    try {
      const fetchData = async () => {
        if (
          (reviewPlatformId &&
            (parseInt(reviewPlatformId, 10) === 2 ||
              parseInt(reviewPlatformId, 10) === 3 ||
              parseInt(reviewPlatformId, 10) === 4 ||
              parseInt(reviewPlatformId, 10) === 7 ||
              parseInt(reviewPlatformId, 10) === 8)) ||
          (userCurrentBusinessDetails?.isOnlineBusiness && placeId && reviewPlatformId)
        ) {
          const payload = {
            query: metricsData.getReviewRatingDistributionV1,
            variables: {
              reviewPlatformId: parseInt(reviewPlatformId, 10),
            },
          };
          if (parseInt(reviewPlatformId, 10) === 5 || parseInt(reviewPlatformId, 10) === 6) {
            payload.variables.placeId = placeId;
          } else {
            payload.variables.businessId = parseInt(userCurrentBusinessDetails?.id, 10);
          }
          const result = await getDataToServer(payload);
          const data = result?.getReviewRatingDistributionV1?.data?.reviewsDistributionJsonV1;
          if (result?.getReviewRatingDistributionV1?.data) {
            setNewGraphData([
              data?.find((item) => item.key === 'fiveStar')?.value,
              data?.find((item) => item.key === 'fourStar')?.value,
              data?.find((item) => item.key === 'threeStar')?.value,
              data?.find((item) => item.key === 'twoStar')?.value,
              data?.find((item) => item.key === 'oneStar')?.value,
            ]);
          } else {
            setNewGraphData('');
          }
        } else if (
          userCurrentBusinessDetails?.isOnlineBusiness &&
          !placeId &&
          (parseInt(reviewPlatformId, 10) === 5 || parseInt(reviewPlatformId, 10) === 6)
        ) {
          setNewGraphData([]);
        }
      };
      fetchData();
    } catch (error) {
      console.error('An error occurred:', error);
    }
  }, [
    userCurrentBusinessDetails?.id,
    reviewPlatformId,
    placeId,
    userCurrentBusinessDetails?.isOnlineBusiness,
  ]);

  function getChartSimple(data, index, types) {
    const chart = {
      series: [
        {
          data,
        },
      ],
      options: {
        grid: {
          strokeDashArray: 3,
          show: false,
          forceNiceScale: false,
          padding: {
            bottom: 0,
          },
        },
        chart: {
          events: {
            mounted: (charts) => {
              charts.windowResizeHandler();
            },
          },
          type: types,
          stacked: true,
          parentHeightOffset: 0,
          zoom: {
            enabled: false,
          },
          toolbar: {
            show: false,
          },
        },
        plotOptions: {
          bar: {
            horizontal: true,
            borderRadius: 5,
            columnWidth: '100%',
            barHeight: useArea === 'dashboard' ? '50%' : '70%',
            distributed: true,
            borderRadiusApplication: 'around',
            colors: {
              backgroundBarColors: ['#F8F8FA'],
              backgroundBarOpacity: 1,
              backgroundBarRadius: 5,
            },
            dataLabels: {
              position: 'center',
              maxItems: 100,
              hideOverflowingLabels: false,
              formatter(val) {
                return `${val}%`;
              },
              total: {
                enabled: false,
                offsetX: -40,
                offsetY: 5,
                formatter(val) {
                  return `${val}%`;
                },
                style: {
                  color: '#373d3f',
                  fontSize: '12px',
                  fontWeight: 600,
                },
              },
            },
          },
        },
        legend: {
          show: false,
          markers: {
            radius: 12,
          },
        },
        fill: {
          colors: color,
          opacity: 1,
        },
        colors: color,
        dataLabels: {
          enabled: false,
          style: {
            colors: ['#000'],
          },
          offsetX: 50,
        },
        xaxis: {
          axisBorder: {
            show: false,
          },
          categories,
          show: false,
          axisTicks: {
            show: false,
          },
          labels: {
            show: false,
            offsetY: 0,
          },
          max: 100,
        },
        yaxis: {
          show: true,
          axisTicks: {
            show: false,
          },
          labels: {
            show: true,
            align: 'left',
            maxWidth: 350,
            position: 'left',
            offsetX: -15,
            style: {
              fontSize: '14px',
            },
          },
        },
        tooltip: {
          enabled: true,
          y: {
            formatter(val) {
              return `${val}%`;
            },
            title: {
              formatter() {
                return '';
              },
            },
          },
        },
      },
    };

    return (
      <div id={`chart${index}`} className="flex flex-col flex-auto w-full" key={`chart-${index}`}>
        <ReactApexChart
          options={chart?.options}
          series={chart?.series}
          type={chart?.options?.chart?.type}
          height="300px"
          width="100%"
        />
      </div>
    );
  }
  return (
    <div>
      {newGraphData && newGraphData?.length > 0 ? (
        <>
          {useArea === 'metrics' && (
            <>
              <div className="bg-grey-100 rounded-md text-18 font-semibold text-center py-8">
                {props?.averageRating} {t('metrics.outOf')}{' '}
                <span className="inline-block ml-5">{reviewFormate(props?.averageRating)}</span>
              </div>
              <div className="text-16 font-semibold text-center mt-16">
                {props?.totalReview}{' '}
                <span className="text-grey-700">{t('metrics.customerReviews')}</span>
              </div>
            </>
          )}
          {useArea === 'dashboard' && (
            <div className="flex flex-col sm:flex-row items-center justify-center gap-24 mt-8">
              <div className="bg-grey-100 max-w-192 min-w-216 rounded-md text-18 font-semibold text-center py-8">
                <span className="inline-block ml-5">
                  {reviewFormate(props?.averageRating)} {props?.averageRating}
                </span>
              </div>
              <div className="bg-grey-100 max-w-144 min-w-144 rounded-md text-18 font-semibold text-center py-8">
                <PersonIcon className="mr-4" /> {props?.totalReview}{' '}
              </div>
            </div>
          )}
          <>{getChartSimple(newGraphData, 1, 'bar')}</>
        </>
      ) : (
        <NoData />
      )}
    </div>
  );
}

export default ReviewDistribution;
