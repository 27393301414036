import PropTypes from 'prop-types';
import withReducer from 'app/store/withReducer';
import { useEffect, useState, useRef, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Typography from '@mui/material/Typography';
import Input from '@mui/material/Input';
import MenuItem from '@mui/material/MenuItem';
import Paper from '@mui/material/Paper';
import Icon from '@mui/material/Icon';
import Table from '@mui/material/Table';
import Select from '@mui/material/Select';
import TableBody from '@mui/material/TableBody';
import { useTranslation } from 'react-i18next';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import DeleteIcon from '@mui/icons-material/Delete';
import Toolbar from '@mui/material/Toolbar';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import { motion } from 'framer-motion';
import FuseLoading from '@fuse/core/FuseLoading';
import history from '@history';
import { useSnackbar } from 'notistack';

import convertedBusinessData from '../../query/convertedBusiness';
import {
  getConvertedAccountSalesPerson,
  resendConvertedUserUsernameAndPasswordBySalesPerson,
  resetConvertedBusiness,
} from './store/convertedBusinessSlice';
import reducer from './store';
import { formatDateWithTimeZone, getEncryptedData } from '../../common/common';

function ConvertedBusiness() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const trialLabel = t('business.convertedBusiness.labels.premium');
  const basicLabel = t('business.convertedBusiness.labels.basic');
  const premiumLabel = t('business.convertedBusiness.labels.premium');

  const convertedBusinessList = useSelector(
    ({ convertedBusinessReducer }) =>
      convertedBusinessReducer?.convertedBusiness?.getConvertedAccountSalesPersonData?.data
        ?.getConvertedAccountSalesPerson?.data
  );
  const totalUser = useSelector(
    ({ convertedBusinessReducer }) =>
      convertedBusinessReducer?.convertedBusiness?.getConvertedAccountSalesPersonData?.data
        ?.getConvertedAccountSalesPerson?.totalCount || ''
  );
  const resendConvertedUserUsernameAndPasswordStatus = useSelector(
    ({ convertedBusinessReducer }) =>
      convertedBusinessReducer?.convertedBusiness
        ?.resendConvertedUserUsernameAndPasswordBySalesPersonData?.data
        ?.resendConvertedUserUsernameAndPasswordBySalesPerson
  );
  const sortedBusinessList = convertedBusinessList?.slice().sort((a, b) => {
    const dateA = new Date(a?.users?.demoAccountConvertedCts);
    const dateB = new Date(b?.users?.demoAccountConvertedCts);

    return dateB - dateA;
  });
  const [loading, setLoading] = useState(true);
  const [loadingPage, setLoadingPage] = useState(false);
  const [refreshIcon, setRefreshIcon] = useState(false);
  const [searchLoading, setSearchLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchValue, setSearchValue] = useState('');
  const [selected, setSelected] = useState([]);
  const [statusValue, setStatusValue] = useState('all');
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [actionValues, setActionValues] = useState({});
  useEffect(() => {
    let unmounted = false;
    const payload = {
      query: convertedBusinessData.getConvertedAccountSalesPerson,
      variables: {
        searchText: '',
        pageNumber: 1,
        pageSize: 10,
      },
    };
    dispatch(getConvertedAccountSalesPerson(payload));

    return () => {
      unmounted = true;
      dispatch(resetConvertedBusiness());
    };
  }, [dispatch]);

  useEffect(() => {
    if (sortedBusinessList || sortedBusinessList === null) {
      setLoading(false);
    } else {
      setLoading(true);
    }
  }, [sortedBusinessList]);
  useEffect(() => {
    if (
      resendConvertedUserUsernameAndPasswordStatus?.status === 400 ||
      resendConvertedUserUsernameAndPasswordStatus?.status === 404
    ) {
      enqueueSnackbar(resendConvertedUserUsernameAndPasswordStatus?.message, {
        variant: 'error',
        autoHideDuration: 3000,
      });
      setRefreshIcon(false);
      closeSnackbar(3000);
    } else if (resendConvertedUserUsernameAndPasswordStatus?.status === 200) {
      enqueueSnackbar(resendConvertedUserUsernameAndPasswordStatus?.message, {
        variant: 'success',
        autoHideDuration: 3000,
      });
      setRefreshIcon(false);
      closeSnackbar(3000);
    }
  }, [resendConvertedUserUsernameAndPasswordStatus, enqueueSnackbar, closeSnackbar]);
  function commonGetData(searchValues = '') {
    setSearchValue(searchValues);
    const payload = {
      query: convertedBusinessData.getConvertedAccountSalesPerson,
      variables: {
        searchText: searchValues,
        pageNumber: page,
        pageSize: rowsPerPage,
      },
    };
    if (statusValue !== 'all') {
      payload.variables.status = statusValue;
    }
    dispatch(getConvertedAccountSalesPerson(payload));
  }

  function handleSearchEvent(event) {
    setSearchLoading(true);
    setPage(1);
    const searchText = event.target.value;
    commonGetData(searchText);
    setTimeout(() => {
      setSearchLoading(false);
    }, 800);
  }

  const clearSearch = async () => {
    await setPage(1);
    await setSearchValue('');
    await commonGetData();
  };

  function handleChangePage(event, value) {
    setPage(value + 1);
    setLoadingPage(true);
    const payload = {
      query: convertedBusinessData.getConvertedAccountSalesPerson,
      variables: {
        searchText: searchValue,
        pageNumber: value + 1,
        pageSize: rowsPerPage,
      },
    };
    dispatch(async (dispatchValue) => {
      await dispatchValue(getConvertedAccountSalesPerson(payload));
      setLoadingPage(false);
    });
  }

  function handleChangeRowsPerPage(event) {
    setLoadingPage(true);
    setRowsPerPage(event.target.value);
    setPage(1);
    const payload = {
      query: convertedBusinessData.getConvertedAccountSalesPerson,
      variables: {
        searchText: searchValue,
        pageNumber: 1,
        pageSize: event.target.value,
      },
    };
    dispatch(async (dispatchValue) => {
      await dispatchValue(getConvertedAccountSalesPerson(payload));
      setLoadingPage(false);
    });
  }
  async function resetPasswordFunction(id) {
    setRefreshIcon(true);
    const encrypted = JSON.stringify({
      userId: id,
    });

    const payload = {
      query: convertedBusinessData.resendConvertedUserUsernameAndPasswordBySalesPerson,
      variables: { data: getEncryptedData(encrypted) },
    };
    setTimeout(() => {
      dispatch(resendConvertedUserUsernameAndPasswordBySalesPerson(payload));
    }, 1000);
  }

  function handleStatusDropdownChange(event) {
    const statusData = event.target.value;
    setPage(1);
    setStatusValue(statusData);
    setLoading(true);
    const payloadData = {
      query: convertedBusinessData.getConvertedAccountSalesPerson,
      variables: {
        pageSize: rowsPerPage,
        pageNumber: 1,
        searchText: searchValue,
      },
    };
    dispatch(getConvertedAccountSalesPerson(payloadData));
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }
  function EnhancedTableToolbar(props) {
    const { numSelected } = props;
    if (numSelected > 0) {
      return (
        <Toolbar className="absolute left-0 right-0 -top-80 bg-black text-white z-10 rounded-md">
          {numSelected > 0 ? (
            <Typography
              sx={{ flex: '1 1 100%' }}
              color="inherit"
              variant="subtitle1"
              component="div"
              className="text-white"
            >
              {numSelected} Selected
            </Typography>
          ) : (
            ''
          )}

          {numSelected > 0 ? (
            <Tooltip title="Delete">
              <IconButton className="text-white">
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          ) : (
            ''
          )}
        </Toolbar>
      );
    }
  }

  EnhancedTableToolbar.propTypes = {
    numSelected: PropTypes.number.isRequired,
  };
  function handleActionDropdownChange(event, rowId) {
    setActionValues((prevValues) => ({
      ...prevValues,
      [rowId]: event.target.value,
    }));
  }
  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = convertedBusinessList.map((n) => n.id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;

  function EnhancedTableHead(props) {
    const { onSelectAllClick, numSelected, rowCount } = props;

    return (
      <TableHead className="bg-grey-50 rounded-md hidden md:table-header-group">
        <TableRow>
          {/* <TableCell padding="checkbox" className="rounded-tl-md">
            <Checkbox
              color="primary"
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={onSelectAllClick}
              inputProps={{
                'aria-label': 'select all User',
              }}
            />
          </TableCell> */}
          <TableCell className="text-16 font-medium" align="left">
            <TableSortLabel hideSortIcon>
              {t('business.convertedBusiness.column.srNo')}
            </TableSortLabel>
          </TableCell>
          <TableCell className="text-16 font-medium" align="left">
            <TableSortLabel hideSortIcon>{t('common.businessName')}</TableSortLabel>
          </TableCell>
          <TableCell className="text-16 font-medium" align="left">
            <TableSortLabel hideSortIcon>
              {t('business.convertedBusiness.column.convertedDate')}
            </TableSortLabel>
          </TableCell>
          <TableCell className="text-16 font-medium" align="left">
            <TableSortLabel hideSortIcon>{t('agencyBusiness.clients.plan')}</TableSortLabel>
          </TableCell>
          <TableCell className="text-16 font-medium rounded-tr-md">
            <TableSortLabel hideSortIcon>{t('common.action')}</TableSortLabel>
          </TableCell>
        </TableRow>
      </TableHead>
    );
  }

  if (loading) {
    return <FuseLoading />;
  }

  function redirectBusinessDetailsRoutes(id, userId) {
    return history.push({
      pathname: `/converted-business-details/${id}`,
    });
  }

  return (
    <div className="p-20 lg:p-24">
      {loadingPage && (
        <div className="loader-container fixed top-0 left-0 w-full h-full flex justify-center items-center">
          <div className="loader h-40 w-40 rounded-full border-4 border-solid border-white border-t-darkgreen" />
        </div>
      )}
      <div className="md:flex flex-1 w-full items-center justify-between md:mb-36 mb-20">
        <div className="flex items-center">
          <Typography
            component={motion.span}
            initial={{ x: -20 }}
            animate={{ x: 0, transition: { delay: 0.2 } }}
            delay={300}
            className="sm:flex text-20 md:text-28 font-bold mb-16 md:mb-0"
          >
            {t('dashboard.dashboardSales.convertedBusiness')}
          </Typography>
        </div>
        <div className="flex flex-1 items-center justify-center px-0 md:px-12">
          <Paper
            component={motion.div}
            initial={{ y: -20, opacity: 0 }}
            animate={{ y: 0, opacity: 1, transition: { delay: 0.2 } }}
            className="flex items-center w-full md:max-w-512 px-8 rounded-8 shadow-none"
          >
            <Input
              placeholder={t('business.visitedBusiness.labels.searchBusinessName')}
              className="flex flex-1 mx-8 text-black text-16"
              disableUnderline
              name="searchText"
              fullWidth
              inputProps={{
                'aria-label': 'Search',
              }}
              onChange={handleSearchEvent}
              value={searchValue}
            />
            {searchValue ? (
              <Icon
                color="action"
                className="cursor-pointer"
                onClick={() => {
                  clearSearch();
                }}
              >
                close
              </Icon>
            ) : (
              <Icon color="action">search</Icon>
            )}
          </Paper>
        </div>
        <div className="md:flex md:flex-1" />
        {/* <div className="items-center justify-end flex">
          <Select
            className="rounded-8 h-40 bg-white text-16 w-full min-w-200 leading-5 shadow-none mr-16"
            variant="filled"
            sx={{ '& .MuiFilledInput-input': { pt: '8px' } }}
            value={statusValue}
            fullWidth
            onChange={handleStatusDropdownChange}
            // IconComponent={() => (
            //   <Icon className="text-20 align-middle absolute right-14 text-black ml-2">
            //     filter_list
            //   </Icon>
            // )}
          >
            <MenuItem value="all" className="capitalize">
              All
            </MenuItem>
            <MenuItem value="pending" className="capitalize">
              Pending
            </MenuItem>
            <MenuItem value="in_progress" className="capitalize">
              Set Up In Progress
            </MenuItem>
            <MenuItem value="reachable" className="capitalize">
              Ready To Demo
            </MenuItem>
          </Select>
        </div> */}
      </div>
      <div className="bg-white rounded-md relative shadow">
        {searchLoading ? (
          <div color="textSecondary" className="text-16 py-12 text-center font-semibold">
            {t('business.searchBusiness.labels.loading')}
          </div>
        ) : (
          <>
            {sortedBusinessList && sortedBusinessList?.length > 0 && (
              <>
                <EnhancedTableToolbar numSelected={selected.length} />
                <TableContainer>
                  <Table className="w-full" aria-labelledby="tableTitle" size="medium">
                    <EnhancedTableHead
                      numSelected={selected.length}
                      onSelectAllClick={handleSelectAllClick}
                      rowCount={convertedBusinessList.length}
                    />
                    <TableBody className="block md:table-row-group">
                      {sortedBusinessList &&
                        sortedBusinessList.length > 0 &&
                        sortedBusinessList.map((row, index) => {
                          const isItemSelected = isSelected(row.id);
                          const labelId = `enhanced-table-checkbox-${index}`;

                          return (
                            <TableRow
                              hover
                              className="border border-t-2 border-t-grey-100 block md:table-row"
                              role="checkbox"
                              aria-checked={isItemSelected}
                              tabIndex={-1}
                              key={row.id}
                              selected={isItemSelected}
                            >
                              {/* <TableCell
                            padding="checkbox"
                            onClick={(event) => handleClick(event, row.id)}
                            sx={{ cursor: 'pointer' }}
                          >
                            <Checkbox
                              color="primary"
                              checked={isItemSelected}
                              inputProps={{
                                'aria-labelledby': labelId,
                              }}
                            />
                          </TableCell> */}
                              <TableCell
                                className="text-14 color-black font-semibold block md:table-cell py-5 pt-16 md:py-16"
                                align="left"
                              >
                                {(page - 1) * rowsPerPage + index + 1}
                              </TableCell>
                              <TableCell
                                className="text-14 color-black font-semibold md:max-w-136 block md:table-cell py-5 md:py-16"
                                align="left"
                              >
                                {row?.name}
                              </TableCell>
                              <TableCell
                                className="text-14 color-black font-semibold md:max-w-136 block md:table-cell py-5 md:py-16"
                                align="left"
                              >
                                <span className="inline-block align-middle md:hidden mr-10 font-bold text-darkgreen">
                                  {t('business.convertedBusiness.column.convertedDate')}:
                                </span>
                                {row?.users?.demoAccountConvertedCts
                                  ? formatDateWithTimeZone(
                                      row?.users?.demoAccountConvertedCts,
                                      'MMM DD, YYYY'
                                    )
                                  : '-'}
                              </TableCell>
                              <TableCell
                                className={`text-14 color-black font-semibold md:max-w-136 block md:table-cell py-5 md:py-16 ${
                                  row?.users?.user_package_subscription[0]?.packages?.package_types
                                    ?.name === trialLabel
                                    ? 'text-darkgreen-500'
                                    : row?.users?.user_package_subscription[0]?.packages
                                        ?.package_types?.name === basicLabel
                                    ? 'text-blue'
                                    : row?.users?.user_package_subscription[0]?.packages
                                        ?.package_types?.name === premiumLabel
                                    ? 'text-orange'
                                    : ''
                                }`}
                                align="left"
                              >
                                <span className="inline-block align-middle md:hidden mr-10 font-bold text-darkgreen">
                                  {t('agencyBusiness.clients.plan')}:
                                </span>
                                {
                                  row?.users?.user_package_subscription[0]?.packages?.package_types
                                    ?.name
                                }
                              </TableCell>
                              <TableCell
                                className="text-14 color-black md:font-semibold block md:table-cell py-5 pb-16 md:py-16"
                                align="left"
                              >
                                <Button
                                  variant="contained"
                                  color="secondary"
                                  className="md:text-14 h-28 px-16 bg-green-100 text-green font-semibold rounded hover:text-white disabled:bg-gry-400"
                                  aria-label="view"
                                  onClick={() => redirectBusinessDetailsRoutes(row.id, row.userId)}
                                  type="button"
                                  size="small"
                                >
                                  {t('common.viewDetails')}
                                </Button>
                              </TableCell>
                            </TableRow>
                          );
                        })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </>
            )}
            {sortedBusinessList && sortedBusinessList.length === 0 && searchValue !== '' && (
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1, transition: { delay: 0.1 } }}
                className="flex flex-1 items-center justify-center h-full"
              >
                <Typography color="textSecondary" variant="h5" className="text-16 py-12">
                  {t('business.convertedBusiness.validationMessage.noBusiness')}
                </Typography>
              </motion.div>
            )}
          </>
        )}
      </div>
      {!searchLoading &&
        sortedBusinessList &&
        sortedBusinessList.length === 0 &&
        searchValue === '' && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1, transition: { delay: 0.1 } }}
            className="flex flex-1 items-center justify-center h-full text-center mx-auto h-fit"
          >
            <div>
              <img
                src="/assets/images/sales-icon/converted_account.png"
                className="img-fluid mx-auto max-w-216 pb-40 lg:pt-96"
                alt="..."
              />
              <h5 className="block text-24 font-bold text-black">
                {t('business.convertedBusiness.labels.notConvertedBusiness')}
              </h5>
              <Typography
                color="textPrimary"
                variant="h5"
                className="text-15 py-12 font-semibold max-w-640"
              >
                {t('business.convertedBusiness.labels.notConvertedBusinessMessage')}
              </Typography>
              <Button
                variant="contained"
                color="secondary"
                className="md:text-18 font-semibold disabled:text-black rounded-3xl px-28 pb-6"
                aria-label={t('business.convertedBusiness.labels.visitedBusinessButton')}
                onClick={() => {
                  history.push({
                    pathname: `/visited-business`,
                  });
                }}
                type="button"
                size="small"
              >
                {t('business.convertedBusiness.labels.visitedBusinessButton')}
              </Button>
            </div>
          </motion.div>
        )}
      {sortedBusinessList?.length > 0 && (
        <TablePagination
          sx={{
            '.MuiInputBase-root': {
              lineHeight: 'inherit',
              padding: 0,
            },
          }}
          component="div"
          count={totalUser}
          rowsPerPage={rowsPerPage}
          page={page - 1}
          backIconButtonProps={{
            'aria-label': 'Previous Page',
          }}
          nextIconButtonProps={{
            'aria-label': 'Next Page',
          }}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      )}
    </div>
  );
}

export default withReducer('convertedBusinessReducer', reducer)(ConvertedBusiness);
