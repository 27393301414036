import { useEffect, useState } from 'react';
import SelectBusinessForRedeem from './SelectBusinessForRedeem';
import PromotionInfo from './PromotionInfo';

const NewRedeem = () => {
  const [selectBusiness, setSelectedBusiness] = useState();
  return (
    <div className="p-20 lg:p-24">
      <SelectBusinessForRedeem
        selectBusiness={selectBusiness}
        setSelectedBusiness={setSelectedBusiness}
      />
      <PromotionInfo selectBusiness={selectBusiness} />
    </div>
  );
};

export default NewRedeem;
