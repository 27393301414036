import { useState } from 'react';
import { formatDateWithTimeZone } from 'src/app/main/common/common';
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from '@mui/material';
import { t } from 'i18next';
import history from '@history';
import { useLocation } from 'react-router-dom';
import TableHeader from '../../../../component/table/TableHeader';

const UpcomingFollowUp = (props) => {
  const { followUpData, handleRedirect, totalCount } = props;
  const location = useLocation();
  const headerData = [
    t('common.srNo'),
    t('dashboard.dashboardSales.column.businessName'),
    t('common.phoneNumber'),
    t('dashboard.dashboardSales.column.nextFollowUp'),
    t('common.note'),
    t('common.action'),
  ];

  const handleViewAllFollowUp = () => {
    history.push('view-all-upcoming-followup');
  };

  const checkPath = location.pathname.includes('view-all-upcoming-followup');

  return (
    <div className="bg-white rounded-md relative shadow p-24">
      {!checkPath && (
        <>
          <div>
            <Typography className="font-bold text-20">
              {t('dashboard.dashboardSales.upcomingFollowUp')}
            </Typography>
          </div>
          <div className="flex justify-between pt-8">
            <Typography className="text-14 font-medium">Total {totalCount} Follow Up</Typography>
            <Button
              className="text-white bg-darkgreen px-24 hover:bg-darkgreen py-4 rounded-sm cursor-pointer"
              size="small"
              onClick={() => handleViewAllFollowUp()}
            >
              {t('dashboard.dashboardSales.viewAll')}
            </Button>
          </div>
        </>
      )}
      <TableContainer>
        <Table className="w-full" aria-labelledby="tableTitle" size="medium">
          <TableHeader headerData={headerData} headerBackgroundColorValue="white" remove="remove" />
          <TableBody className="block md:table-row-group">
            {followUpData?.length > 0 &&
              followUpData?.map((item, i) => {
                return (
                  <TableRow
                    hover
                    className="block md:table-row xs-border-dash"
                    sx={{
                      '& td, th': {
                        borderBottom: '1px dashed #949494',
                        color: '#000000',
                      },
                      '&:last-child td, &:last-child th': { border: 0 },
                    }}
                    role="checkbox"
                    key={i}
                    tabIndex={-1}
                  >
                    <TableCell
                      className="text-16 border-b-1 pb-2 py-10 text-black md:w-[5%] font-medium block md:table-cell py-5 md:py-16 mdp:px-16"
                      align="left"
                    >
                      {i + 1}
                    </TableCell>
                    <TableCell
                      className="text-16 border-b-1 pb-2 py-10 text-black font-medium md:w-[20%] block md:table-cell py-5 md:py-16 mdp:px-16"
                      align="left"
                    >
                      {item.businessName}
                    </TableCell>
                    <TableCell
                      className="text-16 color-black font-medium md:w-[15%] block md:table-cell py-5 md:py-16 mdp:px-16"
                      align="left"
                    >
                      {item.phoneNumber !== 0 ? `${item.countryCode} ${item.phoneNumber}` : '-'}
                    </TableCell>
                    <TableCell
                      className="text-16 color-black font-medium md:w-[15%] block md:table-cell py-5 md:py-16 mdp:px-16"
                      align="left"
                    >
                      <span className="inline-block align-middle md:hidden mr-10 font-bold text-darkgreen">
                        {t('business.selectedBusinessDetail.labels.nextFollowUpDate')}:
                      </span>
                      {formatDateWithTimeZone(item?.followUpDate, 'MMM DD, YYYY')} <br />
                      {formatDateWithTimeZone(item.followUpDate, 'h:mm A')}
                    </TableCell>
                    <TableCell
                      className="text-16 color-black font-medium md:w-[20%] block md:table-cell py-5 md:py-16 mdp:px-16"
                      align="left"
                    >
                      <span className="inline-block align-middle md:hidden mr-10 font-bold text-darkgreen">
                        {t('business.selectedBusinessDetail.labels.nextFollowUpNote')}:
                      </span>
                      {item.note}
                    </TableCell>
                    <TableCell
                      className="text-16 color-black font-medium md:w-[15%] block md:table-cell py-5 md:py-16 mdp:px-16"
                      align="start"
                    >
                      <Button
                        className="bg-[#4CAF504D] hover:bg-[#4CAF504D] text-14 font-bold px-40 text-[#4CAF50] rounded"
                        type="button"
                        onClick={() => handleRedirect(item.businessId)}
                        size="small"
                      >
                        {t('common.viewDetails')}
                      </Button>
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default UpcomingFollowUp;
