import withReducer from 'app/store/withReducer';
import { useEffect, useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { yupResolver } from '@hookform/resolvers/yup';
import Button from '@mui/material/Button';
import { useTranslation } from 'react-i18next';
import { Controller, useForm } from 'react-hook-form';
import PhoneInput from 'react-phone-input-2';
import InputLabel from '@mui/material/InputLabel';
import TextField from '@mui/material/TextField';
import CircularProgress from '@mui/material/CircularProgress';
import ReactSelect from 'react-select';
import { FixedSizeList as List } from 'react-window';
import * as yup from 'yup';
import _ from '@lodash';
import { useLocation } from 'react-router-dom';
import history from '@history';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { useSnackbar } from 'notistack';
import '../MangeBusiness.css';
import { Typography } from '@mui/material';
import queryData from '../../../query/common';
import reducer from '../store';
import UserService from '../../../../services/userService';
import {
  getParamsId,
  handleApiRequest,
  sortOptions,
  getDecryptData,
  handlePhoneNumberValidationCheck,
  fetchBusinesses,
  imageDataToServer,
} from '../../../common/common';
import manageBusinessData from '../../../query/manageBusiness';
import {
  getAllUserBusinessSubscription,
  getCountryData,
  getOnlineBusinessTypeData,
  getPhysicalBusinessTypeData,
  selectDashboardData,
  setUserBusinesses,
  setUserSubscriptionData,
} from '../../../../store/userSlice';

const defaultValues = {
  name: '',
  email: '',
  website: '',
  phone_number: '',
};

function AddBusiness(props) {
  const {
    setBusinessAdded,
    setIsBusinessSuccessFullyAdded,
    setBusinessAddInProgress,
    businessAddInProgress,
    isManual,
  } = props;
  const { t } = useTranslation();
  const schema = yup.object().shape({
    name: yup
      .string()
      .trim()
      .required(
        t('manageBusiness.addBusinessSteps.addBusiness.form.businessName.validationMessage')
      ),
    phone_number: yup.string().required(t('signInPage.enterPhoneNumber')),
    email: yup.string().email(t('common.enterAValidEmail')),
    website: yup
      .string()
      .test(
        'is-url',
        t('manageBusiness.addBusinessSteps.addBusiness.form.website.validationMessage'),
        (value) => {
          if (!value || value === '') {
            return true;
          }
          return /^(https?:\/\/)?(www\.)?[\w-]+(\.[a-z]{2,}){1,}$/i.test(value);
        }
      ),
  });
  const loginUserData = UserService.getUserData();

  const [countryList, setCountryList] = useState([]);
  const [phone, setPhone] = useState();
  const [refreshIcon, setRefreshIcon] = useState(false);
  const [phoneValue, setPhoneValue] = useState();
  const [businessType, setBusinessType] = useState([]);
  const [selectBusinessType, setSelectBusinessType] = useState();
  const [countryId, setCountryId] = useState(loginUserData?.countryId);
  const [inputValue, setInputValue] = useState('');
  const { enqueueSnackbar } = useSnackbar();
  const [selectedCountry, setSelectedCountry] = useState();
  const [errorMessage, setErrorMessage] = useState(true);
  const countriesData = useSelector(getCountryData);
  const userSubscriptionData = useSelector(getAllUserBusinessSubscription);
  const onlineBusinessTypeList = useSelector(getOnlineBusinessTypeData);
  const physicalBusinessTypeList = useSelector(getPhysicalBusinessTypeData);
  const userCurrentBusinessDetails = useSelector(selectDashboardData);
  const [imageData, setImageData] = useState({
    file: null,
    preview: null,
  });
  const location = useLocation();
  const dispatch = useDispatch();

  const { control, watch, reset, handleSubmit, formState, getValues, setError, setValue } = useForm(
    {
      mode: 'onChange',
      defaultValues,
      resolver: yupResolver(schema),
    }
  );
  const { isValid, dirtyFields, errors } = formState;

  useEffect(() => {
    setCountryList(countriesData);
  }, [countriesData]);

  useEffect(() => {
    if (isManual === 'true' && userCurrentBusinessDetails?.isManualBusiness) {
      setValue('name', userCurrentBusinessDetails?.name);
      setValue('email', userCurrentBusinessDetails?.email);
      setValue('phone_number', userCurrentBusinessDetails?.phoneNumber?.phoneNumber);
      setValue('website', userCurrentBusinessDetails?.website);
      // setSelectBusinessType(userCurrentBusinessDetails?.businessTypeId);
      setPhoneValue(
        [
          userCurrentBusinessDetails?.phoneNumber?.countryCode || '',
          userCurrentBusinessDetails?.phoneNumber?.phoneNumber || '',
        ].join('')
      );
      setCountryId(userCurrentBusinessDetails?.country);
    }
  }, [userCurrentBusinessDetails, isManual, setValue]);

  useEffect(() => {
    const ipData =
      localStorage.getItem('ipData') && JSON.parse(getDecryptData(localStorage.getItem('ipData')));

    const findCountry = countryList?.find((country) => country?.isoCode === ipData?.isoCode);
    setSelectedCountry(findCountry);
    setCountryId(findCountry?.id);
  }, [countryList]);

  const CustomMenuList = (data) => {
    const itemHeight = 35;
    const { options, children, maxHeight } = data;
    if (options.length > 0) {
      return (
        <div>
          <List height={maxHeight} itemCount={children.length} itemSize={itemHeight}>
            {({ index, style }) => (
              <div style={style} className="bg-white relative">
                {children[index]}
              </div>
            )}
          </List>
        </div>
      );
    }
    return <div className="p-16">Loading....</div>;
  };

  const fetchUserSubscriptionData = async () => {
    const payload = {
      query: queryData.getAllUserBusinessSubscription,
    };
    try {
      const res = await handleApiRequest(payload);
      if (res?.getAllUserBusinessSubscription?.status === 200) {
        const decryptedData = JSON.parse(getDecryptData(res?.getAllUserBusinessSubscription?.data));
        dispatch(setUserSubscriptionData(decryptedData));
      }
    } catch (error) {
      console.error('Failed to fetch subscription data:', error);
    }
  };

  const handleSelectBusinessType = (selectedOption) => {
    setSelectBusinessType(selectedOption ? selectedOption?.value : null);
  };

  const handleInputChange = (value) => {
    setInputValue(value);
  };

  const filteredOptions = useMemo(() => {
    return sortOptions(inputValue, businessType);
  }, [inputValue, businessType]);

  useEffect(() => {
    if (onlineBusinessTypeList?.length > 0 || physicalBusinessTypeList?.length > 0) {
      const businessTypes = businessAddInProgress.isManual
        ? physicalBusinessTypeList
        : onlineBusinessTypeList;
      const businessTypeData = businessTypes?.map((idx) => ({
        value: idx.id,
        label: idx.bType,
      }));
      setBusinessType(businessTypeData);
    }
  }, [onlineBusinessTypeList, physicalBusinessTypeList, businessAddInProgress]);

  const onSubmit = async (data) => {
    setRefreshIcon(true);
    const isFreeTrial = userSubscriptionData?.find(
      (item) => item.isFreeTrial && !item?.businessName
    );
    const businessDetail = {
      countryId: parseInt(countryId, 10),
      email: data?.email,
      name: data?.name,
      logo: imageData?.file,
      ...(isManual
        ? {
            businessId: Number(userCurrentBusinessDetails?.id),
            phoneNumberV1: {
              phoneNumber: parseInt(data?.phone_number, 10),
              countryCode: `+${phone?.dialCode}`,
              isoCode: phone?.countryCode,
            },
            isOnlineBusiness: true,
            isManualBusiness: true,
          }
        : {
            phoneNumber: {
              phoneNumber: parseInt(data?.phone_number, 10),
              countryCode: `+${phone?.dialCode}`,
              isoCode: phone?.countryCode,
            },
            isOnlineBusiness: businessAddInProgress.isOnline,
          }),
      reviewPlatformId: businessAddInProgress.isOnline
        ? 7
        : isManual && userCurrentBusinessDetails?.isManualBusiness
        ? 7
        : null,
      website: data?.website,
      reviewPlatform: isManual
        ? [
            {
              placeId: '',
              reviewPlatformId: 7,
              url: '',
            },
          ]
        : [],
      businessTypeId: parseInt(selectBusinessType, 10),
    };
    const payload = {
      query: isManual
        ? manageBusinessData.updateBusinessDetail
        : manageBusinessData.addBusinessDetailV3,
      variables: {
        businessDetail,
        ...(!isManual && {
          isQuickHubProduct: true,
          isAddedByGoogleConnect: false,
          isManualBusiness: businessAddInProgress?.isManual,
          userPackageSubscriptionId:
            location.state?.addNewBusiness === 'addNewBusiness'
              ? [location.state.usePackageSubscriptionId]
              : userSubscriptionData
                  .filter((item) => !item?.isFreeTrial)
                  .map((item) => item?.userPackageSubscriptionId) || [],
          addForFreeTrial: isFreeTrial?.isFreeTrial || false,
        }),
      },
    };
    const result = await imageDataToServer(payload);
    const res = isManual ? result?.updateBusinessDetail : result?.addBusinessDetailV3;
    setRefreshIcon(false);
    if (res?.status === 200) {
      setBusinessAdded(true);
      setIsBusinessSuccessFullyAdded(true);
      fetchUserSubscriptionData();
      const userBusinesses = await fetchBusinesses();
      await localStorage.setItem('businesses', JSON.stringify(userBusinesses));
      if (isManual) {
        dispatch(setUserBusinesses(userBusinesses));
        window.location.reload();
      }
    } else {
      enqueueSnackbar(res?.message, {
        variant: 'error',
        autoHideDuration: 3000,
      });
    }
  };

  const handlePhoneChange = (event, data, value, formattedValue) => {
    setPhoneValue(event);
    setPhone({ ...data, phoneNumber: parseInt(event.slice(data?.dialCode?.length), 10) });
    setValue('phone_number', event.slice(data?.dialCode?.length), {
      shouldDirty: true,
      shouldValidate: true,
    });
  };

  const handleSelectCountry = (event) => {
    setCountryId(event.target.value);
  };

  const handleSkipStep = () => {
    setBusinessAddInProgress({
      isPhysical: false,
      isOnline: false,
      isManual: false,
    });
    setBusinessAdded(false);
  };

  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      setImageData({
        file,
        preview: URL.createObjectURL(file),
      });
    }
  };

  return (
    <div className="text-left">
      <form noValidate onSubmit={handleSubmit(onSubmit)} className="md:overflow-hidden">
        <div className="mx-auto mt-24">
          <div className="bg-grey-50 px-20 py-16 rounded-md">
            <div className="flex justify-between border-b-1 border-solid border-grey pb-14 mb-20">
              <h4 className="text-20 font-semibold ">
                {t('manageBusiness.addBusinessSteps.addBusiness.smallTitle')}
              </h4>
            </div>
            <div className="flex items-center gap-24">
              <img
                className="w-112 h-112 rounded-md"
                src={!imageData?.preview ? 'assets/images/icon/upload.svg' : imageData?.preview}
                alt=""
              />
              <div>
                <Typography>{t('manageBusiness.businessLogo')}</Typography>
                <Typography>{t('manageBusiness.logoDescription')}</Typography>
                <div className="flex gap-12">
                  <label htmlFor="upload-input">
                    <input
                      type="file"
                      accept="image/*"
                      onChange={handleImageUpload}
                      id="upload-input"
                      className="hidden"
                    />
                    {!imageData?.preview ? (
                      <Button
                        className="rounded-md font-semibold hover:bg-white text-16 mt-6"
                        variant="outlined"
                        color="secondary"
                        component="span"
                      >
                        {t('manageBusiness.uploadLogo')}
                      </Button>
                    ) : (
                      <div>
                        <Button
                          className="p-0 w-28 min-w-28 max-w-28 hover:bg-white"
                          component="span"
                        >
                          <img src="assets/images/icon/edit.svg" alt="" />
                        </Button>
                      </div>
                    )}
                  </label>
                  {imageData?.preview && (
                    <Button
                      className="p-0 w-28 min-w-28 max-w-28 hover:bg-white"
                      onClick={() => setImageData({ file: null, preview: null })}
                    >
                      <img src="assets/images/icon/delete.svg" alt="" />
                    </Button>
                  )}
                </div>
              </div>
            </div>
            <div className="grid gap-16 md:gap-20 grid-cols-1 md:grid-cols-2 mt-16">
              <div className="block">
                <InputLabel htmlFor="name" className="text-16 font-medium mb-12 text-grey-900">
                  {t('common.businessName')}
                </InputLabel>
                <Controller
                  control={control}
                  name="name"
                  render={({ field }) => (
                    <TextField
                      {...field}
                      placeholder={t(
                        'manageBusiness.addBusinessSteps.addBusiness.form.businessName.placeHolder'
                      )}
                      size="small"
                      id="name"
                      error={!!errors.name}
                      helperText={errors?.name?.message}
                      variant="outlined"
                      required
                      fullWidth
                    />
                  )}
                />
              </div>
              <div className="block">
                <InputLabel
                  htmlFor="phone_number"
                  className="text-16 font-medium mb-12 text-grey-900"
                >
                  {t('common.phone')}
                </InputLabel>
                <PhoneInput
                  placeholder={t(
                    'manageBusiness.addBusinessSteps.addBusiness.form.phone.placeHolder'
                  )}
                  inputStyle={{
                    width: '100%',
                    height: '37px',
                  }}
                  className="w-full"
                  name="phone_number"
                  required
                  error={!!errors.phone_number}
                  helperText={errors?.phone_number?.message}
                  value={phoneValue}
                  size="small"
                  country={
                    isManual
                      ? userCurrentBusinessDetails?.phoneNumber?.isoCode?.toLowerCase()
                      : loginUserData?.phone?.isoCode || selectedCountry?.isoCode.toLowerCase()
                  }
                  enableSearch="true"
                  onChange={(value, data) => {
                    handlePhoneChange(value, data);
                    const validPhoneNumber = handlePhoneNumberValidationCheck(value, data);
                    setErrorMessage(validPhoneNumber);
                  }}
                />
                {errorMessage === false && phoneValue?.length > 0 && (
                  <p className="text-red mt-5 text-16">{t('productSettings.InvalidPhoneNumber')}</p>
                )}
              </div>
              <div className="block">
                <InputLabel htmlFor="name" className="text-16 font-medium mb-12 text-grey-900">
                  {t('manageBusiness.addBusinessSteps.addBusiness.form.businessType.name')}
                </InputLabel>
                <ReactSelect
                  labelId="businessType"
                  placeholder={t('business.selectBusinessType')}
                  id="businessType"
                  name="businessType"
                  onChange={handleSelectBusinessType}
                  onInputChange={handleInputChange}
                  value={businessType?.find((op) => {
                    return parseInt(op.value, 10) === parseInt(selectBusinessType, 10);
                  })}
                  options={filteredOptions}
                  components={{
                    MenuList: CustomMenuList,
                  }}
                />
              </div>
              <div className="block">
                <InputLabel htmlFor="email" className="text-16 font-medium mb-12 text-grey-900">
                  {t('manageBusiness.addBusinessSteps.addBusiness.form.businessEmail.name')}{' '}
                  <span className="text-14">({t('signUpPage.optional')})</span>
                </InputLabel>
                <Controller
                  control={control}
                  name="email"
                  render={({ field }) => (
                    <TextField
                      {...field}
                      placeholder={t(
                        'manageBusiness.addBusinessSteps.addBusiness.form.businessEmail.placeHolder'
                      )}
                      size="small"
                      id="email"
                      error={!!errors.email}
                      helperText={errors?.email?.message}
                      variant="outlined"
                      fullWidth
                    />
                  )}
                />
              </div>

              <div className="block">
                <InputLabel id="country" className="text-16 font-medium mb-12 text-grey-900">
                  {t('common.country')}{' '}
                </InputLabel>
                <Controller
                  name="country"
                  control={control}
                  render={({ field }) => (
                    <Select
                      {...field}
                      className="w-full"
                      id="country"
                      value={countryId}
                      size="small"
                      displayEmpty
                      error={!!errors.country}
                      onChange={(e) => {
                        handleSelectCountry(e);
                      }}
                    >
                      <MenuItem value={0} disabled>
                        {t('signUpPage.formField.country.selectCountry')}
                      </MenuItem>
                      {countryList?.map((option) => (
                        <MenuItem key={option.id} value={option.id}>
                          {option.name}
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                />
              </div>
              <div className="block">
                <InputLabel htmlFor="website" className="text-16 font-medium mb-12 text-grey-900">
                  {t('manageBusiness.addBusinessSteps.addBusiness.form.website.name')} (
                  <span className="text-14">{t('signUpPage.optional')})</span>
                </InputLabel>
                <Controller
                  control={control}
                  name="website"
                  render={({ field }) => (
                    <TextField
                      {...field}
                      placeholder={t(
                        'manageBusiness.addBusinessSteps.addBusiness.form.website.placeHolder'
                      )}
                      size="small"
                      id="website"
                      error={!!errors.website}
                      helperText={errors?.website?.message}
                      variant="outlined"
                      fullWidth
                    />
                  )}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="text-right mt-48 md:mb-96 mb-36">
          <Button
            variant="outlined"
            color="secondary"
            className="md:text-16 font-medium disabled:text-black rounded-md mr-24 sm:min-w-160"
            aria-label="skip"
            onClick={() => handleSkipStep()}
            type="button"
          >
            {t('manageBusiness.button.back')}
          </Button>
          <Button
            className="md:text-16 font-medium disabled:text-black rounded-md min-w-256"
            variant="contained"
            color="secondary"
            type="submit"
            size="small"
            disabled={
              _.isEmpty(dirtyFields) ||
              !isValid ||
              refreshIcon ||
              !selectBusinessType ||
              !countryId ||
              errorMessage === false ||
              phone?.phoneNumber?.length === 0
            }
          >
            {t('manageBusiness.addBusinessSteps.addBusiness.title')}
            {refreshIcon && (
              <CircularProgress size={24} className="text-darkgreen absolute mx-auto" />
            )}
          </Button>
        </div>
      </form>
    </div>
  );
}

export default withReducer('manageBusinessReducer', reducer)(AddBusiness);
