import { useEffect, useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import ReactWordcloud from 'react-wordcloud';
import { selectDashboardData } from 'src/app/store/userSlice';
import metricsData from '../../query/metrics';
import NoData from './noDataScreen';
import ReviewSentiment from './reviewSentiment';
import { getDataToServer } from '../../common/common';

function ReviewCloud(props) {
  const { reviewSentiment, reviewPlatformId } = props;
  const [selectedReviewSentimentId, setSelectedReviewSentimentId] = useState(4);
  const [words, setWords] = useState([]);
  const userCurrentBusinessDetails = useSelector(selectDashboardData);
  const [planStatus, setPlanStatus] = useState();
  const cloudRef = useRef(null);
  const [cloudSize, setCloudSize] = useState([500, 100]);

  useEffect(() => {
    try {
      const fetchData = async () => {
        if (reviewPlatformId) {
          const payload = {
            query: metricsData.getReviewCloudData,
            variables: {
              businessId: parseInt(userCurrentBusinessDetails?.id, 10),
              reviewPlatformId: parseInt(reviewPlatformId, 10),
              reviewPeriodId: parseInt(selectedReviewSentimentId, 10),
            },
          };
          if (props?.placeId) {
            payload.variables.placeId = props?.placeId;
          }
          const result = await getDataToServer(payload);
          const transformedWords = result?.getReviewCloudData?.data?.map((item) => ({
            text: item.word,
            value: item.wordOccurrence,
            sentiment: item.sentiment,
          }));
          setWords(transformedWords);
          setPlanStatus(result?.getReviewCloudData?.status);
        }
      };
      fetchData();
    } catch (error) {
      console.error('An error occurred:', error);
    }
  }, [userCurrentBusinessDetails?.id, reviewPlatformId, selectedReviewSentimentId, props?.placeId]);

  useEffect(() => {
    const updateSize = () => {
      if (cloudRef.current) {
        const width = cloudRef.current.offsetWidth;
        const height = cloudRef.current.offsetHeight;
        setCloudSize([width, height]);
      }
    };

    window.addEventListener('resize', updateSize);
    updateSize();

    return () => window.removeEventListener('resize', updateSize);
  }, []);

  const callbacks = {
    getWordColor: (word) =>
      word.sentiment === 'neutral'
        ? '#3E8CF3'
        : word.sentiment === 'positive'
        ? '#6CDFA0'
        : '#FF5E5E',
    onWordClick: () => {},
    onWordMouseOver: () => {},
  };
  const options = {
    rotations: 2,
    rotationAngles: [-90, 0],
    fontSizes: [20, 70],
    scale: 'sqrt',
    padding: 2,
    enableTooltip: false,
  };

  return (
    <>
      {planStatus !== 403 && !userCurrentBusinessDetails?.isOnlineBusiness && (
        <ReviewSentiment
          reviewSentiment={reviewSentiment}
          sentimentId={[4, 5, 6]}
          onSelectSentiment={setSelectedReviewSentimentId}
        />
      )}
      {words?.length > 0 ? (
        <>
          <div
            ref={cloudRef}
            style={{
              width: '100%',
              height: '100%',
              maxHeight: '450px',
              overflow: 'auto',
            }}
          >
            <ReactWordcloud
              className="reactWordcloud"
              callbacks={callbacks}
              options={options}
              size={cloudSize}
              words={words}
            />
          </div>
        </>
      ) : (
        <NoData />
      )}
    </>
  );
}

export default ReviewCloud;
