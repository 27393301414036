import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { selectDashboardData } from 'app/store/userSlice';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Icon,
  IconButton,
  Typography,
} from '@mui/material';
import { t } from 'i18next';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import CachedIcon from '@mui/icons-material/Cached';
import CloseIcon from '@mui/icons-material/Close';
import { useSnackbar } from 'notistack';

import { fetchSocialConnections, handleApiRequest, handleFBLogin } from '../../common/common';

const SelectPlatformBox = (props) => {
  const {
    onClose,
    openDialog,
    socialPlatformId,
    connectionData,
    setSelectedPage,
    selectedPage,
    setOpenDialog,
    handleSocialResponse,
    handleClose,
    setConnectionData,
    setLoading,
    setSocialPlatformId,
  } = props;
  const userCurrentBusinessDetails = useSelector(selectDashboardData);
  const [facebookUserId, setFacebookUserId] = useState();
  const [accessToken, setAccessToken] = useState();
  const { enqueueSnackbar } = useSnackbar();
  const appKey = process.env.REACT_APP_WABA_APP_ID;
  const fbConfigKey = process.env.REACT_APP_FB_CONFIG_ID;
  const inConfigKey = process.env.REACT_APP_IN_CONFIG_ID;

  const handleRedirect = () => {
    window.location.href = 'https://www.facebook.com';
  };

  const handleRefresh = () => {
    window.location.reload();
  };

  const handleSocialConnection = async (social) => {
    try {
      let authResponse;
      if (social === 9 || social === 10) {
        authResponse = await handleFBLogin(appKey, fbConfigKey, inConfigKey, social);
        if (authResponse) {
          const fbAccessToken = authResponse?.accessToken;
          setFacebookUserId(authResponse?.userID);

          if (fbAccessToken && userCurrentBusinessDetails?.id) {
            await fetchSocialConnections(
              social,
              userCurrentBusinessDetails,
              setConnectionData,
              setAccessToken,
              setOpenDialog,
              setSelectedPage,
              setLoading,
              fbAccessToken,
              enqueueSnackbar
            );
          } else {
            console.log('Missing Facebook access token or business details.');
          }
        } else {
          console.log('Facebook login failed or was cancelled.');
        }
      } else if (social?.reviewPlatformId === 11) {
        // handleXLogin();
      } else if (social?.reviewPlatformId === 12) {
        // handleLinkedInLogin();
      }
    } catch (error) {
      console.error('Error during social connection:', error);
    }

    setSocialPlatformId(social);
  };

  return (
    <Dialog
      classes={{
        paper: 'border-t-8 border-solid border-t-quick-social border-quick-social rounded-md',
      }}
      open={openDialog}
      fullWidth
      maxWidth="xs"
    >
      {socialPlatformId === 9 && (
        <div>
          <DialogTitle>
            <Typography className="text-20 font-semibold text-black border-b-1 border-dashed border-grey-500 w-full pb-10">
              {socialPlatformId === 9
                ? t('quickSocial.selectFbPage')
                : socialPlatformId === 10
                ? t('quickSocial.selectInPage')
                : null}
            </Typography>
          </DialogTitle>
          <Icon
            onClick={onClose}
            className="text-24 absolute top-12 right-10 text-grey-400 font-normal cursor-pointer hover:text-gray-700 focus:text-gray-700"
          >
            cancel
          </Icon>
          <div className="gap-20 p-4 mx-20 rounded-8 flex items-center flex-col">
            {connectionData?.map((item) => (
              <div
                key={item?.id}
                role="button"
                tabIndex={0}
                onClick={() => setSelectedPage(item)}
                onKeyDown={(e) => {
                  if (e.key === 'Enter' || e.key === ' ') {
                    setSelectedPage(item);
                  }
                }}
                className={`flex gap-5 border-1 border-grey-200 rounded-md px-20 py-10 flex-col w-full cursor-pointer ${
                  selectedPage?.id === item?.id
                    ? 'bg-quick-social bg-opacity-70 text-white'
                    : 'bg-white'
                }`}
              >
                <p className="text-20 font-600">{item?.name}</p>
              </div>
            ))}
          </div>
          <DialogActions className="flex justify-center my-5">
            <Button
              className="text-12 md:text-14 px-32 py-5 font-semibold rounded-6"
              variant="contained"
              color="quickSocial"
              size="small"
              type="submit"
              disabled={!selectedPage}
              onClick={() => {
                if (selectedPage) {
                  setOpenDialog(false);
                  handleSocialResponse();
                  handleClose();
                }
              }}
            >
              {t('waba.inbox.select')}
            </Button>
          </DialogActions>
        </div>
      )}
      {socialPlatformId === 10 && connectionData?.length > 0 ? (
        connectionData?.some((item) => item?.isConnectedToInstagram) ? (
          <div>
            <DialogTitle>
              <Typography className="text-20 font-semibold text-black border-b-1 border-dashed border-grey-500 w-full pb-10">
                {t('quickSocial.selectInPage')}
              </Typography>
            </DialogTitle>
            <Icon
              onClick={onClose}
              className="text-24 absolute top-12 right-10 text-grey-400 font-normal cursor-pointer hover:text-gray-700 focus:text-gray-700"
            >
              cancel
            </Icon>
            <div className="gap-20 p-4 mx-20 rounded-8 flex items-center flex-col">
              {connectionData?.map((item) => (
                <div
                  key={item?.id}
                  role="button"
                  tabIndex={0}
                  onClick={() => setSelectedPage(item)}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter' || e.key === ' ') {
                      setSelectedPage(item);
                    }
                  }}
                  className={`flex gap-5 border-1 border-grey-200 rounded-md px-20 py-10 flex-col w-full cursor-pointer ${
                    selectedPage?.id === item?.id
                      ? 'bg-quick-social bg-opacity-70 text-white'
                      : 'bg-white'
                  }`}
                >
                  <p className="text-20 font-600">{item?.name}</p>
                </div>
              ))}
            </div>
            <DialogActions className="flex justify-center my-5">
              <Button
                className="text-12 md:text-14 px-32 py-5 font-semibold rounded-6"
                variant="contained"
                color="quickSocial"
                size="small"
                type="submit"
                disabled={!selectedPage}
                onClick={() => {
                  if (selectedPage) {
                    setOpenDialog(false);
                    handleSocialResponse();
                    handleClose();
                  }
                }}
              >
                {t('waba.inbox.select')}
              </Button>
            </DialogActions>
          </div>
        ) : (
          <div className="p-48 flex flex-col justify-center items-center text-center">
            <img
              src="/assets/images/icon/noFBPage.png"
              alt="noFBPage"
              className="h-120 w-120 mb-14"
            />
            <h1 className="mb-14 text-20 font-600">{t('quickSocial.connectInstagram')}</h1>
            <p className="text-18 font-500 text-gray mb-28">
              {connectionData?.some((item) => item?.isConnectedToInstagram)
                ? t('quickSocial.connectInstagramContent')
                : connectionData?.length > 0
                ? t('quickSocial.noBusinessPageContent')
                : t('quickSocial.somethingWentWrongContent')}
            </p>
            <div className="flex gap-14">
              <Button
                variant="outlined"
                color="quickSocial"
                type="button"
                className="rounded-md bg-quick-social-100 text-black"
                onClick={onClose}
              >
                <CachedIcon className="mr-5 h-20 w-20" />
                {t('quickSocial.later')}
              </Button>
              <Button
                variant="outlined"
                color="quickSocial"
                type="button"
                className="rounded-md bg-quick-social-100 text-black"
                onClick={handleRedirect}
              >
                <InstagramIcon className="mr-5 h-20 w-20 " />
                {t('quickSocial.connectNow')}
              </Button>
            </div>
            {connectionData?.length > 0 && (
              <Button
                variant="outlined"
                color="quickSocial"
                type="button"
                className="rounded-md bg-quick-social-100"
                onClick={handleRedirect}
              >
                {t('quickSocial.createBusinessPage')}
              </Button>
            )}
            {connectionData?.length === 0 && (
              <div className="flex gap-14">
                <Button
                  variant="outlined"
                  color="quickSocial"
                  type="button"
                  className="rounded-md bg-quick-social-100 text-black"
                  onClick={handleRefresh}
                >
                  <CachedIcon className="mr-5 h-20 w-20" />
                  Refresh
                </Button>
                <Button
                  variant="outlined"
                  color="quickSocial"
                  type="button"
                  className="rounded-md bg-quick-social-100 text-black"
                  onClick={() => handleSocialConnection(socialPlatformId)}
                >
                  <FacebookIcon className="mr-5 h-20 w-20 " />
                  {t('quickSocial.reconnectFacebook')}
                </Button>
              </div>
            )}
          </div>
        )
      ) : null}
    </Dialog>
  );
};

export default SelectPlatformBox;
