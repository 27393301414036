import { Button, CircularProgress, Dialog, DialogTitle, Icon, Typography } from '@mui/material';
import { t } from 'i18next';
import { useSnackbar } from 'notistack';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import { useCallback, useEffect, useState } from 'react';
import { handleApiRequest } from '../../../common/common';
import manageBusinessData from '../../../query/manageBusiness';

const AttributeSection = (props) => {
  const { userCurrentBusinessDetails, googleBusinessData, openDialog, setOpenDialog } = props;
  const [refreshIcon, setRefreshIcon] = useState(false);
  const [googleAttribute, setGoogleAttributes] = useState([]);
  const [defaultAttribute, setDefaultAttribute] = useState([]);
  const [addAttribute, setAddAttribute] = useState();
  const [deleteAttribute, setDeleteAttribute] = useState();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    const fetchGoogleAttribute = async () => {
      try {
        const payload = {
          query: manageBusinessData.getGoogleSuggestionAttributes,
          variables: {
            businessId: Number(userCurrentBusinessDetails?.id),
          },
        };
        const result = await handleApiRequest(payload);
        if (result?.getGoogleSuggestionAttributes?.status === 200) {
          setGoogleAttributes(result?.getGoogleSuggestionAttributes?.data);
        } else {
          enqueueSnackbar(result?.getGoogleSuggestionAttributes?.message, {
            variant: 'error',
            autoHideDuration: 3000,
          });
        }
      } catch (error) {
        console.error('Error in fetchGoogleAttribute:', error);
      }
    };
    if (userCurrentBusinessDetails?.id) {
      fetchGoogleAttribute();
    }
  }, [userCurrentBusinessDetails?.id, enqueueSnackbar]);

  const fetchDefaultGoogleAttribute = useCallback(async () => {
    try {
      const payload = {
        query: manageBusinessData.getDefaultGoogleAttributes,
        variables: {
          businessId: Number(userCurrentBusinessDetails?.id),
        },
      };
      const result = await handleApiRequest(payload);
      if (result?.getDefaultGoogleAttributes?.status === 200) {
        setDefaultAttribute(result?.getDefaultGoogleAttributes?.data);
      } else {
        enqueueSnackbar(result?.getDefaultGoogleAttributes?.message, {
          variant: 'error',
          autoHideDuration: 3000,
        });
      }
    } catch (error) {
      console.error('Error in fetchGoogleAttribute:', error);
    }
  }, [userCurrentBusinessDetails?.id, enqueueSnackbar]);

  useEffect(() => {
    if (userCurrentBusinessDetails?.id) {
      fetchDefaultGoogleAttribute();
    }
  }, [userCurrentBusinessDetails?.id, fetchDefaultGoogleAttribute]);

  const onSubmit = async () => {
    setRefreshIcon(true);
    try {
      const payload = {
        query: manageBusinessData.updateOrDeleteGoogleAttributes,
        variables: {
          businessId: Number(userCurrentBusinessDetails?.id),
          data: [
            {
              name: addAttribute?.name,
              valueType: addAttribute.valueType,
              ...(addAttribute.valueType === 'BOOL'
                ? {
                    boolValues: [Boolean(addAttribute?.valueMetadata?.[0]?.value)],
                  }
                : {
                    enumValues: [addAttribute?.valueMetadata?.[0]?.value],
                  }),
            },
          ],
        },
      };
      const result = await handleApiRequest(payload);
      setRefreshIcon(false);
      if (result?.updateOrDeleteGoogleAttributes?.status === 200) {
        setOpenDialog({ ...openDialog, attributesDialog: false });
        setAddAttribute();
        fetchDefaultGoogleAttribute();
      } else {
        enqueueSnackbar(result?.updateOrDeleteGoogleAttributes?.message, {
          variant: 'error',
          autoHideDuration: 3000,
        });
      }
    } catch (error) {
      setRefreshIcon(false);
      console.error('Error adding photos/videos:', error);
    }
  };

  const handleDeleteAttribute = async (name) => {
    setRefreshIcon(true);
    setDeleteAttribute(name);
    try {
      const payload = {
        query: manageBusinessData.updateOrDeleteGoogleAttributes,
        variables: {
          businessId: Number(userCurrentBusinessDetails?.id),
          removeAttribute: [name],
        },
      };
      const result = await handleApiRequest(payload);
      setRefreshIcon(false);
      if (result?.updateOrDeleteGoogleAttributes?.status === 200) {
        setAddAttribute();
        fetchDefaultGoogleAttribute();
      } else {
        enqueueSnackbar(result?.updateOrDeleteGoogleAttributes?.message, {
          variant: 'error',
          autoHideDuration: 3000,
        });
      }
    } catch (error) {
      setRefreshIcon(false);
      console.error('Error adding photos/videos:', error);
    }
  };

  return (
    <div>
      <div className="bg-white rounded-md">
        <div className="flex justify-between items-center mt-16">
          <Typography className="font-bold text-24 mt-16">
            {t('listManagement.attributes')}
          </Typography>
          <Button
            className="rounded-md bg-quick-review px-24 hover:bg-quick-review text-white font-semibold"
            variant="contained"
            onClick={() => setOpenDialog({ ...openDialog, attributesDialog: true })}
          >
            <AddIcon />
            {t('listManagement.addAttribute')}
          </Button>
        </div>
        <div className="bg-gray-A500 p-28 rounded-md mt-16">
          {defaultAttribute?.length > 0 ? (
            defaultAttribute?.map((attribute, i) => {
              return (
                <div key={i}>
                  {attribute?.attributes?.length > 0 && (
                    <div>
                      <Typography>{attribute?.groupDisplayName}</Typography>
                      {attribute?.attributes?.map((item, index) => {
                        return (
                          <div
                            key={index}
                            className="flex justify-between bg-white rounded-md px-12 p-6 my-12 items-center"
                          >
                            <Typography>{item?.displayName}</Typography>
                            <Button
                              className="w-20 min-w-20 hover:bg-white py-0"
                              onClick={() => handleDeleteAttribute(item?.name, true)}
                              disabled={refreshIcon}
                            >
                              <DeleteIcon className="text-gray text-20" />
                              {refreshIcon && deleteAttribute === item?.name && (
                                <CircularProgress
                                  className="text-quick-review absolute mx-auto"
                                  size={20}
                                />
                              )}
                            </Button>
                          </div>
                        );
                      })}
                    </div>
                  )}
                </div>
              );
            })
          ) : (
            <div
              className="bg-white p-24 rounded-md"
              onClick={() => {
                setOpenDialog({ ...openDialog, attributesDialog: true });
              }}
              role="button"
              tabIndex={0}
              onKeyPress={(e) => {
                if (e.key === 'Enter' || e.key === ' ') {
                  setOpenDialog({ ...openDialog, attributesDialog: true });
                }
              }}
            >
              <Typography className="font-bold text-16">
                {t('listManagement.attributes')}
              </Typography>
              <Typography className="font-semibold text-14">
                {t('listManagement.addAttributeHere')}
              </Typography>
            </div>
          )}
        </div>
      </div>
      <Dialog
        classes={{
          paper: 'm-24 sm:p-28 p-20 business-type-dropdown',
        }}
        open={openDialog}
        fullWidth
        maxWidth="md"
      >
        <DialogTitle className="p-0 mb-8">
          <div className="flex">
            <Icon
              onClick={() => setOpenDialog({ ...openDialog, attributesDialog: false })}
              className="text-24 absolute right-24 text-grey-400 font-normal cursor-pointer hover:text-gray-700 focus:text-gray-700"
            >
              cancel
            </Icon>
          </div>
          <div className="border-b-2 border-solid border-quick-review-100 pb-10">
            <Typography className="font-bold text-20">{googleBusinessData?.title}</Typography>
            <Typography className="text-gray text-16 font-semibold pb-8">
              {t('common.address')} : {googleBusinessData?.storefrontAddress?.addressLines?.[0]}{' '}
              {googleBusinessData?.storefrontAddress?.administrativeArea}{' '}
              {googleBusinessData?.storefrontAddress?.locality}{' '}
              {googleBusinessData?.storefrontAddress?.administrativeArea}{' '}
              {googleBusinessData?.storefrontAddress?.postalCode}
            </Typography>
          </div>
          <div>
            <Typography className="font-bold text-16 pt-20">
              {t('listManagement.selectAttributes')}
            </Typography>
          </div>
          <Typography />
          <div className="max-h-[340px] overflow-scroll">
            {googleAttribute?.map((item, index) => {
              return (
                <div className="border p-16 rounded-md bg-gray-A500 mt-16" key={index}>
                  <Typography className="font-bold text-16">{item?.groupDisplayName}</Typography>
                  <div className="flex flex-wrap gap-16 mt-16">
                    {item?.attributes?.map((attribute, i) => {
                      return (
                        <div key={i}>
                          {attribute?.valueMetadata?.length > 0 && (
                            <Button
                              className={`${
                                addAttribute?.name === attribute?.name
                                  ? 'bg-quick-review hover:bg-quick-review text-white'
                                  : 'bg-gray-300 hover:bg-gray-300'
                              } px-40 font-semibold text-16`}
                              onClick={() => setAddAttribute(attribute)}
                            >
                              {attribute?.valueMetadata?.[0]?.displayName}
                            </Button>
                          )}
                        </div>
                      );
                    })}
                  </div>
                </div>
              );
            })}
          </div>
          <div className="text-center">
            <Button
              className="bg-quick-review w-1/2 text-white rounded-full mt-16 px-40 disabled:text-white font-bold text-18 disabled:bg-gray hover:bg-quick-review"
              disabled={refreshIcon || !addAttribute}
              onClick={() => onSubmit()}
            >
              {refreshIcon && (
                <CircularProgress className="text-quick-review absolute mx-auto" size={24} />
              )}
              Save
            </Button>
          </div>
        </DialogTitle>
      </Dialog>
    </div>
  );
};

export default AttributeSection;
