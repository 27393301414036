import withReducer from 'app/store/withReducer';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import history from '@history';
import { AnimatePresence, motion } from 'framer-motion';
import './MangeBusiness.css';
import { Button, Typography } from '@mui/material';
import BusinessLocation from './addPhyscialBusiness/BusinessLocation';
import reducer from './store';
import {
  getReviewPlaterFormData,
  getUserPackageData,
  setOnlineBusinessType,
  setPhysicalBusinessType,
  setReviewPlateForm,
} from '../../../store/userSlice';
import AddOnlineBusiness from './addOnlineBusiness/AddOnlineBusiness';
import {
  fetchOnlineBusinessType,
  fetchPhysicalBusinessType,
  fetchReviewPlateForm,
} from '../../common/common';
import Assistance from '../assistance/Assistance';

function ManageBusiness({ isManual }) {
  const { t } = useTranslation();

  const [physicalPlatFrom, setPhysicalPlatForm] = useState([]);
  const [onlinePlatFrom, setOnlinePlatForm] = useState([]);
  const getUserSubscriptionPackageDetails = useSelector(getUserPackageData);
  const reviewPlateFormsData = useSelector(getReviewPlaterFormData);
  const [assistanceDialog, setAssistanceDialog] = useState(false);
  const dispatch = useDispatch();
  const [businessAddInProgress, setBusinessAddInProgress] = useState({
    isPhysical: false,
    isOnline: false,
    isManual: false,
  });
  const [isBusinessSuccessFullyAdded, setIsBusinessSuccessFullyAdded] = useState(false);
  const [businessAdded, setBusinessAdded] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      const reviewPlateForm = await fetchReviewPlateForm();
      const physicalBusinessType = await fetchPhysicalBusinessType();
      const onlineBusinessType = await fetchOnlineBusinessType();
      if (reviewPlateForm?.length > 0) {
        localStorage.setItem('reviewPlateForm', JSON.stringify(reviewPlateForm));
        dispatch(setReviewPlateForm(reviewPlateForm));
      }
      if (physicalBusinessType?.length > 0) {
        dispatch(setPhysicalBusinessType(physicalBusinessType));
        localStorage.setItem('physicalBusinessType', JSON.stringify(physicalBusinessType));
      }
      if (onlineBusinessType?.length > 0) {
        dispatch(setOnlineBusinessType(onlineBusinessType));
        localStorage.setItem('onlineBusinessType', JSON.stringify(onlineBusinessType));
      }
    };
    fetchData();
  }, [dispatch]);

  useEffect(() => {
    const excludedPlatforms = [
      'amazon',
      'flipkart',
      'linkedin',
      'X',
      'instagram',
      'facebook',
      'website',
    ];
    const filteredData = reviewPlateFormsData?.filter(
      (platform) => !excludedPlatforms?.includes(platform?.name)
    );
    const updatedData = filteredData?.map((platform) => ({
      ...platform,
      isBusinessDetailsAdded: false,
    }));
    const googlePlatform = updatedData?.find((platform) => platform.name === 'google');
    const otherPlatforms = updatedData?.filter((platform) => platform.name !== 'google');
    const finalData = googlePlatform ? [googlePlatform, ...otherPlatforms] : otherPlatforms;
    setPhysicalPlatForm(finalData);
  }, [reviewPlateFormsData]);

  useEffect(() => {
    const onlineBusiness = ['amazon', 'flipkart', 'website'];
    const onlineData = reviewPlateFormsData?.filter((platform) =>
      onlineBusiness?.includes(platform?.name)
    );
    const sortOnlineData = onlineData?.sort((a, b) => a.id - b.id);
    setOnlinePlatForm(sortOnlineData);
  }, [reviewPlateFormsData]);

  const clickOnExploreFeature = () => {
    setIsBusinessSuccessFullyAdded(false);
    setBusinessAddInProgress({
      isPhysical: false,
      isOnline: false,
      isManual: false,
    });
    history.push('business/dashboard');
  };

  const addManualBusiness = () => {
    setBusinessAdded(true);
    setBusinessAddInProgress({
      ...businessAddInProgress,
      isManual: true,
    });
  };

  return (
    <>
      <div
        className={`${
          !isManual && 'py-40 h-full'
        } flex items-center justify-center bg-white w-full sm:px-0 px-16 sm:py-0`}
      >
        {!isManual && (
          <>
            <img
              src="assets/images/background/top-round.png"
              className="absolute top-0 right-0 hidden lg:block"
              alt="..."
            />
            <img
              src="assets/images/background/bottom-round.png"
              className="absolute bottom-0 left-0 hidden lg:block"
              alt="..."
            />
          </>
        )}
        {!isBusinessSuccessFullyAdded ? (
          <div className="container px-16">
            {!isManual && (
              <>
                <img
                  className="m-auto w-84 h-84"
                  src="assets/images/business/quickhub.png"
                  alt="..."
                />
                <Typography className="text-center font-bold text-24 my-16">
                  {t('manageBusiness.addBusinessSteps.businessLocation.pageTitle')}
                </Typography>
              </>
            )}
            <div className={`${!isManual && 'bg-gray-50'} p-4 sm:p-28 md:p-32 rounded-xl`}>
              {!businessAddInProgress.isManual &&
                !businessAddInProgress.isOnline &&
                !businessAddInProgress.isPhysical &&
                !physicalPlatFrom?.some((business) => business.businessName) && (
                  <div className="flex items-center justify-between mb-16">
                    <Typography className="font-bold text-16 sm:text-20">
                      {t('manageBusiness.addBusiness')}
                    </Typography>
                    {!isManual && (
                      <Button
                        variant="contained"
                        color="secondary"
                        className="md:text-14 font-semibold disabled:text-black rounded-md sm:min-w-160"
                        aria-label="skip"
                        onClick={() => addManualBusiness()}
                        type="button"
                      >
                        {t('manageBusiness.addManualBusiness')}
                      </Button>
                    )}
                  </div>
                )}
              <div className={`${!isManual ? 'sm:flex w-full' : ''} gap-24 md:gap-112`}>
                {!businessAddInProgress.isOnline &&
                  !businessAddInProgress.isManual &&
                  !isBusinessSuccessFullyAdded && (
                    <div className="w-full">
                      {!businessAddInProgress.isOnline && !businessAddInProgress.isPhysical && (
                        <Typography className="font-semibold mb-12">
                          {t('manageBusiness.physicalBusiness')}
                        </Typography>
                      )}
                      <BusinessLocation
                        physicalPlatFrom={physicalPlatFrom}
                        businessAddInProgress={businessAddInProgress}
                        setPhysicalPlatForm={setPhysicalPlatForm}
                        packageTypeId={getUserSubscriptionPackageDetails?.packageTypeId}
                        setBusinessAddInProgress={setBusinessAddInProgress}
                        setIsBusinessSuccessFullyAdded={setIsBusinessSuccessFullyAdded}
                        isManual={isManual}
                      />
                    </div>
                  )}
                {!businessAddInProgress.isOnline &&
                  !businessAddInProgress.isPhysical &&
                  !businessAddInProgress.isManual &&
                  !isBusinessSuccessFullyAdded && <div className="border-r-2 border-gray" />}
                {!businessAddInProgress.isPhysical &&
                  !isBusinessSuccessFullyAdded &&
                  !physicalPlatFrom?.find((item) => item.businessName)?.businessName && (
                    <div className="w-full">
                      {!businessAddInProgress.isOnline &&
                        !businessAddInProgress.isPhysical &&
                        !businessAddInProgress.isManual && (
                          <Typography className="font-semibold mb-12">
                            {t('manageBusiness.onlineBusiness')}
                          </Typography>
                        )}
                      <AddOnlineBusiness
                        onlinePlatFrom={onlinePlatFrom}
                        businessAddInProgress={businessAddInProgress}
                        setBusinessAddInProgress={setBusinessAddInProgress}
                        setIsBusinessSuccessFullyAdded={setIsBusinessSuccessFullyAdded}
                        setBusinessAdded={setBusinessAdded}
                        businessAdded={businessAdded}
                        isManual={isManual}
                      />
                    </div>
                  )}
              </div>
              {!businessAddInProgress.isOnline &&
                !businessAddInProgress.isPhysical &&
                !businessAddInProgress.isManual &&
                !physicalPlatFrom?.some((business) => business.businessName) &&
                !isManual && (
                  <div className="text-right">
                    <Button
                      variant="outlined"
                      color="secondary"
                      className="md:text-16 font-medium disabled:text-black rounded-md sm:min-w-160"
                      aria-label="skip"
                      onClick={() => history.push('business/dashboard')}
                      type="button"
                    >
                      {t('manageBusiness.button.skip')}
                    </Button>
                  </div>
                )}
            </div>
          </div>
        ) : (
          <div className="container !mx-16 md:!w-1/2 bg-gray-100 py-148 flex justify-center rounded-lg">
            <div className="flex items-center text-center justify-center px-16 py-96">
              <div>
                <img
                  className="m-auto w-160 sm:w-192"
                  src="assets/images/icon/success.png"
                  alt=""
                />
                <Typography className="font-bold text-24 py-20">
                  {t('manageBusiness.businessAddedSuccessFully')}
                </Typography>
                <Typography className="md:max-w-480 font-semibold">
                  {t('manageBusiness.businessAddedMessage')}
                </Typography>
                <Button
                  className="bg-darkgreen text-white w-256 mt-16 hover:bg-darkgreen font-medium rounded"
                  onClick={() => clickOnExploreFeature()}
                >
                  {t('manageBusiness.button.exploreFeature')}
                </Button>
              </div>
            </div>
          </div>
        )}
      </div>
      <div className="mb-16 mr-16 bg-white flex justify-end absolute bottom-0 right-0">
        <AnimatePresence>
          {assistanceDialog ? (
            <motion.div
              initial={{ opacity: 0, y: 100 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: 100 }}
              transition={{
                type: 'spring',
                stiffness: 120,
                damping: 14,
              }}
              className="absolute bottom-0 right-0"
            >
              <Assistance setAssistanceDialog={setAssistanceDialog} />
            </motion.div>
          ) : (
            <Button
              className="rounded-md font-semibold flex items-center gap-6 text-16 mb-8 mr-8"
              variant="contained"
              color="secondary"
              onClick={() => setAssistanceDialog(true)}
            >
              {t('navigation.needAssistance')}{' '}
              <img className="w-24" src="assets/images/icon/assistance.svg" alt="" />
            </Button>
          )}
        </AnimatePresence>
      </div>
    </>
  );
}

export default withReducer('manageBusinessReducer', reducer)(ManageBusiness);
