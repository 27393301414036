const getQuickHubProducts = `
query($countryId: Int,$packageInterval: String,$packageTypeId: Int){
  getQuickHubProducts(countryId: $countryId, packageInterval: $packageInterval, packageTypeId: $packageTypeId){
    message
    status
    data{
      id
      name
      image
      description
      featureDetails
      packageIndex
      currency
      packageInterval
      packageBundle
      stripePriceId
      razorpayPlanId
      offerAmount
      packageTypeId
      monthlyAmount
      amount
      yearlyAmount
      getOfferStripePriceId
    }
  }
}
`;

const getSurpriseMeCard = `
query($isHomeScreen: Boolean!,$userBusinessId: Int!){
  getSurpriseMeCard(isHomeScreen: $isHomeScreen, userBusinessId: $userBusinessId){
    message
    status
    data{
      id
      button
      description
      buttonColor
      image
    }
  }
}
`;

const getQuickHubOverAllSummary = `
query($businessId: Int!){
  getQuickHubOverAllSummary(businessId: $businessId){
    message
    status
    data {
      overallSummary {
        key
        value
        icon
        backgroundColor
      }
    }
  }
}
`;

const businessPerformanceReports = `
query($userBusinessId: Int!){
  businessPerformanceReports(userBusinessId: $userBusinessId){
    status
    message
    data {
      overallBusinessReport {
        url
        timestamp
      }
      monthlyBusinessReport {
        url
        timestamp
      }
      }
  }
}
`;

const getSpecialOfferForUserBusiness = `
query($userBusinessId: Int!){
  getSpecialOfferForUserBusinessV1(userBusinessId: $userBusinessId){
    message
    status
    data {
      id
      backgroundColor
      name
      description
      icons
      button {
        name
        buttonColor
      }
      offerAmount
      packageInterval
      discount
      packageId
      packageBundle
      amount
      razorpayPlanId
      stripePriceId
    }
  }
}
`;

const getQuickHubProductSnapShotData = `
query($businessId: Int!){
  getQuickHubProductSnapShotData(businessId: $businessId){
    message
    status
    data {
      id
      type
      dataPoint {
       sharedPostCount
       totalEngagementsCount
       todayConversation
       todayReview
       totalConversation
       smsSent
       emailSent
       whatsAppSent
       adsSpents
       engagements
       totalConversation
       totalReviews{
        count
        newReviews
       }
      totalReviewsRating{
        count
        totalReviewRatingsUpToGo
      }
      }
      pendingTask {
        totalProgressCount
        progressCount
        card {
          name
          button
          screen
          portalURL
          onlineIndex
          physicalIndex
          isOfflineSupport
          isOnlineSupport
        }
      }
      whatsNewCard
    }
  }
}
`;

const getCombo = `query ($countryId: Int!, $interval: String!, $packageId: Int, $packageTypeId: [Int!], $stripeIds: [String!],){
   getCombo(countryId: $countryId, interval: $interval, packageId: $packageId, packageTypeId: $packageTypeId, stripeIds: $stripeIds){
     message
     status
     data{
      amount
      discount
      expireDate
      isOffer
      name
      offerAmount
      packageId
      packagesName{
        id
        name
      }
      razorpayPlanId
      startDate
      stripeIds
      stripePriceId
      tax
     }
   }
  }`;

const redirectPortalForRazorpayOneTimePayment = `mutation ($amount: Float!, $billingCycle: String!, 
    $isCombo: Boolean!, $packageId: Int!, $userBusinessId: Int){
    redirectPortalForRazorpayOneTimePayment(
    amount: $amount,
    billingCycle: $billingCycle,
    isCombo: $isCombo,
    packageId: $packageId,
    userBusinessId: $userBusinessId
    ){
    status
    message
    data{
    url
    }
  }
}`;

const redirectPortalForSubscription = `mutation redirectPortalForSubscription($countryId: Int!, $priceId: String, 
    $stripePriceIds: [String!], $userBusinessId: Int, $isStripeBundleOffer: Boolean){
    redirectPortalForSubscription(
    countryId: $countryId
    priceId: $priceId
    stripePriceIds: $stripePriceIds
    userBusinessId: $userBusinessId,
    isStripeBundleOffer: $isStripeBundleOffer){
      message
      status
      data{
      url
      razorpaySubscriptionId      
    }
    }
  }`;

const getOverallRatingAndReview = `query getOverallRatingAndReview($businessId: Int, $domain: String, $pageNumber: Int, $pageSize: Int){
    getOverallRatingAndReview(businessId: $businessId, domain: $domain, pageNumber: $pageNumber, pageSize: $pageSize){
      message
      status
      data{
      review
      reviewText
      reviewBackgroundColor
      rating
      ratingText
      ratingBackgroundColor
      todayReviews
      todaysReviewText
      todaysReviewBackgroundColor
    }
      id
      uts
    }
  }`;

const getQuickCampaignDataPoint = `query getQuickCampaignDataPoint($businessId: Int){
    getQuickCampaignDataPoint(businessId: $businessId){
      message
      status
      data{
        key
        backgroundColor
        icon
        value
      }
    }
  }`;

const createAndEditUserBusinessesLabel = `mutation createAndEditUserBusinessesLabel($label : String!, $color: String!, $businessIdsToAdd: [Int!], $businessIdsToRemove: [Int!], $labelId: Int){
  createAndEditUserBusinessesLabel(
    label: $label
    color: $color
    businessIdsToAdd: $businessIdsToAdd
    businessIdsToRemove: $businessIdsToRemove
    labelId: $labelId
  ) {
    status
    message
  }
}`;

const getAllBusinessesLabels = `query ($pageSize: Int, $pageNumber: Int, $searchText: String,){
  getAllBusinessesLabels (pageSize: $pageSize , pageNumber: $pageNumber, searchText: $searchText){
    status
    message
    totalCount
    data {
      id
      label
      color
      businessAssociatedCount
    }
  }
}`;

const deleteBusinessesLabel = `mutation ($labelId: Int!){
  deleteBusinessesLabel(labelId: $labelId) {
    status
    message
  }
}`;

const getAllBusinesses = `query ($businessName: String, $isGoogleConnected: Boolean, $dataMask: String, $labelIds: [Int!], $searchText: String, $city: String, $state: String, $stateId: Int, $countryId: Int, $pageSize: Int, $stateByGoogle: String, $pageNumber:Int, $sortBy: String, $sortOrder: OrderDirection, $userBusinessId: Int) {
  getAllBusinesses(
    businessName: $businessName
    isGoogleConnected: $isGoogleConnected
    dataMask: $dataMask
    labelIds: $labelIds
    searchText: $searchText
    city: $city
    state: $state
    stateId: $stateId
    countryId: $countryId
    pageSize: $pageSize
    pageNumber: $pageNumber
    sortBy: $sortBy
    sortOrder: $sortOrder
    userBusinessId: $userBusinessId
    stateByGoogle: $stateByGoogle
  ) {
    status
    message
    totalCount
    data {
      id
      name
      logo
      address
      address2
      profileURL
      city
      stateByGoogle
      isSupportFoodMenus
      googleBusinessAccountId
      quickHubAdsAccountId
      userId
      locationId
      countries {
        name
      }
      states {
        name
      }
      phoneNumber {
        isoCode
        countryCode
        phoneNumber
      }
      label {
        id
        label
        color
      }
      viewData {
        isGooglePostAvailable
        regularHours
        activeServices
        activeAttributes
        isImagesAvailable
        isVideosAvailable
        isProfilePhotoAvailable
        isCoverPhotoAvailable
      }
    }
  }
}`;

const getOnlineReputationReport = `query ($labelId: [Int!], $userBusinessId: [Int!], $durationDate: durationDateDto){
  getOnlineReputationReport(
    labelId: $labelId,
    userBusinessId: $userBusinessId,
    durationDate: $durationDate
  ) {
    status
    message
    data {
      placeId
      businessName
      address
      label
      reviewDistribution {
        key
        value
      }
      starRatingByBusiness {
        key
        value
      }
      numberOfReviewByBusiness {
        key
        value
      }
    }
  }
}
`;

const updateGoogleBusinessDetailsForBusinesses = `mutation ($mask: String!, $businessIds: [Int!], $data: String, $mediaFormat: String, $allMedia: [GoogleMedia!]){
  updateGoogleBusinessDetailsForBusinesses(
    mask: $mask
    businessIds: $businessIds
    data: $data
    mediaFormat: $mediaFormat
    allMedia: $allMedia
  ) {
    data {
      updatedBusinessIds
      failedRequests {
        userBusinessId
        reason
        name
        address
        profileURL
      }
    }
    message
    status
  }
}`;

const addOrUpdateMenuForGoogleBusiness = `mutation addOrUpdateMenuForGoogleBusiness($media: Upload, $isNewSection: Boolean, $menuDetails: MenuDetails, $sectionName: String, $userBusinessId: Int!, $isUpdate: Boolean, $preMediaKey: String) {
  addOrUpdateMenuForGoogleBusiness(
    media: $media
    isNewSection: $isNewSection
    menuDetails: $menuDetails
    sectionName: $sectionName
    userBusinessId: $userBusinessId
    isUpdate: $isUpdate
    preMediaKey: $preMediaKey
  ) {
    message
    status
  }
}`;

const deleteMenuForGoogleBusiness = `mutation ($menuName: String!, $userBusinessId: Int!, $sectionName: String!) {
  deleteMenuForGoogleBusiness(
    menuName: $menuName
    sectionName: $sectionName
    userBusinessId: $userBusinessId
  ) {
    message
    status
  }
}`;

const getMenuForGoogleBusiness = `query ($businessId: Int!, $pageNumber: Int, $pageSize: Int){
  getMenuForGoogleBusiness(businessId: $businessId, pageNumber: $pageNumber, pageSize: $pageSize) {
    message
    status
    data {
      menus {
        description
        dietaryRestriction
        name
        image
        mediaKey
        price {
          unit
          currencyCode
        }
      }
      sectionName
    }
  }
}`;

const removeGoogleBusinessPhotos = `mutation ($userBusinessId: Int!, $category: String!){
  removeGoogleBusinessPhotos(userBusinessId: $userBusinessId, category: $category) {
    status
    message
  }
}`;

const getGooglePostsPostedByMultipleBusinesses = `query ($pageSize: Int, $pageNumber: Int){
  getGooglePostsPostedByMultipleBusinesses(pageSize: $pageSize, pageNumber: $pageNumber) {
    status
    message
    totalCount
    data {
      id
      title
      description
      type
      image
      userBusinessIds
      schedule
    }
  }
}`;

const deleteGooglePostsInBulk = `mutation ($globalGooglePostId: Int!){
  deleteGooglePostsInBulk(globalGooglePostId: $globalGooglePostId) {
    status
    message
    data {
      updatedBusinessIds
      failedRequests {
        userBusinessId
        reason
      }
    }
  }
}`;

const quickHub = {
  getQuickHubProducts,
  getSurpriseMeCard,
  getQuickHubOverAllSummary,
  businessPerformanceReports,
  getSpecialOfferForUserBusiness,
  getQuickHubProductSnapShotData,
  getCombo,
  redirectPortalForRazorpayOneTimePayment,
  redirectPortalForSubscription,
  getOverallRatingAndReview,
  getQuickCampaignDataPoint,
  createAndEditUserBusinessesLabel,
  getAllBusinessesLabels,
  deleteBusinessesLabel,
  getAllBusinesses,
  getOnlineReputationReport,
  addOrUpdateMenuForGoogleBusiness,
  deleteMenuForGoogleBusiness,
  getMenuForGoogleBusiness,
  removeGoogleBusinessPhotos,
  updateGoogleBusinessDetailsForBusinesses,
  getGooglePostsPostedByMultipleBusinesses,
  deleteGooglePostsInBulk,
};

export default quickHub;
