import React, { useEffect, useState } from 'react';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import CloseIcon from '@mui/icons-material/Close';
import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  IconButton,
  Menu,
  Radio,
  RadioGroup,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

export default function FilterDialog({
  filterOpen,
  setFilterOpen,
  open,
  setOpen,
  selectedCategory,
  setSelectedCategory,
  selectedCountries,
  setSelectedCountries,
  selectedStatus,
  setSelectedStatus,
  sortByValue,
  setSortByValue,
  getStatusClick,
  setFilterLength,
  getBusinessListData,
}) {
  const [categoryIcon, setCategoryIcon] = useState(false);
  const [countryIcon, setCountryIcon] = useState(false);
  const [statusIcon, setStatusIcon] = useState(false);
  const [registrationIcon, setRegistrationIcon] = useState(false);

  const { t } = useTranslation();

  const handleFilterClose = () => {
    setFilterOpen(!filterOpen);
    setOpen(!open);
  };

  const handleCategoryChange = (event) => {
    const { value } = event.target;
    setSelectedCategory((prev) =>
      prev.includes(value) ? prev.filter((item) => item !== value) : [...prev, value]
    );
  };

  const handleCountryChange = (event) => {
    const { value } = event.target;
    setSelectedCountries((prev) =>
      prev.includes(value) ? prev.filter((item) => item !== value) : [...prev, value]
    );
  };

  const handleStatusChange = (event) => {
    const { value } = event.target;
    setSelectedStatus(value);
    // handleFilterApply();
  };

  const handleSortChange = (event) => {
    const { value } = event.target;
    setSortByValue(value);
    // handleFilterApply();
  };

  const handleFilterApply = () => {
    setFilterOpen(!filterOpen);
    setOpen(!open);
    getStatusClick(0);
  };

  const clearFilters = () => {
    setSelectedCategory([]);
    setSelectedStatus('Active');
    setSortByValue('Newest');
    setSelectedCountries([]);
    setFilterLength([]);
    getBusinessListData();
  };

  return (
    <div>
      <Menu
        className="rounded-lg mt-96"
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        sx={{
          '& .MuiPaper-root': {
            mt: 5,
            ml: -5,
            height: '600px',
            width: '270px',
          },
        }}
        open={filterOpen}
        onClose={clearFilters}
      >
        <div className="px-8">
          <div className="flex justify-between items-center ms-10">
            <Typography variant="h6" className="text-black text-base font-semibold">
              {t('users.UserList.Filter.mainBtn')}
            </Typography>
            <IconButton onClick={handleFilterClose}>
              <CloseIcon className="text-lg" />
            </IconButton>
          </div>
          <hr className="ms-10 me-7" />

          <div className="flex justify-between">
            <FormLabel
              className="text-darkgreen text-14 font-semibold ms-10 me-7 mb-10 cursor-pointer mt-7"
              onClick={() => setCategoryIcon(!categoryIcon)}
            >
              {t('business.businessList.filter.categoryName')}
            </FormLabel>
            {categoryIcon ? (
              <ArrowDropUpIcon
                onClick={() => setCategoryIcon(!categoryIcon)}
                className="cursor-pointer mt-7"
              />
            ) : (
              <ArrowDropDownIcon
                onClick={() => setCategoryIcon(!categoryIcon)}
                className="cursor-pointer mt-7"
              />
            )}
          </div>
          {categoryIcon && (
            <FormGroup className="ms-5 me-7 ">
              <FormControlLabel
                className="-ml-6 -mt-[14px]"
                control={
                  <Checkbox
                    className="-mr-5"
                    checked={selectedCategory.includes('Online')}
                    onChange={handleCategoryChange}
                    value="Online"
                  />
                }
                label="Online"
              />
              <FormControlLabel
                className="-ml-6 -mt-[14px]"
                control={
                  <Checkbox
                    className="-mr-5"
                    checked={selectedCategory.includes('physical')}
                    onChange={handleCategoryChange}
                    value="physical"
                  />
                }
                label="Physical"
              />
            </FormGroup>
          )}

          <hr className="ms-10 me-7" />

          <div className="flex justify-between">
            <FormLabel
              className="text-darkgreen text-14 font-semibold ms-10 me-7 mb-10 cursor-pointer mt-7"
              onClick={() => setCountryIcon(!countryIcon)}
            >
              {t('common.country')}
            </FormLabel>
            {countryIcon ? (
              <ArrowDropUpIcon
                onClick={() => setCountryIcon(!countryIcon)}
                className="cursor-pointer mt-7"
              />
            ) : (
              <ArrowDropDownIcon
                onClick={() => setCountryIcon(!countryIcon)}
                className="cursor-pointer mt-7"
              />
            )}
          </div>

          {countryIcon && (
            <FormGroup className="ms-5 me-7 ">
              <FormControlLabel
                className="-ml-6 -mt-[14px]"
                control={
                  <Checkbox
                    className="-mr-5"
                    checked={selectedCountries.includes('USA')}
                    onChange={handleCountryChange}
                    value="USA"
                  />
                }
                label="USA"
              />
              <FormControlLabel
                className="-ml-6 -mt-[14px]"
                control={
                  <Checkbox
                    className="-mr-5"
                    checked={selectedCountries.includes('India')}
                    onChange={handleCountryChange}
                    value="India"
                  />
                }
                label="India"
              />
            </FormGroup>
          )}
          <hr className=" ms-10 me-7" />

          <div className="flex justify-between">
            <FormLabel
              className="text-darkgreen Quicksand font-semibold ms-10 me-7 cursor-pointer mt-7"
              onClick={() => setStatusIcon(!statusIcon)}
            >
              {t('common.status')}
            </FormLabel>
            {statusIcon ? (
              <ArrowDropUpIcon
                onClick={() => setStatusIcon(!statusIcon)}
                className="cursor-pointer mt-7"
              />
            ) : (
              <ArrowDropDownIcon
                onClick={() => setStatusIcon(!statusIcon)}
                className="cursor-pointer mt-7"
              />
            )}
          </div>
          {statusIcon && (
            <FormGroup className="ms-5 me-7">
              <RadioGroup
                className="-ml-17 -mt-[10px]"
                value={selectedStatus}
                onChange={handleStatusChange}
              >
                <FormControlLabel
                  className="-my-4 -ms-7 mt-1"
                  value="Active"
                  control={<Radio />}
                  label="Active"
                />
                <FormControlLabel
                  className="-my-4 -ms-7 -mt-8"
                  value="Inactive"
                  control={<Radio />}
                  label="Inactive"
                />
              </RadioGroup>
            </FormGroup>
          )}

          <hr className=" ms-10 me-7" />

          <div className="flex justify-between">
            <FormLabel
              className="text-darkgreen Quicksand font-semibold ms-10 me-7 cursor-pointer mt-7"
              onClick={() => setRegistrationIcon(!registrationIcon)}
            >
              {t('business.businessList.filter.sortBy')}
            </FormLabel>
            {registrationIcon ? (
              <ArrowDropUpIcon
                onClick={() => setRegistrationIcon(!registrationIcon)}
                className="cursor-pointer mt-7"
              />
            ) : (
              <ArrowDropDownIcon
                onClick={() => setRegistrationIcon(!registrationIcon)}
                className="cursor-pointer mt-7"
              />
            )}
          </div>
          {registrationIcon && (
            <FormGroup className="ms-5 me-7 ">
              <RadioGroup
                className="-ml-17 -mt-[10px]"
                value={sortByValue}
                onChange={handleSortChange}
              >
                <FormControlLabel
                  className="-my-4 -ms-7 mt-1"
                  value="Newest"
                  control={<Radio />}
                  label="Newest"
                />
                <FormControlLabel
                  className="-my-4 -ms-7 -mt-8"
                  value="Oldest"
                  control={<Radio />}
                  label="Oldest"
                />
              </RadioGroup>
            </FormGroup>
          )}

          <hr className="ms-10 me-7" />
          {(selectedCategory.length >= 1 ||
            selectedCountries.length >= 1 ||
            selectedStatus !== 'Active' ||
            sortByValue !== 'Newest') && (
            <div className="flex justify-end gap-8 mt-[150px]">
              <Button variant="outlined" color="secondary" onClick={clearFilters}>
                {t('business.businessList.filter.BtnClear')}
              </Button>
              <Button variant="contained" color="secondary" onClick={() => handleFilterApply()}>
                {t('business.businessList.filter.BtnApply')}
              </Button>
            </div>
          )}
        </div>
      </Menu>
    </div>
  );
}
