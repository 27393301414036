import { Button, Typography } from '@mui/material';
import { selectDashboardData } from 'app/store/userSlice';
import { t } from 'i18next';
import React from 'react';
import QRCode from 'react-qr-code';
import { useSelector } from 'react-redux';

const QuickReviewsFeatures = (props) => {
  const userCurrentBusinessDetails = useSelector(selectDashboardData);

  function openReviewSite() {
    const url = userCurrentBusinessDetails?.businessQRId;
    if (url) {
      window.open(url, '_blank');
    }
  }

  return (
    <div className="flex flex-col items-center bg-white sm:p-40 p-16">
      <h1 className="sm:text-28 text-18 mb-20 font-medium">
        {t('dashboard.exploreFeatures.reviewFeaturesTitle')}
      </h1>
      {userCurrentBusinessDetails?.businessQRId && (
        <div className="relative w-256 h-256 mx-auto">
          <QRCode
            size={256}
            className="relative w-full h-full qr-code-border"
            value={`${userCurrentBusinessDetails?.businessQRId}`}
            id="QRCodes"
            includeMargin
            level="H"
          />
          <img
            className="absolute w-68 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
            src="/assets/images/icon/quickreviewsrocket.svg"
            alt=""
          />
        </div>
      )}

      <Typography className="sm:text-20 text-16 mt-20 text-center font-medium">
        {t('dashboard.exploreFeatures.desc1')} <br />
        {t('dashboard.exploreFeatures.desc2')}
      </Typography>
      <div className="flex flex-row gap-20 mt-10">
        <Button
          className="text-14 mt-10 sm:px-60 font-medium disabled:text-black rounded-md"
          variant="outlined"
          color="secondary"
          type="button"
          onClick={() => props.setActiveStep(1)}
        >
          {t('manageBusiness.button.next')}
        </Button>
        <Button
          className="text-14 mt-10 px-28 font-medium disabled:text-black rounded-md"
          variant="contained"
          color="secondary"
          type="button"
          onClick={openReviewSite}
        >
          {t('dashboard.exploreFeatures.shareReview')}
        </Button>
      </div>
    </div>
  );
};

export default QuickReviewsFeatures;
