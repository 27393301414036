import withReducer from 'app/store/withReducer';
import ReactApexChart from 'react-apexcharts';
import { useEffect, useState, useRef, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import FuseLoading from '@fuse/core/FuseLoading';
import history from '@history';
import salesData from '../../query/sales';
import { getDashboardForSalesUser, resetDashboards } from './store/dashboardsSlice';
import reducer from './store';
import UpcomingFollowUp from './followUp/UpcomingFollowUp';
import { handleApiRequest } from '../../common/common';

function getChartSimple(data, business, index, color, types) {
  const chart = {
    series: [
      {
        name: 'Total Business',
        data,
      },
    ],
    options: {
      grid: {
        show: true,
        forceNiceScale: false,
        padding: {
          bottom: -15,
        },
      },
      chart: {
        events: {
          mounted: (charts) => {
            charts.windowResizeHandler();
          },
        },
        type: types,
        parentHeightOffset: 0,
        zoom: {
          enabled: false,
        },
        toolbar: {
          show: false,
        },
      },
      plotOptions: {
        bar: {
          dataLabels: {
            position: 'right',
          },
          horizontal: true,
          borderRadius: 3,
          barHeight: '35px',
        },
      },
      fill: {
        colors: color,
      },
      dataLabels: {
        enabled: false,
      },
      xaxis: {
        categories: business,
        show: true,
        axisTicks: {
          show: false,
        },
        labels: {
          show: true,
          offsetY: 0,
        },
      },
      yaxis: {
        show: true,
        axisTicks: {
          show: false,
        },
        labels: {
          show: true,
          align: 'left',
          maxWidth: 350,
          position: 'left',
          offsetX: -15,
          style: {
            fontSize: '14px',
          },
        },
      },
    },
  };

  return (
    <div
      id={`chart${index}`}
      // style={{ height: '100%', width: '100%' }}
      className="flex flex-col flex-auto w-full"
    >
      <ReactApexChart
        options={chart?.options}
        series={chart?.series}
        type={chart?.options?.chart?.type}
        height="300px"
        width="100%"
      />
    </div>
  );
}

function getChartRealAccount(data, business, index, color, types) {
  const chart = {
    series: [
      {
        name: 'Accounts',
        data,
      },
    ],
    options: {
      grid: {
        show: true,
        forceNiceScale: false,
      },
      chart: {
        events: {
          mounted: (charts) => {
            charts.windowResizeHandler();
          },
        },
        type: types,
        zoom: {
          enabled: false,
        },
        toolbar: {
          show: false,
        },
      },
      plotOptions: {
        bar: {
          dataLabels: {
            position: 'right',
          },
          columnWidth: '23px',
          horizontal: false,
          barHeight: '5px',
        },
      },
      fill: {
        colors: color,
      },
      dataLabels: {
        enabled: false,
      },
      xaxis: {
        categories: business,
        show: true,
        axisTicks: {
          show: false,
        },
        labels: {
          show: true,
        },
      },
      yaxis: {
        show: true,
        axisTicks: {
          show: false,
        },
        labels: {
          show: true,
          align: 'left',
          maxWidth: 350,
          offsetX: -15,
          style: {
            fontSize: '14px',
          },
        },
      },
    },
  };

  return (
    <div
      id={`chart${index}`}
      // style={{ height: '100%', width: '100%' }}
      className="flex flex-col flex-auto w-full"
    >
      <ReactApexChart
        options={chart?.options}
        series={chart?.series}
        type={chart?.options?.chart?.type}
        height="300px"
        width="100%"
      />
    </div>
  );
}

function Dashboards() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  let lastBusiness = [];
  let totalBusinessType;

  const getDashboardDetails = useSelector(
    ({ salesDashboardsReducer }) =>
      salesDashboardsReducer?.dashboards?.getDashboardForSalesUSerData?.data
        ?.getDashboardForSalesUser?.data || ''
  );

  const getDashboardTopBusinessDetails = useSelector(
    ({ salesDashboardsReducer }) =>
      salesDashboardsReducer?.dashboards?.getDashboardForSalesUSerData?.data
        ?.getDashboardForSalesUser?.data?.salesUserDashboardData?.topBusinessType || ''
  );

  const getDashboardMessage = useSelector(
    ({ salesDashboardsReducer }) =>
      salesDashboardsReducer?.dashboards?.getDashboardForSalesUSerData?.data
        ?.getDashboardForSalesUser || ''
  );
  if (getDashboardTopBusinessDetails) {
    const businessTypeArray = JSON.parse(getDashboardTopBusinessDetails);
    totalBusinessType = businessTypeArray;
    if (Array.isArray(businessTypeArray?.topBusinessType)) {
      lastBusiness = businessTypeArray.topBusinessType.slice(0, 7);
    }
  }

  const [followUpData, setFollowUpData] = useState([]);
  const [totalCount, setTotalCount] = useState();

  useEffect(() => {
    const fetchFollowUp = async () => {
      const payload = {
        query: salesData.getDemoBusinessUpcomingFollowUpDataBySalesPerson,
      };
      const result = await handleApiRequest(payload);
      if (result?.getDemoBusinessUpcomingFollowUpDataBySalesPerson?.status === 200) {
        setFollowUpData(result?.getDemoBusinessUpcomingFollowUpDataBySalesPerson?.data);
        setTotalCount(result?.getDemoBusinessUpcomingFollowUpDataBySalesPerson?.totalCount);
      }
    };
    fetchFollowUp();
  }, []);

  const handleRedirect = (id) => {
    history.push(`visited-business-details/${id}`);
  };
  useEffect(() => {
    if (getDashboardMessage && getDashboardMessage.message === 'Not found.') {
      setLoading(false);
    } else {
      setLoading(true);
    }
  }, [getDashboardMessage]);

  useEffect(() => {
    const payload = {
      query: salesData.getDashboardForSalesUser,
    };
    dispatch(getDashboardForSalesUser(payload));

    return () => {
      dispatch(resetDashboards());
    };
  }, [dispatch]);

  useEffect(() => {
    if (getDashboardDetails || getDashboardDetails === null) {
      setLoading(false);
    } else {
      setLoading(true);
    }
  }, [getDashboardDetails]);

  if (loading) {
    return <FuseLoading />;
  }

  return (
    <div className="p-20 lg:p-24 l-padding">
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-20 lg:gap-40">
        <div className="shadow-md bg-white rounded-lg md:p-24 p-16">
          <div className="flex justify-between items-center">
            <div>
              <span className="text-16 md:text-18 lg:text-20 font-semibold mb-10 block">
                {t('dashboard.dashboardSales.selectedAccount')}
              </span>
              <span className="text-32 text-darkgreen font-semibold block">
                {getDashboardDetails?.salesUserDashboardDataV1?.salesPersonPc?.selectedAccount || 0}
              </span>
            </div>
            <img
              src="/assets/images/sales-icon/selected_account.png"
              width="70px"
              height="70px"
              alt="selected_account"
            />
          </div>
          {/* <div className="font-medium text-14">
            {t('dashboard.dashboardSales.selected')}{' '}
            {getDashboardDetails?.salesUserDashboardDataV1?.totalDemoAccounts
              ?.onlyDemoAccountsCount || 0}{' '}
            + {t('dashboard.dashboardSales.visited')}{' '}
            {getDashboardDetails?.salesUserDashboardDataV1?.totalDemoAccounts?.visitedCount || 0} +{' '}
            {t('dashboard.dashboardSales.converted')}{' '}
            {getDashboardDetails?.salesUserDashboardDataV1?.totalDemoAccounts
              ?.totalConvertedAccountsCount || 0}{' '}
            + {t('dashboard.dashboardSales.deactivated')}{' '}
            {getDashboardDetails?.salesUserDashboardDataV1?.totalDemoAccounts
              ?.deactivatedVisitedAccountsCount || 0}
          </div> */}
        </div>
        <div className="shadow-md bg-white rounded-lg md:p-24 p-16">
          <div className="flex justify-between items-center">
            <div>
              <span className="text-16 md:text-18 lg:text-20 font-semibold mb-10 block text-black">
                {t('dashboard.dashboardSales.visitedAccount')}
              </span>
              <span className="text-32 text-darkgreen font-semibold block">
                {getDashboardDetails?.salesUserDashboardDataV1?.salesPersonPc?.visitedAccount || 0}
              </span>
            </div>
            <img
              src="/assets/images/sales-icon/visited_account.png"
              width="70px"
              height="70px"
              alt="visited_account"
            />
          </div>
          {/* <div className="font-medium text-14">
            {t('dashboard.dashboardSales.visited')}{' '}
            {getDashboardDetails?.salesUserDashboardDataV1?.totalVisitedAccounts
              ?.onlyVisitedAccountsCount || 0}{' '}
            + {t('dashboard.dashboardSales.converted')}{' '}
            {getDashboardDetails?.salesUserDashboardDataV1?.totalVisitedAccounts
              ?.totalConvertedAccountsCount || 0}{' '}
            + {t('dashboard.dashboardSales.deactivated')}{' '}
            {getDashboardDetails?.salesUserDashboardDataV1?.totalVisitedAccounts
              ?.deactivatedVisitedAccountsCount || 0}
          </div> */}
        </div>
        {/* <div className="shadow-md bg-white rounded-lg md:p-24 p-16">
          <div className="flex justify-between items-center">
            <div>
              <span className="text-16 md:text-18 lg:text-20 font-semibold mb-10 block text-black">
                {t('dashboard.dashboardSales.lastMonthVisitedAccount')}
              </span>
              <span className="text-32 text-darkgreen font-semibold block">
                {getDashboardDetails?.salesUserDashboardData?.lastMonthVisitedAccount || 0}
              </span>
            </div>
            <img
              src="/assets/images/sales-icon/last_month_visited_account.png"
              width="70px"
              height="70px"
              alt="last_month_visited_account"
            />
          </div>
        </div> */}
        <div className="shadow-md bg-white rounded-lg md:p-24 p-16">
          <div className="flex justify-between items-center">
            <div>
              <span className="text-16 md:text-18 lg:text-20 font-semibold mb-10 block text-black">
                {t('dashboard.dashboardSales.convertedBusiness')}
              </span>
              <span className="text-32 text-darkgreen font-semibold block">
                {getDashboardDetails?.salesUserDashboardDataV1?.salesPersonPc?.convertedBusiness ||
                  0}
              </span>
            </div>
            <img
              src="/assets/images/sales-icon/converted_business.png"
              width="70px"
              height="70px"
              alt="last_month_visited_account"
            />
          </div>
        </div>
        <div className="shadow-md bg-white rounded-lg md:p-24 p-16">
          <div className="flex justify-between items-center">
            <div>
              <span className="text-16 md:text-18 lg:text-20 font-semibold mb-10 block text-black">
                {t('dashboard.dashboardSales.activeBusiness')}
              </span>
              <span className="text-32 text-darkgreen font-semibold block">
                {getDashboardDetails?.salesUserDashboardDataV1?.salesPersonPc?.activeBusiness || 0}
              </span>
            </div>
            <img
              src="/assets/images/sales-icon/active_business.png"
              width="70px"
              height="70px"
              alt="last_month_visited_account"
            />
          </div>
        </div>
      </div>
      <div className="lg:flex gap-20 lg:gap-40 mt-20 lg:mt-40 l-width l-block">
        <div className="sm:flex gap-20 lg:gap-40 w-full lg:w-7/12 l-width">
          <div className="shadow-md bg-white rounded-lg p-20 w-full sm:w-1/2">
            <h3 className="text-16 md:text-18 lg:text-20 font-semibold text-black pb-10 max-w-288">
              {t('dashboard.dashboardSales.totalDemoAccountToRealAccount')}
            </h3>
            <div>
              {getChartRealAccount(
                [
                  parseInt(
                    getDashboardDetails?.salesUserDashboardData?.totalDemoAccountToRealAccount
                      ?.demoAccountsCount,
                    10
                  ) || 0,
                  parseInt(
                    getDashboardDetails?.salesUserDashboardData?.totalDemoAccountToRealAccount
                      ?.convertedAccountsCount,
                    10
                  ) || 0,
                ],
                ['Demo Account', 'Real Account'],
                1,
                '#0BA861',
                'bar'
              )}
            </div>
          </div>
          <div className="shadow-md bg-white rounded-lg p-20 w-full sm:w-1/2 mt-20 sm:mt-0">
            <div className="md:flex items-start justify-between pb-10">
              <h3 className="text-16 md:text-18 lg:text-20 font-semibold text-black">
                {t('dashboard.dashboardSales.demoAccountToRealAccount')}
              </h3>
              <span className="text-12 font-semibold text-black rounded-xl bg-grey-300 px-10 py-5 block min-w-96 text-center h-auto">
                {t('dashboard.dashboardSales.last30Days')}
              </span>
            </div>
            <div>
              {getChartRealAccount(
                [
                  parseInt(
                    getDashboardDetails?.salesUserDashboardData?.salesPersonsActivityInMonth
                      ?.demoAccountsCount,
                    10
                  ) || 0,
                  parseInt(
                    getDashboardDetails?.salesUserDashboardData?.salesPersonsActivityInMonth
                      ?.convertedAccountsCount,
                    10
                  ) || 0,
                ],
                ['Demo Account', 'Real Account'],
                1,
                '#0BA861',
                'bar'
              )}
            </div>
          </div>
        </div>
        <div className="shadow-md bg-white rounded-lg p-20 w-full lg:w-5/12 mt-20 lg:mt-0 l-width">
          <div className="md:flex items-center justify-between">
            <h3 className="text-16 md:text-18 lg:text-20 font-semibold text-black">
              {t('dashboard.dashboardAdmin.topBusinessTypes')}
            </h3>
            <span className="text-14 font-semibold text-black">
              {t('dashboard.dashboardAdmin.total')} {totalBusinessType?.topBusinessType?.length}{' '}
              {t('dashboard.dashboardAdmin.totalBusinessTypes')}
            </span>
          </div>
          <div>
            {lastBusiness?.length > 0 ? (
              getChartSimple(
                lastBusiness?.map((bType) => bType?.value?.numTotal),
                lastBusiness?.map((bType) => bType?.name),
                1,
                '#03CEF3',
                'bar'
              )
            ) : (
              <div className="pt-156">
                <p className="mt-4 text-lg text-gray-500 text-center" style={{ padding: '150px' }}>
                  {t('common.noData')}
                </p>
              </div>
            )}
          </div>
        </div>
      </div>
      {followUpData?.length > 0 && (
        <div className="pt-28">
          <UpcomingFollowUp
            followUpData={followUpData}
            handleRedirect={handleRedirect}
            totalCount={totalCount}
          />
        </div>
      )}
    </div>
  );
}
export default withReducer('salesDashboardsReducer', reducer)(Dashboards);
