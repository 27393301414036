const getBusinessQRDetail = `
	query ($businessQRId: String!) {
		getBusinessQRDetail(businessQRId: $businessQRId) {
      data {
        user_businesses_attribute_level_threshold {
          face
          star
          thumbs
        }
        id
        name
        platFormURL
        reviewModeTypeId
        reviewModeTypeName
        isAutoTextGenerate
        isAutoThankYouReply
        isNegativeReviewRedirect
        autoGenerateReviewComplexityTypeId
        isExpire
        isNegativeReviewsPrevention
        userId
        isAmazonProduct
        amazonProduct {
          id
          placeId
          title
        }
        isFlipkartProduct
        flipkartProduct{
          id
          placeId
          productQrId
          title
        }
        isOnlineBusiness
        address
        user_package_subscription {
          id
          isActive
          expireDate
          packageId
        }
        review_platforms {
          id
          name
        }
        business_types {
          bType
          image
          business_attributes {
          bAttribute
          bAttributeId
          icon
          isCustomAttribute
          business_attribute_values{
            id
            type
            reaction
          }
        }
        } 
      }  
			message
			status
		}
	}
`;

const getAmazonProductDetailsBaseOnId = `
	query ($amazonProductId: Int!) {
		getAmazonProductDetailsBaseOnId(amazonProductId: $amazonProductId) {
      data {
        user_businesses_attribute_level_threshold {
          face
          star
          thumbs
        }
        id
        platFormURL
        genericName
        autoGenerateReviewComplexityTypeId
        id
        isExpire
        isNegativeReviewRedirect
        isNegativeReviewsPrevention
        isAutoTextGenerate
        isAutoThankYouReply
        name
        userId
        profileUrl
        reviewModeTypeId
        reviewModeTypeName
        userBusinessId
        review_platforms {
          id
          name
        }
        business_types {
          bType
          business_attributes {
            id
            bAttribute
            bAttributeId
            business_attribute_values {
              id
              reaction
              type
            }
          }
        }
      }
      message
      status  
		}
	}
`;

const getFlipkartProductDetailsBaseOnId = `
	query ($flipkartProductId: Int!) {
		getFlipkartProductDetailsBaseOnId(flipkartProductId: $flipkartProductId) {
      data {
        user_businesses_attribute_level_threshold {
          face
          star
          thumbs
        }
        id
        platFormURL
        genericName
        autoGenerateReviewComplexityTypeId
        id
        isExpire
        name
        userId
        profileUrl
        reviewModeTypeId
        reviewModeTypeName
        isNegativeReviewRedirect
        isNegativeReviewsPrevention
        isAutoTextGenerate
        isAutoThankYouReply
        reviewUrl
        productQrId
        userBusinessId
        review_platforms {
          id
          name
        }
        business_types {
          bType
          business_attributes {
            bAttribute
            bAttributeId
            business_attribute_values {
              id
              reaction
              type
            }
          }
        }
      }
      message
      status  
		}
	}
`;

const submitBusinessReview = `
mutation SubmitBusinessReview($attributeDetail: [attributeDetailType!], $businessAgentDetailsId: Int, $businessId: Int, $amazonProductId: Int, $flipkartProductId: Int, $reviewDescription: String!, $reviewPlatformId: Int!, $phoneNumber: PhoneNumberInput, $businessProductsAndServicesId: [Int!], $rating: Int, $reviewerName: String, $isAutoThankYouSupport: Boolean, $isAutogenerate: Boolean, $isNegativeReview: Boolean) {
  submitBusinessReview(
    attributeDetail: $attributeDetail,
    businessAgentDetailsId: $businessAgentDetailsId,
    businessId: $businessId
    amazonProductId: $amazonProductId,
    flipkartProductId: $flipkartProductId,
    reviewDescription: $reviewDescription
    reviewPlatformId: $reviewPlatformId
    phoneNumber: $phoneNumber
    businessProductsAndServicesId: $businessProductsAndServicesId
    rating: $rating
    reviewerName: $reviewerName
    isAutoThankYouSupport: $isAutoThankYouSupport
    isAutogenerate: $isAutogenerate
    isNegativeReview: $isNegativeReview
  ) {
    data {
      id
    }
    message
    status
  }
}
`;

const updateReviewPhoneNumber = `
mutation ($businessReviewsId: Int!, $countryCode: String!, $phoneNumber: String!) {
  updateReviewPhoneNumber(data: {
    businessReviewsId: $businessReviewsId,
    phoneNumber: {
      countryCode: $countryCode,
      phoneNumber: $phoneNumber
    }
  }) {
    status
    message
  }
}
`;
const addVisitorCountBasedOnIPAddress = `
mutation addVisitorCountBasedOnIPAddress($businessId: Int , $trackingUniqueId:String ) {
  addVisitorCountBasedOnIPAddress(businessId: $businessId, trackingUniqueId:$trackingUniqueId) {
    status
    message
  }
}
`;

const getBusinessServicesAndProductByBusinessId = `
query getBusinessServicesAndProductByBusinessId($businessId: Int!, $type: String ) {
  getBusinessServicesAndProductByBusinessId(businessId: $businessId, type: $type) {
    status
    message
    data
  }
}
`;

const getBusinessAgentDetailsOnPortalSideUsingBusinessQRId = `
query getBusinessAgentDetailsOnPortalSideUsingBusinessQRId(
  $businessId: Int!
  $businessQrId: String
) {
  getBusinessAgentDetailsOnPortalSideUsingBusinessQRId(
    businessId: $businessId
    businessQrId: $businessQrId
  ) {
    status
    message
    defaultAgent {
      id
      name
      role
      cts
      uts
    }
    allAgent {
      id
      name
      role
      cts
      uts
    }
  }
}
`;

const isReviewNegative = `
mutation isReviewNegative($review: String!, $attributes: String! ) {
  isReviewNegative(review: $review, attributes: $attributes) {
    status
    message
    isNegative
  }
}
`;

const requestCallForNegativeReviews = `
mutation requestCallForNegativeReviews($autoGeneratedReview: String, $businessId: Int, $countryCode: String!, $name: String!, $phoneNumber: Float!, $reason: String, $userId: Int!, $amazonProductId: Int, $flipkartProductId: Int) {
  requestCallForNegativeReviews(autoGeneratedReview: $autoGeneratedReview, businessId: $businessId, countryCode: $countryCode, name: $name, phoneNumber: $phoneNumber, reason: $reason, userId: $userId, amazonProductId: $amazonProductId, flipkartProductId: $flipkartProductId ) {
    status
    message
  }
}
`;

const autoGenerateReview = `
query autoGenerateReview($data: String! ) {
  autoGenerateReview(data: $data) {
    status
    message
    data
  }
}
`;

const getAllRemainingCountV1 = `
query getAllRemainingCountV1($businessId: Int!, $userId: Int! ) {
  getAllRemainingCountV1(businessId: $businessId, userId: $userId) {
    status
    message
    data{
      autoGenerateReview{
        availableCount
        useCount
      }
      negativeReviewRemainingCount{
        availableCount
        useCount
      }
    }
  }
}
`;

const qrData = {
  getBusinessQRDetail,
  getAmazonProductDetailsBaseOnId,
  getFlipkartProductDetailsBaseOnId,
  submitBusinessReview,
  updateReviewPhoneNumber,
  addVisitorCountBasedOnIPAddress,
  getBusinessServicesAndProductByBusinessId,
  getBusinessAgentDetailsOnPortalSideUsingBusinessQRId,
  isReviewNegative,
  requestCallForNegativeReviews,
  autoGenerateReview,
  getAllRemainingCountV1,
};

export default qrData;
