import { Typography } from '@mui/material';
import { t } from 'i18next';
import { useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import SelectBusiness from './SelectBusiness';
import SelectPromotionType from './SelectPromotionType';
import FixDiscount from './FixedDiscount';
import PercentageDiscount from './PercentageDiscount';
import promotionQuery from '../../../query/promotion';
import {
  getLoyaltyPointsType,
  getPromotionAndRedeemPreviewImages,
  getPromotionApplicableForType,
  getPromotionApplyTo,
  getPromotionButtonType,
  getPromotionLoyaltyDurationType,
} from '../store/promotionSlice';
import BuyXGetYFree from './BuyXGetYFree';
import LoyaltyPoints from './LoyaltyPoints';
import userService from '../../../../services';

const NewPromotion = () => {
  const dispatch = useDispatch();
  const [selectBusiness, setSelectedBusiness] = useState();
  const [selectPromotionTypeId, setSelectPromotionTypeId] = useState();
  const [promotionApplicableData, setPromotionApplicableData] = useState([]);
  const [buttonTypeData, setButtonTypeData] = useState([]);
  const [previewImages, setPreviewImages] = useState([]);
  const [images, setImages] = useState();
  const [loyaltyPointsData, setLoyaltyPointsData] = useState([]);
  const [loyaltyDurationType, setLoyaltyDurationType] = useState([]);
  const [promotionApplyTo, setPromotionApplyTo] = useState([]);
  const loginUserData = userService.getUserData()?.countryId;
  useEffect(() => {
    const fetchPromotionType = async () => {
      const payload = {
        query: promotionQuery.getPromotionApplicableForType,
      };
      const result = await dispatch(getPromotionApplicableForType(payload));
      setPromotionApplicableData(result?.payload?.getPromotionApplicableForType?.data);
    };
    fetchPromotionType();
  }, [dispatch]);

  useEffect(() => {
    const fetchPromotionApplyTo = async () => {
      const payload = {
        query: promotionQuery.getPromotionApplyTo,
      };
      const result = await dispatch(getPromotionApplyTo(payload));
      setPromotionApplyTo(result?.payload?.getPromotionApplyTo?.data);
    };
    fetchPromotionApplyTo();
  }, [dispatch]);

  useEffect(() => {
    const fetchPromotionType = async () => {
      const payload = {
        query: promotionQuery.getPromotionAndRedeemPreviewImages,
        variables: {
          type: 'promotion',
          pageNumber: 1,
          pageSize: 20,
        },
      };
      const result = await dispatch(getPromotionAndRedeemPreviewImages(payload));
      setPreviewImages(result?.payload?.getPromotionAndRedeemPreviewImages?.data);
      setImages(result?.payload?.getPromotionAndRedeemPreviewImages?.data?.[0].image);
    };
    fetchPromotionType();
  }, [dispatch]);

  useEffect(() => {
    const fetchLoyaltyPoints = async () => {
      const payload = {
        query: promotionQuery.getLoyaltyPointsType,
      };
      const result = await dispatch(getLoyaltyPointsType(payload));
      if (result?.payload?.getLoyaltyPointsType?.status === 200) {
        const data = result?.payload?.getLoyaltyPointsType?.data;
        const sortData = data?.sort((a, b) => a.id - b.id);
        setLoyaltyPointsData(sortData);
      }
    };
    fetchLoyaltyPoints();
  }, [dispatch]);

  useEffect(() => {
    const fetchLoyaltyDuration = async () => {
      const payload = {
        query: promotionQuery.getPromotionLoyaltyDurationType,
      };
      const result = await dispatch(getPromotionLoyaltyDurationType(payload));
      if (result?.payload?.getPromotionLoyaltyDurationType?.status === 200) {
        setLoyaltyDurationType(result?.payload?.getPromotionLoyaltyDurationType?.data);
      }
    };
    fetchLoyaltyDuration();
  }, [dispatch]);

  useEffect(() => {
    const fetchPromotionType = async () => {
      const payload = {
        query: promotionQuery.getPromotionButtonType,
      };
      const result = await dispatch(getPromotionButtonType(payload));
      setButtonTypeData(result?.payload?.getPromotionButtonType?.data);
    };
    fetchPromotionType();
  }, [dispatch]);

  return (
    <div className="p-20 lg:p-24">
      <Typography
        initial={{ x: -20 }}
        animate={{ x: 0, transition: { delay: 0.2 } }}
        delay={300}
        className="sm:flex text-16 md:text-28 mb-24 font-bold"
      >
        {t('promotion.newPromotions')}
      </Typography>
      <SelectBusiness setSelectedBusiness={setSelectedBusiness} selectBusiness={selectBusiness} />
      <SelectPromotionType
        selectPromotionTypeId={selectPromotionTypeId}
        setSelectPromotionTypeId={setSelectPromotionTypeId}
      />
      {selectPromotionTypeId === 1 && (
        <FixDiscount
          images={images}
          setImages={setImages}
          selectBusiness={selectBusiness}
          selectPromotionTypeId={selectPromotionTypeId}
          promotionApplicableData={promotionApplicableData}
          buttonTypeData={buttonTypeData}
          previewImages={previewImages}
          countryId={loginUserData}
        />
      )}
      {selectPromotionTypeId === 2 && (
        <PercentageDiscount
          images={images}
          setImages={setImages}
          selectBusiness={selectBusiness}
          selectPromotionTypeId={selectPromotionTypeId}
          promotionApplicableData={promotionApplicableData}
          buttonTypeData={buttonTypeData}
          previewImages={previewImages}
          countryId={loginUserData}
        />
      )}
      {selectPromotionTypeId === 3 && (
        <BuyXGetYFree
          images={images}
          setImages={setImages}
          selectBusiness={selectBusiness}
          selectPromotionTypeId={selectPromotionTypeId}
          promotionApplicableData={promotionApplicableData}
          buttonTypeData={buttonTypeData}
          previewImages={previewImages}
          countryId={loginUserData}
        />
      )}
      {selectPromotionTypeId === 4 && (
        <LoyaltyPoints
          images={images}
          setImages={setImages}
          selectBusiness={selectBusiness}
          selectPromotionTypeId={selectPromotionTypeId}
          promotionApplicableData={promotionApplicableData}
          buttonTypeData={buttonTypeData}
          previewImages={previewImages}
          loyaltyPointsData={loyaltyPointsData}
          loyaltyDurationType={loyaltyDurationType}
          promotionApplyTo={promotionApplyTo}
          countryId={loginUserData}
        />
      )}
    </div>
  );
};

export default NewPromotion;
