import { Box, Typography, Tab, Tabs, IconButton } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import React, { useCallback, useEffect, useState } from 'react';
import { t } from 'i18next';
import FuseLoading from '@fuse/core/FuseLoading';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { selectDashboardData } from 'app/store/userSlice';
import QuickChat from './QuickChat';
import BotResponse from './BotResponse';
import HumanInteraction from './HumanInteraction';
import quickChatQuery from '../../query/quickChat';
import { getDecryptData, handleApiRequest, handleWabaApiRequest } from '../../common/common';
import wabaQuery from '../../query/waba';

import manageBusinessData from '../../query/manageBusiness';

const BotDetails = () => {
  const location = useLocation();
  const [activeStep, setActiveStep] = useState(
    location?.state?.item?.cardName === 'configureTheBotResponses' ||
      location?.state?.task?.cardName === 'configureTheBotResponses' ||
      location?.state?.taskCard?.screen === 'configureTheBotResponses' ||
      location?.state?.cardName === 'quickChatImproveBotReply'
      ? 1
      : 0 || 0
  );
  const [pageLoading, setPageLoading] = useState(true);
  const [quickChatDetails, setQuickChatDetails] = useState({});
  const [selectAvatarUrl, setSelectAvatarUrl] = useState('');
  const [color, setColor] = useState('#0BA861');
  const [imageUrl, setImageUrl] = useState();
  const [avatarList, setAvatarList] = useState([]);
  const [quickChatEdit, setQuickChatEdit] = useState(false);
  const [pageSize, setPageSize] = useState(10);
  const [pageNumber, setPageNumber] = useState(1);
  const [whatsAppPhoneNumberId, setWhatsAppPhoneNumberId] = useState('');
  const [domain, setDomain] = useState({});
  const [getQRData, setGetQRData] = useState([]);
  const [totalCount, setTotalCount] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [quickChatActiveData, setQuickChatActiveData] = useState({});
  const userCurrentBusinessDetails = useSelector(selectDashboardData);
  const [quickChatCreate, setQuickChatCreate] = useState(false);
  const [botData, setBotData] = useState({
    botName: quickChatDetails?.name || 'Quick Chat',
    domainName:
      quickChatDetails?.domain ||
      `${
        userCurrentBusinessDetails?.city?.toLowerCase() ||
        userCurrentBusinessDetails?.countries?.name?.toLowerCase()
      }/${userCurrentBusinessDetails?.name
        ?.replace(/[\s-]+/g, '')
        .replace(/[^a-zA-Z0-9]/g, '')
        .toLowerCase()}`,
  });
  const [isEmpty, setIsEmpty] = useState({ botName: false, domainName: false });
  const [imageUpload, setImageUpload] = useState();
  const [showBotResponse, setShowBotResponse] = useState(false);
  const [editIndex, setEditIndex] = useState(null);
  const [editData, setEditData] = useState({ question: '', response: '' });
  const [isEdit, setIsEdit] = useState(false);
  const handleClose = () => {
    setQuickChatEdit(false);
    setQuickChatCreate(false);
    setBotData((prevBotData) => ({
      ...prevBotData,
      domainName: quickChatDetails?.domain,
      botName: quickChatDetails?.name,
    }));
    setIsEmpty({ botName: false, domainName: false });
    setImageUpload();
    setImageUrl(quickChatDetails?.avatar);
  };

  const onClose = () => {
    setIsEdit(false);
    setEditData({ question: '', response: '' });
    setEditIndex(null);
    setShowBotResponse(false);
  };

  useEffect(() => {
    const fetchAgentSupportStatus = async () => {
      try {
        if (userCurrentBusinessDetails?.id && !userCurrentBusinessDetails?.isOnlineBusiness) {
          setPageLoading(true);
          const payloadData = {
            query: quickChatQuery.getQuickChat,
            variables: {
              businessId: parseInt(userCurrentBusinessDetails?.id, 10),
            },
          };
          const resultData = await handleApiRequest(payloadData);
          setPageLoading(false);
          if (
            resultData?.getQuickChat?.status === 200 &&
            resultData?.getQuickChat?.message !== 'Not found.'
          ) {
            setImageUrl(resultData?.getQuickChat?.data?.avatar);
            setQuickChatDetails(resultData?.getQuickChat?.data);
            setSelectAvatarUrl(resultData?.getQuickChat?.data?.avatar);
            setColor(resultData?.getQuickChat?.data?.headerColor);
            setDomain(resultData?.getQuickChat?.data);
          }
        }
      } catch (error) {
        console.error('Error:', error);
      }
    };

    fetchAgentSupportStatus();
  }, [userCurrentBusinessDetails]);

  const getQuestionResponseData = useCallback(async () => {
    try {
      setIsLoading(true);
      const payload = {
        query: manageBusinessData.getQuickChatBotQuestionAndResponses,
        variables: {
          domain: quickChatActiveData?.domain,
          pageSize,
          businessId: parseInt(userCurrentBusinessDetails?.id, 10),
          pageNumber,
        },
      };
      const result = await handleApiRequest(payload);
      if (result?.getQuickChatBotQuestionAndResponses?.status === 200) {
        setGetQRData(result?.getQuickChatBotQuestionAndResponses?.data);
        setTotalCount(result?.getQuickChatBotQuestionAndResponses?.totalCount);
      }
    } catch (err) {
      console.error('Error fetching data:', err);
    } finally {
      setIsLoading(false);
    }
  }, [quickChatActiveData, pageSize, pageNumber, userCurrentBusinessDetails?.id]);

  useEffect(() => {
    if (userCurrentBusinessDetails?.id && quickChatActiveData?.domain) {
      getQuestionResponseData();
    }
  }, [getQuestionResponseData, userCurrentBusinessDetails?.id, quickChatActiveData?.domain]);

  const fetchAgentSupport = useCallback(async () => {
    try {
      if (userCurrentBusinessDetails?.id && !userCurrentBusinessDetails?.isOnlineBusiness) {
        const payloadData = {
          query: quickChatQuery.getQuickChatDetails,
          variables: {
            businessId: Number(userCurrentBusinessDetails?.id),
          },
        };
        const resultData = await handleApiRequest(payloadData);
        if (resultData?.getQuickChatDetails?.status === 200) {
          setQuickChatActiveData(resultData?.getQuickChatDetails?.data);
        }
      }
    } catch (error) {
      console.error('An error occurred:', error);
    }
  }, [userCurrentBusinessDetails?.id, userCurrentBusinessDetails?.isOnlineBusiness]);

  useEffect(() => {
    fetchAgentSupport();
  }, [fetchAgentSupport]);

  const fetchAgentSupportStatus = useCallback(async () => {
    const payloadData = {
      query: quickChatQuery.getQuickChatDefaultAvatar,
      variables: {
        businessId: Number(userCurrentBusinessDetails?.id),
      },
    };
    const resultData = await handleApiRequest(payloadData);
    if (resultData?.getQuickChatDefaultAvatar?.status === 200) {
      setAvatarList(resultData?.getQuickChatDefaultAvatar?.data);
    }
  }, [userCurrentBusinessDetails?.id]);

  useEffect(() => {
    if (avatarList?.length === 0 && userCurrentBusinessDetails?.id) {
      fetchAgentSupportStatus();
    }
  }, [avatarList?.length, fetchAgentSupportStatus, userCurrentBusinessDetails?.id]);

  useEffect(() => {
    const fetchUserDetails = async () => {
      if (userCurrentBusinessDetails?.id) {
        try {
          const payload = {
            query: wabaQuery.getUserDetail,
            variables: {
              quickReviewBusinessId: Number(userCurrentBusinessDetails?.id),
            },
          };
          const res = await handleWabaApiRequest(payload);
          if (res?.getUserDetail?.status === 200) {
            if (res?.getUserDetail?.data) {
              const decryptedData = JSON.parse(getDecryptData(res.getUserDetail.data));
              setWhatsAppPhoneNumberId(decryptedData?.whatsAppPhoneNumberId);
            }
          }
        } catch (error) {
          console.error('An error occurred:', error);
        }
      }
    };
    fetchUserDetails();
  }, [userCurrentBusinessDetails?.id]);

  if (pageLoading) {
    return <FuseLoading />;
  }

  return (
    <div className="lg:p-24 p-16 font-quicksand flex flex-col gap-20">
      {userCurrentBusinessDetails?.isOnlineBusiness ? (
        <div className="h-2xl w-full flex flex-col justify-center items-center">
          <div className="w-512 flex flex-col gap-20 items-center">
            <img className="max-w-96" src="/assets/images/icon/chatUnavailable.png" alt="" />
            <p className="text-24 text-center font-600">{t('quickChat.chatbotForOnline')}</p>
          </div>
        </div>
      ) : (
        <>
          <div className="flex gap-5">
            <IconButton
              onClick={() => {
                onClose();
                handleClose();
              }}
              className="text-black"
            >
              <ArrowBackIcon />
            </IconButton>
            <Typography className="md:text-28 text-20 font-semibold">
              {t('quickChat.botDetails.title')}
            </Typography>
          </div>
          <div className="border-b-3 border-solid border-grey sm:flex justify-between items-center quick-chat">
            <Tabs
              value={activeStep}
              onChange={(e, newValue) => setActiveStep(newValue)}
              indicatorColor="secondary"
              aria-label="secondary tabs example"
            >
              <Tab
                className={`sm:text-18 font-semibold p-0 sm:mr-28 mr-14 ${
                  activeStep === 0 ? 'text-quick-chat' : 'text-black'
                }`}
                value={0}
                label={t('quickChat.botDetails.botInfo')}
              />
              <Tab
                className={`sm:text-18 font-semibold p-0 sm:mr-28 mr-14 ${
                  activeStep === 1 ? 'text-quick-chat' : 'text-black'
                }`}
                value={1}
                label={t('quickChat.botDetails.botResponse')}
              />
              <Tab
                className={`sm:text-18 font-semibold p-0 ${
                  activeStep === 2 ? 'text-quick-chat' : 'text-black'
                }`}
                value={2}
                label={t('quickChat.botDetails.humanInteraction')}
              />
            </Tabs>
          </div>
          <div>
            {activeStep === 0 && (
              <Box>
                <QuickChat
                  setActiveStep={setActiveStep}
                  setPageLoading={setPageLoading}
                  quickChatDetails={quickChatDetails}
                  selectAvatarUrl={selectAvatarUrl}
                  color={color}
                  imageUrl={imageUrl}
                  setSelectAvatarUrl={setSelectAvatarUrl}
                  setImageUrl={setImageUrl}
                  setQuickChatDetails={setQuickChatDetails}
                  setColor={setColor}
                  whatsAppPhoneNumberId={whatsAppPhoneNumberId}
                  avatarList={avatarList}
                  setQuickChatEdit={setQuickChatEdit}
                  quickChatEdit={quickChatEdit}
                  quickChatActiveData={quickChatActiveData}
                  setQuickChatActiveData={setQuickChatActiveData}
                  fetchAgentSupportStatus={fetchAgentSupport}
                  quickChatCreate={quickChatCreate}
                  setQuickChatCreate={setQuickChatCreate}
                  botData={botData}
                  setBotData={setBotData}
                  isEmpty={isEmpty}
                  setIsEmpty={setIsEmpty}
                  imageUpload={imageUpload}
                  setImageUpload={setImageUpload}
                  handleClose={handleClose}
                />
              </Box>
            )}
            {activeStep === 1 && (
              <Box>
                <BotResponse
                  setActiveStep={setActiveStep}
                  getQuestionResponseData={getQuestionResponseData}
                  pageNumber={pageNumber}
                  setPageNumber={setPageNumber}
                  pageSize={pageSize}
                  setPageSize={setPageSize}
                  getQRData={getQRData}
                  totalCount={totalCount}
                  isLoading={isLoading}
                  showBotResponse={showBotResponse}
                  setShowBotResponse={setShowBotResponse}
                  editIndex={editIndex}
                  setEditIndex={setEditIndex}
                  editData={editData}
                  setEditData={setEditData}
                  setIsEdit={setIsEdit}
                  isEdit={isEdit}
                  onClose={onClose}
                />
              </Box>
            )}
            {activeStep === 2 && (
              <Box>
                <HumanInteraction
                  setActiveStep={setActiveStep}
                  isLoading={isLoading}
                  quickChatDetails={quickChatDetails}
                />
              </Box>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default BotDetails;
