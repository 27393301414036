const authLoginMutation = `mutation login($data: String!) {
    login(data: $data) {
        status
        message
        accessToken
        refreshToken
        user {
          id
          cts
          uts
          firstName
          lastName
          fullName
          domainRegisterId
          deviceId
          email
          middleName
          address
          address2
          subUserType
          isSupportMultipleBusinessConnect
          zipcode
          stateId
          countryId
          countryCode
          currency
          isoCode
          profileUrl
          roleId
          roles{
            id
            type
          }
          userStatusId
          privacyPolicyAccept
          packagePlanId
          phoneNumber {
            isoCode
            countryCode
            phoneNumber
          }
          timeZone
        }
        business {
          id
          cts
          uts
          name
          email
          address
          address2
          city
          stateId
          countryId
          phoneNumber {
            isoCode
            countryCode
            phoneNumber
          }
          businessQRId
          reviewPlatformId
          businessTypeId
          website
          userId
          profileURL
          user_business_review_platforms {
            id
            isActive
            userBusinessId
            reviewPlatformId
            placeId
            url
          }
        }
      }    
  }`;

const verifyToken = `mutation verifyToken($token: JWT!){
  verifyToken(token: $token){
    status
    message
    data{
      accessToken
      id
      firstName
      countryCode
      countryId
      symbol
      currency
      isoCode
      lastName
      email
      userStatusId
      subUserType
      roleId
      domainRegisterId
      roles{
        id
        type
      }
      cts
      uts
      profileUrl
      countryId
      countries {
        name
        id
      }
    }
  }
}
`;
const sendForgotPasswordLink = `
mutation( $data: String!){
  sendForgotPasswordLink(data: $data) { 
    message
    status 
  }
}
`;

const googleAuth = `mutation googleAuth($data: String!) {
  googleAuth(data: $data) {
      status
      message
      accessToken
      refreshToken
      user {
        id
        cts
        uts
        firstName
        lastName
        fullName
        domainRegisterId
        deviceId
        email
        middleName
        address
        address2
        zipcode
        stateId
        countryId
        countryCode
        currency
        isoCode
        profileUrl
        subUserType
        roleId
        roles{
          id
          type
        }
        userStatusId
        privacyPolicyAccept
        packagePlanId
        phoneNumber {
          isoCode
          countryCode
          phoneNumber
        }
        timeZone
      }
      business {
        id
        cts
        uts
        name
        email
        address
        address2
        city
        stateId
        countryId
        phoneNumber {
          isoCode
          countryCode
          phoneNumber
        }
        businessQRId
        reviewPlatformId
        businessTypeId
        website
        userId
        profileURL
        user_business_review_platforms {
          id
          isActive
          userBusinessId
          reviewPlatformId
          placeId
          url
        }
      }
    }    
}`;
const asPreviouslyRegesterInGoogle = `
query( $data: String!){
  asPreviouslyRegesterInGoogle(data: $data) { 
    message
    status 
    available
  }
}
`;

const sendOTPOnPhoneNumberForLogin = `
mutation( $countryCode: String, $isoCode: String, $phoneNumber: Float){
  sendOTPOnPhoneNumberForLogin(countryCode: $countryCode, isoCode: $isoCode, phoneNumber: $phoneNumber) { 
    message
    status 
  }
}
`;
const asPreviouslyRegesterInApple = `query($data: String!){
  asPreviouslyRegesterInApple(data:$data){
    status
    message
    available
  }
}`;
const appleAuthV2 = `mutation appleAuthV2($data: String!){
  appleAuthV2(data: $data){
    status
    message
    accessToken
    refreshToken
    user {
      id
      cts
      uts
      firstName
      lastName
      fullName
      email
      middleName
      address
      address2
      zipcode
      stateId
      countryId
      isDefaultCountry
      roleId
      roles{
        id
        type
      }
      userStatusId
      privacyPolicyAccept
      subUserType
      packagePlanId
      phoneNumber {
        isoCode
        countryCode
        phoneNumber
      }
      timeZone
    }
    business {
      id
      cts
      uts
      name
      email
      address
      address2
      city
      stateId
      countryId
      phoneNumber {
        isoCode
        countryCode
        phoneNumber
      }
      businessQRId
      reviewPlatformId
      businessTypeId
      website
      userId
      profileURL
      user_business_review_platforms {
        id
        isActive
        userBusinessId
        reviewPlatformId
        placeId
        url
      }
    }
  }
}
`;

const verifyWhatsAppAuthToken = `
mutation ($data: String!){
 verifyWhatsAppAuthToken(data: $data){
    status
    message
    accessToken
    user {
      id
      phoneNumber{
        isoCode
        countryCode
        phoneNumber
      }
      firstName
      lastName
      fullName
      countryId
      packagePlanId
      email
      roles{
        id
        type
      }
      userType
      address
      address2
      city
      zipcode
      userStatusId
      profileUrl
      domainRegisterId
      deviceId
      roleId
    }
  }
}
`;

const Query = {
  authLoginMutation,
  verifyToken,
  sendForgotPasswordLink,
  googleAuth,
  asPreviouslyRegesterInGoogle,
  sendOTPOnPhoneNumberForLogin,
  asPreviouslyRegesterInApple,
  appleAuthV2,
  verifyWhatsAppAuthToken,
};

export default Query;
