import React, { useCallback, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import history from '@history';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import FuseLoading from '@fuse/core/FuseLoading';
import { handleApiRequest, imageDataToServer } from 'src/app/main/common/common';
import { useLocation } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import ConformationDialogBox from 'src/app/main/CommonComponent/ConformationDialogBox';
import { selectDashboardData } from 'app/store/userSlice';
import quickAdsQuery from 'src/app/main/query/quickAds';
import userService from 'src/app/services';
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Toolbar,
  Icon,
  Box,
  CircularProgress,
} from '@mui/material';
import ChoosePlatform from './choose-platform';
import AudienceSegmentBudget from './audienceSegment-budget';
import ChooseMedia from './choose-media';
import AdCopy from './adCopy';

const platforms = [
  {
    id: 1,
    name: 'Facebook',
    logo: 'https://upload.wikimedia.org/wikipedia/commons/5/51/Facebook_f_logo_%282019%29.svg',
    enabled: true,
  },
  {
    id: 2,
    name: 'Instagram',
    logo: 'https://upload.wikimedia.org/wikipedia/commons/a/a5/Instagram_icon.png',
    enabled: true,
  },
];

function CreateAds() {
  const location = useLocation();
  const userCurrentBusinessDetails = useSelector(selectDashboardData);
  const loginUserData = userService.getUserData();
  const countryId = Number(loginUserData?.countryId);
  const { adsData, data, campaignName, draftId } = location.state;
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [activeStep, setActiveStep] = useState(0);
  const [allFeaturesTrialDataId, setAllFeaturesTrialDataId] = useState();
  const [cardsData, setCardsData] = useState([]);
  const isInstagramConnected = cardsData?.find(
    (item) => item?.name === 'Instagram' && item?.isConnect
  );
  const [scheduledDate, setScheduledDate] = useState(
    adsData?.scheduleDate ? new Date(adsData?.scheduleDate) : new Date()
  );
  const [scheduledTime, setScheduledTime] = useState(
    adsData?.scheduleDate
      ? new Date(adsData?.scheduleDate)
      : new Date(new Date().getTime() + 5 * 60000)
  );
  const [refreshIcon, setRefreshIcon] = useState(false);
  const [budgetType, setBudgetType] = useState(adsData?.budget?.budgetType || 'daily_budget');
  const [amount, setAmount] = useState(adsData?.budget?.bidAmount || 0);
  const [platformStates, setPlatformStates] = useState(platforms);
  const [budgetAmount, setBudgetAmount] = useState(adsData?.budget?.totalBudget || 100);
  const [addedInterests, setAddedInterests] = useState(() => {
    const cities = adsData?.campaignOverview?.interest || [];
    const countries = adsData?.campaignOverview?.behavior || [];
    return cities?.length > 0 || countries?.length > 0
      ? [...new Set([...cities, ...countries])]
      : [];
  });
  const [addedAreas, setAddedAreas] = useState(() => {
    const cities =
      adsData?.campaignOverview?.geoLocation?.cities?.map((city) => ({
        ...city,
        type: city?.type || 'city',
      })) || [];
    const countries = adsData?.campaignOverview?.geoLocation?.countries || [];
    return cities?.length > 0 || countries?.length > 0
      ? [...new Set([...cities, ...countries])]
      : [];
  });
  const [timeRange, setTimeRange] = useState([7, 18]);
  const [selectedDays, setSelectedDays] = useState(adsData?.adsSchedule[0]?.days || []);
  const [ageRange, setAgeRange] = useState(
    adsData?.campaignOverview?.minAge
      ? [adsData?.campaignOverview?.minAge, adsData?.campaignOverview?.maxAge]
      : [20, 38]
  );
  const [mediaFiles, setMediaFiles] = useState(null);
  const [selected, setSelected] = useState([adsData?.destinationId] || null);
  const [headline, setHeadline] = useState(adsData?.title || '');
  const [primaryText, setPrimaryText] = useState(adsData?.description || '');
  const [instantFormTitle, setInstantFormTitle] = useState('Form Title');
  const [instantHeadline, setInstantHeadline] = useState('');
  const [instantSelected, setInstantSelected] = useState([{ key: 'full_name', type: 'FULL_NAME' }]);
  const [instantCTA, setInstantCTA] = useState();
  const [CTA, setCTA] = useState(adsData?.cta || null);
  const [frequency, setFrequency] = useState(adsData?.adFreqCap || 1);
  const [gender, setGender] = useState(adsData?.quickHubAdsAudienceSegment || 3);
  const [from, setFrom] = useState('');
  const [adsCampaignId, setAdsCampaignId] = useState(adsData?.id || null);
  const [openPreview, setOpenPreview] = useState(false);
  const [preview, setPreview] = useState(null);
  const [activeTab, setActiveTab] = useState(0);
  const [instantForm, setInstantForm] = useState(false);
  const [mobileApp, setMobileApp] = useState(null);
  const [appName, setAppName] = useState(null);
  const [endDate, setEndDate] = useState(!!adsData?.endDate);
  const [discard, setDiscard] = useState(false);
  const [termsAndCondition, setTermsAndCondition] = useState(false);
  const [TAndCURL, setTAndCURL] = useState('');
  const [scheduleEndDate, setScheduleEndDate] = useState(
    adsData?.endDate ? new Date(adsData?.endDate) : null
  );
  const [scheduleEndTime, setScheduleEndTime] = useState(
    adsData?.endDate ? new Date(adsData?.endDate) : null
  );
  const [phoneValue, setPhoneValue] = useState(adsData?.destinationDetails?.phoneNumber || null);
  const [website, setWebsite] = useState(adsData?.destinationDetails?.link || '');
  const [refresh, setRefresh] = useState(false);
  const [status, setStatus] = useState('');

  const [cityLongLat, setCityLongLat] = useState([]);
  const [regionLongLat, setRegionLongLat] = useState([]);
  const [prevCityKeysLength, setPrevCityKeysLength] = useState(0);
  const [prevRegionKeysLength, setPrevRegionKeysLength] = useState(0);
  const [radiusValue, setRadiusValue] = useState(countryId === 2 ? 17 : 10);

  const isButtonEnabled = platformStates?.some((platform) => platform?.enabled);

  useEffect(() => {
    setPlatformStates((prevPlatforms) =>
      prevPlatforms.map((platform) =>
        platform.name === 'Instagram' ? { ...platform, enabled: isInstagramConnected } : platform
      )
    );
  }, [isInstagramConnected]);

  const fetchCardDetails = useCallback(async () => {
    try {
      const payload = {
        query: quickAdsQuery.getAdsConnectApis,
        variables: {
          businessId: Number(userCurrentBusinessDetails?.id),
        },
      };
      const result = await handleApiRequest(payload);
      if (result?.getAdsConnectApis?.status === 200) {
        const cards = result?.getAdsConnectApis?.data || [];
        setCardsData(cards);
      } else if (result?.getAdsConnectApis?.status === 190) {
        enqueueSnackbar(
          result?.getAdsConnectApis?.errorMessage || result?.getAdsConnectApis?.message,
          {
            variant: 'error',
            autoHideDuration: 2000,
          }
        );
        setTimeout(() => {
          history.push(`/quick-ads/ads-social-connect/`);
        }, 1000);
      }
    } catch (error) {
      console.error('error', error);
    }
  }, [enqueueSnackbar, userCurrentBusinessDetails?.id]);

  useEffect(() => {
    if (userCurrentBusinessDetails?.id) {
      fetchCardDetails();
    }
  }, [fetchCardDetails, userCurrentBusinessDetails?.id]);

  const handleSetScheduleEndDate = useCallback(
    (date) => {
      if (scheduledDate && date && scheduledTime && scheduleEndTime) {
        const startDateTime = new Date(scheduledDate);
        const startTime = new Date(scheduledTime);
        startDateTime.setHours(
          startTime.getHours(),
          startTime.getMinutes(),
          startTime.getSeconds()
        );
        const endDateTime = new Date(date);
        const endTime = new Date(scheduleEndTime);
        endDateTime.setHours(endTime.getHours(), endTime.getMinutes(), endTime.getSeconds());
        const differenceInMilliseconds = endDateTime - startDateTime;
        const differenceInHours = differenceInMilliseconds / (1000 * 60 * 60);
        if (differenceInHours < 24) {
          enqueueSnackbar(
            'End date and time must be at least 24 hours after the start date and time.',
            {
              variant: 'error',
              autoHideDuration: 2000,
            }
          );
          return;
        }
      }
      setScheduleEndDate(date);
    },
    [scheduledDate, scheduledTime, scheduleEndTime, enqueueSnackbar]
  );

  useEffect(() => {
    if (scheduledDate && scheduledTime && scheduleEndDate && scheduleEndTime) {
      handleSetScheduleEndDate(scheduleEndDate);
    }
  }, [scheduledDate, scheduledTime, scheduleEndDate, scheduleEndTime, handleSetScheduleEndDate]);

  const interest =
    adsData?.campaignOverview?.interest ||
    addedInterests
      ?.filter((int) => int?.type === 'interests')
      ?.map((area) => ({ id: area?.id, name: area?.name }));

  const behavior =
    adsData?.campaignOverview?.behavior ||
    addedInterests
      ?.filter((int) => int?.type === 'behaviors')
      ?.map((area) => ({ id: area?.id, name: area?.name }));

  const countryKeys =
    addedAreas?.filter((area) => area?.type === 'country')?.map((area) => area?.key) ||
    adsData?.campaignOverview?.geoLocation?.countries;

  const state =
    addedAreas
      ?.filter((area) => area?.type === 'region')
      ?.map((area) => ({ key: area?.key, name: area?.name })) ||
    adsData?.campaignOverview?.geoLocation?.regions;

  const city =
    addedAreas
      ?.filter((area) => area?.type === 'city' || area?.type === 'neighborhood')
      ?.map((area) => ({ key: area?.key, name: area?.name, radius: area?.radius })) ||
    adsData?.campaignOverview?.geoLocation?.cities;

  const enabledPlatformNames = platformStates
    ?.filter((platform) => platform?.enabled)
    ?.map((platform) => platform?.name?.toLowerCase());

  const formatDateTime = (date, time) => {
    const originalDate = new Date(date);
    const formattedDat = originalDate?.toLocaleDateString('en-US', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    });
    const [month, day, year] = formattedDat.split('/');
    const desiredFormat = `${year}-${month}-${day}`;
    const setTime = time.toLocaleTimeString();
    const dateTimeString = `${desiredFormat} ${setTime}`;
    const dateObj = new Date(dateTimeString);
    dateObj?.setHours(dateObj.getHours() + 5);
    dateObj?.setMinutes(dateObj.getMinutes() + 30);
    const offset = dateObj.getTimezoneOffset();
    const hoursOffset = String(Math.floor(Math.abs(offset) / 60)).padStart(2, '0');
    const minutesOffset = String(Math.abs(offset) % 60).padStart(2, '0');
    const sign = offset > 0 ? '-' : '+';
    const formattedDate = dateObj
      ?.toISOString()
      ?.replace(/:\d{2}\.\d{3}Z$/, '')
      ?.concat(`${sign}${hoursOffset}:${minutesOffset}`);
    return formattedDate;
  };

  const redirect = () => {
    setTermsAndCondition(false);
    window.open(TAndCURL, '_blank');
  };

  const publishAds = useCallback(async () => {
    try {
      if (draftId || adsData?.id) {
        const payload = {
          query: quickAdsQuery.publishAds,
          variables: {
            adsCampaignId: draftId || adsData?.id,
          },
        };
        const result = await handleApiRequest(payload);
        if (result?.publishAds?.status === 200) {
          enqueueSnackbar(result?.publishAds?.message, {
            variant: 'success',
            autoHideDuration: 2000,
          });
          history.push('/quick-ads/ads-campaign/');
        } else if (result?.publishAds?.status === 190) {
          enqueueSnackbar(result?.publishAds?.errorMessage || result?.publishAds?.message, {
            variant: 'error',
            autoHideDuration: 2000,
          });
          setTimeout(() => {
            history.push(`/quick-ads/ads-social-connect/`);
          }, 1000);
        } else {
          enqueueSnackbar(result?.publishAds?.message, {
            variant: 'error',
            autoHideDuration: 2000,
          });
        }
      }
    } catch {
      console.error('An error occurred');
    }
  }, [adsData?.id, draftId, enqueueSnackbar]);

  const fetchAdsPreview = useCallback(
    async (Id) => {
      setRefresh(true);
      try {
        const payload = {
          query: quickAdsQuery.getAdsPreview,
          variables: {
            adsCampaignId,
            viewFor: activeTab === 0 ? 'FACEBOOK' : 'INSTAGRAM',
          },
        };
        const result = await handleApiRequest(payload);
        if (result?.getAdsPreview?.status === 200) {
          setPreview(result?.getAdsPreview?.data);
        }
        if (result?.getAdsPreview?.status === 190) {
          enqueueSnackbar(result?.getAdsPreview?.errorMessage || result?.getAdsPreview?.message, {
            variant: 'error',
            autoHideDuration: 2000,
          });
          setTimeout(() => {
            history.push(`/quick-ads/ads-social-connect/`);
          }, 1000);
        }
      } catch {
        console.error('An error occurred');
      }
      setRefresh(false);
    },
    [activeTab, adsCampaignId, enqueueSnackbar]
  );

  useEffect(() => {
    if (activeTab || (adsCampaignId && activeTab && status === 'Preview')) {
      fetchAdsPreview();
    }
  }, [adsCampaignId, activeTab, fetchAdsPreview, status]);

  const createAndUpdateAdsCampaign = useCallback(
    async (onClick) => {
      setRefreshIcon(true);
      try {
        const payload = {
          query: quickAdsQuery.createAndUpdateAdsCampaign,
          variables: {
            adDraftId: draftId || adsData?.id || null,
            userBusinessId: Number(userCurrentBusinessDetails?.id),
            campaign: {
              campaignName: campaignName || adsData?.name,
              objectiveId: data?.id || adsData?.quickHubAdsObjectiveId,
            },
            adSets: {
              genderSelected: gender,
              geoLocation:
                countryKeys?.length > 0 || city?.length > 0 || state?.length > 0
                  ? {
                      countries: countryKeys?.length > 0 ? countryKeys : null,
                      cities: city?.length > 0 ? city : null,
                      regions: state?.length > 0 ? state : null,
                    }
                  : null,
              ageRange: {
                maxAge: ageRange[1],
                minAge: ageRange[0],
              },
              publisherPlatforms: enabledPlatformNames,
              startDate:
                scheduledTime && scheduledDate
                  ? formatDateTime(scheduledDate, scheduledTime)
                  : null,

              endDate:
                endDate && scheduleEndDate && scheduleEndTime
                  ? formatDateTime(scheduleEndDate, scheduleEndTime)
                  : null,
              totalBudget: Number(budgetAmount) || null,
              bidAmount: Number(amount) || null,
              budgetType,
              interests: interest,
              behaviors: behavior,
              billingOn: 'IMPRESSIONS',
              destinationId: Number(selected) || null,
              schedule:
                budgetType === 'lifetime_budget'
                  ? {
                      start_minute: Number(timeRange[0]) * 60,
                      end_minute: Number(timeRange[1]) * 60,
                      days: [...selectedDays]?.sort((a, b) => a - b) || null,
                    }
                  : null,
              frequencyCap: frequency,
            },
            adCreative: {
              description: primaryText || '',
              title: headline || '',
              media: mediaFiles || null,
              destinationId: Number(selected) || null,
              mediaUrl: adsData?.mediaUrl || null,
              CTA,
              phoneNumber: phoneValue ? `+${phoneValue}` : null,
              link: website || null,
              instantForm:
                Number(selected) === 6
                  ? {
                      headline: instantHeadline,
                      formName: instantFormTitle,
                      questions: instantSelected,
                      CTA: instantCTA,
                    }
                  : null,
            },
          },
        };
        const result =
          from === 'preview' || onClick === 'Preview' || onClick === 'Publish'
            ? await imageDataToServer(payload)
            : await handleApiRequest(payload);
        if (result?.createAndUpdateAdsCampaign?.status === 200) {
          setInstantForm(false);
          setAdsCampaignId(result?.createAndUpdateAdsCampaign?.data?.adDraftId);
          if (onClick === 'Preview') {
            fetchAdsPreview();
          }
          if (onClick === 'Draft') {
            history.push('quick-ads/ads-campaign/');
          }
          if (onClick === 'Publish') {
            publishAds();
          }
        } else {
          if (onClick === 'Draft') {
            history.push('quick-ads/ads-campaign/');
          }
          if (result?.createAndUpdateAdsCampaign?.status === 190) {
            enqueueSnackbar(
              result?.createAndUpdateAdsCampaign?.errorMessage ||
                result?.createAndUpdateAdsCampaign?.message,
              {
                variant: 'error',
                autoHideDuration: 2000,
              }
            );
            setTimeout(() => {
              history.push(`/quick-ads/ads-social-connect/`);
            }, 1000);
          }
          if (result?.createAndUpdateAdsCampaign?.status === 303) {
            setTermsAndCondition(true);
            const url = result?.createAndUpdateAdsCampaign?.url;
            if (url) {
              setTAndCURL(url);
            }
          }
          enqueueSnackbar(
            result?.createAndUpdateAdsCampaign?.errorMessage ||
              result?.createAndUpdateAdsCampaign?.message,
            {
              variant: 'error',
              autoHideDuration: 2000,
            }
          );
        }
      } catch {
        console.error('An error occurred');
      }
      setRefreshIcon(false);
    },

    [
      CTA,
      adsData?.id,
      adsData?.mediaUrl,
      adsData?.name,
      adsData?.quickHubAdsObjectiveId,
      ageRange,
      amount,
      behavior,
      budgetAmount,
      budgetType,
      campaignName,
      city,
      countryKeys,
      data?.id,
      draftId,
      enabledPlatformNames,
      endDate,
      enqueueSnackbar,
      fetchAdsPreview,
      frequency,
      from,
      gender,
      headline,
      instantCTA,
      instantFormTitle,
      instantHeadline,
      instantSelected,
      interest,
      mediaFiles,
      phoneValue,
      primaryText,
      publishAds,
      scheduleEndDate,
      scheduleEndTime,
      scheduledDate,
      scheduledTime,
      selected,
      selectedDays,
      state,
      timeRange,
      userCurrentBusinessDetails?.id,
      website,
    ]
  );

  const handleDelete = async () => {
    try {
      const payload = {
        query: quickAdsQuery.pauseOrDeleteAdsCampaign,
        variables: {
          adsCampaignId: draftId || adsData?.id,
          operation: 'DELETE',
        },
      };
      const result = await handleApiRequest(payload);
      if (result?.pauseOrDeleteAdsCampaign?.status === 200) {
        history.push('quick-ads/ads-campaign/');
        enqueueSnackbar(result?.pauseOrDeleteAdsCampaign?.message, {
          variant: 'success',
          autoHideDuration: 2000,
        });
      } else {
        enqueueSnackbar(result?.pauseOrDeleteAdsCampaign?.message, {
          variant: 'error',
          autoHideDuration: 2000,
        });
      }
    } catch (err) {
      console.error('Error fetching data:', err);
    }
  };

  const steps = [
    t('quickAds.choosePlatform&Schedule'),
    t('quickAds.audienceSegment'),
    t('quickAds.chooseMedia'),
    t('quickAds.adCopy&CTASelection'),
  ];

  if (loading) {
    return <FuseLoading />;
  }

  const handleClick = (index) => {
    setActiveStep(index);
  };

  return (
    <div className="p-16 lg:p-24 h-full">
      <div className="sm:flex justify-between lg:mb-20 mb-16">
        <h1 className=" md:text-28 text-20 font-semibold mb-16 sm:mb-0 flex items-center">
          {t('quickCampaigns.createCampaigns')}
        </h1>
        <Button
          className="text-16 font-medium rounded-md px-28 disabled:bg-gray flex"
          variant="outlined"
          color="quickSocial"
          disabled={refreshIcon}
          onClick={() => setDiscard(true)}
        >
          {t('quickAds.discardCampaign')}
          <DeleteOutlineIcon className="text-20 text-black ml-10 " />
          {refreshIcon && (
            <CircularProgress className="text-darkgreen absolute mx-auto" size={24} />
          )}
        </Button>
      </div>
      <div>
        <div className="flex mb-32">
          {steps?.map((label, index) => (
            <React.Fragment key={index}>
              <div className="flex flex-row max-h-72">
                <div className="flex flex-col gap-20">
                  <Button
                    className={`border-1 border-solid text-14 sm:text-16 font-medium px-20 py-5 hover:bg-quick-social hover:border-quick-social hover:text-white ${
                      activeStep === index
                        ? 'border-quick-social bg-quick-social text-white'
                        : 'border-black disabled:border-grey-300'
                    } `}
                    onClick={() => handleClick(index)}
                    disabled={
                      (label === 'Audience Segment' && !isButtonEnabled) ||
                      (label === 'Audience Segment' && !scheduledTime) ||
                      (addedInterests?.length <= 0 && label === 'Choose Media') ||
                      (addedAreas?.length <= 0 && label === 'Choose Media') ||
                      (!mediaFiles && !adsData?.mediaUrl && label === 'Ad Copy & CTA Selection') ||
                      refreshIcon
                    }
                  >
                    <Icon className="mr-5">{t('quickAds.groups')}</Icon>
                    <p className="text-center">{label}</p>
                  </Button>
                </div>
                {index < steps.length - 1 && (
                  <div className="w-36 sm:w-96 h-1 mt-20 mx-12 sm:mx-10 my-2 relative bg-grey-800 before:w-5 before:h-5 before:bg-black before:inline-block before:rounded-full before:absolute before:-mt-2 after:w-5 after:h-5 after:bg-black after:inline-block after:rounded-full after:absolute after:-mt-2 after:right-0" />
                )}
              </div>
            </React.Fragment>
          ))}
        </div>

        <div>
          {activeStep === 0 && (
            <Box className="max-w-786 w-full">
              <ChoosePlatform
                cardsData={cardsData}
                setActiveStep={setActiveStep}
                scheduledDate={scheduledDate}
                setScheduledDate={setScheduledDate}
                scheduledTime={scheduledTime}
                setScheduledTime={setScheduledTime}
                budgetType={budgetType}
                setBudgetType={setBudgetType}
                amount={amount}
                setAmount={setAmount}
                platformStates={platformStates}
                setPlatformStates={setPlatformStates}
                budgetAmount={budgetAmount}
                setBudgetAmount={setBudgetAmount}
                refreshIcon={refreshIcon}
                setRefreshIcon={setRefreshIcon}
                createAndUpdateAdsCampaign={createAndUpdateAdsCampaign}
                setFrom={setFrom}
                endDate={endDate}
                setEndDate={setEndDate}
                scheduleEndDate={scheduleEndDate}
                setScheduleEndDate={setScheduleEndDate}
                scheduleEndTime={scheduleEndTime}
                setScheduleEndTime={setScheduleEndTime}
                handleSetScheduleEndDate={handleSetScheduleEndDate}
              />
            </Box>
          )}
          {activeStep === 1 && (
            <Box className="max-w-[982px] w-full">
              <AudienceSegmentBudget
                setActiveStep={setActiveStep}
                addedInterests={addedInterests}
                setAddedInterests={setAddedInterests}
                addedAreas={addedAreas}
                setAddedAreas={setAddedAreas}
                timeRange={timeRange}
                setTimeRange={setTimeRange}
                selectedDays={selectedDays}
                setSelectedDays={setSelectedDays}
                ageRange={ageRange}
                setAgeRange={setAgeRange}
                createAndUpdateAdsCampaign={createAndUpdateAdsCampaign}
                gender={gender}
                setGender={setGender}
                setFrom={setFrom}
                budgetType={budgetType}
                refreshIcon={refreshIcon}
                setCityLongLat={setCityLongLat}
                cityLongLat={cityLongLat}
                regionLongLat={regionLongLat}
                setRegionLongLat={setRegionLongLat}
                prevCityKeysLength={prevCityKeysLength}
                setPrevCityKeysLength={setPrevCityKeysLength}
                prevRegionKeysLength={prevRegionKeysLength}
                setPrevRegionKeysLength={setPrevRegionKeysLength}
                radiusValue={radiusValue}
                setRadiusValue={setRadiusValue}
                countryId={countryId}
              />
            </Box>
          )}
          {activeStep === 2 && (
            <Box className="max-w-786 w-full">
              <ChooseMedia
                setActiveStep={setActiveStep}
                mediaFiles={mediaFiles}
                setMediaFiles={setMediaFiles}
                createAndUpdateAdsCampaign={createAndUpdateAdsCampaign}
                setFrom={setFrom}
                adsData={adsData}
                refreshIcon={refreshIcon}
              />
            </Box>
          )}
          {activeStep === 3 && (
            <Box>
              {/* <QuickCampaignsFeatures
              allFeaturesTrialDataId={allFeaturesTrialDataId}
              campaignTrialDone={trialStatus.campaign}
              surpriseMeCardData={surpriseMeCardData}
            /> */}
              <AdCopy
                createAndUpdateAdsCampaign={createAndUpdateAdsCampaign}
                mediaFiles={mediaFiles}
                data={data}
                selected={selected}
                setSelected={setSelected}
                headline={headline}
                setHeadline={setHeadline}
                primaryText={primaryText}
                setPrimaryText={setPrimaryText}
                CTA={CTA}
                setCTA={setCTA}
                frequency={frequency}
                setFrequency={setFrequency}
                setFrom={setFrom}
                openPreview={openPreview}
                setOpenPreview={setOpenPreview}
                preview={preview}
                setPreview={setPreview}
                activeTab={activeTab}
                setActiveTab={setActiveTab}
                instantFormTitle={instantFormTitle}
                setInstantFormTitle={setInstantFormTitle}
                instantHeadline={instantHeadline}
                setInstantHeadline={setInstantHeadline}
                instantSelected={instantSelected}
                setInstantSelected={setInstantSelected}
                instantCTA={instantCTA}
                setInstantCTA={setInstantCTA}
                instantForm={instantForm}
                setInstantForm={setInstantForm}
                mobileApp={mobileApp}
                setMobileApp={setMobileApp}
                appName={appName}
                setAppName={setAppName}
                phoneValue={phoneValue}
                setPhoneValue={setPhoneValue}
                website={website}
                setWebsite={setWebsite}
                publishAds={publishAds}
                refresh={refresh}
                fetchAdsPreview={fetchAdsPreview}
                status={status}
                adsData={adsData}
                refreshIcon={refreshIcon}
                isInstagramConnected={isInstagramConnected}
              />
            </Box>
          )}
        </div>
      </div>

      {termsAndCondition && TAndCURL && (
        <ConformationDialogBox
          open={termsAndCondition}
          onClose={() => setTermsAndCondition(false)}
          onDelete={() => redirect()}
          confirmationText={t('common.note')}
          additionalInfo={t('quickAds.termsAndConditionContent')}
          deleteImage="/assets/images/icon/socialConfirmation.png"
          backgroundColor="quick-social"
          textColor="quick-social"
          buttonText="Got It"
        />
      )}

      {discard && (
        <ConformationDialogBox
          open={discard}
          onClose={() => setDiscard(false)}
          onDelete={() => handleDelete()}
          confirmationText={t('quickAds.discardConfirmation')}
          additionalInfo={t('quickAds.discardContent')}
          deleteImage="/assets/images/icon/socialConfirmation.png"
          backgroundColor="quick-social"
          textColor="quick-social"
          buttonText="Discard"
        />
      )}
    </div>
  );
}
export default CreateAds;
