import { getAllUserBusinessSubscription, selectDashboardData } from 'app/store/userSlice';
import userService from 'src/app/services';
import React from 'react';
import { useSelector } from 'react-redux';
import Slider from 'react-slick';
import history from '@history';
import { t } from 'i18next';
import { useParams } from 'react-router-dom';
import { Button } from '@mui/material';
import { findExpireDay } from '../common/common';

const CommonFreeTrial = (props) => {
  const userCurrentBusinessDetails = useSelector(selectDashboardData);
  const userSubscriptionData = useSelector(getAllUserBusinessSubscription);
  const loginUserData = userService.getUserData();
  const params = useParams();

  const isFreeTrialLength = !!userSubscriptionData?.some(
    (subscriptionData) =>
      (props.callFrom !== 'QuickHubDashboard' ||
        subscriptionData?.userBusinessId === userCurrentBusinessDetails?.id) &&
      subscriptionData?.isFreeTrial
  );

  const subscriptionDataSettings = {
    dots: false,
    infinite: !isFreeTrialLength,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
  };

  return (
    !loginUserData?.isSupportMultipleBusinessConnect &&
    userSubscriptionData &&
    userSubscriptionData?.length > 0 &&
    userSubscriptionData?.some(
      (subscriptionData) =>
        (props.callFrom === 'QuickHubDashboard'
          ? subscriptionData?.userBusinessId === userCurrentBusinessDetails?.id
          : true) && subscriptionData.isFreeTrial
    ) &&
    (props.callFrom === 'Layout1' || props.callFrom === 'QuickHubDashboard' ? (
      <div className="bg-[#FFE4E1] mb-20 sm:text-18 text-14 rounded-6 font-semibold p-10">
        <Slider {...subscriptionDataSettings}>
          {userSubscriptionData
            ?.filter((subscriptionData) => subscriptionData?.isFreeTrial)
            ?.flatMap((subscriptionData, i) => {
              const comboCount = subscriptionData?.comboDetails?.length;
              const hasExpired = findExpireDay(subscriptionData?.expireDate) <= 0;

              if (comboCount === 5) {
                return (
                  <div key={i} className="w-full !flex justify-center relative top-3">
                    <div className="items-center flex justify-center">
                      <img
                        className="block rounded max-w-38 mr-16"
                        name="logo"
                        src="/assets/images/icon/bellIconNotification.png"
                        alt="bell notification icon"
                      />
                      {hasExpired ? (
                        <div className="w-full min-h-40 max-h-40 flex items-center justify-center">
                          <p className="mr-28">
                            {t('quickHubDashboard.your')} [
                            {subscriptionData?.comboDetails?.map((item) => item?.name).join(', ')}]{' '}
                            {t('quickHubDashboard.planExpired')}.
                          </p>
                        </div>
                      ) : (
                        <p className="mr-28 sm:mr-48">
                          {t('quickHubDashboard.your')} {subscriptionData?.name} [
                          {subscriptionData?.comboDetails?.map((item) => item?.name).join(', ')}]{' '}
                          {t('quickHubDashboard.freeTrialExpire')}{' '}
                          {findExpireDay(subscriptionData?.expireDate)} {t('common.days')}.
                        </p>
                      )}
                      <Button
                        className="!min-w-128 !max-w-128 !w-128 text-14 font-medium bg-[#D07062] text-white hover:bg-red-300 rounded-12 px-16"
                        variant="contained"
                        size="small"
                        type="submit"
                        onClick={() => {
                          const packageBundle = [subscriptionData?.packageTypeId];
                          const intervalType = subscriptionData?.packageInterval;
                          const paymentDetails =
                            subscriptionData?.packageInterval === 'month' ||
                            subscriptionData?.packageInterval === 'year'
                              ? 'subscription'
                              : 'oneTimePayment';
                          history.push(
                            params?.id ? `/selectProduct/${params.id}` : '/selectProduct/',
                            {
                              packageBundle,
                              intervalType,
                              paymentDetails,
                              isBusinessAdded: true,
                            }
                          );
                        }}
                      >
                        {t('quickHubDashboard.upgradeNow')}
                      </Button>
                    </div>
                  </div>
                );
              }
              return subscriptionData?.comboDetails?.length > 0 ? (
                subscriptionData?.comboDetails?.map((item, index) => (
                  <div
                    key={`${subscriptionData?.id}-${index}`}
                    className="w-full !flex items-center  justify-center"
                  >
                    <div className="items-center flex justify-center">
                      <img
                        className="block rounded max-w-38 mr-16"
                        name="logo"
                        src="/assets/images/icon/bellIconNotification.png"
                        alt="bell notification icon"
                      />
                      {hasExpired ? (
                        <div className="w-full min-h-40 max-h-40 flex items-center justify-center">
                          <p className="mr-28">
                            {t('quickHubDashboard.your')} {item?.name}{' '}
                            {t('quickHubDashboard.planExpired')}.
                          </p>
                        </div>
                      ) : (
                        <p className="mr-28 sm:mr-48">
                          {t('quickHubDashboard.your')} {item?.name}{' '}
                          {t('quickHubDashboard.freeTrialExpire')}{' '}
                          {findExpireDay(subscriptionData?.expireDate)} {t('common.days')}.
                        </p>
                      )}
                      <Button
                        className="!min-w-128 !max-w-128 !w-128 text-14 font-medium bg-[#D07062] text-white hover:bg-red-300 rounded-12 px-16"
                        variant="contained"
                        size="small"
                        type="submit"
                        onClick={() => {
                          const packageBundle = [item?.id] || [subscriptionData?.packageTypeId];
                          const intervalType = subscriptionData?.packageInterval;
                          const paymentDetails =
                            subscriptionData?.packageInterval === 'month' ||
                            subscriptionData?.packageInterval === 'year'
                              ? 'subscription'
                              : 'oneTimePayment';
                          history.push(
                            params?.id ? `/selectProduct/${params.id}` : '/selectProduct/',
                            {
                              packageBundle,
                              intervalType,
                              paymentDetails,
                              isBusinessAdded: true,
                            }
                          );
                        }}
                      >
                        {t('quickHubDashboard.upgradeNow')}
                      </Button>
                    </div>
                  </div>
                ))
              ) : (
                <div className="w-full !flex items-center  justify-center">
                  <div className="items-center flex justify-center">
                    <img
                      className="block rounded max-w-38 mr-16"
                      name="logo"
                      src="/assets/images/icon/bellIconNotification.png"
                      alt="bell notification icon"
                    />
                    {hasExpired ? (
                      <div className="w-full min-h-40 max-h-40 flex items-center justify-center">
                        <p className="mr-28">
                          {t('quickHubDashboard.your')} {subscriptionData?.name}{' '}
                          {t('quickHubDashboard.planExpired')}.
                        </p>
                      </div>
                    ) : (
                      <p className="mr-28 sm:mr-48">
                        {t('quickHubDashboard.your')} {subscriptionData?.name}{' '}
                        {t('quickHubDashboard.freeTrialExpire')}{' '}
                        {findExpireDay(subscriptionData?.expireDate)} {t('common.days')}.
                      </p>
                    )}
                    <Button
                      className="!min-w-128 !max-w-128 !w-128 text-14 font-medium bg-[#D07062] text-white hover:bg-red-300 rounded-12 px-16"
                      variant="contained"
                      size="small"
                      type="submit"
                      onClick={() => {
                        const packageBundle = [subscriptionData?.packageTypeId];
                        const intervalType = subscriptionData?.packageInterval;
                        const paymentDetails =
                          subscriptionData?.packageInterval === 'month' ||
                          subscriptionData?.packageInterval === 'year'
                            ? 'subscription'
                            : 'oneTimePayment';
                        history.push(
                          params?.id ? `/selectProduct/${params.id}` : '/selectProduct/',
                          {
                            packageBundle,
                            intervalType,
                            paymentDetails,
                            isBusinessAdded: true,
                          }
                        );
                      }}
                    >
                      {t('quickHubDashboard.upgradeNow')}
                    </Button>
                  </div>
                </div>
              );
            })}
        </Slider>
      </div>
    ) : (
      userSubscriptionData &&
      userSubscriptionData
        .filter((subscriptionData) =>
          subscriptionData?.packageTypeId !== 12 &&
          subscriptionData?.comboDetails?.length > 0 &&
          subscriptionData?.comboDetails?.length !== 5
            ? subscriptionData?.comboDetails.some((item) => item?.name === props.productName)
            : subscriptionData?.name === props.productName
        )
        .map(
          (subscriptionData, i) =>
            subscriptionData?.userBusinessId === userCurrentBusinessDetails?.id &&
            subscriptionData?.isFreeTrial === true &&
            findExpireDay(subscriptionData?.expireDate) > 0 && (
              <div key={i} className="bg-[#FFE4E1] text-16 rounded-6 font-600 p-16">
                <div>
                  <div className="w-full flex justify-center">
                    <div className="w-384 sm:w-640 items-center flex">
                      <img
                        className="block rounded max-w-28 sm:max-w-32"
                        name="logo"
                        src="/assets/images/icon/reviewNotificationIcon.png"
                        alt="bell notification icon"
                      />
                      <p className="ml-10 mr-16 sm:ml-16 sm:mr-32 text-14 sm:text-16 ">
                        {t('quickHubDashboard.your')} {props.productName}{' '}
                        {t('quickHubDashboard.freeTrialExpire')}{' '}
                        {findExpireDay(subscriptionData?.expireDate)} {t('common.days')}.
                      </p>
                      (
                      <Button
                        className="text-12 md:text-14 font-semibold bg-[#D24811] text-white hover:bg-red-500 rounded-12"
                        variant="contained"
                        size="small"
                        type="submit"
                        onClick={() => {
                          history.push(
                            params?.id ? `/selectProduct/${params.id}` : '/selectProduct/',
                            {
                              packageBundle: [props?.packageBundle],
                              intervalType: subscriptionData?.packageInterval,
                              isBusinessAdded: true,
                            }
                          );
                        }}
                      >
                        {t('quickHubDashboard.upgradeNow')}
                      </Button>
                      )
                    </div>
                  </div>
                </div>
              </div>
            )
        )
    ))
  );
};

export default CommonFreeTrial;
