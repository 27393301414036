import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import ReactApexChart from 'react-apexcharts';
import { selectDashboardData } from 'src/app/store/userSlice';
import metricsData from '../../query/metrics';
import ReviewSentiment from './reviewSentiment';
import { getDataToServer } from '../../common/common';
import NoData from './noDataScreen';

function RepliedVsNotReplied(props) {
  const { reviewSentiment, reviewPlatformId, placeId, sizes, dataLabel, fromDashboard } = props;
  const [reviewsSentimentBarData, setReviewsSentimentBarData] = useState();
  const userCurrentBusinessDetails = useSelector(selectDashboardData);
  const [newGraphData, setNewGraphData] = useState([]);
  const [planStatus, setPlanStatus] = useState();

  useEffect(() => {
    try {
      const fetchData = async () => {
        if (
          (reviewPlatformId &&
            (Number(reviewPlatformId) === 2 ||
              Number(reviewPlatformId) === 3 ||
              Number(reviewPlatformId) === 4 ||
              Number(reviewPlatformId) === 7 ||
              Number(reviewPlatformId) === 8)) ||
          (userCurrentBusinessDetails?.isOnlineBusiness && placeId && reviewPlatformId)
        ) {
          const payload = {
            query: metricsData.getRepliedVsNonRepliedMatrices,
            variables: {
              reviewPlatformId: Number(reviewPlatformId),
              reviewsSentimentPeriodId: 7,
            },
          };
          if (Number(reviewPlatformId) === 5 || Number(reviewPlatformId) === 6) {
            payload.variables.placeId = placeId;
          } else {
            payload.variables.userBusinessId = parseInt(userCurrentBusinessDetails?.id, 10);
          }
          const result = await getDataToServer(payload);
          const data = result?.getRepliedVsNonRepliedMatrices?.data?.repliedVsNonrepliedData;
          const convertToObject = (arr) => {
            const obj = {};
            arr?.forEach(({ key, value }) => {
              obj[key] = value;
            });
            return obj;
          };
          const keyValueObject = convertToObject(data);
          setReviewsSentimentBarData(keyValueObject || {});

          const filterTotalCount = data?.filter((item) => item.key !== 'repliedReviewCount');
          if (filterTotalCount) {
            setNewGraphData(
              [
                filterTotalCount?.find((item) => item.key === 'replied')?.value,
                filterTotalCount?.find((item) => item.key === 'nonReplied')?.value,
              ] || []
            );
          }
        } else if (
          userCurrentBusinessDetails?.isOnlineBusiness &&
          !placeId &&
          (Number(reviewPlatformId) === 5 || Number(reviewPlatformId) === 6)
        ) {
          setPlanStatus('');
          setNewGraphData([]);
          setReviewsSentimentBarData({});
        }
      };
      fetchData();
    } catch (error) {
      console.error('An error occurred:', error);
    }
  }, [
    userCurrentBusinessDetails?.id,
    reviewPlatformId,
    placeId,
    userCurrentBusinessDetails?.isOnlineBusiness,
  ]);

  function getChartSimple(data, business, index, color, types) {
    const chart = {
      series: data,
      options: {
        labels: business,
        chart: {
          events: {
            mounted: (charts) => {
              charts.windowResizeHandler();
            },
          },
          type: types,
          stacked: true,
          parentHeightOffset: 0,
          zoom: {
            enabled: false,
          },
          toolbar: {
            show: false,
          },
        },
        plotOptions: {
          pie: {
            expandOnClick: false,
            donut: {
              size: 70,
              labels: {
                show: true,
                name: {
                  show: true,
                  fontSize: '15px',
                  fontWeight: 600,
                  color: undefined,
                  offsetY: -10,
                },
                value: {
                  show: true,
                  fontSize: '16px',
                  fontWeight: 400,
                  color: undefined,
                  offsetY: 16,
                },
              },
            },
          },
        },
        legend: {
          position: 'bottom',
          show: false,
          width: '100%',
          markers: {
            radius: 12,
          },
        },
        colors: color,
        dataLabels: {
          enabled: dataLabel === 'true',
        },
      },
    };
    return (
      <div id={`chart${index}`} className="flex flex-col">
        <ReactApexChart
          options={chart?.options}
          series={chart?.series}
          type={chart?.options?.chart?.type}
          height="170px"
          width="100%"
        />
      </div>
    );
  }

  return (
    <>
      {newGraphData?.length > 0 ? (
        <div className="flex flex-col md:flex-row w-full justify-center items-center">
          <div className="w-full md:w-7/12 justify-center">
            {reviewsSentimentBarData &&
              getChartSimple(
                newGraphData,
                ['Replied', 'No Replied'],
                2,
                ['#67DF9D', '#F2B443'],
                'donut'
              )}
          </div>
          <div className="w-full md:w-5/12 flex-col">
            <li className="flex items-center justify-between border-b-1 border-dashed border-grey-400 pb-12 mb-12">
              <span className="text-14 font-medium">
                <span className="w-12 h-12 inline-block bg-[#67DF9D] rounded-full mr-10 relative top-3" />
                Replied
              </span>
              <span className="text-18 font-semibold">{reviewsSentimentBarData?.replied}%</span>
            </li>
            <li className="flex items-center justify-between pb-12">
              <span className="text-14 font-medium">
                <span className="w-12 h-12 inline-block bg-[#F2B443] rounded-full mr-10 relative top-3" />
                No Replied
              </span>
              <span className="text-18 font-semibold">{reviewsSentimentBarData?.nonReplied}%</span>
            </li>
          </div>
        </div>
      ) : (
        <NoData fromDashboard={fromDashboard} />
      )}
    </>
  );
}

export default RepliedVsNotReplied;
