import { Button, CircularProgress, Icon, Toolbar, Typography } from '@mui/material';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import EditIcon from '@mui/icons-material/Edit';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import history from '@history';
import BrokenImageOutlinedIcon from '@mui/icons-material/BrokenImageOutlined';
import PlayArrowOutlinedIcon from '@mui/icons-material/PlayArrowOutlined';
import FuseLoading from '@fuse/core/FuseLoading';
import { useLocation } from 'react-router-dom';
import { t } from 'i18next';
import { useSnackbar } from 'notistack';
import quickHub from 'src/app/main/query/quickHub';
import { handleApiRequest, multipleImageDataToSend } from 'src/app/main/common/common';
import manageBusinessData from 'src/app/main/query/manageBusiness';
import LabelOrBusinessDrawer from '../../reports/LabelOrBusinessDrawer';
import ErrorPopUp from '../../common/ErrorPopUp';

const AddPhotoAndVideo = () => {
  const location = useLocation();
  const fileInputRef = useRef(null);
  const { enqueueSnackbar } = useSnackbar();
  const [selectedImages, setSelectedImages] = useState([]);
  const activeTabLabel = location?.state?.activeTab?.label;
  const [refreshIcon, setRefreshIcon] = useState(false);
  const [imagesPhotoData, setImagesPhotoData] = useState([]);
  const [googleBusinessData, setGoogleBusinessData] = useState();
  const [isCoverOrProfilePhotoEdit, setIsCoverOrProfilePhotoEdit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [deletePhotoVideoData, setDeletePhotoVideoData] = useState();
  const [deleteRefreshIcon, setDeleteRefreshIcon] = useState(false);
  const [openBusinessDrawer, setOpenBusinessDrawer] = useState(false);
  const [openErrorDialog, setOpenErrorDialog] = useState(false);
  const [failedRequests, setFailedRequests] = useState([]);
  const [selectLabelOrBusiness, setSelectLabelOrBusiness] = useState(
    location?.state?.selectBusiness || []
  );

  const selectedSection = useMemo(
    () => [
      {
        id: 1,
        label: t('primaryDetails.images'),
        title: t('primaryDetails.addImages'),
        title2: t('primaryDetails.addMoreImages'),
        desc1: t('primaryDetails.dragDropImage'),
        value: 'photo',
      },
      {
        id: 2,
        label: t('primaryDetails.videos'),
        title: t('primaryDetails.addVideo'),
        title2: t('primaryDetails.addMoreVideo'),
        desc1: t('primaryDetails.dragDropVideo'),
        value: 'video',
      },
      {
        id: 3,
        label: t('primaryDetails.profilePhotos'),
        title: t('primaryDetails.addProfilePhoto'),
        desc1: t('primaryDetails.dragDropImage'),
        value: 'profile',
      },
      {
        id: 4,
        label: t('primaryDetails.coverPhoto'),
        title: t('primaryDetails.coverPhoto'),
        desc1: t('primaryDetails.dragDropImage'),
        value: 'cover',
      },
    ],
    []
  );
  const selectedTabData = selectedSection?.find((tab) => tab?.label === activeTabLabel);

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  const fetchBusinessDetails = useCallback(async () => {
    if (Number(selectLabelOrBusiness?.[0]?.id)) {
      setLoading(true);
      try {
        const payload = {
          query: manageBusinessData.getUserBusinessesGoogleDetails,
          variables: {
            businessId: Number(selectLabelOrBusiness?.[0]?.id),
          },
        };
        const result = await handleApiRequest(payload);
        setLoading(false);
        if (result?.getUserBusinessesGoogleDetails?.status === 200) {
          const data = result.getUserBusinessesGoogleDetails?.data;
          const parsedData = JSON.parse(data);
          setGoogleBusinessData(parsedData);
        } else {
          enqueueSnackbar(result?.getUserBusinessesGoogleDetails?.message, {
            variant: 'error',
            autoHideDuration: 3000,
          });
        }
      } catch (error) {
        setLoading(false);
        console.error('Error fetching business details:', error);
      }
    }
  }, [selectLabelOrBusiness, enqueueSnackbar]);

  useEffect(() => {
    if (!location?.state?.isBulk) {
      fetchBusinessDetails();
    }
  }, [fetchBusinessDetails, location?.state?.isBulk]);

  const fetchGooglePhotoVideo = useCallback(async () => {
    if (selectLabelOrBusiness?.[0]?.id) {
      try {
        const payload = {
          query: manageBusinessData.getGoogleBusinessPhotosVideos,
          variables: {
            businessId: Number(selectLabelOrBusiness?.[0]?.id),
          },
        };
        const result = await handleApiRequest(payload);
        if (result?.getGoogleBusinessPhotosVideos?.status === 200) {
          const googlePhotos =
            (result.getGoogleBusinessPhotosVideos?.data?.googlePhotos &&
              JSON.parse(result.getGoogleBusinessPhotosVideos?.data?.googlePhotos)) ||
            [];
          const additionalPhotoValues = googlePhotos?.find(
            (item) => item.key === 'ADDITIONAL'
          )?.value;
          const googleVideos =
            (result.getGoogleBusinessPhotosVideos?.data?.googleVideo &&
              JSON.parse(result.getGoogleBusinessPhotosVideos?.data?.googleVideo)) ||
            [];
          const additionalVideoValues = googleVideos?.find(
            (item) => item.key === 'ADDITIONAL'
          )?.value;
          setImagesPhotoData({
            photo: additionalPhotoValues || [],
            video: additionalVideoValues || [],
          });
        }
      } catch (error) {
        console.error('Error fetching Google Photos/Videos:', error);
      }
    }
  }, [selectLabelOrBusiness]);

  useEffect(() => {
    if (!location?.state?.isBulk) {
      fetchGooglePhotoVideo();
    }
  }, [fetchGooglePhotoVideo, location?.state?.isBulk]);

  useEffect(() => {
    const selectedTab = selectedSection?.find((tab) => tab?.label === activeTabLabel);
    if (!location?.state?.isBulk) {
      if (selectedTab?.value === 'photo') {
        setSelectedImages(imagesPhotoData?.photo);
      } else if (selectedTab?.value === 'video') {
        setSelectedImages(imagesPhotoData?.video);
      }
    }
  }, [activeTabLabel, selectedSection, imagesPhotoData, location?.state?.isBulk]);

  const handleFileChange = useCallback(
    (event) => {
      try {
        const file = event.target.files[0];
        if (file) {
          const fileKey = `isValidFile_${file.name}_${file.size}`;
          let isValidFile = sessionStorage.getItem(fileKey);

          if (isValidFile === null) {
            isValidFile =
              (file.type.startsWith('image/') && file.type !== 'image/webp') ||
              (file.type.startsWith('video/') && file.type !== 'video/webp');
            sessionStorage.setItem(fileKey, isValidFile);
          } else {
            isValidFile = JSON.parse(isValidFile);
          }
          if (isValidFile) {
            const isDuplicate = selectedImages?.some(
              (image) => image?.file?.name === file?.name && image?.file?.size === file?.size
            );
            if (!isDuplicate) {
              const fileWithPreview = {
                file,
                preview: URL.createObjectURL(file),
              };
              setSelectedImages((prevImages) =>
                prevImages?.length < 1 ? [fileWithPreview] : [...prevImages, fileWithPreview]
              );
              setIsCoverOrProfilePhotoEdit(false);
            }
          } else {
            enqueueSnackbar(
              `Only valid ${activeTabLabel === 'Videos' ? 'Video' : 'Image'} files are allowed`,
              {
                variant: 'error',
                autoHideDuration: 2000,
              }
            );
          }
          setTimeout(() => {
            sessionStorage.removeItem(fileKey);
          }, 100);
        }
      } catch (error) {
        console.error('Error handling file change:', error);
      }
    },
    [activeTabLabel, selectedImages, enqueueSnackbar]
  );

  const handleFileDrop = (event) => {
    event.preventDefault();
    const files = Array.from(event.dataTransfer.files);

    const errorMessageKey = `errorMessageShownFor${activeTabLabel}`;

    if (!sessionStorage.getItem(errorMessageKey)) {
      sessionStorage.removeItem(errorMessageKey);
    }

    files.forEach((file) => {
      const errorMessageAlreadyShown = sessionStorage.getItem(errorMessageKey);
      if (
        (activeTabLabel === 'Videos' &&
          file.type.startsWith('video/') &&
          file.type !== 'video/webp') ||
        ((activeTabLabel === 'Images' ||
          activeTabLabel === 'Profile Photo' ||
          activeTabLabel === 'Cover Photo') &&
          file.type.startsWith('image/') &&
          file.type !== 'image/webp')
      ) {
        const isDuplicate = selectedImages?.some(
          (image) => image?.file?.name === file?.name && image?.file?.size === file?.size
        );

        if (!isDuplicate) {
          const fileWithPreview = {
            file,
            preview: URL.createObjectURL(file),
          };

          const sessionKey = `file_${file.name}_${file.size}`;
          if (!sessionStorage.getItem(sessionKey)) {
            sessionStorage.setItem(sessionKey, 'added');
            setSelectedImages((prevImages) => [...prevImages, fileWithPreview]);
            setIsCoverOrProfilePhotoEdit(false);
          }
          setTimeout(() => {
            sessionStorage.removeItem(sessionKey, 'added');
          }, 100);
        }
      } else if (!errorMessageAlreadyShown) {
        enqueueSnackbar(`Only valid ${activeTabLabel === 'Videos' ? 'Video' : 'Image'} files`, {
          variant: 'error',
          autoHideDuration: 2000,
        });
        sessionStorage.setItem(errorMessageKey, 'true');
      }
    });

    setTimeout(() => {
      sessionStorage.removeItem(errorMessageKey);
    }, 100);
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const onSubmit = async () => {
    setRefreshIcon(true);
    try {
      const payload = {
        query: quickHub.updateGoogleBusinessDetailsForBusinesses,
        variables: {
          businessIds: selectLabelOrBusiness?.map((business) => business?.id),
          mask: selectedSection?.find((tab) => tab?.label === activeTabLabel)?.value,
          allMedia: selectedImages
            ?.filter((img) => !img?.name)
            ?.map((image) => ({ media: image?.file })),
        },
      };
      const result = await multipleImageDataToSend(payload);
      setRefreshIcon(false);
      if (result?.updateGoogleBusinessDetailsForBusinesses?.data?.failedRequests?.length > 0) {
        setOpenErrorDialog(true);
        setFailedRequests(result?.updateGoogleBusinessDetailsForBusinesses?.data?.failedRequests);
      }
      if (result?.updateGoogleBusinessDetailsForBusinesses?.status === 200) {
        if (location?.state?.isBulk) {
          history.push('quick-hub/primaryDetails', location?.state?.activeTab);
          enqueueSnackbar(result?.updateGoogleBusinessDetailsForBusinesses?.message, {
            variant: 'success',
            autoHideDuration: 3000,
          });
        } else {
          fetchGooglePhotoVideo();
          enqueueSnackbar(result?.updateGoogleBusinessDetailsForBusinesses?.message, {
            variant: 'success',
            autoHideDuration: 3000,
          });
        }
      } else if (result?.updateGoogleBusinessDetailsForBusinesses?.message !== 'Bad request') {
        enqueueSnackbar(result?.updateGoogleBusinessDetailsForBusinesses?.message, {
          variant: 'error',
          autoHideDuration: 3000,
        });
      }
    } catch (error) {
      setRefreshIcon(false);
      console.error('Error updating business hours:', error);
    }
  };

  const deleteImage = async (data) => {
    setDeleteRefreshIcon(true);
    setDeletePhotoVideoData(data?.name);
    if (selectLabelOrBusiness?.length > 0) {
      try {
        const payload = {
          query: manageBusinessData.deleteGoogleBusinessPhotosAndVideos,
          variables: {
            mediaId: data?.name?.split('/')?.pop(),
            userBusinessId: Number(selectLabelOrBusiness?.[0]?.id),
            mediaFormat: data?.mediaFormat,
          },
        };
        const result = await handleApiRequest(payload);
        setDeleteRefreshIcon(false);
        if (result?.deleteGoogleBusinessPhotosAndVideos?.status === 200) {
          fetchGooglePhotoVideo();
          setDeletePhotoVideoData();
          enqueueSnackbar(result?.deleteGoogleBusinessPhotosAndVideos?.message, {
            variant: 'success',
            autoHideDuration: 3000,
          });
        } else {
          enqueueSnackbar(result?.deleteGoogleBusinessPhotosAndVideos?.message, {
            variant: 'error',
            autoHideDuration: 3000,
          });
        }
      } catch (error) {
        setDeleteRefreshIcon(false);
        setDeletePhotoVideoData();
        enqueueSnackbar('An error occurred while deleting the image or video. Please try again.', {
          variant: 'error',
          autoHideDuration: 3000,
        });
        console.error('Error in deleteImage:', error);
      }
    }
  };

  const deleteCoverOrProfileImage = async () => {
    if (selectedImages?.length > 0) {
      setSelectedImages([]);
    } else if (selectLabelOrBusiness?.length > 0) {
      const payload = {
        query: quickHub.removeGoogleBusinessPhotos,
        variables: {
          userBusinessId: Number(selectLabelOrBusiness?.[0]?.id),
          category: selectedTabData?.value?.toUpperCase(),
        },
      };
      const result = await handleApiRequest(payload);
      if (result?.removeGoogleBusinessPhotos?.status === 200) {
        fetchBusinessDetails();
        enqueueSnackbar(result?.removeGoogleBusinessPhotos?.message, {
          variant: 'success',
          autoHideDuration: 3000,
        });
      } else {
        enqueueSnackbar(result?.removeGoogleBusinessPhotos?.message, {
          variant: 'error',
          autoHideDuration: 3000,
        });
      }
    }
  };

  const handleRemoveImage = (data, index) => {
    if (data?.name) {
      deleteImage(data);
    } else {
      const newImages = [...selectedImages];
      newImages.splice(index, 1);
      setSelectedImages(newImages);
    }
  };

  if (loading) {
    return <FuseLoading />;
  }

  return (
    <div className="bg-gray-A500 h-full p-16 sm:p-24">
      <Toolbar className="px-0 font-semibold min-h-fit mb-20">
        <Icon
          className="text-20 cursor-pointer"
          onClick={() => history.push('quick-hub/primaryDetails', location?.state?.activeTab)}
        >
          west
        </Icon>
        <Typography variant="subtitle1" color="inherit" className="flex-1 px-12 font-bold text-18">
          {selectedTabData?.title}
        </Typography>
      </Toolbar>
      <div
        className={`flex flex-col ${
          selectedImages?.length < 1
            ? 'max-w-[800px]'
            : 'min-w-auto md:min-w-[800px] w-fit max-w-[1435px]'
        }`}
      >
        <div className="bg-white min-w-auto md:min-w-[800px] rounded-md">
          {selectedSection?.find((tab) => tab?.label === activeTabLabel)?.value === 'profile' ||
          selectedSection?.find((tab) => tab?.label === activeTabLabel)?.value === 'cover' ? (
            <>
              {selectedImages?.length > 0 ||
              (selectedSection?.find((tab) => tab?.label === activeTabLabel)?.value === 'profile' &&
                googleBusinessData?.profileImage) ||
              (selectedSection?.find((tab) => tab?.label === activeTabLabel)?.value === 'cover' &&
                googleBusinessData?.coverImage) ? (
                <div className="p-20 rounded-6">
                  <div className="relative w-full sm:w-[224px] h-192 sm:h-224 bg-gray-200 flex items-center justify-center rounded-md overflow-hidden">
                    <img
                      src={
                        !isCoverOrProfilePhotoEdit && selectedImages?.length > 0
                          ? selectedImages?.[0]?.preview
                          : selectedSection?.find((tab) => tab?.label === activeTabLabel)?.value ===
                            'profile'
                          ? googleBusinessData?.profileImage
                          : googleBusinessData?.coverImage
                      }
                      alt=""
                      className="w-full min-h-224 rounded-md"
                    />
                    <div className="absolute flex gap-8">
                      <Button
                        className="p-0 min-w-32 w-32 min-h-32 h-32 bg-white hover:bg-gray-A500"
                        onClick={() => {
                          setIsCoverOrProfilePhotoEdit(true);
                          setSelectedImages([]);
                        }}
                      >
                        <EditIcon className="w-20" />
                      </Button>
                      <Button
                        className="p-0 min-w-32 w-32 min-h-32 h-32 bg-white hover:bg-gray-A500"
                        onClick={() => {
                          deleteCoverOrProfileImage();
                          setIsCoverOrProfilePhotoEdit(true);
                        }}
                      >
                        <img className="w-16" src="assets/images/quick-hub/delete.png" alt="" />
                      </Button>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="p-20 rounded-6">
                  <div
                    className="flex flex-wrap flex-col bg-indigo-50 w-full max-w-480 items-center p-20 bg-opacity-50 rounded-6 mt-12 justify-center gap-6"
                    onDragOver={handleDragOver}
                    onDrop={handleFileDrop}
                  >
                    <div className="bg-gray-300 w-80 h-80 rounded-full flex justify-center items-center bg-opacity-50">
                      {activeTabLabel === 'Videos' ? (
                        <PlayArrowOutlinedIcon className="w-56 h-56 border-2 border-black rounded-full p-4" />
                      ) : (
                        <BrokenImageOutlinedIcon className="w-56 h-56 border-2 border-black rounded-full p-4" />
                      )}
                    </div>
                    <Typography>{selectedTabData?.desc1}</Typography>
                    <Typography>or</Typography>
                    <Button
                      className="rounded-md w-128 font-semibold"
                      variant="contained"
                      color="secondary"
                      onClick={handleButtonClick}
                    >
                      {t('primaryDetails.browseFile')}
                    </Button>
                  </div>
                </div>
              )}
              <input
                type="file"
                accept={activeTabLabel === 'Videos' ? 'video/*' : 'image/*'}
                ref={fileInputRef}
                style={{ display: 'none' }}
                onChange={handleFileChange}
              />
            </>
          ) : (
            <div className="p-20 rounded-6">
              <Typography className="text-16 mb-16">{t('primaryDetails.selectMedia')}</Typography>
              <div
                className="flex gap-10 flex-wrap"
                onDragOver={handleDragOver}
                onDrop={handleFileDrop}
              >
                {selectedImages?.map((image, index) => (
                  <div
                    key={index}
                    className="relative w-full sm:w-[224px] h-192 sm:h-120 bg-gray-200 flex items-center justify-center rounded-md overflow-hidden"
                  >
                    {activeTabLabel === 'Videos' ? (
                      <video
                        src={image?.preview || image?.sourceUrl || image?.googleUrl}
                        className="w-full h-full object-cover"
                        controls={false}
                        muted
                        playsInline
                      >
                        {t('primaryDetails.notSupportd')}
                      </video>
                    ) : (
                      <img
                        src={image?.preview || image?.googleUrl}
                        alt={`Images ${index + 1}`}
                        className="w-full h-full object-cover"
                      />
                    )}
                    {deleteRefreshIcon && deletePhotoVideoData === image?.name ? (
                      <CircularProgress
                        className="text-quick-review absolute top-4 right-4 mx-auto"
                        size={24}
                      />
                    ) : (
                      <CloseRoundedIcon
                        onClick={() => handleRemoveImage(image, index)}
                        className="text-18 absolute top-3 right-3 bg-white rounded-full text-black font-normal cursor-pointer hover:text-gray-700 focus:outline-none"
                      />
                    )}
                  </div>
                ))}
                {selectedImages?.length < 1 ? (
                  <>
                    <div
                      className="flex flex-wrap flex-col bg-indigo-50 w-full max-w-480 items-center p-20 bg-opacity-50 rounded-6 mt-12 justify-center gap-6"
                      onDragOver={handleDragOver}
                      onDrop={handleFileDrop}
                    >
                      <div className="bg-gray-300 w-80 h-80 rounded-full flex justify-center items-center bg-opacity-50">
                        {activeTabLabel === 'Videos' ? (
                          <PlayArrowOutlinedIcon className="w-56 h-56 border-2 border-black rounded-full p-4" />
                        ) : (
                          <BrokenImageOutlinedIcon className="w-56 h-56 border-2 border-black rounded-full p-4" />
                        )}
                      </div>
                      <Typography>{selectedTabData?.desc1}</Typography>
                      <Typography>or</Typography>
                      <Button
                        className="rounded-md w-128 font-semibold"
                        variant="contained"
                        color="secondary"
                        onClick={handleButtonClick}
                      >
                        {t('primaryDetails.browseFile')}
                      </Button>
                    </div>
                  </>
                ) : (
                  (activeTabLabel === 'Images' || activeTabLabel === 'Videos') && (
                    <div
                      className="flex flex-col justify-center items-center rounded-md w-full sm:w-[224px] h-192 sm:h-120 gap-8 bg-opacity-50 bg-indigo-50"
                      onDragOver={handleDragOver}
                      onDrop={handleFileDrop}
                    >
                      <Button
                        className="rounded-full !min-w-60 !max-w-60 !min-h-60 !max-h-60 bg-gray-300 bg-opacity-80 flex items-center justify-center border-0"
                        onClick={handleButtonClick}
                        variant="outlined"
                      >
                        <span className="text-52">+</span>
                      </Button>
                      <Typography className="text-16">{selectedTabData?.title2}</Typography>
                    </div>
                  )
                )}
              </div>
              <input
                type="file"
                accept={activeTabLabel === 'Videos' ? 'video/*' : 'image/*'}
                ref={fileInputRef}
                style={{ display: 'none' }}
                onChange={handleFileChange}
              />
            </div>
          )}
          {location?.state?.isBulk && (
            <>
              <hr className="border-solid !border-gray-400 border-1" />
              <div className="p-20 flex items-center flex-col sm:flex-row justify-between">
                <Typography className="font-semibold text-18">
                  {t('reports.selectBusiness')}
                </Typography>
                <div className="flex items-center sm:w-auto w-full sm:justify-end justify-between gap-10">
                  <Typography className="font-medium text-16">{`${t(
                    'primaryDetails.selectedBusiness'
                  )} (${selectLabelOrBusiness?.length})`}</Typography>
                  <Button
                    className="rounded-md pl-28 pr-28 !h-32 min-h-32 max-h-32 font-semibold"
                    variant="outlined"
                    color="secondary"
                    onClick={() => setOpenBusinessDrawer(true)}
                  >
                    {t('common.edit')}
                  </Button>
                </div>
              </div>
            </>
          )}
        </div>
        <div className="flex justify-end mt-20 w-full max-w-[1435px]">
          <Button
            className="rounded-md w-128 font-semibold"
            variant="contained"
            color="secondary"
            onClick={() => onSubmit()}
            disabled={
              refreshIcon ||
              selectLabelOrBusiness?.length === 0 ||
              selectedImages?.filter((img) => !img?.name)?.map((image) => ({ media: image?.file }))
                ?.length === 0
            }
          >
            {t('common.save')}
            {refreshIcon && (
              <CircularProgress className="text-quick-review absolute mx-auto" size={24} />
            )}
          </Button>
        </div>
      </div>
      <LabelOrBusinessDrawer
        openBusinessDrawer={openBusinessDrawer}
        setOpenBusinessDrawer={setOpenBusinessDrawer}
        setSelectLabelOrBusiness={setSelectLabelOrBusiness}
        selectLabelOrBusiness={selectLabelOrBusiness}
        groupType="business"
        isGoogleConnected
        activeTab={location.state?.activeTab}
      />
      <ErrorPopUp
        openErrorDialog={openErrorDialog}
        setOpenErrorDialog={setOpenErrorDialog}
        failedRequests={failedRequests}
        setFailedRequests={setFailedRequests}
      />
    </div>
  );
};

export default AddPhotoAndVideo;
