import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Icon,
  IconButton,
  Tooltip,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import React, { useState } from 'react';
import { t } from 'i18next';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { selectDashboardData } from 'app/store/userSlice';
import Input from '@mui/material/Input';
import { useSnackbar } from 'notistack';
import { handleApiRequest } from '../../common/common';
import quickSocialQuery from '../../query/quickSocial';

const PostEditDialogBox = ({
  open,
  onClose,
  post,
  fetchAgentSupportStatus,
  setOpenDrawer,
  setShowEditPost,
  handleCloseMenu,
}) => {
  const [descriptionValue, setDescriptionValue] = useState(post?.description || post?.caption);
  const userCurrentBusinessDetails = useSelector(selectDashboardData);
  const { enqueueSnackbar } = useSnackbar();
  const [btLoading, setBtLoading] = useState(false);

  const handleSearchEvent = (event) => {
    setDescriptionValue(event?.target.value);
  };

  const handleSubmit = async () => {
    setBtLoading(true);
    try {
      const payload = {
        query: quickSocialQuery.updatePost,
        variables: {
          description: descriptionValue,
          postId: Number(post?.id),
          userBusinessId: Number(userCurrentBusinessDetails?.id),
        },
      };
      const result = await handleApiRequest(payload);
      if (result?.updatePost?.status === 200) {
        fetchAgentSupportStatus();
        setShowEditPost(false);
        setOpenDrawer(false);
        handleCloseMenu();
        enqueueSnackbar(result?.updatePost?.message, {
          variant: 'success',
          autoHideDuration: 3000,
        });
      }
    } catch (error) {
      console.error('An error occurred while updating the post:', error);
    }
    setBtLoading(false);
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="delete-confirmation-dialog"
      classes={{
        paper: `business-type-dropdown rounded-md`,
      }}
      PaperProps={{
        style: {
          maxWidth: '1500px',
          maxHeight: '90vh',
        },
        className: 'rounded-md',
      }}
    >
      <DialogContent>
        <div className="flex justify-between bg-white p-16 rounded-t-12 ">
          <Typography className="text-28 font-600">{t('quickSocial.editPost')}</Typography>
          <IconButton className="" onClick={onClose} color="inherit">
            <CloseIcon className="w-24 h-24 text-grey-700" />
          </IconButton>
        </div>
        <div className="bg-bg-gray-A500 rounded-md flex">
          <div className="w-full flex flex-col justify-between">
            <div className="flex flex-col">
              <div className="flex justify-between bg-white m-28 rounded-md">
                <Input
                  placeholder="Add description"
                  className="text-black text-16 h-auto w-512  px-12"
                  disableUnderline
                  name="searchText"
                  fullWidth
                  multiline
                  maxRows={3}
                  onChange={handleSearchEvent}
                  value={descriptionValue}
                  inputProps={{
                    'aria-label': 'Search',
                  }}
                />
              </div>
              {post?.media?.image && (
                <div className="justify-center items-center flex px-160 mb-48">
                  <img src={post?.media?.image} alt="Post" className="max-h-400 max-w-400" />
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="flex justify-end items-center bg-gray-A500 p-28 rounded-b-12">
          <Button
            className="md:text-18 px-40 font-semibold rounded-md"
            variant="contained"
            color="quickSocial"
            type="button"
            onClick={() => handleSubmit()}
            disabled={btLoading}
          >
            Save
            {btLoading && (
              <CircularProgress size={24} className="text-quick-social absolute mx-auto" />
            )}
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default PostEditDialogBox;
