import React, { useCallback, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import UserService from 'src/app/services';
import { useTranslation } from 'react-i18next';
import FuseLoading from '@fuse/core/FuseLoading';
import {
  Button,
  Typography,
  CircularProgress,
  TextField,
  Switch,
  Box,
  Radio,
  RadioGroup,
  FormControlLabel,
  Checkbox,
} from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import {
  DatePicker,
  LocalizationProvider,
  renderTimeViewClock,
  TimePicker,
} from '@mui/x-date-pickers';
import { selectDashboardData } from 'app/store/userSlice';

const loginUserData = UserService.getUserData();

function ChoosePlatform({
  setActiveStep,
  scheduledDate,
  setScheduledDate,
  scheduledTime,
  setScheduledTime,
  refreshIcon,
  setRefreshIcon,
  budgetType,
  setBudgetType,
  amount,
  setAmount,
  platformStates,
  setPlatformStates,
  budgetAmount,
  setBudgetAmount,
  createAndUpdateAdsCampaign,
  endDate,
  setEndDate,
  scheduleEndDate,
  setScheduleEndDate,
  scheduleEndTime,
  setScheduleEndTime,
  handleSetScheduleEndDate,
  cardsData,
}) {
  const countryId = Number(loginUserData?.countryId);
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const userCurrentBusinessDetails = useSelector(selectDashboardData);

  const isInstagramConnected = cardsData?.find(
    (item) => item?.name === 'Instagram' && item?.isConnect
  );
  const [totalAmount, setTotalAmount] = useState();

  function calculateDaysDifference(startTime, endTime) {
    const scheduledStartTime = new Date(startTime);
    const scheduledEndTime = new Date(endTime);
    const diffInMs = scheduledEndTime - scheduledStartTime;
    const diffInMinutes = Math.floor(diffInMs / (1000 * 60));
    const days = Math.floor(diffInMinutes / (60 * 24));
    const remainingHours = Math.floor((diffInMinutes % (60 * 24)) / 60);
    return remainingHours > 0 ? days + 1 : days;
  }

  useEffect(() => {
    if (budgetType === 'lifetime_budget' && scheduledDate && scheduleEndDate) {
      const daysCount = calculateDaysDifference(scheduledDate, scheduleEndDate);
      setTotalAmount(countryId === 2 ? daysCount * 87 : daysCount * 1);
    }
  }, [budgetType, scheduledDate, scheduleEndDate, countryId]);

  const handleToggle = useCallback(
    (id) => {
      setPlatformStates((prevPlatforms) =>
        prevPlatforms?.map((platform) => {
          if (platform?.name === 'Instagram' && !isInstagramConnected) {
            return { ...platform, enabled: false };
          }
          return platform?.id === id ? { ...platform, enabled: !platform?.enabled } : platform;
        })
      );
    },
    [isInstagramConnected, setPlatformStates]
  );

  useEffect(() => {
    handleToggle(null);
  }, [handleToggle]);

  const isButtonEnabled = platformStates?.some((platform) => platform?.enabled);

  const handleDateChange = (data) => {
    setScheduledDate(data);
  };

  const handleTimeChange = (event) => {
    setScheduledTime(event);
  };

  const handleEndDateChange = (data) => {
    setScheduleEndDate(data);
  };

  const handleEndTimeChange = (event) => {
    setScheduleEndTime(event);
  };

  const isDateError = () => {
    if (!scheduledDate) return false;

    const today = new Date();
    const selectedDate = new Date(scheduledDate);

    return selectedDate < today.setHours(0, 0, 0, 0);
  };

  const isTimeError = () => {
    if (!scheduledDate || !scheduledTime) return false;

    const now = new Date();
    const selectedDate = new Date(scheduledDate);

    if (
      selectedDate?.getFullYear() === now.getFullYear() &&
      selectedDate?.getMonth() === now.getMonth() &&
      selectedDate?.getDate() === now.getDate()
    ) {
      const selectedTime = new Date();
      selectedTime?.setHours(scheduledTime?.getHours(), scheduledTime?.getMinutes(), 0, 0);
      return selectedTime < now;
    }

    return false;
  };

  const isEndDateError = () => {
    if (!scheduleEndDate) return false;

    const today = new Date();
    const selectedDate = new Date(scheduleEndDate);

    return selectedDate < today?.setHours(0, 0, 0, 0);
  };

  const isEndTimeError = () => {
    if (!scheduleEndDate || !scheduleEndTime) return false;

    const now = new Date();
    const selectedDate = new Date(scheduleEndDate);

    if (
      selectedDate?.getFullYear() === now?.getFullYear() &&
      selectedDate?.getMonth() === now?.getMonth() &&
      selectedDate?.getDate() === now?.getDate()
    ) {
      const selectedTime = new Date();
      selectedTime.setHours(scheduleEndTime.getHours(), scheduleEndTime.getMinutes(), 0, 0);

      return selectedTime < now;
    }

    return false;
  };

  if (loading) {
    return <FuseLoading />;
  }

  return (
    <div className="quick-social">
      <div className="bg-white rounded-lg border-1 border-solid border-grey-300 p-20 mb-20">
        <Typography className="font-semibold mb-10 mt-0 text-14 lg:text-18">
          {t('quickAds.choosePlatform')}
        </Typography>
        {platformStates
          ?.filter((platform) => platform?.name !== 'Instagram' || isInstagramConnected)
          ?.map((platform) => (
            <Box
              key={platform?.id}
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              className="p-16 mb-12 rounded-md bg-gray-A500 h-56"
            >
              <Box display="flex" alignItems="center">
                <img src={platform?.logo} alt={platform?.name} height={24} width={24} />
                <Typography className="ml-8 text-18 font-semibold">{platform?.name}</Typography>
              </Box>
              <Switch
                checked={platform?.enabled}
                onChange={() => handleToggle(platform?.id)}
                color="secondary"
              />
            </Box>
          ))}
      </div>
      <div className="bg-white rounded-lg border-1 border-grey-300 p-20 mb-20">
        <Typography className="font-600 mb-20 text-18 ">{t('quickAds.budget&Schedule')}</Typography>
        <RadioGroup
          row
          value={budgetType}
          onChange={(e) => setBudgetType(e.target.value)}
          className="mb-16"
        >
          <FormControlLabel
            value="lifetime_budget"
            control={<Radio />}
            label="Lifetime Budget"
            className="text-18"
          />
          <FormControlLabel
            value="daily_budget"
            control={<Radio />}
            label="Daily Budget"
            className="text-18 "
          />
        </RadioGroup>
        <div className="relative">
          <TextField
            className="w-full border-grey-300 bg-gray-A500"
            value={budgetAmount}
            type="number"
            size="medium"
            onChange={(e) => {
              const { value } = e.target;
              if (value === '' || Number(value) >= 0) {
                setBudgetAmount(value);
              }
            }}
            placeholder={t('waba.dashboard.enterAmount')}
            sx={{
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: '#f5f6fa',
                },
                '&:hover fieldset': {
                  borderColor: '#219EBC',
                },
              },
              '& .MuiInputBase-input': {
                fontWeight: '600',
                lineHeight: '22px',
                fontSize: 16,
              },
            }}
          />
          <div className="absolute inset-y-0 right-20 flex items-center text-quick-social font-semibold border-l-2 border-grey-300 pl-16  ">
            {countryId === 2 ? <p className="text-20"> ₹ </p> : <p className="text-20"> $ </p>}
          </div>
        </div>
        {budgetAmount < (countryId === 2 ? 86 : 1) && budgetType === 'daily_budget' && (
          <p className="text-16 text-red font-medium">
            {t(countryId === 2 ? 'quickAds.rupeesLimit' : 'quickAds.dollarLimit')}
          </p>
        )}
        {budgetAmount < totalAmount && budgetType === 'lifetime_budget' && (
          <p className="text-16 text-red font-medium">
            {t(countryId === 2 ? 'quickAds.rupeesLimitLifeTime' : 'quickAds.dollarLimitLifeTime')}
            {countryId === 2 ? '₹' : '$'}
            {totalAmount}
          </p>
        )}
        {budgetType === 'daily_budget' && (
          <Typography className="text-16 text-gray-600 mt-9 flex">
            {t('quickAds.budgetContent')}
            {countryId === 2 ? '₹' : '$'}
            {budgetAmount || '0'} {t('quickAds.perDay')}
          </Typography>
        )}
      </div>
      <div className="bg-white rounded-lg border-1 border-grey-300 p-20 mb-20">
        <Typography className=" mb-20 text-18 font-600">
          {t('quickAds.costPerResultGoal')}
        </Typography>
        <div className="relative">
          <TextField
            className="w-full border-grey-300 bg-gray-A500"
            value={amount}
            type="number"
            size="medium"
            onChange={(e) => {
              const { value } = e.target;
              if ((value === '' || Number(value) >= 0) && /^\d*$/.test(value)) {
                setAmount(value);
              }
            }}
            placeholder={t('waba.dashboard.enterAmount')}
            sx={{
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: '#f5f6fa',
                },
                '&:hover fieldset': {
                  borderColor: '#219EBC',
                },
              },
              '& .MuiInputBase-input': {
                fontWeight: '600',
                lineHeight: '22px',
                fontSize: 16,
              },
            }}
          />
          <div className="absolute inset-y-0 right-20 flex items-center text-quick-social font-semibold border-l-2 border-grey-300 pl-16  ">
            {countryId === 2 ? <p className="text-20"> ₹ </p> : <p className="text-20"> $ </p>}
          </div>
        </div>

        <Typography className="mt-14 text-16 text-gray-600 font-500">
          {t('quickAds.goalContent')}
          {countryId === 2 ? '₹' : '$'}
          {amount || '0'}
          {t('quickAds.goalContent2')}
        </Typography>
      </div>
      <div className="bg-white rounded-lg border-1 border-solid border-grey-300 p-20 mb-20">
        <Typography className="font-semibold mb-10 mt-0 text-14 lg:text-18">
          {t('quickCampaign.campaign.scheduleCampaign')}
        </Typography>

        <div className=" items-center justify-between gap-16 ">
          <div className="mb-20">
            <Typography className="font-semibold mb-10 mt-0 text-14 lg:text-18">
              {t('sendMessage.selectDate')}
            </Typography>
            <div className="mt-4">
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  className="w-full select-date bg-gray-A500 rounded-md"
                  id="note"
                  variant="contained"
                  fullWidth
                  disablePast
                  value={scheduledDate}
                  format="MMM dd, yyyy"
                  onChange={handleDateChange}
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      '& fieldset': {
                        borderColor: '#f5f6fa',
                      },
                      '&:hover fieldset': {
                        borderColor: '#219EBC',
                      },
                      '&.Mui-focused fieldset': {
                        borderColor: '#219EBC',
                      },
                    },
                    '& .MuiSvgIcon-root': {
                      color: '#219EBC',
                    },
                  }}
                />
              </LocalizationProvider>
            </div>
            {isDateError() && (
              <Typography className="text-red-500 text-sm mt-2">
                {t('quickAds.pleaseSelectAValidDate')}
              </Typography>
            )}
          </div>
          <div className="">
            <Typography className="font-semibold mb-10 mt-0 text-14 lg:text-18">
              {t('sendMessage.selectTime')}
            </Typography>
            <div className="mt-4">
              <TimePicker
                className="w-full select-date bg-gray-A500 rounded-md"
                id="time"
                onChange={handleTimeChange}
                size="small"
                value={scheduledTime}
                required
                viewRenderers={{
                  hours: renderTimeViewClock,
                  minutes: renderTimeViewClock,
                  seconds: renderTimeViewClock,
                }}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      borderColor: '#f5f6fa',
                    },
                    '&:hover fieldset': {
                      borderColor: '#219EBC',
                    },
                    '&.Mui-focused fieldset': {
                      borderColor: '#219EBC',
                    },
                  },
                  '& .MuiSvgIcon-root': {
                    color: '#219EBC',
                  },
                }}
              />
            </div>
            {isTimeError() && (
              <Typography className="text-red-500 text-sm mt-2">
                {t('quickAds.validTime')}
              </Typography>
            )}
          </div>
        </div>
        <FormControlLabel
          className=" capitalize"
          control={
            <Checkbox
              className=""
              checked={endDate}
              onChange={() => setEndDate(!endDate)}
              value={endDate}
            />
          }
          label={<p className="text-16 font-500"> {t('quickAds.setEndDateAndTime')} </p>}
        />
        {endDate && (
          <div className="bg-white rounded-lg mb-20">
            <div className=" items-center justify-between gap-16 ">
              <div className="mb-20">
                <Typography className="font-semibold mb-10 mt-0 text-14 lg:text-18">
                  {t('sendMessage.selectEndDate')}
                </Typography>
                <div className="mt-4">
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      className="w-full select-date bg-gray-A500 rounded-md"
                      id="note"
                      variant="contained"
                      fullWidth
                      disablePast
                      value={scheduleEndDate}
                      format="MMM dd, yyyy"
                      onChange={handleEndDateChange}
                      sx={{
                        '& .MuiOutlinedInput-root': {
                          '& fieldset': {
                            borderColor: '#f5f6fa',
                          },
                          '&:hover fieldset': {
                            borderColor: '#219EBC',
                          },
                          '&.Mui-focused fieldset': {
                            borderColor: '#219EBC',
                          },
                        },
                        '& .MuiSvgIcon-root': {
                          color: '#219EBC',
                        },
                      }}
                    />
                  </LocalizationProvider>
                </div>
                {isEndDateError() && (
                  <Typography className="text-red-500 text-sm mt-2">
                    {t('quickAds.pleaseSelectAValidDate')}
                  </Typography>
                )}
              </div>
              <div className="">
                <Typography className="font-semibold mb-10 mt-0 text-14 lg:text-18">
                  {t('sendMessage.selectEndTime')}
                </Typography>
                <div className="mt-4">
                  <TimePicker
                    className="w-full select-date bg-gray-A500 rounded-md"
                    id="time"
                    onChange={handleEndTimeChange}
                    size="small"
                    value={scheduleEndTime}
                    required
                    viewRenderers={{
                      hours: renderTimeViewClock,
                      minutes: renderTimeViewClock,
                      seconds: renderTimeViewClock,
                    }}
                    sx={{
                      '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                          borderColor: '#f5f6fa',
                        },
                        '&:hover fieldset': {
                          borderColor: '#219EBC',
                        },
                        '&.Mui-focused fieldset': {
                          borderColor: '#219EBC',
                        },
                      },
                      '& .MuiSvgIcon-root': {
                        color: '#219EBC',
                      },
                    }}
                  />
                </div>
                {isEndTimeError() && (
                  <Typography className="text-red-500 text-sm mt-2">
                    {t('quickAds.validTime')}
                  </Typography>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
      <div className="flex items-center justify-between">
        <Button
          className="md:text-16 font-medium rounded-md px-28 disabled:bg-gray"
          variant="outlined"
          color="quickSocial"
          onClick={() => createAndUpdateAdsCampaign('Draft')}
          disabled={isDateError() || isTimeError() || refreshIcon}
        >
          {t('quickAds.saveDraft')}
          {refreshIcon && (
            <CircularProgress className="text-darkgreen absolute mx-auto" size={24} />
          )}
        </Button>
        <Button
          className="md:text-16 font-medium rounded-md px-28 disabled:bg-gray disabled:text-white"
          variant="contained"
          color="quickSocial"
          onClick={() => {
            createAndUpdateAdsCampaign();
            setActiveStep(1);
          }}
          disabled={
            !scheduledDate ||
            !scheduledTime ||
            isDateError() ||
            isTimeError() ||
            isEndDateError() ||
            isEndTimeError() ||
            !budgetAmount ||
            (endDate && !scheduleEndDate) ||
            (endDate && !scheduleEndTime) ||
            !isButtonEnabled ||
            budgetAmount < (countryId === 2 ? 86 : 1) ||
            (budgetType === 'lifetime_budget' && budgetAmount < totalAmount) ||
            (budgetType === 'lifetime_budget' &&
              !endDate &&
              !scheduleEndDate &&
              !scheduleEndTime) ||
            refreshIcon
          }
        >
          {t('quickAds.proceed')}
          {refreshIcon && (
            <CircularProgress className="text-darkgreen absolute mx-auto" size={24} />
          )}
        </Button>
      </div>
    </div>
  );
}
export default ChoosePlatform;
