import dashboardsConfigs from './dashboards/dashboardsConfigs';
import MenuConfig from './menu/MenuConfig';
import PromotionConfig from './promotion/PromotionConfig';
import PlanConfig from './plan/PlanConfig';
import TargetMessageConfig from './target-messages/TargetMessageConfig';
import QrcodeOrderConfig from './qrcode-order/qrcodeOrderConfig';
import MangeBusinessConfig from './manage-business/ManageBusinessConfig';
import ReviewScreenModeConfig from './business-setup/review-screen-mode/ReviewScreenModeConfig';
import BusinessProductAndServiceConfig from './business-setup/business-productandservice/BusinessProductAndServiceConfig';
import CustomAttributeConfig from './business-setup/custom-attributes/CustomAttributesConfig';
import EditBusinessDetailsConfig from './business-setup/editBusinessDetails/EditBusinessDetailsConfig';
import ManageReviewSiteConfig from './business-setup/manageReviewSite/ManageReviewSiteConfig';
import ServiceRecoveryConfig from './business-setup/serviceRecovery/ServiceRecoveryConfig';
import StaffConfig from './business-setup/staff/StaffConfig';
import QrCodeConfig from './business-setup/qrCode/QrCodeConfig';
import QuickChatConfigs from './quick-chat/quickChatConfig';
import QuickSocialConfigs from './quick-social/quickSocialConfig';
import WabaConfigs from './waba/wabaConfig';
import QuickPostConfigs from './quick-post/quickPostConfig';
import PlanUsageConfig from './business-setup/planUsage/PlanUsageConfig';
import UserNumbersConfig from './business-setup/userNumbers/UserNumberConfig';
import AutoReplyConfig from './business-setup/autoReply/AutoReplyConfig';
import AutoGenerateTextConfig from './business-setup/autoGenerateText/AutoGenerateTextConfig';
import MatricesConfig from './matrices/matricesConfigs';
import ProductsConfig from './products/ProductsConfig';
import InviteUserConfig from './business-setup/inviteUser/InviteUserConfig';
import BusinessPerformanceReportConfig from './business-setup/businessPerformanceReport/BusinessPerformanceReportConfig';
import GeoGridConfigs from './businessCompetitor/geoGridConfig';
import ListManagementConfig from './listManagement/ListManagementConfig';
import MySubscriptionConfig from './plan/mySubscription/MysubscriptionConfig';
import DescriptionConfigs from './description/DescriptionConfig';
import BusinessListConfig from './manageBusinessList/BusinessListConfig';
import ExploreFeaturesConfigs from './explore-features/ExploreFeaturesConfig';
import NotificationConfig from './notifications/NotificationConfig';
import QuickCampaignsConfigs from './quick-campaigns/quickCampaignsConfig';
import quickAdsConfig from './quick-ads/quickAdsConfig';

const businessConfigs = [
  dashboardsConfigs,
  DescriptionConfigs,
  MenuConfig,
  TargetMessageConfig,
  PromotionConfig,
  PlanConfig,
  QrcodeOrderConfig,
  MangeBusinessConfig,
  ReviewScreenModeConfig,
  BusinessProductAndServiceConfig,
  CustomAttributeConfig,
  EditBusinessDetailsConfig,
  ManageReviewSiteConfig,
  ServiceRecoveryConfig,
  StaffConfig,
  QrCodeConfig,
  QuickChatConfigs,
  QuickSocialConfigs,
  WabaConfigs,
  QuickPostConfigs,
  PlanUsageConfig,
  UserNumbersConfig,
  AutoReplyConfig,
  AutoGenerateTextConfig,
  MatricesConfig,
  ProductsConfig,
  InviteUserConfig,
  BusinessPerformanceReportConfig,
  GeoGridConfigs,
  ListManagementConfig,
  MySubscriptionConfig,
  BusinessListConfig,
  ExploreFeaturesConfigs,
  NotificationConfig,
  QuickCampaignsConfigs,
  quickAdsConfig,
];

export default businessConfigs;
